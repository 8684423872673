import React from 'react';
import PropTypes from 'prop-types';
import { Switch, useRouteMatch } from 'react-router-dom';
import AppRoute from '../../AppRoute';

// Layouts
import ApplicationLayout from '../ApplicationLayout';

// Pages
// BusinessOwners
import Home from './Home';
import About from './About';
import WhySimplyRetirement from './WhySimplyRetirement';
import RetirementPlans from './RetirementPlans';
import Resources from './Resources';
import LearnTheBasics from './LearnTheBasics';
import BusinessOwnersFAQ from './Faq';
import Investments from '../Investments';
import Disclosures from '../Disclosures';
import ManageCookiesPreferences from '../ManageCookiesPreferences';
import Sitemap from '../Sitemap';

const BusinessOwners = ({ paradigmHost }) => {
  const { url } = useRouteMatch();
  const description =
    'Meet Simply Retirement by Principal®, the online 401(k) plan for small business owners. Learn about this 401(k) plan and sign up today.';
  const businessOwnerRoutes = [
    {
      key: 'business-owners',
      component: Home,
      title: '401k Plans for Small Business Owners',
      description,
    },
    {
      key: 'about',
      component: About,
      title: 'Small Business 401k Market',
      description:
        'Grow your small business retirement plan options with Simply Retirement by Principal®. Create and share a proposal today.',
    },
    {
      key: 'why-simply-retirement',
      component: WhySimplyRetirement,
      title: ' Small Business 401k',
      description:
        'Simply Retirement by Principal® is an affordable small business 401(k) plan solution. This simple, online platform will give your employees a way to save.',
    },
    {
      key: 'retirement-plans',
      component: RetirementPlans,
      title: '401k Plans for Small Business Owners',
      description:
        'Simply Retirement by Principal® allows you to customize a 401(k) plan for small business owners. Learn about this 401(k) plan and sign up today.',
    },
    {
      key: 'investments',
      component: Investments,
      title: '401k Program For Small Business',
      description:
        'Grow your small business retirement plan options with Simply Retirement by Principal®. Create and share a proposal today.',
    },
    {
      key: 'resources',
      component: Resources,
      title: 'Resources for Setting Up A Small Business 401k Plan',
      description:
        'Simply Retirement by Principal® helps you set up a 401(k) plan for your small business. Learn more about retirement plans and how you can help your employees most.',
    },
    {
      key: 'learn-the-basics',
      component: LearnTheBasics,
      title: 'How Do You Set up a 401k Plan',
      description:
        'Wondering "How do I set up a 401(k) plan?" Simply Retirement by Principal® can help! It’s a simple, affordable 401(k) plan solution for small businesses.',
    },
    {
      key: 'faq',
      component: BusinessOwnersFAQ,
      title: 'Frequently Asked Questions',
      description:
        'Read some of the frequently asked questions about Simply Retirement by Principal®. Contact us with any additional questions about our small business 401k plans.',
    },
    {
      exact: true,
      key: 'disclosures',
      layout: ApplicationLayout,
      component: Disclosures,
      title: 'Disclosures',
    },
    {
      exact: true,
      key: 'manage-cookie-settings',
      layout: ApplicationLayout,
      component: ManageCookiesPreferences,
      title: 'Manage Cookie Preferences',
    },
    {
      exact: true,
      key: 'sitemap',
      layout: ApplicationLayout,
      component: Sitemap,
      title: 'Sitemap',
    },
  ];

  return (
    <Switch>
      {businessOwnerRoutes.map((route) => {
        const keyPath = url === `/${route.key}` ? url : `${url}/${route.key}`;
        return (
          <AppRoute
            key={route.key}
            exact
            path={`${keyPath}`}
            layout={ApplicationLayout}
            paradigmHost={paradigmHost}
            {...route}
          />
        );
      })}
      <AppRoute
        layout={ApplicationLayout}
        component={Home}
        title="401k Plans For Small Business"
        description="Simply Retirement by Principal® is a flat-fee, retirement plan solution allowing small businesses to easily manage an affordable 401(k)."
        paradigmHost={paradigmHost}
      />
    </Switch>
  );
};

BusinessOwners.propTypes = {
  paradigmHost: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  hashId: PropTypes.string,
};

export default BusinessOwners;
