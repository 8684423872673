import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import smoothscroll from 'smoothscroll-polyfill';
import { Navigation } from './Navigation';
import { toCamelCase } from './utils';

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

const ArrowLeft = ({
  navWindow,
  translateWidth,
  navLinksWidth,
  activeTab,
  setTranslateWidth,
}) => (
  <button
    aria-label="Left"
    className="tab-controls left"
    type="button"
    onClick={() => {
      navWindow.current.scrollTo({
        top: 0,
        left: translateWidth - (navLinksWidth[activeTab]?.width ?? 0),
        behavior: 'smooth',
      });

      setTranslateWidth((prevWidth) =>
        Math.ceil(prevWidth - (navLinksWidth[activeTab]?.width ?? 0)),
      );
    }}
  >
    <i className="fa-solid fa-angle-left" aria-hidden />
  </button>
);

ArrowLeft.propTypes = {
  navWindow: PropTypes.shape({
    current: PropTypes.any,
  }),
  translateWidth: PropTypes.number,
  navLinksWidth: PropTypes.object,
  activeTab: PropTypes.string,
  setTranslateWidth: PropTypes.func,
};

const ArrowRight = ({
  linkWidthTotal,
  translateWidth,
  navWindow,
  navLinksWidth,
  activeTab,
  setTranslateWidth,
}) => (
  <button
    aria-label="Right"
    className="tab-controls right"
    type="button"
    onClick={() => {
      if (Math.floor(0) - 50 > linkWidthTotal + translateWidth) {
        return false;
      }
      navWindow?.current.scrollTo({
        top: 0,
        left: translateWidth + (navLinksWidth[activeTab]?.width ?? 0),
        behavior: 'smooth',
      });
      setTranslateWidth((prevWidth) =>
        Math.ceil(prevWidth + (navLinksWidth[activeTab]?.width ?? 0)),
      );
      return true;
    }}
  >
    <i className="fa-solid fa-angle-right" aria-hidden />
  </button>
);

ArrowRight.propTypes = {
  linkWidthTotal: PropTypes.number,
  translateWidth: PropTypes.number,
  navWindow: PropTypes.shape({
    current: PropTypes.any,
  }),
  navLinksWidth: PropTypes.object,
  activeTab: PropTypes.string,
  setTranslateWidth: PropTypes.func,
};

export const PrincipalTabs = ({ children, defaultTab = 0, ...rest }) => {
  const [activeTab, setTab] = useState(
    toCamelCase(children[defaultTab].props.title + defaultTab),
  );
  const [navLinksWidth, setNavLinksWidth] = useState({});
  const [translateWidth, setTranslateWidth] = useState(0);
  const navWindow = useRef();
  const navTabs = useRef();
  const navTabsWidth = navTabs.current ? navTabs.current.scrollWidth : 0;
  const linkWidthTotal = Object.values(navLinksWidth).reduce(
    (t, { width }) => t + width,
    0,
  );

  const handleScroll = () => {
    setTranslateWidth(navWindow.current ? navWindow.current.scrollLeft : 0);
  };

  useEffect(() => {
    if (!navWindow.current) {
      return false;
    }
    navWindow.current.addEventListener('scroll', handleScroll);
    return () => false;
  }, []);

  const showRightArrow = () => {
    if (!navWindow.current) return false;
    if (
      Math.ceil(navWindow.current.getBoundingClientRect().width) <
      Math.ceil(navTabsWidth)
    ) {
      return (
        translateWidth <
        linkWidthTotal - (navTabs.current?.getBoundingClientRect().width ?? 0)
      );
    }
    return false;
  };

  return (
    <section className="principal-tabs">
      <div className="principal-tabs__wrapper">
        {translateWidth > 0 && (
          <ArrowLeft
            linkWidthTotal={linkWidthTotal}
            translateWidth={translateWidth}
            navWindow={navWindow}
            navLinksWidth={navLinksWidth}
            activeTab={activeTab}
            setTranslateWidth={setTranslateWidth}
          />
        )}
        <nav
          className="principal-tabs__tablist position-relative"
          ref={navWindow}
        >
          <ul
            ref={navTabs}
            aria-label="Tab List"
            role="tablist"
            className="principal-nav"
            style={{
              transition: 'all 0.2s ease-in 0s',
            }}
          >
            <Navigation
              activeTab={activeTab}
              setTab={setTab}
              navLinksWidth={navLinksWidth}
              setNavLinksWidth={setNavLinksWidth}
            >
              {children}
            </Navigation>
          </ul>
        </nav>
        {showRightArrow() && (
          <ArrowRight
            linkWidthTotal={linkWidthTotal}
            translateWidth={translateWidth}
            navWindow={navWindow}
            navLinksWidth={navLinksWidth}
            activeTab={activeTab}
            setTranslateWidth={setTranslateWidth}
          />
        )}
      </div>
      <div className={classNames('principal-tabs__panels', rest.panelStyle)}>
        {children.map((child, index) => {
          const id = toCamelCase(child.props.title) + index;

          return (
            <article
              key={id}
              id={`panel_${id}`}
              role="tabpanel"
              aria-labelledby={`tab_${id}`}
              className={classNames('principal-tabs__panel', {
                'animated fadeInUp show': activeTab === id,
                'animated fadeOutDown': activeTab !== id,
              })}
              style={{ animationDuration: '.2s' }}
            >
              {React.cloneElement(child.props.component, {
                title: child.props.title,
                description: child.props.description,
              })}
            </article>
          );
        })}
      </div>
    </section>
  );
};

PrincipalTabs.propTypes = {
  defaultTab: PropTypes.number,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        title: PropTypes.any.isRequired,
        component: PropTypes.any.isRequired,
        description: PropTypes.any,
      }),
    }),
  ),
};

export { Item } from './Item';
export default PrincipalTabs;
