import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { roleSpecificPath } from '../FormWithSteps/Utilities';
import { selectRole } from '../../Redux/FormDataRedux';
import { IconReturnO } from '../../ImagePaths';
import { Alert } from './PlanDesignStickyAlert.styles';

const PlanDesignStickyAlert = ({
  rootRoute,
  userRole,
  planInProgress,
  isReviewPlan,
}) => (
  <Link
    to={roleSpecificPath(userRole, rootRoute, '/review-plan')}
    className="alert alert-info d-print-none"
    style={{
      display: planInProgress && !isReviewPlan ? 'block' : 'none',
    }}
  >
    <Alert
      className="bounceIn mx-auto mr-md-3 mb-3"
      style={{ animationDuration: '0.5s' }}
    >
      <img
        src={IconReturnO}
        width={30}
        height={30}
        className="my-auto"
        alt=""
      />
      <div className="mx-3">
        <h5 className="fs-p fw-bold text-white mb-0 lh-125">
          You have a plan in progress.
        </h5>
        <p className="fs-disclaimer fw-bold text-white lh-125 my-0 py-0">
          Continue building your plan.
        </p>
      </div>
      <i
        className="ml-2 fa-solid fa-chevron-right "
        style={{ color: '#026964' }}
        aria-hidden
      />
    </Alert>
  </Link>
);

PlanDesignStickyAlert.propTypes = {
  rootRoute: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  planInProgress: PropTypes.bool.isRequired,
  isReviewPlan: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  userRole: selectRole(store),
  planInProgress: store.formData.planDesign.inProgress,
});

export default connect(mapStateToProps)(PlanDesignStickyAlert);
