import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const NextStepBtn = ({
  onClick,
  className,
  text = 'Next step',
  disabled,
  id,
}) => (
  <button
    id={id}
    type="button"
    className={classNames(
      'srp-button',
      'srp-button__sm-large',
      'srp-button__primary',
      'mt-3',
      'mb-5',
      'my-md-5',
      className,
    )}
    disabled={disabled}
    onClick={() => {
      onClick();
    }}
  >
    {text}
  </button>
);

NextStepBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default NextStepBtn;
