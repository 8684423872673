import styled from 'styled-components';

export const HeroWrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    display: block;
    background-color: #e6e7e8;
    background-image: repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.25) 400px,
        rgba(57, 68, 78, 0.25) 650px,
        transparent 650px 760px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      );
    opacity: 0.2;
    background-repeat: no-repeat;
    z-index: -1;
    bottom: 0px;
    left: 0px;
    border-radius: 50%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    position: absolute;
    height: 100%;
    transform: scale(1.65);
    transform-origin: bottom center;
    width: 100%;
  }
`;
