export const onSaveToast = (hashId, userRole, planOriginator, action) => {
  if (!hashId) {
    return 'You’ll receive an email with a link to your plan.';
  }
  if (userRole === 'advisor' && planOriginator.role === 'employer') {
    return `You've successfully shared this plan.`;
  }
  if (
    userRole !== planOriginator.role &&
    userRole !== 'advisor' &&
    planOriginator.role !== 'employer'
  ) {
    return `We’ll notify ${planOriginator.name} by email that the plan has been updated.`;
  }
  return `Your proposal has been successfully ${action}.`;
};
