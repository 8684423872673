export const vestingScheduleMatch = {
  id: 'q6aI1c1d1a',
  type: 'dropdown',
  name: 'vestingScheduleMatch',
  paradigmAlias: 'vestingScheduleMatch',
  user: {},
  stepName: 'a_step_6_a_i_1_d',
  label:
    'Which vesting schedule will apply to discretionary matching contributions?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: '4-year graded = 25%, 50%, 75%, 100%',
      value: 'fourYearGraded',
      valueFormatted: '4-year graded',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: '5-year graded = 20%, 40%, 60%, 80%, 100%',
      value: 'fiveYearGraded',
      valueFormatted: '5-year graded',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: '6-year graded = 0%, 20%, 40%, 60%, 80%, 100%',
      value: 'sixYearGraded',
      valueFormatted: '6-year graded',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 4,
      user: {},
      label: '3-year cliff = 0%, 0%, 100%',
      value: 'threeYearCliff',
      valueFormatted: '3-year cliff',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 5,
      user: {},
      label: 'Immediate = 100%',
      value: 'immediate',
      valueFormatted: 'Immediate',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Vesting schedule',
  reviewScreenTitle:
    'Vesting schedule for discretionary matching contributions',
  optionDescription: '',
  description:
    'If a specific vesting schedule was selected for this contribution type, it will be shown here.',
};
