import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  authenticityToken,
  isValid,
  hashId,
  redirect,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isValid ? (
        <Layout {...rest} title={rest.title} description={rest.description}>
          <Component
            {...props}
            authenticityToken={authenticityToken}
            hashId={hashId}
          />
        </Layout>
      ) : (
        <Redirect to={redirect} />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  authenticityToken: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  redirect: PropTypes.string.isRequired,
  hashId: PropTypes.string,
};

export default PrivateRoute;
