import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReviewSelection from './ReviewSelection';
import SecureActNotice from './SecureActNotice';
import { rootRoute } from '../../../Services/utilities';

const NavBtn = ({
  text,
  panelId,
  activePlanOutLineTab,
  togglePlanOutLineTab,
  notificationIndicator,
  setNotificationIndicator,
}) => (
  <div className="position-relative">
    {notificationIndicator && text === 'Cost Estimator' && (
      <div
        className="d-inline-block flash"
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: '#4caac6',
          animationDuration: '3s',
          borderBottom: 'none',
        }}
      ></div>
    )}
    <button
      className={`nav-btn${activePlanOutLineTab === panelId ? ' active' : ''}`}
      type="button"
      onClick={() => {
        togglePlanOutLineTab(panelId);
        if (panelId === 'estimator') {
          setNotificationIndicator(false);
        }
      }}
      onKeyDown={() => {
        togglePlanOutLineTab(panelId);
      }}
    >
      {text}
    </button>
  </div>
);

NavBtn.propTypes = {
  text: PropTypes.string.isRequired,
  panelId: PropTypes.string.isRequired,
  activePlanOutLineTab: PropTypes.string.isRequired,
  togglePlanOutLineTab: PropTypes.func.isRequired,
  notificationIndicator: PropTypes.bool.isRequired,
  setNotificationIndicator: PropTypes.func.isRequired,
};

export const PlanOutLine = ({
  selections,
  notificationIndicator,
  setNotificationIndicator,
  questions,
  resetData,
  hashId,
  role,
  costEstimator: CostEstimator,
  ...rest
}) => {
  const location = useLocation();
  const [activePlanOutLineTab, togglePlanOutLineTab] =
    useState('reviewSelections');
  const tabButtons = [
    { text: 'Your Selections', panelId: 'reviewSelections' },
    { text: 'Cost Estimator', panelId: 'estimator' },
  ];
  const [showSecureActNotice, toggleSecureActNotice] = useState(true);
  const [resetClick, handleResetClick] = useState(false);
  const toggleReset = () => {
    handleResetClick((prevState) => !prevState);
  };
  useEffect(() => {
    togglePlanOutLineTab(rest.defaultTab);
  }, [rest.defaultTab]);
  return (
    <div className={`fws__sidebar${rest.showPlanOutLine ? ' show' : ''}`}>
      <div className="text-center mt-4">
        <h2 className="fs-h2 text-white d-none d-md-block">Plan Outline</h2>
        <button
          type="button"
          className="sidebar__toggle mx-auto fs-h2 text-white fw-bold text-center"
          onClick={() => {
            rest.togglePlanOutLine(false);
          }}
        >
          Plan Outline <i className="fas fa-chevron-down" aria-hidden />
        </button>
      </div>
      <div className="tabs">
        <div className="tabs__nav">
          {tabButtons.map((btn) => (
            <NavBtn
              key={btn.panelId}
              text={btn.text}
              panelId={btn.panelId}
              activePlanOutLineTab={activePlanOutLineTab}
              togglePlanOutLineTab={togglePlanOutLineTab}
              notificationIndicator={notificationIndicator}
              setNotificationIndicator={setNotificationIndicator}
            />
          ))}
        </div>
        <div className="tabs__panels">
          <div
            id="reviewSelections"
            className={classNames('panel', {
              active: activePlanOutLineTab === 'reviewSelections',
            })}
          >
            <ReviewSelection
              selections={selections}
              resetData={resetData}
              hashId={hashId}
            />
          </div>
          <div
            id="costEstimator"
            className={classNames('panel', {
              active: activePlanOutLineTab === 'estimator',
            })}
          >
            {showSecureActNotice && (
              <SecureActNotice toggleSecureActNotice={toggleSecureActNotice} />
            )}
            <CostEstimator
              questions={questions}
              setNotificationIndicator={setNotificationIndicator}
              selections={selections}
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        {hashId === '' && !resetClick && (
          <p>
            <button
              type="button"
              className="text-center srp-button srp-button__outline__white"
              onClick={() => {
                toggleReset();
              }}
            >
              <i className="fa-solid fa-arrows-rotate"></i>&nbsp;&nbsp;Reset and
              start over
            </button>
          </p>
        )}
        {resetClick && (
          <div className="my-3 justify-content-center">
            <div className="bg-light-purple card p-3">
              <p className="text-white text-center py-3 mb-0 fw-bold">
                <i className="fa-solid fa-arrows-rotate"></i>&nbsp;&nbsp;Reset
                and start over
              </p>
              <p className="text-white text-center">
                This will reset your current plan data. Are you sure you want to
                start over?
              </p>
              <div className="text-center">
                <button
                  type="button"
                  className="srp-button srp-button__primary mx-2 mb-2"
                  onClick={() => {
                    sessionStorage.clear();
                    resetData();
                  }}
                >
                  Reset Plan
                </button>{' '}
                <button
                  type="button"
                  className="text-center srp-button srp-button__outline__white mx-2 mb-2"
                  onClick={() => {
                    toggleReset();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <p>
          <Link
            to={
              role === 'advisor'
                ? `${rootRoute(location.pathname)}/investments`
                : '/business-owners/investments'
            }
            className="text-white"
          >
            View investment lineup
          </Link>
        </p>
      </div>
    </div>
  );
};

PlanOutLine.propTypes = {
  selections: PropTypes.any.isRequired,
  questions: PropTypes.object.isRequired,
  notificationIndicator: PropTypes.bool.isRequired,
  setNotificationIndicator: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
  hashId: PropTypes.string,
  role: PropTypes.string,
  costEstimator: PropTypes.any.isRequired,
};

export default PlanOutLine;
