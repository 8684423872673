import React from 'react';
import PropTypes from 'prop-types';

const IconUserMarkerO = ({ fill }) => (
  <path
    d="M15.294 0C6.89 0 0 6.944 0 15.417c0 2.57.62 5.139 1.86 7.36l.069.14 12.607 16.736c.138.208.414.347.69.347.275 0 .55-.139.688-.347L28.66 22.917l.07-.14c1.24-2.221 1.86-4.79 1.86-7.36C30.588 6.944 23.698 0 15.293 0zm0 37.57L5.236 24.235c.069-.07.138-.139.138-.208.55-.972 1.24-1.806 2.066-2.361 2.067-1.39 4.685-2.014 7.854-2.014 3.238 0 5.787.694 7.854 2.014a6.104 6.104 0 0 1 2.136 2.57l-9.99 13.332zm-5.58-25.348c0-3.125 2.48-5.625 5.58-5.625s5.58 2.5 5.58 5.625-2.48 5.625-5.58 5.625-5.58-2.57-5.58-5.625zm17.43 9.653l-.62.833c-.62-1.041-1.447-1.944-2.412-2.5a12.96 12.96 0 0 0-4.615-1.875 7.414 7.414 0 0 0 3.1-6.041c0-4.098-3.307-7.43-7.372-7.43-4.064 0-7.371 3.332-7.371 7.43 0 2.5 1.24 4.722 3.1 6.041a12.96 12.96 0 0 0-4.616 1.875c-.896.625-1.722 1.528-2.342 2.57l-.551-.903a13.77 13.77 0 0 1-1.585-6.458c0-7.5 6.063-13.611 13.503-13.611s13.503 6.11 13.503 13.61c-.069 2.223-.62 4.445-1.722 6.459z"
    fill={fill}
    className="fill"
  />
);

IconUserMarkerO.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconUserMarkerO;
