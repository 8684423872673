export const checkNpnValue = (npnValue) => {
  switch (true) {
    case true:
      return !(npnValue.length >= 4 && npnValue.length <= 10);
    case npnValue.length > 0:
      return !(npnValue.length >= 4 && npnValue.length <= 10);
    default:
      return npnValue.length < 0;
  }
};
