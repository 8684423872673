import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AppRoute from '../../AppRoute';

// Layouts
import ApplicationLayout from '../ApplicationLayout';

// Pages
import Home from './FinancialProfessionals';
import AdvisorsAbout from './AdvisorsAbout';
import AdvisorWhySimplyRetirement from './AdvisorWhySimplyRetirement';
import AdvisorRetirementPlans from './AdvisorRetirementPlans';
import AdvisorResources from './AdvisorResources';
import AdvisorsFAQ from './Faq';
import Investments from './Investments';
import Disclosures from '../Disclosures';
import Sitemap from '../Sitemap';
import PlanProposal from './PlanProposal';
import ManageCookiesPreferences from '../ManageCookiesPreferences';

const FinancialProfessionals = ({ paradigmHost }) => {
  const { url } = useRouteMatch();
  const financialProfessionalsRoutes = [
    {
      key: 'financial-professionals',
      exact: true,
      component: Home,
      title: 'Small Business Retirement Plan',
      description:
        'With Simply Retirement by Principal<small><sup>®</sup></small>, financial professionals can quickly and easily set up affordable small business retirement plans.',
    },
    {
      key: 'about',
      exact: true,
      component: AdvisorsAbout,
      title: 'Small Business 401k Market',
      description:
        'Grow your small business retirement plan options with Simply Retirement by Principal<small><sup>®</sup></small>. Create and share a proposal today.',
    },
    {
      key: 'why-simply-retirement',
      exact: true,
      component: AdvisorWhySimplyRetirement,
      title: '401k for small business owners',
      description:
        'Its hard to find the right 401k for small business owners. Simply Retirement by Principal<small><sup>®</sup></small> has made it simple and affordable. Create a proposal today.',
    },
    {
      key: 'retirement-plans',
      exact: true,
      component: AdvisorRetirementPlans,
      title: 'Low Cost 401k For Small Business Owners',
      description:
        'Meet Simply Retirement by Principal. Learn more this low cost 401(k) plan for all small business owners and create a proposal today.',
    },
    {
      key: 'plan-proposal',
      component: PlanProposal,
      title: 'PlanProposal for Setting Up A Small Business 401k Plan',
      description:
        'Simply Retirement by Principal<small><sup>®</sup></small> helps you set up a 401(k) plan for your small business. Learn more about retirement plans and how you can help your employees most.',
    },
    {
      key: 'investments',
      exact: true,
      component: Investments,
      title: '401k Program For Small Business',
      description:
        'Grow your small business retirement plan options with Simply Retirement by Principal<small><sup>®</sup></small>. Create and share a proposal today.',
    },
    {
      key: 'faq',
      exact: true,
      component: AdvisorsFAQ,
      title: 'Frequently Asked Questions',
      description:
        'Read some of the frequently asked questions about Simply Retirement by Principal<small><sup>®</sup></small>. Contact us with any additional questions about our small business 401k plans.',
    },
    {
      exact: true,
      key: 'disclosures',
      layout: ApplicationLayout,
      component: Disclosures,
      title: 'Disclosures',
    },
    {
      exact: true,
      key: 'manage-cookie-settings',
      layout: ApplicationLayout,
      component: ManageCookiesPreferences,
      title: 'Manage Cookie Preferences',
    },
    {
      exact: true,
      key: 'sitemap',
      layout: ApplicationLayout,
      component: Sitemap,
      title: 'Sitemap',
    },
  ];

  return (
    <Switch>
      {financialProfessionalsRoutes.map((route) => {
        const keyPath = url === `/${route.key}` ? url : `${url}/${route.key}`;
        return (
          <AppRoute
            key={route.key}
            path={`${keyPath}`}
            layout={ApplicationLayout}
            paradigmHost={paradigmHost}
            {...route}
          />
        );
      })}
      <Route path="/financial-professionals/resources">
        <AdvisorResources paradigmHost={paradigmHost} />
      </Route>
      <AppRoute
        layout={ApplicationLayout}
        component={Home}
        title="401k Plans For Small Business"
        description="With Simply Retirement by Principal<small><sup>®</sup></small>, financial professionals can quickly and easily set up affordable small business retirement plans."
        paradigmHost={paradigmHost}
      />
    </Switch>
  );
};

FinancialProfessionals.propTypes = {
  paradigmHost: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  hashId: PropTypes.string,
};

export default FinancialProfessionals;
