import { getServiceEligibility } from '../ParadigmUtilities';
import { setBundledPlanAddSources } from './setBundledPlanAddSources';

export const paradigmPackagerBundled = async (planDesign, userRole) => {
  const {
    autoEnrollment: { value: enrollment },
    autoDeferralType,
    employerContributionMatch,
    employerMatch,
    employerStateCode,
    hardshipWithdrawals: { value: hardships },
    insuranceGroupType,
    safeHarbor,
    serviceEligibility,
    stretchSafeHarbor,
    vestingSchedule: { value: defaultPlanVesting },
  } = planDesign.questions;

  const bundledParadigmPlanData = {
    productCode: 'simplyrtmt',
    autoEnrollment: enrollment,
    allowsLoans: true,
    allowsHardshipWithdrawals: hardships === 'yes',
    entry: 'DAILY',
    requiredAge: {
      month: 0,
      year: 21,
    },
    serviceEligibility: await getServiceEligibility(serviceEligibility),
  };

  if (enrollment === 'yes') {
    bundledParadigmPlanData.minimumAutoDeferralPercentage =
      planDesign.questions.minimumAutoDeferralPercentage.value;
    bundledParadigmPlanData.autoDeferralType = autoDeferralType.value;
    bundledParadigmPlanData.autoEnrollmentType = 'EACA';
    bundledParadigmPlanData.maximumAutoDeferralPercentage =
      autoDeferralType.value === 'ESCALATING' ? 10 : 0;
    bundledParadigmPlanData.yearlyAutoDeferralPercentageIncrease =
      autoDeferralType.value === 'ESCALATING' ? 1 : 0;
  } else {
    bundledParadigmPlanData.minimumAutoDeferralPercentage = 0;
    bundledParadigmPlanData.maximumAutoDeferralPercentage = 0;
    bundledParadigmPlanData.yearlyAutoDeferralPercentageIncrease = 0;
  }

  bundledParadigmPlanData.addSources = setBundledPlanAddSources(
    employerContributionMatch,
    safeHarbor,
    stretchSafeHarbor,
    employerMatch,
    userRole,
    defaultPlanVesting,
    planDesign.questions,
  );

  if (employerStateCode.value === 'TX') {
    bundledParadigmPlanData.attributes = [
      {
        key: 'INSURANCE_GROUP_TYPE',
        value: parseInt(insuranceGroupType?.valueFormatted, 10),
      },
    ];
  }

  return bundledParadigmPlanData;
};
