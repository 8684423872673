import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectRole,
  selectEmployerQuestions,
  selectAdvisorQuestions,
  selectTpaQuestions,
} from '../../../Redux/FormDataRedux/index';
import PlanSelections from './PlanSelections';
import PrintPlanTable from './PrintPlanTable';
import TpaInfoCard from '../../../common/TpaInfoCard';
import AdvisorInfoCard from '../../../common/AdvisorInfoCard';
import ReviewPlanControls from '../ReviewPlanControls';
import { checkValidityOnQuestionArray } from '../../../common/FormWithSteps/Utilities';

const SectionPlanReview = ({
  userRole,
  questions,
  goToRoleSelection,
  putActiveStep,
  advisorInfo,
  tpaInfo,
  savePlanStatus,
  employerInfo,
  createSavedForm,
  planOptionQuestions,
  contributionPlanOptionQuestions,
}) => {
  const requireFinancialProfessionalsTab =
    questions.hasFp.value && !checkValidityOnQuestionArray(advisorInfo);

  return (
    <section
      id="planSelections"
      className="section pt-0 pb-5 print-break-after"
    >
      <div className="container-xlg d-print-none">
        <div className="row">
          <div className="col-lg-10 col-xl-9 mx-auto">
            <div className="row principal-card py-5 default">
              <div className="col-lg-10 mx-auto">
                {questions.hasTpa.value && (
                  <TpaInfoCard goToRoleSelection={goToRoleSelection} />
                )}
                {userRole !== 'anonymous' && questions.hasFp.value && (
                  <AdvisorInfoCard goToRoleSelection={goToRoleSelection} />
                )}
                {requireFinancialProfessionalsTab && (
                  <p className="text-muted">
                    <em>Some financial professional information is missing.</em>
                  </p>
                )}

                <PlanSelections
                  goToRoleSelection={goToRoleSelection}
                  questions={questions}
                  putActiveStep={putActiveStep}
                  planOptionQuestions={planOptionQuestions}
                  contributionPlanOptionQuestions={
                    contributionPlanOptionQuestions
                  }
                  userRole={userRole}
                  advisorInfo={advisorInfo}
                  tpaInfo={tpaInfo}
                  savePlanStatus={savePlanStatus}
                  employerInfo={employerInfo}
                  createSavedForm={createSavedForm}
                />
                <div className="my-3 text-md-right text-center d-print-none">
                  <ReviewPlanControls
                    savePlanStatus={savePlanStatus}
                    userRole={userRole}
                    employerInfo={employerInfo}
                    advisorInfo={advisorInfo}
                    tpaInfo={tpaInfo}
                    createSavedForm={createSavedForm}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-none d-print-block">
        {questions.hasTpa.value && (
          <TpaInfoCard goToRoleSelection={goToRoleSelection} />
        )}
        {userRole !== 'anonymous' && questions.hasFp.value && (
          <AdvisorInfoCard goToRoleSelection={goToRoleSelection} />
        )}
      </div>
      <PrintPlanTable
        planOptionQuestions={planOptionQuestions}
        contributionPlanOptionQuestions={contributionPlanOptionQuestions}
      />
    </section>
  );
};

SectionPlanReview.propTypes = {
  userRole: PropTypes.string.isRequired,
  questions: PropTypes.object.isRequired,
  goToRoleSelection: PropTypes.func.isRequired,
  putActiveStep: PropTypes.func.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  savePlanStatus: PropTypes.string.isRequired,
  employerInfo: PropTypes.array.isRequired,
  createSavedForm: PropTypes.func.isRequired,
  planOptionQuestions: PropTypes.object.isRequired,
  contributionPlanOptionQuestions: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  userRole: selectRole(store),
  formData: store.formData,
  questions: store.formData.planDesign.questions,
  employerInfo: selectEmployerQuestions(store),
  advisorInfo: selectAdvisorQuestions(store),
  tpaInfo: selectTpaQuestions(store),
});

const mapDispatchToProps = (dispatch) => ({
  putActiveStep: (formId, updateStep) => {
    dispatch(FormDataRedux.putActiveStep(formId, updateStep));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionPlanReview);
