import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectEmployerQuestions,
  selectAdvisorQuestions,
  selectApplicationPanelInfo,
  selectAdvisors,
  selectTpaQuestions,
  isFormValid,
} from '../../../Redux/FormDataRedux';
import {
  NextStepBtn,
  checkValidityOnQuestionArray,
  restrictedState,
} from '../../../common/FormWithSteps';
import {
  pushGoogleDataLayer,
  checkForErrors,
  restrictedStateInsuranceAgent,
} from '../../../common/FormWithSteps/Utilities';
import EmployerForm from './EmployerForm';
import SkipEmployerBtn from '../../../common/SkipEmployerBtn';

const EmployerQuestions = ({
  role,
  active,
  questions,
  previousStep,
  updateActiveStep,
  updateQuestion,
  history,
  employerInfo,
  advisorInfo,
  hashId,
  applicationPanelInfo,
  putApplicationPanelInfo,
  advisors,
  updateAdvisor,
  tpaInfo,
  formData,
  setPlanOriginator,
  isValid,
  putInsuranceAgent,
  putInsuranceAgentNpn,
}) => {
  const { hasFp } = formData.planDesign.questions;
  const { brokerType } =
    formData.planDesign.advisors[0].advisorCredentialVerification;
  const brokerTypeFp = hasFp.value ? brokerType : '';

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChangeState = (id, updateValue, valueFormatted) => {
    const { insuranceGroupType } = questions;

    handleQuestionChange(id, updateValue, valueFormatted);
    advisors.forEach((advisor, index) => {
      const { floridaLicenseNumber } = advisor.questions;
      updateAdvisor(
        {
          name: floridaLicenseNumber.name,
          value: floridaLicenseNumber.value,
          hasError: updateValue === 'FL' ? !floridaLicenseNumber.value : false,
          dirty: true,
          valueFormatted: floridaLicenseNumber.value,
        },
        index,
      );
    });
    updateQuestion({
      name: insuranceGroupType.name,
      value: insuranceGroupType.value,
      valueFormatted: insuranceGroupType.valueFormatted,
      hasError: updateValue === 'TX' ? !insuranceGroupType.value : false,
      dirty: true,
    });
    if (resetFpInsuranceAgent) handleRestrictedInsuranceStateChange();
  };

  const showStateErrorMessage =
    (restrictedState(questions.employerStateCode.value) &&
      !checkValidityOnQuestionArray(advisorInfo)) ||
    restrictedStateInsuranceAgent(questions.employerStateCode.value);

  const handleRestrictedInsuranceStateChange = () => {
    putInsuranceAgent({ insuranceAgent: '' }, 0);
    putInsuranceAgentNpn({ insuranceAgentNpn: '' }, 0);
  };

  const resetFpInsuranceAgent =
    hasFp.value && brokerTypeFp === 'insuranceAgent' && formData.hashId !== '';

  const showWorkingWith =
    formData.planOriginator === '' ||
    formData.planOriginator.role === 'employer';

  const stateErrorMessage = () => {
    const { employerStateCode, insuranceGroupType } = questions;

    switch (true) {
      case employerStateCode.value === 'GA':
        return 'Your state requires a financial professional to set up a 401(k) plan. Please provide their contact information in the next step.';

      case employerStateCode.value === 'TX' &&
        insuranceGroupType.valueFormatted === '':
        return 'Your state requires a financial professional to set up a 401(k) plan. Please provide their contact information in the next step. An insurance group type must be selected.';

      case employerStateCode.value === 'FL':
        return 'A financial professional with a valid Florida license is required to set up a 401(k) plan. Please provide their contact information in the next step.';

      case restrictedStateInsuranceAgent(employerStateCode.value) &&
        hasFp.value &&
        brokerType === 'insuranceAgent':
        return "Be careful, in the state you're in, you will not be able to continue with this plan because you are working with an insurance agent.";
      default:
        return '';
    }
  };

  const submitContactInfo = async () => {
    const { sponsorFirstName, sponsorLastName, sponsorEmail } = questions;

    if (formData.planOriginator === '') {
      const planOriginator = {
        name: `${sponsorFirstName.value} ${sponsorLastName.value}`,
        email: sponsorEmail.value,
        role: 'employer',
      };
      setPlanOriginator(planOriginator);
    }
  };

  const destination = (step) => {
    const { hasTpa, employerPaidParticipantFees } = questions;
    tpaInfo.push(employerPaidParticipantFees);
    if (hasTpa.value) {
      if (checkValidityOnQuestionArray(tpaInfo) && isValid) {
        if (role === 'employer' && hasFp.value === false) {
          updateActiveStep('employerPaidParticipantFees', 'forward');
          return false;
        }
        history.push('/review-plan');
        return false;
      }
      if (hasFp.value) {
        updateActiveStep('shareForm', 'forward');
        return false;
      }
      updateActiveStep('employerPaidParticipantFees', 'forward');
      return false;
    }
    updateActiveStep(step, 'forward');
    return true;
  };

  const nextStepAction = () => {
    const { employerStateCode } = questions;

    submitContactInfo();
    pushGoogleDataLayer({
      event: 'PlanDesignQuestion',
      PlanDesignEmployerState: employerStateCode.value,
    });
    if (restrictedState(employerStateCode.value)) {
      if (checkValidityOnQuestionArray(advisorInfo)) {
        destination('hasRetirementPlan');
        return false;
      }
      if (hasFp.value) {
        destination('hasRetirementPlan');
        return false;
      }
      history.push({
        pathname: '/requires-license',
      });
      return false;
    }
    if (hashId !== '') {
      destination('step_1');
    } else destination('hasRetirementPlan');
    return false;
  };

  useEffect(() => {
    pushGoogleDataLayer({
      event: 'PlanDesignView',
      PlanDesignView: 'info_employer',
    });
  }, []);

  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });

  return (
    <div className="container-fluid--sitewidth h-100">
      <div className="row h-100">
        <div className="col-md-10 d-flex flex-column justify-content-between mx-auto my-md-auto">
          <h3 className="fs-form-q text-center pb-5 px-3">
            Tell us about yourself.
          </h3>
          <p className="text-center">
            This information is used to create legal documents. Please ensure it
            is correct.
          </p>
          <EmployerForm
            role={role}
            advisorInfo={advisorInfo}
            putApplicationPanelInfo={putApplicationPanelInfo}
            questions={questions}
            handleQuestionChange={handleQuestionChange}
            handleQuestionChangeState={handleQuestionChangeState}
            showStateErrorMessage={showStateErrorMessage}
            stateErrorMessage={stateErrorMessage}
            showWorkingWith={showWorkingWith}
          />
          <div className="fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(employerInfo)}
              onClick={() => {
                nextStepAction();
              }}
            />
          </div>
          <SkipEmployerBtn />
        </div>
      </div>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          if (hashId !== '') {
            updateActiveStep('returnRoleSelection', 'back');
          } else updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

EmployerQuestions.propTypes = {
  formData: PropTypes.object.isRequired,
  role: PropTypes.string,
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  history: PropTypes.object,
  employerInfo: PropTypes.array.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  hashId: PropTypes.string,
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  advisors: PropTypes.array.isRequired,
  updateAdvisor: PropTypes.func.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  setPlanOriginator: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  putInsuranceAgent: PropTypes.func.isRequired,
  putInsuranceAgentNpn: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  employerInfo: selectEmployerQuestions(store),
  advisorInfo: selectAdvisorQuestions(store),
  applicationPanelInfo: selectApplicationPanelInfo(store),
  advisors: selectAdvisors(store),
  tpaInfo: selectTpaQuestions(store),
  isValid: isFormValid(store),
});
const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  updateAdvisor: (obj, index) => {
    dispatch(FormDataRedux.updateAdvisor(obj, index));
  },
  setPlanOriginator: (planOriginator) => {
    dispatch(FormDataRedux.setPlanOriginator(planOriginator));
  },
  putInsuranceAgent: (obj, index) => {
    dispatch(FormDataRedux.putInsuranceAgent(obj, index));
  },
  putInsuranceAgentNpn: (obj, index) => {
    dispatch(FormDataRedux.putInsuranceAgentNpn(obj, index));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployerQuestions),
);
