import React from 'react';
import PropTypes from 'prop-types';
import { asOfDate } from '../../../../Services/utilities';

const Pricing = ({ putApplicationPanelInfo }) => (
  <div className="py-4 px-5">
    <p className="fs-p fw-bold mb-0">Pricing for our solution</p>
    <p className="fs-h2">
      The Simply Retirement by Principal
      <small>
        <sup>®</sup>
      </small>{' '}
      401(k) plan has a simple flat recordkeeping fee structure.
    </p>
    <p className="fs-p">
      You pay $185 per month ($555 billed quarterly). If you choose to pay the
      $6 per participant, per month fee on your employees’ behalf, this will
      also be billed quarterly, three months at a time. Otherwise, it will be
      deducted from participants’ plan assets. Custodial and investment fees are
      charged against participating employees’ accounts (those vary by
      investment and range from{' '}
      <button
        type="button"
        onClick={() =>
          putApplicationPanelInfo({
            hasVisibility: true,
            additionalInformation: `<p class="fs-p">As part of the Simply Retirement by Principal<small><sup>®</sup></small> solution, Wilshire Investments, Inc. is the fiduciary responsible for the selection and monitoring of the investments.</p>`,
          })
        }
        className="text-underline p-0 no-border-button"
      >
        0.03% – 0.86%
      </button>
      ,
      <sup>
        <small>*</small>
      </sup>{' '}
      as of {asOfDate}).
    </p>
  </div>
);

Pricing.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

export default Pricing;
