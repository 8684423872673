const date = new Date();

export const planEffectiveDate = {
  id: 17,
  stepName: 'step_14',
  type: 'date',
  name: 'planEffectiveDate',
  user: {
    onedigital: {
      label:
        'When do you want the ability for contributions to be made to the plan?',
      heading: 'Plan Effective Date',
      content:
        'The date you enter here will help us create your 401(k) plan with an accurate effective date. The plan effective date must be on or before the date when the first 401(k) contributions are withheld from employee paychecks.',
    },
    employer: {
      label:
        'When do you want the ability for contributions to be made to the plan?',
      heading: 'Plan Effective Date',
      content:
        'The date entered here will help us create the 401(k) plan with an accurate effective date. The plan effective date must be on or before the date when the first 401(k) contributions are withheld from employee paychecks. The plan effective date can be any date that is at least 21 days or more from the date the purchase process is initiated.',
    },
    advisor: {
      label:
        'When does the business owner want the ability for contributions to be made to the plan?',
      heading: 'Plan Effective Date',
      content:
        'The date entered here will help us create the 401(k) plan with an accurate effective date. The plan effective date must be on or before the date when the first 401(k) contributions are withheld from employee paychecks. The plan effective date can be any date that is at least 21 days or more from the date the purchase process is initiated.',
    },
    tpa: {
      label:
        'When does the business owner want the ability for contributions to be made to the plan?',
    },
    anonymous: {
      label:
        'When do you want the ability for contributions to be made to the plan?',
      heading: 'Plan Effective Date',
      content:
        'The date entered here will help us create the 401(k) plan with an accurate effective date. The plan effective date must be on or before the date when the first 401(k) contributions are withheld from employee paychecks. The plan effective date can be any date that is at least 21 days or more from the date the purchase process is initiated.',
    },
  },
  inputLabel: 'Plan effective date',
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  valueFormatted: '',
  hasConditional: false,
  hasError: false,
  reviewScreenTitle: 'Plan effective date',
  sideCardTitle: 'Plan effective date',
  description:
    'The plan effective date is the date on which eligible employees may begin participating in the plan.',
  info: `This date is passed the current year deadline for establishing a safe harbor plan. If you select a safe harbor plan, you must select a plan effective date no earlier than January 1, ${
    date.getFullYear() + 1
  }. <strong>For questions please call 844-804-0604.</strong>`,
  infoDate: `January 1, ${date.getFullYear() + 1}`,
};
