import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  toggleModalVisibility: ['toggle'],
});

export const SignUpModalTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  modalVisibility: false,
};

/* ------------- Reducers ------------- */

const toggleModalVisibility = (state, { toggle }) => ({
  ...state,
  modalVisibility: toggle,
});

/* ------------- Hookup Reducers To Types ------------- */

export const signUpModalReducer = createReducer(INITIAL_STATE, {
  [Types.TOGGLE_MODAL_VISIBILITY]: toggleModalVisibility,
});
