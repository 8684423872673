import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import FormDataRedux, { selectRole } from '../../Redux/FormDataRedux';
import {
  CloseForm,
  Question,
  checkValidityOnQuestionArray,
} from '../../common/FormWithSteps';
import EmployerFormSection from '../../common/EmployerFormSection';
import { pushGoogleDataLayer } from '../../common/FormWithSteps/Utilities';
import { forms, pages } from '../data';

export const ExploreOptions = ({
  history,
  role,
  location,
  sponsorFirstName,
  sponsorLastName,
  sponsorEmail,
  companyName,
  companyPhone,
  employerStateCode,
  formData,
  putQuestionById,
}) => {
  const lastLocation = useLastLocation();
  const handleClose = () => {
    if (lastLocation) {
      history.goBack();
    } else {
      history.push('/');
    }
  };
  const pageKey = 'exploreOptions';
  const additionalInputs = Object.values(formData.planDesign.questions).filter(
    (question) => forms[pageKey].inputs.includes(question.name),
  );
  const inputsToValidate = [
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    companyName,
    companyPhone,
    employerStateCode,
    formData.planDesign.questions.companyPhone,
    formData.planDesign.questions.zipCode,
    ...additionalInputs,
  ];
  const { currentPlan } = formData.planDesign.questions;
  const searchParams = new URLSearchParams(location.search);
  const isDisabled = !checkValidityOnQuestionArray(inputsToValidate);
  const renderResponse = () => {
    switch (searchParams.get('response')) {
      case 'success':
        return (
          <div className="text-success fs-h3 text-center">
            {forms[pageKey].responseSuccessMsg}
          </div>
        );
      case 'error':
        return (
          <div className="text-warning fs-h3 text-center">
            {forms[pageKey].responseErrorMsg}
          </div>
        );
      default:
        return false;
    }
  };
  const [status, setStatus] = useState('');
  const refForm = useRef('');
  const submitForm = async (e) => {
    e.preventDefault();
    // Previously POST to Pardot -- has been removed
    // Form is broken and needs to be fixed
    setStatus('error');
  };

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    putQuestionById(
      formData.planDesign.formId,
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };
  return (
    <div className="fws fws_dark">
      <div className="fws__wrapper h-md-100">
        <CloseForm handleClose={handleClose} light />
        <div className="container h-100">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div
                dangerouslySetInnerHTML={{
                  __html: pages[pageKey].content,
                }}
              />
              {status === 'error' ? (
                <div className="text-warning fs-h3 text-center">
                  {forms[pageKey].responseErrorMsg}
                </div>
              ) : (
                ''
              )}
              {searchParams.has('response') && renderResponse()}
              <form
                ref={refForm}
                id={forms[pageKey].id}
                action={forms[pageKey].action}
                method={forms[pageKey].method}
              >
                <EmployerFormSection />
                <div className="row mb-4">
                  <div className="col-md-6">
                    <label
                      className="fs-form-label text-white fw-bold"
                      htmlFor="current_plan"
                    >
                      {currentPlan.label}
                    </label>
                    <select
                      id="current_plan"
                      name="current_plan"
                      value={currentPlan.value}
                      className="custom-select srp-form-input form-control form-select"
                      onChange={(selected) => {
                        handleQuestionChange(
                          currentPlan.name,
                          selected.target.value,
                          selected.target.value,
                        );
                      }}
                    >
                      <option>Select</option>
                      {currentPlan.options.map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {currentPlan.hasError && (
                      <small>Question is required</small>
                    )}
                  </div>
                </div>

                {additionalInputs.map((question) => (
                  <div key={question.name} className="row mb-4">
                    <div className="col-md-6">
                      <Question
                        role={role}
                        uniqueId="current_plan"
                        type={question.type}
                        labelClass="fs-form-label fw-bold text-white"
                        inputClass="custom-select srp-form-input form-control form-select"
                        question={question}
                        handleQuestionChange={handleQuestionChange}
                      />
                    </div>
                  </div>
                ))}

                <div
                  dangerouslySetInnerHTML={{
                    __html: forms[pageKey].disclaimer,
                  }}
                />
                <p className="text-center">
                  <button
                    disabled={isDisabled || status === 'loading'}
                    className="srp-button srp-button__large srp-button__primary"
                    type="submit"
                    onClick={(e) => {
                      setStatus('loading');
                      submitForm(e);

                      pushGoogleDataLayer({
                        event: 'PlanDesignQuestion',
                        PlanDesignExistingPlanCategory:
                          formData.planDesign.questions.currentPlan.value.value,
                      });
                    }}
                  >
                    Contact us
                  </button>
                </p>
              </form>
            </div>
          </div>
          {lastLocation && (
            <button
              type="button"
              className="goback fa-solid fa-arrow-left text-white"
              onClick={() => {
                history.replace({
                  pathname: lastLocation.pathname,
                });
              }}
            >
              <span className="sr-only">Go Back</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

ExploreOptions.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  sponsorFirstName: PropTypes.object.isRequired,
  sponsorLastName: PropTypes.object.isRequired,
  sponsorEmail: PropTypes.object.isRequired,
  companyName: PropTypes.object.isRequired,
  companyPhone: PropTypes.object.isRequired,
  employerStateCode: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  putQuestionById: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  role: selectRole(store),
  formData: store.formData,
  sponsorFirstName: store.formData.planDesign.questions.sponsorFirstName,
  sponsorLastName: store.formData.planDesign.questions.sponsorLastName,
  sponsorEmail: store.formData.planDesign.questions.sponsorEmail,
  companyName: store.formData.planDesign.questions.companyName,
  companyPhone: store.formData.planDesign.questions.companyPhone,
  employerStateCode: store.formData.planDesign.questions.employerStateCode,
});

const mapDispatchToProps = (dispatch) => ({
  putQuestionById: (
    formId,
    questionId,
    value,
    hasError,
    dirty,
    valueFormatted,
  ) => {
    dispatch(
      FormDataRedux.putQuestionById(
        formId,
        questionId,
        value,
        hasError,
        dirty,
        valueFormatted,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExploreOptions);
