import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  selectHashId,
  selectRole,
  selectEmployerQuestions,
  selectAdvisorQuestions,
  selectTpaQuestions,
  selectPlanOriginator,
} from '../../../Redux/FormDataRedux/index';
import { checkValidityOnQuestionArray } from '../../../common/FormWithSteps/Utilities';

import ToastRedux from '../../../Redux/ToastRedux';
import PrintIcon from '../../../../Images/icon-print.svg';
import SaveIcon from '../../../../Images/icon-save.svg';

// import API from '../../../Services/api';
//
// const api = API.create();

const ReviewPlanControls = ({
  userRole,
  savePlanStatus,
  createSavedForm,
  employerInfo,
  advisorInfo,
  tpaInfo,
  planOriginator,
}) => {
  const validEmployer = checkValidityOnQuestionArray(employerInfo);
  const validAdvisor = checkValidityOnQuestionArray(advisorInfo);
  const validTPA = checkValidityOnQuestionArray(tpaInfo);
  const canSave = () => {
    switch (userRole) {
      case 'anonymous':
        return false;
      case 'employer':
      case 'psivet':
        return validEmployer;
      case 'advisor':
        return validAdvisor;
      case 'tpa':
        return validTPA;
      default:
        return false;
    }
  };

  return (
    <div>
      {canSave() && (
        <button
          type="button"
          className="srp-button srp-button__outline mx-2 mb-2 hvr-icon-push"
          onClick={() => {
            createSavedForm();
          }}
        >
          {savePlanStatus === 'loading' ? (
            <span
              className="spinner-border spinner-border-sm icon"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <img src={SaveIcon} alt="" className="icon hvr-icon" />
          )}
          Save <span className="d-sm-inline d-none">this plan</span>
        </button>
      )}
      <button
        type="button"
        className="srp-button srp-button__outline mx-2 mb-2 hvr-icon-push"
        onClick={() => {
          if (typeof window !== 'undefined') {
            window.print();
          }
        }}
      >
        <img src={PrintIcon} alt="" className="icon hvr-icon" />
        Print <span className="d-sm-inline d-none">this plan</span>
      </button>
      {canSave() && planOriginator.role === userRole && (
        <p className="fs-sm text-muted">
          Save the plan now and you’ll get an email with a link to come back and
          make changes or purchase the plan. This link will be valid for 60
          days.
        </p>
      )}
    </div>
  );
};

ReviewPlanControls.propTypes = {
  savePlanStatus: PropTypes.string,
  userRole: PropTypes.string.isRequired,
  createSavedForm: PropTypes.func.isRequired,
  employerInfo: PropTypes.array.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  planOriginator: PropTypes.any.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  userRole: selectRole(store),
  hashId: selectHashId(store),
  questions: store.formData.planDesign.questions,
  employerInfo: selectEmployerQuestions(store),
  advisorInfo: selectAdvisorQuestions(store),
  tpaInfo: selectTpaQuestions(store),
  planOriginator: selectPlanOriginator(store),
});

const mapDispatchToProps = (dispatch) => ({
  addToast: (text, toastType) => {
    dispatch(ToastRedux.addToast(text, toastType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPlanControls);
