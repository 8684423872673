export const safeHarborDiscretionaryMatchingContributions = {
  id: 'q6ai3a1e',
  type: 'radio_condensed',
  name: 'safeHarborDiscretionaryMatchingContributions',
  paradigmAlias: 'hasDiscretionaryEmployerMatch',
  user: {},
  label:
    'In addition to the safe harbor contribution, will the plan allow for discretionary matching contributions?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      checked: false,
      icon: 'icon-check-o',
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      checked: false,
      icon: 'icon-close-o',
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Discretionary matching contributions',
  reviewScreenTitle: 'Discretionary matching contributions',
  optionDescription: '',
  description:
    'If the business owner chooses, the plan can allow for discretionary matching contributions with separate eligibility requirements and a separate vesting schedule.',
};
