import { googleData } from '../../../Services/utilities';
import EligibleEmployees from './EligibleEmployees';
import AverageSalary from './AverageSalary';
import EmployerPaidParticipantFees from './EmployerPaidParticipantFees';
import StepQuestion from './StepQuestion';
import EnableEmployerContribution from './EnableEmployerContribution';
import EmployerContributionMatch from './EmployerContributionMatch';
import AutoDeferralType from './AutoDeferralType';
import PlanEffectiveDate from './PlanEffectiveDate';
import ShareForm from './ShareForm';
import AutoEnrollment from './AutoEnrollment';
import SafeHarborQuestions from './SafeHarborQuestions';
import HardshipWithdrawals from './HardshipWithdrawals';
import VestingSchedule from './VestingSchedule';
import GrandfatherDate from './GrandfatherDate';

export const defaultComponents = {
  EligibleEmployees,
  AverageSalary,
  EmployerPaidParticipantFees,
  StepQuestion,
  EnableEmployerContribution,
  EmployerContributionMatch,
  AutoDeferralType,
  PlanEffectiveDate,
  ShareForm,
  AutoEnrollment,
  SafeHarborQuestions,
  HardshipWithdrawals,
  VestingSchedule,
  GrandfatherDate,
};

const step1PreviousStep = (formData, advisorIa, splitComp, role, hashId) => {
  if (role === 'advisor') {
    // if (splitComp && formData.planDesign.advisors.length > 1)
    //   return 'splitComp';
    // if (advisorIa) return 'compensation';
    return 'isCurrentPrincipalPlan';
  }
  if (formData.planDesign.questions.hasFp.value) {
    return hashId !== '' ? 'employerQuestions' : 'typeOfOrganization';
  }
  return 'roleSelection';
};

const step3NextStep = (formData, role) => {
  const { hasFp, hasTpa } = formData.planDesign.questions;
  if (hasTpa.value || (hasFp.value && role !== 'advisor')) return 'step_6';
  return 'step_4';
};

const step6PreviousStep = (formData, role) => {
  const { hasFp, hasGrandfatherDate, hasTpa } = formData.planDesign.questions;
  if (hasTpa.value || (hasFp.value && role !== 'advisor')) return 'step_3';
  return hasGrandfatherDate.value === 'yes' ? 'step_5' : 'step_4';
};

export const defaultFormSteps = (
  hashId,
  formData,
  lastLocation,
  role,
  advisorInfo,
  history,
  goToReviewPlan,
  employerContributionMatch,
  employerPaidParticipantFees,
  autoEnrollment,
  safeHarbor,
  isFirmBrokerSplitComp,
  isAdvisorIa,
) => [
  {
    hashId,
    hasSidebar: false,
    planOwner: formData.planOwner,
    createdDate: formData.createdDate,
    updatedDate: formData.updatedDate,
    key: 'begin',
    component: 'LetsBegin',
    googleData: googleData('plans'),
    destination: () => {
      if (lastLocation) {
        if (lastLocation.pathname === '/financial-professionals')
          return 'advisorCheckList';
      }
      if (hashId !== '') {
        return 'returnRoleSelection';
      }
      return 'roleSelection';
    },
  },
  {
    role,
    hashId,
    history,
    key: 'roleSelection',
    questionId: 'roleSelection',
    component: 'RoleSelection',
    hasSidebar: false,
    previousStep: 'begin',
    googleData: googleData('role_selection'),
  },
  {
    role,
    hashId,
    advisorInfo,
    history,
    key: 'returnRoleSelection',
    questionId: 'returnRoleSelection',
    component: 'ReturnRoleSelection',
    hasSidebar: false,
    previousStep: 'begin',
    googleData: googleData('return_role_selection'),
  },
  {
    role,
    hashId,
    history,
    key: 'userInfo',
    questionId: 'userInfo',
    component: 'UserInfo',
    hasSidebar: false,
    previousStep: 'returnRoleSelection',
    advisorInfo,
    googleData: googleData('user_info'),
  },
  {
    role,
    goToReviewPlan,
    history,
    key: 'shareForm',
    hasSidebar: false,
    component: 'ShareForm',
    previousStep: 'returnRoleSelection',
    googleData: googleData('shareForm'),
  },
  {
    role,
    hashId,
    key: 'step_1',
    showTracker: true,
    questionId: 'numberOfEmployees',
    component: 'EligibleEmployees',
    hasSidebar: false,
    singleSelection: true,
    destination: (isEligible, cb) => {
      if (!isEligible) {
        history.replace({
          pathname: '/larger-group',
        });
      }
      cb('step_2', 'forward');
    },
    goToReviewPlan,
    previousStep: step1PreviousStep(
      formData,
      isAdvisorIa,
      isFirmBrokerSplitComp,
      role,
      hashId,
    ),
    googleData: googleData(`eligible_employees_${role}`),
  },
  {
    role,
    key: 'step_2',
    showTracker: true,
    singleSelection: true,
    questionId: 'averageSalary',
    component: 'AverageSalary',
    hasSidebar: true,
    destination: () => 'step_3',
    goToReviewPlan,
    previousStep: 'step_1',
    googleData: googleData(`annual_salary_${role}`),
  },
  {
    role,
    key: 'step_3',
    showTracker: true,
    singleSelection: true,
    questionId: 'serviceEligibility',
    component: 'StepQuestion',
    hasSidebar: true,
    destination: () => step3NextStep(formData, role),
    goToReviewPlan,
    previousStep: 'step_2',
    googleData: googleData(`when_enrolled_${role}`),
  },
  {
    role,
    key: 'step_4',
    showTracker: true,
    singleSelection: true,
    questionId: 'hasGrandfatherDate',
    component: 'StepQuestion',
    hasSidebar: true,
    destination: () =>
      formData.planDesign.questions.hasGrandfatherDate.value === 'yes'
        ? 'step_5'
        : 'step_6',
    goToReviewPlan,
    previousStep: 'step_3',
    googleData: googleData(`has_grandfather_date_${role}`),
  },
  {
    role,
    key: 'step_5',
    showTracker: true,
    singleSelection: true,
    questionId: 'grandfatherDateHasCustomDate',
    component: 'GrandfatherDate',
    hasSidebar: true,
    destination: () => 'step_6',
    goToReviewPlan,
    previousStep: 'step_4',
    googleData: googleData(`grandfather_date_${role}`),
  },
  {
    role,
    key: 'step_6',
    showTracker: true,
    singleSelection: true,
    questionId: 'autoEnrollment',
    component: 'AutoEnrollment',
    hasSidebar: true,
    destination: (value) => (value === 'yes' ? 'step_7' : 'step_9'),
    goToReviewPlan,
    previousStep: step6PreviousStep(formData, role),
    // googleData: googleData(`when_enrolled_${role}`),
  },
  {
    role,
    key: 'step_7',
    showTracker: true,
    singleSelection: true,
    questionId: 'minimumAutoDeferralPercentage',
    component: 'EnableEmployerContribution',
    hasSidebar: true,
    destination: () => 'step_8',
    goToReviewPlan,
    previousStep: 'step_6',
    googleData: googleData(`default_contribution_${role}`),
  },
  {
    role,
    key: 'step_8',
    showTracker: true,
    singleSelection: true,
    questionId: 'autoDeferralType',
    component: 'AutoDeferralType',
    hasSidebar: true,
    destination: () => 'step_9',
    goToReviewPlan,
    previousStep: 'step_7',
    googleData: googleData(`deferral_type_${role}`),
  },
  {
    role,
    key: 'step_9',
    showTracker: true,
    singleSelection: true,
    questionId: 'employerPaidParticipantFees',
    component: 'EmployerPaidParticipantFees',
    hasSidebar: true,
    destination: () => 'step_10',
    goToReviewPlan,
    previousStep:
      formData.planDesign.questions.autoEnrollment.value === 'yes'
        ? 'step_8'
        : 'step_6',
    googleData: googleData(`pay_participant_fee_${role}`),
  },
  {
    role,
    key: 'step_10',
    showTracker: true,
    goToReviewPlan,
    questionId: 'employerContributionMatch',
    component: 'EmployerContributionMatch',
    hasSidebar: true,
    singleSelection: true,
    destination: (value) => (value === 'yes' ? 'step_11' : 'step_15'),
    previousStep: 'step_9',
    googleData: googleData(`contribution_match_${role}`),
  },
  {
    role,
    key: 'step_11',
    showTracker: true,
    mergedSelections: true,
    questionId: 'safeHarbor',
    component: 'SafeHarborQuestions',
    hasSidebar: true,
    destination: (value) => (value === 'nonSafeHarbor' ? 'step_13' : 'step_12'),
    goToReviewPlan,
    previousStep: 'step_10',
    googleData: googleData(`safe_harbor_${role}`),
  },
  {
    role,
    key: 'step_12',
    showTracker: true,
    questionId: 'stretchSafeHarbor',
    component: 'StepQuestion',
    hasSidebar: true,
    mergedSelections: true,
    goToReviewPlan,
    destination: () => 'step_15',
    previousStep: 'step_11',
    googleData: googleData(`stretch_safe_harbor_${role}`),
  },
  {
    role,
    key: 'step_13',
    showTracker: true,
    singleSelection: true,
    questionId: 'employerMatch',
    component: 'StepQuestion',
    hasSidebar: true,
    destination: () =>
      employerContributionMatch.value === 'yes' &&
      safeHarbor.value === 'nonSafeHarbor'
        ? 'step_14'
        : 'step_15',
    goToReviewPlan,
    previousStep: 'step_11',
  },
  {
    role,
    key: 'step_14',
    showTracker: true,
    singleSelection: true,
    questionId: 'vestingSchedule',
    component: 'VestingSchedule',
    hasSidebar: true,
    destination: () => 'step_15',
    goToReviewPlan,
    previousStep: 'step_13',
  },
  {
    role,
    key: 'step_15',
    showTracker: true,
    singleSelection: true,
    questionId: 'hardshipWithdrawals',
    component: 'HardshipWithdrawals',
    hasSidebar: true,
    destination: () => 'step_16',
    goToReviewPlan,
    previousStep: prevStepHardship(employerContributionMatch, safeHarbor),
  },
  {
    role,
    key: 'step_16',
    showTracker: true,
    questionId: 'planEffectiveDate',
    component: 'PlanEffectiveDate',
    hasSidebar: true,
    singleSelection: true,
    goToReviewPlan,
    previousStep: 'step_15',
    googleData: googleData(`plan_effective_date_${role}`),
  },
];

const prevStepHardship = (employerContributionMatch, safeHarbor) => {
  if (employerContributionMatch.value === 'no') {
    return 'step_10';
  }
  if (safeHarbor.value === 'nonSafeHarbor') {
    return 'step_14';
  }
  return 'step_12';
};
