import React from 'react';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectEmployerQuestions,
  selectAdvisorQuestions,
  selectTpaQuestions,
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
import {
  Question,
  checkValidityOnQuestionArray,
} from '../../../common/FormWithSteps';
import Dropdown from '../../../common/FormWithSteps/Question/Dropdown';

const EmployerQuestions = ({
  role,
  advisorInfo,
  tpaInfo,
  putApplicationPanelInfo,
  questions,
  handleQuestionChange,
  handleQuestionChangeState,
  showStateErrorMessage,
  stateErrorMessage,
  showWorkingWith,
}) => {
  const {
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    sponsorEmployerIdentificationNumber,
    companyName,
    sponsorTitle,
    companyPhone,
    employerStateCode,
    insuranceGroupType,
    hasFp,
    hasTpa,
  } = questions;

  return (
    <fieldset>
      <div className="row">
        <div className="col-md">
          <Question
            role={role}
            type={sponsorFirstName.type}
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={sponsorFirstName}
            handleQuestionChange={handleQuestionChange}
          >
            {sponsorFirstName.hasError && <small>Question is required</small>}
          </Question>
        </div>
        <div className="col-md">
          <Question
            role={role}
            type={sponsorLastName.type}
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={sponsorLastName}
            handleQuestionChange={handleQuestionChange}
          >
            {sponsorLastName.hasError && <small>Question is required</small>}
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <Question
            role={role}
            type={sponsorEmail.type}
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={sponsorEmail}
            handleQuestionChange={handleQuestionChange}
          >
            {sponsorEmail.hasError && <small>Question is required</small>}
          </Question>
        </div>
        <div className="col-md">
          <Question
            role={role}
            uniqueId="employerCompany"
            type={companyName.type}
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={companyName}
            handleQuestionChange={handleQuestionChange}
          >
            {companyName.hasError && <small>Question is required</small>}
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <Question
            type={sponsorTitle.type}
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={sponsorTitle}
            handleQuestionChange={handleQuestionChange}
          />
        </div>
        <div className="col-md">
          <div
            className={classNames('mb-3 form-group', {
              'has-error': companyPhone.hasError,
            })}
          >
            <label
              className="fs-form-label fw-bold"
              htmlFor={companyPhone.name}
            >
              {companyPhone.label}
            </label>
            <PatternFormat
              value={companyPhone.value}
              format="###-###-####"
              mask="_"
              className={classNames('srp-form-input form-control', {
                'is-invalid': companyPhone.hasError,
                'is-dirty': companyPhone.dirty,
              })}
              id={companyPhone.name}
              name={companyPhone.name}
              required
              onValueChange={(values) => {
                handleQuestionChange(
                  companyPhone.name,
                  values.value,
                  values.formattedValue,
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label
              className="fs-form-label fw-bold"
              htmlFor={employerStateCode.name}
            >
              Your company’s state of business
            </label>
            <select
              id={employerStateCode.name}
              name="state"
              autoComplete="address-level1"
              value={employerStateCode.value}
              className="custom-select srp-form-input form-control form-select"
              onChange={(selected) => {
                handleQuestionChangeState(
                  employerStateCode.name,
                  selected.target.value,
                  selected.target.value,
                );
              }}
            >
              <option value="select" disabled hidden>
                Select
              </option>

              {employerStateCode.options.map((option) => (
                <option key={option.id} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {showStateErrorMessage && (
              <p className="fs-disclaimer lh-125 mt-2 text-danger">
                {stateErrorMessage()}
              </p>
            )}
          </div>
        </div>
        {employerStateCode.value === 'TX' && (
          <div className="col-md">
            <Dropdown
              name={insuranceGroupType.name}
              label={insuranceGroupType.label}
              labelClass="fs-form-label fw-bold"
              inputClass="srp-form-dropdown form-control"
              options={insuranceGroupType.options}
              value={insuranceGroupType.value}
              required
              hasError={insuranceGroupType.hasError}
              handleQuestionChange={handleQuestionChange}
            />
          </div>
        )}
        <div className="col-md">
          <Question
            role={role}
            type={sponsorEmployerIdentificationNumber.type}
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={sponsorEmployerIdentificationNumber}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {sponsorEmployerIdentificationNumber.hasError && (
              <small>Question is required</small>
            )}
            <button
              type="button"
              className="srp-button__link"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'More info',
                  additionalInformation: `The Employer Identification Number (EIN) will be used as your business’ unique identifier.`,
                })
              }
            >
              More info
            </button>
          </Question>
        </div>
      </div>
      <hr />
      {showWorkingWith && (
        <div className="row">
          <div className="col-md-auto mx-auto">
            {!checkValidityOnQuestionArray(advisorInfo) && (
              <div className="mb-3 form-group">
                <span className="fs-form-label fw-bold">{hasFp.label}</span>
                <div className="my-3">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="hasFpOption1"
                      value={hasFp.options[0].value}
                      name={hasFp.name}
                      checked={hasFp.value === hasFp.options[0].value}
                      onChange={(input) => {
                        handleQuestionChange(
                          hasFp.name,
                          input.target.checked,
                          hasFp.options[0].label,
                        );
                      }}
                    />
                    <label
                      className="form-check-label mr-2"
                      htmlFor="hasFpOption1"
                    >
                      <strong>Yes</strong>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="hasFpOption2"
                      value={hasFp.options[1].value}
                      name={hasFp.name}
                      checked={hasFp.value === hasFp.options[1].value}
                      onChange={(input) => {
                        handleQuestionChange(
                          hasFp.name,
                          !input.target.checked,
                          hasFp.options[1].label,
                        );
                      }}
                    />
                    <label
                      className="form-check-label mr-2"
                      htmlFor="hasFpOption2"
                    >
                      <strong>No</strong>
                    </label>
                  </div>
                  <small>
                    <button
                      type="button"
                      className="srp-button__link"
                      onClick={() =>
                        putApplicationPanelInfo({
                          hasVisibility: true,
                          heading: 'Why are we asking?',
                          additionalInformation: `If you decide to purchase your plan, we’ll need to confirm that your financial professional is set up with Simply Retirement by Principal<sup><small>®</small></sup>. You’ll be asked to provide their contact information so we can follow up to complete this process.`,
                        })
                      }
                    >
                      Why are we asking?
                    </button>
                  </small>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {showWorkingWith && (
        <div className="row">
          <div className="col-md-auto mx-auto">
            {!checkValidityOnQuestionArray(tpaInfo) && (
              <div className="form-group">
                <span className="fs-form-label fw-bold">{hasTpa.label}</span>
                <div className="my-3">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="hasTpaOption1"
                      value={hasTpa.options[0].value}
                      name={hasTpa.name}
                      checked={hasTpa.value === hasTpa.options[0].value}
                      onChange={(input) => {
                        handleQuestionChange(
                          hasTpa.name,
                          input.target.checked,
                          hasTpa.options[0].label,
                        );
                      }}
                    />
                    <label
                      className="form-check-label mr-2"
                      htmlFor="hasTpaOption1"
                    >
                      <strong>Yes</strong>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="hasTpaOption2"
                      value={hasTpa.options[1].value}
                      name={hasTpa.name}
                      checked={hasTpa.value === hasTpa.options[1].value}
                      onChange={(input) => {
                        handleQuestionChange(
                          hasTpa.name,
                          !input.target.checked,
                          hasTpa.options[1].label,
                        );
                      }}
                    />
                    <label
                      className="form-check-label mr-2"
                      htmlFor="hasTpaOption2"
                    >
                      <strong>No</strong>
                    </label>
                  </div>
                  <small>
                    <button
                      type="button"
                      className="srp-button__link"
                      onClick={() =>
                        putApplicationPanelInfo({
                          hasVisibility: true,
                          heading: 'Why are we asking?',
                          additionalInformation: `Third party administrators (TPAs) help complete key plan details. If you're working with a TPA, you'll be asked to share your proposal with them in the next step.`,
                        })
                      }
                    >
                      Why are we asking?
                    </button>
                  </small>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </fieldset>
  );
};

EmployerQuestions.propTypes = {
  role: PropTypes.string,
  questions: PropTypes.object,
  advisorInfo: PropTypes.array.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  handleQuestionChangeState: PropTypes.func.isRequired,
  showStateErrorMessage: PropTypes.bool.isRequired,
  stateErrorMessage: PropTypes.func.isRequired,
  showWorkingWith: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  employerInfo: selectEmployerQuestions(store),
  advisorInfo: selectAdvisorQuestions(store),
  tpaInfo: selectTpaQuestions(store),
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerQuestions);
