import React from 'react';
import PropTypes from 'prop-types';

const AdvisorFirmBrokerNotice = ({ advisorFirmBroker }) => {
  if (advisorFirmBroker?.value?.notice) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: advisorFirmBroker?.value?.notice }}
      />
    );
  }
  return null;
};

AdvisorFirmBrokerNotice.propTypes = {
  advisorFirmBroker: PropTypes.shape({
    value: PropTypes.shape({
      notice: PropTypes.string,
    }),
  }),
};

export default AdvisorFirmBrokerNotice;
