export const entryDateDeferrals = {
  id: 'bQ3',
  type: 'radio_button',
  name: 'entryDateDeferrals',
  paradigmAlias: 'entryDatesPlanParticipation',
  user: {},
  label: 'What is the entry date for deferrals?',
  options: [
    {
      id: 1,
      user: {},
      label: 'Daily / Immediate',
      value: 'dailyImmediate',
      valueFormatted: 'Daily / Immediate',
      checked: false,
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: 'Monthly',
      info: '(First day of the month following the date the eligibility requirements are satisfied)',
      value: 'monthly',
      valueFormatted: 'Monthly',
      checked: false,
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: 'Quarterly',
      info: '(First day of the calendar quarter following the date the eligibility requirements are satisfied)',
      value: 'quarterly',
      valueFormatted: 'Quarterly',
      checked: false,
      customStyle: '',
    },
    {
      id: 4,
      user: {},
      label: 'Semi-annually (1/1 and 7/1)',
      value: 'semiAnnually',
      valueFormatted: 'Semi-annually (1/1 and 7/1)',
      checked: false,
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Deferrals entry date',
  reviewScreenTitle: 'Deferrals entry date',
  optionDescription: '',
  description:
    'This is the entry date for deferrals. Monthly is the first day of the month following the date the eligibility requirements are satisfied. Quarterly is the first day of the calendar quarter following the date the eligibility requirements are satisfied.',
};
