import React from 'react';
import PropTypes from 'prop-types';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

const ReviewPlanBtn = ({ disabled, goToReviewPlan }) => {
  const sendDataToGTM = useGTMDispatch();
  if (disabled) return false;

  return (
    <button
      type="button"
      className="srp-button srp-button__sm-large srp-button__success mt-3 mb-5 my-md-5"
      disabled={disabled}
      onClick={() => {
        sendDataToGTM({
          event: 'e_toolInteraction',
          eventType: 'progress',
          tool: {
            name: 'Simply Retirement',
            stepNumber: '2',
            stepName: 'review',
          },
        });
        goToReviewPlan();
      }}
    >
      <span className="d-none d-lg-block">
        Review your plan{' '}
        <i className="fa-solid fa-angle-right align-middle" aria-hidden />
      </span>
      <span className="d-lg-none">
        Review{' '}
        <i className="fa-solid fa-angle-right align-middle" aria-hidden />
      </span>
    </button>
  );
};

ReviewPlanBtn.propTypes = {
  disabled: PropTypes.bool.isRequired,
  goToReviewPlan: PropTypes.func.isRequired,
};

export default ReviewPlanBtn;
