import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { styles } from './Styles';
import { asOfDate, investmentCost } from '../../Services/utilities';

const currentYear = new Date().getFullYear();

export const Page2 = () => (
  <Page
    size="A4"
    style={[styles.page, { display: 'flex', justifyContent: 'space-between' }]}
    wrap
    orientation="portrait"
  >
    <View style={[styles.section, { marginTop: 'auto' }]}>
      <Image
        style={[styles.logo, { margin: 0, width: '2.25in' }]}
        src="/images/SimplyRetirement_Logo.png"
      />
    </View>
    <View style={[styles.section, { marginBottom: 40 }]}>
      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        *There is a flat monthly $6 recordkeeping fee per participating employee
        for the Simply Retirement by Principal
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>{' '}
        401(k) plan. The business owner, can pay this fee quarterly—or it can be
        deducted from each participating employee’s plan assets monthly.
      </Text>
      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        ¹Fees paid by the business owner are billed quarterly. Fees paid by
        participants are deducted monthly from participant accounts. Participant
        fees are charged if the individual balance is greater than $100 on the
        fee assessment date and regardless of whether the participant is active
        or inactive. Custodial and investment fees are charged against
        participating employees’ accounts (those vary by investment and range
        from {investmentCost}, as of {asOfDate}). Financial professional
        compensation and TPA fees are also additional and may be billed to the
        business owner or deducted from participant accounts.
      </Text>
      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        ²Antonelli (2020). What are the Potential Benefits of Universal Access
        to Retirement Savings? Georgetown University Center for Retirement
        Initiatives in conjunction with Econsult Solutions, Inc.
      </Text>
      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        ³Based on monthly plan sponsor recordkeeping fee. This fee is $145/month
        when working with a TPA, which assumes the TPA will provide select
        administrative services and bill their fees separately. Otherwise,
        bundled pricing is $185/month.
      </Text>
      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        <Text style={[styles.copyDisclaimerSuper, styles.utilities.font4]}>
          4
        </Text>
        100% for employers with up to 50 employees of the qualified startup
        costs paid or incurred, but limited to the initial three years the
        employer has a qualified plan and the greater of (1) $500 or (2) the
        lesser of (a) $250 for each non-highly compensated employee who is
        eligible to participate in the plan or (b) $5,000. Qualified startup
        costs (1) In general “qualified startup costs” is ordinary and necessary
        expenses of an eligible employer which are paid or incurred in
        connection with -- (i) the establishment or administration of an
        eligible employer plan, or (ii) the retirement-related education of
        employees with respect to such plan. (2) Plan must have at least 1
        participant: would not apply if plan does not have at least 1 employee
        eligible to participate who is not a highly compensated employee.
        Information about the SECURE 2.0 Act is educational only and provided
        with the understanding that Principal
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>{' '}
        is not rendering legal, accounting, investment advice or tax advice. You
        should consult with appropriate counsel or other advisors on all matters
        pertaining to legal, tax, investment or accounting obligations and
        requirements.
      </Text>
      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        <Text style={[styles.copyDisclaimerSuper, styles.utilities.font4]}>
          5
        </Text>
        Fee for ERISA fidelity bond is covered for first three years of the
        plan.
      </Text>

      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        Simply Retirement by Principal
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>{' '}
        401(k) plan recordkeeping, and administrative services are provided
        through Decimal, Inc. dba Ubiquity Retirement + Savings (“Ubiquity”).
        Ubiquity is not affiliated with any plan and investment administrative
        services provided through Principal Life Insurance Company
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>{' '}
        or affiliated with any company of the Principal Financial Group
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>
        . Principal makes available the Separate Accounts and collective
        investment trusts for customers to select them through Simply Retirement
        by Principal. All other services and mutual funds are provided by
        service providers not affiliated with any company of the Principal
        Financial Group. Refer to related documents and agreements for more
        details on plan services available.
      </Text>

      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        Mutual fund, separate account and collective investment trust trade
        instructions are processed by Matrix Settlement & Clearance Services,
        LLC through the NSCC Membership of its affiliate, Matrix Trust Company.
      </Text>

      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        Simply Retirement by Principal
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>{' '}
        requires a client service agreement between Ubiquity and the plan
        sponsor (Customer). In addition to and as part of the services, each
        Customer will sign separate agreements with the following parties: (i)
        custodian (ii) 3(38) investment manager, and (iii) Principal Life
        Insurance Company, a Comprehensive Retirement Program (PCRP) offered
        through the Modular Group Annuity Contract for separate accounts, and a
        declaration of trust for Principal collective investment funds. Simply
        Retirement by Principal services, costs and resources are subject to
        change.
      </Text>

      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        While the TPA fees will be collected on the TPA’s behalf per TPA’s
        direction, it is the TPA’s responsibility to disclose these fees.
      </Text>

      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        Insurance products and plan administrative services provided through
        Principal Life Insurance Company, a member of the Principal Financial
        Group
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>
        , Des Moines, Iowa 50392.
      </Text>
      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        Principal
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>
        , Principal Financial Group
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>
        , and Principal and the logomark design are registered trademarks of
        Principal Financial Services, Inc., a Principal Financial Group company,
        in the United States and are trademarks and service marks of Principal
        Financial Services, Inc., in various countries around the world.
      </Text>
      <Text style={[styles.copyDisclaimer, styles.utilities.spacingMb10]}>
        PQ12736X | © {currentYear} Principal Financial Services, Inc. |
        3155976-112023 | 01/{currentYear}
      </Text>
    </View>
    <View style={styles.section}>
      <Text style={styles.copyDisclaimer}>
        Intended for financial professional, TPA, and plan sponsor use.
      </Text>
    </View>
  </Page>
);

export default Page2;
