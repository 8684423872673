import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux from '../../../../Redux/FormDataRedux';
import {
  Question,
  PrevStepBtn,
  NextStepBtn,
} from '../../../../common/FormWithSteps';

import { pushGoogleDataLayer } from '../../../../common/FormWithSteps/Utilities';

export const StepQuestion = ({
  role,
  active,
  questions,
  updateActiveStep,
  updateQuestionById,
  googleData,
  putApplicationPanelInfo,
  destination,
  formData,
  setPlanOriginator,
  isFirmBrokerSplitComp,
}) => {
  const { investmentLineup, advisorFirmBroker } = questions;
  const { advisorFirstName, advisorLastName, advisorEmail } =
    formData.planDesign.advisors[0].questions;
  const { brokerType } =
    formData.planDesign.advisors[0].advisorCredentialVerification;
  const previousStep =
    formData.planDesign.advisors.length > 1 && isFirmBrokerSplitComp
      ? 'splitComp'
      : 'compensation';

  const filterOptions = (options, filters = ['fundLineup1']) => {
    const filterBy = options.filter((option) => filters.includes(option.value));
    return filterBy || options;
  };

  const { dirty, hasError } = investmentLineup;
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };

  const destinationWithTpa = (step) => {
    if (formData.planOriginator === '') {
      const planOriginator = {
        name: `${advisorFirstName.value} ${advisorLastName.value}`,
        email: advisorEmail.value,
        role: 'advisor',
      };
      setPlanOriginator(planOriginator);
    }
    updateActiveStep(step, 'forward');
    return true;
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
    if (
      !advisorFirmBroker.value.investments ||
      brokerType === 'insuranceAgent'
    ) {
      updateQuestionById(
        'investmentLineup',
        'fundLineup1',
        false,
        true,
        'Wilshire 3(38) Investment Lineup 1',
      );
      destinationWithTpa(destination());
    }
  }, []);
  return (
    <div className="container-fluid h-100">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Question
            role={role}
            type={investmentLineup.type}
            labelClass="form-label"
            inputClass={
              investmentLineup.type === 'number_field' ? 'text-question' : ''
            }
            thousandSeparator
            question={{
              ...investmentLineup,
              options: filterOptions(
                investmentLineup.options,
                advisorFirmBroker.value?.investments,
              ),
            }}
            handleQuestionChange={handleQuestionChange}
            hasInfo={!!investmentLineup.heading}
            putApplicationPanelInfo={putApplicationPanelInfo}
          />

          {validate && (
            <p className="text-danger text-center">Question is required</p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <NextStepBtn
              text="Next step"
              disabled={
                investmentLineup.required ? !(dirty && !hasError) : false
              }
              onClick={() => {
                destinationWithTpa(destination());
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

StepQuestion.propTypes = {
  formData: PropTypes.object.isRequired,
  role: PropTypes.string,
  active: PropTypes.bool,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  googleData: PropTypes.object,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  destination: PropTypes.func.isRequired,
  setPlanOriginator: PropTypes.func.isRequired,
  isFirmBrokerSplitComp: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  setPlanOriginator: (planOriginator) => {
    dispatch(FormDataRedux.setPlanOriginator(planOriginator));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StepQuestion);
