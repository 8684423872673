export const getAdvisorBrokerDealer = (
  brokerType,
  advisor,
  collectionSource,
  role,
) => {
  if (role === 'psivet' || role === 'onedigital')
    return advisor.questions.firmName.value.value;
  if (brokerType === 'insuranceAgent')
    return advisor.advisorCredentialVerification.insuranceAgent;
  return advisor.questions.firmName?.value.broker_dealer_name;
};
