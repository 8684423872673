import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectTpaQuestions } from '../../Redux/FormDataRedux';
import AdvisorInfoCard from '../../common/AdvisorInfoCard';
import {
  pushGoogleDataLayer,
  checkValidityOnQuestionArray,
} from '../../common/FormWithSteps/Utilities';

export const UserInfo = ({
  previousStep,
  updateActiveStep,
  active,
  googleData,
  questions,
  history,
  tpaInfo,
}) => {
  const { hasTpa } = questions;
  const goToRoleSelection = () => {
    updateActiveStep('advisorInformation', 'forward');
  };

  const destinationWithTpa = (step) => {
    if (hasTpa.value) {
      if (checkValidityOnQuestionArray(tpaInfo)) {
        history.push('/review-plan');
        return false;
      }
      updateActiveStep('shareForm', 'forward');
      return false;
    }
    updateActiveStep(step, 'forward');
    return true;
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <div className="row mb-5">
        <p className="fs-form-q fw-bold text-center col-md-7 m-auto">
          To continue, please verify your information
        </p>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8 m-auto">
          <AdvisorInfoCard goToRoleSelection={goToRoleSelection} />

          <div className="text-center mt-5 pt-5">
            <button
              type="button"
              className="srp-button mx-2 srp-button__large srp-button__primary my-5"
              onClick={() => destinationWithTpa('step_1')}
            >
              Looks Good
            </button>
            <button
              type="button"
              className="srp-button mx-2 srp-button__large srp-button__primary my-5"
              onClick={() => updateActiveStep('clientInfo', 'forward')}
            >
              Start Over
            </button>
          </div>
        </div>
      </div>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

UserInfo.propTypes = {
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  updateActiveStep: PropTypes.func,
  googleData: PropTypes.object,
  questions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  tpaInfo: PropTypes.array.isRequired,
};

const mapStateToProps = (store) => ({
  tpaInfo: selectTpaQuestions(store),
});

export default connect(mapStateToProps)(UserInfo);
