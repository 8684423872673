import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ step }) => (
  <div className="row mb-4">
    <div className="col-lg-8 mx-auto">
      <h3 className="fs-form-q fw-bold mb-md-5 text-center">
        Client and firm information
      </h3>
      <ul className="stepwizard justify-content-center py-3">
        <li
          className={`stepwizard__item w-25 text-center ${
            step === 1 ? 'stepwizard_active' : ''
          }`}
        >
          <span className="stepwizard__link">
            <span className="d-block">Client information</span>
          </span>
        </li>
        <li
          className={`stepwizard__item w-25 text-center ${
            step === 2 ? 'stepwizard_active' : ''
          }`}
        >
          <span className="stepwizard__link">
            <span className="d-block d-none">Firm information</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
);

Header.propTypes = {
  step: PropTypes.number.isRequired,
};

export default Header;
