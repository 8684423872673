import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HeaderSection = ({
  image,
  title,
  caption,
  copy,
  alt,
  wrapperClasses,
}) => (
  <header
    className={classNames('header-section header-section-mask', wrapperClasses)}
  >
    <div className="container-md mb-md-5 header-section__container py-5">
      <div className="row">
        <div className="col-12 col-sm-7">
          <h1 className="fs-h1 pt-0 pb-3 pr-4 mr-md-0 fw-bold lh-100 header-section__title">
            {title}
          </h1>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 col-md-7 col-lg-6 mb-3 header-section__title"
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      </div>
      <div className="row">
        <div className="col-8 mx-auto position-static">
          <img
            src={image}
            className="img-fluid mt-1 mb-4 header-section__image"
            alt={alt}
          />
        </div>
        <div
          className="col-12 col-md-7 col-lg-6"
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </div>
    </div>
  </header>
);
HeaderSection.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  copy: PropTypes.string,
  alt: PropTypes.string,
  wrapperClasses: PropTypes.string,
};

export default HeaderSection;
