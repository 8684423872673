import styled from 'styled-components';
import { backgroundGrayPattern } from '../../../ImagePaths';

export const DivStyled = styled.div`
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9) 15%,
    rgba(255, 255, 255, 0) 50%
  );
  &::after {
    content: '';
    opacity: 0.2;
    position: absolute;
    z-index: -2;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    transition: all 0.5s cubic-bezier(0.44, 0.01, 0.4, 1.34) 0.125s;
    background-image: url(${backgroundGrayPattern});
    background-size: 100%;
    background-image: repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.25) 400px,
        rgba(57, 68, 78, 0.25) 650px,
        transparent 650px 760px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      );
  }
`;

export const MaskArch = styled.svg`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  @media (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: none !important;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;
