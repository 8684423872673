import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EquationStyled, FeeStyled, FeeStylesSm } from './index.styles';

const SectionSMBCosts = ({ title, cardType }) => (
  <div>
    <h3 className="fs-h3 text-center py-5">
      <div dangerouslySetInnerHTML={{ __html: title }} />
    </h3>

    <div className="row mb-2">
      <div className="col-lg-5 col-md-8 col mx-auto mb-3">
        <div className={classNames('principal-card text-center p-4', cardType)}>
          <p className="text-black fw-bold fs-h6 mb-4 leading-tight">
            Simply Retirement by Principal<sup>®</sup>
            <br /> recordkeeping costs
          </p>
          <div className="d-flex flex-md-row flex-column justify-content-around">
            <div className="flex-1">
              <p className="text-center mb-0">Up-front</p>
              <FeeStyled className="d-block text-purple fw-bold text-center fs-xxl">
                <sup>
                  <small className="fs-h3">$</small>
                </sup>
                500
              </FeeStyled>
              <p className="fs-h2 text-purple fw-bold text-center leading-none mb-1">
                One-time <br />
                setup fee
              </p>
            </div>
            <div className="flex-1">
              <p className="text-center mb-0">Every month</p>
              <FeeStyled className="d-block text-center fs-xxl text-purple fw-bold">
                <sup>
                  <small className="fs-h3">$</small>
                </sup>
                185
              </FeeStyled>
              <EquationStyled>
                <EquationStyled.OperatorDown>+</EquationStyled.OperatorDown>
                <EquationStyled.ParensDown>
                  <EquationStyled.NumberDown>
                    <sup>$</sup>6
                  </EquationStyled.NumberDown>
                  <EquationStyled.LabelDown>
                    for every
                    <br /> participant
                  </EquationStyled.LabelDown>
                </EquationStyled.ParensDown>
              </EquationStyled>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row mb-5">
      <div className="col-lg-5 col-md-8 col mx-auto mb-3">
        <div className={classNames('principal-card text-center p-4', cardType)}>
          <p className="text-black fw-bold fs-h6 mb-4 leading-tight">
            OneDigital advisory fees
          </p>
          <div className="d-flex align-items-center justify-content-center">
            <div>
              <FeeStylesSm>
                <sup>
                  <small className="fs-h5">$</small>
                </sup>
                750
              </FeeStylesSm>
            </div>
            <div className="text-purple fw-bold fs-display3 mx-2">+</div>
            <div>
              <FeeStylesSm>11.25</FeeStylesSm>
              <small
                style={{
                  fontSize: '22px',
                  fontWeight: 'bolder',
                  color: '#002855',
                }}
              >
                bps<sup>*</sup>
              </small>
            </div>
          </div>
          <p className="mb-1 text-center">Every quarter</p>
        </div>
      </div>
    </div>
  </div>
);

SectionSMBCosts.propTypes = {
  title: PropTypes.string.isRequired,
  cardType: PropTypes.string,
};

export default SectionSMBCosts;
