import styled from 'styled-components';

export const PurpleWrapper = styled.div`
  position: relative;
  margin-top: 140px;
  padding-bottom: 80px;
  background-color: #002855;

  &:before {
    content: '';
    display: block;
    background-color: #002855;
    top: 0;
    left: 0;
    right: 0;
    transform: scale(1.2) translateY(-50%);
    position: absolute;
    height: 200px;
    border-radius: 100%;
  }
  @media print {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
    &:before {
      display: none;
    }
  }
`;
