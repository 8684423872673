import React from 'react';

const FinancialProfessionalsTab = () => (
  <div className="py-4 px-5">
    <h5 className="fs-h3 my-4 pt-2 text-purple">For financial professionals</h5>
    <p className="fs-p fw-bold">
      Watch for an email invitation when you save your first Simply Retirement
      by Principal<sup>&reg;</sup> plan proposal. The email will include a link
      to the Ubiquity Retirement + Savings
      <sup>
        <small>®</small>
      </sup>{' '}
      website, where you can:
    </p>

    <div className="row my-5 justify-content-center">
      <div className="col-md-2 align-self-center">
        <h5 className="fs-h5 fw-bold text-purple">Register with Ubiquity</h5>
      </div>
      <div className="col-md-1 align-self-center">
        <i className="fa-solid fa-arrow-right text-link" aria-hidden />
      </div>
      <div className="col-md-2 align-self-center">
        <h5 className="fs-h5 fw-bold text-purple">
          Set up a username and password
        </h5>
      </div>
      <div className="col-md-1 align-self-center">
        <i className="fa-solid fa-arrow-right text-link" aria-hidden />
      </div>
      <div className="col-md-2 align-self-center">
        <h5 className="fs-h5 fw-bold text-purple">Check out your dashboard</h5>
      </div>
      <div className="col-md-1 align-self-center">
        <i className="fa-solid fa-arrow-right text-link" aria-hidden />
      </div>
      <div className="col-md-2 align-self-center">
        <h5 className="fs-h5 fw-bold text-purple">
          Manage Simply Retirement by Principal
          <small>
            <sup>&reg;</sup>
          </small>{' '}
          clients
        </h5>
      </div>
    </div>

    <p className="fs-p mb-4">
      You’ll also be copied on key email notifications to your clients as they
      complete the purchase and plan setup process.
    </p>
  </div>
);

export default FinancialProfessionalsTab;
