import React from 'react';
import PropTypes from 'prop-types';
import Table from './Table';

const TableSection = ({ putApplicationPanelInfo, safeHarborDisclaimer3 }) => (
  <section className="section section_py-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-md-8 mx-auto">
          <h3 className="fs-h3 fw-bold text-center mb-5">
            Two contribution choices are available with a{' '}
            <button
              type="button"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: safeHarborDisclaimer3,
                })
              }
              className="text-underline p-0 no-border-button fs-h3 fw-bold"
            >
              safe harbor plan
            </button>
            <sup>
              <small>*</small>
            </sup>
            .
          </h3>
        </div>
      </div>
    </div>
    <div className="container-lg">
      <div className="row mb-5">
        <div className="col-lg-10 mx-auto">
          <Table />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 mx-auto">
          <p className="fw-bold text-center mb-5">
            Example is based on an employee with an annual salary of $35,000
            contributing at the highest percentage to maximize the match.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <p className="fs-p">
            <strong>
              Are there special deadlines for safe harbor 401(k) plans?
            </strong>{' '}
            In a word, yes. If you’re starting a brand-new safe harbor 401(k)
            plan and want to have it take effect in the current calendar year,
            your plan must be fully set up and active by October 1. Setting up
            the plan and taking care of all the related administrative tasks can
            take some time, so consider finalizing your plan by mid-September,
            to meet this date.
          </p>
        </div>
      </div>
    </div>
  </section>
);

TableSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  safeHarborDisclaimer3: PropTypes.any,
};

export default TableSection;
