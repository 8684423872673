import React from 'react';
import PropTypes from 'prop-types';
import { asOfDate } from '../../../../Services/utilities';

const Pricing = ({ putApplicationPanelInfo }) => (
  <div className="py-4 px-5">
    <p className="fs-p fw-bold mb-0">Pricing for our solution</p>
    <p className="fs-p">
      The Simply Retirement by Principal
      <small>
        <sup>®</sup>
      </small>{' '}
      401(k) plan has a simple flat recordkeeping fee structure.
    </p>
    <p className="fs-p">
      You’ll pay as little as $145 per month{' '}
      <button
        type="button"
        className="p-0 no-border-button text-underline"
        onClick={() => {
          putApplicationPanelInfo({
            hasVisibility: true,
            additionalInformation:
              'Pricing if working with a TPA. Bundled pricing includes a one-time setup fee of $500 upfront to get started, then $185 per month ($555 billed quarterly).',
          });
        }}
      >
        ($435 billed quarterly).
      </button>
      If you choose to pay the $6 per participant, per month fee on your
      employees’ behalf, this will also be billed quarterly, three months at a
      time. Otherwise, it will be deducted from participants’ plan assets.
      Custodial and investment fees are charged against participating employees’
      accounts (those vary by investment and range from 0.05% – 0.91%, as of{' '}
      {asOfDate}).
    </p>
  </div>
);

Pricing.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

export default Pricing;
