import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addToast: ['text', 'toastType'],
  removeToast: ['index'],
});

export const ToastTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  notifications: [],
};

/* ------------- Reducers ------------- */

const addToast = (state, { text, toastType }) => ({
  ...INITIAL_STATE,
  notifications: [
    ...state.notifications,
    {
      text,
      toastType,
      key: state.notifications.length,
    },
  ],
});

const removeToast = (state, { index }) => ({
  ...state,
  notifications: state.notifications.filter(
    (notification, compareIndex) => compareIndex !== index,
  ),
});

/* ------------- Hookup Reducers To Types ------------- */

export const toastReducer = createReducer(INITIAL_STATE, {
  [Types.ADD_TOAST]: addToast,
  [Types.REMOVE_TOAST]: removeToast,
});
