import React from 'react';
import {
  iconMoneyCurrency,
  iconMobilePhonePig,
  iconCoinStack,
} from '../../../ImagePaths';

const DescriptionSection = () => (
  <section className="section pt-5 bg-white">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-md-6 mx-auto">
          <h3 className="fs-h3 text-center">
            Advantages of a Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            401(k) plan
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 mx-auto">
          <div className="row pt-5">
            <div className="text-left col-md-4">
              <div className="mb-2">
                <img src={iconMoneyCurrency} width="45" height="55" alt="" />
              </div>
              <p className="fs-h2 text-purple fw-bold">Consistent flat fee</p>
              <p className="fs-p">
                Business owners pay the same flat recordkeeping fee, so you
                don’t have to worry about pricing that goes up as your plan
                assets grow or wonder if you’re getting the same rate as others.
              </p>
            </div>
            <div className="text-left col-md-4">
              <div className="mb-2">
                <img src={iconMobilePhonePig} width="45" height="55" alt="" />
              </div>
              <p className="fs-h2 text-purple fw-bold">Completely online</p>
              <p className="fs-p">
                You can make all of your plan design selections, sign forms, add
                employees, and manage contributions in one central online
                location—when and where it’s convenient for you. And if you have
                questions, help is just a phone call away.
              </p>
            </div>
            <div className="text-left col-md-4">
              <div className="mb-2">
                <img src={iconCoinStack} width="45" height="55" alt="" />
              </div>
              <p className="fs-h2 text-purple fw-bold">
                Payroll provider integration
              </p>
              <p className="fs-p">
                Ubiquity Retirement + Savings
                <sup>
                  <small>&reg;</small>
                </sup>{' '}
                supports integrations with select payroll providers like
                Paychex, ADP, and QuickBooks, helping business owners save time
                and reduce errors by automating contribution reporting.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default DescriptionSection;
