import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import classNames from 'classnames';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

const ActionCard = ({ icon, excerpt, caption, actionText, actionPath }) => {
  const sendDataToGTM = useGTMDispatch();
  const [active, setActive] = useState(false);
  const [animate, setAnimate] = useState(true);

  return (
    <div
      role="button"
      tabIndex={0}
      onTouchStart={() => {
        setAnimate((prevState) => !prevState);
      }}
      onMouseEnter={() => {
        setAnimate(false);
      }}
      onMouseLeave={() => {
        setAnimate(true);
      }}
      onKeyPress={() => {
        setActive((prevState) => !prevState);
        setAnimate((prevState) => !prevState);
      }}
      onClick={() => {
        setActive((prevState) => !prevState);
        setAnimate((prevState) => !prevState);
      }}
      className={classNames('actioncard', {
        active,
        inactive: !active,
      })}
    >
      <div className="actioncard__media mx-2 text-center px-2 px-sm-4 px-md-0">
        <div
          dangerouslySetInnerHTML={{ __html: icon }}
          className={classNames('actioncard__icon mx-auto', { animate })}
        />
      </div>
      <div
        className="fs-p lh-125 actioncard__caption px-3"
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
      <div
        className="fs-p lh-125 actioncard__excerpt px-3"
        aria-hidden={!active ? 'true' : 'false'}
        dangerouslySetInnerHTML={{ __html: caption }}
      />
      <div
        className="actioncard__action mt-3"
        aria-hidden={!active ? 'true' : 'false'}
      >
        <Link
          to={actionPath}
          className="fs-sm srp-button srp-button__rev"
          onClick={() => {
            sendDataToGTM({
              event: 'e_toolInteraction',
              eventType: 'progress',
              tool: {
                name: 'Simply Retirement',
                stepNumber: '1',
                stepName: 'begin',
              },
            });
          }}
        >
          {actionText}
        </Link>
      </div>
    </div>
  );
};

ActionCard.propTypes = {
  icon: PropTypes.any.isRequired,
  excerpt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  actionPath: PropTypes.string.isRequired,
};

export default ActionCard;
