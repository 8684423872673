import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NextStepBtn, PrevStepBtn } from '../../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../../common/FormWithSteps/Utilities';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../../Redux/FormDataRedux';
import DefaultCheckList from './DefaultCheckList';
import ReturningCheckList from './ReturningCheckList';

export const TpaCheckList = ({
  active,
  previousStep,
  updateActiveStep,
  destination,
  putRole,
  applicationPanelInfo,
  googleData,
  hasHashId,
}) => {
  useEffect(() => {
    putRole('tpa');
    pushGoogleDataLayer(googleData);
  }, []);
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });
  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-md-10 col-lg-8 mx-auto my-md-auto">
          {hasHashId ? <ReturningCheckList /> : <DefaultCheckList />}
          <div className="row">
            <div className="col-md-10 col-lg-8 mx-auto">
              <p className="fws__control-group">
                <NextStepBtn
                  id="next"
                  text="Create plan"
                  onClick={() => {
                    pushGoogleDataLayer({
                      event: 'TpaPlanDesignStart',
                    });
                    updateActiveStep(destination(), 'forward');
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

TpaCheckList.propTypes = {
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  updateActiveStep: PropTypes.func,
  destination: PropTypes.func,
  putRole: PropTypes.func.isRequired,
  applicationPanelInfo: PropTypes.object.isRequired,
  googleData: PropTypes.object,
  hasHashId: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  hasHashId: !!store.formData.hashId,
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putRole: (role) => {
    dispatch(FormDataRedux.putRole(role));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TpaCheckList);
