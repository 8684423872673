import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Question,
  StepControls,
  WithInfoPanel,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import { isFormValid } from '../../../Redux/FormDataRedux';

export const StepQuestion = ({
  role,
  active,
  questionId,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  googleData,
  isValid,
}) => {
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };

  const { value, dirty, hasError } = questions[questionId];
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <WithInfoPanel
        visibleInfoPanel={visibleInfoPanel}
        setVisibility={setVisibility}
        heading={questions[questionId].heading}
        content={questions[questionId].content}
        toggleInfoPanel={toggleInfoPanel}
      >
        <Question
          role={role}
          type={questions[questionId].type}
          labelClass="form-label"
          inputClass={
            questions[questionId].type === 'number_field' ? 'text-question' : ''
          }
          thousandSeparator
          question={questions[questionId]}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!questions[questionId].heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        {questions[questionId].heading && (
          <p className="text-center mb-lg-auto">
            <button
              type="button"
              className="srp-button srp-button__link mx-auto"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Need clarification?
            </button>
          </p>
        )}
        <StepControls
          formInReview={formInReview}
          disableReviewBtn={!isValid}
          disabledNextBtn={
            questions[questionId].required ? !(dirty && !hasError) : false
          }
          goToReviewPlan={goToReviewPlan}
          clickNextBtn={() => {
            setVisibility(false);
            updateActiveStep(destination(value), 'forward');
          }}
        />
      </WithInfoPanel>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          setVisibility(false);
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

StepQuestion.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  destination: PropTypes.any,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  googleData: PropTypes.object,
  isValid: PropTypes.bool,
};

const mapStateToProps = (store) => ({
  isValid: isFormValid(store),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StepQuestion);
