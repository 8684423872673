import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ToastRedux from '../../Redux/ToastRedux';
import Toast from './Toast';

const Toasts = ({ toasts, removeToast }) => (
  <TransitionGroup component="ul" className="toast-notes d-print-none">
    {toasts &&
      toasts.map((toast, index) => {
        const { text, toastType, key } = toast;
        return (
          <CSSTransition
            key={key}
            classNames={{
              enter: 'animated',
              enterActive: 'fadeInRight',
              exit: 'animated',
              exitActive: 'fadeOutRight',
            }}
            timeout={900}
          >
            <Toast
              key={key}
              {...toast}
              text={text}
              toastType={toastType}
              index={index}
              removeToast={removeToast}
              onDismissClick={() => removeToast(index)}
            />
          </CSSTransition>
        );
      })}
  </TransitionGroup>
);

const mapDispatchToProps = (dispatch) => ({
  removeToast: (index) => {
    dispatch(ToastRedux.removeToast(index));
  },
});

Toasts.propTypes = {
  toasts: PropTypes.array.isRequired,
  removeToast: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  toasts: store.toastData.notifications,
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
