// VisibilitySensor.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

export const ScrollInToView = ({ renderItem, triggerOnce = false }) => {
  const { ref, inView } = useInView({
    triggerOnce,
    threshold: 0,
  });

  return <div ref={ref}>{renderItem(inView)}</div>;
};

ScrollInToView.propTypes = {
  renderItem: PropTypes.func.isRequired,
  triggerOnce: PropTypes.bool,
};
