import React from 'react';
import PropTypes from 'prop-types';

const ConfirmModal = ({ destroyModal, nextAction }) => (
  <div className="text-center my-5">
    <h1 className="fs-h1 text-center mb-3">Is everything correct?</h1>
    <div className="fs-p text-center mb-4 lh-125">
      <p>
        Before you continue, please make sure you’ve selected the correct state
        for your client. This should be the U.S. state where the plan sponsor’s
        primary business is located for tax purposes.
      </p>
      <p>
        In addition, please ensure the name you’ve entered in the Company Name
        field is the <strong>legal name</strong> of the company. This
        information will be used to generate legal documents.
      </p>
    </div>
    <button
      type="button"
      className="srp-button srp-button__primary_outline srp-button__sm-large mt-3 mb-5 my-md-5 mx-2"
      onClick={() => {
        destroyModal();
      }}
    >
      Edit
    </button>
    <button
      type="button"
      className="srp-button srp-button__sm-large srp-button__primary mt-3 mb-5 my-md-5 mx-2"
      onClick={nextAction}
    >
      Next
    </button>
  </div>
);

ConfirmModal.propTypes = {
  destroyModal: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired,
};

export default ConfirmModal;
