export const getSourceId = async (
  whichSafeHarborQaca,
  whichSafeHarbor,
  autoEnrollmentPlan,
) => {
  if (
    autoEnrollmentPlan !== undefined &&
    autoEnrollmentPlan.valueFormatted === 'QACA'
  ) {
    if (whichSafeHarborQaca.value.value === '3PercNonelectiveSafeharbor') {
      return 1001;
    }
    if (whichSafeHarborQaca.value.value === 'basicMatch100to1-50toNext6') {
      return 22;
    }
    if (whichSafeHarborQaca.value.value === 'enhancedMatch100to3.5') {
      return 1003;
    }
    if (whichSafeHarborQaca.value.value === 'enhancedMatch100to4') {
      return 1004;
    }
    if (whichSafeHarborQaca.value.value === 'enhancedMatch100to5') {
      return 1005;
    }
    return 1006;
  }
  if (whichSafeHarbor === undefined) {
    return 7;
  }
  if (whichSafeHarbor.value.value === '3PercNonelectiveSafeharbor') {
    return 8;
  }
  if (whichSafeHarbor.value.value === 'basicMatch100to3-50toNext2') {
    return 4;
  }
  if (whichSafeHarbor.value.value === 'enhancedMatch100to4') {
    return 5;
  }
  if (whichSafeHarbor.value.value === 'enhancedMatch100to5') {
    return 6;
  }
  return 7;
};
