import React from 'react';
import PropTypes from 'prop-types';
import { Question } from '../../../../common/FormWithSteps';

export const AutoEnrollmentQuestion = ({
  primaryQuestion,
  secondaryQuestion,
  handleQuestionChange,
}) => {
  const showEnrollmentOptions =
    primaryQuestion.value === primaryQuestion.options[0].value;

  return (
    <div className="row">
      <div className="col-md-8 mx-auto">
        <Question
          type={primaryQuestion.type}
          question={primaryQuestion}
          handleQuestionChange={handleQuestionChange}
          radioWrapper="srp-form-toggle"
        />

        {showEnrollmentOptions && (
          <Question
            type={secondaryQuestion.type}
            question={secondaryQuestion}
            handleQuestionChange={handleQuestionChange}
            labelClass="fs-h4 fw-bold text-center"
            radioWrapper="flex-lg-row flex-sm-row flex-md-column flex-column d-lg-block d-md-flex d-sm-block d-flex align-items-center"
          />
        )}
      </div>
    </div>
  );
};

AutoEnrollmentQuestion.propTypes = {
  primaryQuestion: PropTypes.object.isRequired,
  secondaryQuestion: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default AutoEnrollmentQuestion;
