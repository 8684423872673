import React from 'react';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { Question } from '../../../../../common/FormWithSteps';

const FinancialProfessional = ({
  advisorIndex,
  advisor,
  uniqueId,
  handleQuestionChange,
  handleQuestionChangeFirmName,
  handleQuestionChangeAllAdvisors,
  employerStateCode,
  firmNameFreeForm,
}) => {
  const {
    advisorEmail,
    advisorFirstName,
    advisorLastName,
    advisorStateCode,
    advisorZipCode,
    advisorPhone,
    advisorNpn,
    floridaLicenseNumber,
  } = advisor;

  return (
    <fieldset id="yourInformation" className="mb-4">
      <div className="row">
        <div className="col-md">
          <Question
            uniqueId={uniqueId(advisorFirstName.name, advisorIndex)}
            type={advisorFirstName.type}
            labelClass="fs-form-label fw-bold text-center"
            inputClass="srp-form-input form-control"
            question={advisorFirstName}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChange({
                name,
                updateValue,
                valueFormatted,
                advisorIndex,
              });
            }}
          >
            {advisorFirstName.hasError && <small>Question is required</small>}
          </Question>
        </div>
        <div className="col-md">
          <Question
            uniqueId={uniqueId(advisorLastName.name, advisorIndex)}
            type={advisorLastName.type}
            labelClass="fs-form-label fw-bold text-center"
            inputClass="srp-form-input form-control"
            question={advisorLastName}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChange({
                name,
                updateValue,
                valueFormatted,
                advisorIndex,
              });
            }}
          >
            {advisorLastName.hasError && <small>Question is required</small>}
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Question
            uniqueId={uniqueId(advisorEmail.name, advisorIndex)}
            type={advisorEmail.type}
            labelClass="fs-form-label fw-bold text-center"
            inputClass="srp-form-input form-control"
            question={advisorEmail}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChange({
                name,
                updateValue,
                valueFormatted,
                advisorIndex,
              });
            }}
          >
            {advisorEmail.hasError && <small>Question is required</small>}
          </Question>
        </div>
        <div className="col-md-6">
          <div
            className={classNames('mb-3 form-group', {
              'has-error': advisorPhone.hasError,
            })}
          >
            <label
              className="fs-form-label fw-bold"
              htmlFor={uniqueId(advisorPhone.name, advisorIndex)}
            >
              Your phone number
            </label>
            <PatternFormat
              value={advisorPhone.value}
              format="###-###-####"
              mask="_"
              className={classNames('srp-form-input form-control', {
                'is-invalid': advisorPhone.hasError,
                'is-dirty': advisorPhone.dirty,
              })}
              id={uniqueId(advisorPhone.name, advisorIndex)}
              name={advisorPhone.name}
              required
              onValueChange={(values) => {
                handleQuestionChange({
                  name: advisorPhone.name,
                  updateValue: values.value,
                  valueFormatted: values.formattedValue,
                  advisorIndex,
                });
              }}
            />
            {advisorPhone.hasError && <small>Question is required</small>}
          </div>
        </div>
      </div>
      {advisorIndex < 1 && (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label
                className="fs-form-label fw-bold"
                htmlFor={uniqueId(advisorStateCode.name, advisorIndex)}
              >
                {advisorStateCode.label}
              </label>
              <select
                id={uniqueId(advisorStateCode.name, advisorIndex)}
                name="state"
                autoComplete="address-level1"
                value={advisorStateCode.value}
                className="custom-select srp-form-input form-control form-select"
                onChange={(selected) => {
                  handleQuestionChangeAllAdvisors({
                    name: advisorStateCode.name,
                    updateValue: selected.target.value,
                    valueFormatted: selected.target.value,
                    advisorIndex,
                  });
                }}
              >
                <option>Select</option>
                {advisorStateCode.options.map((option) => (
                  <option key={option.id} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>{' '}
              {advisorStateCode.hasError && <small>Question is required</small>}
            </div>
          </div>
          <div className="col-md-6">
            <Question
              uniqueId={uniqueId(advisorZipCode.name, advisorIndex)}
              type={advisorZipCode.type}
              labelClass="fs-form-label fw-bold text-center"
              inputClass="srp-form-input form-control"
              question={advisorZipCode}
              handleQuestionChange={(name, updateValue, valueFormatted) => {
                handleQuestionChangeAllAdvisors({
                  name,
                  updateValue,
                  valueFormatted,
                  advisorIndex,
                });
              }}
            >
              {advisorZipCode.hasError && <small>Question is required</small>}
            </Question>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-6">
          <Question
            uniqueId={uniqueId(advisorNpn.name, advisorIndex)}
            type={advisorNpn.type}
            labelClass="fs-form-label fw-bold text-center"
            inputClass="srp-form-input form-control"
            question={advisorNpn}
            maxLength={10}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChange({
                name,
                updateValue,
                valueFormatted,
                advisorIndex,
              });
            }}
          />
          <small>
            (Your NPN is a number between 4-10 digits in length. Not sure what
            your NPN is?{' '}
            <a href=" https://nipr.com/help/look-up-your-npn" target="_blank">
              Find it here
            </a>
            )
          </small>
        </div>
        {advisorIndex === 0 && (
          <div className="col-md-6">
            <Question
              // eslint-disable-next-line jsx-a11y/aria-role
              role="advisor"
              type={firmNameFreeForm.type}
              labelClass="fs-form-label fw-bold text-center"
              inputClass="srp-form-input form-control"
              question={firmNameFreeForm}
              handleQuestionChange={handleQuestionChangeFirmName}
            >
              {firmNameFreeForm.hasError && <small>Question is required</small>}
              <small>
                This is the name of the company you work for (i.e., your
                employer).
              </small>
            </Question>
          </div>
        )}
        {employerStateCode.value === 'FL' && (
          <div className="col-md-6">
            <Question
              uniqueId={uniqueId(floridaLicenseNumber.name, advisorIndex)}
              type={floridaLicenseNumber.type}
              labelClass="fs-form-label fw-bold text-center"
              inputClass="srp-form-input form-control"
              question={floridaLicenseNumber}
              handleQuestionChange={(name, updateValue, valueFormatted) => {
                handleQuestionChange({
                  name,
                  updateValue,
                  valueFormatted,
                  advisorIndex,
                });
              }}
            >
              {floridaLicenseNumber.hasError && (
                <small>Question is required</small>
              )}
            </Question>
          </div>
        )}
      </div>
    </fieldset>
  );
};

FinancialProfessional.propTypes = {
  advisorIndex: PropTypes.number.isRequired,
  advisor: PropTypes.object.isRequired,
  uniqueId: PropTypes.func.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  handleQuestionChangeAllAdvisors: PropTypes.func.isRequired,
  employerStateCode: PropTypes.object.isRequired,
  firmNameFreeForm: PropTypes.object.isRequired,
  handleQuestionChangeFirmName: PropTypes.func.isRequired,
};

export default FinancialProfessional;
