export const allowLoans = {
  id: 'q6aI1d',
  type: 'radio_condensed',
  name: 'allowLoans',
  paradigmAlias: 'allowLoans',
  user: {},
  stepName: 'z',
  label: 'Will the plan allow for loans?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Allow loans',
  reviewScreenTitle: 'Allow loans',
  optionDescription: '',
  description:
    "If selected, participants can request to take a loan from their 401(k) plan balance and select a loan repayment schedule that best suits them. Only one loan may be outstanding at a time. Loan repayments are made via after-tax payroll deductions. The interest portion of the loan payment is applied to the participant's account.",
};
