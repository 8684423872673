import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
import TableSection from './TableSection';
import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import InvestmentLineUp from '../../../common/InvestmentLineUp';

import { fpInvestmentLineupHeader } from '../../../ImagePaths';

const sectionHeader = {
  image: fpInvestmentLineupHeader,
  title: 'Investments option lineups',
  caption: `
    <p class="fs-display5 mb-5">
      Financial professionals can assist the business owner when choosing from the Wilshire & Associates LLC 3(38) investment lineups for added flexibility to match their clients’ needs.
    </p>
  `,
  alt: 'Two women enjoying the outdoors.',
  copy: ``,
};

const sectionInvestmentOptions = `
<h2 class="fs-h2 fw-normal mb-4">
   Simply Retirement by Principal<small><sup>®</sup></small> investment options offer attractive performance with a competitive return, helping make the most of employees’ contributions and any matching contributions provided by the business owner.
</h2>
<p class="fs-h6">
  Wilshire Advisors LLC will be the plan’s 3(38) investment fiduciary. Wilshire Advisors LLC provides objective, independent third-party oversight for the screening, selection, and monitoring of the investment options available for Simply Retirement by Principal<small><sup>®</sup></small>. The firm also makes changes to the investment lineup when they deem appropriate.
</p>
<p class="fs-h6">
As a financial professional, you can assist your client in the selection of one investment option lineup for your client’s plan from the options below. <strong>Your broker-dealer licensing will determine which investment option lineups are available for you to offer your clients.</strong>
</p>
`;

const sectionAbout = `
<h4 class="fs-display5 mb-3">
  About Fiduciary Services and Wilshire Advisors LLC
</h4>
<p class="fs-h6">
  Managing obligations as a fiduciary can be challenging, from navigating the landscape of available investment managers to monitoring plan investments and more.
</p>
<p class="fs-h6">
  <strong>That means the investment professionals at Wilshire Advisors LLC are responsible for the creation and oversight of the investment lineup for Simply Retirement by Principal<small><sup>®</sup></small>.</strong> As a result, participants can benefit from the same investment manager research resources that help select investment options for some of the largest retirement and pension plans in the world.
</p>
<p class="fs-h6 mb-0"><strong>What does Wilshire Advisors LLC do?</strong></p>
<p class="fs-h6">
  Wilshire Advisors LLC helps screen and select plan investments for Simply Retirement by Principal<small><sup>®</sup></small>. They routinely monitor the investments in the plan and apply a rigorous review process, then make appropriate adjustments. If Wilshire Advisors LLC makes a change to any investments in the Simply Retirement by Principal<small><sup>®</sup></small> lineup, plan sponsors will receive an email, and the required participant notices will be sent to the participants’ email accounts and be accessible via the dashboard.
</p>
<p class="fs-h6 mb-0">
  <strong>Why Wilshire Advisors LLC?</strong>
</p>
<p class="fs-h6">Wilshire Advisors LLC is a diversified global financial services firm that offers:</p>
<ul>
<li>
  <p class="fs-h6 mb-0">
    <strong>Objectivity</strong> with independent third-party investment evaluation based on more than 40 years of providing investment guidance to some of the largest plan sponsors in the country.
  </p>
</li>
<li>
  <p class="fs-h6 mb-0">
    <strong>A tested process</strong> for screening, monitoring, and recommending investment options for the plan’s investment lineup.
  </p>
</li>
<li>
  <p class="fs-h6 mb-0">
    <strong>Experience</strong> with more than 500 clients in over 20 countries, <span id="investments-disclaimer-1" class="text-underline p-0 no-border-button">representing combined assets of more than $9 trillion</span><sup><small>*</small></sup>, as well as fiduciary services serving <span id="investments-disclaimer-2" class="text-underline p-0 no-border-button">more than 39,000 plans</span><sup><small>*</small></sup>.
  </p>
</li>
<li>
  <p class="fs-h6 mb-0">
    Wilshire is an investment advisory firm with more than 50 years of investment consulting services, analytics and customized investment solutions. They have a long and tenured history in providing investment solutions to some of the world’s largest retirement plan sponsors. They take the same institutional resources and advisory solutions typically reserved for billion-dollar retirement plans directly to financial professionals serving plan sponsors both large and small through the Wilshire 3(21) and 3(38) fiduciary services offering. Wilshire helps plan sponsors manage their fiduciary obligations, not just in designing the menu and selecting investments, but also monitoring them on an ongoing basis.
  </p>
</li>
</ul>
`;

const investmentDisclaimer1 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*below:</span> As of 6/1/2019.</p>`;
const investmentDisclaimer2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Representing combined assets of more than $9 trillion:</span> As of 6/30/2019.</p>`;
const investmentDisclaimer3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*More than 39,000 plans:</span> As of 1/31/2019.</p>`;
const investmentDisclaimer4 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*Over 70 investment professionals contributing to investment manager research:</span> As of 6/1/2020.</p>`;
const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${investmentDisclaimer1}
     ${investmentDisclaimer2}
     ${investmentDisclaimer3}
     ${investmentDisclaimer4}
    </div>
  </div>
`;

const Investments = ({ applicationPanelInfo, putApplicationPanelInfo }) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'investments-disclaimer-1',
      'investments-disclaimer-2',
      'investments-disclaimer-3',
      'investments-disclaimer-4',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });
  const handleClick = (e) => {
    switch (e.target.id) {
      case 'investments-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: investmentDisclaimer1,
        });
        break;

      case 'investments-disclaimer-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: investmentDisclaimer2,
        });
        break;

      case 'investments-disclaimer-3':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: investmentDisclaimer3,
        });
        break;

      case 'investments-disclaimer-4':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: investmentDisclaimer4,
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="about-page mb-5 pb-5">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        alt={sectionHeader.alt}
      />

      <SectionSingleCol
        copy={sectionInvestmentOptions}
        columns="col-md-10 mx-auto"
      />

      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <TableSection />
          </div>
        </div>
      </div>

      <section className="section section_py-5 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2 className="fs-h2 fw-normal mb-4">
                Participating employees have the option to choose their mix of
                investments from the plan’s investment lineup based on the asset
                classes{' '}
                <span
                  id="investments-disclaimer-4"
                  className="text-underline p-0 no-border-button"
                >
                  below
                </span>
                <small>
                  <sup>*</sup>
                </small>
                :
              </h2>
              <InvestmentLineUp />
            </div>
          </div>
        </div>
      </section>

      <SectionSingleCol
        copy={sectionAbout}
        columns="col-md-10 mx-auto"
        className="pt-0"
      />
      <SectionSingleCol
        copy={`<p class="fs-disclaimer pb-5 text-center">
                Intended for financial professional and third party administrator use.
              </p>`}
        columns="col-md-10 mx-auto"
      />
      <SectionSingleCol className="d-print-block d-none" copy={footNoteList} />
    </div>
  );
};

Investments.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Investments);
