import styled from 'styled-components';

export const BgImage = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 55%;
  right: 30%;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  transform: translateY(-50%) translateX(50%);
  @media (min-width: 768px) {
    top: 50%;
    right: 30%;
    width: 290px;
    height: 290px;
  }
  @media (min-width: 992px) {
    width: 308px;
    height: 308px;
    right: 30%;
  }
`;

export const Circle = styled.div`
  position: absolute;
  top: -200px;
  right: 50%;
  border-radius: 50%;
  justify-content: center;
  width: 400px;
  height: 400px;
  background-color: white;
  overflow: hidden;
  @media (min-width: 768px) {
    top: -20%;
    right: 60%;
    width: 575px;
    height: 575px;
  }
  @media (min-width: 992px) {
    width: 608px;
    height: 608px;
    top: -50%;
    right: 60%;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    background-color: #fff;
  }
`;
