import styled from 'styled-components';

export const CalloutSection = styled.div`
  background: #002855;
  position: relative;
  padding-bottom: 40px;
  padding-top: 40px;
  @media (min-width: 768px) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
  &::before {
    content: '';
    display: block;
    background: #ffffff;
    z-index: 0;
    top: -40px;
    left: 0;
    right: 0;
    height: 80px;
    transform: scale(1.2);
    position: absolute;
    border-radius: 100%;
    @media (min-width: 768px) {
      height: 200px;
      top: -100px;
    }
  }
  &::after {
    content: '';
    display: block;
    background: #ffffff;
    z-index: 0;
    bottom: -40px;
    left: 0;
    right: 0;
    height: 80px;
    transform: scale(1.2);
    position: absolute;
    border-radius: 100%;
    @media (min-width: 768px) {
      height: 200px;
      bottom: -100px;
    }
  }
`;
