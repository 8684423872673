import React from 'react';
import PropTypes from 'prop-types';

const CloseForm = ({ handleClose, light }) => (
  <button
    type="button"
    className={`overlay-close-btn ${light ? 'overlay-close-btn_light' : ''}`}
    onClick={() => {
      handleClose();
    }}
  >
    Close
  </button>
);

CloseForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  light: PropTypes.bool,
};

export default CloseForm;
