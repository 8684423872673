import { restrictedState } from '../../../common/FormWithSteps/Utilities';

// Do we ever care if an advisor fails CV? If not, we can simplify this function by removing the check for 'PASS'.
/**
 * Determines whether a purchase can be made based on the employer state code and advisor CV status.
 * @param {string} employerStateCode - The state code of the employer.
 * @param {Array<string>} advisorCvStatus - An array of advisor CV status.
 * @returns {boolean} - Returns true if a purchase can be made, false otherwise.
 */
export const canPurchase = (employerStateCode, advisorCvStatus) => {
  if (
    !restrictedState(employerStateCode) ||
    employerStateCode === 'FL' ||
    employerStateCode === 'TX' ||
    employerStateCode === 'GA'
  )
    return true;
  // NOTE: This is unreachable code, but it's here to match the original implementation.
  if (restrictedState(employerStateCode)) {
    return advisorCvStatus.every((status) => status === 'PASS');
  }
  return false;
};
