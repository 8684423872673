import React from 'react';
import { HeroWrapper } from './index.styles';

const HeroSection = () => (
  <div className="disclosures-page mb-5">
    <HeroWrapper className="hero mb-5 pt-5">
      <div className="container-fluid container-fluid--sitewidth">
        <div className="row justify-content-center py-5">
          <h1 className="fs-h1 text-center fw-bold py-5 col-xl-6 col-lg-8 col-md-10 mt-5">
            Disclosures
          </h1>
        </div>
      </div>
    </HeroWrapper>
    <div className="container pb-5">
      <div className="row pb-5">
        <div className="col-md-9 mx-auto">
          <p className="fs-p">
            Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            401(k) plan recordkeeping and administrative services are provided
            through Decimal, Inc. dba Ubiquity Retirement + Savings
            <sup>
              <small>®</small>
            </sup>
            (“Ubiquity”). Ubiquity is not affiliated with any plan and
            investment administrative services provided through Principal Life
            Insurance Company, or affiliated with any company of the Principal
            Financial Group
            <sup>
              <small>®</small>
            </sup>
            . Principal makes available a collective investment trust and a
            mutual fund for customers to select through Simply Retirement by
            Principal
            <sup>
              <small>®</small>
            </sup>
            . All other services and mutual funds are provided by service
            providers not affiliated with any company of the Principal Financial
            Group. Refer to related documents and agreements for more details on
            plan services available.
          </p>

          <p className="fs-p">
            Mutual fund, separate account and collective investment trust trade
            instructions are processed by Matrix Settlement & Clearance
            Services, LLC through the NSCC Membership of its affiliate, Matrix
            Trust Company.
          </p>

          <p className="fs-p">
            Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            requires a client service agreement between Ubiquity and the plan
            sponsor (Customer). In addition to and as part of the services, each
            Customer will sign separate agreements with the following parties:
            (i) custodian and (ii) 3(38) investment manager. Simply Retirement
            by Principal services, costs and resources are subject to change.
          </p>

          <p className="fs-p">
            Investment advisory services are offered through OneDigital
            Investment Advisors LLC., an SEC-registered investment adviser and
            wholly owned subsidiary of OneDigital. Please note the other
            companies referenced on this website are separate entities from
            OneDigital Investment Advisors and are not authorized to provide,
            and do not provide, investment advisory or securities brokerage
            services.
          </p>

          <h3 className="fs-h3">Investment disclosures</h3>
          <p className="fs-p">
            Qualified startup costs (1) In general “qualified startup costs” is
            ordinary and necessary expenses of an eligible employer which are
            paid or incurred in connection with (i) the establishment or
            administration of an eligible employer plan, or (ii) the retirement
            related education of employees with respect to such plan. (2) Plan
            must have at least 1 participant: would not apply if plan does not
            have at least 1 employee eligible to participate who is not a highly
            compensated employee.
          </p>

          <p className="fs-p">
            <strong>
              Asset allocation and diversification does not ensure a profit or
              protect against a loss.
            </strong>{' '}
            Equity investment options involve greater risk, including heightened
            volatility, than fixed-income investment options. Fixed- income
            investments are subject to interest rate risk; as interest rates
            rise their value will decline.{' '}
            <strong>International and global investing</strong>
            involves greater risks such as currency fluctuations,
            political/social instability and differing accounting standards.
            These risks are magnified in <strong>emerging markets</strong>.
            Fixed-income and asset allocation investment options that invest in
            mortgage securities are subject to increased risk due to{' '}
            <strong>real estate exposure</strong>. Small and mid-cap stocks may
            have additional risks including greater price volatility. The
            performance and risks of a fund of funds directly correspond to the
            performance and risks of the underlying funds in which the fund
            invests.
          </p>

          <p className="fs-p">
            There is no guarantee that a target date investment will provide
            adequate income at or through retirement. A target date fund’s (TDF)
            glide path is typically set to align with a retirement age of 65,
            which may be your plan’s normal retirement date (NRD). If your
            plan’s NRD/age is different, the plan may default you to a TDF based
            on the plan’s NRD/Age. Participants may choose a TDF that does not
            match the plan’s intended retirement date but instead aligns more to
            their investment risk. Compare the different TDF’s to see how the
            mix of investments shift based on the TDF glide path.
          </p>

          <p className="fs-p">
            Information provided on the websites regarding our products and
            services is not intended to take the place of our agreements
            governing our products and services, or our disclosures made in
            accordance with federal and state law.
          </p>

          <p className="fs-p">
            Additionally, this information does not take the place of any
            legally required disclosures for any products or services offered by
            any of the member companies of Principal.
          </p>

          <p className="fs-p">
            Ubiquity Retirement + Savings
            <sup>
              <small>®</small>
            </sup>
            , OneDigital and Surety Bonds Direct are not affiliates of any
            company of the Principal Financial Group.
          </p>

          <p className="fs-p">
            <strong>
              Mutual funds and other securities are not insured by the FDIC,
              have no bank guarantee, involve risk, and may lose value.
            </strong>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default HeroSection;
