import { advisorNextAction } from './advisorNextAction';
import { employerCanPurchasePlan } from './employerCanPurchasePlan';
import { tpaNextAction } from './tpaNextAction';

export const validUser = (
  userRole,
  employerStateCode,
  hasFp,
  hasTpa,
  advisorInfo,
  tpaInfo,
  employerInfo,
  formData,
  isValid,
) => {
  switch (userRole) {
    case 'employer':
    case 'psivet':
    case 'onedigital':
      return employerCanPurchasePlan(
        employerStateCode,
        hasFp,
        hasTpa,
        advisorInfo,
        tpaInfo,
        employerInfo,
        formData,
      );
    case 'advisor':
      return advisorNextAction(userRole, advisorInfo, formData, isValid);
    case 'tpa':
      return tpaNextAction(userRole, advisorInfo, tpaInfo, formData, isValid);
    default:
      return 'anonymous';
  }
};
