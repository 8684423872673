import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, { selectCanBeAnonymous } from '../Redux/FormDataRedux';

const SkipEmployerBtn = ({
  canBeAnonymous,
  putRole,
  resetEmployerData,
  putActiveStep,
}) => {
  if (canBeAnonymous) {
    return (
      <p className="text-center">
        <button
          type="button"
          className="srp-button srp-button__link fs-p lh-125 fw-bold"
          onClick={() => {
            putRole('anonymous');
            resetEmployerData();
            putActiveStep('step_1');
          }}
        >
          I’d like to continue without giving my information right now.
        </button>
      </p>
    );
  }
  return false;
};

SkipEmployerBtn.propTypes = {
  canBeAnonymous: PropTypes.bool.isRequired,
  putRole: PropTypes.func.isRequired,
  resetEmployerData: PropTypes.func.isRequired,
  putActiveStep: PropTypes.func.isRequired,
};
const mapStateToProps = (store) => ({
  canBeAnonymous: selectCanBeAnonymous(store),
});

const mapDispatchToProps = (dispatch) => ({
  putActiveStep: (UpdateStep) => {
    dispatch(FormDataRedux.putActiveStep('planDesign', UpdateStep));
  },
  resetEmployerData: () => {
    dispatch(FormDataRedux.resetEmployerData());
  },
  putRole: (role) => {
    dispatch(FormDataRedux.putRole(role));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SkipEmployerBtn);
