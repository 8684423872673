import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toCamelCase, useResizeObserver } from './utils';

const TabLink = ({ id, index, activeTab, setTab, title, setNavLinksWidth }) => {
  const [linkRef, observer] = useResizeObserver();
  const { width: linkRefWidth } = observer.contentRect || 0;
  useEffect(() => {
    setNavLinksWidth((prevNavWidths) => ({
      ...prevNavWidths,
      [id]: { index, width: linkRefWidth, target: observer.target },
    }));
  }, [linkRefWidth]);
  return (
    <li
      ref={linkRef}
      className={classNames('principal-nav__item', {
        active: activeTab === id,
      })}
      role="tab"
      aria-controls={`panel_${id}`}
      aria-selected={activeTab === id ? 'true' : 'false'}
      aria-label={title}
      id={`tab_${id}`}
    >
      <a
        aria-label={title}
        onClick={(e) => {
          observer.target.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'nearest',
          });
          setTab(id);
          e.preventDefault();
        }}
        href={`#${id}`}
        className="principal-nav__link"
      >
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </a>
    </li>
  );
};

TabLink.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  activeTab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setNavLinksWidth: PropTypes.func.isRequired,
};

export const Navigation = ({
  children,
  activeTab,
  setTab,
  navLinksWidth,
  setNavLinksWidth,
}) => (
  <>
    {children.map((child, index) => {
      const id = toCamelCase(child.props.title) + index;
      const { title } = child.props;
      return (
        <TabLink
          key={id}
          id={id}
          index={index}
          activeTab={activeTab}
          setTab={setTab}
          title={title}
          navLinksWidth={navLinksWidth}
          setNavLinksWidth={setNavLinksWidth}
        />
      );
    })}
  </>
);

Navigation.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        title: PropTypes.any.isRequired,
        component: PropTypes.any.isRequired,
      }),
    }),
  ),
  activeTab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  navLinksWidth: PropTypes.object.isRequired,
  setNavLinksWidth: PropTypes.func.isRequired,
};
