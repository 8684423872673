export const entryDateDiscretionaryProfitContribution = {
  id: 'bQ4ai1d1c',
  type: 'radio_button',
  name: 'entryDateDiscretionaryProfitContribution',
  paradigmAlias: 'entryDateDiscretionaryProfitContribution',
  user: {},
  label:
    'What is the entry date for discretionary profit-sharing contributions?',
  options: [
    {
      id: 1,
      user: {},
      label: 'Daily / Immediate',
      value: 'dailyImmediate',
      valueFormatted: 'Daily / Immediate',
      checked: false,
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: 'Monthly',
      info: '(First day of the month following the date the eligibility requirements are satisfied)',
      value: 'monthly',
      valueFormatted: 'Monthly',
      checked: false,
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: 'Quarterly',
      info: '(First day of the calendar quarter following the date the eligibility requirements are satisfied)',
      value: 'quarterly',
      valueFormatted: 'Quarterly',
      checked: false,
      customStyle: '',
    },
    {
      id: 4,
      user: {},
      label: 'Semi-annually (1/1 and 7/1)',
      value: 'semiAnnually',
      valueFormatted: 'Semi-annually',
      checked: false,
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Discretionary profit-sharing date',
  reviewScreenTitle: 'Discretionary profit-sharing date',
  optionDescription: '',
  description:
    'This is the entry date for discretionary profit-sharing contributions. Monthly is the first day of the month following the date the eligibility requirements are satisfied. Quarterly is the first day of the calendar quarter following the date the eligibility requirements are satisfied.',
};
