import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BlobProvider, Font, Document } from '@react-pdf/renderer';
import Page1 from './Page1';
import Page2 from './Page2';
import SummaryPageFP from './Page3Fp';
import SummaryPageTPA from './Page3Tpa';
import { SubmitQuickQuoteSalesforceProposal } from '../../containers/FinancialProfessionals/PlanProposal/QuickQuote/Utilities';
import { AppVariables } from '../../AppVariables';
import FontElliotRegular from '../../../assets/fonts/fselliotpro-regular-webfont.woff';
import FontElliotBold from '../../../assets/fonts/fselliotpro-bold-webfont.woff';
import FontElliotItalic from '../../../assets/fonts/fselliotpro-italic-webfont.woff';
import FontRegisteredSymbol from '../../../assets/fonts/registered-symbol-superscript.woff';

Font.register({
  family: 'Elliot',
  fonts: [
    {
      src: FontElliotRegular,
      fontStyle: 'normal',
      fontWeight: 100,
    },
    { src: FontElliotBold, fontWeight: 400 },
    { src: FontElliotItalic, fontStyle: 'italic' },
  ],
});
Font.register({
  family: 'registered-symbol-superscript',
  fonts: [
    {
      src: FontRegisteredSymbol,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
  ],
});

Font.registerHyphenationCallback((word) =>
  // Return entire word as unique part
  [word],
);

export const PlanProposalDoc = ({ data }) => (
  <Document
    title="Simply Retirement by Principal® 401(k) Plan Proposal"
    author="Principal®"
    subject="Plan Proposal"
    pdfVersion="1.0.0"
  >
    <Page1 data={data} />
    {data.hasTpa.value && <SummaryPageTPA />}
    {!data.hasTpa.value && <SummaryPageFP />}
    <Page2 data={data} />
  </Document>
);

PlanProposalDoc.propTypes = {
  data: PropTypes.object.isRequired,
};

export const BtnCreateProposalPdf = ({
  data,
  className,
  referrerType,
  disabled,
}) => {
  const document = <PlanProposalDoc data={data} />;
  const { authenticityToken } = useContext(AppVariables);
  return (
    <BlobProvider document={document}>
      {/* eslint-disable-next-line no-unused-vars */}
      {({ blob, url, loading, error }) => {
        if (loading) {
          return (
            <button disabled={disabled} className={className} type="button">
              Loading document...
            </button>
          );
        }
        if (error) return <div>Something went wrong: {error}</div>;
        return (
          <a
            href={url}
            download="Simply-Retirement-by-Principal-Plan-Proposal.pdf"
            disabled={disabled}
            onClick={() =>
              SubmitQuickQuoteSalesforceProposal(
                data,
                referrerType,
                authenticityToken,
              )
            }
            className={className}
          >
            Click to download proposal PDF
          </a>
        );
      }}
    </BlobProvider>
  );
};

BtnCreateProposalPdf.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  referrerType: PropTypes.string,
  disabled: PropTypes.bool,
};
