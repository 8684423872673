import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Radio from './Radio';

export const RadioQuestion = ({
  type,
  role,
  name,
  label,
  value,
  required,
  options,
  onClick = () => false,
  hasError,
  handleQuestionChange,
  infoLabel,
}) => {
  const handleInputChange = (radioValue, valueFormatted) => {
    handleQuestionChange(name, radioValue, valueFormatted);
  };
  const handleClick = (title, content) => {
    onClick(title, content);
  };
  return (
    <div className={classNames('mb-3 form-group', { 'has-error': hasError })}>
      <p className="fs-form-q fw-bold text-center pt-3 pb-2 px-3">
        <strong>{label}</strong>
      </p>
      {infoLabel && (
        <p
          className="fs-p lh-125 text-center mb-4"
          dangerouslySetInnerHTML={{ __html: infoLabel }}
        />
      )}

      <div
        className={classNames('radio-group', {
          'radio-group_inline': type === 'radio_boolean',
        })}
      >
        {options.map((option) => {
          const { user, checked, ...rest } = option;
          const { label: optionLabel, info } = {
            ...user[role !== 'advisor' ? 'employer' : role],
          };
          return (
            <Radio
              key={option.id}
              dirty={value !== ''}
              checked={value === option.value}
              questionId={`${name}_${option.id}`}
              name={name}
              required={required}
              onClick={handleClick}
              handleInputChange={handleInputChange}
              hasError={hasError}
              label={optionLabel}
              info={info}
              {...rest}
            />
          );
        })}
      </div>
    </div>
  );
};

RadioQuestion.propTypes = {
  type: PropTypes.string.isRequired,
  role: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  hasError: PropTypes.bool.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  infoLabel: PropTypes.string,
};

export default RadioQuestion;
