import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux from '../../../../Redux/FormDataRedux';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../../common/FormWithSteps/Utilities';
import {
  Question,
  StepControls,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../common/FormWithSteps';

const BqacaDiscretionaryMatchContribution = ({
  active,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestion,
  role,
  googleData,
  resetUnusedBqacaAltOptions,
  isValid,
  ...stepProps
}) => {
  const primaryQuestion = questions[stepProps.questionId];
  const [validateQuestions, setValidateQuestions] = useState([]);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  useEffect(() => {
    resetUnusedBqacaAltOptions(primaryQuestion.value);
    setValidateQuestions([primaryQuestion]);
  }, [primaryQuestion]);

  return (
    <div className="container-fluid h-100">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Question
            role={role}
            labelClass="fs-form-q fw-bold text-center mb-4"
            radioWrapper="srp-form-toggle"
            type={primaryQuestion.type}
            question={primaryQuestion}
            handleQuestionChange={handleQuestionChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <StepControls
              formInReview={stepProps.formInReview}
              disableReviewBtn={!isValid}
              disabledNextBtn={!checkValidityOnQuestionArray(validateQuestions)}
              goToReviewPlan={stepProps.goToReviewPlan}
              clickNextBtn={() => {
                updateActiveStep(destination(primaryQuestion.value), 'forward');
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

BqacaDiscretionaryMatchContribution.propTypes = {
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  role: PropTypes.string,
  updateQuestion: PropTypes.func.isRequired,
  googleData: PropTypes.object,
  resetUnusedBqacaAltOptions: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  resetUnusedBqacaAltOptions: (value) => {
    dispatch(FormDataRedux.resetUnusedBqacaAltOptions(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BqacaDiscretionaryMatchContribution);
