import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectIsFirmBrokerSplitComp,
  selectAdvisors,
  selectPrimaryAdvisor,
} from '../../../../../Redux/FormDataRedux';
import {
  NextStepBtn,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../../common/FormWithSteps';
import AdvisorAuthHeader from '../../AdvisorAuthHeader';
import AdvisorHeading from './AdvisorHeading';
import FinancialProfessional from './FinancialProfessional';
import {
  pushGoogleDataLayer,
  checkNpnValue,
  checkForErrors,
} from '../../../../../common/FormWithSteps/Utilities';
import Button from '../../../../../common/Button';
import API from '../../../../../Services/api';

const api = API.create();

const AdvisorInformation = ({
  authenticityToken,
  active,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateAdvisor,
  updateAllAdvisors,
  addAdvisor,
  removeAdvisor,
  isFirmBrokerSplitComp,
  advisors,
  putEid,
  primaryAdvisor,
  googleData,
  formData,
  setPlanOriginator,
  updateQuestion,
}) => {
  const { employerStateCode, advisorFirmBroker, firmNameFreeForm } = questions;
  const {
    advisorEmail,
    advisorFirstName,
    advisorLastName,
    advisorStateCode,
    advisorPhone,
    advisorZipCode,
  } = primaryAdvisor.questions;
  const isPrimaryAdvisorValid = checkValidityOnQuestionArray([
    advisorEmail,
    advisorFirstName,
    advisorLastName,
    advisorStateCode,
    advisorPhone,
    advisorZipCode,
  ]);
  const handleQuestionChangeFirmName = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChange = ({ name, updateValue, ...rest }) => {
    updateAdvisor(
      {
        ...rest,
        name,
        dirty: true,
        value: updateValue,
        hasError: checkForErrors(name, updateValue, questions),
      },
      rest.advisorIndex,
    );
  };

  const handleQuestionChangeAllAdvisors = ({ name, updateValue, ...rest }) => {
    updateAllAdvisors({
      ...rest,
      name,
      dirty: true,
      value: updateValue,
      hasError: checkForErrors(name, updateValue, questions),
    });
  };

  const validateAdvisors = () =>
    advisors.map((advisor, index) => {
      const validate = [
        advisor.questions.advisorNpn,
        advisor.questions.advisorEmail,
        advisor.questions.advisorFirstName,
        advisor.questions.advisorLastName,
        advisor.questions.advisorZipCode,
        advisor.questions.advisorPhone,
        advisor.questions.advisorStateCode,
      ];
      if (index === 0) validate.push(firmNameFreeForm);
      if (questions.employerStateCode.value === 'FL') {
        validate.push(advisor.questions.floridaLicenseNumber);
      }
      return checkValidityOnQuestionArray(validate);
    });

  const fetchAdvisorEid = () => {
    advisors.map(async (advisor, index) => {
      const eid = await api.authenticateNpn({
        npn: advisor.questions.advisorNpn.value,
        authenticity_token: authenticityToken,
      });
      putEid(eid.data, index);
    });
  };

  const nextStepAction = () => {
    pushGoogleDataLayer({
      event: 'PlanDesignQuestion',
      PlanDesignAdvisorState: advisorStateCode.value,
      PlanDesignAdvisorClientState: employerStateCode.value,
    });

    if (primaryAdvisor.questions.advisorNpn.value.length === 0) {
      updateActiveStep('noNpnBilling', 'forward');
    } else {
      if (primaryAdvisor.questions.advisorNpn.value) fetchAdvisorEid();
      updateActiveStep(destination, 'forward');
    }
  };

  const uniqueId = (name, index) => (index <= 0 ? name : `${name}_${index}`);

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  useEffect(() => {
    if (
      formData.planOriginator === '' &&
      advisorFirstName.value !== '' &&
      advisorLastName.value !== '' &&
      advisorEmail.value !== ''
    ) {
      const planOriginator = {
        name: `${advisorFirstName.value} ${advisorLastName.value}`,
        email: advisorEmail.value,
        role: 'advisor',
      };
      setPlanOriginator(planOriginator);
    }
  }, [advisorFirstName.value, advisorLastName.value, advisorEmail.value]);

  useEffect(() => {
    advisors.forEach((advisor, index) => {
      const { advisorNpn } = advisor.questions;
      updateAdvisor(
        {
          name: advisorNpn.name,
          required: true,
          hasError: checkNpnValue(advisorNpn.value, questions),
          dirty: true,
        },
        index,
      );
    });
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <AdvisorAuthHeader step={4} />
      {!!isFirmBrokerSplitComp && (
        <div className="row">
          <div className="col-md-8 mx-auto">
            <p>
              Because you chose {advisorFirmBroker.valueFormatted} as your
              broker dealer you may add additional financial professionals to
              this plan.
            </p>
          </div>
        </div>
      )}

      {advisors.map((advisor, index) => {
        const indexKey = index;
        return (
          <div key={indexKey}>
            <div className="row">
              <div className="col-md-8 mx-auto d-flex flex-lg-row flex-column align-items-lg-center align-items-start">
                <AdvisorHeading
                  isFirst={index < 1}
                  removeAdvisor={removeAdvisor}
                  advisorIndex={index}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 mx-auto">
                <FinancialProfessional
                  advisorIndex={index}
                  advisor={advisor.questions}
                  firmNameFreeForm={firmNameFreeForm}
                  uniqueId={uniqueId}
                  handleQuestionChange={handleQuestionChange}
                  handleQuestionChangeFirmName={handleQuestionChangeFirmName}
                  handleQuestionChangeAllAdvisors={
                    handleQuestionChangeAllAdvisors
                  }
                  employerStateCode={employerStateCode}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="row">
        <div className="col-md-8 mx-auto">
          <hr />

          {isFirmBrokerSplitComp && advisors.length <= 4 && (
            <p className="text-muted">
              <Button
                classes="srp-button srp-button--outline"
                disabled={!isPrimaryAdvisorValid}
                clickEvent={() => {
                  addAdvisor();
                  updateAllAdvisors({
                    name: 'advisorStateCode',
                    dirty: true,
                    value: advisorStateCode.value,
                    hasError: checkForErrors(
                      'advisorStateCode',
                      advisorStateCode.value,
                      questions,
                    ),
                  });
                  updateAllAdvisors({
                    name: 'advisorZipCode',
                    dirty: true,
                    value: advisorZipCode.value,
                    hasError: checkForErrors(
                      'advisorZipCode',
                      advisorZipCode.value,
                      questions,
                    ),
                  });
                }}
              >
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  style={{ fontWeight: 100, marginRight: '10px' }}
                />
                Add Another Financial Professional
              </Button>
            </p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <NextStepBtn
              disabled={!validateAdvisors().every(Boolean)}
              onClick={() => nextStepAction()}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

AdvisorInformation.propTypes = {
  formData: PropTypes.object.isRequired,
  active: PropTypes.bool,
  destination: PropTypes.any,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  authenticityToken: PropTypes.string,
  addAdvisor: PropTypes.func.isRequired,
  updateAdvisor: PropTypes.func.isRequired,
  updateAllAdvisors: PropTypes.func.isRequired,
  removeAdvisor: PropTypes.func.isRequired,
  isFirmBrokerSplitComp: PropTypes.bool.isRequired,
  advisors: PropTypes.array.isRequired,
  putEid: PropTypes.func.isRequired,
  primaryAdvisor: PropTypes.object.isRequired,
  googleData: PropTypes.object,
  setPlanOriginator: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  isFirmBrokerSplitComp: selectIsFirmBrokerSplitComp(store),
  advisors: selectAdvisors(store),
  primaryAdvisor: selectPrimaryAdvisor(store),
});

const mapDispatchToProps = (dispatch) => ({
  addAdvisor: () => {
    dispatch(FormDataRedux.addAdvisor());
  },
  removeAdvisor: (index) => {
    dispatch(FormDataRedux.removeAdvisor(index));
  },
  updateAdvisor: (obj, index) => {
    dispatch(FormDataRedux.updateAdvisor(obj, index));
  },
  updateAllAdvisors: (obj) => {
    dispatch(FormDataRedux.updateAllAdvisors(obj));
  },
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  putEid: (obj, index) => {
    dispatch(FormDataRedux.putEid(obj, index));
  },
  setPlanOriginator: (planOriginator) => {
    dispatch(FormDataRedux.setPlanOriginator(planOriginator));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorInformation);
