import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Question,
  NextStepBtn,
  WithInfoPanel,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import FormDataRedux from '../../../Redux/FormDataRedux';

export const StepQuestion = ({
  role,
  active,
  questionId,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  googleData,
  resetPlanQuestion,
}) => {
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };

  const { value, dirty, hasError } = questions[questionId];
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
    resetPlanQuestion('isCurrentPrincipalPlan', '');
  };
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <WithInfoPanel
        visibleInfoPanel={visibleInfoPanel}
        setVisibility={setVisibility}
        heading={questions[questionId].heading}
        content={questions[questionId].content}
        toggleInfoPanel={toggleInfoPanel}
      >
        <Question
          role={role}
          type={questions[questionId].type}
          labelClass="fs-form-q fw-bold text-center mb-4"
          inputClass="srp-form-input form-control"
          radioWrapper="srp-form-toggle"
          thousandSeparator
          question={questions[questionId]}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!questions[questionId].heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        {questions[questionId].heading && (
          <p className="text-center mb-lg-auto">
            <button
              type="button"
              className="srp-button srp-button__link mx-auto"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Need clarification?
            </button>
          </p>
        )}
        <p className="fws__control">
          <NextStepBtn
            disabled={
              questions[questionId].required ? !(dirty && !hasError) : false
            }
            onClick={() => {
              setVisibility(false);
              pushGoogleDataLayer({
                event: 'PlanDesignQuestion',
                PlanDesignExistingPlanStatus: value,
              });
              if (value === 'yes' && !questions.hasFp.value) {
                updateActiveStep('isCurrentPrincipalPlan', 'forward');
                return false;
              }
              updateActiveStep('employeesCanContribute', 'forward');
              return false;
            }}
          />
        </p>
      </WithInfoPanel>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          setVisibility(false);
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

StepQuestion.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  googleData: PropTypes.object,
  resetPlanQuestion: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  resetPlanQuestion: (questionId) => {
    dispatch(FormDataRedux.resetPlanQuestion(questionId));
  },
});

export default connect(null, mapDispatchToProps)(StepQuestion);
