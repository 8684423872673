import {
  checkValidityOnQuestionArray,
  isEligible,
} from '../../../common/FormWithSteps/Utilities';
import { pushGrandfatherDateQuestions } from '../../../Services/utilities';

import {
  TPA_INITIAL_SECTION_A_DEFAULT,
  TPA_INITIAL_SECTION_A_NONE,
  TPA_INITIAL_SECTION_A_ACA,
  TPA_INITIAL_SECTION_A_QACA,
  TPA_INITIAL_SECTION_B_DEFAULT,
  TPA_INITIAL_SECTION_B_NONE_ALT,
  TPA_INITIAL_SECTION_B_NONE_ALT2,
  TPA_INITIAL_SECTION_B_NONE,
  TPA_INITIAL_SECTION_B_ACA_ALT,
  TPA_INITIAL_SECTION_B_ACA_ALT2,
  TPA_INITIAL_SECTION_B_ACA,
  TPA_INITIAL_SECTION_B_QACA_ALT,
  TPA_INITIAL_SECTION_B_QACA_ALT2,
  TPA_INITIAL_SECTION_B_QACA,
  TPA_INITIAL_PLAN_DEFAULT,
} from '../InitialState/TPAInitialState';

import {
  DEFAULT_PLAN_AUTO_ENROLLMENT_SAFE_HARBOR,
  DEFAULT_PLAN_NO_AUTO_ENROLLMENT_SAFE_HARBOR,
  DEFAULT_PLAN_AUTO_ENROLLMENT_NON_SAFE_HARBOR_EMPLOYER_CONTRIBUTION,
  DEFAULT_PLAN_NO_AUTO_ENROLLMENT_NON_SAFE_HARBOR_EMPLOYER_CONTRIBUTION,
  DEFAULT_PLAN_NO_AUTO_ENROLLMENT_NO_MATCH_CONTRIBUTION,
  DEFAULT_PLAN_AUTO_ENROLLMENT_NO_MATCH_CONTRIBUTION,
} from '../InitialState/DefaultPlanInitialState';

/* ------------- Selectors ------------- */
export const selectFormStat = (state, formId) => state[formId];
export const selectPlanURL = (state) => state.formData.planURL;
export const selectHashId = (state) => state.formData.hashId;
export const selectPurchasePlanURL = (state) => state.formData.purchasePlanURL;
export const selectPlanId = (state) => state.formData.planId;
export const selectTpaPlanOptions = (state) =>
  validateWithTpa(state)
    .sort((a, b) =>
      a.stepName.localeCompare(b.stepName, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    )
    .reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {});

export const selectPlanOptions = (state) => {
  const {
    numberOfEmployees,
    averageSalary,
    serviceEligibility,
    autoEnrollment,
    employerPaidParticipantFees,
    autoDeferralType,
    minimumAutoDeferralPercentage,
    hasTpa,
    employerMatch,
    vestingSchedule,
    hardshipWithdrawals,
    planEffectiveDate,
    hasFp,
    investmentLineup,
    isCurrentPrincipalPlan,
    grandfatherDate,
  } = state.formData.planDesign.questions;
  const planOptionSelections = {
    numberOfEmployees,
    averageSalary,
    serviceEligibility,
    autoEnrollment,
    employerPaidParticipantFees,
    autoDeferralType,
    minimumAutoDeferralPercentage,
    employerMatch,
    vestingSchedule,
    hardshipWithdrawals,
    planEffectiveDate,
    isCurrentPrincipalPlan,
    grandfatherDate,
  };
  if (hasTpa.value) {
    const tpaPlanOptions = selectTpaPlanOptions(state);
    delete tpaPlanOptions.hasGrandfatherDate;
    delete tpaPlanOptions.grandfatherDateHasCustomDate;

    return {
      numberOfEmployees: {
        ...numberOfEmployees,
        stepName: '_tpa_step_1',
        description: '',
        optionDescription: '',
      },
      employerPaidParticipantFees: {
        ...employerPaidParticipantFees,
        stepName: 'employerPaidParticipantFees',
        description: '',
        optionDescription: '',
      },
      ...tpaPlanOptions,
    };
  }
  return hasFp.value
    ? { ...planOptionSelections, investmentLineup }
    : planOptionSelections;
};
export const selectTpaContributionOptions = (state) => {
  const { offerRothContributions, planUsingSafeHarbor, whichSafeHarbor } =
    state.formData.planDesign.questions;
  return {
    offerRothContributions,
    planUsingSafeHarbor,
    whichSafeHarbor,
  };
};

export const selectContributionOptions = (state) => {
  const { safeHarbor, stretchSafeHarbor } = state.formData.planDesign.questions;
  return {
    safeHarbor,
    stretchSafeHarbor,
  };
};
export const selectApplicationPanelInfo = (state) =>
  state.formData.applicationPanelInfo;
export const selectRole = (state) => {
  if (state.formData.role !== '') {
    return state.formData.role;
  }
  return state.formData.defaultRole;
};

export const selectExperience = (state) => {
  if (state.formData.experience !== '') {
    return state.formData.experience;
  }
  return 'anonymous';
};

export const selectPlanOriginator = (state) => state.formData.planOriginator;

export const validateDefaultPlan = (state) => {
  const {
    autoEnrollment,
    employerContributionMatch,
    safeHarbor,
    isCurrentPrincipalPlan,
  } = state.formData.planDesign.questions;

  const planDefault = Object.keys(DEFAULT_PLAN_AUTO_ENROLLMENT_SAFE_HARBOR)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);

  if (isCurrentPrincipalPlan.value === 'yes')
    planDefault.push(isCurrentPrincipalPlan);

  const planNoEnrollmentSafeHarbor = Object.keys(
    DEFAULT_PLAN_NO_AUTO_ENROLLMENT_SAFE_HARBOR,
  )
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const planEnrollmentNoSafeHarborEmployerCont = Object.keys(
    DEFAULT_PLAN_AUTO_ENROLLMENT_NON_SAFE_HARBOR_EMPLOYER_CONTRIBUTION,
  )
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const planNoEnrollmentNoSafeHarborEmployerCont = Object.keys(
    DEFAULT_PLAN_NO_AUTO_ENROLLMENT_NON_SAFE_HARBOR_EMPLOYER_CONTRIBUTION,
  )
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const planNoEnrollmentNoMatch = Object.keys(
    DEFAULT_PLAN_NO_AUTO_ENROLLMENT_NO_MATCH_CONTRIBUTION,
  )
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const planEnrollmentNoMatch = Object.keys(
    DEFAULT_PLAN_AUTO_ENROLLMENT_NO_MATCH_CONTRIBUTION,
  )
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);

  if (autoEnrollment.value === 'yes') {
    if (employerContributionMatch.value === 'yes') {
      if (safeHarbor.value === 'safeHarbor') {
        return [...planDefault];
      }
      return [...planEnrollmentNoSafeHarborEmployerCont];
    }
    return [...planEnrollmentNoMatch];
  }
  if (employerContributionMatch.value === 'yes') {
    if (safeHarbor.value === 'safeHarbor') {
      return [...planNoEnrollmentSafeHarbor];
    }
    return [...planNoEnrollmentNoSafeHarborEmployerCont];
  }
  return [...planNoEnrollmentNoMatch];
};

export const validateWithPsivet = (state) => {
  const {
    numberOfEmployees,
    averageSalary,
    employerPaidParticipantFees,
    planEffectiveDate,
    serviceEligibility,
    minimumAutoDeferralPercentage,
    safeHarbor,
    stretchSafeHarbor,
    autoDeferralType,
    employerContributionMatch,
    employerMatch,
    autoEnrollment,
  } = state.formData.planDesign.questions;
  const validateQuestions = [
    numberOfEmployees,
    averageSalary,
    employerPaidParticipantFees,
    planEffectiveDate,
    serviceEligibility,
    autoEnrollment,
  ];
  if (employerContributionMatch.value === 'yes') {
    validateQuestions.push(safeHarbor);
    if (safeHarbor.value === 'safeHarbor') {
      validateQuestions.push(stretchSafeHarbor);
    } else {
      validateQuestions.push(employerMatch);
    }
  }

  if (autoEnrollment.value === 'yes') {
    validateQuestions.push(minimumAutoDeferralPercentage);
    validateQuestions.push(autoDeferralType);
  }

  pushGrandfatherDateQuestions(state, state.formData.role, validateQuestions);

  return validateQuestions;
};

export const validateWithTpa = (state) => {
  const {
    contributionRequirements,
    autoEnrollment,
    autoEnrollmentPlan,
    b_autoEnrollment, // eslint-disable-line camelcase
    b_autoEnrollmentPlan, // eslint-disable-line camelcase
    bQaca_discretionarySharingContributions, // eslint-disable-line camelcase
    b_hasDiscretionaryEmployerMatch, // eslint-disable-line camelcase
    b_discretionarySharingContributions, // eslint-disable-line camelcase
    bAca_hasDiscretionaryEmployerMatch, // eslint-disable-line camelcase
    bAca_discretionarySharingContributions, // eslint-disable-line camelcase
    bQaca_safeHarborDiscretionaryMatchingContributions, // eslint-disable-line camelcase
    numberOfEmployees,
    hasTpa,
    isCurrentPrincipalPlan,
    hasFp,
    hasGrandfatherDate,
    grandfatherDateHasCustomDate,
    grandfatherDate,
    feeCollectionSource,
  } = state.formData.planDesign.questions;
  const planDefault = Object.keys(TPA_INITIAL_PLAN_DEFAULT)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  planDefault.push(numberOfEmployees);
  if (!hasFp.value && isCurrentPrincipalPlan.value === 'yes') {
    planDefault.push(isCurrentPrincipalPlan);
  }
  planDefault.push(feeCollectionSource);
  planDefault.push(hasGrandfatherDate);

  if (hasGrandfatherDate.value === 'yes') {
    planDefault.push(grandfatherDateHasCustomDate);
    planDefault.push(grandfatherDate);
  }

  const aDefault = Object.keys(TPA_INITIAL_SECTION_A_DEFAULT)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const aAca = Object.keys(TPA_INITIAL_SECTION_A_ACA)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const aQaca = Object.keys(TPA_INITIAL_SECTION_A_QACA)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const aNone = Object.keys(TPA_INITIAL_SECTION_A_NONE)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const bDefault = Object.keys(TPA_INITIAL_SECTION_B_DEFAULT)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const bAca = Object.keys(TPA_INITIAL_SECTION_B_ACA)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const bQaca = Object.keys(TPA_INITIAL_SECTION_B_QACA)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  const bNone = Object.keys(TPA_INITIAL_SECTION_B_NONE)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  let bAcaAltInit = Object.keys(TPA_INITIAL_SECTION_B_ACA_ALT)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  let bAcaAlt2Init = Object.keys(TPA_INITIAL_SECTION_B_ACA_ALT2)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  let bQacaAltInit = Object.keys(TPA_INITIAL_SECTION_B_QACA_ALT)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  let bQacaAlt2Init = Object.keys(TPA_INITIAL_SECTION_B_QACA_ALT2)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  let bAltInit = Object.keys(TPA_INITIAL_SECTION_B_NONE_ALT)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);
  let bAlt2Init = Object.keys(TPA_INITIAL_SECTION_B_NONE_ALT2)
    .map((initQ) => state.formData.planDesign.questions[initQ])
    .filter((q) => q.required);

  if (contributionRequirements.value === 'yes') {
    if (autoEnrollment.value === 'yes') {
      switch (autoEnrollmentPlan.value) {
        case 'aca':
        case 'eaca':
          return [...planDefault, ...aDefault, ...aAca];
        default:
          return [...planDefault, ...aDefault, ...aQaca];
      }
    }
    return [...planDefault, ...aDefault, ...aNone];
  }
  if (contributionRequirements.value === 'no') {
    if (b_autoEnrollment.value === 'yes') {
      if (bAca_hasDiscretionaryEmployerMatch.value === 'no') {
        bAcaAltInit = [];
      }
      if (bAca_discretionarySharingContributions.value === 'no') {
        bAcaAlt2Init = [];
      }
      if (bQaca_safeHarborDiscretionaryMatchingContributions.value === 'no') {
        bQacaAltInit = [];
      }
      if (bQaca_discretionarySharingContributions.value === 'no') {
        bQacaAlt2Init = [];
      }
      switch (b_autoEnrollmentPlan.value) {
        case 'aca':
        case 'eaca':
          return [
            ...planDefault,
            ...bDefault,
            ...bAca,
            ...bAcaAltInit,
            ...bAcaAlt2Init,
          ];
        default:
          return [
            ...planDefault,
            ...bDefault,
            ...bQaca,
            ...bQacaAltInit,
            ...bQacaAlt2Init,
          ];
      }
    }
    if (b_hasDiscretionaryEmployerMatch.value === 'no') {
      bAltInit = [];
    }
    if (b_discretionarySharingContributions.value === 'no') {
      bAlt2Init = [];
    }
    return [...planDefault, ...bDefault, ...bNone, ...bAltInit, ...bAlt2Init];
  }
  if (hasTpa.value)
    return [
      ...planDefault,
      ...aDefault,
      ...aAca,
      ...aQaca,
      ...aNone,
      ...bDefault,
      ...bAca,
      ...bQaca,
      ...bNone,
    ];
  return [];
};

export const isFormValid = (state) => {
  const { hasTpa, employerPaidParticipantFees } =
    state.formData.planDesign.questions;

  let validQuestions;
  if (hasTpa.value) {
    if (state.formData.role === 'tpa') {
      validQuestions = checkValidityOnQuestionArray(validateWithTpa(state));
    } else {
      const planQuestions = validateWithTpa(state);
      planQuestions.push(employerPaidParticipantFees);
      validQuestions = checkValidityOnQuestionArray(planQuestions);
    }
  } else if (
    state.formData.role === 'psivet' ||
    state.formData.role === 'onedigital'
  ) {
    validQuestions = checkValidityOnQuestionArray(validateWithPsivet(state));
  } else {
    const defaultPlan = validateDefaultPlan(state);
    pushGrandfatherDateQuestions(
      state.formData.planDesign.questions,
      state.formData.role,
      defaultPlan,
    );
    validQuestions = checkValidityOnQuestionArray(defaultPlan);
  }
  const validEmployees = isEligible(
    state.formData.planDesign.questions.numberOfEmployees.value,
  );
  if (validQuestions && validEmployees) {
    return true;
  }
  return false;
};

export const selectEmployerQuestions = (state) => {
  const totalEmployerQuestions = [
    'sponsorFirstName',
    'sponsorLastName',
    'sponsorEmail',
    'sponsorEmployerIdentificationNumber',
    'companyName',
    'companyPhone',
    'employerStateCode',
    'insuranceGroupType',
    'hasFp',
    'hasTpa',
  ].map((key) => state.formData.planDesign.questions[key]);

  pushGrandfatherDateQuestions(
    state,
    state.formData.role,
    totalEmployerQuestions,
  );

  return totalEmployerQuestions;
};

export const selectTpaQuestions = (state) => {
  const tpasArr = state.formData.planDesign.tpas;
  const tpaPlan = state.formData.planDesign.questions;
  let totalTpaQuestions = [
    tpaPlan.tpaFirmName,
    tpaPlan.employerIdentificationNumber,
    tpaPlan.feeCollectionSource,
  ];

  tpasArr.forEach((tpa) => {
    const tpaQuestions = [
      tpa.questions.tpaEmail,
      tpa.questions.tpaFirstName,
      tpa.questions.tpaLastName,
      tpa.questions.tpaPhone,
    ];
    totalTpaQuestions = totalTpaQuestions.concat(tpaQuestions);
  });
  pushGrandfatherDateQuestions(state, state.formData.role, totalTpaQuestions);

  return totalTpaQuestions.filter((v) => v.required);
};

export const selectAdvisorQuestions = (state) => {
  let totalAdvisorQuestions = [
    state.formData.planDesign.questions.companyName,
    state.formData.planDesign.questions.insuranceGroupType,
    state.formData.planDesign.questions.employerStateCode,
    state.formData.planDesign.questions.investmentLineup,
  ];
  state.formData.planDesign.advisors.forEach((advisor) => {
    const advisorQuestions = [
      advisor.questions.advisorNpn,
      advisor.questions.advisorEmail,
      advisor.questions.advisorFirstName,
      advisor.questions.advisorLastName,
      advisor.questions.advisorZipCode,
      advisor.questions.advisorPhone,
      advisor.questions.advisorStateCode,
      {
        stepName: 'advisorInformation',
        name: 'brokerType',
        required: true,
        dirty: !!advisor.advisorCredentialVerification.brokerType,
        hasError: !advisor.advisorCredentialVerification.brokerType,
        value: advisor.advisorCredentialVerification.brokerType || '',
      },
    ];
    if (state.formData.planDesign.questions.employerStateCode.value === 'FL') {
      advisorQuestions.push(advisor.questions.floridaLicenseNumber);
    }
    if (
      state.formData.planDesign.questions.advisorFirmBroker.valueFormatted ===
        'PRINCIPAL SECURITIES INC' &&
      state.formData.planDesign.advisors.length > 1
    ) {
      advisorQuestions.push(advisor.questions.compensation);
    }
    totalAdvisorQuestions = totalAdvisorQuestions.concat(advisorQuestions);
  });
  pushGrandfatherDateQuestions(
    state,
    state.formData.role,
    totalAdvisorQuestions,
  );

  return totalAdvisorQuestions;
};

export const selectHasTpaHasFp = (state) => {
  const ret = {
    hasFp: state.formData.planDesign.questions.hasFp,
    hasTpa: state.formData.planDesign.questions.hasTpa,
  };
  return ret;
};

// remove true to limit firm broker split comp rules
export const selectIsFirmBrokerSplitComp = (state) => {
  const isSplitComp =
    state.formData.planDesign.questions.advisorFirmBroker.value?.split_comp;

  return !!isSplitComp;
};

export const selectIsAdvisorIa = (state) =>
  state.formData.planDesign.advisors[0].advisorCredentialVerification
    .brokerType === 'insuranceAgent';

export const selectIsFirmBroker = (state, broker) => {
  const { value } = state.formData.planDesign.questions.advisorFirmBroker.value;
  if (!value || !value.length) {
    return false;
  }
  return value === broker;
};

export const selectPrimaryTPA = (state) => state.formData.planDesign.tpas[0];

export const selectTpas = (state) => state.formData.planDesign.tpas;

export const selectAdvisors = (state) => state.formData.planDesign.advisors;

export const selectPrimaryAdvisor = (state) =>
  state.formData.planDesign.advisors[0];

export const selectCanBeAnonymous = (state) => !state.formData.hashId;

export const selectIsAutoEnrolled = (state) => {
  const {
    contributionRequirements,
    autoEnrollment,
    b_autoEnrollment, // eslint-disable-line camelcase
  } = state.formData.planDesign.questions;
  if (contributionRequirements.value === 'yes') {
    return autoEnrollment.value;
  }
  return b_autoEnrollment.value;
};

export const selectAutoEnrolledPlan = (state) => {
  const {
    contributionRequirements,
    autoEnrollmentPlan,
    b_autoEnrollmentPlan, // eslint-disable-line camelcase
  } = state.formData.planDesign.questions;
  if (contributionRequirements.value === 'yes') {
    return autoEnrollmentPlan.value;
  }
  return b_autoEnrollmentPlan.value;
};

export const selectActiveUserEmail = (state) => {
  const { role, planOriginator } = state.formData;
  switch (role) {
    case 'tpa':
      if (planOriginator.role === 'tpa') {
        return planOriginator.email;
      }
      return state.formData.planDesign.tpas[0].questions.tpaEmail.value;

    case 'advisor':
      if (planOriginator.role === 'advisor') {
        return planOriginator.email;
      }
      return state.formData.planDesign.advisors[0].questions.advisorEmail.value;

    default:
      if (
        planOriginator.role === 'employer' ||
        planOriginator.role === 'psivet' ||
        planOriginator.role === 'onedigital'
      ) {
        return planOriginator.email;
      }
      return state.formData.planDesign.questions.sponsorEmail.value;
  }
};

export const selectQuestion = (state, question) =>
  state.formData.planDesign.questions[question];
