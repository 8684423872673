import React from 'react';
import { PrincipalTabs, Item } from '../../../../common/PrincipalTabs';

import FiduciaryResponsibility from './FiduciaryResponsibility';
import SafeHarborMatching from './SafeHarborMatching';
import PreApprovedDocuments from './PreApprovedDocuments';
import ParticipantCommunication from './ParticipantCommunication';
import DeadlineReminders from './DeadlineReminders';

const ReducedRisk = () => (
  <section className="section section_py-5 pt-0">
    <div className="container-lg">
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <PrincipalTabs panelStyle="shadowed">
            <Item
              title="Fiduciary responsibility"
              component={<FiduciaryResponsibility />}
            />
            <Item
              title="Safe harbor matching"
              component={<SafeHarborMatching />}
            />
            <Item
              title="Pre-approved documents"
              component={<PreApprovedDocuments />}
            />
            <Item
              title="Participant communication"
              component={<ParticipantCommunication />}
            />
            <Item
              title="Deadline reminders"
              component={<DeadlineReminders />}
            />
          </PrincipalTabs>
        </div>
      </div>
    </div>
  </section>
);

export default ReducedRisk;
