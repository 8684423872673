export const oneDigitalAdvisors = [
  {
    name: 'West Staples',
    advisorIndex: 0,
    data: {
      advisorEmail:
        process.env.RAILS_ENV === 'production'
          ? 'Weston.staples@onedigital.com'
          : 'britt.wells+onedigitalWS@shiftdsm.com',
      advisorFirstName: 'Weston',
      advisorLastName: 'Staples',
      repNumber: 'Y3X8F0',
      crd: '6823125',
      advisorStateCode: 'SC',
      advisorZipCode: '29464',
      advisorPhone: '919-573-8644',
      compensation: 100,
    },
  },
  {
    name: 'Anthony John',
    advisorIndex: 1,
    data: {
      advisorEmail:
        process.env.RAILS_ENV === 'production'
          ? 'Anthony.john@onedigital.com'
          : 'britt.wells+onedigitalAJ@shiftdsm.com',
      advisorFirstName: 'Anthony',
      advisorLastName: 'John',
      repNumber: 'G8X9W7',
      crd: '7669067',
      advisorStateCode: 'MO',
      advisorZipCode: '65201',
      advisorPhone: '440-551-5780',
      compensation: 0,
    },
  },
  {
    name: 'Joe Capela',
    advisorIndex: 2,
    data: {
      advisorEmail:
        process.env.RAILS_ENV === 'production'
          ? 'Joe.capela@onedigital.com'
          : 'britt.wells+onedigitalJC@shiftdsm.com',
      advisorFirstName: 'Joe',
      advisorLastName: 'Capela',
      repNumber: '',
      crd: '2708246',
      advisorStateCode: 'OH',
      advisorZipCode: '44281',
      advisorPhone: '330-419-9842',
      compensation: 0,
    },
  },
  {
    name: 'Eric Daina',
    advisorIndex: 2,
    data: {
      advisorEmail:
        process.env.RAILS_ENV === 'production'
          ? 'Eric.Daina@onedigital.com'
          : 'britt.wells+onedigitalED@shiftdsm.com',
      advisorFirstName: 'Eric',
      advisorLastName: 'Daina',
      repNumber: 'C1J0R3',
      crd: '5820164',
      advisorStateCode: 'OH',
      advisorZipCode: '44281',
      advisorPhone: '330-419-9842',
      compensation: 0,
    },
  },
];
