import React from 'react';

const AdvisorFee = () => (
  <div className="py-4 px-5">
    <p className="fs-p fw-bold mb-0">OneDigital Fees</p>
    <p className="fs-p">
      OneDigital also has a fee for providing their expertise and assistance
      with the setup, management of, and advisory services for, your plan.{` `}
    </p>

    <p className="fs-p">
      OneDigital&apos;s monthly advisor fee of $250 ($750 billed quarterly to
      the plan sponsor) and 45 bps (11.25 bps deducted quarterly to the
      participant)
      {` `}
      until plan assets reach defined threshold.{` `}
    </p>

    <p className="fs-p">
      Please refer to OneDigital&apos;s 3(38) agreement for more details.
    </p>
  </div>
);

export default AdvisorFee;
