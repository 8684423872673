import React from 'react';

const DeadlineReminders = () => (
  <div className="py-4 px-5">
    <h5 className="fs-h5 my-2 pt-2">Deadline reminders</h5>
    <p className="fs-p">
      If you’re late for a required task, such as sending in contributions,
      we’ll send you a reminder to help you stay in compliance.
    </p>
  </div>
);

export default DeadlineReminders;
