import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLastLocation } from 'react-router-last-location';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../Redux/FormDataRedux';
import RangeSlider from '../../common/FormWithSteps/Question/RangeSlider';
import CloseForm from '../../common/FormWithSteps/CloseForm';
import Input from './Input';
import {
  numberToCurrency,
  pushGoogleDataLayer,
} from '../../common/FormWithSteps/Utilities';
import { rootRoute } from '../../Services/utilities';

const ROICalculator = ({
  history,
  putApplicationPanelInfo,
  applicationPanelInfo,
}) => {
  const lastLocation = useLastLocation();
  const handleClose = () => {
    if (lastLocation) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  const [age, setAge] = useState('');
  const [contribution, setContribution] = useState('');
  const [retirementAge, setRetirementAge] = useState('');
  const [sliderValue, setSliderValue] = useState(7);
  const [totalContributionValue, setTotalContributionValue] = useState('');
  const [totalBalanceValue, setTotalBalanceValue] = useState('');
  const [inputValue, setInputValue] = useState(7);
  const [sliderInteraction, setSliderInteraction] = useState(0);
  const [ageInteraction, setAgeInteraction] = useState(0);
  const [contributionInteraction, setContributionInteraction] = useState(0);
  const [retireAgeInteraction, setRetireAgeInteraction] = useState(0);

  const inputs = [
    {
      id: 'age',
      label: `If your employee <br/>started at age:`,
      name: 'age',
      value: '',
      autoFocus: true,
      key: 1,
      per: 'years old',
      inputWidth: 70,
    },
    {
      id: 'contribution',
      label: 'and <br/>contributed:',
      name: 'contribution',
      per: '/month',
      thousandSeparator: true,
      prefix: '$',
      value: '',
      key: 2,
      inputWidth: 90,
    },
    {
      id: 'retirement_age',
      label: 'until the <br/>retirement age:',
      name: 'retirement_age',
      value: '',
      key: 3,
      per: 'years old',
      inputWidth: 70,
    },
  ];

  let gaEvent;

  if (typeof window !== 'undefined') {
    gaEvent = rootRoute(window.location.pathname);
  }

  const handleChange = (id, value) => {
    const objIndex = inputs.findIndex((obj) => obj.id === id);
    inputs[objIndex].value = value;
    switch (objIndex) {
      case 0:
        setAge(inputs[0].value);

        if (ageInteraction !== 1) {
          pushGoogleDataLayer({
            event: gaEvent === '/psivet' ? 'psivetRoiAge' : 'roiAge',
          });
        }
        setAgeInteraction(1);

        break;

      case 1:
        setContribution(inputs[1].value);

        if (contributionInteraction !== 1) {
          pushGoogleDataLayer({
            event:
              gaEvent === '/psivet'
                ? 'psivetRoiContribution'
                : 'roiContribution',
          });
        }
        setContributionInteraction(1);
        break;

      case 2:
        setRetirementAge(inputs[2].value);
        if (retireAgeInteraction !== 1) {
          pushGoogleDataLayer({
            event:
              gaEvent === '/psivet' ? 'psivetRoiRetireAge' : 'roiRetireAge',
          });
        }
        setRetireAgeInteraction(1);
        break;

      default:
        break;
    }
  };
  const handleSliderChange = (name, value) => {
    setSliderValue(value);

    if (sliderInteraction !== 1) {
      pushGoogleDataLayer({
        event: gaEvent === '/psivet' ? 'psivetRoiSlider' : 'roiSlider',
      });
    }
    setSliderInteraction(1);
  };
  const valid =
    sliderValue !== '' &&
    age !== '' &&
    retirementAge !== '' &&
    contribution !== '' &&
    age < retirementAge;
  useEffect(() => {
    const principal = contribution;
    const compoundingFrequency = 1;
    const returnRate = parseInt(sliderValue, 10) / 100;
    const recurringDeposit = parseInt(contribution, 10);
    const growthPerMonth =
      (1 + returnRate / compoundingFrequency) ** (compoundingFrequency / 12);
    const numberofMonths =
      (parseInt(retirementAge, 10) - parseInt(age, 10)) * 12;
    const totalContribution = numberToCurrency(
      numberofMonths * recurringDeposit,
    );
    setTotalContributionValue(totalContribution);
    const principalEarnings = principal * growthPerMonth ** numberofMonths;
    const totalBalance =
      principalEarnings +
      (recurringDeposit * (growthPerMonth ** numberofMonths - growthPerMonth)) /
        (growthPerMonth - 1);

    const totalBalanceCurrency = numberToCurrency(parseInt(totalBalance, 10));
    setTotalBalanceValue(totalBalanceCurrency);
  });
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });
  return (
    <div className="fws bg-purple">
      <div
        className="fws__header fadeInDown"
        style={{
          animationDuration: '.2s',
          animationDelay: '0',
        }}
      >
        <CloseForm handleClose={handleClose} />
      </div>

      <div className="container-fluid container-fluid__sitewidth">
        <div className="row justify-content-center">
          <div className="col col-lg-9 mb-3 mb-md-5 text-center">
            <h1 className="fs-h1 fw-bold text-white lh-125">
              See how your employees’ retirement savings could potentially add
              up over time.
            </h1>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xl-7">
            <Input inputs={inputs} handleChange={handleChange} />
            <div className="row py-3 py-md-4 d-sm-flex d-none mx-lg-3">
              <div className="col-12">
                <h2 className="fs-h2 text-white text-center">
                  and had the following rate of return of:
                </h2>
                <RangeSlider
                  name="rate_of_return"
                  label=""
                  value={sliderValue}
                  min={1}
                  max={12}
                  defaultValue={7}
                  hasError={false}
                  handleQuestionChange={handleSliderChange}
                  step={1}
                  invert
                />
                <p className="fs-p text-white text-center mb-auto">
                  (We typically illustrate an annual rate of return of 6%. You
                  can adjust the slider to change the rate of return.)
                </p>
              </div>
            </div>
            <div className="row d-sm-none py-3 py-md-4">
              <div className="col-12 col-sm-6">
                <p className="fw-bold fs-h2 mb-0 pb-3 text-white lh-125">
                  and we assume the rate of return on their retirement plan is a
                  conservative
                </p>
              </div>
              <div className="col-8 col-sm-6 mx-auto mb-5 text-center rc-slider-wrapper">
                <button
                  type="button"
                  aria-label="minus"
                  className="rc-slider-button fa fa-minus text-white mr-3"
                  style={{
                    '--fa-secondary-color': '#111c2f',
                  }}
                  onClick={() => {
                    setInputValue(Math.max(1, inputValue - 1));
                  }}
                />
                <NumericFormat
                  required
                  className="srp-form-input form-control text-purple fs-h3 fw-bold d-inline"
                  allowNegative={false}
                  id={4}
                  suffix="%"
                  value={inputValue}
                  name="return-rate-investment"
                  style={{ width: '60px' }}
                  onValueChange={(values) => {
                    handleSliderChange(4, values.value);
                  }}
                />
                <button
                  type="button"
                  aria-label="plus"
                  className="rc-slider-button fa fa-plus text-white ml-3"
                  style={{
                    '--fa-secondary-color': '#111c2f',
                  }}
                  onClick={() => {
                    setInputValue(Math.min(12, inputValue + 1));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col col-xl-4">
            <div className="principal-card roi-input py-4">
              <p className="fs-h6 text-center text-dark fw-bold pb-3">
                Retirement savings results
              </p>
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-10 justify-content-center">
                  <div className="roi-input-card-border-right roi-input-card-border-bottom">
                    <p className="fs-h6 text-dark text-center mb-0 col-sm-12">
                      your employee would contribute
                    </p>
                    <p className="text-purple fs-h1 text-center fw-bold col-sm-12 pb-lg-2">
                      {valid && totalContributionValue
                        ? totalContributionValue
                        : '-'}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-10 justify-content-center">
                  <p className="fs-h6 text-dark text-center mb-0 col-sm-12 pt-lg-4">
                    by the time your employee would retire, their plan could
                    have over{' '}
                  </p>
                  <p className="text-purple fs-h1 text-center fw-bold col-sm-12">
                    {valid && totalBalanceValue ? totalBalanceValue : '-'}
                  </p>
                </div>
                <div className="col-12 col-md-6 col-lg-10 justify-content-center d-md-flex d-none">
                  <p className="fs-h6 text-dark lh-125 fw-bold text-center">
                    And that&rsquo;s not accounting for any employer match along
                    the way!
                  </p>
                </div>
                <div className="col-12 postion-relative d-xl-none text-dark fs-h3 text-end">
                  <button
                    type="button"
                    aria-label="question"
                    className="fad fa-question-circle ms-auto btn no-border-button fs-h3"
                    onClick={() => {
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        description: `The assumed rate of return in this chart is hypothetical and does not guarantee any future returns nor represent the returns of any particular investment. Amounts shown do not reflect the impact of taxes on pre-tax distributions. Individual taxpayer circumstances may vary. This is for illustrative purposes only.`,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="fs-disclaimer text-white text-center py-5">
        Intended for financial professional and plan sponsor use.
      </p>
      <span className="d-none">1178600-052020</span>
    </div>
  );
};

ROICalculator.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ROICalculator);
