import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { BtnCreateProposalPdf } from '../PortableDocumentFormat';

const Controls = ({ data, isFormInValid }) => {
  const [generatePdf, setGeneratePdf] = React.useState(false);

  React.useEffect(() => {
    if (isFormInValid) {
      setGeneratePdf(false);
    } else {
      setGeneratePdf(true);
    }
  }, [isFormInValid]);

  return (
    <div id="shareLink">
      {!generatePdf && (
        <p className="text-center">
          <Button
            classes={`mb-3 mx-md-2 mx-1 px-md-5 srp-button srp-button__primary fs-p ${
              isFormInValid ? 'disabled' : ''
            }`}
            clickEvent={() => setGeneratePdf(true)}
          >
            {!isFormInValid ? 'Generate PDF' : 'Missing some required fields'}
          </Button>
        </p>
      )}
      {generatePdf && (
        <p className="text-center">
          {typeof window !== 'undefined' && (
            <BtnCreateProposalPdf
              className={`mb-3 mx-md-2 mx-1 px-md-5 srp-button srp-button__primary fs-p ${
                isFormInValid ? 'disabled' : ''
              }`}
              data={data}
              referrerType="advisor"
              disabled={isFormInValid}
            />
          )}
        </p>
      )}
      {isFormInValid && (
        <p className="text-danger text-center">Required fields are marked.</p>
      )}
      {data.hasTpa.value && (
        <p className="text-center">
          For new business, TPA fees deducted from plan assets will be included
          in Ubiquity’s fee disclosure. This includes flat fee or basis point
          TPA compensation taken from plan assets, as well any TPA transaction
          fees. TPA setup cost, annual base cost, and/or per participant cost
          will be collected outside of the plan by the TPA and the TPA will be
          responsible for the collection and disclosure of these fees to the
          plan sponsor.
        </p>
      )}
    </div>
  );
};

Controls.propTypes = {
  data: PropTypes.object.isRequired,
  isFormInValid: PropTypes.bool.isRequired,
};

export default Controls;
