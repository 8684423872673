import React from 'react';
import PropTypes from 'prop-types';
import ResourceCard from '../../../../common/ResourceCard';

export const TabContent = ({ intro, resourceCards }) => (
  <section className="py-5 mt-5">
    <div className="container">
      <div className="row mb-5">
        <div className="col-lg-10 mx-auto">
          <div dangerouslySetInnerHTML={{ __html: intro }} />
          <div className="row row-cols-md-2 row-cols-1">
            {resourceCards.map((card) => (
              <div className="col" key={card.id}>
                <ResourceCard
                  headline={card.headline}
                  date={card.date}
                  link={card.link}
                  linkText={card.linkText}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

TabContent.propTypes = {
  intro: PropTypes.string,
  resourceCards: PropTypes.array,
};

export default TabContent;
