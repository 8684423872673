export const serviceEligibility = {
  id: 9,
  stepName: 'step_3',
  type: 'radio_button',
  name: 'serviceEligibility',
  user: {
    employer: {
      label: 'When will employees be enrolled in the retirement plan?',
    },
    advisor: {
      label: 'When will employees be enrolled in the retirement plan?',
    },
  },
  infoLabel:
    'Full-time and part-time employees who are at least 21 years old will be eligible for contributions.',
  options: [
    {
      id: 1,
      user: {},
      label: 'First day of work',
      value: 'IMMEDIATE',
      valueFormatted: 'First day',
      checked: false,
      icon: 'icon-first-day-o',
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: 'After 3 months of work',
      value: 'THREE_MONTHS',
      valueFormatted: '3 months',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: 'First anniversary',
      value: 'TWELVE_MONTHS',
      valueFormatted: 'First anniversary',
      checked: false,
      icon: 'icon-anniversary-o',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Eligible employees enrolled',
  reviewScreenTitle: 'Employees enrolled starting',
  optionDescription: `
      <p class="mb-0 font-italic"><strong><em>You selected from these options:</em></strong></p>
      <ul class="font-italic"><li><em>First day of work</em></li> <li><em>After 3 months of work</em></li> <li><em>First anniversary</em></li></ul>
    `,
  description:
    'This is how long an eligible employee (age 21 or older) must be employed at the organization before being automatically enrolled in the plan. They can opt out anytime.',
};
