import React from 'react';

export const ReturningCheckList = () => (
  <>
    <h3 className="fs-form-q text-center pb-4 px-md-3">
      Before you review the plan, you’ll be asked to confirm a few things:
    </h3>
    <div className="row">
      <div className="col-md-10 col-lg-8 mx-auto">
        <ul className="fa-ul">
          <li className="fs-p mb-1">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Your contact information.
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Your firm name.
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Your broker-dealer (to identify if yours has specific rules of
            engagement).
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Your compensation information.
          </li>
        </ul>
        <p className="fs-p mt-3 lh-125">
          After you select the investment lineup and review the plan, click
          “Share the proposal” to share your changes with the client or third
          party administrator.
        </p>
      </div>
    </div>
  </>
);

export default ReturningCheckList;
