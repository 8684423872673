import React from 'react';
import PropTypes from 'prop-types';

const IconStopWatchO = ({ fill }) => (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9701 5.70261C11.1043 5.70261 3.90476 12.947 3.90476 21.8989C3.90476 30.8507 11.1043 38.0952 19.9701 38.0952C28.8358 38.0952 36.0354 30.8507 36.0354 21.8989C36.0354 12.947 28.8358 5.70261 19.9701 5.70261ZM2 21.8989C2 11.9089 10.0386 3.79785 19.9701 3.79785C29.9015 3.79785 37.9401 11.9089 37.9401 21.8989C37.9401 31.8889 29.9015 39.9999 19.9701 39.9999C10.0386 39.9999 2 31.8889 2 21.8989Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9701 9.43259C13.1488 9.43259 7.60667 15.0069 7.60667 21.8985C7.60667 28.7901 13.1488 34.3644 19.9701 34.3644C26.7914 34.3644 32.3335 28.7901 32.3335 21.8985C32.3335 15.0069 26.7914 9.43259 19.9701 9.43259ZM5.7019 21.8985C5.7019 13.9687 12.0831 7.52783 19.9701 7.52783C27.8571 7.52783 34.2383 13.9687 34.2383 21.8985C34.2383 29.8283 27.8571 36.2692 19.9701 36.2692C12.0831 36.2692 5.7019 29.8283 5.7019 21.8985Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7556 0.952381C14.7556 0.426395 15.182 0 15.708 0H23.7937C24.3197 0 24.7461 0.426395 24.7461 0.952381V4.75024C24.7461 5.27623 24.3197 5.70262 23.7937 5.70262H15.708C15.182 5.70262 14.7556 5.27623 14.7556 4.75024V0.952381ZM16.6604 1.90476V3.79786H22.8413V1.90476H16.6604Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.613 3.61216C31.869 3.5879 32.1239 3.66817 32.3198 3.83472L34.463 5.65696C34.8563 5.99133 34.9116 6.57812 34.5877 6.98007L32.3532 9.75331C32.1922 9.95317 31.9573 10.0796 31.7017 10.1039C31.4462 10.1282 31.1917 10.0483 30.9959 9.88236L28.8527 8.06626C28.4587 7.73241 28.4026 7.14539 28.7262 6.74292L30.9607 3.96355C31.1218 3.76316 31.3571 3.63643 31.613 3.61216ZM31.8318 5.91996L30.7922 7.21308L31.4825 7.79805L32.5225 6.50726L31.8318 5.91996Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1285 19.7467C16.4357 19.3198 17.0309 19.2228 17.4578 19.5301L20.5265 21.7388C20.9534 22.0461 21.0503 22.6413 20.7431 23.0682C20.4358 23.4951 19.8406 23.5921 19.4137 23.2848L16.3451 21.076C15.9182 20.7687 15.8212 20.1736 16.1285 19.7467Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8508 15.1859C27.2343 15.546 27.2532 16.1487 26.8931 16.5321L20.6644 23.1645C20.3044 23.548 19.7017 23.5669 19.3182 23.2068C18.9348 22.8467 18.9159 22.244 19.276 21.8606L25.5046 15.2281C25.8647 14.8447 26.4674 14.8258 26.8508 15.1859Z"
      fill={fill}
      className="fill"
    />
  </g>
);

IconStopWatchO.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconStopWatchO;
