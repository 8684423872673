import React from 'react';
import PropTypes from 'prop-types';

const IconCoinsO = ({ fill }) => (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 1.9896C10.0531 1.9896 1.9896 10.0531 1.9896 20C1.9896 29.9469 10.0531 38.0104 20 38.0104C29.9469 38.0104 38.0104 29.9469 38.0104 20C38.0104 10.0531 29.9469 1.9896 20 1.9896ZM0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0001 13.9906C16.6815 13.9906 13.9912 16.6809 13.9912 19.9995C13.9912 23.3181 16.6815 26.0084 20.0001 26.0084C23.3187 26.0084 26.009 23.3181 26.009 19.9995C26.009 16.6809 23.3187 13.9906 20.0001 13.9906ZM12.0016 19.9995C12.0016 15.582 15.5826 12.001 20.0001 12.001C24.4175 12.001 27.9986 15.582 27.9986 19.9995C27.9986 24.4169 24.4175 27.998 20.0001 27.998C15.5826 27.998 12.0016 24.4169 12.0016 19.9995Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0069 5.43311C20.5563 5.43311 21.0017 5.87849 21.0017 6.4279V12.7176C21.0017 13.267 20.5563 13.7124 20.0069 13.7124C19.4575 13.7124 19.0121 13.267 19.0121 12.7176V6.4279C19.0121 5.87849 19.4575 5.43311 20.0069 5.43311Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3701 5.05516C19.7292 4.77186 20.2351 4.76984 20.5964 5.05027L23.5135 7.31457C23.9475 7.65146 24.0262 8.27639 23.6893 8.7104C23.3524 9.1444 22.7275 9.22313 22.2935 8.88625L19.9914 7.09934L17.7332 8.88135C17.3018 9.22169 16.6763 9.14795 16.336 8.71665C15.9956 8.28535 16.0694 7.65981 16.5007 7.31947L19.3701 5.05516Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9524 26.0024C20.5019 26.0024 20.9472 26.4478 20.9472 26.9972V33.3346C20.9472 33.884 20.5019 34.3294 19.9524 34.3294C19.403 34.3294 18.9576 33.884 18.9576 33.3346V26.9972C18.9576 26.4478 19.403 26.0024 19.9524 26.0024Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2774 31.051C16.6148 30.6173 17.2398 30.5393 17.6735 30.8767L19.9693 32.6629L22.2338 30.8802C22.6655 30.5403 23.291 30.6148 23.6308 31.0465C23.9706 31.4782 23.8962 32.1036 23.4645 32.4435L20.5882 34.7078C20.2289 34.9907 19.7229 34.9921 19.362 34.7113L16.4517 32.447C16.0181 32.1096 15.9401 31.4846 16.2774 31.051Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.53503 19.8503C5.53503 19.3009 5.98042 18.8555 6.52983 18.8555H12.704C13.2534 18.8555 13.6988 19.3009 13.6988 19.8503C13.6988 20.3997 13.2534 20.8451 12.704 20.8451H6.52983C5.98042 20.8451 5.53503 20.3997 5.53503 19.8503Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.81334 16.1755C9.24696 16.5129 9.32499 17.1379 8.98761 17.5715L7.20139 19.8673L8.98411 22.1319C9.32396 22.5636 9.2495 23.189 8.8178 23.5289C8.38611 23.8687 7.76066 23.7943 7.42081 23.3626L5.15651 20.4863C4.87362 20.1269 4.87218 19.621 5.15301 19.2601L7.41731 16.3498C7.75469 15.9162 8.37971 15.8381 8.81334 16.1755Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2946 19.9113C26.2946 19.3619 26.7399 18.9165 27.2894 18.9165H33.4363C33.9857 18.9165 34.4311 19.3619 34.4311 19.9113C34.4311 20.4607 33.9857 20.9061 33.4363 20.9061H27.2894C26.7399 20.9061 26.2946 20.4607 26.2946 19.9113Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.1473 16.2339C31.5786 15.8936 32.2041 15.9673 32.5445 16.3986L34.8088 19.2681C35.0921 19.6271 35.0941 20.133 34.8137 20.4943L32.5494 23.4114C32.2125 23.8454 31.5875 23.9241 31.1535 23.5873C30.7195 23.2504 30.6408 22.6254 30.9777 22.1914L32.7646 19.8894L30.9826 17.6311C30.6422 17.1998 30.716 16.5743 31.1473 16.2339Z"
      fill={fill}
      className="fill"
    />
  </g>
);

IconCoinsO.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconCoinsO;
