import React from 'react';
import PropTypes from 'prop-types';
import { recordkeepingFee } from './Utilities';

const setupFeeInfo = `
<h2 class="text-white fw-bold fs-h3">What’s included in the one-time $500 setup fee</h2>
<p class='text-white pt-3 fs-sm'>
This one-time fee covers the setup required to administer your plan. Tax credits
may be available to offset a portion of plan startup costs through the SECURE 2.0 Act.
<a class="text-white" target="_blank" href="https://www.principal.com/landing-page/secureact-plansponsor">Find more information here</a> or contact your tax advisor or appropriate counsel for details.
</p>
`;

const recordkeepingInfo = `
<h2 class="text-white fw-bold fs-h3 mb-4">What’s included in the recordkeeping fees</h2>
<p class='text-white pt-3 fs-sm'>
  Monthly recordkeeping fees cover the cost of maintaining the plan, including
  services such as annual nondiscrimination testing, filing and reporting of
  required documentation, ongoing recordkeeping, automated participant signup
  and onboarding, an online platform for plan administration, and a dedicated phone
  number for questions.
</p>
`;

const QuickQuoteCard = ({
  statValue,
  statLabel,
  statHelper,
  moreInfo,
  putApplicationPanelInfo,
}) => (
  <div className="stat">
    <strong className="stat-value">
      <span className="prefix">$</span>
      {statValue}
    </strong>
    {statHelper && (
      <strong className="stat-label">
        <small>{statHelper}</small>
      </strong>
    )}
    <strong
      className="stat-label text-center"
      dangerouslySetInnerHTML={{ __html: statLabel }}
    />
    {moreInfo && (
      <button
        className="srp-button__link p-0 d-block"
        type="button"
        onClick={() => {
          putApplicationPanelInfo({
            hasVisibility: true,
            additionalInformation: moreInfo,
          });
        }}
      >
        What’s included?
      </button>
    )}
  </div>
);

QuickQuoteCard.propTypes = {
  statValue: PropTypes.string.isRequired,
  statLabel: PropTypes.string,
  statHelper: PropTypes.string,
  moreInfo: PropTypes.string,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const QuickQuoteCalculator = ({
  hasTpa,
  numberOfEmployees,
  putApplicationPanelInfo,
  data,
}) => (
  <div className="row plan-proposal__card-row justify-content-center mb-5">
    <div className="col-md-9 mb-5">
      <h4>Your client can offer a 401(k) plan to their employees for:</h4>
    </div>
    {hasTpa.dirty && !hasTpa.value && (
      <div className="col-lg-4 col-md-4 mb-3">
        <div className=" plan-proposal__card principal-card default floating h-100 d-flex justify-content-center flex-column">
          <div className="label">
            <i className="far fa-clock icon" aria-hidden />{' '}
            <span>Up front</span>
          </div>
          <QuickQuoteCard
            statValue="500"
            statLabel="One-time setup fee for bundled plans"
            moreInfo={setupFeeInfo}
            putApplicationPanelInfo={putApplicationPanelInfo}
          />
        </div>
      </div>
    )}
    <div className="col-xl-5 col-lg-6 col-md-8 mb-3">
      <div className=" plan-proposal__card principal-card default floating h-100 d-flex justify-content-around flex-column">
        <div className="label">
          <i className="far fa-calendar-check icon" aria-hidden />{' '}
          <span>Every month</span>
        </div>
        <QuickQuoteCard
          statValue={recordkeepingFee(
            numberOfEmployees,
            data.hasTpa.value ? 145 : 185,
          )}
          statHelper={
            data.hasTpa.value
              ? '($145 + $6 per participant*)'
              : '($185 + $6 per participant*)'
          }
          statLabel="Recordkeeping fee"
          moreInfo={recordkeepingInfo}
          putApplicationPanelInfo={putApplicationPanelInfo}
        />
      </div>
    </div>
  </div>
);

QuickQuoteCalculator.propTypes = {
  hasTpa: PropTypes.object.isRequired,
  numberOfEmployees: PropTypes.number.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default QuickQuoteCalculator;
