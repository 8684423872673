import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectTpaQuestions,
  selectPrimaryTPA,
} from '../../../../../Redux/FormDataRedux';
import {
  NextStepBtn,
  PrevStepBtn,
  saveData,
  uuidContext,
} from '../../../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../../../common/FormWithSteps/Utilities';
import TpaFee from './TpaFee';
import Header from '../Header';

import API from '../../../../../Services/api';
import { createSalesforceProposal } from '../../../../../Services/helpers/Salesforce/salesforceHelper';
import { collectSavedPlanData } from '../../../../../Services/helpers/Paradigm/planHelper';

const api = API.create();

const Fees = ({
  authenticityToken,
  role,
  active,
  questions,
  destination,
  previousStep,
  updateActiveStep,
  updateQuestion,
  primaryTPA,
  setPlanOriginator,
  formData,
  putPlanURL,
}) => {
  const uuid = useContext(uuidContext);
  const { tpaFirstName, tpaLastName, tpaEmail } = primaryTPA.questions;
  const { tpaSetupFee, annualTpaFee, annualTpaPerHeadFee, hasFp } = questions;
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      dirty: true,
    });
  };

  const submitContactInfo = async () => {
    const savePlanData = collectSavedPlanData(formData);
    const saveResponse = await api.savePlan({
      ...savePlanData,
      authenticity_token: authenticityToken,
    });
    if (saveResponse.status === 'success') {
      saveData(uuid, formData);
      putPlanURL(saveResponse.url, saveResponse.hashId);
      await createSalesforceProposal({
        formData,
        authenticityToken,
        stageFlag: 'New',
        link: saveResponse.url,
        advisorCvStatuses: [],
        tpaParadigmPackager: [],
        hashId: saveResponse.hashId,
      });
    }
  };

  const nextStepAction = () => {
    [tpaSetupFee, annualTpaFee, annualTpaPerHeadFee].forEach((q) => {
      updateQuestion({
        name: q.name,
        dirty: true,
      });
    });

    submitContactInfo();
    updateActiveStep(destination, 'forward');
  };

  useEffect(() => {
    pushGoogleDataLayer({
      event: 'PlanDesignView',
      PlanDesignView: 'fees_tpa',
    });
  }, []);

  useEffect(() => {
    if (
      formData.planOriginator === '' &&
      tpaFirstName.value !== '' &&
      tpaLastName.value !== '' &&
      tpaEmail.value !== ''
    ) {
      const planOriginator = {
        name: `${tpaFirstName.value} ${tpaLastName.value}`,
        email: tpaEmail.value,
        role: 'tpa',
      };
      setPlanOriginator(planOriginator);
    }
  }, [tpaFirstName.value, tpaLastName.value, tpaEmail.value]);

  return (
    <div className="container-fluid--sitewidth h-100">
      <Header step={4} />
      <div className="row">
        <div className="col-md-10 d-flex flex-column justify-content-between mx-auto my-md-auto">
          <TpaFee
            role={role}
            questions={questions}
            handleQuestionChange={handleQuestionChange}
          />
          <div className="fws__control">
            <NextStepBtn
              onClick={() => {
                nextStepAction();
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep(hasFp.value)}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

Fees.propTypes = {
  formData: PropTypes.object.isRequired,
  role: PropTypes.string,
  active: PropTypes.bool,
  destination: PropTypes.string.isRequired,
  previousStep: PropTypes.func,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string,
  primaryTPA: PropTypes.object.isRequired,
  setPlanOriginator: PropTypes.func.isRequired,
  putPlanURL: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  tpaInfo: selectTpaQuestions(store),
  primaryTPA: selectPrimaryTPA(store),
});
const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  updateTPA: (obj, index) => {
    dispatch(FormDataRedux.updateTPA(obj, index));
  },
  setPlanOriginator: (planOriginator) => {
    dispatch(FormDataRedux.setPlanOriginator(planOriginator));
  },
  putPlanURL: (planURL, hashId) => {
    dispatch(FormDataRedux.putPlanURL(planURL, hashId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Fees);
