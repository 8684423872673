/**
 * Updates the advisor's CV data with the provided information.
 *
 * @param {Object} state - The current state object. NOTE - this is not the Redux state object, and it's not used in this function.
 * @param {Object} obj - The object containing the new CV data.
 * @param {Object} advisor - The advisor object to be updated.
 * @returns {Object} - The updated advisor object.
 */
export const setAdvisorCvData = (state, obj, advisor) => {
  const update = {
    ...advisor,
    advisorCvData: {
      ...advisor.advisorCvData,
      state: obj.writtenState,
      date: obj.applicationSignedDate,
      mutualFund: obj.mutualFund,
      levelized: obj.levelized,
      brokerType: advisor.advisorCredentialVerification.brokerType,
      distributionPartners: obj.distributionPartners,
      principalError: obj.principalError,
      cvResponse: obj.cvResponse,
    },
  };
  return update;
};
