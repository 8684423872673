import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import {
  LastLocationProvider,
  useLastLocation,
} from 'react-router-last-location';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import reducers, { INITIAL_STATE } from './reducers';
import AppRoutes from './AppRoutes';
import { saveData, uuidContext } from './common/FormWithSteps';

import {
  USER_SELECT_INITIAL_STATE,
  PLAN_DESIGN_INITIAL_STATE,
} from './Redux/FormDataRedux/InitialState';

const tagManagerArgs = {
  gtmId: process.env.RAILS_ENV !== 'staging' ? 'GTM-PL5PHS4' : 'GTM-PKQPHQ2',
};

if (typeof window !== 'undefined') {
  TagManager.initialize(tagManagerArgs);
}

if (process.env.RAILS_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://85c3ed86724f4072af57f865c272fa23@o128337.ingest.sentry.io/1535293',
    integrations: [new BrowserTracing()],
    environment: process.env.RAILS_ENV,
    tracesSampleRate: 1.0,
  });
}

class Router extends React.Component {
  renderRouter = () => {
    if (typeof window !== 'undefined') {
      return <BrowserRouter>{this.props.children}</BrowserRouter>;
    }
    return (
      <StaticRouter location={this.props.path} context={{}}>
        {this.props.children}
      </StaticRouter>
    );
  };

  render() {
    return this.renderRouter();
  }
}

const Application = (props) => {
  const uuid = useContext(uuidContext);
  let data = INITIAL_STATE;
  if (props.plan) {
    const propsPlan = props.plan.formData || props.plan;
    data = {
      ...INITIAL_STATE,
      ...propsPlan,
      createdDate: props.createdAt,
      updatedDate: props.updatedAt,
      planOwner: props.name,
      hashId: props.hashId,
      role: '',
      planDesign: {
        ...INITIAL_STATE.planDesign,
        ...propsPlan.planDesign,
        activeStep: 'begin',
        questions: {
          hasTpa: USER_SELECT_INITIAL_STATE.hasTpa,
          investmentLineup: PLAN_DESIGN_INITIAL_STATE.investmentLineup,
          planNumber: PLAN_DESIGN_INITIAL_STATE.planNumber,
          planName: PLAN_DESIGN_INITIAL_STATE.planName,
          ...INITIAL_STATE.planDesign.questions,
          ...propsPlan.planDesign.questions,
          roleSelection: {
            ...propsPlan.planDesign.questions.roleSelection,
            value: '',
            valueFormatted: '',
          },
        },
      },
    };

    if (typeof window !== 'undefined') {
      saveData(uuid, data);
    }
  }
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    typeof window !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
      : undefined;

  const store = configureStore({
    reducer: reducers,
    formData: data,
    composeEnhancers,
  });

  /* eslint-enable */
  const lastLocation = useLastLocation();
  const GtmInitialState = {
    id: 'GTM-TN78FGB',
    dataLayer: {
      event: 'info-added',
      'business-units': {
        gcbi: false,
        pgi: false,
        pi: false,
        ris: true,
        usis: false,
      },
    },
  };
  return (
    <Provider store={store}>
      <Router path={props.path} lastLocation={lastLocation}>
        <LastLocationProvider>
          <GTMProvider state={GtmInitialState}>
            <AppRoutes {...props} />
          </GTMProvider>
        </LastLocationProvider>
      </Router>
    </Provider>
  );
};

Router.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  path: PropTypes.string,
};

Application.propTypes = {
  path: PropTypes.string,
  plan: PropTypes.object,
  hashId: PropTypes.string,
  formData: PropTypes.object,
  createdAt: PropTypes.string,
  name: PropTypes.string,
  updatedAt: PropTypes.string,
};

export default Application;
