export const handlePlanNumber = (planNumber) => {
  if (planNumber !== undefined && planNumber.dirty) {
    let { value } = planNumber;
    if (value.toString().length > 3) {
      value = ('0', value).slice(-3);
    }
    while (value.toString().length < 3) {
      value = `0${value}`;
    }
    return value;
  }
  return '001';
};
