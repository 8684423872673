import { requireReview } from './requireReview';
import { getInvestmentLineup } from './getInvestmentLineup';

export const setParadigmAdvisors = (
  advisors,
  planDesign,
  advisorCvStatus,
  role,
) => {
  const { advisorCompensation, advisorFee, advisorPoints, investmentLineup } =
    planDesign.questions;

  const paradigmPlanAdvisors = {
    feeCollectionSource:
      advisorCompensation.value === 'option_1' ? 'DIRECT' : 'ASSETS',
    disbursedByUbiquity:
      getInvestmentLineup(investmentLineup) === 'onedigital' ||
      role === 'onedigital',
  };

  if (advisorCompensation.value === 'option_2') {
    if (advisorFee.value) {
      paradigmPlanAdvisors.fixedAmount = parseInt([advisorFee.value], 10);
    } else {
      paradigmPlanAdvisors.bpsAmount = parseInt(
        advisorPoints?.valueFormatted,
        10,
      );
    }
  }

  const { brokerType } = advisors[0].advisorCredentialVerification;
  let advisorType;
  if (brokerType === 'insuranceAgent') {
    advisorType = 'IA';
  } else if (brokerType === 'brokerDealer') {
    advisorType = 'RR';
  } else if (brokerType === 'iarRia') {
    advisorType = 'IAR';
  } else {
    advisorType = '';
  }

  paradigmPlanAdvisors.actors = advisors.map((advisor, index) => {
    const hasNpnValue = !!advisor.questions.advisorNpn.value;
    const hasAdvisorCrd = !!advisor.advisorCredentialVerification.crd.value;
    const showAdvisorCrd = hasAdvisorCrd && !hasNpnValue;
    return {
      advisorService: 'NONE',
      ...(hasNpnValue && { advisorNpn: advisor.questions.advisorNpn.value }),
      requireReview: requireReview(advisor, advisorCvStatus[index]),
      isPrimary: index === 0,
      commissionPct: advisor.questions.compensation.value,
      roles:
        advisor.questions.compensation.value === 0
          ? ['NONCOMMISSIONABLE']
          : ['COMMISSIONABLE'],
      advisorType,
      ...(showAdvisorCrd && {
        advisorCrd: advisor.advisorCredentialVerification.crd.value,
      }),
    };
  });

  return paradigmPlanAdvisors;
};
