import { googleData } from '../../../Services/utilities';

export const stepsOneToSix = (
  hashId,
  role,
  history,
  goToReviewPlan,
  formData,
) => [
  {
    role,
    hashId,
    key: '_tpa_step_1',
    googleData: googleData('_tpa_step_1'),
    questionId: 'numberOfEmployees',
    component: 'EligibleEmployees',
    singleSelection: true,
    destination: (isEligible, cb) => {
      if (!isEligible) {
        history.replace({
          pathname: '/larger-group',
        });
      }
      cb('_tpa_step_5', 'forward');
    },
    goToReviewPlan,
    previousStep: formData.planDesign.questions.hasFp.value
      ? 'tpaFees'
      : 'isCurrentPrincipalPlan',
    showTracker: true,
    activeStepIndex: 1,
    totalSteps: 10,
  },
  // {
  //   role,
  //   key: '_tpa_step_3',
  //   googleData: googleData('_tpa_step_3'),
  //   questionId: 'employerPaidParticipantFees',
  //   component: 'EmployerPaidParticipantFees',
  //   hasSidebar: true,
  //   singleSelection: true,
  //   destination: () => '_tpa_step_5',
  //   goToReviewPlan,
  //   previousStep: '_tpa_step_1',
  //   showTracker: true,
  //   activeStepIndex: 2,
  //   totalSteps: 10,
  // },
  {
    key: '_tpa_step_5',
    questionId: 'offerRothContributions',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => '_tpa_step_6',
    previousStep: '_tpa_step_1',
    googleData: googleData('_tpa_step_5'),
    showTracker: true,
    activeStepIndex: 4,
    totalSteps: 10,
  },
  {
    key: '_tpa_step_6',
    questionId: 'contributionRequirements',
    component: 'ContributionRequirements',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: (value) => (value === 'yes' ? 'a_step_7_1' : 'b_step_7_1'),
    previousStep: '_tpa_step_5',
    googleData: googleData('_tpa_step_6'),
    showTracker: true,
    activeStepIndex: 5,
    totalSteps: 10,
  },
];
