import { INITIAL_STATE } from '../../Redux/FormDataRedux/InitialState';

export const dataSet = {
  companyName: {
    ...INITIAL_STATE.planDesign.questions.companyName,
    user: {},
    label: 'Organization name',
    required: true,
  },
  numberOfEmployees: {
    ...INITIAL_STATE.planDesign.questions.numberOfEmployees,
    user: {},
    label: 'Number of employees',
    placeholder: '',
    required: true,
  },
  hasFp: {
    ...INITIAL_STATE.planDesign.questions.hasFp,
    value: true,
    required: true,
  },
  hasTpa: {
    ...INITIAL_STATE.planDesign.questions.hasTpa,
    value: true,
    required: true,
  },
  advisorType: {
    ...INITIAL_STATE.planDesign.questions.advisorType,
    label: '',
    infoLabel: '',
    required: false,
  },
  advisorPoints: {
    ...INITIAL_STATE.planDesign.questions.advisorPoints,
    user: {},
    label:
      'How many annualized basis points? (up to 250 bps, per year, paid quarterly)',
    infoLabel: '',
    required: false,
  },
  advisorFee: {
    ...INITIAL_STATE.planDesign.questions.advisorFee,
    user: {},
    label:
      'What is your annual flat fee? (up to $5,000 per year, paid quarterly)',
    infoLabel: '',
    labelClass: '',
    required: false,
  },
  advisorEmail: {
    ...INITIAL_STATE.planDesign.advisors[0].questions.advisorEmail,
    label: 'Financial professional email',
    required: true,
  },
  advisorFirstName: {
    ...INITIAL_STATE.planDesign.advisors[0].questions.advisorFirstName,
    label: 'Financial professional first name',
    required: true,
  },
  advisorLastName: {
    ...INITIAL_STATE.planDesign.advisors[0].questions.advisorLastName,
    label: 'Financial professional last name',
    required: true,
  },
  advisorPhone: {
    ...INITIAL_STATE.planDesign.advisors[0].questions.advisorPhone,
    label: 'Financial professional phone number',
    required: true,
  },
  advisorStateCode: {
    ...INITIAL_STATE.planDesign.advisors[0].questions.advisorStateCode,
    user: {},
    label: 'Financial Professional State',
    required: true,
  },
  firmName: {
    ...INITIAL_STATE.planDesign.advisors[0].questions.firmName,
    user: {},
    value: '',
    label: 'Firm Name',
    required: true,
  },
  tpaFirmName: {
    ...INITIAL_STATE.planDesign.questions.tpaFirmName,
    user: {},
    label: 'TPA firm name',
    type: 'dropdown',
    required: true,
  },
  tpaSetupFee: {
    ...INITIAL_STATE.planDesign.questions.tpaSetupFee,
    required: false,
  },
  annualTpaFee: {
    ...INITIAL_STATE.planDesign.questions.annualTpaFee,
    required: false,
  },
  annualTpaPerHeadFee: {
    ...INITIAL_STATE.planDesign.questions.annualTpaPerHeadFee,
    required: false,
  },
  tpaFirstName: {
    ...INITIAL_STATE.planDesign.tpas[0].questions.tpaFirstName,
    user: {},
    label: 'Sales contact first name',
    required: true,
  },
  tpaLastName: {
    ...INITIAL_STATE.planDesign.tpas[0].questions.tpaLastName,
    user: {},
    label: 'Sales contact last name',
    required: true,
  },
  tpaEmail: {
    ...INITIAL_STATE.planDesign.tpas[0].questions.tpaEmail,
    user: {},
    label: 'Sales contact email',
    required: true,
  },
  tpaPhone: {
    ...INITIAL_STATE.planDesign.tpas[0].questions.tpaPhone,
    user: {},
    label: 'Sales contact phone number',
    type: 'tel',
    required: true,
  },
  tpaTbd: {
    type: 'checkbox',
    user: {},
    label: '',
    name: 'tpaTbd',
    required: false,
    dirty: true,
    value: false,
    hasError: false,
    options: [
      {
        id: 1,
        value: true,
        label: 'Yes, but I don’t have their info yet',
        checked: true,
      },
    ],
  },
  tpaCompensationType: {
    type: 'radio_condensed',
    name: 'tpaCompensationType',
    paradigmAlias: 'tpaCompensationType',
    user: {},
    stepName: 'tpaFeesDeductions',
    label: '',
    infoLabel: '',
    options: [
      {
        id: 1,
        user: {},
        label: 'Flat Fee',
        value: 'fixedAmount',
        checked: false,
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Basis Points',
        value: 'bpsAmount',
        checked: false,
        customStyle: '',
      },
    ],
    required: false,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasError: false,
  },
  fixedAmount: {
    type: 'number_field',
    name: 'fixedAmount',
    paradigmAlias: 'fixedAmount',
    user: {},
    stepName: 'tpaFeesDeductions',
    label:
      'What is your annual flat fee? (up to $5,000 per year, paid quarterly)',
    infoLabel: '',
    valuePrefix: '$',
    required: false,
    dirty: true,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasError: false,
  },
  bpsAmount: {
    type: 'number_field',
    name: 'bpsAmount',
    paradigmAlias: 'bpsAmount',
    user: {},
    stepName: 'tpaFeesDeductions',
    label:
      'How many annualized basis points? (up to 250 bps, per year, paid quarterly)',
    infoLabel: '',
    required: false,
    dirty: true,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasError: false,
  },
};
