import moment from 'moment';
import { effectiveMinMaxDate } from '../../Services/planEffectiveDate';

export const verifyPlanEffectiveDate = ({
  isSafeHarbor,
  planDate,
  hasTpa,
  callback,
}) => {
  const date = moment();
  const currentYear = moment().year();
  const daysBeforeOctOne = moment(`${currentYear}-10-1`).subtract(22, 'days');
  const septCutOff = moment(`${currentYear}-09-20`).endOf('day');
  if (hasTpa) return;
  if (date.isBetween(daysBeforeOctOne, septCutOff) && isSafeHarbor) {
    return;
  }
  if (moment(planDate).diff(moment(), 'days') < 21) {
    callback({
      name: 'planEffectiveDate',
      value: effectiveMinMaxDate(isSafeHarbor).min,
      hasError: false,
      dirty: true,
      valueFormatted: effectiveMinMaxDate(isSafeHarbor).min,
    });
  }
};
