import React from 'react';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import Icon from '../../../../Images/icon-laptop-purple.svg';

const SectionOnlineDashboard = () => (
  <section className="section section_py-5 pt-0">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={Icon}
                width={60}
                height={60}
                alt="computer icon with checkmark"
                className="mb-4"
              />
              <h3 className="fs-h3 fw-bold text-purple mb-4">
                Simple-to-use online dashboard
              </h3>
              <p className="fs-h4 fw-normal mb-4">
                With the Simply Retirement by Principal
                <sup>
                  <small>®</small>
                </sup>{' '}
                platform, you’ll have a central place to manage your retirement
                plan.
              </p>
              <p className="fs-h6">
                You’ll be able to add employees, manage contributions, and
                change account settings—all online.
              </p>
            </div>
            <div className="col-lg-6 d-print-none">
              <ReactPlayerLoader
                accountId="1510329453001"
                videoId="6274775432001"
                attrs={{
                  className: 'brightcove-player',
                }}
                embedOptions={{ responsive: { aspectRatio: '16:9' } }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SectionOnlineDashboard;
