import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { styles } from './Styles';

export const SummaryPageTPA = () => (
  <Page
    size="A4"
    style={[styles.page, { display: 'flex', justifyContent: 'space-between' }]}
    wrap
    orientation="portrait"
  >
    <View style={[styles.summaryHeader]}>
      <Text style={[styles.summaryHeaderText]}>Plan features</Text>
    </View>
    <View style={[styles.summaryIconSection]}>
      <Image style={[styles.summaryIcon1]} src="/images/clipboard.png" />
      <Image style={[styles.summaryIcon2]} src="/images/piggy_bank.png" />
      <Image style={[styles.summaryIcon3]} src="/images/money_cycle.png" />
      <Image style={[styles.summaryIcon4]} src="/images/smart_person.png" />
    </View>
    <View style={[styles.summarySectionText]}>
      <Text style={[styles.summaryParagraphHeader, { color: '#002855' }]}>
        Enrollment and fee options
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Employee fee coverage.{' '}
        </Text>{' '}
        By default, the $6 per employee per month fee is paid out of the
        employees’ plan accounts. But employers can choose to cover this fee for
        their employees if they prefer.¹
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Automatic employee enrollment.{' '}
        </Text>
        Employers have the option for employees to be automatically enrolled at
        a default pre-tax contribution percentage set by the employer. Employees
        can change this amount or opt out at any time. In addition, if automatic
        enrollment is selected, employers can choose to have their employees’
        contributions remain fixed unless they change them, or auto-escalate 1%
        each year up to 10% of eligible pay. Note: If automatic enrollment is
        selected, employers still need to add all employees to the recordkeeping
        system in order for the enrollment process to commence.
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Flexible employee eligibility.{' '}
        </Text>{' '}
        Employers choose when their employees will be enrolled in the plan.
        There are various options for the minimum age and service requirements,
        along with plan entry date selections.
      </Text>

      <Text style={[styles.summaryParagraphHeader, { color: '#007fa3' }]}>
        Contributions and matching
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Matching contributions.{' '}
        </Text>{' '}
        Employers aren’t required to provide matching contributions, but if they
        want to, they have many options.
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Roth contributions.{' '}
        </Text>{' '}
        Employers can choose to allow their employees to defer both pre-tax and
        Roth contributions to their 401(k) plan.
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Profit-sharing flexibility.{' '}
        </Text>{' '}
        Employers have the option to contribute company profits back to
        employees’ 401(k) plan accounts.
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Payroll provider integrations.{' '}
        </Text>{' '}
        Ubiquity supports integrations with select payroll providers— helping
        employers save time and reduce errors by automating contribution
        reporting.
      </Text>

      <Text style={[styles.summaryParagraphHeader, { color: '#315470' }]}>
        Streamlined investment and service offering
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Investment option lineups.{' '}
        </Text>{' '}
        Financial professionals can assist in the selection of one of the
        available Wilshire 3(38) investment lineups for each plan. For plans
        without a financial professional, the employer will be defaulted to
        Wilshire 3(38) investment lineup 1.
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Protection through an ERISA fidelity bond.{' '}
        </Text>{' '}
        Employers will get an ERISA fidelity bond to protect the plan’s assets
        (up to $250,000 in assets, which equals a $25,000 bond) for the first
        three years.
        <Text style={[styles.copyDisclaimerSuper, styles.utilities.font4]}>
          5
        </Text>
      </Text>

      <Text style={[styles.summaryParagraphHeader, { color: '#0091da' }]}>
        Participant benefits
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Vesting schedule flexibility.{' '}
        </Text>{' '}
        Employers can choose to have their employees 100% vested in the 401(k)
        plan employer contributions immediately, or they can choose one of the
        other vesting schedule options.
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Loans and hardship withdrawals.{' '}
        </Text>{' '}
        If the employer chooses to offer loans, employees can request to take a
        loan from their 401(k) plan balance and select a loan repayment schedule
        based on the plan’s loan term that best suits them but is no longer than
        five years. Employers can also choose to have the plan allow for
        hardship withdrawals.
      </Text>
      <Text style={[styles.summaryParagraphText]}>
        <Text style={[styles.utilities.fontWeight400]}>
          Financial wellness.{' '}
        </Text>{' '}
        Participants will have access to a comprehensive financial wellness
        platform that provides tools and resources to employees to better manage
        their current and future financial well-being.
      </Text>
    </View>
    <View style={[styles.summaryFooter]}>
      <Text style={[styles.copySummaryFooter1]}>2 | SimplyRetirement.com</Text>
      <Text style={[styles.copySummaryFooter2]}>
        For financial professional use only.
      </Text>
    </View>
  </Page>
);

export default SummaryPageTPA;
