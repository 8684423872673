import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectAdvisors,
} from '../../../../../Redux/FormDataRedux';
import WithInfoPanelMobile from '../../../../../common/FormWithSteps/WithInfoPanel/WithInfoPanelMobile';
import {
  NextStepBtn,
  PrevStepBtn,
  checkValidityOnQuestionArray,
  saveData,
  uuidContext,
} from '../../../../../common/FormWithSteps';
import AdvisorAuthHeader from '../../AdvisorAuthHeader';
import Template from './Template';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../../../common/FormWithSteps/Utilities';
import { STATE_REQUIRED_BASIS_POINTS } from '../../../../../Services/StateRequiredBasisPoints';
import { collectSavedPlanData } from '../../../../../Services/helpers/Paradigm/planHelper';
import { createSalesforceProposal } from '../../../../../Services/helpers/Salesforce/salesforceHelper';
import API from '../../../../../Services/api';

const api = API.create();

const BillingPreferences = ({
  active,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestion,
  advisors,
  googleData,
  putApplicationPanelInfo,
  role,
  authenticityToken,
  formData,
  putPlanURL,
}) => {
  const uuid = useContext(uuidContext);
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };
  const isBasisPointState = (advisorsArray) => {
    const checkState = (advisorState) =>
      STATE_REQUIRED_BASIS_POINTS.some((state) => state === advisorState);
    const restrictedAdvisors = advisorsArray.some((advisor) =>
      checkState(advisor.questions.advisorStateCode.value),
    );
    return restrictedAdvisors;
  };
  const heading = 'About compensation and billing';
  const content = `<p>Simply Retirement by Principal<sup>®</sup> offers investments (separate accounts) made available through a group annuity contract. If you or your firm are compensated from plan assets, you can choose a flat fee (not to exceed 50bp annually), or 25, 50 or 75 basis points annually based on assets under management*. Group annuity contracts are treated as an insurance product and require appropriate insurance licensing and/or security registration to receive compensation. Our credential validation process will determine if you meet the appropriate criteria. If additional information is required, we will work with you to ensure the appropriate requirements (differentiated by state) are met.</p>`;
  const {
    advisorCompensation,
    advisorType,
    advisorFee,
    advisorPoints,
    advisorFirmBroker,
  } = questions;

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChangeCompensation = (
    id,
    updateValue,
    valueFormatted,
  ) => {
    handleQuestionChange(id, updateValue, valueFormatted);
    [advisorType.name, advisorFee.name, advisorPoints.name].forEach((q) => {
      updateQuestion({
        name: q,
        value: '',
        valueFormatted: '',
        hasError: false,
        dirty: updateValue === 'option_1',
      });
    });
    if (advisorFirmBroker.value.compensation === 'variousWithBasisPoints') {
      updateQuestion({
        name: advisorType.name,
        value: 'option_2',
        valueFormatted: 'Basis Points',
        hasError: false,
        dirty: true,
        disabled: true,
      });
    }
  };

  const handleQuestionChangeType = (id, updateValue, valueFormatted) => {
    handleQuestionChange(id, updateValue, valueFormatted);
    updateQuestion({
      name: advisorFee.name,
      value: '',
      valueFormatted: '',
      hasError: false,
      dirty: updateValue === 'option_2',
    });
    updateQuestion({
      name: advisorPoints.name,
      value: '',
      valueFormatted: '',
      hasError: false,
      dirty: updateValue === 'option_1',
    });
  };

  const handleQuestionChangeConfirm = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: updateValue,
    });
  };

  const showAdvisorType =
    advisorCompensation.value === advisorCompensation.options[1].value;

  const showAdvisorFee =
    showAdvisorType && advisorType.value === advisorType.options[0].value;

  const showAdvisorPoints =
    showAdvisorType && advisorType.value === advisorType.options[1].value;

  const requireConfirmationAdvisorFee =
    isBasisPointState(advisors) && advisorFee.value !== '';

  const requireConfirmationAdvisorBasis =
    isBasisPointState(advisors) &&
    parseInt(advisorPoints.valueFormatted, 10) >= 50;

  const advisorQuestions = () => {
    const validateThese = [advisorCompensation, advisorType, advisorPoints];
    if (requireConfirmationAdvisorFee) {
      validateThese.push(questions.confirmAdvisorFee);
    }
    if (requireConfirmationAdvisorBasis) {
      validateThese.push(questions.confirmAdvisorPoints);
    }

    if (advisorFirmBroker.value.compensation !== 'variousWithBasisPoints') {
      validateThese.push(advisorFee);
    }

    return validateThese;
  };

  const submitContactInfo = async () => {
    const savePlanData = collectSavedPlanData(formData);
    const saveResponse = await api.savePlan({
      ...savePlanData,
      authenticity_token: authenticityToken,
    });
    if (saveResponse.status === 'success') {
      saveData(uuid, formData);
      putPlanURL(saveResponse.url, saveResponse.hashId);
      await createSalesforceProposal({
        formData,
        authenticityToken,
        stageFlag: 'New',
        link: saveResponse.url,
        advisorCvStatuses: [],
        tpaParadigmPackager: [],
        hashId: saveResponse.hashId,
      });
    }
  };

  const nextStepAction = () => {
    submitContactInfo();
    const hasMultipleAdvisors = advisors.length > 1;
    updateActiveStep(destination(hasMultipleAdvisors), 'forward');
    pushGoogleDataLayer({
      event: 'PlanDesignQuestion',
      PlanDesignAdvisorCompensation: advisorCompensation.valueFormatted,
    });

    if (advisorType.valueFormatted) {
      pushGoogleDataLayer({
        event: 'PlanDesignQuestion',
        PlanDesignAdvisorType: advisorType.valueFormatted,
      });
      if (advisorPoints.valueFormatted) {
        pushGoogleDataLayer({
          event: 'PlanDesignQuestion',
          PlanDesignAdvisorPoints: advisorPoints.valueFormatted,
        });
      } else {
        pushGoogleDataLayer({
          event: 'PlanDesignQuestion',
          PlanDesignAdvisorFee: advisorFee.valueFormatted,
        });
      }
    }
  };

  useEffect(() => {
    isBasisPointState(advisors);
    pushGoogleDataLayer(googleData);
  }, []);
  return (
    <div className="container-fluid--sitewidth h-100">
      <AdvisorAuthHeader step={5} />

      <Template
        visibleInfoPanel={visibleInfoPanel}
        advisorCompensation={advisorCompensation}
        handleQuestionChangeCompensation={handleQuestionChangeCompensation}
        showAdvisorType={showAdvisorType}
        advisorType={advisorType}
        handleQuestionChangeType={handleQuestionChangeType}
        showAdvisorFee={showAdvisorFee}
        advisorFee={advisorFee}
        requireConfirmationAdvisorBasis={requireConfirmationAdvisorBasis}
        requireConfirmationAdvisorFee={requireConfirmationAdvisorFee}
        questions={questions}
        handleQuestionChange={handleQuestionChange}
        showAdvisorPoints={showAdvisorPoints}
        advisorPoints={advisorPoints}
        handleQuestionChangeConfirm={handleQuestionChangeConfirm}
        toggleInfoPanel={toggleInfoPanel}
        heading={heading}
        content={content}
        setVisibility={setVisibility}
        putApplicationPanelInfo={putApplicationPanelInfo}
        role={role}
      />
      <div className="form-group fws__control">
        <NextStepBtn
          disabled={!checkValidityOnQuestionArray(advisorQuestions())}
          onClick={() => nextStepAction()}
        />
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
      <WithInfoPanelMobile
        heading={heading}
        content={content}
        visibleInfoPanel={visibleInfoPanel}
        toggleInfoPanel={toggleInfoPanel}
        setVisibility={setVisibility}
      />
    </div>
  );
};

BillingPreferences.propTypes = {
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  advisors: PropTypes.array.isRequired,
  googleData: PropTypes.object,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string,
  putPlanURL: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  advisors: selectAdvisors(store),
  formData: store.formData,
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  putPlanURL: (planURL, hashId) => {
    dispatch(FormDataRedux.putPlanURL(planURL, hashId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingPreferences);
