import { produce } from 'immer';

export const putQuestionById = produce(
  (
    draft,
    { formId, questionId, value, hasError, dirty, valueFormatted, disabled },
  ) => {
    draft[formId].inProgress = true;
    draft[formId].questions[questionId].value = value;
    draft[formId].questions[questionId].hasError = hasError;
    draft[formId].questions[questionId].dirty = dirty;
    draft[formId].questions[questionId].valueFormatted = valueFormatted;
    draft[formId].questions[questionId].disabled = disabled;
  },
);
