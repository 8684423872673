import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Switch, useRouteMatch } from 'react-router-dom';
import AppRoute from '../../AppRoute';
import PrivateRoute from '../../PrivateRoute';
import { AppVariables } from '../../AppVariables';

// Layouts
import ApplicationLayout from '../ApplicationLayout';
import ModalLayout from '../ModalLayout';

// Pages
// BusinessOwners
import Home from './Home';
import About from './About';
import WhyChooseUs from './WhyChooseUs';
import RetirementPlans from './RetirementPlans';
import Resources from '../BusinessOwners/Resources';
import LearnTheBasics from './LearnTheBasics';
import OneDigitalFAQ from './Faq';
import Investments from './Investments';
import Disclosures from './Disclosures';
import Sitemap from '../Sitemap';

import SetupPlan from './Plans';
import ReviewPlan from './ReviewPlan';
import ManageCookiesPreferences from '../ManageCookiesPreferences';

const OneDigital = ({ paradigmHost, hashId, isValid }) => {
  const { authenticityToken } = useContext(AppVariables);
  const { url } = useRouteMatch();
  const description =
    'Meet Simply Retirement by Principal®, the online 401(k) plan for small business owners. Learn about this 401(k) plan and sign up today.';

  const oneDigitalRoutes = [
    {
      key: 'onedigital',
      component: Home,
      title: 'OneDigital Homepage',
      description,
    },
    {
      key: 'about',
      component: About,
      title: 'Small Business 401k Market',
      description:
        'Grow your small business retirement plan options with Simply Retirement by Principal®. Create and share a proposal today.',
    },
    {
      key: 'why-simply-retirement',
      component: WhyChooseUs,
      title: ' Small Business 401k',
      description:
        'Simply Retirement by Principal® is an affordable small business 401(k) plan solution. This simple, online platform will give your employees a way to save.',
    },
    {
      key: 'retirement-plans',
      component: RetirementPlans,
      title: '401k Plans for Small Business Owners',
      description:
        'Simply Retirement by Principal® allows you to customize a 401(k) plan for small business owners. Learn about this 401(k) plan and sign up today.',
    },
    {
      key: 'investments',
      component: Investments,
      title: '401k Program For Small Business',
      description:
        'Grow your small business retirement plan options with Simply Retirement by Principal®. Create and share a proposal today.',
    },
    {
      key: 'resources',
      component: Resources,
      title: 'Resources for Setting Up A Small Business 401k Plan',
      description:
        'Simply Retirement by Principal® helps you set up a 401(k) plan for your small business. Learn more about retirement plans and how you can help your employees most.',
    },
    {
      key: 'learn-the-basics',
      component: LearnTheBasics,
      title: 'How Do You Set up a 401k Plan',
      description:
        'Wondering "How do I set up a 401(k) plan?" Simply Retirement by Principal® can help! It’s a simple, affordable 401(k) plan solution for small businesses.',
    },
    {
      key: 'faq',
      component: OneDigitalFAQ,
      title: 'Frequently Asked Questions',
      description:
        'Read some of the frequently asked questions about Simply Retirement by Principal®. Contact us with any additional questions about our small business 401k plans.',
    },
    {
      exact: true,
      key: 'disclosures',
      layout: ApplicationLayout,
      component: Disclosures,
      title: 'Disclosures',
    },
    {
      exact: true,
      key: 'manage-cookie-settings',
      layout: ApplicationLayout,
      component: ManageCookiesPreferences,
      title: 'Manage Cookie Preferences',
    },
    {
      exact: true,
      key: 'sitemap',
      layout: ApplicationLayout,
      component: Sitemap,
      title: 'Sitemap',
    },
  ];

  return (
    <Switch>
      {oneDigitalRoutes.map((route) => {
        const keyPath = url === `/${route.key}` ? url : `${url}/${route.key}`;
        return (
          <AppRoute
            key={route.key}
            exact
            path={`${keyPath}`}
            layout={ApplicationLayout}
            paradigmHost={paradigmHost}
            {...route}
          />
        );
      })}
      <AppRoute
        exact
        path="/onedigital/plans"
        authenticityToken={authenticityToken}
        layout={ModalLayout}
        component={SetupPlan}
        title="Set Up a Plan"
        description={description}
        paradigmHost={paradigmHost}
      />
      <AppRoute
        exact
        path="/onedigital/plans/:id"
        authenticityToken={authenticityToken}
        layout={ModalLayout}
        component={SetupPlan}
        title="Set Up a Plan"
        description={description}
        paradigmHost={paradigmHost}
      />
      <PrivateRoute
        exact
        path="/onedigital/review-plan"
        redirect="/onedigital/plans"
        hashId={hashId}
        authenticityToken={authenticityToken}
        isValid={isValid}
        layout={ApplicationLayout}
        component={ReviewPlan}
        paradigmHost={paradigmHost}
        title="Review Plan"
      />
      <AppRoute
        layout={ApplicationLayout}
        component={Home}
        title="OneDigital Homepage"
        description="Simply Retirement by Principal® is a flat-fee, retirement plan solution allowing small businesses to easily manage an affordable 401(k)."
        paradigmHost={paradigmHost}
      />
    </Switch>
  );
};

OneDigital.propTypes = {
  paradigmHost: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  hashId: PropTypes.string,
};

export default OneDigital;
