import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectEmployerQuestions } from '../../../../Redux/FormDataRedux';

const Header = ({ step, employerInfo }) => {
  const { 7: hasFp } = employerInfo;

  return (
    <div className="row mb-4">
      <div className="col-lg-8 mx-auto">
        <h3 className="fs-form-q fw-bold mb-md-5 text-center">
          Third party administrator and client details
        </h3>
        <ul className="stepwizard py-3">
          <li
            className={`stepwizard__item w-25 text-center ${
              step === 1 ? 'stepwizard_active' : ''
            }`}
          >
            <span className="stepwizard__link">
              <span className="d-md-block d-none">TPA information</span>
              <span className="d-md-none d-block">TPA info</span>
            </span>
          </li>
          <li
            className={`stepwizard__item w-25 text-center ${
              step === 2 ? 'stepwizard_active' : ''
            }`}
          >
            <span className="stepwizard__link">
              <span className="d-md-block d-none">Client information</span>
              <span className="d-md-none d-block">Client info</span>
            </span>
          </li>
          {hasFp.value && (
            <li
              className={`stepwizard__item w-25 text-center ${
                step === 3 ? 'stepwizard_active' : ''
              }`}
            >
              <span className="stepwizard__link">
                <span className="d-md-block d-none">
                  Financial Professional&nbsp;info
                </span>
                <span className="d-md-none d-block">
                  Financial Professional
                </span>
              </span>
            </li>
          )}
          <li
            className={`stepwizard__item w-25 text-center ${
              step === 4 ? 'stepwizard_active' : ''
            }`}
          >
            <span className="stepwizard__link">
              <span>TPA fees</span>
            </span>
          </li>
          <li
            className={`stepwizard__item w-25 text-center ${
              step === 5 ? 'stepwizard_active' : ''
            }`}
          >
            <span className="stepwizard__link">
              <span className="d-md-block d-none">Participant fees</span>
              <span className="d-md-none d-block">Fees</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

Header.propTypes = {
  step: PropTypes.number,
  employerInfo: PropTypes.array.isRequired,
};

const mapStateToProps = (store) => ({
  employerInfo: selectEmployerQuestions(store),
});

export default connect(mapStateToProps)(Header);
