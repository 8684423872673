import { getUrlParameter } from '../../Services/utilities';

export const updateShareUrl = (shareURL, employees, salary) => {
  if (parseInt(employees, 10) > 0 && parseInt(salary, 10) > 0) {
    return `${shareURL}?calculate=${employees}x${salary}#quickquote`;
  }
  return `${shareURL}#quickquote`;
};

export const loadFromParams = (setData) => {
  const values = getUrlParameter('calculate').split('x');
  setData((prevData) => ({
    ...prevData,
    numberOfEmployees: {
      ...prevData.numberOfEmployees,
      value: values[0],
    },
    averageSalary: {
      ...prevData.averageSalary,
      value: values[1],
    },
  }));
};

export const recordkeepingFee = (employees) => {
  const cap = employees >= 100 ? 0 : employees;
  const value = cap ? 175 + 6 * cap : 0;
  const dollarValue = value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return value ? dollarValue : '0.00';
};

export const totalContributions = (employees, salary) => {
  const cap = employees >= 100 ? 0 : employees;
  const value = (cap * salary * 0.04) / 12;
  const dollarValue = value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return value ? dollarValue : '0.00';
};

export const contributionsPerEmployee = (employees, salary) => {
  const cap = employees >= 100 ? 0 : employees;
  const value = (cap * (salary * 0.04)) / (12 * cap);
  const dollarValue = value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return value ? dollarValue : '0.00';
};
