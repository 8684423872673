import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import PredictableNoGuessPricing from './PredictableNoGuessPricing';
import CardSection from './CardSection';
import PurpleSection from './PurpleSection';
import SectionInvestPerform from './SectionInvestPerform';

import {
  WhyChooseUsAdvisorHeaderImage,
  CheckCircle,
  IconBlueMoneyHandO,
  IconMoneyExchange,
  IconData,
  IconChart,
  IconCheckPurple,
  IconRiscPurple,
} from '../../../ImagePaths';
import { asOfDate, investmentCost } from '../../../Services/utilities';

const sectionHeader = {
  image: WhyChooseUsAdvisorHeaderImage,
  alt: 'Employees and business owners meeting at a table.',
  title: 'Why choose us?',
  caption: `
    <p class="fs-display5">
     It’s a simple way to turn small business 401(k) plans into a big opportunity.
    </p>
  `,
  copy: `
    <p class="fs-h6 text-md-left px-md-0 px-5">
      Managing your current client base is a full-time job. It can be tough to find time to grow your business, especially with clients you don’t normally serve. But now there’s a simple way to <strong>create small business retirement plan proposals in minutes without investing a lot of time and effort.</strong>
    </p>`,
};

const sectionDigitalSolution = `
  <img
    src="${IconChart}"
    alt="Chart Icon"
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">
    A true 100% digital process
  </h3>
  <p class="fs-h4 fw-normal mb-4">Simply Retirement by Principal<sup><small>&reg;</small></sup> lets you complete every step in the process online, when and where it’s convenient for you and your clients.</p>
  <p class="fs-h6">From plan design and e-signing documents to census and onboarding, it all takes place on the Simply Retirement by Principal<small><sup>&reg;</sup></small> digital platform. And once the plan is active, your clients can manage their retirement plan online through the simple-to-use dashboard. They’ll be able to add employees, manage contributions, change account settings, and more.</p>
`;

const sectionEnrollment = `
  <img
    src="${IconCheckPurple}"
    alt="Check Purple Icon"
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">
    Automatic enrollment
  </h3>
  <p class="fs-h4 fw-normal">Employees will be automatically enrolled at a default pre-tax contribution percentage set by the business owner.</p>
  <p class="fs-h6"><strong>This automatic enrollment feature may qualify your client for a SECURE 2.0 Act tax credit of up to $500 per tax year for the first three years of their <button id="whysimply-advisor-disclaimer-4" class="text-underline p-0 no-border-button fs-h6 fw-bold">plan</button><sup><small>*</small></sup>.</strong> This is a selling point, as it can help offset some of your clients' costs. Automatic enrollment can also help them increase participation, simplify administration, reduce follow-up, and help employees save for retirement. And participants can change their contribution and investment selection or opt out of the plan at any time.</p>
`;

const sectionPayroll = `
  <img
    src="${IconMoneyExchange}"
    alt="Chart Icon"
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">
    Payroll provider integration
  </h3>
  <p class="fs-h4 fw-normal mb-4">Ubiquity Retirement + Savings<small><sup>&reg;</sup></small> supports integrations with select payroll providers—helping business owners save time and reduce errors by automating contribution reporting.</p>
  <p class="fs-h6">Ubiquity supports direct integrations with dozens of payroll providers, including Paylocity and Namely. ADP, Paychex,
QuickBooks<small><sup>&reg;</sup></small>, and others are supported through add-on third party services. Ubiquity’s Payroll (K)oncierge service is available to help your clients get started.</p>
`;

const sectionRisk = `
  <img
    src="${IconRiscPurple}"
    alt=""
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">
    Reduced risk
  </h3>
  <p class="fs-h4 fw-normal mb-4">Your clients don’t have to be experts on regulations, because the administrative platform helps them determine what to do and when to do it.</p>
  <p class="fs-h6">Simply Retirement by Principal<sup><small>&reg;</small></sup> takes the guesswork out of staying on top of plan compliance. Electronic participant statements, forms for tax filing and plan documents, participant disclosures, annual plan notifications, IRS plan compliance testing and monitoring, and eligibility notices are provided—meaning less work for your clients. Other plan features and options to help your clients with compliance include:</p>
  <ul class="pl-3"><li class="text-purple"><strong>Safe harbor matching options</strong></li><li class="text-purple"><strong>Pre-approved documents for plan participants</strong></li><li class="text-purple"><strong>Participant notifications</strong></li><li class="text-purple"><strong>Deadline reminders</strong></li></ul>
  <p>See the <a href="/financial-professionals/retirement-plans">401(k) page</a> for a full list of plan features.</p>
`;

const sectionDataAccuracy = `
  <img
    src="${IconData}"
    alt="Chart Icon"
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">
    Data accuracy and protection
  </h3>
  <p class="fs-h4 fw-normal mb-4">To put it simply, your clients’ data is accurate and protected.</p>
  <p class="fs-h6">Ubiquity Retirement + Savings<small><sup>&reg;</sup></small> knows how important security is to you and your clients. Ubiquity has completed the requirements for a Service Organization Control (SOC) 1, type 2 audit covering their proprietary Paradigm
Recordkeeping System, which is the platform used by Simply Retirement by Principal<small><sup>&reg;</sup></small>. The SOC 1 standard covers
internal controls relevant to financial reporting at service organizations. Ubiquity’s continued investment in their SOC
1 program and reporting demonstrates their year-over-year commitment to ensuring complete and accurate financial
accounting, as well as proper information technology general controls over their Paradigm Recordkeeping platform.</p>
`;

const financialProfessionalWhySimplyDisclaimer1 = `
<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Retirement plan:</span>Small business retirement plans: How firms perceive benefits & costs, Center for Retirement Research at Boston College, March 26, 2024.</p><p class="fs-disclaimer pt-2">
</p>`;
const financialProfessionalWhySimplyDisclaimer2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Per participating employee:</span>  Fees paid by the business owner are billed quarterly. Fees paid by participants are deducted monthly from participant accounts. Participant fees are charged if there is a $100 account balance, regardless of whether the participant is active or inactive. Custodial and investment fees are charged against participating employees’ accounts (those vary by investment and range from 0.03% – 0.86%, as of ${asOfDate}). If the business owner chooses to work with a financial professional, their fee is also additional and may be billed to the business owner or deducted from participant accounts.</p>`;
const financialProfessionalWhySimplyDisclaimer3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Recordkeeping fee:</span> Pricing shown applies when working with a TPA. Bundled pricing is a $500 initial setup fee, then $185 per month. Fees paid by the business owner are billed quarterly. Fees paid by participants are deducted monthly from participant accounts. Participant fees are charged if there is a $100 account balance, regardless of whether the participant is active or inactive. Custodial and investment fees are charged against participating employees’ accounts (those vary by investment and range from ${investmentCost}, as of ${asOfDate}). If the business owner chooses to work with a financial professional and/or TPA, their fees are also additional and may be billed to the business owner. Financial professional fees may be deducted from participant accounts.</p>`;
const financialProfessionalWhySimplyDisclaimer4 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*What’s included:</span> Plan costs are billed quarterly. Custodial and investment fees are charged against participating employees’ accounts (those vary by investment and range from 0.03% – 0.86%, as of ${asOfDate}). Should you choose to work with a financial professional, their fee is also additional.</p>`;
const financialProfessionalWhySimplyDisclaimer5 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Plan:</span> This credit is for plans that include the eligible automatic contribution arrangement (EACA) feature only. In addition, SECURE 2.0 Act legislation allows small businesses with no more than 100 employees to claim a tax credit of 50% of the qualifying start-up costs for a new employee retirement plan for the first three years of the plan as follows but limited to the greater of (1) $500 or (2) the lesser of (a) $250 for each non-highly compensated employee who is eligible to participate in the plan or (b) $5,000. Information about the SECURE Act is educational only and provided with the understanding that Principal<small><sup>&reg;</sup></small> is not rendering legal, accounting, investment advice or tax advice. You should consult with appropriate counsel or other financial professionals on all matters pertaining to legal, tax, investment or accounting obligations and requirements.</p>`;
const financialProfessionalWhySimplyDisclaimer6 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Investment lineup as appropriate:</span> As part of the Simply Retirement by Principal<sup>®</sup> solution, Wilshire Investments, Inc. is the fiduciary responsible for the selection and monitoring of the investments.</p>`;
const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${financialProfessionalWhySimplyDisclaimer1}
     ${financialProfessionalWhySimplyDisclaimer2}
     ${financialProfessionalWhySimplyDisclaimer3}
     ${financialProfessionalWhySimplyDisclaimer4}
     ${financialProfessionalWhySimplyDisclaimer5}
     ${financialProfessionalWhySimplyDisclaimer6}
    </div>
  </div>
`;

const WhySimplyRetirement = ({
  putApplicationPanelInfo,
  applicationPanelInfo,
}) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = ['whysimply-advisor-disclaimer-4'];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'whysimply-advisor-disclaimer-4':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: financialProfessionalWhySimplyDisclaimer5,
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="why-choose-page mb-5">
      <HeaderSection
        image={sectionHeader.image}
        alt={sectionHeader.alt}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
      />
      <CardSection
        putApplicationPanelInfo={putApplicationPanelInfo}
        financialProfessionalWhySimplyDisclaimer1={
          financialProfessionalWhySimplyDisclaimer1
        }
      />
      <PurpleSection />
      <section className="section py-5">
        <div className="container">
          <img
            src={IconBlueMoneyHandO}
            width="80px"
            height="80px"
            alt="Hand Holding Money Icon"
            className="d-block mx-auto mb-5"
          />
          <h3 className="fs-h3 text-center fw-bold">
            Simply Retirement by Principal
            <small>
              <sup>&reg;</sup>
            </small>{' '}
            advantages
          </h3>
        </div>
      </section>
      <PredictableNoGuessPricing
        putApplicationPanelInfo={putApplicationPanelInfo}
        financialProfessionalWhySimplyDisclaimer2={
          financialProfessionalWhySimplyDisclaimer2
        }
        financialProfessionalWhySimplyDisclaimer3={
          financialProfessionalWhySimplyDisclaimer3
        }
      />
      <SectionSingleCol
        copy={sectionDigitalSolution}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />
      <SectionSingleCol
        copy={sectionEnrollment}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />
      <SectionInvestPerform
        putApplicationPanelInfo={putApplicationPanelInfo}
        financialProfessionalWhySimplyDisclaimer6={
          financialProfessionalWhySimplyDisclaimer6
        }
      />
      <SectionSingleCol
        copy={sectionPayroll}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />
      <SectionSingleCol
        copy={sectionRisk}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />
      <SectionSingleCol
        copy={sectionDataAccuracy}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />

      <section className="section section_py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <img
                src={CheckCircle}
                alt="Checkmark In Circle Icon"
                className="d-block mx-auto mb-3"
              />
              <h3 className="fs-h3 text-center mb-4">Ready to get started?</h3>
              <p className="text-center pb-5 mb-5">
                <Link
                  to="/plans"
                  className="srp-button srp-button__primary px-5"
                >
                  Create a proposal
                </Link>
              </p>
              <SectionSingleCol
                copy={footNoteList}
                columns="col-12"
                className="d-print-block d-none"
              />
              <p className="fs-disclaimer text-center pb-5 mb-5">
                Intended for financial professional and TPA use.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

WhySimplyRetirement.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WhySimplyRetirement);
