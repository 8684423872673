import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import QuestionSection from './QuestionSection';
import TableSection from './TableSection';

import { Plan401kHeaderImageAdvisor, CheckCircle } from '../../../ImagePaths';

const sectionHeader = {
  image: Plan401kHeaderImageAdvisor,
  title: 'The simple 401(k) plan for small businesses',
  caption: `
    <p class="fs-display5">
      Our 401(k) plan solution makes plan design easier for you
      and retirement savings more accessible for your clients.
    </p>
  `,
  alt: 'Employees taking notes in a business meeting.',
  copy: `
    <p class="fs-h6 text-md-left px-md-0 px-5">
     Simply Retirement by Principal<sup>®</sup> is a 401(k) plan solution <strong>that’s designed specifically for small businesses with fewer than 100 employees.</strong>
     This streamlined product includes the features your clients need without extra complexity they don’t.
     It’s a simple, cost-efficient option for business owners looking for an easy way to help their employees save for retirement.
    </p>`,
};

const whyConsiderSection = `
  <h3 class="fs-h3 fw-bold text-purple pb-3">
    Tax credits are available for small businesses just starting a new
    retirement plan.
  </h3>
  <p class="fs-p fw-bold">
    SECURE 2.0 Act legislation could help offset some of your plan start-up costs.
  </p>
  <p class="fs-p">
   The SECURE 2.0 Act allows small businesses with no more than 50 employees to claim a tax credit of 100% of the qualifying start-up costs for a new employee retirement plan (<span id="retirement-plans-advisor-startup-costs-disclaimer" class="text-underline p-0 no-border-button">up to $5,000 per tax year for the first three years</span><sup><small>*</small></sup>). SECURE Act of 2019 allows a tax credit of 50% of the qualifying start-up costs for a new employee retirement plan for 3 years if the employer has 51-100 employees (maximum $5,000 a year).
   </p>
  <p class="fs-p">
    There’s also an extra tax credit for 5 years of up to $1,000 per employee a year for employer contributions made if employers have no more than 50 employees. For employers with more than 51-100 employees, the credit is reduced by 2% for each employee in excess of 50. Under SECURE of 2019, an employer may also be eligible to claim up to $500 tax credit for including an <span id="retirement-plans-advisor-eligible-contribution" class="text-underline p-0 no-border-button">eligible automatic contribution arrangement.</span> Plus, matching contributions you make to employee retirement accounts as noted above can be tax-deductible. For example, a company with 25 employees can see the current plan start-up fee change from $2,200 per year to potentially offset the expenses by 100% with tax credits. See your tax advisor for guidance on how these credits may apply.
  </p>
`;

const financialProfessionalRetirementPlansDisclaimer1 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*Up to $5,000 per tax year for the first three years:</span> 1) $500 or (2) the lesser of (a) $250 for each non-highly compensated employee who is eligible to participate in the plan or (b) $5,000. Qualified startup costs (1) In general “qualified startup costs” is ordinary and necessary expenses of an eligible employer which are paid or incurred in connection with -- (i) the establishment or administration of an eligible employer plan, or (ii) the retirement-related education of employees with respect to such plan. (2) Plan must have at least 1 participant: would not apply if plan does not have at least 1 employee eligible to participate who is not a highly compensated employee. Information about the SECURE 2.0 Act is educational only and provided with the understanding that Principal<sup><small>®</small></sup> is not rendering legal, accounting, investment advice or tax advice. You should consult with appropriate counsel or other advisors on all matters pertaining to legal, tax, investment or accounting obligations and requirements.</p>`;
const financialProfessionalRetirementPlansDisclaimer2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Automatic enrollment:</span> <strong>Automatic enrollment</strong> is a plan provision which automatically enrolls participants into the retirement plan at a specified pre-tax salary deferral percentage. This can help increase participation, simplify administration, and help employees save for retirement. Participants in the Simply Retirement by Principal<sup><small>®</small></sup> 401(k) plan are automatically enrolled, but can change their contribution details or opt out at any time. This credit is for plans that include the eligible automatic contribution arrangement (EACA) feature only.</p>`;
const financialProfessionalRetirementPlansDisclaimer3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*19,500:</span> Up to $30,500 for employees age 50 or older. Amounts are for the 2024 tax year.</p>`;
const financialProfessionalRetirementPlansDisclaimer4 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*19,500:</span> Up to $30,500 for employees age 50 or older. Amounts are for the 2024 tax year.</p>`;
const financialProfessionalRetirementPlansDisclaimer5 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*19,500:</span> Up to $30,500 for employees age 50 or older. Amounts are for the 2024 tax year.</p>`;
const financialProfessionalRetirementPlansDisclaimer6 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*13,500:</span> Up to $19,500 for employees age 50 or older; can’t exceed 100% of compensation. Amount is for the 2024 tax year.</p>`;
const financialProfessionalStartupCostsDisclaimer = `<p class="fs-startup-costs-disclaimer">Start-up tax credit modification: Small employers with 50 or fewer employees may apply 100% of qualified start-up costs towards the tax credit formula (up to $5,000 per year). </p><p>New tax credit for start-up plans offering employer contributions: A tax credit equal to the applicable percentage of employer contributions, capped at a maximum of $1,000 per employee.<ul><li>Applicable to small employers with 50 or fewer employees.</li><li>For employees with 51-100 employees: The credit is phased out by reducing the amount of credit each year 2% for each employee in excess of 50.</li></ul><b>Applicable Percentage:</b><p>1st and 2nd year = 100%, 3rd year = 75%, 4th year = 50%, 5th year = 25%, 6th year = 0% </p><p>No contributions may be counted for employees with wages in excess of $100,000 (inflation adjusted). If taking advantage of this tax credit, employer contributions may not also be counted towards “start-up costs” in the start-up tax credit calculation.</p></p>`;
const financialProfessionalEligibleContributionDisclaimer = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Eligible automatic contribution arrangement:</span> The SECURE Act of 2019 provided an automatic enrollment one-time tax credit possible to be up to $500 per tax year for each year of the 3-taxable-year period beginning with the first taxable year for which the employer includes an eligible automatic contribution arrangement. This credit is for plans that include the eligible automatic contribution arrangement (EACA) feature only. Information about the SECURE Acts is educational only and provided with the understanding that Principal<small><sup>®</sup></small> is not rendering legal, accounting, investment advice or tax advice. You should consult with appropriate counsel or other financial professionals on all matters pertaining to legal, tax, investment or accounting obligations and requirements.</p>`;
const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${financialProfessionalRetirementPlansDisclaimer1}
     ${financialProfessionalRetirementPlansDisclaimer2}
     ${financialProfessionalRetirementPlansDisclaimer3}
     ${financialProfessionalRetirementPlansDisclaimer4}
     ${financialProfessionalRetirementPlansDisclaimer5}
     ${financialProfessionalRetirementPlansDisclaimer6}
     ${financialProfessionalStartupCostsDisclaimer}
     ${financialProfessionalEligibleContributionDisclaimer}
    </div>
  </div>
`;

const RetirementPlans = ({ applicationPanelInfo, putApplicationPanelInfo }) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'retirement-plans-advisor-disclaimer-1',
      'retirement-plans-advisor-disclaimer-2',
      'retirement-plans-employer-disclaimer-4',
      'retirement-plans-employer-disclaimer-4-2',
      'retirement-plans-employer-disclaimer-5',
      'retirement-plans-employer-disclaimer-6',
      'retirement-plans-advisor-startup-costs-disclaimer',
      'retirement-plans-advisor-eligible-contribution',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'retirement-plans-advisor-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation:
            financialProfessionalRetirementPlansDisclaimer1,
        });
        break;

      case 'retirement-plans-advisor-disclaimer-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation:
            financialProfessionalRetirementPlansDisclaimer2,
        });
        break;

      case 'retirement-plans-employer-disclaimer-4':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation:
            financialProfessionalRetirementPlansDisclaimer3,
        });
        break;

      case 'retirement-plans-employer-disclaimer-4-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation:
            financialProfessionalRetirementPlansDisclaimer4,
        });
        break;

      case 'retirement-plans-employer-disclaimer-5':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation:
            financialProfessionalRetirementPlansDisclaimer5,
        });
        break;

      case 'retirement-plans-employer-disclaimer-6':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation:
            financialProfessionalRetirementPlansDisclaimer6,
        });
        break;

      case 'retirement-plans-advisor-startup-costs-disclaimer':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: financialProfessionalStartupCostsDisclaimer,
        });
        break;

      case 'retirement-plans-advisor-eligible-contribution':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation:
            financialProfessionalEligibleContributionDisclaimer,
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="retirment-plans-page">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        alt={sectionHeader.alt}
      />
      <SectionSingleCol
        columns="offset-lg-1 col-lg-10"
        copy={whyConsiderSection}
        className="py-5"
        id="secure-act"
      />
      <QuestionSection />
      <TableSection />
      <section className="section section_py-5 mb-5 bg-iron">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <img
                src={CheckCircle}
                alt="Checkmark In Circle Icon"
                className="d-block mx-auto mb-3"
              />
              <h3 className="fs-h3 text-center mb-4">Ready to get started?</h3>
              <p className="text-center pb-5 mb-5">
                <Link to="/plans" className="srp-button srp-button__primary">
                  Create a proposal
                </Link>
              </p>
              <p className="fs-disclaimer text-center pb-5 mb-5">
                Intended for financial professional and TPA use.
                <p className="fs-disclaimer">
                  If the client is working with a TPA, eligibility, automatic
                  enrollment, and vesting options may vary.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>{' '}
      <SectionSingleCol className="d-print-block d-none" copy={footNoteList} />
    </div>
  );
};

RetirementPlans.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RetirementPlans);
