import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '../Label';

export const TextQuestion = (props) => {
  const {
    uniqueId = '',
    name,
    label,
    labelClass = '',
    inputClass = '',
    placeholder = '',
    required,
    value,
    hasError,
    handleQuestionChange,
    infoLabel = '',
    disabled = false,
  } = props;

  return (
    <div
      className={classNames('mb-3 form-group', {
        'has-error': hasError && required,
      })}
    >
      <Label
        name={uniqueId || name}
        className={labelClass}
        text={label}
        required={required}
      />
      {infoLabel && (
        <div
          className="fs-p text-center"
          dangerouslySetInnerHTML={{ __html: infoLabel }}
        />
      )}
      {placeholder && (
        <span className="fs-form-label text-center d-block">{placeholder}</span>
      )}
      <input
        id={uniqueId || name}
        className={classNames(inputClass, {
          'is-invalid': hasError && required,
        })}
        required={required}
        name={uniqueId || name}
        value={value}
        onChange={(event) => {
          handleQuestionChange(name, event.target.value);
        }}
        disabled={disabled}
      />
      {props.children}
    </div>
  );
};

TextQuestion.propTypes = {
  children: PropTypes.any,
  uniqueId: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  infoLabel: PropTypes.any,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextQuestion;
