export const hasAutoEscalation = {
  id: 'q6ai3a',
  type: 'radio_condensed',
  name: 'hasAutoEscalation',
  paradigmAlias: 'hasAutoEscalation',
  user: {},
  label: 'Will you be using auto escalation?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      checked: false,
      icon: 'icon-check-o',
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      checked: false,
      icon: 'icon-close-o',
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Auto escalation',
  reviewScreenTitle: 'Auto escalation',
  optionDescription: '',
  description: '',
};
