import React, { useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import SimplyIconSprite from '../../../../common/SimplyIconSprite';

const startupCostsDisclaimer = `<p class="fs-startup-costs-disclaimer">Start-up tax credit modification: Small employers with 50 or fewer employees may apply 100% of qualified start-up costs towards the tax credit formula (up to $5,000 per year). </p><p>New tax credit for start-up plans offering employer contributions: A tax credit equal to the applicable percentage of employer contributions, capped at a maximum of $1,000 per employee.<ul><li>Applicable to small employers with 50 or fewer employees.</li><li>For employees with 51-100 employees: The credit is phased out by reducing the amount of credit each year 2% for each employee in excess of 50.</li></ul><b>Applicable Percentage:</b><p>1st and 2nd year = 100%, 3rd year = 75%, 4th year = 50%, 5th year = 25%, 6th year = 0% </p><p>No contributions may be counted for employees with wages in excess of $100,000 (inflation adjusted). If taking advantage of this tax credit, employer contributions may not also be counted towards “start-up costs” in the start-up tax credit calculation.</p></p>`;
const TPAhomefeeDisclaimer = `<p class="fs-tpa-home-disclaimer">Billed directly to the employer and collected by the TPA outside of plan assets.</p>`;

const QuestionSection = ({ putApplicationPanelInfo, applicationPanelInfo }) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'startupCostsDisclaimer',
      'TPAhomefeeDisclaimer',
      'TPAhomefeeDisclaimer-1',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'startupCostsDisclaimer':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: startupCostsDisclaimer,
        });
        break;
      case 'TPAhomefeeDisclaimer':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: TPAhomefeeDisclaimer,
        });
        break;
      case 'TPAhomefeeDisclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: TPAhomefeeDisclaimer,
        });
        break;
      default:
        break;
    }
  };
  return (
    <section className="section section_py-5 bg-concrete">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-5 mx-auto">
            <SimplyIconSprite
              name="icon-comment-question-o"
              className="d-block mx-auto"
              size="75"
              fill="#00ADCA"
              height="75%"
            />
            <p className="fs-h5 fw-bold text-center">
              Still have questions?
              <br /> We have answers.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-5">
            <p className="fs-lead fw-bold lh-125">
              How will my third party administrator fees be paid?
            </p>
            <p className="fs-sm lh-165">
              Select client and participant level fees can be included in the
              fee proposal.
              <li className="fs-p">
                One-time set up{' '}
                <span
                  className="text-underline p-0 no-border-button"
                  id="TPAhomefeeDisclaimer"
                >
                  fee*
                </span>
              </li>
              <li className="fs-p">
                {' '}
                Annual base{' '}
                <span
                  className="text-underline p-0 no-border-button"
                  id="TPAhomefeeDisclaimer-1"
                >
                  fee*
                </span>
              </li>
              <li className="fs-p"> Annual per head fee* </li>
              <li className="fs-p">
                {' '}
                Asset based fee (up to $5,000 per year or 250 bps), paid
                quarterly
              </li>
              <li className="fs-p"> Participant transaction fees</li>
            </p>

            {/* <a */}
            {/*  href="https://www.principal.com/landing-page/secureact-plansponsor" */}
            {/*  target="_blank" */}
            {/* > */}
            {/*  Additional fee payment details */}
            {/* </a> */}
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <p className="fs-lead fw-bold" style={{ lineHeight: '125%' }}>
              How can my clients benefit from SECURE 2.0 Act tax credits?
            </p>
            <p className="fs-sm text-left">
              The SECURE 2.0 Act offers a tax credit that can help offset up to
              100%{' '}
              <span
                id="startupCostsDisclaimer"
                className="text-underline p-0 no-border-button"
              >
                (up to $5,000 per tax year for the first three years for some
                employers){' '}
              </span>{' '}
              for a new 401(k) plan. There’s also a tax credit for automatic
              enrollment, which is a feature of the Simply Retirement by
              Principal
              <small>
                <sup>®</sup>
              </small>{' '}
              plan.
            </p>
            <Link to="/third-party-administrator/retirement-plans#secure-act">
              SECURE 2.0 Act tax credit specifics
            </Link>
          </div>
          <div className="col-md-3 mb-5">
            <p className="fs-lead fw-bold lh-125">
              What are my responsibilities as a TPA with this solution?
            </p>
            <p className="fs-sm lh-165">
              The TPA handles certain third-party plan administrator
              responsibilities, while Ubiquity provides the electronic portal as
              well as recordkeeping services.
            </p>
            {/* <Link to="third-party-administrator/learn-the-basics"> */}
            {/*  View TPA responsibilities */}
            {/* </Link> */}
          </div>
          <div className="col-md-3 mb-5">
            <p className="fs-lead fw-bold lh-125">
              How does this 401(k) plan compare to traditional plans?
            </p>
            <p className="fs-sm lh-165">
              Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              is 100% online, from plan design and purchase to onboarding and
              administration. It’s also designed specifically for businesses
              with fewer than 100 employees.
            </p>
            <Link to="third-party-administrator/retirement-plans#401k-table">
              401(k) plan comparison
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionSection;

QuestionSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  applicationPanelInfo: PropTypes.any,
};
