export const whoToShareWith = (
  userRole,
  hasFp,
  hasTpa,
  validFp,
  isValid,
  creator,
) => {
  const isCreator = normalizeCreator() === userRole;

  function upcase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function normalizeCreator() {
    switch (creator) {
      case 'psivet':
      case 'employer':
        return 'employer';
      case 'advisor':
        return 'advisor';
      case 'tpa':
        return 'tpa';
      default:
        return 'Anonymous';
    }
  }

  switch (userRole) {
    case 'psivet':
    case 'employer':
      if (hasFp && validFp && hasTpa && isValid) return 'employerToAnonymous';
      if (hasFp && hasTpa)
        return isCreator
          ? 'employerToFp'
          : `employerTo${upcase(normalizeCreator())}`;
      if (!hasFp && hasTpa && isValid) return 'employerToAnonymous';
      if (!hasFp && hasTpa) return 'employerToTpa';
      if (hasFp && validFp && !hasTpa) return 'employerToAnonymous';
      if (hasFp && !hasTpa) return 'employerToAdvisor';
      return `employerToAnonymous`;
    case 'advisor':
      if (!isCreator && hasTpa && isValid) return 'advisorToEitherParty';
      if (hasTpa && isValid) return 'advisorToEmployer';
      if (hasTpa && !isValid) return 'advisorToTpa';
      return 'advisorToEmployer';
    case 'tpa':
      if (hasFp) {
        if (!isCreator) return 'tpaToEitherParty';
        if (isValid && validFp) return 'tpaToEitherParty';
        if (isCreator) return 'tpaToAdvisor';
      }
      return 'tpaToEmployer';
    default:
      return `employerToAnonymous`;
  }
};
