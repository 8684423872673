import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { stepTrackerObj, isActive, saveData, uuidContext } from './Utilities';
import StepTracker from './StepTracker';
import CloseForm from './CloseForm';
import PlanOutLine from './PlanOutLine';
import Controls from './Controls';
export { default as NextStepBtn } from './NextStepBtn';
export { default as PrevStepBtn } from './PrevStepBtn';
export { default as ReviewPlanBtn } from './ReviewPlanBtn';
export { default as StepControls } from './StepControls';
export { default as Step } from './Step';
export { default as Question } from './Question';
export { default as WithInfoPanel } from './WithInfoPanel';
export { default as CloseForm } from './CloseForm';
export {
  uuidContext,
  saveData,
  checkValidityOnQuestionArray,
  isEligible,
  restrictedState,
} from './Utilities';

export const FormWithSteps = ({
  children,
  questions,
  formInReview = false,
  startAtStep,
  activeStep,
  putActiveStep,
  putQuestionById,
  handleClose,
  formId,
  formData,
  notificationIndicator,
  setNotificationIndicator,
  resetData,
  hashId,
  costEstimator,
}) => {
  const uuid = useContext(uuidContext);
  const [stepAnimDirection, setDirection] = useState('forward');
  const [showPlanOutLine, togglePlanOutLine] = useState(false);
  const [defaultTab, setDefaultTab] = useState('reviewSelections');
  const formEl = useRef(null);
  const stepNames = children.map((child) => child.props.name);
  const currentStep = stepNames.includes(activeStep) ? activeStep : startAtStep;
  const { totalSteps, activeStepIndex } = stepTrackerObj(children, currentStep);

  const updateActiveStep = (updateStep, direction) => {
    setDirection(direction);
    putActiveStep(formId, updateStep);
    togglePlanOutLine(false);
    formEl.current.scrollTo(0, 0);
  };
  const updateQuestionById = (
    questionId,
    value,
    hasError,
    dirty,
    valueFormatted,
    disabled,
  ) => {
    putQuestionById(
      formId,
      questionId,
      value,
      hasError,
      dirty,
      valueFormatted,
      disabled,
    );
  };

  const updateChildrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      stepAnimDirection,
      updateActiveStep,
      updateQuestionById,
      questions,
      formInReview,
      active: isActive(startAtStep, child.props.name, currentStep),
      notificationIndicator,
      setNotificationIndicator,
    }),
  );

  const activeChild = updateChildrenWithProps.find(
    (child) => child.props.name === currentStep,
  );

  useEffect(() => {
    if (formData.planDesign.inProgress) {
      saveData(uuid, formData);
    }
  }, [formData]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.history.replaceState(undefined, undefined, `#${activeStep}`);
    }
  }, [activeStep]);

  return (
    <form
      ref={formEl}
      className={classNames('fws', {
        'fws__with-sidebar': activeChild.props.hasSidebar,
        fws_dark: activeChild.props.dark,
      })}
    >
      <div className="fws__wrapper h-md-100">
        <div
          className="fws__header fadeInDown"
          style={{
            animationDuration: '.2s',
            animationDelay: '0',
          }}
        >
          <div
            className="back-btn-spacer"
            style={{ width: '40px', height: '65px', marginRight: 'auto' }}
          ></div>
          {activeChild.props.showTracker && (
            <StepTracker
              activeStepIndex={
                activeChild.props.activeStepIndex || activeStepIndex
              }
              totalSteps={activeChild.props.totalSteps || totalSteps}
            />
          )}
          <CloseForm handleClose={handleClose} light={activeChild.props.dark} />
        </div>
        {activeChild}
        {activeChild.props.hasSidebar && (
          <Controls
            formData={formData}
            setDefaultTab={setDefaultTab}
            togglePlanOutLine={togglePlanOutLine}
            notificationIndicator={notificationIndicator}
            setNotificationIndicator={setNotificationIndicator}
          />
        )}
      </div>
      {activeChild.props.hasSidebar && (
        <PlanOutLine
          selections={updateChildrenWithProps}
          questions={questions}
          showPlanOutLine={showPlanOutLine}
          togglePlanOutLine={togglePlanOutLine}
          defaultTab={defaultTab}
          notificationIndicator={notificationIndicator}
          setNotificationIndicator={setNotificationIndicator}
          resetData={resetData}
          hashId={hashId}
          role={formData.role}
          costEstimator={costEstimator}
        />
      )}
    </form>
  );
};

FormWithSteps.propTypes = {
  children: PropTypes.any.isRequired,
  questions: PropTypes.object.isRequired,
  formInReview: PropTypes.bool,
  startAtStep: PropTypes.string.isRequired,
  activeStep: PropTypes.string.isRequired,
  putActiveStep: PropTypes.func.isRequired,
  putQuestionById: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  notificationIndicator: PropTypes.bool.isRequired,
  setNotificationIndicator: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
  hashId: PropTypes.string,
  costEstimator: PropTypes.any.isRequired,
};

export default FormWithSteps;
