import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Question from './Question';
import {
  IconMoneyHandO,
  IconCommentQuestionO,
  IconStarCupO,
  WomanInConversation,
  IconUserGraphO,
  WomanWorkingInOffice,
  IconRisingGraphO,
  IconLockSecureO,
  IconMoneySyncO,
  IconSearchMagnifyO,
  CheckCircle,
} from '../../../../ImagePaths';
import {
  QuestionWrapperStyles,
  SectionFullStyles,
  FullImgStyles,
} from '../index.styles';

import { PrincipalTabs, Item } from '../../../../common/PrincipalTabs';
import AdvisorFee from './AdvisorFee';
import Pricing from './Pricing';
import PurpleSection from '../PurpleSection';

const QuestionsSet1 = [
  {
    id: 1,
    src: IconMoneyHandO,
    anchor: 'who-offer',
    title: `Who can offer a retirement plan?`,
    desc: `<p class="fs-p mb-5">
      Businesses of any size can offer a retirement plan, whether it’s a
      solo 401(k) for an owner-only operation or a group plan for dozens
      of employees. No business is too small to qualify. In fact, <strong>Simply
      Retirement by Principal<small><sup>®</sup></small> is designed for businesses with fewer
      than 100 employees.</strong>
    </p>`,
  },
  {
    id: 2,
    src: IconCommentQuestionO,
    anchor: 'why-offer',
    title: `Why offer a retirement plan?`,
    desc: `<p class="fs-h2">For business owners, potential benefits include:</p>
    <p class="fs-p"><strong>Recruitment and retention.</strong> A retirement plan is a valuable benefit that can help you compete for top talent.</p>
    <p class="fs-p"><strong>Tax advantages.</strong> SECURE 2.0 Act legislation allows small businesses with up to 50 employees a tax credit of 100% and those with 51-100 a tax credit of 50% of the qualifying <span id="ltb-startup-costs-disclaimer" class="text-underline p-0 no-border-button">start-up costs&nbsp;</span> for a new employee retirement plan for the first three years of the plan as follows but limited to the greater of (1) $500 or (2) the lesser of (a) $250 for each non-highly compensated employee who is eligible to participate in the plan or (b) $5,000.</p>
    <p class="fs-p">There’s also a tax credit for 5 years of up to&nbsp;<span id='ltb-tax-credit-disclaimer' class="text-underline p-0 no-border-button">$1,000</span>&nbsp;per employee a year for employer contributions made if employer has no more than 50 employees. Employers with 51-100 employees, the credit is reduced by 2% for each employee in excess of 50. A small employer may also be eligible to claim a tax credit for including an&nbsp;<span id="ltb-disclaimer-2" class="text-underline p-0 no-border-button">eligible automatic contribution arrangement with their newplan under SECURE Act of 2019.</span>&nbsp;Plus, any matching contributions you make to employeeretirement accounts are tax-deductible.</p>
    <p class="fs-p"><strong>Helping employees prepare for the future.</strong> Research shows that overall, <span id="ltb-disclaimer-3" class="text-underline p-0 no-border-button">96% of workers use an employer-sponsored retirement plan to save for retirement.</span> It can fill a critical need at a time when the vast majority of Americans haven’t saved even a fraction of what they would need to retire comfortably.</p>`,
  },
];

const QuestionsSet2 = [
  {
    id: 1,
    src: IconStarCupO,
    anchor: 'benefits',
    title: `What are some of the potential benefits to your employees?`,
    desc: `
    <div class="row">
      <div class="col-md-6 mb-4 order-md-1 order-2"><p class="fs-h2 fw-bold mb-0">Convenience</p><p class="fs-p">Contributions are automatically deducted from participating employees' paychecks each pay period, so they don't have to budget separately for setting money aside.</p></div>
      <div class="col-md-6 mb-4 order-md-2 order-1"><img src=${WomanInConversation} alt="" class="img-fluid" /></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p class="fs-h2 fw-bold mb-0">Savings</p><p class="fs-p">The earlier employees start saving, the more time they’ll have for their retirement accounts to grow. And any matching contributions you offer give them an opportunity to take advantage of “free money”—providing even more incentive to maximize their contributions.</p></div>
      <div class="col-md-6 mb-4"><p class="fs-h2 fw-bold mb-0">Tax advantages</p><p class="fs-p">Employee pre-tax contributions are deducted from paychecks before income taxes, reducing taxable income. Taxes are also deferred on any investment earnings until the money is withdrawn in retirement.</p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p class="fs-h2 fw-bold mb-0">Flexibility</p><p class="fs-p">Employees can request to take a loan from their 401(k) plan balance and select a loan repayment schedule that suits them within plan terms. You can also choose to have the plan allow for hardship withdrawals.</p></div>
      <div class="col-md-6 mb-4"><p class="fs-h2 fw-bold mb-0">Financial wellness</p><p class="fs-p">Simply Retirement by Principal<small><sup>®</sup></small> 401(k) plan participants will have access to a comprehensive financial wellness platform that provides tools and resources to employees to better manage their current and future financial well-being.</p></div>
    </div>
    `,
  },
  {
    id: 2,
    src: IconUserGraphO,
    anchor: 'how-much',
    title: `How much could your employees save for retirement?`,
  },
];

const QuestionsSet3 = [
  {
    id: 1,
    src: IconRisingGraphO,
    anchor: 'whats-involved',
    title: `What’s involved in managing a 401(k) plan?`,
    desc: `<p class="fs-h2">As the plan administrator, you and/or a designated employee (such as a human resources manager) will have some tasks, including:</p>`,
  },
  {
    id: 2,
    src: IconLockSecureO,
    anchor: 'how-protect',
    title: `How can you protect yourself and limit your liability?`,
    desc: `<p class="fs-h2">Choosing investments for a retirement plan comes with a lot of responsibility. It’s called being a "fiduciary" and fiduciaries are personally liable for those choices.</p>
           <p class="fs-p">Simply Retirement by Principal<sup><small>®</small></sup> makes it easier for you with OneDigital Investment Advisors LLC (“OneDigital”) as this will be the plan’s 3(38) investment fiduciary. That means OneDigital will provide objective, independent third-party oversight for the screening, selection, and monitoring of the plan’s investment options. They’ll also make changes to the investment lineup as appropriate. This service will help manage your related fiduciary liability.<sup><small>*</small></sup></p>
           <p class="fs-disclaimer mb-5 pb-5"><sup><small>*</small></sup>You, as plan fiduciary, are ultimately responsible for the selection and monitoring of their delegated responsibilities, not by any member of Principal<sup><small>®</small></sup> and Ubiquity Retirement + Savings<sup><small>®</small></sup></p>
           `,
  },
  {
    id: 3,
    src: IconMoneySyncO,
    anchor: 'automatic-enrollments',
    title: `How do automatic enrollment and contributions work?`,
    desc: `<p class="fs-p">If you choose a 401(k) plan with Simply Retirement by Principal<sup><small>®</small></sup>, you’ll start by answering a few questions to create a plan proposal with cost estimates. Once you complete your plan purchase, you'll be prompted to create a login and password to access the Ubiquity recordkeeping platform. Log in, provide any necessary additional information, sign required documents, and pay the one-time start-up fee of $500. Then you'll be able to start onboarding participants. Participants will receive an email to create their own login and set up their account.</p>
           <p class="fs-h2 fw-bold mb-0">Automatic enrollment</p>
           <p class="fs-h2">The Simply Retirement by Principal<sup><small>®</small></sup> 401(k) plan offers the option for employees to be automatically enrolled at a default pre-tax contribution percentage set by you. Employees can change this amount or opt out at any time.</p>
           <p class="fs-p">To be eligible, employees must be age 21 or older and meet the employment requirement you set, whether it's their first day of work,  after three months of work, or on their first employment anniversary. If you choose the automatic enrollment option, once eligible, employees will be automatically enrolled at the default pre-tax contribution percentage you set. They’ll also have contributions directed to the plan’s qualified default investment alternative (QDIA) unless they elect otherwise. A QDIA is a default investment used when money is contributed to an employee’s 401(k) account, but the employee hasn’t made their investment election.</p>
           <p class="fs-p">Don't worry—employees can change their contributions and investment election or opt out of contributing to the plan at any time. <strong>Automatic enrollment can help increase participation, simplify administration, reduce follow-up, and help your employees save for retirement.</strong></p>
           <p class="fs-h2 fw-bold">Employee contributions</p>
           <p class="fs-p">Employee contributions will be deducted from payroll each pay period. Simply Retirement by Principal<sup><small>®</small></sup> can integrate with select payroll providers to make this process easier for you.</p>`,
  },
  {
    id: 4,
    src: IconSearchMagnifyO,
    anchor: 'costs-involved',
    title: `What are the costs involved in a retirement plan?`,
    desc: `<p class="fs-p mb-5 pb-5">As you research your options and compare pricing, there are several components to keep in mind. Plan expenses are the fees you and/or your participants pay for things like initial plan setup, recordkeeping, and investment management. These are set by the organizations involved with providing services for a plan. Plan costs are any matching contributions you decide to make, which you’ll set in your plan design. In addition, OneDigital has a fee for their advisory services.</p>`,
  },
];

const QuestionSection = ({ putApplicationPanelInfo }) => (
  <>
    <QuestionWrapperStyles>
      <section className="section section_py-5">
        <div className="container">
          <p className="fs-h6 text-md-left text-center px-md-0 px-5 pb-5 d-md-none">
            <strong>
              Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>
              is designed to make it easy to set up a plan yourself, step by
              step.
            </strong>
            Once you understand the basics, it’s really just a matter of making
            choices that fit your situation. Here are a few things to consider
            as you look at your retirement plan options.
          </p>
          <div className="row my-md-5 pb-5">
            <div className="col-md-7 mx-auto">
              <h2 className="fs-h2 text-center text-center mb-4">
                When you’re researching a retirement plan that works for your
                business, questions may come up. We can help.
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="row">
                <Question questions={QuestionsSet1} width={40} height={60} />
                <Question questions={QuestionsSet2} width={40} height={60} />
                <Question questions={QuestionsSet3} width={40} height={60} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section section_py-5">
        <div className="container">
          <div className="row">
            <Question questions={QuestionsSet1} width={60} height={80} expand />
          </div>
        </div>
      </section>
    </QuestionWrapperStyles>

    <PurpleSection />

    <section className="section section_py-5">
      <div className="container">
        <div className="row">
          <Question
            questions={[QuestionsSet2[0]]}
            width={60}
            height={80}
            expand
          />
        </div>
        <div className="row">
          <Question
            questions={[QuestionsSet2[1]]}
            width={60}
            height={80}
            expand
          />
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <p className="fs-h2 mb-5">
              The earlier your employees start contributing to a 401(k) plan,
              the more their retirement savings could potentially add up.
            </p>
            <div className="text-center">
              <Link
                to="/roi-calculator"
                className="srp-button srp-button__primary"
              >
                Calculate how much your employees could save
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section section_py-5 pb-0">
      <div className="container">
        <div className="row">
          <Question
            questions={[QuestionsSet3[0]]}
            width={60}
            height={80}
            expand
          />
        </div>
      </div>
      <SectionFullStyles className="full-section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-4 col-md-6">
              <p className="fs-h2 fw-bold mb-0">
                Keep employee information up to date
              </p>
              <p className="fs-p">
                As employees are hired or when they leave, just provide the
                latest information. If you use QuickBooks
                <sup>
                  <small>®</small>
                </sup>{' '}
                Online, this information can automatically sync with your
                retirement plan.
              </p>
              <p className="fs-h2 fw-bold mb-0">
                Submit contributions each payroll
              </p>
              <p className="fs-p">
                Each payroll, you’ll need to deduct employee contributions from
                their pay. Then, you can easily submit those contributions and
                any matching contributions to us via EFT from your bank account.
              </p>
              <p className="fs-h2 fw-bold mb-0">
                Approve employee withdrawals/loans
              </p>
              <p className="fs-p">
                If an employee requests money out of the plan, you’ll need to
                approve the request.
              </p>
              <p className="fs-h2 fw-bold mb-0">
                Complete annual plan compliance activities
              </p>
              <p className="fs-p">
                Plan compliance basically means that you’re following the rules.
                The Ubiquity Retirement + Savings
                <small>
                  <sup>&reg;</sup>
                </small>{' '}
                compliance system is fully automated—you just need to answer
                some questions each year. We’ll then crunch the numbers and
                create the necessary forms for you to review and submit to the
                IRS.
              </p>
            </div>
          </div>
        </div>
        <FullImgStyles
          className="img-spread"
          style={{ backgroundImage: `url(${WomanWorkingInOffice})` }}
        />
      </SectionFullStyles>
    </section>
    <section className="section section_py-5 pt-0">
      <div className="container">
        <div className="row">
          <Question
            questions={[
              ...[QuestionsSet3[1]],
              ...[QuestionsSet3[2]],
              ...[QuestionsSet3[3]],
            ]}
            width={60}
            height={80}
            expand
          />
        </div>
      </div>
    </section>

    <section className="section section_py-5">
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <PrincipalTabs panelStyle="shadowed">
              <Item
                title="Pricing for our solution"
                component={
                  <Pricing putApplicationPanelInfo={putApplicationPanelInfo} />
                }
              />
              <Item title="OneDigital fees" component={<AdvisorFee />} />
            </PrincipalTabs>
          </div>
        </div>
      </div>
    </section>

    <div
      className="bg-pattern mb-5 pb-5"
      style={{
        background:
          'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
      }}
    >
      <section className="section pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <img
                src={CheckCircle}
                alt="Checkmark In Circle Icon"
                className="d-block mx-auto mb-3"
              />
              <h3 className="fs-h3 text-center mb-4">Ready to get started?</h3>
              <p className="text-center mb-5 pb-5">
                <Link
                  to="/onedigital/plans"
                  className="srp-button srp-button__primary"
                >
                  Try our 401(k) planner
                </Link>
              </p>
              <p className="fs-disclaimer mb-5 text-center">
                Intended for financial professional and plan sponsor use.
              </p>
              <span className="d-none">1178600-052020</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
);

QuestionSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

export default QuestionSection;
