export const vestingScheduleQaca = {
  id: 'q6ai3a1c',
  type: 'radio_condensed',
  name: 'vestingScheduleQaca',
  stepName: 'vestingScheduleQaca',
  user: {},
  sideCardTitle: 'Vesting schedule QACA',
  label: 'What is the vesting schedule for the QACA Safe Harbor contributions?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: 'Immediate',
      value: 'immediate',
      valueFormatted: 'Immediate',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: '2-year cliff = 0%, 100%',
      value: '2YearCliff',
      valueFormatted: '2-year cliff = 0%, 100%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  reviewScreenTitle: 'Vesting schedule QACA',
  optionDescription: '',
  description:
    'If a specific vesting schedule was selected for this contribution type, it will be shown here.',
};
