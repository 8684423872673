import { checkAdvisorCredentialVerification } from './checkAdvisorCredentialVerification';
import { checkValidityOnQuestionArray } from './checkValidityOnQuestionArray';
import { whoToShareWith } from './whoToShareWith';

export const advisorNextAction = (userRole, advisorInfo, formData, isValid) => {
  const isFpValid =
    checkValidityOnQuestionArray(advisorInfo) &&
    checkAdvisorCredentialVerification(
      formData.planDesign.advisors[0].advisorCredentialVerification,
    );
  const shareWith = whoToShareWith(
    userRole,
    formData.planDesign.questions.hasFp.value,
    formData.planDesign.questions.hasTpa.value,
    isFpValid,
    isValid,
    formData.planOriginator.role,
  );
  if (
    !checkValidityOnQuestionArray(advisorInfo) ||
    !checkAdvisorCredentialVerification(
      formData.planDesign.advisors[0].advisorCredentialVerification,
    )
  )
    return 'advisor-error';

  if (shareWith === 'advisorToEitherParty') return 'advisor-to-either';
  if (shareWith === 'advisorToTpa') {
    if (formData.planOriginator.role === 'tpa') {
      return 'advisor-save-only';
    }
  }
  if (shareWith === 'advisorToEmployer') {
    if (formData.planOriginator.role === 'employer') {
      return 'advisor-save-only';
    }
  }

  return 'advisor';
};
