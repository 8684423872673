import React from 'react';

const SafeHarborMatching = () => (
  <div className="py-4 px-5">
    <h5 className="fs-h5 my-2 pt-2">Safe harbor matching</h5>

    <p className="fs-p">
      <strong>
        Safe harbor is a popular solution for business owners who want to
        maximize their contributions.
      </strong>{' '}
      If you choose this option, you can have a simpler plan design that allows
      you to bypass some of the compliance testing required by the IRS. To avoid
      compliance testing, business owners must make a minimum safe harbor
      contribution to the plan. “Compliance testing” essentially refers to two
      government-mandated nondiscrimination tests that prove that the plan is
      not providing a more significant benefit to highly compensated employees
      (those who earn at least $150,000 in 2024 tax year or who own more than 5%
      of the company). A safe harbor plan is deemed to pass these tests.
    </p>
  </div>
);

export default SafeHarborMatching;
