import React from 'react';
import PropTypes from 'prop-types';

const IconCoinsO = ({ fill }) => (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.719 1.27709C39.9209 1.47787 40.0224 1.75846 39.9956 2.04193C39.3264 9.12764 36.3342 16.0404 31.0292 21.4728C27.9822 24.5931 24.4566 26.9343 20.694 28.4934C20.3343 28.6425 19.9201 28.5575 19.648 28.279L13.3134 21.7921C13.0511 21.5235 12.9711 21.1259 13.109 20.7768C14.6287 16.931 16.9056 13.3233 19.953 10.2026C25.2592 4.76205 32.018 1.69838 38.9526 1.00473C39.236 0.976395 39.5171 1.07631 39.719 1.27709ZM37.9648 3.04238C31.8792 3.90574 26.0002 6.73036 21.3166 11.5326L21.3161 11.533C18.6021 14.3123 16.5377 17.5025 15.112 20.9077L20.555 26.4814C23.8609 25.0241 26.9616 22.9119 29.6665 20.142C34.3585 15.3372 37.129 9.29829 37.9648 3.04238Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.2742 26.562C28.5759 26.6942 28.7879 26.9728 28.8348 27.2988C29.3413 30.8236 29.3669 34.0646 27.7909 36.3354C26.1527 38.6956 23.0592 39.6683 18.2677 39.3625C17.8785 39.3376 17.5437 39.0779 17.4229 38.7071C17.302 38.3363 17.4195 37.9292 17.7194 37.6798C20.0644 35.7293 20.8248 33.9868 21.0484 32.8196C21.162 32.2269 21.1426 31.7555 21.1005 31.4456C21.0794 31.2903 21.0526 31.1754 21.0337 31.1063C21.0243 31.0718 21.017 31.0488 21.0134 31.0381C21.0126 31.0357 21.012 31.0339 21.0116 31.0328C21.0116 31.0328 21.0116 31.0328 21.0116 31.0328C20.8289 30.5526 21.0604 30.0128 21.536 29.815C23.5861 28.9624 25.529 27.903 27.3526 26.6495C27.624 26.4629 27.9726 26.4298 28.2742 26.562ZM22.9971 31.2602C23.0561 31.7437 23.0686 32.3983 22.9192 33.1781C22.6822 34.4146 22.0497 35.9082 20.6454 37.4994C23.7336 37.3805 25.364 36.4913 26.2261 35.2493C27.1752 33.8817 27.4001 31.8296 27.1321 29.0695C25.8077 29.8935 24.428 30.6251 22.9971 31.2602Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7712 13.2984C14.6347 12.9987 14.3529 12.7908 14.0263 12.7487C10.4945 12.2936 7.25345 12.3153 5.00593 13.9243C2.6698 15.5967 1.74241 18.7041 2.11809 23.4906C2.14861 23.8794 2.41313 24.2104 2.7857 24.3258C3.15826 24.4412 3.56355 24.3178 3.80856 24.0143C5.72469 21.6411 7.45591 20.8554 8.61968 20.6147C9.21063 20.4925 9.68234 20.505 9.99278 20.5426C10.1484 20.5615 10.2636 20.5866 10.333 20.6044C10.3677 20.6133 10.3907 20.6204 10.4015 20.6238C10.4039 20.6246 10.4057 20.6252 10.4069 20.6255C10.4069 20.6255 10.4069 20.6255 10.4069 20.6255C10.8897 20.8012 11.426 20.5619 11.6168 20.0833C12.4395 18.0211 13.4704 16.0629 14.6972 14.2212C14.8798 13.9471 14.9078 13.5981 14.7712 13.2984ZM10.1505 18.6435C9.66623 18.5916 9.01144 18.5886 8.23395 18.7494C7.00106 19.0044 5.51684 19.6587 3.9463 21.086C4.02007 17.9964 4.88536 16.3532 6.1147 15.4731C7.46828 14.5041 9.51693 14.2493 12.2806 14.477C11.476 15.8133 10.7646 17.2035 10.1505 18.6435Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5087 11.4077C26.2367 11.4077 25.1671 12.4701 25.1671 13.8285C25.1671 15.1868 26.2367 16.2492 27.5087 16.2492C28.7808 16.2492 29.8503 15.1868 29.8503 13.8285C29.8503 12.4701 28.7808 11.4077 27.5087 11.4077ZM23.2623 13.8285C23.2623 11.461 25.1423 9.50293 27.5087 9.50293C29.8751 9.50293 31.7551 11.461 31.7551 13.8285C31.7551 16.1959 29.8751 18.154 27.5087 18.154C25.1423 18.154 23.2623 16.1959 23.2623 13.8285Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9544 26.4829C12.3307 26.8504 12.3378 27.4534 11.9703 27.8297L1.63352 38.4148C1.26603 38.7912 0.663058 38.7983 0.286742 38.4308C-0.0895735 38.0633 -0.0967306 37.4604 0.270757 37.0841L10.6076 26.4989C10.9751 26.1226 11.578 26.1154 11.9544 26.4829Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6138 28.2217C17.9902 28.5892 17.9973 29.1921 17.6298 29.5685L9.42404 37.9714C9.05656 38.3477 8.45359 38.3549 8.07727 37.9874C7.70095 37.6199 7.6938 37.0169 8.06128 36.6406L16.2671 28.2377C16.6346 27.8614 17.2375 27.8542 17.6138 28.2217Z"
      fill={fill}
      className="fill"
    />
  </g>
);

IconCoinsO.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconCoinsO;
