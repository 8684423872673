import React, { useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dataSet } from '../../../../common/QuickQuote/dataSet';
import FormDataRedux, {
  selectApplicationPanelInfo,
  selectRole,
} from '../../../../Redux/FormDataRedux';
import RowPlanSponsor from '../../../../common/QuickQuote/RowPlanSponsor';
import Controls from '../../../../common/QuickQuote/Controls';
import RowTpaDetails from './RowTpaDetails';
import RowFinancialPro from './RowFinancialPro';
import QuickQuoteCalculator from './QuickQuoteCalculator';
import { checkForErrors } from '../../../../common/FormWithSteps/Utilities';

const hasErrorsIsRequired = (array) => {
  const error = array
    .filter((item) => item.required)
    .find((requiredItem) => {
      if (requiredItem.value === '' || requiredItem.hasError) {
        return requiredItem;
      }
      return false;
    });
  return error?.name;
};

const QuickQuote = ({
  applicationPanelInfo,
  putApplicationPanelInfo,
  sectionLabel,
}) => {
  const [data, setData] = useState(dataSet);
  const [isFormInValid, setIsFormInValid] = useState(true);
  const fpRequiredQuestions = [
    'advisorFirstName',
    'advisorLastName',
    'advisorEmail',
    'advisorPhone',
    'advisorStateCode',
    'firmName',
  ];

  const handleQuestionChange = (name, updateValue, valueFormatted) => {
    setData((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        value: updateValue,
        valueFormatted,
        hasError: data[name].required
          ? checkForErrors(name, updateValue, data)
          : false,
      },
    }));
  };

  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });

  useEffect(() => {
    fpRequiredQuestions.forEach((question) => {
      setData((prevData) => ({
        ...prevData,
        [question]: {
          ...prevData[question],
          required: data.hasFp.value,
        },
      }));
    });
  }, [data.hasFp.value]);

  useEffect(() => {
    const error = hasErrorsIsRequired(Object.values(data));
    setIsFormInValid(error);
  }, [data]);

  return (
    <div className="plan-proposal-section position-relative plan-proposal index-1">
      <div className="container-lg bg-white">
        <div className="row">
          <div className="col-lg-10 mx-auto px-md-3">
            <h3 className="fs-h1 text-center fw-bold mb-6">
              401(k) Plan Proposal
            </h3>
            <RowPlanSponsor
              data={data}
              handleQuestionChange={handleQuestionChange}
            />
          </div>
        </div>
        <QuickQuoteCalculator
          numberOfEmployees={parseInt(data.numberOfEmployees.value, 10)}
          putApplicationPanelInfo={putApplicationPanelInfo}
          sectionLabel={sectionLabel}
        />
        <div className="row justify-content-center">
          <div className="col-lg-10 mx-auto px-md-3">
            <RowTpaDetails
              data={data}
              handleQuestionChange={handleQuestionChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 mx-auto px-md-3">
            <RowFinancialPro
              data={data}
              handleQuestionChange={handleQuestionChange}
              putApplicationPanelInfo={putApplicationPanelInfo}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 mx-auto px-md-3">
            <Suspense fallback={<div>Loading... </div>}>
              <Controls data={data} isFormInValid={!!isFormInValid} />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

QuickQuote.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  sectionLabel: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  questions: store.formData.planDesign.questions,
  applicationPanelInfo: selectApplicationPanelInfo(store),
  role: selectRole(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickQuote);
