import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux from '../../../Redux/FormDataRedux';
import {
  Question,
  PrevStepBtn,
  StepControls,
  checkValidityOnQuestionArray,
} from '../../../common/FormWithSteps';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../common/FormWithSteps/Utilities';
import CheckBox from '../../../common/FormWithSteps/Question/CheckBox';

export const AllowLoans = ({
  questionId,
  questions,
  role,
  active,
  previousStep,
  updateActiveStep,
  goToReviewPlan,
  googleData,
  updateQuestion,
  formInReview,
  destination,
  isValid,
}) => {
  const [validateQuestions, setValidateQuestions] = useState([]);
  const [confirmAllowLoans, setConfirmAllowLoans] = useState({
    type: 'checkbox',
    user: {},
    label: '',
    name: 'confirmAllowLoans',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: false,
    hasError: false,
    options: [
      {
        id: 1,
        value: false,
        label: 'Only one outstanding loan allowed. No refinance available.',
        checked: false,
        icon: '',
        hasInfo: false,
        customStyle: '',
      },
    ],
    description: '',
  });

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
    if (updateValue === 'no') {
      setConfirmAllowLoans({
        ...confirmAllowLoans,
        value: '',
        dirty: false,
      });
    }
  };

  const handleQuestionChangeConfirm = (_id, updateValue) => {
    setConfirmAllowLoans({
      ...confirmAllowLoans,
      value: updateValue,
      dirty: true,
    });
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  useEffect(() => {
    if (questions[questionId].value === 'yes') {
      setValidateQuestions([questions[questionId], confirmAllowLoans]);
    } else {
      setValidateQuestions([questions[questionId]]);
    }
  }, [questions[questionId], confirmAllowLoans]);

  return (
    <div className="container-fluid h-100">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Question
            role={role}
            labelClass="fs-form-q fw-bold text-center mb-4"
            inputClass="srp-form-input form-control"
            radioWrapper="srp-form-toggle"
            type={questions[questionId].type}
            question={questions[questionId]}
            handleQuestionChange={handleQuestionChange}
          />
          {questions[questionId].value === 'yes' && (
            <CheckBox
              labelClass="fs-form-q fw-bold text-center"
              handleQuestionChange={handleQuestionChangeConfirm}
              inputClass="ml-5"
              {...confirmAllowLoans}
            />
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mx-auto">
          <div className="form-group fws__control">
            <StepControls
              formInReview={formInReview}
              disableReviewBtn={!isValid}
              disabledNextBtn={!checkValidityOnQuestionArray(validateQuestions)}
              goToReviewPlan={goToReviewPlan}
              clickNextBtn={() => {
                updateActiveStep(destination(), 'forward');
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

AllowLoans.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  goToReviewPlan: PropTypes.func,
  googleData: PropTypes.object,
  updateQuestion: PropTypes.func.isRequired,
  formInReview: PropTypes.bool,
  destination: PropTypes.func,
  questionId: PropTypes.string,
  isValid: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
});

export default connect(null, mapDispatchToProps)(AllowLoans);
