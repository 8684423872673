import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import InfoPanel from './InfoPanel';

const WithInfoPanelMobile = ({
  visibleInfoPanel,
  toggleInfoPanel,
  setVisibility,
  heading,
  content,
}) => {
  let modalRef;

  const customStyles = {
    overlay: {
      background: 'rgba(5, 5, 5, 0.5)',
      transition: 'background-color 0.5s',
    },
    content: {
      background: '#EEEEEE',
      position: 'relative',
      overflowY: 'auto',
      padding: '24px 32px',
      flex: '1 1 auto',
    },
  };

  useEffect(() => {
    Modal.setAppElement(modalRef);
  }, []);
  return (
    <Modal
      className="quick-quote__slide-from-right quick-quote__slide"
      isOpen={visibleInfoPanel}
      onRequestClose={toggleInfoPanel}
      closeTimeoutMS={500}
      ariaHideApp={false}
      style={customStyles}
      overlayClassName="d-xl-none quick-quote__slide-overlay"
      shouldCloseOnEsc
    >
      <div
        ref={(arg) => {
          modalRef = arg;
        }}
      >
        <InfoPanel
          heading={heading}
          content={content}
          toggleInfoPanel={setVisibility}
        />
      </div>
    </Modal>
  );
};

WithInfoPanelMobile.propTypes = {
  visibleInfoPanel: PropTypes.bool,
  toggleInfoPanel: PropTypes.func.isRequired,
  setVisibility: PropTypes.func.isRequired,
  heading: PropTypes.string,
  content: PropTypes.string,
};

export default WithInfoPanelMobile;
