import React from 'react';
import PropTypes from 'prop-types';

const SwitchAdvisor = ({ current, setCurrent, total }) => {
  const previous = () => {
    const prev = current - 1 < 0 ? total - 1 : current - 1;
    setCurrent(prev);
  };
  const next = () => {
    const n = current + 1 > total - 1 ? 0 : current + 1;
    setCurrent(n);
  };

  return (
    <div>
      <button
        aria-label="Previous"
        type="button"
        className="btn"
        onClick={() => previous()}
      >
        <i className="fas fa-chevron-left"></i>
      </button>
      {current + 1} of {total}
      <button
        aria-label="Next"
        type="button"
        className="btn"
        onClick={() => next()}
      >
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
};

SwitchAdvisor.propTypes = {
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default SwitchAdvisor;
