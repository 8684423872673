export const employerPaidParticipantFees = {
  id: 10,
  stepName: 'step_9',
  type: 'radio_condensed',
  name: 'employerPaidParticipantFees',
  user: {
    employer: {
      label: 'Who will pay the monthly $6 per  participant fee?',
      infoLabel:
        'There is a flat monthly $6 recordkeeping fee per participating employee for the Simply Retirement by Principal<small><sup>®</sup></small> 401(k) plan. You, as the business owner, can pay this fee quarterly—or it can be deducted from each participating employee’s plan assets monthly.',
    },
    advisor: {
      label: 'Who will pay the monthly $6 per  participant fee?',
      infoLabel:
        'There is a flat monthly $6 recordkeeping fee per participating employee with over $100 in the plan. The business owner can pay this fee quarterly—or it can be deducted from each participant’s plan assets monthly.',
    },
    tpa: {
      label: 'Who will pay the monthly $6 per participant fee?',
      infoLabel:
        'There is a flat monthly $6 recordkeeping fee per participating employee with over $100 in the plan. The business owner can pay this fee quarterly—or it can be deducted from each participant’s plan assets monthly.',
    },
  },
  heading: 'How could the SECURE 2.0 Act help with this fee?',
  content:
    'The SECURE 2.0 Act provides a tax credit for up to 50% of the qualified new plan startup costs paid or incurred by a small business for 3 years.* If you choose to cover the monthly $6 per participant fee as an added benefit to your employees, this will increase the total plan startup costs that may qualify for this tax credit. Consult with a tax advisor or other appropriate counsel for details. <p class="fs-disclaimer">*(1) $500 or (2) the lesser of (a) $250 for each non-highly compensated employee who is eligible to participate in the plan or (b) $5,000.</p>',
  options: [
    {
      id: 1,
      user: {
        employer: {
          label: 'Business owner (you)',
        },
        advisor: {
          label: 'Business owner (your client)',
        },
      },
      label: 'Business owner',
      value: 'true',
      valueFormatted: 'Business owner',
      checked: false,
      icon: 'icon-single-user-o',
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: 'Employees',
      value: 'false',
      valueFormatted: 'Employees',
      checked: false,
      icon: 'icon-multi-user-o',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  reviewScreenTitle: 'Who pays the monthly $6 per participant fee',
  sideCardTitle: 'Responsible for recordkeeping fee',
  description:
    'There is a flat monthly $6 fee per participating employee for the Simply Retirement 401(k) plan. The business owner can pay this fee quarterly—or it can be deducted from each participating employee’s plan assets monthly.',
};
