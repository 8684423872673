const date = new Date();

export const planEffectiveDate = {
  id: 17,
  stepName: '_tpa_step_4',
  paradigmAlias: 'planEffectiveDate',
  type: 'date',
  name: 'planEffectiveDate',
  user: {
    employer: {
      label: 'When do you want the plan to start?',
    },
    advisor: {
      label: 'When do you want the plan to start?',
    },
    tpa: {
      label: 'When do you want the plan to start?',
    },
  },
  inputLabel: 'Plan effective date',
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  valueFormatted: '',
  hasConditional: false,
  hasError: false,
  reviewScreenTitle: 'Plan effective date',
  sideCardTitle: 'Plan effective date',
  description:
    'The plan effective date is the date on which eligible employees may begin participating in the plan.',
  info: `This date is passed the current year deadline for establishing a safe harbor plan. If you select a safe harbor plan, you must select a plan effective date no earlier than January 1, ${
    date.getFullYear() + 1
  }. <strong>For questions please call 844-804-0604.</strong>`,
  infoDate: `January 1, ${date.getFullYear() + 1}`,
};
