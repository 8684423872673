export const getServiceRequirement = async (
  minServiceReq,
  minServiceReqDiscretionary,
) => {
  let serviceReq;
  if (minServiceReq.value === 'noServiceRequirement') {
    serviceReq = minServiceReqDiscretionary;
  } else if (minServiceReq.value === 'oneYearService') {
    serviceReq = minServiceReq;
  } else if (minServiceReq.value === '12months') {
    serviceReq =
      minServiceReqDiscretionary.value === 'oneYearService' ||
      minServiceReqDiscretionary.value === 'oneYear'
        ? minServiceReqDiscretionary
        : minServiceReq;
  } else if (minServiceReq.value === 'sixMonths') {
    serviceReq =
      minServiceReqDiscretionary.value === '12months' ||
      minServiceReqDiscretionary.value === 'twelveMonths' ||
      minServiceReqDiscretionary.value === 'oneYearService' ||
      minServiceReqDiscretionary.value === 'oneYear'
        ? minServiceReqDiscretionary
        : minServiceReq;
  } else if (minServiceReq.value === 'threeMonths') {
    serviceReq =
      minServiceReqDiscretionary.value === '12months' ||
      minServiceReqDiscretionary.value === 'twelveMonths' ||
      minServiceReqDiscretionary.value === 'oneYearService' ||
      minServiceReqDiscretionary.value === 'oneYear' ||
      minServiceReqDiscretionary.value === 'sixMonths'
        ? minServiceReqDiscretionary
        : minServiceReq;
  } else if (minServiceReqDiscretionary.value !== 'noServiceRequirement') {
    serviceReq = minServiceReqDiscretionary;
  } else {
    serviceReq = minServiceReq;
  }
  return serviceReq;
};
