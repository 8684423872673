export const planNumber = {
  stepName: '_tpa_step_4',
  paradigmAlias: 'planNumber',
  type: 'number_field',
  name: 'planNumber',
  user: {},
  label: 'Plan number',
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasError: false,
  reviewScreenTitle: 'Plan number',
  sideCardTitle: 'Plan number',
  optionDescription: '',
  description:
    'The plan number listed here must match the Summary Plan Description (SPD) document.',
};
