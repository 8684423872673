import ReactOnRails from 'react-on-rails';
import 'core-js';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import App from '../components/App';

import 'rc-slider/assets/index.css';
import '../Theme/_styles.scss';
import '../Theme/lib/bootstrapThemed.scss';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  App,
});
