export const checkValidityOnQuestionArray = (arrayOfQuestions = []) => {
  const isDirty = arrayOfQuestions.filter((q) => q.dirty);
  const hasError = arrayOfQuestions.filter((q) => q.hasError);

  // const invalid = arrayOfQuestions.filter((question) => {
  //   if (question.name !== 'advisorNpn') {
  //     return checkForErrors(question.name, question.value, arrayOfQuestions);
  //   }
  //   return false;
  // });
  // if (invalid) console.info('The following questions are blank:', invalid);

  let dirty = false;
  let error = true;
  if (isDirty.length === arrayOfQuestions.length) {
    dirty = true;
  }
  if (hasError.length === 0) {
    error = false;
  }
  // console.log(isDirty, arrayOfQuestions);

  return dirty && !error;
};
