import React from 'react';
import PropTypes from 'prop-types';

import RadioQuestion from './RadioQuestion';
import TextQuestion from './TextQuestion';
import NumberQuestion from './NumberQuestion';
import Dropdown from './Dropdown';
import Select from './Select';
import RangeSlider from './RangeSlider';
import RadioQuestionCondensed from './RadioQuestionCondensed';
import CheckBox from './CheckBox';
import Date from './Date';
import RadioQuestionInvestmentLineup from './RadioQuestionInvestmentLineup';

const whichLabel = (user) => {
  switch (user) {
    case 'advisor':
      return user;
    case 'tpa':
    case 'salesContact':
      return user;
    default:
      return 'employer';
  }
};

export const Question = ({
  uniqueId,
  type,
  labelClass,
  inputClass,
  placeholderClass,
  thousandSeparator,
  question,
  hasInfo,
  toggleInfoPanel,
  putApplicationPanelInfo,
  handleQuestionChange,
  radioWrapper,
  role,
  children,
  info,
  min,
  max,
  allowLeadingZeros,
  maxLength,
  labelInfo,
  inputLabel,
}) => {
  const { user, format, ...rest } = question;
  const { label, infoLabel, placeholder } = {
    ...user[whichLabel(role)],
  };

  switch (type) {
    case 'text_field':
      return (
        <TextQuestion
          role={role}
          uniqueId={uniqueId}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          labelClass={labelClass}
          inputClass={inputClass}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          {...rest}
        >
          {children}
        </TextQuestion>
      );
    case 'number_field':
      return (
        <NumberQuestion
          role={role}
          uniqueId={uniqueId}
          labelClass={labelClass}
          inputClass={inputClass}
          placeholderClass={placeholderClass}
          thousandSeparator={thousandSeparator}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          allowLeadingZeros={allowLeadingZeros}
          maxLength={maxLength}
          {...rest}
        >
          {children}
        </NumberQuestion>
      );
    case 'radio_boolean':
    case 'radio_button':
      return (
        <RadioQuestion
          role={role}
          labelClass="fs-form-q fw-bold text-center"
          inputClass={inputClass}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          {...rest}
        />
      );
    case 'radio_condensed':
      return (
        <RadioQuestionCondensed
          role={role}
          labelClass={labelClass}
          inputClass={inputClass}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          radioWrapper={radioWrapper}
          info={info}
          {...rest}
        >
          {children}
        </RadioQuestionCondensed>
      );
    case 'radio_button_investment':
      return (
        <RadioQuestionInvestmentLineup
          role={role}
          labelClass="fs-form-q fw-bold text-center"
          inputClass={inputClass}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          putApplicationPanelInfo={putApplicationPanelInfo}
          {...rest}
        >
          {children}
        </RadioQuestionInvestmentLineup>
      );
    case 'dropdown':
      return (
        <Dropdown
          role={role}
          uniqueId={uniqueId}
          labelClass={labelClass}
          inputClass={inputClass}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          labelInfo={labelInfo}
          {...rest}
        >
          {children}
        </Dropdown>
      );
    case 'select':
      return (
        <Select
          role={role}
          uniqueId={uniqueId}
          labelClass={labelClass}
          inputClass={inputClass}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          {...rest}
        >
          {children}
        </Select>
      );
    case 'slider':
      return (
        <RangeSlider
          role={role}
          labelClass={labelClass}
          inputClass={inputClass}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          format={format}
          {...rest}
        />
      );
    case 'checkbox':
      return (
        <CheckBox
          role={role}
          labelClass="fs-form-q fw-bold text-center"
          inputClass={inputClass}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          info={info}
          {...rest}
        />
      );
    case 'date':
      return (
        <Date
          uniqueId={uniqueId}
          role={role}
          labelClass={labelClass}
          inputClass={inputClass}
          hasInfo={hasInfo}
          toggleInfoPanel={toggleInfoPanel}
          handleQuestionChange={handleQuestionChange}
          label={label}
          infoLabel={infoLabel}
          placeholder={placeholder}
          info={info}
          min={min}
          max={max}
          inputLabel={inputLabel}
          {...rest}
        />
      );
    default:
      return false;
  }
};

Question.propTypes = {
  uniqueId: PropTypes.string,
  type: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  placeholderClass: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  question: PropTypes.object,
  hasInfo: PropTypes.bool,
  toggleInfoPanel: PropTypes.func,
  putApplicationPanelInfo: PropTypes.func,
  handleQuestionChange: PropTypes.func.isRequired,
  radioWrapper: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.any,
  info: PropTypes.any,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowLeadingZeros: PropTypes.bool,
  maxLength: PropTypes.number,
  inputLabel: PropTypes.string,
  labelInfo: PropTypes.string,
};

export default Question;
