import { StyleSheet } from '@react-pdf/renderer';

const utilities = {
  bgWhite: { backgroundColor: '#FFFFFF' },
  bgGray: { backgroundColor: '#bdbdbd' },
  bgLightGray: { backgroundColor: '#eeeded' },
  colorCopy: { color: '#4d4e53' },
  colorBrandPrimary: { color: '#002855' },
  colorBrandPrimaryLight: { color: '#0091da' },
  colorCopySummaryFooter: { color: '#bdbdbd' },
  fontSanSerif: { fontFamily: 'Elliot' },
  dingbats: { fontFamily: 'registered-symbol-superscript' },
  fontNormal: { fontStyle: 'normal' },
  fontWeight100: { fontWeight: 100 },
  fontWeight400: { fontWeight: 400 },
  font24: { fontSize: '24pt' },
  font14: { fontSize: '14pt' },
  font10: { fontSize: '10pt' },
  font9: { fontSize: '9pt' },
  font8: { fontSize: '8pt' },
  font7: { fontSize: '7pt' },
  font6: { fontSize: '6pt' },
  font5: { fontSize: '5pt' },
  font4: { fontSize: '4pt' },
  lineHeight1: { lineHeight: 1 },
  lineHeight15: { lineHeight: 1.5 },
  spacingPr10: { paddingRight: 10 },
  spacingPl10: { paddingLeft: 10 },
  spacingMb10: { marginBottom: 10 },
  spacingMy10: { marginTop: 10, marginBottom: 10 },
  horizontalMargins: {
    marginLeft: '0.5in',
    marginRight: '0.5in',
  },
  block: { display: 'block' },
  flex: { display: 'flex' },
  flexRow: { flexDirection: 'row' },
  w50: { width: '50%' },
  grow1: { flexGrow: 1 },

  debug: { border: 'solid', borderWidth: 1, borderColor: 'red' },
};

const appStyles = {
  page: {
    ...utilities.bgWhite,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  section: {
    ...utilities.horizontalMargins,
    ...utilities.spacingMb10,
    display: 'block',
  },
  sectionRow: {
    ...utilities.horizontalMargins,
    ...utilities.spacingMb10,
    ...utilities.flex,
    ...utilities.flexRow,
    ...utilities.spacingMb10,
    ...utilities.block,
  },
  column50: {
    ...utilities.w50,
    ...utilities.grow1,
    ...utilities.spacingMb10,
  },
  sectionCallOut: {
    ...utilities.horizontalMargins,
    ...utilities.spacingMb10,
    borderRadius: 25,
    backgroundColor: '#f0f0f0',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 50,
    paddingRight: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionHeader: {
    ...utilities.spacingMy10,
    ...utilities.horizontalMargins,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    marginLeft: 'auto',
    width: '1.8in',
  },
  summaryHeader: {
    width: '100%',
    position: 'absolute',
    top: '0px',
    paddingBottom: '35px',
    paddingTop: '25px',
    ...utilities.bgLightGray,
  },
  summaryFooter: {
    width: '100%',
    position: 'absolute',
    bottom: '0px',
  },
  copySummaryFooter1: {
    ...utilities.colorCopySummaryFooter,
    ...utilities.fontSanSerif,
    ...utilities.font8,
    ...utilities.fontWeight100,
    bottom: '10px',
    left: '25px',
  },
  copySummaryFooter2: {
    ...utilities.colorCopySummaryFooter,
    ...utilities.fontSanSerif,
    ...utilities.font8,
    ...utilities.fontWeight100,
    left: '450px',
    bottom: '20px',
  },
  summaryIconSection: {
    position: 'absolute',
    top: '90px',
    paddingLeft: '50px',
    marginLeft: '20px',
    width: '65px',
    height: '80%',
  },
  summaryHeaderText: {
    position: 'absolute',
    left: '25px',
    top: '25px',
    ...utilities.colorCopy,
  },
  summarySectionText: {
    position: 'relative',
    paddingTop: '85px',
    paddingLeft: '100px',
    paddingRight: '90px',
  },
  summarySectionBoldText: {
    paddingTop: 5,
    display: 'inline',
    ...utilities.fontWeight400,
    ...utilities.colorCopy,
    ...utilities.fontSanSerif,
    ...utilities.font10,
  },
  summaryParagraphHeader: {
    paddingTop: 5,
    ...utilities.fontSanSerif,
    ...utilities.font14,
    ...utilities.lineHeight15,
    ...utilities.fontNormal,
    ...utilities.fontWeight100,
  },
  summaryParagraphText: {
    paddingTop: 5,
    ...utilities.colorCopy,
    ...utilities.fontSanSerif,
    ...utilities.font10,
    ...utilities.lineHeight15,
    ...utilities.fontNormal,
    ...utilities.fontWeight100,
  },
  summaryIcon1: {
    position: 'absolute',
    width: '0.5in',
    marginLeft: '20px',
    height: '0.6in',
  },
  summaryIcon2: {
    position: 'absolute',
    marginTop: '235px',
    marginLeft: '20px',
    width: '0.5in',
    height: '0.6in',
  },
  summaryIcon3: {
    position: 'absolute',
    marginTop: '415px',
    marginLeft: '20px',
    width: '0.5in',
    height: '0.6in',
  },
  summaryIcon3Fp: {
    position: 'absolute',
    marginTop: '430px',
    marginLeft: '20px',
    width: '0.5in',
    height: '0.6in',
  },
  summaryIcon4: {
    position: 'absolute',
    marginTop: '545px',
    marginLeft: '20px',
    width: '0.5in',
    height: '0.6in',
  },
  copyDisplay1: {
    ...utilities.colorBrandPrimaryLight,
    ...utilities.fontSanSerif,
    ...utilities.font14,
    ...utilities.lineHeight1,
    ...utilities.fontNormal,
    ...utilities.fontWeight400,
  },
  copyDisplay1Super: {
    ...utilities.colorBrandPrimaryLight,
    ...utilities.fontSanSerif,
    ...utilities.font7,
  },
  copyTitle: {
    ...utilities.colorCopy,
    ...utilities.font14,
    ...utilities.fontNormal,
    ...utilities.fontWeight100,
    fontFamily: 'Elliot',
  },
  copyTitleSuper: {
    ...utilities.colorCopy,
    ...utilities.fontSanSerif,
  },
  copyDefault: {
    ...utilities.colorCopy,
    ...utilities.fontSanSerif,
    ...utilities.font9,
    ...utilities.lineHeight15,
    ...utilities.fontNormal,
    ...utilities.fontWeight100,
  },
  copyDefaultSuper: {
    ...utilities.colorCopy,
    ...utilities.fontSanSerif,
  },
  copyDisclaimer: {
    ...utilities.colorCopy,
    ...utilities.fontSanSerif,
    ...utilities.fontNormal,
    ...utilities.fontWeight100,
    ...utilities.font8,
    ...utilities.lineHeight1,
  },
  copyDisclaimerSuper: {
    ...utilities.colorCopy,
    ...utilities.fontSanSerif,
    verticalAlign: 10,
  },
  registrationSup: {
    ...utilities.dingbats,
  },
};

const tableStyles = {
  sectionTable: {
    ...utilities.horizontalMargins,
    width: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  tableStyle: {
    display: 'table',
    flexGrow: 1,
    width: '33%',
  },
  tableHeaderStyle: {
    ...utilities.bgGray,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 10,
  },
  tableHeaderCellStyle: {
    ...utilities.colorBrandPrimary,
    ...utilities.fontSanSerif,
    ...utilities.fontNormal,
    ...utilities.fontWeight400,
    ...utilities.font9,
  },
  tableHeaderCellStyleSup: {
    ...utilities.colorBrandPrimary,
    ...utilities.dingbats,
    ...utilities.fontNormal,
    ...utilities.fontWeight400,
    ...utilities.font9,
  },
  tableRowStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: 50,
  },
  tableColStyle: {
    borderStyle: 'solid',
    borderColor: '#bdbdbd',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    flexGrow: 1,
    width: '100%',
    maxWidth: '100%',
    padding: 5,
    flex: '1 0 0%',
    overflow: 'wrap',
  },
  tableCellStyle: {
    ...utilities.colorCopy,
    ...utilities.fontSanSerif,
    ...utilities.font9,
    ...utilities.lineHeight1,
    ...utilities.fontNormal,
    ...utilities.fontWeight100,
    textAlign: 'center',
    hyphens: 'none',
  },
};

export const styles = StyleSheet.create({
  utilities: {
    ...utilities,
  },
  ...appStyles,
  ...tableStyles,
});
