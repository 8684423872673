import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayerLoader from '@brightcove/react-player-loader';

const AboutSection = ({ whyUsSection }) => (
  <section className="section section_py-5">
    <div className="container position-relative" style={{ zIndex: 1 }}>
      <div className="row">
        <div className="col-lg px-lg-5">
          <div
            dangerouslySetInnerHTML={{
              __html: whyUsSection.heading,
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: whyUsSection.copy,
            }}
          />
        </div>
        <div className="col-lg">
          <ReactPlayerLoader
            accountId={whyUsSection.video.accountId}
            videoId={whyUsSection.video.videoId}
            attrs={{
              className: 'brightcove-player mb-4',
            }}
            embedOptions={{ responsive: { aspectRatio: '16:9' } }}
          />
        </div>
      </div>
    </div>
  </section>
);

export default AboutSection;

AboutSection.propTypes = {
  whyUsSection: PropTypes.object.isRequired,
};
