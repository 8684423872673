export const numberToCurrency = (number, fraction = 0) => {
  if (number) {
    return `$${number.toLocaleString(
      ('us-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction,
      }),
    )}`;
  }
  return '$0';
};
