import React from 'react';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { Question } from '../FormWithSteps';
import { useTpaFirmApi } from '../../containers/TPA/Plans/TpaQuestions/TpaInformation/useTpaFirmApi';

const RowTpa = ({ data, handleQuestionChange }) => {
  const [{ tpaFirmOptions, isLoading, isError }] = useTpaFirmApi();
  return (
    <div className="row justify-content-center">
      <div className="col-md-5 mb-5">
        <h4 className="min-h-50">Third party administrator details</h4>
        <Question
          type={data.tpaFirstName.type}
          inputClass="srp-form-input form-control"
          question={{
            ...data.tpaFirstName,
            label: `${data.tpaFirstName.label} ${
              data.tpaFirstName.required ? '(Required)' : '(Optional)'
            }`,
          }}
          handleQuestionChange={handleQuestionChange}
        >
          {data.tpaFirstName.hasError && data.tpaFirstName.required && (
            <small>Question is required</small>
          )}
        </Question>
        <Question
          type={data.tpaLastName.type}
          inputClass="srp-form-input form-control"
          question={{
            ...data.tpaLastName,
            label: `${data.tpaLastName.label} ${
              data.tpaLastName.required ? '(Required)' : '(Optional)'
            }`,
          }}
          handleQuestionChange={handleQuestionChange}
        >
          {data.tpaLastName.hasError && data.tpaLastName.required && (
            <small>Question is required</small>
          )}
        </Question>
        <Question
          type={data.tpaEmail.type}
          inputClass="srp-form-input form-control"
          question={{
            ...data.tpaEmail,
            label: `${data.tpaEmail.label} ${
              data.tpaEmail.required ? '(Required)' : '(Optional)'
            }`,
          }}
          handleQuestionChange={handleQuestionChange}
        >
          {data.tpaEmail.hasError && data.tpaEmail.required && (
            <small>Question is required</small>
          )}
        </Question>
      </div>
      <div className="offset-md-1 col-md-5 mb-5">
        <h4 className="min-h-50"></h4>
        <div
          className={classNames('mb-3 form-group', {
            'has-error': data.tpaPhone.hasError,
          })}
        >
          <label className="" htmlFor="tpaPhone">
            {`${data.tpaPhone.label} ${
              data.tpaPhone.required ? '(Required)' : '(Optional)'
            }`}
          </label>
          <PatternFormat
            value={data.tpaPhone.value}
            format="###-###-####"
            mask="_"
            id={data.tpaPhone.name}
            name={data.tpaPhone.name}
            required={data.tpaPhone.required}
            className={classNames('srp-form-input form-control mb-3', {
              'is-invalid': data.tpaPhone.hasError,
              'is-dirty': data.tpaPhone.dirty,
            })}
            onValueChange={(values) => {
              handleQuestionChange(
                data.tpaPhone.name,
                values.value,
                values.formattedValue,
              );
            }}
            type={data.tpaPhone.type}
          />
        </div>
        <Question
          type={data.tpaFirmName.type}
          inputClass="srp-form-input form-control"
          question={{
            ...data.tpaFirmName,
            label: `${data.tpaFirmName.label} ${
              data.tpaFirmName.required ? '(Required)' : '(Optional)'
            }`,
            disabled: isLoading || isError,
            options: tpaFirmOptions,
          }}
          handleQuestionChange={handleQuestionChange}
        >
          {data.tpaFirmName.hasError && data.tpaFirmName.required && (
            <small>Question is required</small>
          )}
        </Question>
      </div>
    </div>
  );
};

RowTpa.propTypes = {
  data: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default RowTpa;
