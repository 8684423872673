import API from '../../api';
import { planHelper, collectSavedPlanData } from '../Paradigm/planHelper';
import { createSalesforceProposal } from '../Salesforce/salesforceHelper';
const api = API.create();

export const paradigmPurchase = async ({
  formData,
  authenticityToken,
  setPurchasePlanStatus,
  advisorCvStatuses,
  putPurchasePlanURL,
  resetData,
  tpaParadigmPackager = [],
  isOneDigital = false,
}) => {
  const { hasFp } = formData.planDesign.questions;
  const paradigmPlan = await planHelper(
    formData.planDesign,
    advisorCvStatuses,
    tpaParadigmPackager,
    authenticityToken,
    formData.role,
    formData.planURL,
  );
  const data = {
    state: formData,
    paradigm_plan: paradigmPlan,
    authenticity_token: authenticityToken,
    email: formData.planDesign.questions.sponsorEmail.value,
  };

  if (hasFp.value) {
    data.paradigm_advisors = collectSavedPlanData(formData).paradigm_advisors;
  }

  if (formData.hashId) {
    data.hashId = formData.hashId;
  }

  const response = await api.paradigmPurchase(data);

  const { purchaseLink } = response.data;

  setPurchasePlanStatus(response.status);
  putPurchasePlanURL(purchaseLink, response.data.planId);
  if (response.status === 200 || response.status === 201) {
    const goToParadigm = () => {
      if (typeof window !== 'undefined') {
        window.location.href = purchaseLink;
      }
    };
    createSalesforceProposal({
      formData,
      authenticityToken,
      stageFlag: 'Purchase',
      link: formData.planURL,
      advisorCvStatuses,
      tpaParadigmPackager,
      hashId: formData.hashId,
      isOneDigital,
    });
    sessionStorage.clear();
    resetData();
    goToParadigm();
  }
};
