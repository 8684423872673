import styled from 'styled-components';

export const SectionFullStyles = styled.div`
  position: relative;
  padding-bottom: 75px;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    bottom: -110px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    width: 110%;
    height: 160px;
    background-color: #fff;
  }
`;

export const FullImgStyles = styled.div`
  display: none;
  border-left: 10px solid rgb(255, 255, 255);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 50%;
  bottom: 0px;
  right: 0px;
  @media (min-width: 768px) {
    display: block;
  }
`;

export const HeaderStyles = styled.div`
  &::after {
    display: none;
  }
`;

export const QuestionWrapperStyles = styled.div`
  position: relative;
  z-index: 5;
  margin-top: -51px;
  overflow: hidden;
`;

export const DivStyled = styled.div`
  &:first-child {
    margin-bottom: 100px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
    &::after {
      content: '';
      background-color: #ffffff;
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
      height: 2px;
      @media (min-width: 768px) {
        right: 0;
        left: auto;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%) translateX(0);
        width: 2px;
        height: 90%;
      }
      @media (min-width: 992px) {
        height: 100%;
      }
    }
  }
`;

export const PurpleSectionStyles = styled.section`
  overflow: hidden;
  background-color: #002855;
  position: relative;
  padding: 8rem 0;
  &:before,
  &:after {
    content: '';
    display: block;
    background: #ffffff;
    z-index: 0;
    left: 0;
    right: 0;
    height: 200px;
    transform: scale(1.2);
    position: absolute;
    border-radius: 100%;
  }
  &:before {
    top: -165px;
  }
  &:after {
    top: auto;
    bottom: -165px;
  }
`;
