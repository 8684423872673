import React from 'react';

const MobileContact = () => (
  <div className="contactlist d-lg-none">
    <div className="row m-0 p-0">
      <div className="contactlist__item col-12 text-center m-0 p-0">
        <a
          className="contactlist__link text-white d-block no-link"
          href="tel:+18448040604"
        >
          844-804-0604
        </a>
      </div>
    </div>
  </div>
);

export default MobileContact;
