import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectIsFirmBrokerSplitComp,
  selectAdvisors,
} from '../../../../../Redux/FormDataRedux';
import {
  Question,
  NextStepBtn,
  PrevStepBtn,
} from '../../../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../../../common/FormWithSteps/Utilities';
import AdvisorAuthHeader from '../../AdvisorAuthHeader';

const Compensation = ({
  active,
  destination,
  previousStep,
  updateActiveStep,
  advisors,
  updateAdvisor,
  updateAllAdvisors,
  googleData,
}) => {
  const loadTotalCompensation = advisors.reduce(
    (acc, value) => acc + value.questions.compensation.value,
    0,
  );
  const [totalCompensation, setTotalCompensation] = useState(
    loadTotalCompensation,
  );
  const [maxAmount, setMaxAmount] = useState(100 - loadTotalCompensation);
  const [validation, setValidation] = useState({ status: '', msg: '' });

  const setAllocation = ({
    questionName,
    updateValue,
    valueFormatted,
    index: idx,
  }) => {
    const filteredTotals = advisors
      .filter((_advisor, index) => index !== idx)
      .reduce((acc, value) => acc + value.questions.compensation.value, 0);
    const hasError =
      filteredTotals + updateValue < 100 || filteredTotals + updateValue > 100;
    setTotalCompensation(filteredTotals + updateValue);
    setMaxAmount(100 - (filteredTotals + updateValue));
    updateAdvisor(
      {
        name: questionName,
        dirty: true,
        value: updateValue,
        valueFormatted,
        hasError,
      },
      idx,
    );
    advisors.forEach((_advisor, index) => {
      if (index !== idx) {
        updateAdvisor(
          {
            name: questionName,
            hasError,
          },
          index,
        );
      }
    });
  };

  const handleQuestionChange = (props) => {
    setAllocation(props);
  };

  const isDisabled = totalCompensation !== 100;

  useEffect(() => {
    updateAllAdvisors({
      name: 'compensation',
      hasError: totalCompensation !== 100,
      dirty: true,
    });
  }, []);

  useEffect(() => {
    if (totalCompensation < 100 || totalCompensation > 100) {
      setValidation({
        status: 'danger',
        msg: `Allocation is ${totalCompensation} with ${maxAmount} remaining.`,
      });
    }
    if (totalCompensation === 100) {
      setValidation({
        status: 'success',
        msg: `All compensation ${totalCompensation}% allocated.`,
      });
    }
  }, [advisors]);

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <AdvisorAuthHeader step={5} />
      <div className="row">
        <div className="col-md-8 mx-auto">
          {advisors.map((advisor, index) => {
            const { advisorFirstName, advisorLastName } = advisor.questions;
            const { type, value } = advisor.questions.compensation;
            const label = `${advisorFirstName.value} ${advisorLastName.value} (${value} of ${maxAmount} remaining)`;
            const indexKey = index;
            return (
              <fieldset
                key={`${advisorFirstName.value
                  .toLowerCase()
                  .replace(/[^\w\s]/gi, '')}-${indexKey}`}
                className="border-bottom"
              >
                <Question
                  type={type}
                  labelClass="fs-h5 fw-bold pt-3 pb-4"
                  question={{
                    ...advisor.questions.compensation,
                    label,
                  }}
                  handleQuestionChange={(
                    questionName,
                    updateValue,
                    valueFormatted,
                  ) => {
                    handleQuestionChange({
                      questionName,
                      updateValue,
                      valueFormatted,
                      index,
                    });
                  }}
                >
                  {advisorFirstName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </fieldset>
            );
          })}
          <div className={`text-${validation.status}`}>{validation.msg}</div>
        </div>
      </div>
      <div className="form-group fws__control">
        <NextStepBtn
          disabled={isDisabled}
          onClick={() => {
            updateActiveStep(destination(), 'forward');
          }}
        />
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

Compensation.propTypes = {
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  updateActiveStep: PropTypes.func,
  advisors: PropTypes.array.isRequired,
  updateAdvisor: PropTypes.func.isRequired,
  updateAllAdvisors: PropTypes.func.isRequired,
  googleData: PropTypes.object,
};

const mapStateToProps = (store) => ({
  isFirmBrokerSplitComp: selectIsFirmBrokerSplitComp(store),
  advisors: selectAdvisors(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateAdvisor: (obj, index) => {
    dispatch(FormDataRedux.updateAdvisor(obj, index));
  },
  updateAllAdvisors: (obj) => {
    dispatch(FormDataRedux.updateAllAdvisors(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Compensation);
