export const planUsingSafeHarbor = {
  id: 'q6aI1c',
  type: 'radio_condensed',
  name: 'planUsingSafeHarbor',
  paradigmAlias: 'planUsingSafeHarbor',
  user: {},
  stepName: 'a_step_6_a_i_1_c',
  label: 'Will the plan be using safe harbor?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      icon: 'icon-check-o',
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      icon: 'icon-close-o',
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Safe harbor',
  reviewScreenTitle: 'Safe harbor',
  optionDescription: '',
  description:
    'Employers have the option to match employee contributions. There are two ways an employer can contribute—safe harbor and non-safe harbor.',
};
