import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import EditButton from './EditButton';
import Label from './Label';
import SwitchCard from './SwitchCard';
import { selectTpas, selectRole } from '../../Redux/FormDataRedux';

const TpaInfoCard = ({ userRole, tpas, questions, goToRoleSelection }) => {
  const [current, setCurrent] = useState(0);
  const [tpaHeader, setTpaHeader] = useState('Sales contact TPA');
  const {
    employerStateCode,
    companyName,
    tpaFirmName,
    employerIdentificationNumber,
    tpaLogo,
    tpaFirmNameUnlisted,
  } = questions;
  const isUserRole = (role) => role === userRole;
  const isEmpty = (key, index) => tpas[index].questions[key].value === '';
  const isRequired = (key, index) =>
    tpas[index].questions[key].required
      ? 'Missing information'
      : 'Not required';
  const labelText = (key, index) =>
    isEmpty(key, index)
      ? isRequired(key, index)
      : tpas[index].questions[key].value;

  const hasManyTpas = tpas.length > 1;

  return (
    <section>
      <div className="d-flex justify-content-between align-content-center mb-4 d-print-none">
        <div className="fs-h3 text-purple fw-bold">
          <span className="mr-3">{tpaHeader}</span>
          {!!isUserRole('tpa') && (
            <EditButton
              handleClick={() => goToRoleSelection('tpaInformation')}
            />
          )}
        </div>
        {!!hasManyTpas && (
          <SwitchCard
            current={current}
            setCurrent={setCurrent}
            total={tpas.length}
            setTpaHeader={setTpaHeader}
          />
        )}
      </div>
      <div className="advisors-carousel">
        {tpas.map((tpa, index) => {
          const keyIndex = index;
          const tpaFirm =
            tpaFirmName.value.value === 'myFirmIsntListed'
              ? tpaFirmNameUnlisted.value
              : tpaFirmName.value.value;
          return (
            <div
              key={keyIndex}
              className={classNames('row mb-5', {
                'advisors-carousel--hide': current !== index,
              })}
            >
              <div className="col">
                <strong className="fs-h5 text-purple">
                  {index === 0 && 'Proposal created by:'}
                </strong>
                <Label
                  label="Name"
                  labelStyle={
                    isEmpty('tpaFirstName', index) ? 'text-danger' : ''
                  }
                >
                  {`${labelText('tpaFirstName', index)} ${
                    tpa.questions.tpaLastName.value
                  }`}
                </Label>
                <Label
                  label="Email"
                  labelStyle={isEmpty('tpaEmail', index) ? 'text-danger' : ''}
                >
                  {labelText('tpaEmail', index)}
                </Label>
                {tpa.questions.tpaPhone.valueFormatted && (
                  <Label
                    label="Phone number"
                    labelStyle={isEmpty('tpaPhone', index) ? 'text-danger' : ''}
                  >
                    {tpa.questions.tpaPhone.valueFormatted}
                  </Label>
                )}
                {!!isUserRole('tpa') && (
                  <Label label="Firm Name">{tpaFirm}</Label>
                )}
                {!!isUserRole('tpa') && (
                  <Label label="Employer Identification Number (EIN)">
                    {employerIdentificationNumber.value}
                  </Label>
                )}
                {!!tpaLogo.value && (
                  <img
                    className="imagePreview"
                    src={tpaLogo.value}
                    alt="Third Party Administrator Logo"
                  />
                )}
              </div>
              <div className="col">
                {!!isUserRole('tpa') && (
                  <div>
                    <Label label="Client’s company name">
                      {companyName.value}
                    </Label>
                    <Label label="Client’s state of residence">
                      {employerStateCode.value}
                    </Label>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <hr />
      <hr className="mb-5" />
    </section>
  );
};

TpaInfoCard.propTypes = {
  userRole: PropTypes.string.isRequired,
  tpas: PropTypes.array.isRequired,
  questions: PropTypes.object.isRequired,
  goToRoleSelection: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  tpas: selectTpas(store),
  questions: store.formData.planDesign.questions,
  userRole: selectRole(store),
});

export default connect(mapStateToProps)(TpaInfoCard);
