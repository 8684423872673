import React from 'react';
import PropTypes from 'prop-types';
import ActionCard from './ActionCard';

const ActionCardSection = ({ cards }) => (
  <div className="container">
    <div className="row">
      {cards.map((card) => (
        <div className="col-md-4" key={card.id}>
          <div className="calltoaction-wrapper">
            <ActionCard
              icon={card.icon}
              image={card.image}
              excerpt={card.excerpt}
              caption={card.caption}
              actionText={card.actionText}
              actionPath={card.actionPath}
            />
          </div>
        </div>
      ))}
    </div>
  </div>
);

ActionCardSection.propTypes = {
  cards: PropTypes.array.isRequired,
};

export default ActionCardSection;
