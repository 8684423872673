import React from 'react';
import PropTypes from 'prop-types';

const InfoPanel = ({ toggleInfoPanel, heading, content }) => (
  <>
    <button
      type="button"
      onClick={() => {
        toggleInfoPanel();
      }}
      className="fws__info-arrow"
    >
      <i
        className="fa-sharp fa-solid fa-chevron-right fa-2xl"
        style={{ color: '#979797' }}
      ></i>
      <span className="sr-only">Close</span>
    </button>
    <div className="p-5 p-md-3 ps-lg-5">
      <p className="pt-4 pt-lg-5 fs-h3 lh-100 text-purple fw-bold">{heading}</p>
      <div className="fs-p" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </>
);

InfoPanel.propTypes = {
  toggleInfoPanel: PropTypes.func.isRequired,
  heading: PropTypes.string,
  content: PropTypes.string,
};

export default InfoPanel;
