import React from 'react';
import PropTypes from 'prop-types';

export const PrevStepBtn = ({
  goTo,
  updateActiveStep,
  disabled,
  active,
  light,
}) => (
  <button
    type="button"
    className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''} ${
      light ? ' text-white' : ''
    }`}
    disabled={disabled}
    onClick={() => {
      updateActiveStep(goTo, 'back');
    }}
  >
    <span className="sr-only">Go Back</span>
  </button>
);

PrevStepBtn.propTypes = {
  goTo: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  light: PropTypes.bool,
};

export default PrevStepBtn;
