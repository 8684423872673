import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CostEstimatorCard from './CostEstimatorCard';
import { numberToCurrency } from '../Utilities';
import { rootRoute } from '../../../Services/utilities';

const CostEstimator = ({ questions, setNotificationIndicator }) => {
  const location = useLocation();
  const isInOneDigitalPath = rootRoute(location.pathname) === '/onedigital';
  const onedigitalCopy = {
    id: 4,
    title: 'OneDigital advisory compensation annual fee',
    value: '$750',
    period: 'per quarter',
    hasInfo: false,
  };
  const {
    numberOfEmployees,
    employerPaidParticipantFees,
    averageSalary,
    employerContributionMatch,
    advisorCompensation,
    advisorType,
    advisorFee,
    advisorPoints,
    safeHarbor,
    stretchSafeHarbor,
    minimumAutoDeferralPercentage,
    employerMatch,
  } = questions;
  const advisorFeeValue = () => {
    if (
      advisorCompensation.valueFormatted ===
        'Deducted from participant accounts' &&
      advisorType.valueFormatted === 'Flat Fee'
    ) {
      return `$${parseFloat(
        advisorFee.value / (numberOfEmployees.value * 12),
      ).toFixed(2)}`;
    }
    return '';
  };
  const buisnessOwnerMatchValue = () => {
    if (safeHarbor.value === 'safeHarbor' && stretchSafeHarbor.value === '5') {
      switch (true) {
        case minimumAutoDeferralPercentage.value > 4:
          return 4;

        default:
          return minimumAutoDeferralPercentage.value;
      }
    } else if (
      safeHarbor.value === 'safeHarbor' &&
      stretchSafeHarbor.value === '4'
    ) {
      switch (true) {
        case minimumAutoDeferralPercentage.value === 3:
          return 3;

        case minimumAutoDeferralPercentage.value === 4:
          return 3.5;

        default:
          return 4;
      }
    } else if (safeHarbor.value === 'nonSafeHarbor') {
      switch (true) {
        case employerMatch.value > 7:
          return 8;

        default:
          return employerMatch.value;
      }
    }
    return 0;
  };

  const businessOwnerCostCardsTitle = 'Estimated Business Owner Plan Costs';
  const businessOwnerCostCards = [
    {
      id: 1,
      title: 'One-time setup fee for bundled plans',
      value: '$500',
      period: '',
      hasInfo: false,
    },
    {
      id: 2,
      title: 'Ongoing recordkeeping fee',
      value: '$185',
      period: 'per month',
      hasInfo: false,
    },
    {
      id: 3,
      title: 'Ongoing participant fee',
      value:
        employerPaidParticipantFees.valueFormatted === 'Business owner'
          ? `$${parseInt(numberOfEmployees.value, 10) * 6}`
          : '',
      period: 'per month',
      hasInfo: true,
      info: `<p class="fs-sm mb-0"><strong>&ensp;&ensp;&ensp;${
        numberOfEmployees.value
      }</strong> employees</p><p class="fs-sm mb-0">x&ensp;&ensp;<strong>$6 per-participant</strong> fee</p><p class="fs-sm cost-estimator-info-border mt-2 pt-2">=&ensp;&ensp;<strong>$${
        parseInt(numberOfEmployees.value, 10) * 6
      } per month
      </strong></p><p class="fs-sm">As long as you maintain the same number of employees, this amount will also remain the same, year after year.</p>`,
    },
    ...(isInOneDigitalPath ? [onedigitalCopy] : []),
  ];
  const businessOwnerContributionCardTitle =
    'Estimated Business Owner Contributions';
  const businessOwnerContributionCard = [
    {
      id: 1,
      title: 'Ongoing matching contributions',
      value: `${numberToCurrency(
        parseInt(
          (averageSalary.value *
            numberOfEmployees.value *
            (buisnessOwnerMatchValue() / 100)) /
            12,
          10,
        ),
        2,
      )}`,
      period: 'per month',
      hasInfo: true,
      info: `<p class="fs-sm mb-0">&#160;&#160;&#160;<strong>${numberToCurrency(
        parseInt(averageSalary.value, 10),
      )}</strong> per year avg. salary</p><p class="fs-sm mb-0">x&#160;&#160;<strong>${buisnessOwnerMatchValue()}%</strong> matching contribution</p><p class="fs-sm mb-0">x&#160;&#160;<strong>${parseInt(
        numberOfEmployees.value,
        10,
      )}</strong> employees</p><p class="fs-sm mb-0">&#247;&#160;&#160;<strong>12</strong> months</p><p class="fs-sm cost-estimator-info-border mt-2 pt-2">=&#160;&#160;<strong>${numberToCurrency(
        parseInt(
          (averageSalary.value *
            numberOfEmployees.value *
            (buisnessOwnerMatchValue() / 100)) /
            12,
          10,
        ),
        2,
      )}
      </strong></p>
      <p class="fs-sm">Matching contribution is estimated based on the average annual salary and number of employees you entered.</p>`,
    },
  ];
  const employeeCardTitle = 'Estimated Employee Costs';
  const employeeCostCards = [
    {
      id: 1,
      title: 'Ongoing per-participant fee',
      value:
        employerPaidParticipantFees.valueFormatted === 'Employees' ? '$6' : '',
      period: 'per month',
      hasInfo: false,
    },
    {
      id: 2,
      title: isInOneDigitalPath
        ? 'OneDigital Advisory Fee'
        : 'Financial Professional fee',
      value:
        advisorCompensation.valueFormatted ===
          'Deducted from participant accounts' &&
        advisorType.valueFormatted === 'Basis Points'
          ? `${parseInt(advisorPoints.valueFormatted, 10) / 100}%`
          : '',
      period: 'per year',
      hasInfo: true,
      info: `<p class="fs-sm mb-0">This fee will be calculated based on assets in the plan. With a financial professional fee of <strong>0.45%</strong>, participating employees will pay <strong>$4.50 for every $1,000</strong> they have in the plan annually (not to exceed $5,000 per year across all participants in plan). This amount will be deducted quarterly from participants’ plan assets.</p>`,
    },
    {
      id: 3,
      title: 'Financial Professional fee',
      value: advisorFeeValue(),
      period: 'per year',
      hasInfo: true,
      info: `<p class="fs-sm mb-0"><strong>&ensp;&ensp;${
        advisorFee.value
      }</strong> per year</p><p class="fs-sm mb-0">&#247;&#160;&#160;<strong>12</strong> months</p><p class="fs-sm mb-0">&#247;&#160;&#160;<strong>${
        numberOfEmployees.value
      }</strong> employees</p><p class="fs-sm cost-estimator-info-border mt-2 pt-2">=&ensp;&ensp;<strong>${advisorFeeValue()} per month
      </strong></p><p class="fs-sm">As long as you maintain the same number of employees, this amount will also remain the same, year after year.</p>`,
    },
  ];

  useEffect(() => {
    if (
      employerPaidParticipantFees.valueFormatted === 'Business owner' ||
      employerContributionMatch.valueFormatted === 'Yes' ||
      employerPaidParticipantFees.valueFormatted === 'Employees' ||
      advisorCompensation.valueFormatted ===
        'Deducted from participant accounts'
    ) {
      setNotificationIndicator(true);
    }
  }, [
    employerPaidParticipantFees.valueFormatted,
    advisorCompensation.valueFormatted,
    employerContributionMatch.valueFormatted,
    employerPaidParticipantFees.valueFormatted,
  ]);

  return (
    <>
      <CostEstimatorCard
        title={businessOwnerCostCardsTitle}
        cards={businessOwnerCostCards}
      />
      {employerContributionMatch.valueFormatted === 'Yes' && (
        <CostEstimatorCard
          title={businessOwnerContributionCardTitle}
          cards={businessOwnerContributionCard}
        />
      )}
      {(employerPaidParticipantFees.valueFormatted === 'Employees' ||
        advisorCompensation.valueFormatted ===
          'Deducted from participant accounts') && (
        <CostEstimatorCard
          title={employeeCardTitle}
          cards={employeeCostCards}
        />
      )}
    </>
  );
};

const mapStateToProps = () => ({});

CostEstimator.propTypes = {
  questions: PropTypes.object,
  setNotificationIndicator: PropTypes.func,
};

export default connect(mapStateToProps)(CostEstimator);
