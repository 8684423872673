import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BgImage, Circle } from './index.styles';
import { ScrollInToView } from '../../../common/VisibilitySensor';

const TwoColCircleImg = ({ heading, copy, image, circleBg }) => (
  <section className="section section_py-5">
    <div className="container position-relative">
      <div className="row">
        <div
          className="offset-xl-1 col-lg-5 col-md-7 col-8"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      </div>
      <div className="row">
        <div className="offset-xl-1 col-lg-6 col-md-7 col-sm-9">
          <div dangerouslySetInnerHTML={{ __html: copy }} />
          <p className="mb-3 text-sm-left text-center">
            <Link
              to="onedigital/why-simply-retirement"
              className="srp-button srp-button__primary"
            >
              Why choose us?
            </Link>
          </p>
        </div>
      </div>
      <ScrollInToView
        renderItem={(inView) => (
          <Circle className={circleBg}>
            <BgImage
              style={{
                backgroundImage: `url(${image})`,
                animationDelay: '0.2s',
              }}
              className={inView ? 'fadeIn animated' : 'hidden'}
            />
          </Circle>
        )}
      />
    </div>
  </section>
);

TwoColCircleImg.propTypes = {
  heading: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  circleBg: PropTypes.string.isRequired,
};

export default TwoColCircleImg;
