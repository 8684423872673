import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
import { Question, NextStepBtn } from '../../../common/FormWithSteps';
import { requireConfirmation } from '../../../Services/helpers/Paradigm/planHelper';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import { effectiveMinMaxDate } from '../../../Services/planEffectiveDate';

export const PlanEffectiveDate = ({
  role,
  active,
  questionId,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestion,
  formInReview,
  goToReviewPlan,
  googleData,
  putApplicationPanelInfo,
}) => {
  const sendDataToGTM = useGTMDispatch();
  const { dirty, hasError, info } = questions[questionId];
  const isInValid = !!(dirty && hasError);
  const isSafeHarbor = questions.safeHarbor.value === 'safeHarbor';
  let pastDeadline = requireConfirmation(
    questions.safeHarbor,
    questions[questionId].value.split('-'),
  );

  const isError = (date) => {
    if (!moment(date).isValid()) {
      return { hasError: true, errorMsg: 'Date is invalid.' };
    }
    if (moment(date).isBefore(effectiveMinMaxDate(isSafeHarbor).min)) {
      return {
        hasError: true,
        errorMsg: effectiveMinMaxDate(isSafeHarbor).valueFormatted,
      };
    }
    if (moment(date).isAfter(effectiveMinMaxDate().max)) {
      return { hasError: true, errorMsg: 'Date is too far into the future.' };
    }
    if (requireConfirmation(questions.safeHarbor, new Date(date))) {
      return { hasError: true, errorMsg: '' };
    }
    return { hasError: false, errorMsg: '' };
  };

  const handleQuestionChange = (id, updateValue) => {
    pastDeadline = requireConfirmation(
      questions.safeHarbor,
      questions[questionId].value.split('-'),
    );
    updateQuestion({
      name: id,
      value: updateValue,
      hasError: isError(updateValue).hasError,
      errorMsg: isError(updateValue).errorMsg,
      dirty: true,
      valueFormatted: updateValue,
    });
    if (
      questions.hasGrandfatherDate.value === 'yes' &&
      questions.grandfatherDateHasCustomDate.value === 'no'
    ) {
      updateQuestion({
        name: questions.grandfatherDate.name,
        value: updateValue,
        hasError: isError(updateValue).hasError,
        errorMsg: isError(updateValue).errorMsg,
        dirty: true,
        valueFormatted: updateValue,
      });
    }
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid justify-content-center">
      <div className="row h-100">
        <div className="col-md-10 col-xl-8 d-flex flex-column mx-auto my-auto">
          <Question
            role={role}
            type={questions[questionId].type}
            labelClass="fs-form-q fw-bold text-center pt-3 px-3 d-block"
            inputClass="date srp-form-input form-control mt-2 mx-auto w-50"
            question={questions[questionId]}
            handleQuestionChange={handleQuestionChange}
            min={effectiveMinMaxDate(isSafeHarbor).min}
            max={effectiveMinMaxDate().max}
            inputLabel={questions[questionId].inputLabel}
          />
          {isInValid && !pastDeadline && (
            <p className="text-danger text-center">
              {questions[questionId].errorMsg}
            </p>
          )}
          {pastDeadline && (
            <div
              className="mt-5 fs-p text-center"
              dangerouslySetInnerHTML={{ __html: info }}
            />
          )}
          {(questions[questionId].user[role]?.heading ||
            questions[questionId].user.employer.heading) && (
            <p className="text-center mb-lg-auto">
              <button
                type="button"
                className="srp-button__link"
                onClick={() =>
                  putApplicationPanelInfo({
                    hasVisibility: true,
                    heading:
                      questions[questionId].user[role]?.heading ||
                      questions[questionId].user.employer.heading,
                    additionalInformation:
                      questions[questionId].user[role]?.content ||
                      questions[questionId].user.employer.content,
                  })
                }
              >
                Need clarification?
              </button>
            </p>
          )}
          <p className="text-center">
            <NextStepBtn
              text="Review your plan"
              disabled={!(dirty && !hasError)}
              onClick={() => {
                sendDataToGTM({
                  event: 'e_toolInteraction',
                  eventType: 'progress',
                  tool: {
                    name: 'Simply Retirement',
                    stepNumber: '2',
                    stepName: 'review',
                  },
                });
                goToReviewPlan();
              }}
            />
          </p>{' '}
        </div>
      </div>
      <p
        className={classNames({
          fws__control: !formInReview,
          'fws__control-group': formInReview,
        })}
      >
        <button
          type="button"
          className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
          onClick={() => {
            updateActiveStep(previousStep, 'back');
          }}
        >
          <span className="sr-only">Go Back</span>
        </button>
      </p>
    </div>
  );
};

PlanEffectiveDate.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  googleData: PropTypes.object,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanEffectiveDate);
