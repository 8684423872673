import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NumericFormat, PatternFormat } from 'react-number-format';
import FormDataRedux, { selectRole } from '../Redux/FormDataRedux';
import { Question } from './FormWithSteps';

const EmployerFormSection = ({
  role,
  className,
  planDesign,
  putQuestionById,
}) => {
  const {
    roleSelection,
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    companyPhone,
    companyName,
    employerStateCode,
    zipCode,
  } = planDesign.questions;
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    putQuestionById(
      planDesign.formId,
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };
  const handleQuestionChangeEmail = (id, updateValue, valueFormatted) => {
    putQuestionById(
      planDesign.formId,
      id,
      updateValue,
      !/\S+@\S+\.\S+/.test(updateValue),
      true,
      valueFormatted,
    );
  };
  const handleQuestionChangeState = (id, updateValue, valueFormatted) => {
    putQuestionById(
      planDesign.formId,
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
    putQuestionById(
      planDesign.formId,
      planDesign.questions.insuranceGroupType.name,
      planDesign.questions.insuranceGroupType.value,
      false,
      true,
      planDesign.questions.insuranceGroupType.valueFormatted,
    );
  };
  const handleQuestionChangePhone = (id, updateValue, valueFormatted) => {
    putQuestionById(
      planDesign.formId,
      id,
      updateValue,
      !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(valueFormatted),
      true,
      valueFormatted,
    );
  };
  const handleQuestionChangeZipCode = (id, updateValue, valueFormatted) => {
    putQuestionById(
      planDesign.formId,
      id,
      updateValue,
      !/^\d{5}(?:[-\s]\d{4})?$/.test(updateValue),
      true,
      valueFormatted,
    );
  };
  return (
    <section className={className}>
      <input
        type="hidden"
        value={roleSelection.value || 'anonymous'}
        name="lead_type"
        id="lead_type"
      />
      <div className="row">
        <div className="col-md-6">
          <Question
            role={role}
            uniqueId="first_name"
            type={sponsorFirstName.type}
            labelClass="fs-form-label fw-bold text-white"
            inputClass="srp-form-input form-control"
            question={sponsorFirstName}
            handleQuestionChange={handleQuestionChange}
          >
            {sponsorFirstName.hasError && (
              <small className="text-warning">Question is required</small>
            )}
          </Question>{' '}
        </div>
        <div className="col-md-6">
          <Question
            role={role}
            uniqueId="last_name"
            type={sponsorLastName.type}
            labelClass="fs-form-label fw-bold text-white"
            inputClass="srp-form-input form-control"
            question={sponsorLastName}
            handleQuestionChange={handleQuestionChange}
          >
            {sponsorLastName.hasError && (
              <small className="text-warning">Question is required</small>
            )}
          </Question>{' '}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Question
            role={role}
            uniqueId="email"
            type={sponsorEmail.type}
            labelClass="fs-form-label fw-bold text-white"
            inputClass="srp-form-input form-control"
            question={sponsorEmail}
            handleQuestionChange={handleQuestionChangeEmail}
          >
            {sponsorEmail.hasError && (
              <small className="text-warning">Question is required</small>
            )}
          </Question>{' '}
        </div>
        <div className="col-md-3">
          <label
            className="fs-form-label fw-bold text-white"
            htmlFor={employerStateCode.name}
          >
            Your company’s state of business
          </label>
          <select
            id={employerStateCode.name}
            name="state"
            autoComplete="address-level1"
            value={employerStateCode.value}
            className="custom-select srp-form-input form-control form-select"
            onChange={(selected) => {
              handleQuestionChangeState(
                employerStateCode.name,
                selected.target.value,
                selected.target.value,
              );
            }}
          >
            <option>Select</option>
            {employerStateCode.options.map((option) => (
              <option key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-3">
          <label
            className="fs-form-label fw-bold text-white"
            htmlFor="postalcode"
          >
            Your postal code
          </label>
          <NumericFormat
            value={zipCode.value}
            className="srp-form-input form-control"
            id="postalcode"
            name="postalcode"
            required
            onValueChange={(values) => {
              handleQuestionChangeZipCode(
                zipCode.name,
                values.value,
                values.formattedValue,
              );
            }}
          />
          {zipCode.hasError && <small>Question is required</small>}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Question
            role={role}
            uniqueId="company"
            type={companyName.type}
            labelClass="fs-form-label fw-bold text-white"
            inputClass="srp-form-input form-control"
            question={companyName}
            handleQuestionChange={handleQuestionChange}
          >
            {companyName.hasError && (
              <small className="text-warning">Question is required</small>
            )}
          </Question>{' '}
        </div>
        <div className="col-md-6">
          <label className="fs-form-label fw-bold text-white" htmlFor="phone">
            Phone number
          </label>
          <PatternFormat
            value={companyPhone.value}
            format="###-###-####"
            mask="_"
            className="srp-form-input form-control"
            id="phone"
            name="phone"
            required
            onValueChange={(values) => {
              handleQuestionChangePhone(
                companyPhone.name,
                values.value,
                values.formattedValue,
              );
            }}
          />
        </div>
      </div>
    </section>
  );
};

EmployerFormSection.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string,
  planDesign: PropTypes.object.isRequired,
  putQuestionById: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  role: selectRole(store),
  planDesign: store.formData.planDesign,
});

const mapDispatchToProps = (dispatch) => ({
  putQuestionById: (
    formId,
    questionId,
    value,
    hasError,
    dirty,
    valueFormatted,
  ) => {
    dispatch(
      FormDataRedux.putQuestionById(
        formId,
        questionId,
        value,
        hasError,
        dirty,
        valueFormatted,
      ),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployerFormSection);
