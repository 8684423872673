import { autoDeferralType } from './DefaultQuestions/autoDeferralType';
import { autoEnrollment } from './DefaultQuestions/autoEnrollment';
import { employerContributionMatch } from './DefaultQuestions/employerContributionMatch';
import { employerMatch } from './DefaultQuestions/employerMatch';
import { employerPaidParticipantFees } from './DefaultQuestions/employerPaidParticipantFees';
import { hardshipWithdrawals } from './DefaultQuestions/hardshipWithdrawals';
import { minimumAutoDeferralPercentage } from './DefaultQuestions/minimumAutoDeferralPercentage';
import { safeHarbor } from './DefaultQuestions/safeHarbor';
import { stretchSafeHarbor } from './DefaultQuestions/stretchSafeHarbor';
import { vestingSchedule } from './DefaultQuestions/vestingSchedule';
import { numberOfEmployees } from './DefaultQuestions/numberOfEmployees';
import { averageSalary } from './DefaultQuestions/averageSalary';
import { serviceEligibility } from './DefaultQuestions/serviceEligibility';
import { planEffectiveDate } from './DefaultQuestions/planEffectiveDate';

export const DEFAULT_INITIAL_QUESTIONS = {
  numberOfEmployees: {
    ...numberOfEmployees,
  },
  averageSalary: {
    ...averageSalary,
  },
  serviceEligibility: {
    ...serviceEligibility,
  },
};

export const DEFAULT_PLAN_AUTO_ENROLLMENT_SAFE_HARBOR = {
  ...DEFAULT_INITIAL_QUESTIONS,
  autoEnrollment: { ...autoEnrollment },
  minimumAutoDeferralPercentage: { ...minimumAutoDeferralPercentage },
  autoDeferralType: { ...autoDeferralType },
  employerPaidParticipantFees: { ...employerPaidParticipantFees },
  employerContributionMatch: { ...employerContributionMatch },
  safeHarbor: { ...safeHarbor },
  stretchSafeHarbor: { ...stretchSafeHarbor },
  hardshipWithdrawals: { ...hardshipWithdrawals },
  planEffectiveDate: { ...planEffectiveDate },
};

export const DEFAULT_PLAN_NO_AUTO_ENROLLMENT_SAFE_HARBOR = {
  ...DEFAULT_INITIAL_QUESTIONS,
  autoEnrollment: { ...autoEnrollment },
  employerPaidParticipantFees: { ...employerPaidParticipantFees },
  employerContributionMatch: { ...employerContributionMatch },
  safeHarbor: { ...safeHarbor },
  stretchSafeHarbor: { ...stretchSafeHarbor },
  hardshipWithdrawals: { ...hardshipWithdrawals },
  planEffectiveDate: { ...planEffectiveDate },
};

export const DEFAULT_PLAN_AUTO_ENROLLMENT_NON_SAFE_HARBOR_EMPLOYER_CONTRIBUTION =
  {
    ...DEFAULT_INITIAL_QUESTIONS,
    autoEnrollment: { ...autoEnrollment },
    minimumAutoDeferralPercentage: { ...minimumAutoDeferralPercentage },
    autoDeferralType: { ...autoDeferralType },
    employerPaidParticipantFees: { ...employerPaidParticipantFees },
    employerContributionMatch: { ...employerContributionMatch },
    safeHarbor: { ...safeHarbor },
    employerMatch: { ...employerMatch },
    vestingSchedule: { ...vestingSchedule },
    hardshipWithdrawals: { ...hardshipWithdrawals },
    planEffectiveDate: { ...planEffectiveDate },
  };

export const DEFAULT_PLAN_NO_AUTO_ENROLLMENT_NON_SAFE_HARBOR_EMPLOYER_CONTRIBUTION =
  {
    ...DEFAULT_INITIAL_QUESTIONS,
    autoEnrollment: { ...autoEnrollment },
    employerPaidParticipantFees: { ...employerPaidParticipantFees },
    employerContributionMatch: { ...employerContributionMatch },
    safeHarbor: { ...safeHarbor },
    employerMatch: { ...employerMatch },
    vestingSchedule: { ...vestingSchedule },
    hardshipWithdrawals: { ...hardshipWithdrawals },
    planEffectiveDate: { ...planEffectiveDate },
  };

export const DEFAULT_PLAN_NO_AUTO_ENROLLMENT_NO_MATCH_CONTRIBUTION = {
  ...DEFAULT_INITIAL_QUESTIONS,
  autoEnrollment: { ...autoEnrollment },
  employerPaidParticipantFees: { ...employerPaidParticipantFees },
  employerContributionMatch: { ...employerContributionMatch },
  hardshipWithdrawals: { ...hardshipWithdrawals },
  planEffectiveDate: { ...planEffectiveDate },
};

export const DEFAULT_PLAN_AUTO_ENROLLMENT_NO_MATCH_CONTRIBUTION = {
  ...DEFAULT_INITIAL_QUESTIONS,
  autoEnrollment: { ...autoEnrollment },
  minimumAutoDeferralPercentage: { ...minimumAutoDeferralPercentage },
  autoDeferralType: { ...autoDeferralType },
  employerPaidParticipantFees: { ...employerPaidParticipantFees },
  employerContributionMatch: { ...employerContributionMatch },
  hardshipWithdrawals: { ...hardshipWithdrawals },
  planEffectiveDate: { ...planEffectiveDate },
};

export const DEFAULT_PLAN_ENROLLMENT = {};

export const DEFAULT_PLAN_NO_ENROLLMENT = {
  minimumAutoDeferralPercentage: { ...minimumAutoDeferralPercentage },
  autoDeferralType: { ...autoDeferralType },
};

export const DEFAULT_PLAN_MATCHING_CONT = {
  safeHarbor: { ...safeHarbor },
  stretchSafeHarbor: { ...stretchSafeHarbor },
  employerMatch: { ...employerMatch, dirty: false },
  vestingSchedule: { ...vestingSchedule },
  hardshipWithdrawals: { ...hardshipWithdrawals },
};

export const DEFAULT_PLAN_SAFE_HARBOR = {
  employerMatch: { ...employerMatch, dirty: false },
  vestingSchedule: { ...vestingSchedule },
};

export const DEFAULT_PLAN_NON_SAFE_HARBOR = {
  stretchSafeHarbor: { ...stretchSafeHarbor },
};

export const DEFAULT_INITIAL_PLAN = {
  ...DEFAULT_INITIAL_QUESTIONS,
  autoEnrollment: { ...autoEnrollment },
  minimumAutoDeferralPercentage: { ...minimumAutoDeferralPercentage },
  autoDeferralType: { ...autoDeferralType },
  employerPaidParticipantFees: { ...employerPaidParticipantFees },
  employerContributionMatch: { ...employerContributionMatch },
  safeHarbor: { ...safeHarbor },
  employerMatch: { ...employerMatch },
  vestingSchedule: { ...vestingSchedule },
  hardshipWithdrawals: { ...hardshipWithdrawals },
  planEffectiveDate: { ...planEffectiveDate },
};
