import React from 'react';
import InvestmentLineupAccordion from './InvestmentLineupAccordion';

const InvestmentLineUp = () => (
  <section>
    <h5 className="fs-h2 fw-bold text-purple mt-4">US Equity</h5>
    <h6 className="fs-p fw-bold">US Equity Large-Cap</h6>
    <p className="fs-p">
      This asset class is generally composed of investment options that invest
      in stocks or shares of ownership in large, well-established, U.S.
      companies. These investment options typically carry more risk than fixed
      income investment options but have the potential for higher returns over
      longer time periods. They may be an appropriate choice for long-term
      investors who are seeking the potential for growth. All investment options
      in this category have the potential to lose value.
    </p>

    <InvestmentLineupAccordion
      title="US Equity Large-Cap investment options"
      lineup={[
        {
          title: 'Putnam Large Cap Value',
          link: 'https://www.morningstar.com/funds/xnas/PEQSX/quote',
        },
        {
          title: 'Fidelity 500 Index',
          link: 'https://www.morningstar.com/funds/xnas/FXAIX/quote',
        },
        {
          title: 'JPMorgan Large Cap Growth R6',
          link: 'https://www.morningstar.com/funds/xnas/JLGMX/quote',
        },
      ]}
    />

    <h6 className="fs-p fw-bold">US Equity Small/Mid-Cap</h6>
    <p className="fs-p">
      This asset class is generally composed of investment options that invest
      in stocks or shares of ownership in small to medium-sized U.S. companies.
      These investment options typically carry more risk than larger U.S. equity
      investment options but have the potential for higher returns. They may be
      an appropriate choice for long-term investors who are seeking the
      potential for growth. All investment options in this category have the
      potential to lose value.
    </p>

    <InvestmentLineupAccordion
      title="US Equity Small/Mid-Cap investment options"
      lineup={[
        {
          title: 'Victory Sycamore Established Value R6',
          link: 'https://www.morningstar.com/funds/xnas/VEVRX/quote',
        },
        {
          title: 'Fidelity Mid Cap Index',
          link: 'https://www.morningstar.com/funds/xnas/FSMDX/quote',
        },
        {
          title: 'Janus Henderson Enterprise N',
          link: 'https://www.morningstar.com/funds/xnas/JDMNX/quote',
        },
        {
          title: 'DFA US Targeted Value I',
          link: 'https://www.morningstar.com/funds/xnas/DFFVX/quote',
        },
        {
          title: 'Fidelity Small Cap Index',
          link: 'https://www.morningstar.com/funds/xnas/FSSNX/quote',
        },
        {
          title: 'Invesco Discovery R6',
          link: 'https://www.morningstar.com/funds/xnas/ODIIX/quote',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">International Equity</h5>
    <p className="fs-p">
      This asset class is composed of investment options that invest in stocks
      or shares of ownership in companies with their principal place of business
      or office outside the United States. These investment options often carry
      more risk than U.S. equity investment options but may have the potential
      for higher returns. They may be an appropriate choice for long-term
      investors who are seeking the potential for growth. All investment options
      in this category have the potential to lose value.
    </p>
    <InvestmentLineupAccordion
      title="International Equity investment options"
      lineup={[
        {
          title: 'Hartford Schroders International Stk SDR',
          link: 'https://www.morningstar.com/funds/xnas/SCIJX/quote',
        },
        {
          title: 'Fidelity International Index',
          link: 'https://www.morningstar.com/funds/xnas/FSPSX/quote',
        },
        {
          title: 'American Funds New World R6',
          link: 'https://www.morningstar.com/funds/xnas/RNWGX/quote',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">Fixed Income</h5>
    <p className="fs-p">
      This asset class is generally composed of investment options that invest
      in bonds or debt of a company or government entity (including U.S. and
      Non-U.S.). It may also include real estate investment options that
      directly own property. These investment options typically carry more risk
      than short-term fixed income investment options (including, for real
      estate investment options, liquidity risk), but less overall risk than
      equities. All investment options in this category have the potential to
      lose value.
    </p>
    <InvestmentLineupAccordion
      title="Fixed Income investment options"
      lineup={[
        {
          title: 'State Street Aggregate Bond Index K',
          link: 'https://www.morningstar.com/funds/xnas/SSFEX/quote',
        },
        {
          title: 'American Funds Bond Fund of Amer R6',
          link: 'https://www.morningstar.com/funds/xnas/RBFGX/quote',
        },
        {
          title: 'PIMCO Income Instl',
          link: 'https://www.morningstar.com/funds/xnas/PIMIX/quote',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">Target Date</h5>
    <p className="fs-p">
      Target date portfolios provide a diversified exposure to stocks, bonds,
      and cash for those investors who have a specific date in mind for
      retirement or another goal. These portfolios aim to provide investors with
      an optimal level of return and risk, based solely on the target date. Over
      time, management adjusts the allocation among asset classes to more
      conservative mixes as the target date approaches. All investment options
      in this category have the potential to lose value.
    </p>
    <InvestmentLineupAccordion
      title="Target Date investment options"
      lineup={[
        {
          title: 'American Funds 2010 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFTTX/quote',
        },
        {
          title: 'American Funds 2015 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFJTX/quote',
        },
        {
          title: 'American Funds 2020 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RRCTX/quote',
        },
        {
          title: 'American Funds 2025 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFDTX/quote',
        },
        {
          title: 'American Funds 2030 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFETX/quote',
        },
        {
          title: 'American Funds 2035 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFFTX/quote',
        },
        {
          title: 'American Funds 2040 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFGTX/quote',
        },
        {
          title: 'American Funds 2045 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFHTX/quote',
        },
        {
          title: 'American Funds 2050 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFITX/quote',
        },
        {
          title: 'American Funds 2055 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFKTX/quote',
        },
        {
          title: 'American Funds 2060 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFUTX/quote',
        },
        {
          title: 'American Funds 2065 Trgt Date Retire R6',
          link: 'https://www.morningstar.com/funds/xnas/RFVTX/quote',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">Capital Preservation</h5>
    <p className="fs-p">
      Stable-value portfolios seek to provide income while preventing price
      fluctuations. The most common stable-value portfolios invest in a
      diversified portfolio of bonds and enter into wrapper agreements with
      financial companies to guarantee against fluctuations in their share
      prices. These wrapper agreements typically provide price stability on a
      day-to-day basis, thereby insulating each portfolio&apos;s net asset value
      from interest-rate volatility.
    </p>
    <InvestmentLineupAccordion
      title="Capital Preservation investment options"
      lineup={[
        {
          title: 'Morley Stable Value Fund (Class 25-1)',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9082&rtclss=20&_ga=2.193573549.16770829.1698072394-1862312818.1635861795',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">Real Estate</h5>
    <p className="fs-p">
      Real estate portfolios invest primarily in real estate investment trusts
      of various types. REITs are companies that develop and manage real estate
      properties. There are several different types of REITs, including
      apartment, factory-outlet, health care, hotel, industrial, mortgage,
      office, and shopping center REITs. Some portfolios in this category also
      invest in real estate operating companies. All investment options in this
      category have the potential to lose value.
    </p>
    <InvestmentLineupAccordion
      title="Real Estate investment options"
      lineup={[
        {
          title: 'Principal Real Estate Securities Fund R6',
          link: 'https://www.morningstar.com/funds/xnas/PFRSX/quote',
        },
      ]}
    />
  </section>
);

export default InvestmentLineUp;
