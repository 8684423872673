export const getSourceName = async (
  whichSafeHarborQaca,
  whichSafeHarbor,
  autoEnrollmentPlan,
) => {
  if (
    autoEnrollmentPlan !== undefined &&
    autoEnrollmentPlan.valueFormatted === 'QACA'
  ) {
    if (whichSafeHarborQaca.value.value === '3PercNonelectiveSafeharbor') {
      return 'Employer QACA 3% Non-Elective';
    }
    if (whichSafeHarborQaca.value.value === 'basicMatch100to1-50toNext6') {
      return 'Employer QACA 100% up to 1% - 50% up to 6%';
    }
    if (whichSafeHarborQaca.value.value === 'enhancedMatch100to3.5') {
      return 'Employer QACA 3.5% Enhanced Match';
    }
    if (whichSafeHarborQaca.value.value === 'enhancedMatch100to4') {
      return 'Employer QACA 4% Match';
    }
    if (whichSafeHarborQaca.value.value === 'enhancedMatch100to5') {
      return 'Employer QACA 5% Match';
    }
    return 'Employer QACA 6% Match';
  }
  if (whichSafeHarbor === undefined) {
    return '';
  }
  if (whichSafeHarbor.value.value === '3PercNonelectiveSafeharbor') {
    return '3% Non-elective Safe harbor';
  }
  if (whichSafeHarbor.value.value === 'basicMatch100to3-50toNext2') {
    return 'Employer Safe Basic Match';
  }
  if (whichSafeHarbor.value.value === 'enhancedMatch100to4') {
    return 'Enhanced Match: 100% up to 4%';
  }
  if (whichSafeHarbor.value.value === 'enhancedMatch100to5') {
    return 'Enhanced Match: 100% up to 5%';
  }
  return 'Enhanced Match: 100% up to 6%';
};
