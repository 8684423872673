import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectPlanOptions } from '../../../../Redux/FormDataRedux';

export const ReturningCheckList = ({ planOriginator }) => (
  <div className="row">
    <div className="col-md-10 col-lg-8 mx-auto my-md-auto">
      <h3 className="fs-form-q text-center pb-4 px-md-3">
        Before you complete the plan details, you’ll be asked to confirm a few
        things:
      </h3>
      <div className="row">
        <div className="col-md-10 col-lg-8 mx-auto">
          <ul className="fa-ul">
            <li className="fs-p mb-1">
              <span className="fa-li">
                <i className="fa-solid fa-check text-primary" aria-hidden />
              </span>
              Contact information for yourself and your client.
            </li>
            <li className="fs-p mb-1 mb-md-2">
              <span className="fa-li">
                <i className="fa-solid fa-check text-primary" aria-hidden />
              </span>
              Your firm name.
            </li>
            <li className="fs-p mb-1 mb-md-2">
              <span className="fa-li">
                <i className="fa-solid fa-check text-primary" aria-hidden />
              </span>
              The Employer Identification Number (EIN) for your firm and your
              client.
            </li>
            <li className="fs-p mb-1 mb-md-2">
              <span className="fa-li">
                <i className="fa-solid fa-check text-primary" aria-hidden />
              </span>
              Your third party administrator (TPA) fees.
            </li>
          </ul>

          <p className="fs-p mt-3 lh-125">
            {planOriginator.role === 'tpa'
              ? 'After you complete the plan details, click “Share the proposal” to share your changes with the client or financial professional.'
              : 'After you complete the plan details, click “Share the proposal” to share your changes with the client or financial professional.'}
          </p>
          <br></br>
        </div>
      </div>
    </div>
  </div>
);

ReturningCheckList.propTypes = {
  planOriginator: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  planOriginator: selectPlanOptions(store),
});

export default connect(mapStateToProps)(ReturningCheckList);
