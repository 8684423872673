import React from 'react';
import PropTypes from 'prop-types';
import { isEligible } from '../Utilities';
import { selectedSafeHarbor } from '../../../Services/helpers/Paradigm/planHelper';

const SelectionBtn = ({ name, values, updateActiveStep, active, disabled }) => (
  <button
    id={name}
    disabled={disabled}
    className={`review-selection${active ? ' active' : ''}`}
    type="button"
    onClick={() => updateActiveStep(name)}
  >
    {values.map(
      ({
        dirty,
        required,
        name: qName,
        valueFormatted,
        value,
        sideCardTitle,
      }) => {
        if (dirty && required) {
          return (
            <span key={qName}>
              <span className="review-selection__label">{sideCardTitle}</span>
              <span className="review-selection__value">
                {valueFormatted || value}
              </span>
            </span>
          );
        }
        return false;
      },
    )}
  </button>
);

SelectionBtn.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const SingleSelection = ({ singleSelections, disabled }) => {
  const renderSingle = singleSelections.map(
    ({
      props: {
        questions,
        questionId,
        subQuestionIds = [],
        active,
        name,
        updateActiveStep,
      },
    }) => {
      const question = questions[questionId];
      const subQuestions = () => {
        const qArry = [question];
        subQuestionIds.forEach((q) => qArry.push(questions[q]));
        return qArry;
      };

      if (question.dirty) {
        return (
          <SelectionBtn
            key={name}
            active={active}
            name={name}
            values={subQuestions()}
            subQuestions={subQuestions}
            title={question.sideCardTitle}
            updateActiveStep={updateActiveStep}
            disabled={disabled(questionId)}
          />
        );
      }
      if (active && !question.dirty) {
        return <div key={name} className="review-selection empty"></div>;
      }
      return false;
    },
  );
  return <div>{renderSingle}</div>;
};

SingleSelection.propTypes = {
  singleSelections: PropTypes.array.isRequired,
  disabled: PropTypes.func.isRequired,
};

const MergedSelections = ({ mergedSelections, disabled }) => {
  const mergedActive = mergedSelections.some((ms) => ms.props.active);
  const firstSelection = mergedSelections[0].props;
  if (firstSelection.questions[firstSelection.questionId].value !== '') {
    return (
      <button
        disabled={disabled(firstSelection.questionId)}
        className={`review-selection${mergedActive ? ' active' : ''}`}
        type="button"
        onClick={() => firstSelection.updateActiveStep(firstSelection.name)}
      >
        <span className="review-selection__label">Contribution details</span>
        {mergedSelections.map(({ props }) => {
          const question = props.questions[props.questionId];
          if (question.dirty) {
            return (
              <span key={question.name} className="review-selection__value">
                {question.valueFormatted || question.value}
              </span>
            );
          }
          return false;
        })}
      </button>
    );
  }
  return false;
};

MergedSelections.propTypes = {
  mergedSelections: PropTypes.array.isRequired,
  disabled: PropTypes.func.isRequired,
  questionId: PropTypes.string,
  questions: PropTypes.array,
};

const ReviewSelection = ({ selections }) => {
  const activeSelection = selections.find(
    (selection) => selection.props.active,
  );
  const singleSelections = selections.filter((s) => s.props.singleSelection);
  const mergedSelections = selections.filter((s) => s.props.mergedSelections);
  const conditionalSelections = selections.filter(
    (s) => s.props.conditionalSelection,
  );
  const { numberOfEmployees } = activeSelection.props.questions;

  const hasErrors = Object.keys(activeSelection.props.questions)
    .filter((question) => activeSelection.props.questions[question].required)
    .find((question) => activeSelection.props.questions[question].hasError);

  const disabled = (questionId) => {
    if (hasErrors) {
      return questionId !== hasErrors;
    }
    if (questionId !== 'numberOfEmployees') {
      return !isEligible(numberOfEmployees.value);
    }
    return false;
  };
  return (
    <>
      <SingleSelection
        singleSelections={singleSelections}
        disabled={disabled}
      />
      {mergedSelections.length !== 0 && (
        <MergedSelections
          mergedSelections={mergedSelections}
          disabled={disabled}
        />
      )}
      {!selectedSafeHarbor(selections[0].props.questions) && (
        <SingleSelection
          singleSelections={conditionalSelections}
          disabled={disabled}
        />
      )}
    </>
  );
};

ReviewSelection.propTypes = {
  selections: PropTypes.array.isRequired,
};

export default ReviewSelection;
