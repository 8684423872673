import { getUrlParameter } from '../../../../Services/utilities';
import API from '../../../../Services/api';

const api = API.create();

export const updateShareUrl = (shareURL, data) => {
  const params = Object.keys(data).map(
    (q) => `${data[q].name}=${data[q].value}`,
  );
  if (!Object.keys(data).find((q) => data[q].dirty === false)) {
    return `${shareURL}?calculate=true&${params.join('&')}#quickquote`;
  }
  return `${shareURL}?calculate=true&${params.join('&')}#quickquote`;
};

export const loadFromParams = (setData, data) => {
  setData((prevData) => {
    const updateState = Object.keys(data).reduce((acc, key) => {
      const { name } = data[key];
      const value = getUrlParameter(name);
      return { ...acc, [name]: { ...prevData[name], value } };
    }, {});
    return {
      ...prevData,
      ...updateState,
    };
  });
};

export const recordkeepingFee = (employees, addedCost) => {
  const cap = employees >= 100 ? 0 : employees;
  const value = cap ? addedCost + 6 * cap : 0;
  const dollarValue = value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return value ? dollarValue : '0.00';
};

export const totalContributions = (employees, salary) => {
  const cap = employees >= 100 ? 0 : employees;
  const value = (cap * salary * 0.04) / 12;
  const dollarValue = value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return value ? dollarValue : '0.00';
};

export const contributionsPerEmployee = (employees, salary) => {
  const cap = employees >= 100 ? 0 : employees;
  const value = (cap * (salary * 0.04)) / (12 * cap);
  const dollarValue = value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return value ? dollarValue : '0.00';
};

export const SubmitQuickQuoteSalesforceProposal = (
  data,
  referrerType,
  authenticityToken,
) => {
  const hasFp = referrerType === 'advisor' ? true : data.hasFp.value;
  const hasTpa = referrerType === 'tpa' ? true : data.hasTpa.value;
  const referrerEmail =
    referrerType === 'advisor' ? data.advisorEmail.value : data.tpaEmail.value;

  api.createSalesforceQuickQuoteProposal({
    ...{
      X3_38__c: 'Wilshire',
      Brokerage_Accounts_PCRA__c: 'No',
      Client_Company_Name__c: data.companyName.value,
      Custodian__c: 'MG Trust Company, LLC.',
      Number_of_Employees__c: data.numberOfEmployees.value,
      Referrer_Email__c: referrerEmail,
      Referrer_Type__c: referrerType,
      Stage_Flag__c: 'Quick Quote',
      Advisor_Included__c: hasFp,
      Financial_Professional_Email__c: data.advisorEmail.value,
      Financial_Professional_First_Name__c: data.advisorFirstName.value,
      Financial_Professional_Last_Name__c: data.advisorLastName.value,
      Financial_Professional_Phone__c: data.advisorPhone.value,
      Firm_Name__c: data.firmName.value,
      Financial_Professional_State__c: data.advisorStateCode.value.value,
      TPA_Firm_Name__c: hasTpa ? data.tpaFirmName.value.value : null,
      TPA_Included__c: hasTpa,
      Principal_ID__c: null,
      TPA_Sales_Email__c: hasTpa ? data.tpaEmail.value : null,
      TPA_Sales_First_Name__c: hasTpa ? data.tpaFirstName.value : null,
      TPA_Sales_Last_Name__c: hasTpa ? data.tpaLastName.value : null,
      Contact_Phone_Number__c:
        referrerType === 'advisor'
          ? data.advisorPhone.value
          : data.tpaPhone.value,
    },
    authenticity_token: authenticityToken,
  });
  // .then((res) => {
  //   // Fire off email
  //
  // });
};
