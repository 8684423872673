import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './Styles';

const Tpa = ({ data }) => (
  <View>
    <Text style={[styles.copyDefault, { fontWeight: 'bold' }]}>
      Third Party Administrator (TPA):
    </Text>
    <Text style={styles.copyDefault}>
      {data.tpaFirstName.value &&
        `Full name: ${data.tpaFirstName.value} ${data.tpaLastName.value}`}
    </Text>
    <Text style={styles.copyDefault}>
      {!!data.hasTpa.value &&
        `TPA firm name: ${data.tpaFirmName.value.value || 'TBD'}`}
    </Text>
    <Text style={styles.copyDefault}>{`Email: ${data.tpaEmail.value}`}</Text>
    <Text style={styles.copyDefault}>{`Phone: ${data.tpaPhone.value}`}</Text>
  </View>
);

Tpa.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Tpa;
