import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { checkForErrors } from '../../common/FormWithSteps/Utilities';
import FormDataRedux, { selectPrimaryAdvisor } from '../../Redux/FormDataRedux';
import {
  CloseForm,
  checkValidityOnQuestionArray,
} from '../../common/FormWithSteps';
import EmployerFormSection from '../../common/EmployerFormSection';

export const RequiresLicense = ({
  history,
  location,
  formData,
  sponsorFirstName,
  sponsorLastName,
  sponsorEmail,
  companyName,
  companyPhone,
  employerStateCode,
  primaryAdvisor,
  updateAdvisor,
}) => {
  const lastLocation = useLastLocation();
  const handleClose = () => {
    if (lastLocation) {
      history.goBack();
    } else {
      history.push('/');
    }
  };
  const [hasAdvisor, toggleHasAdvisor] = useState(false);
  const inputsToValidate = [
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    companyName,
    companyPhone,
    employerStateCode,
    formData.planDesign.questions.zipCode,
  ];
  if (hasAdvisor) {
    inputsToValidate.push(primaryAdvisor.questions.advisorFirstName);
    inputsToValidate.push(primaryAdvisor.questions.advisorLastName);
    inputsToValidate.push(primaryAdvisor.questions.advisorEmail);
  }
  const searchParams = new URLSearchParams(location.search);
  const isDisabled =
    searchParams.get('response') === 'success'
      ? true
      : !checkValidityOnQuestionArray(inputsToValidate);
  const renderResponse = () => {
    switch (searchParams.get('response')) {
      case 'success':
        return (
          <div className="text-success fs-h3 text-center">
            Thanks. We’ll be in touch.
          </div>
        );
      case 'error':
        return (
          <div className="text-warning fs-h3 text-center">
            There was an error submitting the form.
          </div>
        );
      default:
        return false;
    }
  };
  const [status, setStatus] = useState('');
  const refForm = useRef('');
  const submitForm = async (e) => {
    // Previously POST to Pardot -- has been removed
    // Form is broken and needs to be fixed
    e.preventDefault();
    setStatus('error');
  };
  return (
    <div className="fws fws_dark">
      <div className="fws__wrapper h-md-100">
        <CloseForm handleClose={handleClose} light />
        <div className="container h-100">
          <div className="row">
            <div className="col-lg-11 mx-auto">
              <h1 className="fs-h1 fw-bold text-white text-center my-5">
                Your state requires a financial professional.
              </h1>
              <p className="fs-p text-white text-center mb-5">
                Your state requires business owners to work with a financial
                professional to set up a 401(k) plan. Just send us your contact
                information and a Principal team member will contact you within
                two business days to help coordinate with your financial
                professional or find one near you.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 mx-auto">
              {status === 'error' ? (
                <div className="text-warning fs-h3 text-center">
                  There was an error submitting the form.
                </div>
              ) : (
                ''
              )}

              {searchParams.has('response') && renderResponse()}
              <form
                ref={refForm}
                id="FloridaPlanForm"
                action="https://info.simplyretirement.com/l/836343/2020-02-28/2zmt"
                method="post"
              >
                <EmployerFormSection />
                <div className="form-group form-check">
                  <input
                    checked={hasAdvisor}
                    type="checkbox"
                    className="form-check-input"
                    id="hasAdvisor"
                    name="hasAdvisor"
                    onChange={() => {
                      toggleHasAdvisor(!hasAdvisor);
                    }}
                  />
                  <label
                    className="form-check-label fs-form-label fw-bold text-white"
                    htmlFor="hasAdvisor"
                  >
                    I am currently working with a financial professional
                  </label>
                </div>

                {hasAdvisor && (
                  <fieldset>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor="financial_professional_first_name"
                        >
                          Financial professional’s first name*
                        </label>
                        <input
                          type="text"
                          name="financial_professional_first_name"
                          className="srp-form-input form-control"
                          id="financial_professional_first_name"
                          onChange={(event) => {
                            updateAdvisor(
                              {
                                name: primaryAdvisor.questions.advisorFirstName
                                  .name,
                                value: event.target.value,
                                valueFormatted: event.target.value,
                                hasError: checkForErrors(
                                  primaryAdvisor.questions.advisorFirstName
                                    .name,
                                  event.target.value,
                                ),
                                dirty: true,
                              },
                              0,
                            );
                          }}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor="financial_professional_last_name"
                        >
                          Financial professional’s last name*
                        </label>
                        <input
                          type="text"
                          className="srp-form-input form-control"
                          id="financial_professional_last_name"
                          name="financial_professional_last_name"
                          onChange={(event) => {
                            updateAdvisor(
                              {
                                name: primaryAdvisor.questions.advisorLastName
                                  .name,
                                value: event.target.value,
                                valueFormatted: event.target.value,
                                hasError: checkForErrors(
                                  primaryAdvisor.questions.advisorLastName.name,
                                  event.target.value,
                                ),
                                dirty: true,
                              },
                              0,
                            );
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor="financial_professional_email"
                        >
                          Financial professional’s email address*
                        </label>
                        <input
                          type="text"
                          className="srp-form-input form-control"
                          id="financial_professional_email"
                          name="financial_professional_email"
                          onChange={(event) => {
                            updateAdvisor(
                              {
                                name: primaryAdvisor.questions.advisorEmail
                                  .name,
                                value: event.target.value,
                                valueFormatted: event.target.value,
                                hasError: checkForErrors(
                                  primaryAdvisor.questions.advisorEmail.name,
                                  event.target.value,
                                ),
                                dirty: true,
                              },
                              0,
                            );
                          }}
                          required
                        />
                      </div>
                    </div>
                  </fieldset>
                )}
                <p className="text-white text-center fs-sm mb-4">
                  By clicking “Contact us,” you agree to the Principal privacy
                  policy. Your email will only be used to send you updates about
                  Simply Retirement by Principal
                  <small>
                    <sup>®</sup>
                  </small>
                  , and you may update your preferences or unsubscribe anytime.
                </p>
                <p className="text-center">
                  <button
                    disabled={isDisabled || status === 'loading'}
                    className="srp-button srp-button__large srp-button__primary"
                    type="submit"
                    onClick={(e) => {
                      setStatus('loading');
                      submitForm(e);
                    }}
                  >
                    {status === '' ? 'Contact us' : 'Submitting...'}
                  </button>
                </p>
              </form>
            </div>
          </div>
          {lastLocation && (
            <button
              type="button"
              className="goback fa-solid fa-arrow-left text-white"
              onClick={() => {
                history.replace({
                  pathname: lastLocation.pathname,
                });
              }}
            >
              <span className="sr-only">Go Back</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

RequiresLicense.propTypes = {
  formData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  sponsorFirstName: PropTypes.object.isRequired,
  sponsorLastName: PropTypes.object.isRequired,
  sponsorEmail: PropTypes.object.isRequired,
  companyName: PropTypes.object.isRequired,
  companyPhone: PropTypes.object.isRequired,
  employerStateCode: PropTypes.object.isRequired,
  primaryAdvisor: PropTypes.object.isRequired,
  updateAdvisor: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  sponsorFirstName: store.formData.planDesign.questions.sponsorFirstName,
  sponsorLastName: store.formData.planDesign.questions.sponsorLastName,
  sponsorEmail: store.formData.planDesign.questions.sponsorEmail,
  companyName: store.formData.planDesign.questions.companyName,
  companyPhone: store.formData.planDesign.questions.companyPhone,
  employerStateCode: store.formData.planDesign.questions.employerStateCode,
  primaryAdvisor: selectPrimaryAdvisor(store),
});
const mapDispatchToProps = (dispatch) => ({
  putQuestionById: (
    formId,
    questionId,
    value,
    hasError,
    dirty,
    valueFormatted,
  ) => {
    dispatch(
      FormDataRedux.putQuestionById(
        formId,
        questionId,
        value,
        hasError,
        dirty,
        valueFormatted,
      ),
    );
  },
  updateAdvisor: (obj, index) => {
    dispatch(FormDataRedux.updateAdvisor(obj, index));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RequiresLicense);
