import { getUrlParameter } from '../../../../Services/utilities';

export const updateShareUrl = (shareURL, data) => {
  const params = Object.keys(data).map(
    (q) => `${data[q].name}=${data[q].value}`,
  );
  if (!Object.keys(data).find((q) => data[q].dirty === false)) {
    return `${shareURL}?calculate=true&${params.join('&')}#quickquote`;
  }
  return `${shareURL}?calculate=true&${params.join('&')}#quickquote`;
};

export const loadFromParams = (setData, data) => {
  setData((prevData) => {
    const updateState = Object.keys(data).reduce((acc, key) => {
      const { name } = data[key];
      const value = getUrlParameter(name);
      return { ...acc, [name]: { ...prevData[name], value } };
    }, {});
    return {
      ...prevData,
      ...updateState,
    };
  });
};

export const recordkeepingFee = (employees) => {
  const cap = employees >= 100 ? 0 : employees;
  const value = cap ? 135 + 6 * cap : 0;
  const dollarValue = value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return value ? dollarValue : '0.00';
};

export const totalContributions = (employees, salary) => {
  const cap = employees >= 100 ? 0 : employees;
  const value = (cap * salary * 0.04) / 12;
  const dollarValue = value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return value ? dollarValue : '0.00';
};

export const contributionsPerEmployee = (employees, salary) => {
  const cap = employees >= 100 ? 0 : employees;
  const value = (cap * (salary * 0.04)) / (12 * cap);
  const dollarValue = value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return value ? dollarValue : '0.00';
};
