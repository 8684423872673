import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { EquationStyled } from './index.styles';
import { asOfDate, investmentCost } from '../../../../Services/utilities';

const SectionCosts = ({ title, putApplicationPanelInfo, cardType }) => (
  <div className="container">
    <h3 className="fs-h3 text-center py-5">
      <div dangerouslySetInnerHTML={{ __html: title }} />
    </h3>
    <div className="row mb-5">
      <div className="col-xl-5 col-lg-7 col-md-8 mb-3 mx-auto">
        <div
          className={classNames(
            'principal-card text-center p-4 h-100',
            cardType,
          )}
        >
          <i
            className="far fa-calendar-check my-2 text-secondary fa-lg"
            aria-hidden
          />
          <p className="mb-4">Every month</p>
          <p
            className="mb-0 text-left fw-bold"
            style={{ marginLeft: '40px', color: '#002855' }}
          >
            As low as
          </p>
          <EquationStyled>
            <EquationStyled.Number>
              <sup>$</sup>145
            </EquationStyled.Number>
            <EquationStyled.Operator>+</EquationStyled.Operator>
            <EquationStyled.Parens>
              <EquationStyled.Number>
                <sup>$</sup>6
              </EquationStyled.Number>
              <EquationStyled.Label>
                for every
                <br /> participant
              </EquationStyled.Label>
            </EquationStyled.Parens>
          </EquationStyled>

          <button
            type="button"
            className="btn fs-sm mb-4"
            onClick={() =>
              putApplicationPanelInfo({
                hasVisibility: true,
                heading: 'Recordkeeping fee',
                description: `Fees paid by the business owner are billed quarterly. Fees paid by participants are deducted monthly from participant accounts. Participant fees are charged if there is a $100 account balance, regardless of whether the participant is active or inactive. Custodial and investment fees are charged against participating employees’ accounts (those vary by investment and range from ${investmentCost}, as of ${asOfDate}). Your TPA fees and the financial professional fees are also additional and may be billed to the business owner. (Financial professional fees may be deducted from participant accounts.)`,
              })
            }
          >
            <u>Recordkeeping fee</u>
          </button>

          <div className="d-flex flex-md-row flex-column justify-content-between">
            <button
              type="button"
              className="btn fs-sm mb-4"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading:
                    '$500 initial setup fee for bundled plans + $145-$185 + $6 per participant every month.',
                  description: `The $145 per month recordkeeping fee applies when working with a TPA. Custodial and investment fees are charged against participating employees’ accounts (those vary by investment and range from ${investmentCost}, as of ${asOfDate}). Should the business owner choose to work with a financial professional and/or TPA, their fees are also additional.`,
                  additionalInformation: `<div class='py-5 text-center'><p class="text-white fs-h2 fw-bold">More questions?</p><p className="text-center my-5"><a class="srp-button srp-button__primary px-5 mb-5" href="business-owners/resources">Visit our resources section</a></p></div>`,
                })
              }
            >
              <u>What’s included?</u>
            </button>
            <Link
              to="/third-party-administrator/plan-proposal"
              className="btn fs-sm text-underline"
            >
              Help me calculate the monthly <br /> cost
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

SectionCosts.propTypes = {
  title: PropTypes.string.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  cardType: PropTypes.string,
};

export default SectionCosts;
