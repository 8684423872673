import React from 'react';
import PropTypes from 'prop-types';
import IconCoinsO from './IconCoinsO';
import IconLifesaverO from './IconLifesaverO';
import IconStopWatchO from './IconStopWatchO';
import IconRocketO from './IconRocketO';
import IconCommentQuestionO from './IconCommentQuestionO';
import IconCheckBurstO from './IconCheckBurstO';
import IconCoinO from './IconCoinO';
import IconTimeRewindO from './IconTimeRewindO';
import IconUserMarkerO from './IconUserMarkerO';
import IconBusinessOwnerO from './IconBusinessOwnerO';
import IconSingleUserO from './IconSingleUserO';
import IconMultiUserO from './IconMultiUserO';
import IconFirstDayO from './IconFirstDayO';
import IconCalendarO from './IconCalendarO';
import IconAdvisorO from './IconAdvisorO';
import IconAnniversaryO from './IconAnniversaryO';
import IconCheckO from './IconCheckO';
import IconCloseO from './IconCloseO';
import IconBlueCheckO from './IconBlueCheckO';
import IconMoneyHandO from './IconMoneyHandO';
import IconDownload from './IconDownload';

const getPath = (name, { fill, fillAlt, alt }) => {
  switch (name) {
    case 'icon-coins-o':
      return <IconCoinsO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-lifesaver-o':
      return <IconLifesaverO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-stop-watch-o':
      return <IconStopWatchO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-rocket-o':
      return <IconRocketO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-comment-question-o':
      return <IconCommentQuestionO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-check-burst-o':
      return <IconCheckBurstO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-coin-o':
      return <IconCoinO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-time-rewind-o':
      return <IconTimeRewindO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-user-marker-o':
      return <IconUserMarkerO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-blue-check-o':
      return <IconBlueCheckO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-business-owner-o':
      return <IconBusinessOwnerO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-single-user-o':
      return <IconSingleUserO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-multi-user-o':
      return <IconMultiUserO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-first-day-o':
      return <IconFirstDayO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-calendar-o':
      return <IconCalendarO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-advisor-o':
      return <IconAdvisorO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-anniversary-o':
      return <IconAnniversaryO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-check-o':
      return <IconCheckO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-close-o':
      return <IconCloseO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-money-hand-o':
      return <IconMoneyHandO fill={fill} fillAlt={fillAlt} alt={alt} />;

    case 'icon-download':
      return <IconDownload fill={fill} />;

    default:
      return <path />;
  }
};

const iconSize = (size) => {
  switch (size) {
    case 'small':
      return '20';
    case 'medium':
      return '40';
    case 'large':
      return '100';
    default:
      return size;
  }
};

const SVGIcon = ({
  name = '',
  style = {},
  fill = '#000',
  fillAlt = null,
  size = '40',
  className = '',
  height,
  alt = '',
}) => (
  <svg
    role="img"
    aria-describedby="title"
    width={iconSize(size)}
    style={style}
    height={height || iconSize(size)}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title id="title">{alt}</title>
    {getPath(name, { fill, fillAlt })}
  </svg>
);

export default SVGIcon;

SVGIcon.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
  fill: PropTypes.string,
  fillAlt: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
};
