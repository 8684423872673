import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import USER_LIST_LINKS from '../../data/UserListLinks';

const UserList = ({
  rootRoute,
  paradigmHost,
  setOverlayVisibility,
  visibleOverlay,
}) => {
  const links = USER_LIST_LINKS[rootRoute.slice(1)] || USER_LIST_LINKS.default;

  return (
    <ul className="userlist">
      {links.map((link) => {
        if (!link.excludeRoutes || !link.excludeRoutes.includes(rootRoute)) {
          if (link.type === 'external') {
            return (
              <li
                className={`userlist__item ${link.classes || ''}`}
                key={link.label}
              >
                <a
                  className="userlist__link"
                  href={`${link.href || paradigmHost + link.hrefSuffix}`}
                >
                  {link.label}
                </a>
              </li>
            );
          }
          if (link.type === 'tel') {
            return (
              <li
                className={`userlist__item ${link.classes || ''}`}
                key={link.label}
              >
                <a className="userlist__link no-link" href={link.href}>
                  {link.label}
                </a>
              </li>
            );
          }
          return (
            <li className="userlist__item" key={link.label}>
              <NavLink
                activeClassName="active"
                className="userlist__link"
                to={link.to}
                onClick={() => {
                  setOverlayVisibility(!visibleOverlay);
                }}
              >
                {link.label}
              </NavLink>
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
};

UserList.propTypes = {
  rootRoute: PropTypes.string.isRequired,
  paradigmHost: PropTypes.string.isRequired,
  setOverlayVisibility: PropTypes.func.isRequired,
  visibleOverlay: PropTypes.bool.isRequired,
};

export default UserList;
