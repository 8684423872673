import styled from 'styled-components';

const ListDisclaimer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  @media (min-width: 768px) {
    li {
      margin: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

// Parent Block
const FooterStyles = styled.footer`
  position: relative;
  z-index: 1;
  color: #fff;
  &::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100%;
    background: #002855;
    top: -40px;
    height: 80px;
    width: 100%;
    transform: scale(1.1);
    z-index: -1;
    @media (min-width: 768px) {
      height: 200px;
      top: -100px;
    }
  }
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
`;

// Compose Elements
FooterStyles.ListDisclaimer = ListDisclaimer;

export default FooterStyles;
