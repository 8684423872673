import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '../Label';

export const Date = (props) => {
  const {
    name,
    label,
    value,
    required,
    labelClass,
    handleQuestionChange,
    infoLabel,
    inputClass,
    min,
    max,
    hasError,
    inputLabel,
  } = props;

  return (
    <div
      className={classNames('mb-3 form-group', {
        'has-error': hasError,
      })}
    >
      <Label
        name={name}
        className={labelClass}
        text={label}
        required={required}
      />

      {infoLabel && (
        <div
          className="fs-p text-center"
          dangerouslySetInnerHTML={{ __html: infoLabel }}
        />
      )}
      <div className={inputLabel ? 'text-center mt-5' : 'text-center'}>
        {inputLabel && (
          <small
            className="fs-p fw-bold text-center"
            dangerouslySetInnerHTML={{ __html: inputLabel }}
          />
        )}
        <input
          id={name}
          className={classNames(inputClass)}
          type="date"
          min={min}
          max={max}
          required={required}
          name={name}
          value={value}
          onChange={(event) => {
            handleQuestionChange(name, event.target.value);
          }}
        />
      </div>
      {props.children}
    </div>
  );
};

Date.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  labelClass: PropTypes.string,
  handleQuestionChange: PropTypes.func,
  infoLabel: PropTypes.any,
  inputClass: PropTypes.string,
  children: PropTypes.any,
  min: PropTypes.string.isRequired,
  max: PropTypes.string,
  hasError: PropTypes.bool,
  inputLabel: PropTypes.string,
};

export default Date;
