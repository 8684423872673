export const getAgeRequirement = async (
  minAgeReq,
  minAgeReqDiscretionaryContribtion,
) => {
  let ageReq;
  if (minAgeReq.value === '18' || minAgeReq.value === 'age18') {
    ageReq =
      minAgeReqDiscretionaryContribtion.value === '21' ||
      minAgeReqDiscretionaryContribtion.value === 'age21'
        ? minAgeReqDiscretionaryContribtion
        : minAgeReq;
  } else if (minAgeReq.value === '21' || minAgeReq.value === 'age21') {
    ageReq = minAgeReq;
  } else {
    ageReq = minAgeReqDiscretionaryContribtion;
  }
  return ageReq;
};
