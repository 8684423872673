export const minAgeReqDiscretionaryMatchContributions = {
  id: 'q3',
  type: 'radio_button',
  name: 'minAgeReqDiscretionaryMatchContributions',
  paradigmAlias: 'minAgeReqDiscretionaryMatchContributions',
  user: {},
  stepName: 'a_step_3',
  label:
    'What is the minimum age requirement for discretionary matching contributions?',
  options: [
    {
      id: 1,
      user: {},
      label: 'Age 18',
      value: '18',
      valueFormatted: '18',
      checked: false,
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: 'Age 21',
      value: '21',
      valueFormatted: '21',
      checked: false,
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: 'No minimum age requirement',
      value: 'noMin',
      valueFormatted: 'No minimum age requirement',
      checked: false,
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Minimum age for discretionary matching',
  reviewScreenTitle: 'Minimum age for discretionary matching',
  optionDescription: '',
  description: 'This is the minimum age requirement for deferrals.',
};
