import React from 'react';
import PropTypes from 'prop-types';
import ColFinancialPro from '../../../../common/QuickQuote/ColFinancialPro';
import ColFinancialProComp from '../../../../common/QuickQuote/ColFinancialProComp';

const RowFinancialPro = ({
  data,
  handleQuestionChange,
  putApplicationPanelInfo,
}) => (
  <div>
    <div className="row justify-content-center">
      <div className="form-group">
        <h4>Are you working with a financial professional?</h4>
        <div className="row justify-content-center my-3">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="option1"
              value={data.hasFp.options[0].value}
              name={data.hasFp.options[0].name}
              checked={data.hasFp.value === data.hasFp.options[0].value}
              onChange={(input) => {
                handleQuestionChange(
                  data.hasFp.name,
                  input.target.checked,
                  data.hasFp.options[0].label,
                );
              }}
            />
            <label className="form-check-label mr-2" htmlFor="option1">
              <strong>Yes</strong>
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="option2"
              value={data.hasFp.options[1].value}
              name={data.hasFp.options[1].name}
              checked={data.hasFp.value === data.hasFp.options[1].value}
              onChange={(input) => {
                handleQuestionChange(
                  data.hasFp.name,
                  !input.target.checked,
                  data.hasFp.options[1].label,
                );
              }}
            />
            <label className="form-check-label mr-2" htmlFor="option2">
              <strong>No</strong>
            </label>
          </div>
          <small className="d-block">
            <button
              type="button"
              className="srp-button__link"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'Why are we asking?',
                  additionalInformation: `If your client is working with a financial professional, the financial professional can select one of three Wilshire 3(38) investment option lineups for the plan. Once you have made all other selections, you will be prompted to share the plan design with your client’s financial professional to make the investment lineup selection. The financial professional will share the plan back to you once the investment lineup selection is complete so you can share the plan directly with your client for approval and purchase.`,
                })
              }
            >
              Why are we asking?
            </button>
          </small>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      {data.hasFp.value && (
        <ColFinancialPro
          data={data}
          handleQuestionChange={handleQuestionChange}
        />
      )}
      {data.hasFp.value && (
        <ColFinancialProComp
          data={data}
          handleQuestionChange={handleQuestionChange}
        />
      )}
    </div>
  </div>
);

RowFinancialPro.propTypes = {
  data: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

export default RowFinancialPro;
