import React from 'react';
import { asOfDate } from '../../../../Services/utilities';

const Pricing = () => (
  <div className="py-4 px-5">
    <p className="fs-p fw-bold mb-0">Pricing for our solution</p>
    <p className="fs-h2">
      The Simply Retirement by Principal
      <small>
        <sup>®</sup>
      </small>{' '}
      401(k) plan has a simple flat recordkeeping fee structure.
    </p>
    <p className="fs-p">
      You’ll pay $185 per month ($555 billed quarterly). If you choose to pay
      the $6 per participant, per month fee on your employees’ behalf, this will
      also be billed quarterly, three months at a time. Otherwise, it will be
      deducted from participants’ plan assets. Custodial and investment fees are
      charged against participating employees’ accounts (those vary by
      investment and range from 0.08% – 0.86%, as of {asOfDate}).
    </p>
  </div>
);

export default Pricing;
