import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';

import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import SectionMaskTop from '../../../../Images/section-mask-top.svg';

import {
  AboutHeroImage,
  PrincipalLogo,
  UbiquityLogo,
  AboutFigure001,
  CheckCircle,
} from '../../../ImagePaths';

const sectionHeader = {
  image: AboutHeroImage,
  title: 'Backed By Experience',
  alt: 'Employees collaborating at computers.',
  caption: `
    <p class="fs-display5">
      Simply Retirement by Principal<small><sup>®</sup></small> combines
      more than 80 years of Principal<small><sup>®</sup></small> retirement and <button id="about-employer-disclaimer-1" class="text-underline fs-display5 p-0 no-border-button">financial expertise</button><small><sup>*</sup></small>
      with the digital 401(k) plan technology of Ubiquity Retirement + Savings<small><sup>&reg;</sup></small>.
    </p>
  `,
  copy: `
<div class="container-fluid">
  <div class="row my-4">
  <div class="col text-center my-auto">
    <img src=${PrincipalLogo} alt="Principal Logo" class="img-fluid d-block mx-auto" />
     </div><div class="col-auto fs-h1 my-auto text-center">
 +</div>
<div class="col text-center my-auto">   <img src=${UbiquityLogo} alt="Ubiquity Logo" class="img-fluid d-block mx-auto" />
</div>
</div>
</div>    <p class="fs-h6">
      Together, we’ve created a solution that helps make it simple for
      small businesses to set up and manage an affordable 401(k) plan
      online. It’s our goal to make retirement savings more accessible to
      the millions of small businesses across the nation that don’t offer
      a plan to their employees today.
    </p>`,
};

const principalSection = `
    <img
      src=${PrincipalLogo}
      alt="Principal"
      width="210"
      class="img-fluid d-block mb-5 mx-auto"
    />
    <p class="fs-display5 text-center mb-5">
      <strong>
        With nearly 11.7 million retirement plan <button id="about-employer-disclaimer-2" class="text-underline p-0 no-border-button fs-display5 fw-bold">participants</button><sup><small>*</small></sup>
      </strong>,
      Principal<small><sup>®</sup></small>
      is a global investment management and retirement plan service leader
      that has worked with thousands of organizations like yours.
    </p>
    <p class="fs-h6">
      <strong>
        We’ve also been named one of the World’s Most Ethical <button id="about-employer-disclaimer-3" class="text-underline p-0 no-border-button fs-h6 fw-bold">Companies</button><sup><small>*</small></sup>.
      </strong>
      Our offerings are based on experience, not quick wins or fads.
      Because we know that integrity, honesty, and comprehensive expertise
      are the right way to go—and the surest path to helping you reach
      your long-term financial stability goals. Learn more at
      <a href="https://principal.com" target="_blank">principal.com</a>.
    </p>
`;

const ubiquitySection = `
  <img
    src=${UbiquityLogo}
    width="210"
    alt="Ubiquity"
    class="img-fluid d-block mb-5 mx-auto"
  />
  <p class="fs-h2 text-center mb-5">
    Ubiquity Retirement + Savings<sup><small>&reg;</small></sup> is a leading fintech company that sits at the crossroads of human capital management (HCM), software as a service (SaaS) and Robo-recordkeeping.
  </p>
  <p class="fs-h6">
    With more than <strong>20 years of digital 401(k) plan technology experience</strong>,
    Ubiquity is a good complement to the Principal<small><sup>®</sup></small>
    retirement savings product offering. Paradigm RKS<sup><small>&trade;</small></sup>
    is Ubiquity’s cloud-based, proprietary recordkeeping platform,
    which backs the company’s retirement solutions and is licensed to
    leading financial services firms around the country. For more
    information, please visit <a href="https://myubiquity.com/about" target="_blank">myubiquity.com</a>.
  </p>
`;

const workTogetherSection = `
  <img
    src=${AboutFigure001}
    alt="teamwork illustration"
    class="d-block mx-auto mt-3 mb-5"
  />
  <h3 class="fs-h3 text-center mb-5">Working together for you</h3>
  <h4 class="fs-h4 text-secondary fw-bold mb-3">
    Why we chose Ubiquity
  </h4>
  <p class="fs-h2 mb-5">
    Our vision for Simply Retirement by Principal<small><sup>®</sup></small> has always been to
    create the best possible experience for our customers. That’s why we
    selected Ubiquity and its proprietary Paradigm RKS recordkeeping
    system. Paradigm RKS is a forward-thinking, future-ready solution and
    it’s a major leap forward for the industry.
  </p>
  <h4 class="fs-h4 text-secondary fw-bold mb-3">
    What this means for you
  </h4>
  <p class="fs-h2">
    You may notice the Ubiquity name on some of your plan correspondence
    and documents, and this just means that Principal and Ubiquity are
    working together to support you. Both companies are committed to
    helping ensure you have a retirement savings plan that works for you
    and your employees.
  </p>
`;

const businessOwnerAbout1 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*Financial expertise:</span> As of Dec. 31, 2023</p>`;
const businessOwnerAbout2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Participants:</span> Principal data as of Dec. 31, 2023</p>`;
const businessOwnerAbout3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Companies:</span> Ethisphere<small><sup>®</sup></small>, March 2024</p>`;
const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${businessOwnerAbout1}
     ${businessOwnerAbout2}
     ${businessOwnerAbout3}
    </div>
  </div>
`;

const About = ({ applicationPanelInfo, putApplicationPanelInfo }) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'about-employer-disclaimer-1',
      'about-employer-disclaimer-2',
      'about-employer-disclaimer-3',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'about-employer-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerAbout1,
        });
        break;

      case 'about-employer-disclaimer-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerAbout2,
        });
        break;

      case 'about-employer-disclaimer-3':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerAbout3,
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="about-page mb-5">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        alt={sectionHeader.alt}
      />
      <SectionSingleCol copy={principalSection} />
      <SectionSingleCol className="pt-0" copy={ubiquitySection} />
      <div className="section section_bg-pattern">
        <img src={SectionMaskTop} alt="" className="section__mask-top" />
        <SectionSingleCol copy={workTogetherSection} />
        <section className="section section_py-5 pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <img
                  src={CheckCircle}
                  alt="Checkmark In Circle Icon"
                  className="d-block mx-auto mb-3"
                />
                <h3 className="fs-h3 text-center mb-4">Let’s get started.</h3>
                <p className="text-center pb-5 mb-5">
                  <Link to="/plans" className="srp-button srp-button__primary">
                    Try our 401(k) planner
                  </Link>
                </p>
                <p className="fs-disclaimer text-center pb-5 mb-5">
                  Intended for plan sponsor use.
                </p>
              </div>
            </div>
          </div>
        </section>
        <SectionSingleCol
          className="d-print-block d-none"
          copy={footNoteList}
        />
      </div>
    </div>
  );
};

About.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
