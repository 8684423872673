/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SignUpModalRedux from '../../Redux/SignUpModalRedux';
import ToastRedux from '../../Redux/ToastRedux';
import classNames from 'classnames';
import states from '../../Services/states.json';

const UpdateSignUpModal = ({
  modalVisibility,
  toggleModalVisibility,
  addToast,
}) => {
  const [email, setEmailValue] = useState({ value: '', isValid: true });
  const [stateSelection, setStateSelection] = useState({
    value: '',
    isValid: true,
  });
  const [typeValue, setTypeValue] = useState({ value: '', isValid: true });
  const [status, setStatus] = useState('');
  let modalRef;
  const customStyles = {
    overlay: {
      background: 'rgba(5, 5, 5, 0.5)',
    },
  };

  const handleEmail = (value) => {
    setEmailValue({
      value,
      isValid: /\S+@\S+\.\S+/.test(value),
    });
  };
  const handleStateSelection = (selected) => {
    setStateSelection({
      value: selected,
      isValid: !!selected,
    });
  };
  const handleType = (value) => {
    setTypeValue({
      value,
      isValid: !!value,
    });
  };

  const submitForm = async (obj) => {
    // Previously POST to Pardot -- has been removed
    // Form is broken and needs to be fixed
    setStatus(500);
  };

  const isDisabled =
    email.isValid &&
    stateSelection.isValid &&
    typeValue.isValid &&
    email.value &&
    stateSelection.value &&
    typeValue.value;

  const whichRole = (chosenValue) => {
    switch (chosenValue) {
      case 'third_party_administrator':
        return 'tpa';
      case 'financial_professional':
        return 'advisor';
      case 'business_owner':
        return 'employer';
      default:
        return 'employer';
    }
  };

  useEffect(() => {
    Modal.setAppElement(modalRef);
  }, []);
  useEffect(() => {
    if (status === 200) {
      addToast('Signed up successfully!', 'success');
      toggleModalVisibility(false);
      setEmailValue({ value: '', isValid: true });
      setStateSelection({ value: '', isValid: true });
      setTypeValue({ value: '', isValid: true });
    } else if (status === 500) {
      toggleModalVisibility(false);
      addToast('Request failed to send. Refresh and try again.', 'error');
    }
  }, [status]);
  return (
    <Modal
      className="Modal__Bootstrap modal-dialog modal-dialog-centered modal-xl"
      closeTimeoutMS={150}
      isOpen={modalVisibility}
      onRequestClose={() => toggleModalVisibility(false)}
      ariaHideApp={false}
      shouldCloseOnEsc
      style={customStyles}
    >
      <div
        ref={(arg) => {
          modalRef = arg;
        }}
        className="modal-content"
      >
        <div className="modal-header">
          <button
            type="button"
            aria-label="close"
            className="fa-solid fa-xmark fa-2x text-dusty no-border-button close-btn"
            onClick={() => {
              toggleModalVisibility(false);
            }}
          />
        </div>
        <div className="modal-body">
          <div className="contianer-fluid">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h1 className="fs-h1 text-center mb-3">Sign up for updates</h1>
                <p className="fs-p text-center mb-4 lh-125">
                  Answer the questions below and we’ll be sure you’re up to date
                  with the latest news and product information from Simply
                  Retirement by Principal
                  <small>
                    <sup>®</sup>
                  </small>
                  .
                </p>
                <div className="row mb-3">
                  <div
                    className={classNames('form-group col-12 col-sm-6', {
                      'has-error': !email.value,
                    })}
                  >
                    <label className="fs-form-label fw-bold" htmlFor="email">
                      Email address*
                    </label>
                    <input
                      value={email.value}
                      type="email"
                      name="email"
                      className={classNames('srp-form-input form-control', {
                        'is-invalid': !email.isValid,
                      })}
                      id="email"
                      placeholder="Email"
                      onChange={(el) => {
                        handleEmail(el.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group col-12 col-sm-6">
                    <label
                      className={classNames('fs-form-label fw-bold', {
                        'is-invalid': !stateSelection.isValid,
                      })}
                      htmlFor="state"
                    >
                      State*
                    </label>
                    <select
                      value={stateSelection.value}
                      type="text"
                      name="name"
                      className={classNames(
                        'custom-select srp-form-dropdown form-control',
                        {
                          'is-invalid': !stateSelection.isValid,
                        },
                      )}
                      id="name"
                      placeholder="Name"
                      onChange={(selected) => {
                        handleStateSelection(selected.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="form-group text-center mb-4">
                  <label
                    className={classNames('fs-form-label fw-bold mb-3', {
                      'is-invalid': !typeValue.isValid,
                    })}
                    htmlFor="role"
                  >
                    Are you a business owner or financial professional?*
                  </label>
                  <div className="srp-form-toggle">
                    <label
                      className={classNames('srp-form-checkbox', {
                        active: typeValue.value === 'business_owner',
                      })}
                      htmlFor="type_1"
                    >
                      <input
                        className="srp-form-checkbox__input"
                        type="radio"
                        name="type"
                        id="type_1"
                        value="business_owner"
                        checked={typeValue.value === 'business_owner'}
                        onChange={(el) => handleType(el.target.value)}
                      />
                      <strong className="fs-p fw-bold lh-100 d-inline-block">
                        Business owner
                      </strong>
                    </label>
                    <label
                      className={classNames('srp-form-checkbox', {
                        active: typeValue.value === 'financial_professional',
                      })}
                      htmlFor="type_2"
                    >
                      <input
                        className="srp-form-checkbox__input"
                        type="radio"
                        name="type"
                        id="type_2"
                        value="financial_professional"
                        checked={typeValue.value === 'financial_professional'}
                        onChange={(el) => handleType(el.target.value)}
                      />
                      <strong className="fs-p fw-bold lh-100 d-inline-block">
                        Financial professional
                      </strong>
                    </label>
                    <label
                      className={classNames('srp-form-checkbox', {
                        active: typeValue.value === 'third_party_administrator',
                      })}
                      htmlFor="type_2"
                    >
                      <input
                        className="srp-form-checkbox__input"
                        type="radio"
                        name="type"
                        id="type_2"
                        value="third_party_administrator"
                        checked={
                          typeValue.value === 'third_party_administrator'
                        }
                        onChange={(el) => handleType(el.target.value)}
                      />
                      <strong className="fs-p fw-bold lh-100 d-inline-block">
                        <span className="d-sm-inline d-none">
                          Third party administrator
                        </span>
                        <span className="d-sm-none">TPA</span>
                      </strong>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <p className="fs-disclaimer text-center mb-4 lh-125">
                  By clicking “Sign up for updates,” you agree to the Principal
                  privacy policy. We do not spam and you may update your
                  preferences or unsubscribe at any time.
                </p>
                <div className="text-center">
                  <button
                    disabled={!isDisabled}
                    type="submit"
                    className="srp-button srp-button__primary px-5"
                    onClick={() => {
                      submitForm({
                        email: email.value,
                        state: stateSelection.value,
                        lead_type: whichRole(typeValue.value),
                        stage_flag: 'Updates',
                      });
                    }}
                  >
                    Sign up for updates
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

UpdateSignUpModal.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  modalVisibility: store.signUpModal.modalVisibility,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalVisibility: (toggle) => {
    dispatch(SignUpModalRedux.toggleModalVisibility(toggle));
  },
  addToast: (text, toastType) => {
    dispatch(ToastRedux.addToast(text, toastType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSignUpModal);
