import * as React from 'react';
import API from './api';
const api = API.create();

export const useBrokerDealers = (type) => {
  const [data, setFetchData] = React.useState({
    isLoading: false,
    isError: false,
    brokerOptions: [],
  });

  React.useEffect(() => {
    let isCancelled = false;
    let res;
    const fetchData = async () => {
      setFetchData({ ...data, isLoading: true, isError: false });
      try {
        if (type === 'iarRia') {
          res = await api.getIarRiaBrokerDealers();
        } else if (type === 'brokerDealer') {
          res = await api.getRegisteredRepBrokerDealers();
        } else if (type === 'insuranceAgent') {
          res = await api.getInsuranceBrokerDealers();
        } else {
          res = await api.getAllBrokerDealers();
        }
        const firmOptions = [];
        Object.values(res.data).forEach((element) => {
          if (element !== 'success') {
            firmOptions.push({
              id: element.id,
              broker_dealer_name: element.broker_dealer_name,
              label: element.label,
              value: element.label,
              brokerType: element.broker_type,
              compensation: element.compensation,
              crd: element.crd,
              brokerEid: element.eid,
              investments: element.investments,
              maxBasisPoints: element.max_basis_points,
              split_comp: element.split_comp,
              notice: element.notice,
            });
          }
        });
        setFetchData({
          ...data,
          isLoading: false,
          isError: false,
          brokerOptions: firmOptions,
        });
      } catch (e) {
        if (!isCancelled) {
          setFetchData({ ...data, isLoading: false, isError: true, msg: e });
        }
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [type]);
  return [data, setFetchData];
};
