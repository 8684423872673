import styled from 'styled-components';

export const Alert = styled.div`
  position: fixed;
  left: calc(50% - 160px);
  bottom: 0;
  background-color: #02837c;
  border-radius: 28px;
  font-size: 20px;
  color: #fff;
  padding: 22px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1030;
  min-width: 320px;

  @media (min-width: 768px) {
    left: auto;
    right: 0;
    transform: translateX(0);
  }
`;
