import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux from '../../Redux/FormDataRedux';
import { iconPlaybookO } from '../../ImagePaths';

export const SubmittedPlan = ({ resetData }) => {
  useEffect(() => {
    sessionStorage.clear();
    resetData();
  }, []);
  return (
    <div className="fws">
      <Link
        style={{ position: 'absolute', right: 0 }}
        className="overlay-close-btn quick-quote__close"
        to="/"
      />
      <div className="container-fluid h-100">
        <div className="row h-100 flex-column justify-content-between">
          <div className="col-md-7 m-auto">
            <div className="text-center">
              <div className="mb-4vw">
                <img src={iconPlaybookO} alt="Playbook Icon" height="150" />
              </div>
              <h3 className="fs-form-q fw-bold text-center mb-3">
                This plan has already been submitted.
              </h3>
              <p className="fs-h3 mb-5 pb-5">
                Feel free to start another plan.
              </p>
              <Link
                to="/plans"
                className="text-center srp-button srp-button__large srp-button__primary my-5"
              >
                Start another plan
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SubmittedPlan.propTypes = {
  resetData: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  resetData: () => {
    dispatch(FormDataRedux.resetData());
  },
});

export default connect(null, mapDispatchToProps)(SubmittedPlan);
