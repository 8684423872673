export const discretionarySharingContributions = {
  id: 'q6aI1d',
  type: 'radio_condensed',
  name: 'discretionarySharingContributions',
  paradigmAlias: 'discretionarySharingContributions',
  user: {},
  stepName: 'a_step_6_a_i_1_e',
  label: 'Will the plan allow for discretionary profit-sharing contributions?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      icon: 'icon-check-o',
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      icon: 'icon-close-o',
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Profit-sharing contributions',
  reviewScreenTitle: 'Profit-sharing contributions',
  optionDescription: '',
  description:
    'Business owners have the option to contribute company profits back to employees’ 401(k) plan accounts.',
};
