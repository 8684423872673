export const hardshipWithdrawals = {
  id: '000',
  type: 'radio_condensed',
  name: 'hardshipWithdrawals',
  paradigmAlias: 'hardshipWithdrawals',
  user: {},
  stepName: 'a_step_7',
  label: 'Will the plan allow for hardship withdrawals?',
  infoLabel: '',
  heading: 'What are hardship withdrawals?',
  content:
    'This provision allows participants to withdraw eligible contributions and possibly earnings from their 401(k) account to meet the needs of a real financial emergency. Plans with this provision will have stated reasons for hardship withdrawals, and will specify what documentation the employee must provide to the employer to prove the hardship withdrawal was warranted in case of an audit.</br>Participants must pay taxes on the amount they withdraw, plus a 10 percent penalty if they are under age 59½. Additional requirements may apply based on the provisions of the plan.',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Hardship withdrawals',
  reviewScreenTitle: 'Hardship withdrawals',
  optionDescription: '',
  description:
    'This provision allows participants to withdraw from their 401(k) account to meet the needs of a real financial emergency based on specific criteria as specified in the plan. Participants must pay taxes on the amount they withdraw, plus a 10% penalty if they are under age 59½. Additional requirements may apply based on the provisions of the plan.',
};
