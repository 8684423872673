// Components
import LetsBegin from './LetsBegin';
import RoleSelection from './RoleSelection';
import ReturnRoleSelection from './ReturnRoleSelection';
import UserInfo from './UserInfo';

// Employer Components
import { employerComponents } from './EmployerQuestions/steps';

// Advisor Components
import { advisorComponents } from './AdvisorQuestions/steps';

// Default Components
import { defaultComponents } from './DefaultPlan/steps';

export const components = {
  ...defaultComponents,
  ...employerComponents,
  ...advisorComponents,

  LetsBegin,
  RoleSelection,
  ReturnRoleSelection,
  UserInfo,
};
