import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  hasError,
  storedImage,
  children,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

  const callUpdateFilesCb = (filesCb) => {
    const filesAsArray = convertNestedObjectToArray(filesCb);
    updateFilesCb(filesAsArray);
  };

  const removeFile = (fileName) => {
    const tmpFiles = { ...files };
    delete tmpFiles[fileName];
    setFiles({ ...tmpFiles });
    callUpdateFilesCb({ ...tmpFiles });
  };

  const addNewFiles = (newFiles) => {
    let tmpFiles = { ...files };
    Object.keys(newFiles).forEach((key) => {
      const file = newFiles[key];
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          tmpFiles = {};
        }
        tmpFiles[file.name] = file;
      }
    });
    return { ...tmpFiles };
  };
  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;

    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles);

      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };
  return (
    <div
      className={classNames('mb-3 form-group', {
        'has-error': hasError,
      })}
    >
      <span className="fs-form-label fw-bold text-center">{label}</span>
      <div className="custom-uploader">
        <label
          htmlFor="fileMultiple"
          className="custom-uploader-label srp-form-input form-control"
        >
          <input
            id="fileMultiple"
            className="custom-uploader-input"
            type="file"
            ref={fileInputField}
            onClick={handleUploadBtnClick}
            onChange={handleNewFileUpload}
            title=""
            value=""
            {...otherProps}
          />
          <span className="custom-uploader-text">
            {storedImage.valueFormatted
              ? storedImage.valueFormatted
              : `Upload ${otherProps.multiple ? 'files' : 'a file'}`}
          </span>
        </label>
        {children}
      </div>

      {otherProps.multiple && (
        <article className="filePreviewContainer">
          <section className="previewList">
            {Object.keys(files).map((fileName, index) => {
              const file = files[fileName];
              const isImageFile = file.type.split('/')[0] === 'image';
              return (
                <section className="previewContainer" key={fileName}>
                  <div>
                    {isImageFile && (
                      <img
                        className="imagePreview"
                        src={URL.createObjectURL(file)}
                        alt={`file preview ${index}`}
                      />
                    )}
                    <div
                      className={classNames('fileMetaData', { isImageFile })}
                    >
                      <span>{file.name}</span>
                      <aside>
                        <span>{convertBytesToKB(file.size)} kb</span>
                        <i
                          aria-label="remove"
                          role="button"
                          tabIndex="0"
                          className="fas fa-trash-alt removeFileIcon"
                          onKeyPress={() => removeFile(fileName)}
                          onClick={() => removeFile(fileName)}
                        />
                      </aside>
                    </div>
                  </div>
                </section>
              );
            })}
          </section>
        </article>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  updateFilesCb: PropTypes.func.isRequired,
  maxFileSizeInBytes: PropTypes.number,
  hasError: PropTypes.bool,
  storedImage: PropTypes.object,
  children: PropTypes.any,
};

export default FileUpload;
