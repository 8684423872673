import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Question,
  StepControls,
  WithInfoPanel,
  isEligible,
  PrevStepBtn,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import { isFormValid } from '../../../Redux/FormDataRedux';

export const EligibleEmployees = ({
  role,
  active,
  googleData,
  questionId,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  isValid,
}) => {
  const renderValidationText = () => (
    <p className="fs-p text-center">
      Simply Retirement by Principal
      <sup>
        <small>®</small>
      </sup>{' '}
      is built for companies with fewer than 100 employees.
    </p>
  );

  const validEligibleEmployeeValue = () => !isEligible(value) && value !== '';
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };

  const { value, dirty, hasError } = questions[questionId];

  const disabledBasedOnRole = () => {
    if (role === 'advisor' || role === 'onedigital') {
      if (dirty && !hasError) return !isEligible(value);
    }
    return questions[questionId].required ? !(dirty && !hasError) : false;
  };
  const validate = !!(dirty && hasError);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <WithInfoPanel
        visibleInfoPanel={visibleInfoPanel}
        setVisibility={setVisibility}
        heading={questions[questionId].heading}
        content={questions[questionId].content}
        toggleInfoPanel={toggleInfoPanel}
      >
        <Question
          role={role}
          type={questions[questionId].type}
          labelClass="fs-form-q fw-bold text-center pt-3 pb-5 px-3 d-block"
          inputClass={
            questions[questionId].type === 'number_field' ? 'text-question' : ''
          }
          thousandSeparator
          question={questions[questionId]}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!questions[questionId].heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="fs-p lh-125 text-danger text-center">
            Question is required
          </p>
        )}
        {validEligibleEmployeeValue() && renderValidationText()}
        {questions[questionId].heading && (
          <p className="text-center mb-0">
            <button
              type="button"
              className="srp-button srp-button__link mx-auto"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Need clarification?
            </button>
          </p>
        )}
        <StepControls
          formInReview={isValid && formInReview}
          goToReviewPlan={goToReviewPlan}
          disableReviewBtn={!isEligible(value) && !isValid}
          disabledNextBtn={disabledBasedOnRole()}
          clickNextBtn={() => {
            setVisibility(false);
            destination(isEligible(value), updateActiveStep);
          }}
        />
      </WithInfoPanel>
      <PrevStepBtn
        active={active}
        goTo={previousStep()}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

EligibleEmployees.propTypes = {
  active: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  googleData: PropTypes.object.isRequired,
  questionId: PropTypes.string.isRequired,
  destination: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  updateQuestionById: PropTypes.func.isRequired,
  formInReview: PropTypes.bool.isRequired,
  goToReviewPlan: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  isValid: isFormValid(store),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EligibleEmployees);
