import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import FormDataRedux, { selectPrimaryAdvisor } from '../../Redux/FormDataRedux';
import {
  CloseForm,
  checkValidityOnQuestionArray,
} from '../../common/FormWithSteps';
import { forms, pages } from '../data';

export const RestrictedInsuranceAgent = ({
  history,
  location,
  formData,
  primaryAdvisor,
  updateAdvisor,
  putQuestionById,
  updateQuestion,
}) => {
  const { employerStateCode, currentPlan } = formData.planDesign.questions;
  const lastLocation = useLastLocation();
  const handleClose = () => {
    if (lastLocation) {
      history.goBack();
    } else {
      history.push('/');
    }
  };
  const pageKey = 'restrictedInsuranceAgent';
  const hasAdvisor = true;
  const inputsToValidate = [
    primaryAdvisor.questions.advisorFirstName,
    primaryAdvisor.questions.advisorLastName,
    primaryAdvisor.questions.advisorPhone,
    primaryAdvisor.questions.advisorEmail,
    employerStateCode,
    formData.planDesign.questions.firmNameFreeForm,
    formData.planDesign.questions.zipCode,
    formData.planDesign.questions.currentPlan,
  ];

  const searchParams = new URLSearchParams(location.search);
  const isDisabled =
    searchParams.get('response') === 'success'
      ? true
      : !checkValidityOnQuestionArray(inputsToValidate);
  const renderResponse = () => {
    switch (searchParams.get('response')) {
      case 'success':
        return (
          <div className="text-success fs-h3 text-center">
            {forms[pageKey].responseSuccessMsg}
          </div>
        );
      case 'error':
        return (
          <div className="text-warning fs-h3 text-center">
            {forms[pageKey].responseErrorMsg}
          </div>
        );
      default:
        return false;
    }
  };
  const [status, setStatus] = useState('');
  const refForm = useRef('');
  const submitForm = async (e) => {
    e.preventDefault();
    setStatus('error');
    // Previously POST to Pardot -- has been removed
    // Form is broken and needs to be fixed
  };

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    putQuestionById(
      formData.planDesign.formId,
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };

  const handleQuestionChangeState = (id, updateValue, valueFormatted) => {
    putQuestionById(
      formData.planDesign.formId,
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
    putQuestionById(
      formData.planDesign.formId,
      formData.planDesign.questions.insuranceGroupType.name,
      formData.planDesign.questions.insuranceGroupType.value,
      false,
      true,
      formData.planDesign.questions.insuranceGroupType.valueFormatted,
    );
  };

  return (
    <div className="fws fws_dark">
      <div className="fws__wrapper h-md-100">
        <CloseForm handleClose={handleClose} light />
        <div className="container h-100">
          <div className="row">
            <div className="col-lg-11 mx-auto">
              <h1 className="fs-h1 fw-bold text-white text-center my-5">
                Looks like we need to get in touch before you continue.
              </h1>
              <p className="fs-p text-white text-center mb-5">
                It appears you’re not licensed to sell this product in the state
                where your client is located. Fill out this form and a Simply
                Retirement by Principal<sup>®</sup> representative will contact
                you within 48 hours to discuss how you’d like to proceed.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div
                dangerouslySetInnerHTML={{
                  __html: pages[pageKey].content,
                }}
              />
              {status === 'error' ? (
                <div className="text-warning fs-h3 text-center">
                  {forms[pageKey].responseErrorMsg}
                </div>
              ) : (
                ''
              )}
              {searchParams.has('response') && renderResponse()}
              <form
                ref={refForm}
                id={forms[pageKey].id}
                action={forms[pageKey].action}
                method={forms[pageKey].method}
              >
                {hasAdvisor && (
                  <fieldset>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor="first_name"
                        >
                          First name
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          className="srp-form-input form-control"
                          id="first_name"
                          value={
                            primaryAdvisor.questions.advisorFirstName.value
                          }
                          onChange={(event) => {
                            updateAdvisor(
                              {
                                name: primaryAdvisor.questions.advisorFirstName
                                  .name,
                                value: event.target.value,
                                valueFormatted: event.target.value,
                                dirty: true,
                              },
                              0,
                            );
                          }}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor="last_name"
                        >
                          Last name
                        </label>
                        <input
                          type="text"
                          className="srp-form-input form-control"
                          id="last_name"
                          name="last_name"
                          value={primaryAdvisor.questions.advisorLastName.value}
                          onChange={(event) => {
                            updateAdvisor(
                              {
                                name: primaryAdvisor.questions.advisorLastName
                                  .name,
                                value: event.target.value,
                                valueFormatted: event.target.value,
                                dirty: true,
                              },
                              0,
                            );
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor={primaryAdvisor.questions.advisorEmail}
                        >
                          Email address
                        </label>
                        <input
                          type="text"
                          className="srp-form-input form-control"
                          id={primaryAdvisor.questions.advisorEmail}
                          name="email"
                          value={primaryAdvisor.questions.advisorEmail.value}
                          onChange={(event) => {
                            updateAdvisor(
                              {
                                name: primaryAdvisor.questions.advisorEmail
                                  .name,
                                value: event.target.value,
                                valueFormatted: event.target.value,
                                dirty: true,
                              },
                              0,
                            );
                          }}
                          required
                        />
                      </div>
                      <div className="col-md">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor="phone"
                        >
                          Phone number
                        </label>
                        <input
                          type="text"
                          className="srp-form-input form-control"
                          id={primaryAdvisor.questions.advisorPhone}
                          name="phone"
                          value={primaryAdvisor.questions.advisorPhone.value}
                          onChange={(event) => {
                            updateAdvisor(
                              {
                                name: primaryAdvisor.questions.advisorPhone
                                  .name,
                                value: event.target.value,
                                valueFormatted: event.target.value,
                                dirty: true,
                              },
                              0,
                            );
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor="company"
                        >
                          Your practice/firm name
                        </label>
                        <input
                          type="text"
                          className="srp-form-input form-control"
                          id={formData.planDesign.questions.firmNameFreeForm}
                          name="company"
                          value={
                            formData.planDesign.questions.firmNameFreeForm.value
                          }
                          onChange={(event) => {
                            updateQuestion({
                              name: formData.planDesign.questions
                                .firmNameFreeForm.name,
                              value: event.target.value,
                              valueFormatted: event.target.value,
                              hasError: false,
                              dirty: true,
                            });
                          }}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          className="fs-form-label text-white fw-bold"
                          htmlFor="current_plan"
                        >
                          Current plan
                        </label>
                        <select
                          id="current_plan"
                          name="current_plan"
                          value={currentPlan.value}
                          className="custom-select srp-form-input form-control form-select"
                          onChange={(selected) => {
                            handleQuestionChange(
                              currentPlan.name,
                              selected.target.value,
                              selected.target.value,
                            );
                          }}
                        >
                          <option>Select</option>
                          {currentPlan.options.map((option) => (
                            <option key={option.id} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {currentPlan.hasError && (
                          <small>Question is required</small>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor="state"
                        >
                          State where client is located
                        </label>
                        <select
                          id={employerStateCode.name}
                          name="state"
                          autoComplete="address-level1"
                          value={employerStateCode.value}
                          className="custom-select srp-form-input form-control form-select"
                          onChange={(selected) => {
                            handleQuestionChangeState(
                              employerStateCode.name,
                              selected.target.value,
                              selected.target.value,
                            );
                          }}
                        >
                          <option>Select</option>
                          {employerStateCode.options.map((option) => (
                            <option key={option.id} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label
                          className="fs-form-label fw-bold text-white"
                          htmlFor={formData.planDesign.questions.zipCode}
                        >
                          Client&apos;s zip code
                        </label>
                        <input
                          type="text"
                          name="postalcode"
                          className="srp-form-input form-control"
                          id={formData.planDesign.questions.zipCode}
                          value={formData.planDesign.questions.zipCode.value}
                          onChange={(event) => {
                            updateQuestion({
                              name: formData.planDesign.questions.zipCode.name,
                              value: event.target.value,
                              valueFormatted: event.target.value,
                              hasError: false,
                              dirty: true,
                            });
                          }}
                          required
                        />
                      </div>
                    </div>
                  </fieldset>
                )}
                <p className="text-white text-center fs-sm mb-4">
                  By clicking “Contact us,” you agree to the Principal privacy
                  policy. Your email will only be used to send you updates about
                  Simply Retirement by Principal
                  <small>
                    <sup>®</sup>
                  </small>
                  , and you may update your preferences or unsubscribe anytime.
                </p>
                <p className="text-center">
                  <button
                    disabled={isDisabled || status === 'loading'}
                    className="srp-button srp-button__large srp-button__primary"
                    type="submit"
                    onClick={(e) => {
                      setStatus('loading');
                      submitForm(e);
                    }}
                  >
                    {status === '' ? 'Contact us' : 'Submitting...'}
                  </button>
                </p>
              </form>
            </div>
          </div>
          {lastLocation && (
            <button
              type="button"
              className="goback fa-solid fa-arrow-left text-white"
              onClick={() => {
                history.replace({
                  pathname: lastLocation.pathname,
                });
              }}
            >
              <span className="sr-only">Go Back</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

RestrictedInsuranceAgent.propTypes = {
  formData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  primaryAdvisor: PropTypes.object.isRequired,
  updateAdvisor: PropTypes.func.isRequired,
  putQuestionById: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  primaryAdvisor: selectPrimaryAdvisor(store),
});
const mapDispatchToProps = (dispatch) => ({
  putQuestionById: (
    formId,
    questionId,
    value,
    hasError,
    dirty,
    valueFormatted,
  ) => {
    dispatch(
      FormDataRedux.putQuestionById(
        formId,
        questionId,
        value,
        hasError,
        dirty,
        valueFormatted,
      ),
    );
  },
  updateAdvisor: (obj, index) => {
    dispatch(FormDataRedux.updateAdvisor(obj, index));
  },
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestrictedInsuranceAgent);
