import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SimplyIconSprite from '../../../../common/SimplyIconSprite';
import { SectionStyled, CornerStyled, CircleStyled } from './index.styles';
const AboutSection = ({
  putApplicationPanelInfo,
  businessOwnerHomeDisclaimer1,
}) => (
  <section className="section section_py-5 pt-0 about-section">
    <div className="container-lg px-md-3 px-0">
      <SectionStyled className="px-sm-4 px-lg-5">
        <div className="row ml-lg-1 mx-0">
          <div className="col-md-7">
            <div className="row">
              <div className="col-xl-10 col-lg-12">
                <h3 className="fs-h3 mt-5 mb-3 text-md-left text-center">
                  What Simply Retirement by Principal with OneDigital
                  <sup>
                    <small>®</small>
                  </sup>{' '}
                  can do for you
                </h3>
              </div>
            </div>
            <p className="fs-p">
              Setting up a workplace retirement plan isn’t something you do
              every day. We get it. Maybe you aren’t even sure where to start.
              Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              with OneDigital makes it easy to{' '}
              <Link to="onedigital/why-simply-retirement">
                learn more about retirement plans
              </Link>{' '}
              and what’s involved. And when you’re ready, you can use our{' '}
              <Link to="/onedigital/plans#begin">planner</Link> to see what a
              plan might look like for your business and estimate costs.
            </p>
          </div>
          <CornerStyled className="offset-md-1 col-md-4 d-md-block d-none">
            <CircleStyled className="text-center">
              Start a plan, get up to $5,000/year in tax credits
            </CircleStyled>
            <p className="fs-p text-center">
              Take advantage of SECURE 2.0 Act tax credits to help offset up to
              100% of{' '}
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() =>
                  putApplicationPanelInfo({
                    hasVisibility: true,
                    additionalInformation: businessOwnerHomeDisclaimer1,
                  })
                }
                className="text-underline p-0 no-border-button"
              >
                (up to $5,000 per tax year for the first three years for some
                employers)
              </span>{' '}
              your first three years of plan startup costs.
              <a
                href="https://www.principal.com/landing-page/secureact-plansponsor"
                target="_blank"
                className="d-block"
              >
                The benefits of the SECURE 2.0 Act.
              </a>
            </p>
          </CornerStyled>
        </div>

        <div className="row mx-0">
          <div className="col-12 mx-auto">
            <div className="row">
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-check-burst-o"
                  fill="#002855"
                  height="70"
                  alt="Check Icon"
                />
                <h5 className="fs-h5 text-purple">Simple selections</h5>
                <p className="fs-p">
                  Set up your plan 100% online, where and when it&rsquo;s
                  convenient for you—or call if you have questions. We&rsquo;ve
                  streamlined the investment selection and simplified the
                  paperwork, too.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-coin-o"
                  fill="#002855"
                  height="70"
                  alt="Coin Icon"
                />
                <h5 className="fs-h5 text-purple">Simple pricing</h5>
                <p className="fs-p">
                  The low, flat-fee recordkeeping pricing makes a 401(k) plan
                  affordable for small businesses. It’s a straightforward cost
                  you can plan for each month.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-user-marker-o"
                  fill="#002855"
                  height="70"
                  alt="Map Marker Icon"
                />
                <h5 className="fs-h5 text-purple">Simple onboarding</h5>{' '}
                <p className="fs-p">
                  After you purchase your plan, you’ll enroll your employees
                  through the{' '}
                  <a href="https://www.myubiquity.com/about" target="_blank">
                    Ubiquity Retirement + Savings
                    <sup>
                      <small>&reg;</small>
                    </sup>{' '}
                  </a>
                  platform—used by thousands of small businesses across the U.S.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-time-rewind-o"
                  fill="#002855"
                  height="70"
                  alt="Rewind Icon"
                />
                <h5 className="fs-h5 text-purple">Simple administration</h5>{' '}
                <p className="fs-p">
                  Ubiquity’s user-friendly dashboard will help you manage your
                  plan and save time with features like automated notifications
                  and payroll integration.
                </p>
              </div>
            </div>
          </div>
        </div>
        <CornerStyled className="d-md-none">
          <CircleStyled className="text-center">
            Start a plan, get up to $5,000/year in tax credits
          </CircleStyled>
          <p className="fs-p text-center">
            Take advantage of SECURE 2.0 Act tax credits to help offset your
            first three years of plan{' '}
            <button
              type="button"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: businessOwnerHomeDisclaimer1,
                })
              }
              className="text-underline p-0 no-border-button"
            >
              startup costs.
            </button>
            <sup>
              <small>*</small>
            </sup>
            <a
              href="https://www.principal.com/landing-page/secureact-plansponsor"
              target="_blank"
              className="d-block"
            >
              The benefits of the SECURE 2.0 Act.
            </a>
          </p>
        </CornerStyled>
      </SectionStyled>
    </div>
  </section>
);

export default AboutSection;

AboutSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  businessOwnerHomeDisclaimer1: PropTypes.any,
};
