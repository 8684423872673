import React from 'react';
import { HeroWrapper } from './index.styles';

const SingleArticle = () => (
  <div className="disclosures-page mb-5">
    <HeroWrapper className="hero mb-5 pt-5">
      <div className="container-fluid container-fluid--sitewidth">
        <div className="row justify-content-center py-5">
          <h1 className="fs-h1 text-center fw-bold py-5 col-xl-6 col-lg-8 col-md-10 mt-5">
            Small Business Startup Plan Tax Advantages
          </h1>
        </div>
      </div>
    </HeroWrapper>
    <div className="container pb-5">
      <div className="row pb-5">
        <div className="col-md-9 mx-auto">
          <p>
            Small businesses care about their employees and want to help them
            retire, but cost can be an issue. The good news is that there may be
            tax benefits for some of the costs of starting a SEP, SIMPLE IRA, or
            qualified plan (like a 401(k) or a 403(b)). As always, have your
            client consult a tax advisor, but tax deductions and credits (which
            reduce the amount you pay in taxes) are available. Here are some
            things to consider when setting up a new small business retirement
            plan:
          </p>
          <h2>Tax credit: who’s eligible?</h2>
          <p>
            As a result of the Setting Every Community Up for Retirement
            Enhancement (SECURE) Act, effective Jan. 1, 2020, the tax credit for
            small employers starting a new retirement plan increased from $500
            annually up to a maximum of $5,000 annually for each of the first
            three years.
            <small>
              <sup>1</sup>
            </small>
          </p>
          <p>Employers can claim the credit if:</p>
          <ul>
            <li>
              They have no more than 100 employees who were paid at least $5,000
              in the prior year.
            </li>
            <li>
              At least one, non-highly compensated employee is eligible to
              participate in the plan.
            </li>
            <li>
              The plan is truly a startup—not a replacement for another plan.
              Employees can’t have received a benefit from a qualified plan from
              the company in the last three years.
            </li>
          </ul>
          <h2>What’s the tax credit?</h2>
          <p>
            The tax credit cannot exceed 50% of the qualified startup costs paid
            or incurred by the small employer.
          </p>
          <h2>What’s a qualified startup cost?</h2>
          <p>
            Qualified startup costs are defined by the IRS as ordinary and
            necessary costs to set up, administer, and educate employees about a
            new retirement plan.
          </p>
          <h2>When are clients eligible?</h2>
          <p>
            Your clients can claim the credit for three years—starting the year
            before the plan is effective.
          </p>
          <h2>Other tax benefits</h2>
          <p>
            What’s more, certain other expenses can be claimed as a tax
            deduction (but no double-dipping—they can’t claim the same expense
            as a tax credit and a tax deduction):
          </p>
          <ul>
            <li>Administrative fees are a tax-deductible business expense.</li>
            <li>
              Contributions from the employer are exempt from federal, state,
              and payroll taxes if they fall under 25% of the total compensation
              paid (or accrued) during the year to participating employees.
            </li>
          </ul>
          <h2>A new startup retirement plan option</h2>
          <p>
            For small business clients concerned about cost, there’s a new
            digital startup retirement plan option. Simply Retirement by
            Principal
            <small>
              <sup>®</sup>
            </small>{' '}
            is an online 401(k) offering for businesses with fewer than 100
            employees.
          </p>
          <p>
            With Simply Retirement by Principal
            <small>
              <sup>®</sup>
            </small>
            , you can create 401(k) proposals in minutes and share them with
            your clients by email, no in-person meetings required. Simple plan
            design, streamlined investment options, and flat recordkeeping fees
            make this an easy solution for small businesses that are ready to
            start a retirement plan. And SECURE 2.0 Act tax credits may help
            offset their plan startup costs.{' '}
            <strong>
              Visit{' '}
              <a
                href="https://www.simplyretirement.com/financial-professionals"
                target="_blank"
              >
                SimplyRetirement.com
              </a>{' '}
              to learn more.
            </strong>
          </p>
          <p>
            <small>
              <sup>1</sup> Greater of $500 or the lesser of $250 x NHCEs
              eligible or $5,000.
            </small>
          </p>
          <p>
            <small>
              Simply Retirement by Principal<sup>®</sup> 401(k) plan
              recordkeeping, and administrative services are provided through
              Decimal, Inc. dba Ubiquity Retirement + Savings
              (&ldquo;Ubiquity&rdquo;). Ubiquity is not affiliated with any plan
              and investment administrative services provided through Principal
              Life Insurance Co. or affiliated with any company of the Principal
              Financial Group<sup>®</sup>. Principal makes available the
              investment options for customers to select through Simply
              Retirement by Principal. All other services are provided by
              service providers not affiliated with any company of the Principal
              Financial Group. Refer to related documents and agreements for
              more details on plan services available.
            </small>
          </p>
          <p>
            <small>
              The subject matter in this communication is educational only and
              provided with the understanding that neither Principal
              <sup>®</sup> or Ubiquity Retirement + Savings are rendering
              legal, accounting, investment advice or tax advice. You should
              consult with appropriate counsel or other financial professionals
              on all matters pertaining to legal, tax, investment or accounting
              obligations and requirements.&#8232;
            </small>
          </p>
          <p>
            <small>
              Insurance products and plan administrative services provided
              through Principal Life Insurance Co., a member of the Principal
              Financial Group<sup>®</sup>, Des Moines, Iowa 50392.
            </small>
          </p>
          <p>
            <small>&#8232;©2020 Principal Financial Services, Inc.</small>
          </p>
          <p>
            <small>1344533-09/2020 | 09/2020</small>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default SingleArticle;
