import React from 'react';
import PropTypes from 'prop-types';

const IconCommentQuestionO = ({ fill, fillAlt }) => (
  <g>
    <path
      d="M22.8864 7.75231C21.971 7.32943 20.9851 7.18848 19.9992 7.18848C17.8866 7.18848 15.6332 7.96375 14.084 9.51429C13.7319 9.86668 13.7319 10.4305 14.084 10.8534C14.4361 11.2058 14.9994 11.2058 15.4219 10.8534C16.6191 9.72572 18.45 9.09141 20.0696 9.09141C21.1259 9.09141 22.1118 9.37333 22.7456 9.7962C23.0977 10.0076 23.309 10.2896 23.4498 10.642C23.5906 10.9239 23.7315 11.3467 23.7315 11.8401C23.7315 12.7563 23.5202 13.3906 23.0977 13.9545C22.5343 14.8002 21.5485 15.505 20.633 16.2803C20.1401 16.7032 19.7175 17.126 19.3654 17.6194C19.0133 18.1832 18.7317 18.8175 18.7317 19.5928C18.7317 20.0862 19.1542 20.509 19.6471 20.509C20.1401 20.509 20.5626 20.0862 20.5626 19.5928C20.5626 19.3814 20.633 19.1699 20.7034 18.9585C20.8443 18.6061 21.1964 18.2537 21.6189 17.8308C22.2527 17.1965 23.2385 16.5622 24.0132 15.646C24.4357 15.1526 24.7878 14.6593 25.0694 14.0249C25.3511 13.3906 25.492 12.6154 25.492 11.8401C25.492 11.0648 25.3511 10.4305 24.999 9.7962C24.5061 8.8095 23.7315 8.17518 22.8864 7.75231Z"
      fill={fill}
      className="fill"
    />
    <path
      d="M19.5765 21.8486C18.5907 21.8486 17.816 22.6239 17.816 23.6106C17.816 24.5973 18.5907 25.3726 19.5765 25.3726C20.5624 25.3726 21.337 24.5973 21.337 23.6106C21.4075 22.6239 20.5624 21.8486 19.5765 21.8486Z"
      fill={fill}
      className="fill"
    />
    <path
      d="M34.0832 4.65162C30.4918 1.76198 25.492 0 19.9992 0C14.5065 0 9.57709 1.76198 5.91526 4.65162C2.32385 7.61174 0 11.6995 0 16.2102C0 20.3685 1.90133 24.1039 4.99981 26.923L3.80267 36.1558C3.73225 36.5082 3.87309 36.8606 4.15477 37.0015C4.43645 37.213 4.78855 37.213 5.07023 37.072L15.2107 31.927C16.7599 32.2089 18.3091 32.4204 19.9992 32.4204C25.492 32.4204 30.4214 30.6584 34.0832 27.7688C37.745 24.8791 39.9984 20.7913 39.9984 16.2102C40.0689 11.6995 37.745 7.61174 34.0832 4.65162ZM32.9565 26.2887C29.6467 28.8964 25.0694 30.5879 19.9992 30.5879C18.3796 30.5879 16.8303 30.3765 15.2811 30.0946C15.0698 30.0241 14.8586 30.0946 14.6473 30.1651L5.84484 34.6052L6.90114 26.6411C6.97156 26.3592 6.83072 26.0068 6.61946 25.7953C3.59141 23.2581 1.83091 19.8751 1.83091 16.2102C1.83091 12.3338 3.80267 8.73941 7.1124 6.13168C10.3517 3.52395 14.929 1.83246 19.9992 1.83246C25.0694 1.83246 29.6467 3.45348 32.9565 6.13168C36.2662 8.73941 38.1675 12.3338 38.2379 16.2102C38.1675 20.0865 36.1958 23.681 32.9565 26.2887Z"
      fill={fillAlt || fill}
      className="fill"
    />
  </g>
);

IconCommentQuestionO.propTypes = {
  fill: PropTypes.string.isRequired,
  fillAlt: PropTypes.string,
};

export default IconCommentQuestionO;
