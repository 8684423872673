import styled from 'styled-components';
import EditIcon from '../../../../../Images/edit.svg';

export const Edit = styled.button`
  background-image: url(${EditIcon});
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border: none;
  padding: 25px;
  margin-left: 10px;
  display: inline-block;
`;
