import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import FormDataRedux, {
  selectRole,
  selectHashId,
  isFormValid,
} from '../../../Redux/FormDataRedux';
import FormWithSteps, { Step } from '../../../common/FormWithSteps';
import CostEstimator from './CostEstimator';

// Steps
import { components, tpaFormSteps } from './steps';

export const SetupPlan = ({
  formData,
  role,
  putActiveStep,
  putQuestionById,
  history,
  authenticityToken,
  hashId,
  resetData,
  isValid,
}) => {
  const [notificationIndicator, setNotificationIndicator] = useState(false);
  const lastLocation = useLastLocation();
  const handleClose = () => {
    if (lastLocation) {
      history.goBack();
    } else {
      history.push('/third-party-administrator');
    }
  };
  const goToReviewPlan = () => {
    history.push('/third-party-administrator/review-plan');
  };

  const formSteps = [
    ...tpaFormSteps(formData, hashId, role, history, goToReviewPlan),
  ];

  return (
    <FormWithSteps
      formInReview={formData.planDesign.formInReview}
      startAtStep={formData.planDesign.startAtStep}
      formId={formData.planDesign.formId}
      activeStep={formData.planDesign.activeStep}
      questions={formData.planDesign.questions}
      putActiveStep={putActiveStep}
      putQuestionById={putQuestionById}
      handleClose={handleClose}
      formData={formData}
      resetData={resetData}
      notificationIndicator={notificationIndicator}
      setNotificationIndicator={setNotificationIndicator}
      hashId={hashId}
      costEstimator={CostEstimator}
    >
      {formSteps.map((step) => {
        const { component, key, ...rest } = step;
        const Component = components[component];
        return (
          <Step
            id={component}
            key={step.key}
            component={Component}
            name={step.key}
            role={role}
            formData={formData}
            hashId={hashId}
            authenticityToken={authenticityToken}
            isValid={isValid}
            {...rest}
          />
        );
      })}
    </FormWithSteps>
  );
};

SetupPlan.propTypes = {
  formData: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  putActiveStep: PropTypes.func.isRequired,
  putQuestionById: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string,
  hashId: PropTypes.string,
  resetData: PropTypes.func,
  isValid: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  role: selectRole(store),
  hashId: selectHashId(store),
  isValid: isFormValid(store),
});
const mapDispatchToProps = (dispatch) => ({
  putActiveStep: (formId, updateStep) => {
    dispatch(FormDataRedux.putActiveStep(formId, updateStep));
  },
  putQuestionById: (
    formId,
    questionId,
    value,
    hasError,
    dirty,
    valueFormatted,
    disabled,
  ) => {
    dispatch(
      FormDataRedux.putQuestionById(
        formId,
        questionId,
        value,
        hasError,
        dirty,
        valueFormatted,
        disabled,
      ),
    );
  },
  resetData: (formId) => {
    dispatch(FormDataRedux.resetData(formId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupPlan);
