export const vestingSchedule = {
  id: 'default_step_11',
  type: 'dropdown',
  name: 'vestingSchedule',
  stepName: 'step_12',
  paradigmAlias: 'vestingScheduleProfit',
  user: {
    employer: {
      label:
        "Select your plan's vesting schedule for employer contributions (employer matching and discretionary profit sharing contributions)",
      infoLabel: '',
    },
    advisor: {
      label:
        'Select the plan’s vesting schedule for employer contributions (employer matching and discretionary profit sharing contributions)',
      infoLabel: '',
    },
  },
  heading: 'What’s a vesting schedule?',
  content:
    "A vesting schedule is the time frame it takes for employees to earn interest in contributions that a business owner makes to the employee's retirement plan. This is determined by the business owner and may be used as a retainment incentive. When an employee is 100% vested in their account balance, they earn 100% interest of it and the employer cannot forfeit, or take it back, for any reason. An employee must work 1,000 hours in a calendar year to receive a year of service towards vesting.",
  options: [
    {
      id: 1,
      user: {},
      label: 'Immediate = 100%',
      value: 'immediate',
      valueFormatted: 'Immediate',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: '6-year graded = 0%, 20%, 40%, 60%, 80%, 100%',
      value: 'sixYearGraded',
      valueFormatted: '6-year graded',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Vesting schedule profit-sharing match',
  reviewScreenTitle: 'Vesting schedule for employer contributions',
  optionDescription: '',
  description:
    'If a specific vesting schedule was selected for this contribution type, it will be shown here.',
};
