import React from 'react';
import PropTypes from 'prop-types';

const IconMultiUserO = ({ fill }) => (
  <g>
    <path
      d="M20.2111 22.2948C22.6059 22.2948 24.5473 20.3949 24.5473 18.0512C24.5473 15.7075 22.6059 13.8076 20.2111 13.8076C17.8164 13.8076 15.875 15.7075 15.875 18.0512C15.875 20.3949 17.8164 22.2948 20.2111 22.2948Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M27.7568 27.0689C27.475 24.667 26.6815 24.2766 25.8229 23.7249C24.0798 22.6089 22.0939 22.2991 20.2163 22.2991C18.3388 22.2991 16.3528 22.6131 14.6097 23.7249C13.7511 24.2766 12.9533 24.667 12.6758 27.0689"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M30.8869 20.4872C33.2817 20.4872 35.223 18.5873 35.223 16.2436C35.223 13.8999 33.2817 12 30.8869 12C28.4921 12 26.5508 13.8999 26.5508 16.2436C26.5508 18.5873 28.4921 20.4872 30.8869 20.4872Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M38.4325 25.2571C38.1507 22.8552 37.3571 22.4648 36.4986 21.9132C34.7555 20.7971 32.7695 20.4873 30.892 20.4873C29.0144 20.4873 27.0285 20.8013 25.2854 21.9132C24.8691 22.1763 24.4745 22.4054 24.1406 22.8383"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M9.54121 20.4872C11.936 20.4872 13.8773 18.5873 13.8773 16.2436C13.8773 13.8999 11.936 12 9.54121 12C7.14643 12 5.20508 13.8999 5.20508 16.2436C5.20508 18.5873 7.14643 20.4872 9.54121 20.4872Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M16.3743 22.9486C16.0274 22.4436 15.5938 22.2017 15.1471 21.9132C13.404 20.7971 11.4181 20.4873 9.54053 20.4873C7.66299 20.4873 5.67704 20.8013 3.93391 21.9132C3.07536 22.4648 2.27751 22.8552 2 25.2571"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
  </g>
);

IconMultiUserO.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconMultiUserO;
