import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../Redux/FormDataRedux';
import ToastRedux from '../../Redux/ToastRedux';
import QuickQuoteCalculator from './QuickQuoteCalculator';
import { QuickQuoteCircle } from './index.styles';
import { isEligible } from '../FormWithSteps';
import { loadFromParams, updateShareUrl } from './Utilities';
import { pushGoogleDataLayer } from '../FormWithSteps/Utilities';
import { asOfDate, rootRoute } from '../../Services/utilities';

const checkTheBudget = `
  <h2 class="text-white fw-bold fs-h3">
    Does Simply Retirement by
    Principal<small><sup>®</sup></small> fit your client's annual budget?
  </h2>

  <p class='text-white pt-3 fs-sm'>
    Simply Retirement by Principal<small><sup>®</sup></small> is designed
    to be affordable and straightforward.
    <strong>
    If your client is working with a third party administrator (TPA),
    they would need an estimated $2,120 their first year and $1,620 per year after that in order to offer a 401(k) plan with Simply Retirement by Principal<sup><small>®</small></sup> to their employees.
    </strong> If they’re not working with a TPA, they would need an estimated $2,600 their first year and $2,100 per year after that with bundled pricing. Our fixed flat-fee pricing won’t increase as your client’s plan grows, so it’s a predictable cost they can budget for.
  </p>

  <p class='text-white pt-3 fs-sm'>
    Business owners aren’t required to cover other costs, like the $6 per employee, per month fee or employer matching contributions. But if they want to cover these costs, they’ll need to budget additional dollars for this.
  </p>
`;

const needClarification = `
<h2 class="text-white fw-bold fs-h3">Number of employees</h2>
<p class='text-white pt-3 fs-sm'>Include full-time and part-time employees age 21 or older in your count.</p>

<h2 class="text-white fw-bold fs-h3">Average salary/year</h2>
<p class='text-white pt-3 fs-sm'>If you aren’t sure what your employees’ average salary is, don’t worry. A ballpark number is fine. This will only be used to provide a rough estimate of what your matching contributions could cost, if you choose to offer them.</p>
`;

const footNote = `
<p class='text-white pt-3 fs-sm'>
Optional costs are estimated for informational purposes only and do not
include variables such as financial professional and/or TPA fees.
</p>
<p class='text-white pt-3 fs-sm'>
*By default, the $6 per participant, per
month fee is deducted from employee plan
assets. This is simply an estimate of what
the cost could be if the business owner chose to cover
this fee on their employees’ behalf.
Custodial and investment fees are charged
against participating employees’ accounts
(those vary by investment and range from
0.03% – 0.86%, as of ${asOfDate}).
</p>
<p class='text-white pt-3 fs-sm'>
** Employers are not required to provide a matching contribution. Quote is
calculated based on all employees participating at a contribution of 5% of
pay with the maximum safe harbor employer match (employer matches the employee
contribution dollar for dollar up to 3% of pay, plus 50¢ for every $1 the
employee contributes above 3% up to 5% of pay, which totals a 4% match).
Quote is estimated for informational purposes only. Actual amount will vary
based on participation, employer and employee selections, and other variables.
</p>
`;

const howToWithTpa = `
<h2 class="text-white fw-bold fs-h3">
  How to run a proposal with a third party administrator (TPA)
</h2>
<p class='text-white pt-3 fs-sm'>
  Simply Retirement by Principal<small><sup>®</sup></small> supports working with a TPA.
</p>
<p>
  <strong>If you (the financial professional) start the proposal and indicate you’re working with a TPA,</strong>
  you’ll be prompted to share the proposal with the TPA after you complete your portion of the process. The TPA will
  finish the plan design and share the proposal back to you so you can have the business owner review it and submit the plan for purchase.
</p>
<p class='text-white pt-3 fs-sm'>
  <strong>If the TPA starts the proposal and indicates they’re working with a financial professional,</strong> they’ll share it with you to make
  the investment lineup selection. Once complete, you’ll share it back to the TPA so they can have the business owner review it and submit the plan for purchase.
</p>
`;

const QuickQuote = ({
  applicationPanelInfo,
  putApplicationPanelInfo,
  questionLabels,
  sectionLabel,
  addToast,
}) => {
  const [data, setData] = useState({
    numberOfEmployees: {
      name: 'numberOfEmployees',
      value: '',
      hasError: false,
      dirty: false,
      valueFormatted: '',
    },
    averageSalary: {
      name: 'averageSalary',
      value: '',
      hasError: false,
      dirty: false,
      valueFormatted: '',
    },
  });

  let shareURL = '';
  let gaEvent;

  if (typeof window !== 'undefined') {
    shareURL = `https://${window.location.hostname}${window.location.pathname}`;
    gaEvent = rootRoute(window.location.pathname);
  }

  const { numberOfEmployees, averageSalary } = data;

  const validEligibleEmployeeValue = (value) =>
    !isEligible(value) && value !== '';

  const [salaryInteraction, setSalaryInteraction] = useState(0);
  const [employeeInteraction, setEmployeeInteraction] = useState(0);

  const handleEmployeesChange = (name, updateValue, valueFormatted) => {
    if (employeeInteraction !== 1) {
      pushGoogleDataLayer({
        event:
          gaEvent === '/psivet'
            ? 'psivetQuickQuoteEmployee'
            : 'quickQuoteEmployee',
      });
    }
    setEmployeeInteraction(1);

    setData((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        name,
        value: updateValue,
        hasError: !isEligible(updateValue),
        dirty: true,
        valueFormatted,
      },
    }));
  };
  const [copy, setCopy] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setCopy(true);
    }, 3000);
  }, [copy]);

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(
      updateShareUrl(shareURL, numberOfEmployees.value, averageSalary.value),
    );
    setCopy(false);
    addToast('Copied to clipboard.', 'success');
  };

  const handleSalaryChange = (name, updateValue, valueFormatted) => {
    if (salaryInteraction !== 1) {
      pushGoogleDataLayer({
        event:
          gaEvent === '/psivet' ? 'psivetQuickQuoteSalary' : 'quickQuoteSalary',
      });
    }
    setSalaryInteraction(1);

    setData((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        name,
        value: updateValue,
        hasError: updateValue === '',
        dirty: true,
        valueFormatted,
      },
    }));
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadFromParams(setData, window.location.search);
    }
  }, []);
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });

  return (
    <div className="quick-quote-section bg-purple position-relative">
      <QuickQuoteCircle>
        <strong className="fs-h3 w-100">401(k) plan quick quote</strong>
      </QuickQuoteCircle>

      <div className="container">
        <h3 className="fs-h3 text-white d-md-none text-center">Quick quote</h3>

        <div className="quick-quote quick-quote__container row">
          <div className="quick-quote__col col-lg-4 mb-5">
            <div className="row">
              <div className="pr-lg-5 col-lg-12 col-md-6">
                <div
                  className={classNames('form-group intake', {
                    'has-error': numberOfEmployees.hasError,
                  })}
                >
                  <label
                    htmlFor={numberOfEmployees.name}
                    className="intake-label text-white"
                  >
                    {questionLabels.numberOfEmployees}
                    {validEligibleEmployeeValue(numberOfEmployees.value) && (
                      <span className="error-helper d-md-none text-white">
                        Number of employees must be 100 or less.
                      </span>
                    )}
                  </label>
                  <div className="intake-control">
                    <span className="intake-placeholder text-white">
                      <span className="d-md-inline-block d-none">Number</span>
                      <span className="d-md-none">#</span> of employees
                    </span>
                    <NumericFormat
                      id={numberOfEmployees.name}
                      name={numberOfEmployees.name}
                      type="text"
                      className={classNames('intake-input', {
                        'is-invalid': numberOfEmployees.hasError,
                        'is-dirty': numberOfEmployees.dirty,
                      })}
                      placeholder="XX"
                      thousandSeparator
                      decimalScale={0}
                      value={numberOfEmployees.value}
                      onValueChange={(values) => {
                        handleEmployeesChange(
                          numberOfEmployees.name,
                          values.value,
                          values.formattedValue,
                        );
                      }}
                    />
                    {validEligibleEmployeeValue(numberOfEmployees.value) && (
                      <span className="error-helper d-md-block d-none text-white">
                        Number of employees must be 99 or less.
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="pr-lg-5 col-lg-12 col-md-6">
                <div
                  className={classNames('form-group intake', {
                    'has-error': averageSalary.hasError,
                  })}
                >
                  <label
                    htmlFor="averageSalary"
                    className="intake-label text-white"
                  >
                    {questionLabels.averageSalary}
                  </label>
                  <div className="intake-control">
                    <span className="intake-placeholder text-white">
                      <span className="d-md-inline-block d-none">Average</span>
                      <span className="d-md-none">Avg.</span> salary/year
                    </span>
                    <NumericFormat
                      id="averageSalary"
                      name="averageSalary"
                      type="text"
                      className="intake-input"
                      thousandSeparator
                      decimalScale={2}
                      prefix="$"
                      placeholder="$"
                      value={averageSalary.value}
                      onValueChange={(values) => {
                        handleSalaryChange(
                          averageSalary.name,
                          values.value,
                          values.formattedValue,
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="srp-button__link d-block intake-helper text-white p-0 mx-auto"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: needClarification,
                });
              }}
            >
              Need clarification?
            </button>
          </div>
          <div className="quick-quote__col output col-lg-8">
            <QuickQuoteCalculator
              numberOfEmployees={parseInt(numberOfEmployees.value, 10)}
              averageSalary={parseInt(averageSalary.value, 10)}
              putApplicationPanelInfo={putApplicationPanelInfo}
              sectionLabel={sectionLabel}
            />
          </div>
        </div>

        <div className="text-center pb-4">
          <button
            type="button"
            className="srp-button p-0 text-white"
            onClick={copyToClipBoard}
          >
            <i className="fas fa-link" aria-hidden /> Share this link with
            others{' '}
          </button>{' '}
          <span className="fs-sm text-white">{!copy && '(Copied)'}</span>
          <p className="text-white">
            <a
              href={updateShareUrl(
                shareURL,
                numberOfEmployees.value,
                averageSalary.value,
              )}
              className="text-white"
              target="_blank"
            >
              {updateShareUrl(
                shareURL,
                numberOfEmployees.value,
                averageSalary.value,
              )}
            </a>
          </p>
        </div>
        <p className="text-center">
          <NavLink
            className="mb-3 mx-md-2 mx-1 px-md-5 srp-button srp-button__primary fs-p"
            to="/plans"
          >
            Create a proposal
          </NavLink>
          <button
            type="button"
            className="mb-3 mx-md-2 mx-1 px-md-5 srp-button srp-button__primary fs-p"
            onClick={() => {
              putApplicationPanelInfo({
                hasVisibility: true,
                additionalInformation: checkTheBudget,
              });
            }}
          >
            Help your client budget
          </button>
        </p>
        <div className="text-center mb-4">
          <button
            type="button"
            className="text-white text-underline no-border-button"
            onClick={() => {
              putApplicationPanelInfo({
                hasVisibility: true,
                additionalInformation: howToWithTpa,
              });
            }}
          >
            How to run a proposal with a third party administrator (TPA)
          </button>
        </div>
        <p className="text-center pb-5">
          <button
            type="button"
            style={{
              padding: 0,
              margin: 0,
              backgroundColor: '#E6E7E8',
              width: '30px',
              height: '30px',
              borderRadius: '100%',
              border: 'none',
              color: '#002855',
              fontWeight: 'bold',
              fontSize: '18px',
              lineHeight: '30px',
              display: 'inline-block',
            }}
            onClick={() => {
              putApplicationPanelInfo({
                hasVisibility: true,
                additionalInformation: footNote,
              });
            }}
          >
            ?
          </button>
        </p>
      </div>
      <p className="fs-disclaimer text-white text-center pb-5">
        Intended for financial professional, TPA, and plan sponsor use.
      </p>
    </div>
  );
};

QuickQuote.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  questionLabels: PropTypes.object.isRequired,
  sectionLabel: PropTypes.object.isRequired,
  addToast: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});
const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  addToast: (text, toastType) => {
    dispatch(ToastRedux.addToast(text, toastType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickQuote);
