import API from '../../api';
import {
  advisorSalesforceDataPack,
  getAutoEnrollmentPlan,
  getDeferralTypeAndPercentage,
  getEligibility,
  getFundList,
  getPlanEntryFrequency,
  getProfitMatchVesting,
  getReferrer,
  getSafeHarbor,
  tpaSalesforceDataPack,
} from './salesforceHelperUtilities';
import { TRANSFER_PLAN_VERIFICATION_CODE } from '../../../Redux/FormDataRedux/InitialState/PlanDesignInitialState';
import { sourcesGrandfatherDate } from '../Paradigm/ParadigmUtilities';

const api = API.create();

export const salesforceHelper = (
  formData,
  stageFlag,
  link,
  advisorCvStatuses = [], // eslint-disable-line default-param-last
  advisor,
  role,
  tpaParadigmPackager = [],
  hashId = formData.hashId,
  isOneDigital = false,
) => {
  const {
    companyName: { value: company },
    serviceEligibility: { value: serviceEligibility },
    employerPaidParticipantFees,
    numberOfEmployees,
    employerStateCode,
    hasFp,
    zipCode,
    sponsorEmail,
    sponsorTitle,
    companyPhone,
    sponsorEmployerIdentificationNumber,
    sponsorFirstName,
    sponsorLastName,
    investmentLineup,
    hasTpa,
    autoDeferralType,
    safeHarbor,
    stretchSafeHarbor,
    hardshipWithdrawals: { value: defaultFlowHardships },
    autoEnrollment,
    vestingSchedule,
    employerMatch,
    isCurrentPrincipalPlan,
    transferPlanInputVerificationCode,
  } = formData.planDesign.questions;
  const {
    allowLoans = { value: '', valueFormatted: '' },
    hasDiscretionaryEmployerMatch = { value: '', valueFormatted: '' },
    discretionarySharingContributions = { value: '', valueFormatted: '' },
    offerRothContributions = { value: '', valueFormatted: '' },
    entryDatesPlanParticipation = { value: '', valueFormatted: '' },
    vestingScheduleProfit = { value: '', valueFormatted: '' },
    hardshipWithdrawals = { value: '', valueFormatted: '' },
  } = tpaParadigmPackager;

  const { planOriginator } = formData;
  const {
    advisorPhone: { value: advisorPhone },
    advisorStateCode: { value: advisorStateCode },
    advisorZipCode: { value: zip },
  } = advisor.questions;

  const {
    tpaFirstName: { value: tpaFirstName },
    tpaLastName: { value: tpaLastName },
    tpaPhone: { value: tpaPhone },
  } = formData.planDesign.tpas[0].questions;

  const isTransferPlan =
    isCurrentPrincipalPlan.value === 'yes' &&
    transferPlanInputVerificationCode.value === TRANSFER_PLAN_VERIFICATION_CODE;
  const purchaseWithAdvisor = hasFp.value && stageFlag === 'Purchase';
  const isCredentialed = advisorCvStatuses.every((status) => status === 'PASS');
  const credentialedAdvisorStatus = () => {
    if (isOneDigital) {
      return false;
    }
    if (purchaseWithAdvisor && isCredentialed) {
      return true;
    }
    return '';
  };

  const planEffectiveDate = hasTpa.value
    ? tpaParadigmPackager.planEffectiveDate?.value
    : formData.planDesign.questions.planEffectiveDate.value;

  const grandfatherDate = sourcesGrandfatherDate(
    formData.planDesign.questions,
    tpaParadigmPackager,
  );

  const planSalesforceData = {
    X3_38__c: isOneDigital ? 'OneDigital' : 'Wilshire',
    Auto_Enrollment__c: getAutoEnrollmentPlan(formData, tpaParadigmPackager),
    Brokerage_Accounts_PCRA__c: 'No',
    Client_Company_Name__c: company,
    Company_Contact_First_Name__c: sponsorFirstName.value,
    Company_Contact_Last_Name__c: sponsorLastName.value,
    Company_Email__c: sponsorEmail.value,
    Company_EIN__c: sponsorEmployerIdentificationNumber.value,
    Contact_Job_Title__c: sponsorTitle.value,
    Contact_Phone_Number__c: companyPhone.value,
    Company_State__c: employerStateCode.value,
    Company_Zip_Postal_Code__c: zipCode.value || '',
    Credentialed_Advisor__c: credentialedAdvisorStatus(),
    Custodian__c: 'MG Trust Company, LLC.',
    Deferral_Change_Frequency__c: 'Per Pay Period',
    Discretionary_Match__c: hasDiscretionaryEmployerMatch.value,
    Discretionary_Match_Formula_Notes__c: employerMatch.dirty
      ? employerMatch.valueFormatted
      : 'None',
    ...(grandfatherDate !== '' && { Grandfather_Date__c: grandfatherDate }),
    Hardships__c: hasTpa.value
      ? hardshipWithdrawals.value
      : defaultFlowHardships,
    Loans__c: hasTpa.value ? allowLoans.value : 'yes',
    Number_of_Employees__c: numberOfEmployees.value,
    Participant_Fee_Payment__c:
      employerPaidParticipantFees.value === 'true' ? 'Employer' : 'Employee',
    Plan_Effective_Date__c: planEffectiveDate === '' ? null : planEffectiveDate,
    // this next line eligibility is mispelled on api field
    Plan_Eligibility__c: getEligibility(serviceEligibility),
    Plan_Entry_Frequency__c:
      getPlanEntryFrequency(entryDatesPlanParticipation) || '',
    Plan_ID__c: link || '',
    Plan_Type__c: isTransferPlan ? 'Conversion Plan' : 'New Plan',
    Principal_ID__c: null,
    Product_Type__c: '401(k)',
    Profit_Sharing__c: 'Pro rata',
    Profit_Sharing_Match_Vesting_Schedule__c: getProfitMatchVesting(
      hasTpa,
      discretionarySharingContributions,
      vestingScheduleProfit,
      safeHarbor,
      vestingSchedule,
    ),
    Referrer_Email__c: getReferrer(formData, 'email'),
    Referrer_Type__c: getReferrer(formData, 'type'),
    Roth_Contributions__c: offerRothContributions?.value || '',
    Safe_Harbor_Match_Determination_Period__c: 'Annual',
    Stage_Flag__c: stageFlag,
  };

  if (isOneDigital) {
    planSalesforceData.Fund_List__c = 'Simply OneDigital Fund Lineup 1';
  } else if (investmentLineup.dirty) {
    planSalesforceData.Fund_List__c = getFundList(investmentLineup);
  } else if (stageFlag !== 'New') {
    planSalesforceData.Fund_List__c = 'Simply Fund Lineup 1';
  }

  if (!hasTpa.value) {
    planSalesforceData.Eligibility_Age__c = '21';
    if (stageFlag !== 'New') {
      if (autoEnrollment.value === 'yes') {
        planSalesforceData.Auto_Enrollment_Deferral_Escalation_Rate__c =
          getDeferralTypeAndPercentage(
            autoDeferralType,
            formData.planDesign.questions.minimumAutoDeferralPercentage,
          );
      }
      planSalesforceData.Safe_Harbor__c =
        safeHarbor.value === 'safeHarbor'
          ? getSafeHarbor(stretchSafeHarbor)
          : 'None';
    }
  }

  const advisorSalesforceData = {
    ...planSalesforceData,
    ...advisorSalesforceDataPack(stageFlag, formData),
    ...tpaSalesforceDataPack(stageFlag, formData, tpaParadigmPackager),
    state: advisorStateCode,
    lead_type: role,
    phone: advisorPhone,
    zip,
    email: planOriginator.email,
    hashId,
  };

  const tpaSalesforceData = {
    ...planSalesforceData,
    ...advisorSalesforceDataPack(stageFlag, formData),
    ...tpaSalesforceDataPack(stageFlag, formData, tpaParadigmPackager),
    first_name: tpaFirstName,
    last_name: tpaLastName,
    phone: tpaPhone,
    email: planOriginator.email,
    hashId,
  };

  return role === 'tpa' ? tpaSalesforceData : advisorSalesforceData;
};

export const createSalesforceProposal = async ({
  formData,
  authenticityToken,
  stageFlag,
  link,
  advisorCvStatuses,
  tpaParadigmPackager = [],
  hashId,
  isOneDigital = false,
}) => {
  const primaryAdvisor = formData.planDesign.advisors[0];
  let paradigmPackager;
  if (formData.role === 'advisor' && tpaParadigmPackager.length === 0) {
    paradigmPackager = formData.planDesign.questions;
  } else {
    paradigmPackager = tpaParadigmPackager;
  }
  const salesforceData = salesforceHelper(
    formData,
    stageFlag,
    link,
    advisorCvStatuses,
    primaryAdvisor,
    formData.role,
    paradigmPackager,
    hashId,
    isOneDigital,
  );
  if (salesforceData.email !== undefined && salesforceData.email !== '') {
    await api.createSalesforceProposal({
      ...salesforceData,
      authenticity_token: authenticityToken,
    });
  }
};
