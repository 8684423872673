import { createReducer, createActions } from 'reduxsauce';
import {
  putActiveStep,
  putQuestionById,
  updateQuestion,
  updateTPA,
  addTpa,
  updateAdvisor,
  updateAllAdvisors,
  addAdvisor,
  removeAdvisor,
  removeExtraAdvisors,
  putFormInReview,
  putRole,
  setAdvisorCvDataAndResponse,
  setPlanOriginator,
  setCreateEmailSent,
  resetAdvisorCredential,
  putBrokerType,
  putInsuranceAgentCompType,
  putInsuranceAgent,
  putInsuranceAgentNpn,
  putEid,
  putHierarchies,
  putBrokerEid,
  putCrd,
  putApplicationPanelInfo,
  putPlanURL,
  putPurchasePlanURL,
  putApplicationState,
  putExperience,
  resetData,
  resetEmployerData,
  resetAdvisorData,
  resetUnusedContributionOptions,
  resetUnusedEnrollmentOptions,
  resetPlanQuestion,
  updateRequiredQuestion,
  resetUnusedNoneAltOptions,
  resetUnusedNoneAltTwoOptions,
  resetUnusedBacaAltOptions,
  resetUnusedBacaAltTwoOptions,
  resetUnusedBqacaAltOptions,
  resetUnusedBqacaAltTwoOptions,
  resetUnusedAutoEnrollment,
  resetUnusedContributionMatch,
  resetUnusedSafeHarbor,
  putOneDigitalAdvisor,
  setAsPrimary,
} from './Reducers';

import INITIAL_STATE from './InitialState';

export {
  selectFormStat,
  selectPlanURL,
  selectHashId,
  selectPurchasePlanURL,
  selectPlanId,
  selectPlanOptions,
  selectTpaPlanOptions,
  selectContributionOptions,
  selectTpaContributionOptions,
  selectApplicationPanelInfo,
  selectRole,
  selectExperience,
  isFormValid,
  selectEmployerQuestions,
  selectAdvisorQuestions,
  selectTpaQuestions,
  selectIsFirmBroker,
  selectIsFirmBrokerSplitComp,
  selectPrimaryTPA,
  selectTpas,
  selectAdvisors,
  selectPrimaryAdvisor,
  selectCanBeAnonymous,
  selectAutoEnrolledPlan,
  selectIsAutoEnrolled,
  validateWithTpa,
  selectPlanOriginator,
  selectActiveUserEmail,
  selectQuestion,
  selectIsAdvisorIa,
} from './Selectors';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  putActiveStep: ['formId', 'updateStep'],
  putQuestionById: [
    'formId',
    'questionId',
    'value',
    'hasError',
    'dirty',
    'valueFormatted',
    'disabled',
  ],
  updateQuestion: ['obj'],
  putFormInReview: ['formId', 'formInReview'],
  putEid: ['obj', 'index'],
  putHierarchies: ['obj', 'index'],
  resetAdvisorCredential: ['obj', 'index'],
  putPlanURL: ['planURL', 'hashId'],
  putPurchasePlanURL: ['purchasePlanURL', 'planId'],
  putRole: ['userRole'],
  putExperience: ['experience'],
  setPlanOriginator: ['planOriginator'],
  setCreateEmailSent: [],
  putApplicationState: ['localData'],
  putInsuranceAgent: ['obj', 'index'],
  putInsuranceAgentNpn: ['obj', 'index'],
  putBrokerType: ['obj', 'index'],
  putInsuranceAgentCompType: ['obj', 'index'],
  putBrokerEid: ['obj', 'index'],
  putCrd: ['obj', 'index'],
  putApplicationPanelInfo: ['obj'],
  resetData: ['formId'],
  resetEmployerData: [],
  resetAdvisorData: [],
  updateTPA: ['obj', 'index'],
  addTpa: [],
  updateAdvisor: ['obj', 'index'],
  setAdvisorCvDataAndResponse: ['obj', 'index'],
  updateAllAdvisors: ['obj'],
  addAdvisor: [],
  removeAdvisor: ['index'],
  removeExtraAdvisors: [],
  resetUnusedContributionOptions: [],
  resetUnusedEnrollmentOptions: ['enrollment', 'enrollmentPlan'],
  resetPlanQuestion: ['questionId', 'customAttr'],
  updateRequiredQuestion: ['questionId', 'required'],
  resetUnusedNoneAltOptions: ['value'],
  resetUnusedNoneAltTwoOptions: ['value'],
  resetUnusedBacaAltOptions: ['value'],
  resetUnusedBacaAltTwoOptions: ['value'],
  resetUnusedBqacaAltOptions: ['value'],
  resetUnusedBqacaAltTwoOptions: ['value'],
  resetUnusedAutoEnrollment: ['value'],
  resetUnusedContributionMatch: ['value'],
  resetUnusedSafeHarbor: ['value'],
  putOneDigitalAdvisor: [],
  setAsPrimary: ['advisorIndex'],
});

export const FormTypes = Types;
export default Creators;

/* ------------- Hookup Reducers To Types ------------- */

export const formDataReducer = createReducer(INITIAL_STATE, {
  [Types.PUT_ACTIVE_STEP]: putActiveStep,
  [Types.PUT_FORM_IN_REVIEW]: putFormInReview,
  [Types.PUT_QUESTION_BY_ID]: putQuestionById,
  [Types.UPDATE_QUESTION]: updateQuestion,
  [Types.PUT_ROLE]: putRole,
  [Types.PUT_EXPERIENCE]: putExperience,
  [Types.SET_PLAN_ORIGINATOR]: setPlanOriginator,
  [Types.SET_CREATE_EMAIL_SENT]: setCreateEmailSent,
  [Types.PUT_EID]: putEid,
  [Types.PUT_HIERARCHIES]: putHierarchies,
  [Types.RESET_ADVISOR_CREDENTIAL]: resetAdvisorCredential,
  [Types.PUT_INSURANCE_AGENT]: putInsuranceAgent,
  [Types.PUT_INSURANCE_AGENT_NPN]: putInsuranceAgentNpn,
  [Types.PUT_BROKER_TYPE]: putBrokerType,
  [Types.PUT_INSURANCE_AGENT_COMP_TYPE]: putInsuranceAgentCompType,
  [Types.PUT_BROKER_EID]: putBrokerEid,
  [Types.PUT_CRD]: putCrd,
  [Types.PUT_PLAN_URL]: putPlanURL,
  [Types.PUT_PURCHASE_PLAN_URL]: putPurchasePlanURL,
  [Types.PUT_APPLICATION_STATE]: putApplicationState,
  [Types.PUT_APPLICATION_PANEL_INFO]: putApplicationPanelInfo,
  [Types.RESET_DATA]: resetData,
  [Types.RESET_EMPLOYER_DATA]: resetEmployerData,
  [Types.RESET_ADVISOR_DATA]: resetAdvisorData,
  [Types.UPDATE_TPA]: updateTPA,
  [Types.ADD_TPA]: addTpa,
  [Types.UPDATE_ADVISOR]: updateAdvisor,
  [Types.UPDATE_ALL_ADVISORS]: updateAllAdvisors,
  [Types.ADD_ADVISOR]: addAdvisor,
  [Types.REMOVE_ADVISOR]: removeAdvisor,
  [Types.REMOVE_EXTRA_ADVISORS]: removeExtraAdvisors,
  [Types.SET_ADVISOR_CV_DATA_AND_RESPONSE]: setAdvisorCvDataAndResponse,
  [Types.RESET_UNUSED_CONTRIBUTION_OPTIONS]: resetUnusedContributionOptions,
  [Types.RESET_UNUSED_ENROLLMENT_OPTIONS]: resetUnusedEnrollmentOptions,
  [Types.RESET_PLAN_QUESTION]: resetPlanQuestion,
  [Types.UPDATE_REQUIRED_QUESTION]: updateRequiredQuestion,
  [Types.RESET_UNUSED_NONE_ALT_OPTIONS]: resetUnusedNoneAltOptions,
  [Types.RESET_UNUSED_NONE_ALT_TWO_OPTIONS]: resetUnusedNoneAltTwoOptions,
  [Types.RESET_UNUSED_BACA_ALT_OPTIONS]: resetUnusedBacaAltOptions,
  [Types.RESET_UNUSED_BACA_ALT_TWO_OPTIONS]: resetUnusedBacaAltTwoOptions,
  [Types.RESET_UNUSED_BQACA_ALT_OPTIONS]: resetUnusedBqacaAltOptions,
  [Types.RESET_UNUSED_BQACA_ALT_TWO_OPTIONS]: resetUnusedBqacaAltTwoOptions,
  [Types.RESET_UNUSED_AUTO_ENROLLMENT]: resetUnusedAutoEnrollment,
  [Types.RESET_UNUSED_CONTRIBUTION_MATCH]: resetUnusedContributionMatch,
  [Types.RESET_UNUSED_SAFE_HARBOR]: resetUnusedSafeHarbor,
  [Types.PUT_ONE_DIGITAL_ADVISOR]: putOneDigitalAdvisor,
  [Types.SET_AS_PRIMARY]: setAsPrimary,
});
