import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectQuestion,
  selectPrimaryAdvisor,
} from '../../../../../Redux/FormDataRedux';
import AdvisorAuthHeader from '../../AdvisorAuthHeader';
import {
  Question,
  NextStepBtn,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../../common/FormWithSteps';
import {
  pushGoogleDataLayer,
  restrictedStateInsuranceAgent,
  checkForErrors,
} from '../../../../../common/FormWithSteps/Utilities';
import AdvisorBrokerDealerSelect from '../../AdvisorBrokerDealerSelect';
import {
  initFirmSelect,
  initInsuranceAgent,
  initInsuranceAgentNpn,
  initInsAgentCompType,
} from './firmInfoQuestions';
import AdvisorFirmBrokerNotice from '../../AdvisorFirmBrokerNotice';

export const FirmInfo = ({
  role,
  active,
  destination,
  previousStep,
  updateActiveStep,
  googleData,
  advisorFirmBroker,
  employerStateCode,
  primaryAdvisor,
  putBrokerType,
  putInsuranceAgentCompType,
  putInsuranceAgent,
  putInsuranceAgentNpn,
  resetAdvisorData,
  history,
  putApplicationPanelInfo,
  advisorCompensation,
}) => {
  const {
    advisorCredentialVerification: {
      brokerType = '',
      crd = '',
      insuranceAgent = '',
      insuranceAgentNpn = '',
      insuranceAgentCompType = '',
    },
  } = primaryAdvisor;
  const [firmSelect, setFirmSelect] = useState(initFirmSelect);
  const [insAgentQuestion, setInsuranceAgent] = useState({
    ...initInsuranceAgent,
    value: insuranceAgent,
    dirty: !!insuranceAgent,
  });
  const [insAgentQuestionNpn, setInsuranceAgentNpn] = useState({
    ...initInsuranceAgentNpn,
    value: insuranceAgentNpn,
    dirty: !!insuranceAgentNpn,
    hasError: checkForErrors(initInsuranceAgentNpn.name, insuranceAgentNpn, {
      advisorCompensation,
      employerStateCode,
    }),
  });
  const [insAgentQuestionCompType, setInsAgentQuestionCompType] = useState({
    ...initInsAgentCompType,
    value: insuranceAgentCompType,
    dirty: !!insuranceAgentCompType,
  });
  const validateAdvisorQuestions = [
    firmSelect,
    ...(brokerType === 'brokerDealer' || brokerType === 'iarRia'
      ? [advisorFirmBroker]
      : [insAgentQuestion, insAgentQuestionNpn, insAgentQuestionCompType]),
  ];

  const showIaFirmAndNPN =
    insuranceAgentCompType !== 'direct' && insuranceAgentCompType !== '';

  function handleFirmSelect(updateValue, valueFormatted) {
    resetAdvisorData();
    setInsuranceAgent({ ...initInsuranceAgent });
    setInsuranceAgentNpn({ ...initInsuranceAgentNpn });
    setInsAgentQuestionCompType({ ...initInsAgentCompType });
    setFirmSelect({
      ...firmSelect,
      value: updateValue,
      valueFormatted,
      dirty: true,
    });
    putBrokerType({ brokerType: updateValue }, 0);
  }

  function handleInsAgentCompType(updateValue, valueFormatted) {
    setInsAgentQuestionCompType({
      ...insAgentQuestionCompType,
      value: updateValue,
      valueFormatted,
      dirty: true,
      hasError: updateValue === '',
    });
    putInsuranceAgentCompType({ insuranceAgentCompType: updateValue }, 0);
    if (updateValue === 'direct') {
      setInsuranceAgent({
        ...insAgentQuestion,
        value: '',
        valueFormatted: '',
        dirty: true,
        hasError: false,
      });
      putInsuranceAgent({ insuranceAgent: '' }, 0);
      setInsuranceAgentNpn({
        ...insAgentQuestionNpn,
        value: '',
        valueFormatted: '',
        dirty: true,
        hasError: false,
      });
      putInsuranceAgentNpn({ insuranceAgentNpn: '' }, 0);
    } else {
      setInsuranceAgent({
        ...insAgentQuestion,
        dirty: false,
      });
      putInsuranceAgent({ insuranceAgent: '' }, 0);
      setInsuranceAgentNpn({
        ...insAgentQuestionNpn,
        dirty: false,
      });
      putInsuranceAgentNpn({ insuranceAgentNpn: '' }, 0);
    }
  }

  function handleInsAgent(updateValue, valueFormatted) {
    setInsuranceAgent({
      ...insAgentQuestion,
      value: updateValue,
      valueFormatted,
      dirty: true,
      hasError: updateValue === '',
    });
    putInsuranceAgent({ insuranceAgent: updateValue }, 0);
  }

  function handleInsAgentNpn(updateValue, valueFormatted) {
    setInsuranceAgentNpn({
      ...insAgentQuestionNpn,
      value: updateValue,
      valueFormatted,
      dirty: true,
      hasError: checkForErrors(insAgentQuestionNpn.name, updateValue, {
        advisorCompensation,
        employerStateCode,
      }),
    });
    putInsuranceAgentNpn({ insuranceAgentNpn: updateValue }, 0);
  }

  const nextStepAction = () => {
    pushGoogleDataLayer({
      event: 'PlanDesignQuestion',
      PlanDesignAdvisorBrokerDealer: advisorFirmBroker.valueFormatted,
    });
    if (brokerType === 'insuranceAgent') {
      if (restrictedStateInsuranceAgent(employerStateCode.value)) {
        history.push({
          pathname: '/restricted-insurance-agent',
        });
        return true;
      }
      updateActiveStep(destination(), 'forward');
      return true;
    }
    if (advisorFirmBroker.value.compensation === 'none') {
      updateActiveStep('firmBrokerNotAllowed', 'forward');
      return true;
    }
    updateActiveStep(destination(), 'forward');
    return true;
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
    setFirmSelect({
      ...firmSelect,
      value: brokerType || 'select',
      dirty: !!brokerType,
    });
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <AdvisorAuthHeader step={3} />
      <div className="row">
        <div className="col-lg-6 mx-auto">
          <fieldset id="firmInfo">
            <Question
              role={role}
              type="select"
              labelClass="fs-form-q fw-bold text-center mb-4"
              inputClass="srp-form-input form-control form-select"
              question={firmSelect}
              handleQuestionChange={(_id, updateValue, valueFormatted) => {
                handleFirmSelect(updateValue, valueFormatted);
              }}
            >
              {firmSelect.hasError && (
                <p className="text-danger text-center">Question is required</p>
              )}
            </Question>
            {brokerType === 'insuranceAgent' && (
              <div className="row">
                <div className="col">
                  <Question
                    type={insAgentQuestionCompType.type}
                    question={insAgentQuestionCompType}
                    radioWrapper="srp-form-toggle"
                    labelClass="fs-h4 fw-bold text-center"
                    handleQuestionChange={(
                      _id,
                      updateValue,
                      valueFormatted,
                    ) => {
                      handleInsAgentCompType(updateValue, valueFormatted);
                    }}
                  >
                    {insAgentQuestionCompType.disabled && (
                      <p className="text-center text-muted my-3">
                        Question is required
                      </p>
                    )}
                  </Question>
                </div>
              </div>
            )}{' '}
            <div className="row">
              <div className="col-md-6">
                {(brokerType === 'brokerDealer' || brokerType === 'iarRia') && (
                  <AdvisorBrokerDealerSelect />
                )}
                {brokerType === 'insuranceAgent' && showIaFirmAndNPN && (
                  <Question
                    role={role}
                    type="text_field"
                    labelClass="fs-form-label fw-bold text-center"
                    inputClass="srp-form-input form-control"
                    question={insAgentQuestion}
                    handleQuestionChange={(
                      _id,
                      updateValue,
                      valueFormatted,
                    ) => {
                      handleInsAgent(updateValue, valueFormatted);
                    }}
                  >
                    {insAgentQuestion.hasError && (
                      <p className="text-danger text-center">
                        Question is required
                      </p>
                    )}
                    <button
                      type="button"
                      className="srp-button__link"
                      onClick={() =>
                        putApplicationPanelInfo({
                          hasVisibility: true,
                          heading: 'Need clarification?',
                          additionalInformation:
                            'Please provide the legal business name of the insurance agency to distribute compensation to. The legal name is the formal, official name that identifies the agency with the government (for example, on the agency’s tax ID paperwork and tax returns).',
                        })
                      }
                    >
                      Need clarification?
                    </button>
                  </Question>
                )}
              </div>
              <div className="col-md-6">
                {(brokerType === 'brokerDealer' || brokerType === 'iarRia') && (
                  <div>
                    <span className="fs-form-label fw-bold text-center">
                      {brokerType === 'brokerDealer' && 'Broker-dealer CRD'}
                      {brokerType === 'iarRia' && 'RIA CRD'}
                    </span>
                    <input
                      value={crd.value}
                      readOnly
                      disabled
                      className="srp-form-input form-control"
                    />
                  </div>
                )}
                {brokerType === 'insuranceAgent' && showIaFirmAndNPN && (
                  <Question
                    role={role}
                    type={insAgentQuestionNpn.type}
                    labelClass="fs-form-label fw-bold text-center"
                    inputClass="srp-form-input form-control"
                    question={insAgentQuestionNpn}
                    minLength={4}
                    maxLength={10}
                    handleQuestionChange={(
                      _id,
                      updateValue,
                      valueFormatted,
                    ) => {
                      handleInsAgentNpn(updateValue, valueFormatted);
                    }}
                  >
                    {insAgentQuestionNpn.hasError && (
                      <p className="text-danger text-center">
                        Question is required
                      </p>
                    )}
                  </Question>
                )}
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mx-auto">
          <AdvisorFirmBrokerNotice advisorFirmBroker={advisorFirmBroker} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(validateAdvisorQuestions)}
              onClick={() => nextStepAction()}
            />
          </div>
        </div>
      </div>

      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

FirmInfo.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  destination: PropTypes.any,
  previousStep: PropTypes.string,
  updateActiveStep: PropTypes.func,
  googleData: PropTypes.object,
  advisorFirmBroker: PropTypes.object.isRequired,
  employerStateCode: PropTypes.object.isRequired,
  primaryAdvisor: PropTypes.object.isRequired,
  putBrokerType: PropTypes.func.isRequired,
  putInsuranceAgentCompType: PropTypes.func.isRequired,
  putInsuranceAgent: PropTypes.func.isRequired,
  putInsuranceAgentNpn: PropTypes.func.isRequired,
  resetAdvisorData: PropTypes.func.isRequired,
  history: PropTypes.object,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  advisorCompensation: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  advisorFirmBroker: selectQuestion(store, 'advisorFirmBroker'),
  advisorCompensation: selectQuestion(store, 'advisorCompensation'),
  employerStateCode: selectQuestion(store, 'employerStateCode'),
  primaryAdvisor: selectPrimaryAdvisor(store),
});

const mapDispatchToProps = (dispatch) => ({
  putBrokerType: (obj, index) => {
    dispatch(FormDataRedux.putBrokerType(obj, index));
  },
  putInsuranceAgentCompType: (obj, index) => {
    dispatch(FormDataRedux.putInsuranceAgentCompType(obj, index));
  },
  putInsuranceAgent: (obj, index) => {
    dispatch(FormDataRedux.putInsuranceAgent(obj, index));
  },
  putInsuranceAgentNpn: (obj, index) => {
    dispatch(FormDataRedux.putInsuranceAgentNpn(obj, index));
  },
  resetAdvisorData: (obj, index) => {
    dispatch(FormDataRedux.resetAdvisorData(obj, index));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FirmInfo);
