import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const AppRoute = ({
  component: Component,
  layout: Layout,
  authenticityToken,
  hashId,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout
        {...rest}
        title={rest.title}
        description={rest.description}
        paradigmHost={rest.paradigmHost}
      >
        <Component
          {...props}
          authenticityToken={authenticityToken}
          hashId={hashId}
          paradigmHost={rest.paradigmHost}
        />
      </Layout>
    )}
  />
);

AppRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  authenticityToken: PropTypes.string,
  hashId: PropTypes.string,
};

export default AppRoute;
