import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { selectPrimaryAdvisor } from '../../../../Redux/FormDataRedux';
import InfoPanel from '../../../../common/FormWithSteps/WithInfoPanel/InfoPanel';
import WithInfoPanelMobile from '../../../../common/FormWithSteps/WithInfoPanel/WithInfoPanelMobile';
import { NextStepBtn, PrevStepBtn } from '../../../../common/FormWithSteps';
import AdvisorAuthHeader from '../AdvisorAuthHeader';
import { pushGoogleDataLayer } from '../../../../common/FormWithSteps/Utilities';

const NoNpnBilling = ({
  active,
  updateQuestionById,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  primaryAdvisor,
}) => {
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };
  const heading = 'About compensation and billing';
  const content = `<p>Simply Retirement by Principal<sup>®</sup> offers investments (separate accounts) made available through a group annuity
                    contract. If you or your firm are compensated from plan assets, you can choose a flat fee (not to exceed 50bp
                    annually), or 25, 50 or 75 basis points annually based on assets under management*. Basis points compensation
                    options are based on approved firm-level rules of engagement.</p>`;
  return (
    <div className="container-fluid--sitewidth h-100">
      <AdvisorAuthHeader step={3} />
      <div className="row">
        <div
          className={classNames({
            'col-md-10 mx-auto': !visibleInfoPanel,
            'col-md-7': visibleInfoPanel,
          })}
        >
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row">
                <div className="col-md">
                  <h3 className="fs-h2 text-center fw-bold">
                    You must collect compensation outside the plan.
                  </h3>
                  <p className="fs-p text-center mt-3">
                    Because you did not enter a National Producer Number (NPN),
                    you must collect compensation from your client by billing
                    them separately. If you would like to have your compensation
                    deducted from participant accounts, you must enter your NPN.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center my-4">
            <button
              type="button"
              className="py-2 px-4 bg-white"
              style={{ borderRadius: '40px', border: '2px solid #BABABA' }}
              onClick={() => updateActiveStep(previousStep, 'reverse')}
            >
              I’d like to enter my NPN
            </button>
          </div>

          <p className="fs-p text-center">
            <button
              type="button"
              className="srp-button srp-button__link"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Compensation details
            </button>
          </p>
        </div>
        {visibleInfoPanel && (
          <div
            className={classNames('fws__info-panel d-md-block d-none', {
              'col slideInRight animated': visibleInfoPanel === true,
            })}
            style={{
              animationDuration: '.2s',
              animationDelay: '0',
              paddingLeft: '45px',
            }}
          >
            <InfoPanel
              heading={heading}
              content={content}
              toggleInfoPanel={setVisibility}
            />
          </div>
        )}
      </div>
      <div className="form-group fws__control">
        <NextStepBtn
          onClick={() => {
            if (primaryAdvisor.questions.advisorNpn.value === '') {
              updateQuestionById(
                questions.advisorCompensation.name,
                questions.advisorCompensation.options[0].value,
                false,
                false,
                questions.advisorCompensation.options[0].label,
              );
            }

            updateActiveStep(destination(), 'forward');
            pushGoogleDataLayer({
              event: 'PlanDesignQuestion',
              PlanDesignAdvisorCompensation:
                questions.advisorCompensation.valueFormatted,
            });
          }}
        />
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
      <WithInfoPanelMobile
        heading={heading}
        content={content}
        visibleInfoPanel={visibleInfoPanel}
        toggleInfoPanel={toggleInfoPanel}
        setVisibility={setVisibility}
      />
    </div>
  );
};

NoNpnBilling.propTypes = {
  active: PropTypes.bool,
  destination: PropTypes.func,
  updateQuestionById: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  primaryAdvisor: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  primaryAdvisor: selectPrimaryAdvisor(store),
});

export default connect(mapStateToProps)(NoNpnBilling);
