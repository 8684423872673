import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, { selectRole } from '../../Redux/FormDataRedux';

export const Controls = ({
  setDefaultTab,
  togglePlanOutLine,
  notificationIndicator,
  setNotificationIndicator,
}) => (
  <div className="fws__controls">
    <button
      type="button"
      className="control-btn"
      onClick={() => {
        setDefaultTab('reviewSelections');
        togglePlanOutLine(true);
      }}
    >
      Your Selections
    </button>
    <button
      type="button"
      className="control-btn position-relative"
      onClick={() => {
        setDefaultTab('estimator');
        togglePlanOutLine(true);
        setNotificationIndicator(false);
      }}
    >
      {notificationIndicator && (
        <div
          className="d-inline-block flash"
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: '#4caac6',
            animationDuration: '3s',
            borderBottom: 'none',
            marginRight: '10px',
          }}
        ></div>
      )}
      Cost Estimator
    </button>
  </div>
);

Controls.propTypes = {
  setDefaultTab: PropTypes.func.isRequired,
  togglePlanOutLine: PropTypes.func.isRequired,
  notificationIndicator: PropTypes.bool.isRequired,
  setNotificationIndicator: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  role: selectRole(store),
});

const mapDispatchToProps = (dispatch) => ({
  putRole: (role) => {
    dispatch(FormDataRedux.putRole(role));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
