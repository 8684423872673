import styled from 'styled-components';

export const BgImage = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 55%;
  left: 40%;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  transform: translate(-50%, -50%) scaleX(-1);
  @media (min-width: 768px) {
    top: 50%;
    left: 30%;
    width: 290px;
    height: 290px;
  }
  @media (min-width: 992px) {
    left: 25%;
    width: 300px;
    height: 300px;
  }
`;

export const Circle = styled.div`
  position: absolute;
  top: -200px;
  left: 50%;
  border-radius: 50%;
  border-top-right-radius: 0;
  justify-content: center;
  width: 400px;
  height: 400px;
  z-index: -1;
  background-color: white;
  overflow: hidden;
  @media (min-width: 768px) {
    top: 0;
    left: 60%;
    width: 575px;
    height: 575px;
  }
  @media (min-width: 992px) {
    top: -80%;
    left: 60%;
    width: 990px;
    height: 960px;
  }
`;
