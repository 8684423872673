import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  saveData,
  uuidContext,
  PrevStepBtn,
} from '../../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../../common/FormWithSteps/Utilities';
import ToastRedux from '../../../../Redux/ToastRedux';
import FormDataRedux from '../../../../Redux/FormDataRedux';
import { AppVariables } from '../../../../AppVariables';
import { sendEmail, savePlan } from './libs';
import SHARE_FORM_COPY from './copy.json';

export const StepQuestion = ({
  active,
  previousStep,
  updateActiveStep,
  googleData,
  setCreateEmailSent,
  formData,
  addToast,
  putPlanURL,
}) => {
  const uuid = React.useContext(uuidContext);
  const { authenticityToken } = React.useContext(AppVariables);
  const {
    mailTo,
    emailMessage,
    sendSuccessMessage,
    sendErrorMessage,
    saveErrorMessage,
    shareMessage,
    bccList,
  } = SHARE_FORM_COPY;
  const primaryEmail =
    process.env.RAILS_ENV !== 'production'
      ? 'britt.wells+smbod@shiftdsm.com'
      : mailTo;
  const primaryBccList =
    process.env.RAILS_ENV !== 'production'
      ? 'mackenzie.ostrem+smbod@shiftdsm.com'
      : bccList;

  const { createEmailSent } = formData;
  const { sponsorEmail } = formData.planDesign.questions;
  const { name } = formData.planOriginator;
  const [responseStatus, setResponseStatus] = React.useState('loading');

  const sendSharedPlanEmail = async () => {
    const { hashId, status, url } = await savePlan(formData, authenticityToken);

    if (status === 'success') {
      const sendEmailResponse = await sendEmail(
        authenticityToken,
        hashId,
        primaryEmail,
        name,
        emailMessage,
        sponsorEmail,
        primaryBccList,
      );
      saveData(uuid, formData);
      putPlanURL(url, hashId);

      if (sendEmailResponse.ok) {
        setCreateEmailSent();
        setResponseStatus('success');
        addToast(sendSuccessMessage, 'success');
      } else {
        addToast(sendErrorMessage, 'error');
        setResponseStatus('error');
      }
    } else {
      addToast(saveErrorMessage, 'error');
      setResponseStatus('error');
    }
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
    if (!createEmailSent) {
      sendSharedPlanEmail();
    } else {
      setResponseStatus('success');
    }
  }, []);

  return (
    <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <h3 className="fs-form-q fw-bold mb-md-5 text-center">
            {shareMessage[responseStatus].heading}
          </h3>
          <p className="text-center">{shareMessage[responseStatus].body}</p>
          <p className="text-center">
            <strong>
              <a href={`tel:${shareMessage[responseStatus].phone}`}>
                {shareMessage[responseStatus].phone}
              </a>
            </strong>
          </p>
          <p className="text-center">
            <Link
              to="/onedigital/faq"
              className="srp-button srp-button__sm-large srp-button__primary mt-3 mb-5 my-md-5"
            >
              View FAQ’s
            </Link>
          </p>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

StepQuestion.propTypes = {
  active: PropTypes.bool.isRequired,
  previousStep: PropTypes.string.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  googleData: PropTypes.object.isRequired,
  setCreateEmailSent: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  addToast: PropTypes.func.isRequired,
  putPlanURL: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
});

const mapDispatchToProps = (dispatch) => ({
  putPlanURL: (planURL, hashId) => {
    dispatch(FormDataRedux.putPlanURL(planURL, hashId));
  },
  setCreateEmailSent: () => {
    dispatch(FormDataRedux.setCreateEmailSent());
  },
  addToast: (text, toastType) => {
    dispatch(ToastRedux.addToast(text, toastType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StepQuestion);
