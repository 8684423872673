export const ROUTE_META = {
  employer: {
    role: 'employer',
    route: '/business-owners',
    googleData: { event: 'PlanDesignView', PlanDesignView: '' },
  },
  advisor: {
    role: 'advisor',
    route: '/financial-professionals',
    googleData: { event: 'PlanDesignView', PlanDesignView: '' },
  },
  tpa: {
    role: 'tpa',
    route: '/third-party-administrator',
    googleData: { event: '', PlanDesignView: '' },
  },
  psivet: {
    role: 'psivet',
    route: '/psivet',
    googleData: { event: 'PsivetPlanDesignView', PlanDesignView: '' },
  },
  onedigital: {
    role: 'onedigital',
    route: '/onedigital',
    googleData: { event: 'OneDigitalPlanDesignView', PlanDesignView: '' },
  },
};
