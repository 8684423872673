import { createContext } from 'react';
import { ROUTE_META } from '../../../RouteMeta';

export { stepTrackerObj } from './stepTrackerObj';
export { checkValidityOnQuestionArray } from './checkValidityOnQuestionArray';
export { checkAdvisorCredentialVerification } from './checkAdvisorCredentialVerification';
export { checkErrorPlanEffectiveDate } from './checkErrorPlanEffectiveDate';
export { restrictedStateInsuranceAgent } from './restrictedStateInsuranceAgent';
export { employerCanPurchasePlan } from './employerCanPurchasePlan';
export { advisorNextAction } from './advisorNextAction';
export { checkForErrors } from './checkForErrors';
export { isShareDisabled } from './isShareDisabled';
export { numberToCurrency } from './numberToCurrency';
export { onSaveToast } from './onSaveToast';
export { restrictedState } from './restrictedState';
export { tpaNextAction } from './tpaNextAction';
export { validUser } from './validUser';
export { whoToShareWith } from './whoToShareWith';
export { isEligible } from './isEligible';
export { checkNpnValue } from './checkNpnValue';

export const uuidContext = createContext('principal');
export const saveData = (uuid, data) => {
  sessionStorage.setItem(`${uuid}_PlanDesignSession`, JSON.stringify(data));
};
export const isActive = (startAtStep, name, activeStep) =>
  (activeStep || startAtStep) === name;

export const multiQuestionNextDisabled = (primaryQuestion, secondaryQuestion) =>
  primaryQuestion.value === 'yes' && !secondaryQuestion.value;

export const errorStep = (questionsArry) => {
  const error = questionsArry.find((q) => q.hasError);
  if (error) {
    return error.stepName;
  }
  return 'roleSelection';
};

export const pushGoogleDataLayer = (obj) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      ...obj,
    });
  }
};

export const roleSpecificPath = (userRole, rootRoute, path) => {
  if (
    userRole === ROUTE_META.psivet.role ||
    rootRoute === ROUTE_META.psivet.route
  ) {
    return `${ROUTE_META.psivet.route}${path}`;
  }
  if (
    userRole === ROUTE_META.onedigital.role ||
    rootRoute === ROUTE_META.onedigital.route
  ) {
    return `${ROUTE_META.onedigital.route}${path}`;
  }
  if (rootRoute === ROUTE_META.tpa.route) {
    return `${ROUTE_META.tpa.route}${path}`;
  }
  return path;
};
