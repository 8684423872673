import React from 'react';
import SimplyIconSprite from '../../../../common/SimplyIconSprite';
import Link from '../../../../common/Link';
import { SectionStyled, CornerStyled, CircleStyled } from './index.styles';

const AboutSection = () => (
  <section className="section section_py-5 pt-0 about-section">
    <div className="container-lg px-md-3 px-0">
      <SectionStyled className="px-sm-4 px-lg-5">
        <div className="row ml-lg-1 mx-0">
          <div className="col-md-7">
            <div className="row">
              <div className="col-xl-10 col-lg-12">
                <h3 className="fs-h3 mt-5 mb-3 text-md-left text-center">
                  What Simply Retirement by Principal
                  <sup>
                    <small>®</small>
                  </sup>{' '}
                  can do for you
                </h3>
              </div>
            </div>
            <p className="fs-p">
              With Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>
              , you have the flexibility to set up a plan that works for your
              small business clients without weeks of back-and-forth
              coordination. Just make selections and share your proposal with
              your client so they can complete the purchase. You can also work
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              with your clients' financial professional to select investment
              options.
            </p>
            <p className="fs-p">
              Data collection, key decisions, and electronic document signatures
              are integrated in the process—saving you time. Most of the
              information you need is gathered on day one, so you can focus on
              what comes next: helping clients manage their day-to-day
              retirement plan responsibilities.
            </p>
          </div>
          <CornerStyled className="offset-md-1 col-md-4 d-md-block d-none">
            <CircleStyled className="text-center">
              Businesses can get up to $5,000/year in tax credits
            </CircleStyled>
            <p className="fs-p text-center">
              <strong>
                Business owners can take advantage of SECURE 2.0 Act tax credits
                to help offset up to 100% (up to $5,000 per tax year for the
                first three years for some employers) of the first three years
                of their plan startup costs.
              </strong>
            </p>
            <div className="text-center">
              <p className="text-center">
                <Link to="/third-party-administrator/plans">
                  Create a proposal online now.
                </Link>
              </p>
            </div>
          </CornerStyled>
        </div>

        <div className="row mx-0">
          <div className="col-12 mx-auto">
            <div className="row">
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-lifesaver-o"
                  fill="#002855"
                  height="70"
                  alt="Lifesaver Icon"
                />
                <h5 className="fs-h5 text-purple">
                  Have more time to deliver value
                </h5>
                <p className="fs-p">
                  This streamlined solution allows you to spend less time
                  establishing plans so you can focus on providing expertise to
                  your clients.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-stop-watch-o"
                  fill="#002855"
                  height="70"
                  alt="Stopwatch Icon"
                />
                <h5 className="fs-h5 text-purple">Create proposals faster</h5>
                <p className="fs-p">
                  Here’s a simplified 401(k) plan design and onboarding process
                  with an online experience that saves you time and gives you a
                  competitive advantage.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-coins-o"
                  fill="#002855"
                  height="70"
                  alt="Coins Icon"
                />
                <h5 className="fs-h5 text-purple">
                  Help your clients save more
                </h5>{' '}
                <p className="fs-p">
                  A solution that has the same recordkeeping price for all
                  customers. Period. And that price doesn’t change as your
                  clients’ plan assets change.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-rocket-o"
                  fill="#002855"
                  height="70"
                  alt="Rocket Icon"
                />
                <h5 className="fs-h5 text-purple">
                  Get a jump-start on day-to-day tasks
                </h5>{' '}
                <p className="fs-p">
                  With Ubiquity’s online dashboard, you’ll have access to view
                  client and plan details and efficiently manage key
                  administrative tasks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </SectionStyled>
    </div>
  </section>
);

export default AboutSection;
