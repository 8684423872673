import React from 'react';
import PropTypes from 'prop-types';

const EditButton = ({ handleClick }) => (
  <button
    type="button"
    className="btn btn-sm fs-p py-0 d-print-none"
    style={{ borderRadius: '40px' }}
    onClick={handleClick}
  >
    <i className="fa-solid fa-pencil pr-2" aria-hidden />
    <strong>Edit</strong>
  </button>
);

EditButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default EditButton;
