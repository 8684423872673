import React from 'react';
import HeaderSection from '../../../common/HeaderSection';
import QuickQuote from './QuickQuote';
import { headerPlanProposal001 } from '../../../ImagePaths';
import SectionMaskTop from '../../../../Images/section-mask-top.svg';

const sectionHeader = {
  image: headerPlanProposal001,
  title: 'Start a proposal for your client',
  caption: `
    <p class="fs-display5">
      Create a fee proposal with plan costs and download a worksheet that outlines plan options for your client
    </p>
  `,
  alt: 'Employee reading article.',
  copy: ``,
};

const Resources = () => (
  <div className="resources-page print-pt-80 mb-5">
    <HeaderSection
      image={sectionHeader.image}
      title={sectionHeader.title}
      caption={sectionHeader.caption}
      copy={sectionHeader.copy}
      alt={sectionHeader.alt}
      wrapperClasses="d-print-none"
    />
    <section className="bg-white py-6">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto px-md-3">
            <p className="fs-display5 fw-bold">
              Enter a few details here and create a PDF of your Simply
              Retirement by Principal
              <small>
                <sup>®</sup>
              </small>{' '}
              401(k) fee proposal.
            </p>
            <p>
              With Simply Retirement by Principal
              <small>
                <sup>®</sup>
              </small>
              , you and your client can choose from a streamlined set of options
              to customize their plan. To create your plan proposal, complete
              the information below and click the button to create a PDF. You
              can also{' '}
              <a
                href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736S&ty=VOP"
                target="_blank"
              >
                download a worksheet
              </a>{' '}
              that outlines the available plan options and the information
              you’ll need to complete the plan design process.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section
      id="quickQuote"
      className=" section_bg-pattern section section_pb-5"
    >
      <img src={SectionMaskTop} alt="" className="section__mask-top" />
      <QuickQuote
        questionLabels={{
          numberOfEmployees: `How many people work at your client's organization?`,
          averageSalary: 'What’s the average salary per employee?',
        }}
        sectionLabel={{
          offerEmployees: 'Offer a 401(k) plan to your employees for:',
          matchContributions: 'Your matching contributions could cost:',
        }}
      />
    </section>
  </div>
);

export default Resources;
