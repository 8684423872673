import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
import SectionSingleCol from '../../../common/SectionSingleCol';
import HeaderSection from '../../../common/HeaderSection';
import PredictableNoGuessPricing from './PredictableNoGuessPricing';
import CardSection from './CardSection';
import PurpleSection from './PurpleSection';
import SectionOnlineDashboard from './SectionOnlineDashboard';
import ReducedRiskTabs from './ReducedRiskTabs';

import {
  WhyChooseUsHeaderImage,
  CheckCircle,
  IconBlueMoneyHandO,
  IconChart,
  IconCheckPurple,
  IconPayrollPurple,
  IconRiscPurple,
  IconLockVioletO,
} from '../../../ImagePaths';
import { asOfDate, investmentCost } from '../../../Services/utilities';

const sectionHeader = {
  image: WhyChooseUsHeaderImage,
  alt: 'Employees and business owners meeting at a table.',
  title: 'Why choose us?',
  caption: `
    <p class="fs-display5">
     It's an easy way to help your employees save for the future.
    </p>
  `,
  copy: `
    <p class="fs-h6">
      75% of small and midsize business (SMB) employees are interested in their employer offering additional benefits within the next <button id="whysimply-employer-disclaimer-1" class="text-underline p-0 no-border-button fs-h6">year.</button><sup><small>*</small></sup>
    </p>`,
};

const sectionInvestments = `
  <img
    src="${IconChart}"
    alt="Chart Icon"
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">
    Investments with competitive performance
  </h3>
  <p class="fs-h4 fw-normal mb-4">Simply Retirement by Principal<sup><small>®</small></sup> investment options offer attractive performance with a competitive return, helping make the most of your employees’ contributions and any matching contributions you provide as a business owner.</p>
  <p class="fs-h6"><strong>Wilshire Advisors LLC selects the investment options for the plan</strong> and your employees can pick the investments that best match their needs from the plan’s streamlined <button id="whysimply-employer-disclaimer-5" class="text-underline p-0 no-border-button fs-h6">investment lineup.</button><sup><small>*</small></sup> See the Fiduciary Responsibility tab under Reduced Risk below for more about Wilshire Advisors LLC</p>
`;

const sectionEnrollment = `
  <img
    src="${IconCheckPurple}"
    alt="Chart Icon"
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">Automatic enrollment</h3>
  <p class="fs-h4 fw-normal mb-4">The Simply Retirement by Principal<sup><small>®</small></sup> 401(k) plan requires employees to be automatically enrolled at a default pre-tax contribution percentage set by you.</p>
  <p class="fs-h6">The Simply Retirement by Principal<sup><small>®</small></sup> 401(k) plan requires employees to be automatically enrolled at a default pre-tax contribution percentage set by you. <strong>Don't worry—employees can change their contribution and investment election or opt out of the plan at any time.</strong> Automatic enrollment can help increase participation, simplify administration, reduce follow-up, and help your employees save for retirement. It may also qualify your business for a SECURE 2.0 Act tax credit of up to $500 per tax year for the first three years of your <button id="whysimply-employer-disclaimer-6" class="text-underline p-0 no-border-button fs-h6">plan</button><sup><small>*</small></sup>.</p>
  <p class="">If you&rsquo;re working with a TPA, automatic enrollment, eligibility, and vesting options may vary.</p>
`;

const sectionPayroll = `
  <img
    src="${IconPayrollPurple}"
    alt="Payroll Icon"
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">Payroll provider integration</h3>
  <p class="fs-h4 fw-normal mb-4">Ubiquity Retirement + Savings<sup><small>&reg;</sup></small> supports integrations with select payroll providers—helping business owners save time and reduce errors by automating contribution reporting.</p>
  <p class="fs-h6 mb-4">Ubiquity supports direct integrations with dozens of payroll providers, including Paylocity and Namely. ADP<sup><small>®</small></sup>, Paychex<sup><small>®</small></sup>, QuickBooks<sup><small>®</small></sup>, and others are supported through add-on third party services. Ubiquity’s Payroll (K)oncierge service can help you get started with setting up your payroll information.</p>
`;

const sectionRisk = `
  <img
    src="${IconRiscPurple}"
    alt="Reduced risk Icon"
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">Reduced risk</h3>
  <p class="fs-h4 fw-normal mb-4">You don’t have to be an expert on regulations, because the administrative platform helps you determine what you need to do and when to do it.</p>
  <p class="fs-h6 mb-4">Simply Retirement by Principal<sup><small>®</small></sup> takes the guesswork out of staying on top of plan compliance. Electronic participant statements, forms for tax filing and plan documents, participant disclosures, annual plan notifications, IRS plan compliance testing and monitoring, and eligibility notices are provided—meaning less work for you.</p>
`;

const sectionDataAccuracy = `
  <img
    src="${IconLockVioletO}"
    alt="Data protection Icon"
    width="60"
    height="60"
    class="mb-4"
  />
  <h3 class="fs-h3 fw-bold text-purple mb-4">Data accuracy and protection</h3>
  <p class="fs-h4 fw-normal mb-4">To put it simply, your data is accurate and protected.</p>
  <p class="fs-h6">Ubiquity Retirement + Savings<sup><small>®</small></sup> knows how important security is to you and your employees. Ubiquity has completed the requirements for a Service Organization Control (SOC) 1, type 2 audit covering their proprietary Paradigm Recordkeeping System, which is the platform used by Simply Retirement<sup><small>®</small></sup> by Principal. The SOC 1 standard covers internal controls relevant to financial reporting at service organizations. Ubiquity’s continued investment in their SOC 1 program and reporting demonstrates their year-over-year commitment to ensuring complete and accurate financial accounting, as well as proper information technology general controls over their Paradigm Recordkeeping platform.</p>
`;

const businessOwnerWhySimplyDisclaimer1 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*year.</span> 2024 Principal SMB Sentiment survey of small and midsize employer non-customers (272) and employees (260) conducted between January 22 and February 14, 2024.</p>`;

const businessOwnerWhySimplyDisclaimer2 = `
  <p class="fs-disclaimer-print">
    <span class="d-print-block d-none">*Retirement:</span>
      “LIMRA Research Finds 4 in 10 Small Businesses
      Currently Offer Retirement Benefits,” January 2019.<br/>
      <a
        href="https://www.limra.com/en/newsroom/industry-trends/2019/limra-research-finds-4-in-10-small-businesses-currently-offer-retirement-benefits/"
        target="_blank"
        class="srp-button__link"
      >
        View Article>
      </a>
  </p>`;
const businessOwnerWhySimplyDisclaimer3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Per participating employee:</span>Fees paid by the business owner are billed quarterly. Fees paid by participants are deducted monthly from participant accounts.</p>`;
const businessOwnerWhySimplyDisclaimer4 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Recordkeeping-fee:</span>Pricing shown applies when working with a TPA. Bundled pricing is a $500 initial setup fee, then $185 per month. Fees paid by the business owner are billed quarterly. Fees paid by participants are deducted monthly from participant accounts. Participant fees are charged if there is a $100 account balance, regardless of whether the participant is active or inactive. Custodial and investment fees are charged against participating employees’ accounts (those vary by investment and range from ${investmentCost}, as of ${asOfDate}). If the business owner chooses to work with a financial professional and/or TPA, their fees are also additional and may be billed to the business owner. Financial professional fees may be deducted from participant accounts.</p>`;
const businessOwnerWhySimplyDisclaimer5 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*What’s included:</span> Plan costs are billed quarterly. Custodial and investment fees are charged against participating employees’ accounts (those vary by investment and range from 0.03% – 0.86%, as of ${asOfDate}). Should you choose to work with a financial professional, their fee is also additional.</p>`;
const businessOwnerWhySimplyDisclaimer6 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Investment lineup: </span>As part of Simply Retirement by Principal<sup><small>®</small></sup>, Wilshire Investments, Inc. is the fiduciary responsiblefor the selection and monitoring of the investments.</p>`;
const businessOwnerWhySimplyDisclaimer7 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Plan:</span>This credit is for plans that include the eligible automatic contribution arrangement (EACA) feature only. In addition, SECURE 2.0 Act legislation allows small businesses with fewer than 100 employees to claim a tax credit of 50% of the qualifying start-up costs for a new employee retirement plan for the first three years of the plan as follows but limited to the greater of (1) $500 or (2) the lesser of (a) $250 for each non-highly compensated employee who is eligible to participate in the plan or (b) $5,000. Information about the SECURE Act is educational only and provided with the understanding that Principal<small><sup>®</sup></small> is not rendering legal, accounting, investment advice or tax advice. You should consult with appropriate counsel or other financial professionals on all matters pertaining to legal, tax, investment or accounting obligations and requirements.</p>`;
const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${businessOwnerWhySimplyDisclaimer1}
     ${businessOwnerWhySimplyDisclaimer2}
     ${businessOwnerWhySimplyDisclaimer3}
     ${businessOwnerWhySimplyDisclaimer4}
     ${businessOwnerWhySimplyDisclaimer5}
     ${businessOwnerWhySimplyDisclaimer6}
     ${businessOwnerWhySimplyDisclaimer7}
    </div>
  </div>
`;

const WhySimplyRetirement = ({
  putApplicationPanelInfo,
  applicationPanelInfo,
}) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'whysimply-employer-disclaimer-1',
      'whysimply-employer-disclaimer-2',
      'whysimply-employer-disclaimer-5',
      'whysimply-employer-disclaimer-6',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'whysimply-employer-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerWhySimplyDisclaimer1,
        });
        break;

      case 'whysimply-employer-disclaimer-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerWhySimplyDisclaimer2,
        });
        break;

      case 'whysimply-employer-disclaimer-5':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerWhySimplyDisclaimer6,
        });
        break;

      case 'whysimply-employer-disclaimer-6':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerWhySimplyDisclaimer7,
        });
        break;

      default:
        break;
    }
  };

  const buttonRef = useRef();

  return (
    <div className="why-choose-page mb-5">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        buttonRef={buttonRef}
        alt={sectionHeader.alt}
      />
      <CardSection putApplicationPanelInfo={putApplicationPanelInfo} />
      <PurpleSection />
      <section className="section py-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-10 mx-auto">
              <img
                src={IconBlueMoneyHandO}
                width="80px"
                height="80px"
                alt="Hand Holding Money Icon"
                className="d-block mx-auto mb-5"
              />
              <h3 className="fs-h3 text-center fw-bold">
                What makes Simply Retirement by Principal
                <sup>
                  <small>®</small>
                </sup>{' '}
                a different kind of solution?
              </h3>
            </div>
          </div>
        </div>
      </section>
      <PredictableNoGuessPricing
        putApplicationPanelInfo={putApplicationPanelInfo}
        className="pt-5"
        businessOwnerWhySimplyDisclaimer3={businessOwnerWhySimplyDisclaimer3}
        businessOwnerWhySimplyDisclaimer4={businessOwnerWhySimplyDisclaimer4}
      />
      <SectionSingleCol
        copy={sectionInvestments}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />
      <SectionSingleCol
        copy={sectionEnrollment}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />
      <SectionOnlineDashboard />
      <SectionSingleCol
        copy={sectionPayroll}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />
      <SectionSingleCol
        copy={sectionRisk}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />
      <ReducedRiskTabs />
      <SectionSingleCol
        copy={sectionDataAccuracy}
        columns="col-lg-10 mx-auto"
        className="pt-0"
      />
      <section className="section section_py-5 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <img
                src={CheckCircle}
                alt="Checkmark In Circle Icon"
                className="d-block mx-auto mb-3"
                width="90"
              />
              <h3 className="fs-h3 text-center mb-4">
                Ready to take the next step?
              </h3>
              <p className="text-center pb-5 mb-5">
                <Link
                  to="/plans"
                  className="srp-button srp-button__primary px-5"
                >
                  See if our 401(k){' '}
                  <span className="d-md-inline-block d-none">solution</span>{' '}
                  <span className="d-md-none">plan</span> is right for you
                </Link>
              </p>

              <p className="fs-disclaimer text-center pb-5 mb-5">
                Intended for plan sponsor use.
              </p>
            </div>
          </div>
        </div>
      </section>

      <SectionSingleCol
        copy={footNoteList}
        columns="col-12"
        className="d-print-block d-none"
      />
    </div>
  );
};

WhySimplyRetirement.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WhySimplyRetirement);
