import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SiteLinkList from './SiteLinkList';
import Contact from './Contact';

import PrincipalLogo from '../../../Images/principal-simply-retirement-logo-stacked.svg';
import FooterStyles from './Footer.styles';

const Footer = ({ rootRoute, paradigmHost }) => {
  const getYear = () => new Date().getFullYear();

  const createPath = (pathSuffix) => `${rootRoute}${pathSuffix}`;

  const topLevelLinks = () => {
    const linkMappings = {
      '/psivet': [
        {
          id: 0,
          title: 'PSIvet member retirement plan options',
          path: 'https://landing.principal.com/PSIvet',
        },
      ],
      '/onedigital': [
        {
          id: 0,
          title: '',
          path: '',
        },
      ],
      default: [
        {
          id: 0,
          title: 'Business owners',
          path: '/business-owners/',
        },
        {
          id: 1,
          title: 'Financial professionals',
          path: '/financial-professionals/',
        },
        {
          id: 2,
          title: 'Third party administrators',
          path: '/third-party-administrator/',
        },
      ],
    };

    return linkMappings[rootRoute] || linkMappings.default;
  };

  const plansLink = () => {
    // TODO: This is a hack to get the correct root route for the plans link,
    // future refactor to get all experiences or tenants (validRootRoutes) from the backend
    const validRootRoutes = ['/business-owners', '/psivet', '/onedigital'];
    const basePath = validRootRoutes.includes(rootRoute) ? rootRoute : '';
    return [{ id: 4, title: 'Set up a plan', path: `${basePath}/plans` }];
  };

  const staticLinks = [
    {
      id: 2,
      title: 'Support',
      path: createPath('/resources'),
      children: [
        { id: 0, title: 'Your account', path: paradigmHost },
        { id: 1, title: 'Resources', path: createPath('/resources') },
        {
          id: 2,
          title: 'Frequently asked questions',
          path: createPath('/faq'),
        },
        {
          id: 3,
          title: 'Terms of use',
          path: 'https://www.principal.com/terms-of-use',
        },
        {
          id: 4,
          title: 'Privacy',
          path: 'https://www.principal.com/privacy-policies',
        },
        {
          id: 5,
          title: 'Security',
          path: 'https://www.principal.com/security-policies',
        },
        { id: 6, title: 'Disclosures', path: createPath('/disclosures') },
        {
          id: 7,
          title: 'Cookies settings',
          path: createPath('/manage-cookie-settings'),
        },
        { id: 8, title: 'Sitemap', path: createPath('/sitemap') },
      ],
    },
  ];

  const siteLinkList = [
    {
      id: 0,
      title: `Simply Retirement by <span class="fs-mark-reg">Principal</span> ${
        rootRoute === '/psivet' ? 'with PSIvet' : ''
      }`,
      children: [
        ...topLevelLinks(),
        { id: 3, title: '401(k)', path: createPath('/retirement-plans') },
        ...plansLink(),
        { id: 5, title: 'Investments', path: createPath('/investments') },
      ],
    },
    {
      id: 1,
      title: 'About',
      children: [
        {
          id: 0,
          title:
            '<span class="fs-mark-reg">Principal</span> and Ubiquity Retirement + <span class="fs-mark-reg">Savings</span>',
          path: createPath('/about'),
        },
        {
          id: 1,
          title: 'Why choose us?',
          path: createPath('/why-simply-retirement'),
        },
      ],
    },
    ...staticLinks,
  ];

  return (
    <FooterStyles className="d-print-none">
      <div className="bg-simply-dark-blue">
        <div className="container-xlg">
          <div className="row py-lg-5 pb-5">
            <div className="col-lg-3">
              <div className="mb-5">
                <Link to={rootRoute}>
                  <img
                    src={PrincipalLogo}
                    alt="Simply Retirement by Principal"
                    className="img-fluid d-block mx-lg-0 mx-auto"
                  />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5 col-md-8 mx-md-auto mx-5">
              <nav className="mason-md" aria-label="Site links">
                <SiteLinkList siteMap={siteLinkList} />
              </nav>
            </div>
            <div className="col-xl-3 col-lg-4 pl-xl-5">
              <Contact />
            </div>
          </div>
        </div>
        <div className="bg-blue-whale">
          <div className="container-xl">
            <FooterStyles.ListDisclaimer className="pt-5 pb-5 px-md-0 px-5 text-lg-left text-md-center text-left">
              <li className="d-lg-inline d-block">
                &copy; {getYear()} Principal Financial Services,Inc.
              </li>
              <li className="d-inline-block">
                <a
                  href="https://www.principal.com/terms-of-use"
                  target="_blank"
                >
                  Terms of use
                </a>
              </li>
              <li className="d-inline-block">
                <Link to="/disclosures">Disclosures</Link>
              </li>
              <li className="d-inline-block">
                <a
                  href="https://www.principal.com/privacy-policies"
                  target="_blank"
                >
                  Privacy
                </a>
              </li>
              <li className="d-inline-block">
                <a
                  href="https://www.principal.com/security-policies"
                  target="_blank"
                >
                  Security
                </a>
              </li>
              <li className="d-inline-block">
                <a
                  href="https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct"
                  target="_blank"
                >
                  Report fraud
                </a>
              </li>
              <li className="d-inline-block">
                <a href="/sitemap">Sitemap</a>
              </li>
            </FooterStyles.ListDisclaimer>
          </div>
        </div>
      </div>
    </FooterStyles>
  );
};

Footer.propTypes = {
  rootRoute: PropTypes.string.isRequired,
  paradigmHost: PropTypes.string.isRequired,
};

export default Footer;
