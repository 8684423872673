import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '../Label';

export const CheckBox = (props) => {
  const {
    name,
    label,
    value,
    dirty,
    disabled,
    options,
    hasError,
    required,
    labelClass,
    inputClass,
    handleQuestionChange,
    infoLabel,
  } = props;

  return (
    <div
      className={classNames('mb-3 form-group', {
        'has-error': hasError,
      })}
    >
      <Label
        name={name}
        className={labelClass}
        text={label}
        required={required}
      />
      {infoLabel && (
        <div
          className="fs-p text-center"
          dangerouslySetInnerHTML={{ __html: infoLabel }}
        />
      )}
      {options.map((option) => (
        <div key={option.id} className="radio-group">
          <div className="check-wrapper d-flex align-items-center ml-sm-0 ml-5">
            <input
              id={`${name}_${option.id}`}
              type="checkbox"
              checked={value}
              value={option.value}
              disabled={disabled || false}
              className={classNames(`form-check-input`, {
                'is-invalid': hasError,
                active: value === option.value,
                dirty: dirty && !option.checked,
                disabled: disabled || false,
                inputClass,
              })}
              onChange={(event) => {
                handleQuestionChange(
                  name,
                  event.target.checked,
                  option.valueFormatted,
                );
              }}
            />
            <label
              htmlFor={`${name}_${option.id}`}
              className="form-check-label"
            >
              <strong>{option.label}</strong>
            </label>
          </div>
          <p dangerouslySetInnerHTML={{ __html: option.info }} />
        </div>
      ))}
      {props.children}
    </div>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any.isRequired,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  hasError: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  labelClass: PropTypes.string,
  handleQuestionChange: PropTypes.func,
  infoLabel: PropTypes.any,
  children: PropTypes.any,
  inputClass: PropTypes.string,
};

export default CheckBox;
