export const getVestingScheduleFormula = async (vestingScheduleMatch) => {
  if (vestingScheduleMatch.valueFormatted === '4-year graded') {
    return '25,50,75,100';
  }
  if (vestingScheduleMatch.valueFormatted === '5-year graded') {
    return '20,40,60,80,100';
  }
  if (vestingScheduleMatch.valueFormatted === '6-year graded') {
    return '0,20,40,60,80,100';
  }
  if (vestingScheduleMatch.valueFormatted === '3-year cliff') {
    return '0,0,0,100';
  }
  if (vestingScheduleMatch.valueFormatted === '2-year cliff = 0%, 100%') {
    return '0,0,100';
  }
  return '100';
};
