import styled from 'styled-components';

export const FeeStyled = styled.span`
  font-size: 64px;
  @media (min-width: 768px) {
    font-size: 54px;
  }
  @media (min-width: 992px) {
    font-size: 64px;
  }
`;

export const FeeStylesSm = styled.span`
  color: #002855;
  font-weight: 700;
  font-size: 36px;

  @media (min-width: 992px) {
    font-size: 50px;
    line-height: 50px;
  }
`;

export const FeeStyledDown = styled.span`
  font-size: 36px;
  @media (min-width: 768px) {
    font-size: 36px;
  }
  @media (min-width: 992px) {
    font-size: 36px;
  }
`;

export const EquationStyled = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  color: #002855;
  font-weight: 700;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    flex-flow: row;
  }
`;
const Parens = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:before {
    content: ' ';
    border-left: 4px solid #cbcbcb;
    border-top: 4px solid #cbcbcb;
    border-bottom: 4px solid #cbcbcb;
    padding: 0px 2px;
    width: 14px;
    height: 70px;
  }
  &:after {
    content: ' ';
    padding: 0px 2px;
    border-right: 4px solid #cbcbcb;
    border-top: 4px solid #cbcbcb;
    border-bottom: 4px solid #cbcbcb;
    width: 14px;
    height: 70px;
  }
`;

const ParensDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:before {
    content: ' ';
    border-left: 2px solid #cbcbcb;
    border-top: 2px solid #cbcbcb;
    border-bottom: 2px solid #cbcbcb;
    padding: 0px 2px;
    width: 14px;
    height: 40px;
  }
  &:after {
    content: ' ';
    padding: 0px 2px;
    border-right: 2px solid #cbcbcb;
    border-top: 2px solid #cbcbcb;
    border-bottom: 2px solid #cbcbcb;
    width: 14px;
    height: 40px;
  }
`;

const Number = styled.span`
  font-size: 64px;
  line-height: 54px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  sup {
    font-size: 32px;
  }
`;
const Label = styled.span`
  font-size: 24px;
  line-height: 24px;
  text-align: left;
`;
const Operator = styled.span`
  font-size: 40px;
  line-height: 40px;
  text-align: center;
`;

const NumberDown = styled.span`
  font-size: 36px;
  line-height: 30px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  sup {
    font-size: 16px;
  }
`;
const LabelDown = styled.span`
  font-size: 16px;
  line-height: 14px;
  text-align: left;
`;
const OperatorDown = styled.span`
  font-size: 30px;
  line-height: 24px;
  text-align: center;
`;

EquationStyled.Parens = Parens;
EquationStyled.Number = Number;
EquationStyled.Label = Label;
EquationStyled.Operator = Operator;
EquationStyled.ParensDown = ParensDown;
EquationStyled.NumberDown = NumberDown;
EquationStyled.LabelDown = LabelDown;
EquationStyled.OperatorDown = OperatorDown;
EquationStyled.FeeStyledDown = FeeStyledDown;
EquationStyled.FeeStylesSm = FeeStylesSm;
