import React from 'react';
import PropTypes from 'prop-types';
import AbstractImage from './abstract-shape-001.svg';

export const GrowthPlatform = ({
  title = '',
  copy = '',
  wrapperStyles = '',
}) => (
  <section className={`section section_py-5 ${wrapperStyles}`}>
    <div className="container">
      <div
        className="px-md-90px px-30px py-30px"
        style={{
          borderRadius: '30px',
          backgroundColor: '#E3F5FD',
          textAlign: 'center',
        }}
      >
        <img src={AbstractImage} alt="" />
        <h3 className="mb-4">{title}</h3>
        <p dangerouslySetInnerHTML={{ __html: copy }} />
      </div>
    </div>
  </section>
);

GrowthPlatform.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  wrapperStyles: PropTypes.string,
};
