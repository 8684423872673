import React from 'react';
import { PurpleSectionStyles, ImageColStyles } from './index.styles';
import { WomanWithBook } from '../../../../ImagePaths';

const PurpleBox = () => (
  <PurpleSectionStyles>
    <ImageColStyles style={{ backgroundImage: `url(${WomanWithBook})` }} />
    <div className="container">
      <div className="row my-5">
        <div className="offset-md-6 col-xl-5 col-lg-6 col-md-6 ps-xl-5 py-md-0 px-md-0 py-5 px-5">
          <h3 className="fw-bold text-white ml-md-5">
            What Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            offers you
          </h3>
          <p className="fs-h6 text-white ml-md-5">
            Simply Retirement by Principal
            <small>
              <sup>&reg;</sup>
            </small>{' '}
            was created specifically to simplify the process of researching and
            setting up a retirement plan for small businesses with fewer than
            100 employees. You can complete every step right here with your
            financial professional.
          </p>
        </div>
      </div>
    </div>
  </PurpleSectionStyles>
);

export default PurpleBox;
