import React from 'react';
import PropTypes from 'prop-types';
import ColFinancialPro from '../../../../common/QuickQuote/ColFinancialPro';
import ColFinancialProComp from '../../../../common/QuickQuote/ColFinancialProComp';

const RowFinancialPro = ({ data, handleQuestionChange }) => (
  <div className="row justify-content-center">
    <ColFinancialPro data={data} handleQuestionChange={handleQuestionChange} />
    <ColFinancialProComp
      data={data}
      handleQuestionChange={handleQuestionChange}
    />
  </div>
);

RowFinancialPro.propTypes = {
  data: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default RowFinancialPro;
