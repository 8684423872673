import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NumericFormat } from 'react-number-format';

const Input = ({ inputs, handleChange }) => (
  <div className="row">
    {inputs.map((input) => (
      <div
        className="col-md-4 col-sm-12 roi-input-border-bottom"
        key={input.id}
      >
        <div
          className={classNames('row py-3 py-md-4', {
            'roi-input-border-right': inputs.length !== input.key,
          })}
        >
          <div className="col-5 col-sm-6 col-md-12 mx-md-auto col-lg-12 text-md-center text-lg-left">
            <label
              className="fw-bold text-white fs-h2 lh-125 mb-md-4 mb-lg-0 ml-lg-3 ml-xl-4"
              htmlFor={input.name}
              dangerouslySetInnerHTML={{ __html: input.label }}
            >
              {}
            </label>
          </div>
          <div className="col-auto my-auto mx-md-auto col-lg-auto ml-lg-3 ml-xl-4 mr-lg-0 py-lg-4">
            <NumericFormat
              required={input.required}
              allowNegative={false}
              autoFocus={input.autoFocus}
              prefix={input.prefix}
              thousandSeparator={input.thousandSeparator}
              className="srp-form-input form-control text-purple fs-h3 fw-bold d-block mx-md-auto"
              id={input.id}
              name={input.name}
              style={{ width: `${input.inputWidth}px` }}
              onValueChange={(values) => {
                handleChange(input.id, values.value);
              }}
            />
          </div>
          {input.per && (
            <div className="col-auto col-md-12 col-lg-auto my-auto text-center text-lg-left">
              <p className="fs-p text-white m-auto m-md-auto">
                &nbsp;&nbsp;{input.per}
              </p>
            </div>
          )}
        </div>
      </div>
    ))}
  </div>
);

Input.propTypes = {
  inputs: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Input;
