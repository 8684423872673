import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import Label from '../Label';

export const NumberQuestion = ({
  uniqueId,
  name,
  label,
  labelClass,
  inputClass,
  thousandSeparator,
  valuePrefix,
  placeholder,
  required,
  value,
  hasError,
  dirty,
  handleQuestionChange,
  infoLabel,
  autoFocus,
  children,
  placeholderClass,
  allowLeadingZeros,
  disabled,
  maxLength,
}) => (
  <div
    className={classNames('mb-3 form-group', {
      'has-error': hasError,
    })}
  >
    <Label
      name={uniqueId || name}
      className={labelClass}
      text={label}
      required={required}
    />
    {infoLabel && (
      <div
        className="fs-p text-center mb-5"
        dangerouslySetInnerHTML={{ __html: infoLabel }}
      />
    )}
    {placeholder && (
      <span
        className={classNames(placeholderClass, {
          'fs-form-label d-block': true,
          'text-center': placeholderClass !== '',
        })}
      >
        {placeholder}
      </span>
    )}
    <NumericFormat
      isAllowed={(values) => {
        const { floatValue, formattedValue } = values;
        return allowLeadingZeros ? true : formattedValue === '' || floatValue;
      }}
      autoFocus={autoFocus}
      id={uniqueId || name}
      prefix={valuePrefix}
      allowNegative={false}
      className={classNames(inputClass, {
        'is-invalid': hasError,
        'is-dirty': dirty,
      })}
      disabled={disabled}
      required={required}
      name={uniqueId || name}
      thousandSeparator={thousandSeparator}
      value={value}
      onValueChange={(values) => {
        handleQuestionChange(name, values.value, values.formattedValue);
      }}
      allowLeadingZeros={allowLeadingZeros}
      maxLength={maxLength}
    />
    {children}
  </div>
);

NumberQuestion.propTypes = {
  children: PropTypes.any,
  uniqueId: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  label: PropTypes.string.isRequired,
  valuePrefix: PropTypes.string,
  handleQuestionChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  dirty: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  infoLabel: PropTypes.any,
  autoFocus: PropTypes.bool,
  allowLeadingZeros: PropTypes.bool,
  placeholderClass: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.any,
};

export default NumberQuestion;
