import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import FormDataRedux, { isFormValid } from './Redux/FormDataRedux';
import { ROUTE_META } from './RouteMeta';
import AppRoute from './AppRoute';
import PrivateRoute from './PrivateRoute';
import { AppVariables } from './AppVariables';
import { uuidContext } from './common/FormWithSteps';

// Layouts
import ApplicationLayout from './containers/ApplicationLayout';
import ModalLayout from './containers/ModalLayout';

// Pages
// BusinessOwners
import BusinessOwnersHome from './containers/BusinessOwners/Home';
import BusinessOwners from './containers/BusinessOwners';

// FinancialProfessionals
import FinancialProfessionals from './containers/FinancialProfessionals';

// TPA
import TPA from './containers/TPA';

// OneDigital
import OneDigital from './containers/OneDigital';

// PSIVet
import PSIVet from './containers/PSIVet';

import SetupPlan from './containers/SetupPlan';
import ExpiredPlan from './containers/SetupPlan/ExpiredPlan';
import SubmittedPlan from './containers/SetupPlan/SubmittedPlan';
import ReviewPlan from './containers/ReviewPlan';

import ROICalculator from './containers/ROICalculator';
import ExploreOptions from './containers/ExploreOptions';
import SeveralOptions from './containers/SeveralOptions';
import RequiresLicense from './containers/RequiresLicense';
import RestrictedInsuranceAgent from './containers/RestrictedInsuranceAgent';
import LetsTalk from './containers/LetsTalk';
import PlanLargerGroup from './containers/PlanLargerGroup';
import SafeHarbor from './containers/SafeHarbor';
import Disclosures from './containers/Disclosures';
import ManageCookiesPreferences from './containers/ManageCookiesPreferences';
import Sitemap from './containers/Sitemap';

const AppRoutes = ({ putApplicationState, isValid, hashId, paradigmHost }) => {
  const { authenticityToken } = useContext(AppVariables);
  const uuid = useContext(uuidContext);
  const { employer, advisor, tpa, psivet, onedigital } = ROUTE_META;
  const description =
    'Meet Simply Retirement by Principal®, the online 401(k) plan for small business owners. Learn about this 401(k) plan and sign up today.';

  const appRoutes = [
    {
      exact: true,
      key: 'plans',
      authenticityToken,
      layout: ModalLayout,
      component: SetupPlan,
      title: 'Set Up a Plan',
      description,
    },
    {
      exact: true,
      key: 'explore-options',
      layout: ModalLayout,
      component: ExploreOptions,
      title: 'Explore Options',
    },
    {
      exact: true,
      key: 'requires-license',
      layout: ModalLayout,
      component: RequiresLicense,
      title: 'Requires License',
    },
    {
      exact: true,
      key: 'restricted-insurance-agent',
      layout: ModalLayout,
      component: RestrictedInsuranceAgent,
      title: 'Restricted Insurance Agent',
    },
    {
      exact: true,
      key: 'plan-expired',
      layout: ModalLayout,
      component: ExpiredPlan,
      title: 'Plan Expired',
      description,
    },
    {
      exact: true,
      key: 'plan-submitted',
      layout: ModalLayout,
      component: SubmittedPlan,
      title: 'Plan Submitted',
      description,
    },
    {
      exact: true,
      key: 'lets-talk',
      layout: ModalLayout,
      component: LetsTalk,
      title: "Let's Talk",
    },
    {
      exact: true,
      key: 'several-options',
      layout: ModalLayout,
      component: SeveralOptions,
      title: 'Options',
    },
    {
      exact: true,
      key: 'larger-group',
      layout: ModalLayout,
      component: PlanLargerGroup,
      title: 'Larger Group',
    },
    {
      exact: true,
      key: 'roi-calculator',
      layout: ModalLayout,
      component: ROICalculator,
      title: 'ROI Calculator',
    },
    {
      exact: true,
      key: 'safeharbor-explained',
      layout: ApplicationLayout,
      component: SafeHarbor,
      title: 'Safe Harbor 401k | 401k Employer Match',
      description:
        'Safe harbor 401(k) plans bypass some of the annual compliance testing required by the IRS. Explore a safe harbor 401(k) plan with Simply Retirement by Principal®.',
    },
    {
      exact: true,
      key: 'disclosures',
      layout: ApplicationLayout,
      component: Disclosures,
      title: 'Disclosures',
    },
    {
      exact: true,
      key: 'manage-cookie-settings',
      layout: ApplicationLayout,
      component: ManageCookiesPreferences,
      title: 'Manage Cookie Settings',
    },
    {
      exact: true,
      key: 'sitemap',
      layout: ApplicationLayout,
      component: Sitemap,
      title: 'Sitemap',
    },
  ];

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const localData = JSON.parse(
        sessionStorage.getItem(`${uuid}_PlanDesignSession`),
      );
      if (localData) putApplicationState(localData);
    }
  }, []);
  return (
    <Switch>
      <Route path={employer.route}>
        <BusinessOwners
          paradigmHost={paradigmHost}
          hashId={hashId}
          authenticityToken={authenticityToken}
          isValid={isValid}
        />
      </Route>
      <Route path={advisor.route}>
        <FinancialProfessionals
          paradigmHost={paradigmHost}
          hashId={hashId}
          authenticityToken={authenticityToken}
          isValid={isValid}
        />
      </Route>
      <Route path={tpa.route}>
        <TPA
          paradigmHost={paradigmHost}
          hashId={hashId}
          authenticityToken={authenticityToken}
          isValid={isValid}
        />
      </Route>
      <Route path={onedigital.route}>
        <OneDigital
          paradigmHost={paradigmHost}
          hashId={hashId}
          authenticityToken={authenticityToken}
          isValid={isValid}
        />
      </Route>
      <Route path={psivet.route}>
        <PSIVet
          paradigmHost={paradigmHost}
          hashId={hashId}
          authenticityToken={authenticityToken}
          isValid={isValid}
        />
      </Route>
      {appRoutes.map((route) => (
        <AppRoute
          key={route.key}
          path={`/${route.key}`}
          layout={ApplicationLayout}
          paradigmHost={paradigmHost}
          {...route}
        />
      ))}
      <AppRoute
        exact
        path="/plans/:id"
        authenticityToken={authenticityToken}
        layout={ModalLayout}
        component={SetupPlan}
        title="Set Up a Plan"
        description={description}
        paradigmHost={paradigmHost}
      />
      <PrivateRoute
        exact
        path="/review-plan"
        redirect="/plans"
        hashId={hashId}
        authenticityToken={authenticityToken}
        isValid={isValid}
        layout={ApplicationLayout}
        component={ReviewPlan}
        paradigmHost={paradigmHost}
        title="Review Plan"
      />
      <AppRoute
        layout={ApplicationLayout}
        component={BusinessOwnersHome}
        title="401k Plans For Small Business"
        description="Simply Retirement by Principal® is a flat-fee, retirement plan solution allowing small businesses to easily manage an affordable 401(k)."
        paradigmHost={paradigmHost}
      />
    </Switch>
  );
};

AppRoutes.propTypes = {
  putApplicationState: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  isValid: PropTypes.bool.isRequired,
  hashId: PropTypes.string,
  paradigmHost: PropTypes.string,
};

const mapStateToProps = (store) => ({
  isValid: isFormValid(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationState: (localData) => {
    dispatch(FormDataRedux.putApplicationState(localData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
