import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Question,
  StepControls,
  WithInfoPanel,
  checkValidityOnQuestionArray,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import { selectedSafeHarbor } from '../../../Services/helpers/Paradigm/planHelper';

export const StepQuestion = ({
  role,
  active,
  questionId,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  destination,
}) => {
  const [qid, setQid] = useState(questionId);
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };

  const { dirty, hasError } = questions[qid];
  const validate = !!(dirty && hasError);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    if (id === 'employerMatch') {
      updateQuestionById(
        'stretchSafeHarbor',
        updateValue,
        updateValue === '',
        true,
        valueFormatted,
      );
      updateQuestionById(
        'employerMatch',
        updateValue,
        updateValue === '',
        true,
        valueFormatted,
      );
      return false;
    }
    updateQuestionById(
      'stretchSafeHarbor',
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
    return false;
  };

  const disableReviewBtn = () => {
    if (selectedSafeHarbor(questions)) {
      return questions[questionId].required ? !(dirty && !hasError) : false;
    }
    return !checkValidityOnQuestionArray([questions.planEffectiveDate]);
  };

  useEffect(() => {
    if (questions.safeHarbor.value === 'nonSafeHarbor') {
      setQid('employerMatch');
      pushGoogleDataLayer({
        event: 'PlanDesignView',
        PlanDesignView: `contribution_match_${role}`,
      });
    } else {
      pushGoogleDataLayer({
        event: 'PlanDesignView',
        PlanDesignView: `stretch_safe_harbor_${role}`,
      });
    }
  }, []);

  return (
    <div className="container-fluid h-100">
      <WithInfoPanel
        visibleInfoPanel={visibleInfoPanel}
        setVisibility={setVisibility}
        heading={questions[qid].heading}
        content={questions[qid].content}
        toggleInfoPanel={toggleInfoPanel}
      >
        <Question
          role={role}
          type={questions[qid].type}
          labelClass="form-label"
          question={questions[qid]}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!questions[qid].heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        {questions[questionId].heading && (
          <p className="text-center mb-lg-auto">
            <button
              type="button"
              className="srp-button srp-button__link mx-auto"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Need clarification?
            </button>
          </p>
        )}
        <StepControls
          formInReview={formInReview}
          disabledNextBtn={
            questions[qid].required ? !(dirty && !hasError) : false
          }
          disableReviewBtn={disableReviewBtn()}
          goToReviewPlan={goToReviewPlan}
          clickNextBtn={() => {
            if (qid === 'employerMatch') {
              handleQuestionChange(
                questions[qid].name,
                questions[qid].value,
                `${questions[qid].value}%`,
              );
            }

            setVisibility(false);

            updateActiveStep(destination(), 'forward');
            return false;
          }}
        />
      </WithInfoPanel>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          setVisibility(false);
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

StepQuestion.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  destination: PropTypes.func,
};

export default StepQuestion;
