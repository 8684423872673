import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createModal: ['child'],
  destroyModal: null,
});

export const ModalTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  modalVisibility: false,
  child: '',
};

/* ------------- Reducers ------------- */

const createModal = (state, { child }) => ({
  ...state,
  modalVisibility: true,
  child,
});

const destroyModal = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const modalReducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_MODAL]: createModal,
  [Types.DESTROY_MODAL]: destroyModal,
});
