import React from 'react';

const HeroSection = () => (
  <section
    className="section section_py-5 pb-4 review-plan-section"
    style={{ paddingTop: '200px' }}
  >
    <div className="container">
      <div className="row">
        <div className="offset-lg-1 col-lg-7">
          <h1 className="fs-h1 fw-bold mb-5">Review the plan</h1>
          <p className="fs-display5">
            This is a summary of the plan selections. When ready to sign up for
            this 401(k) plan, the business owner will have the option to click
            “Complete your purchase” at the bottom of this page.
          </p>
          <p className="fs-h6">
            <strong>
              Please note that the business owner isn’t officially signed up for
              a Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              plan until they complete the purchase.
            </strong>
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default HeroSection;
