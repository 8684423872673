export const getServiceEligibility = async (serviceEligibility) => {
  if (serviceEligibility === undefined) {
    return 'ONE_YEAR_HOURS';
  }
  if (serviceEligibility.value === 'oneMonth') {
    return 'ONE_MONTH';
  }
  if (
    serviceEligibility.value === 'threeMonths' ||
    serviceEligibility.value === 'THREE_MONTHS'
  ) {
    return 'THREE_MONTHS';
  }
  if (serviceEligibility.value === 'sixMonths') {
    return 'SIX_MONTHS';
  }
  if (
    serviceEligibility.value === 'twelveMonths' ||
    serviceEligibility.value === '12months' ||
    serviceEligibility.value === 'TWELVE_MONTHS'
  ) {
    return 'TWELVE_MONTHS';
  }
  if (
    serviceEligibility.value === 'oneYear' ||
    serviceEligibility.value === 'oneYearService'
  ) {
    return 'ONE_YEAR_HOURS';
  }
  return 'IMMEDIATE';
};
