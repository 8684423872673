import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import InvestmentLineUp from './InvestmentLineUp';
import { asOfDate } from '../../../Services/utilities';

import { InvestmentLineupImage } from '../../../ImagePaths';

const sectionHeader = {
  image: InvestmentLineupImage,
  title: 'Investment options available',
  caption: `
    <p class="fs-display5 mb-5">
      Participants can pick the mix of investments that best match their needs from the plan’s streamlined investment lineup.
    </p>
  `,
  alt: 'Two women enjoying the outdoors.',
  copy: ``,
};

const sectionInvestmentOptions = `
<h2 class="fs-h2 fw-normal mb-4">
  OneDigital Investment Advisors LLC (“OneDigital”) offers diverse investment options, helping make the most of employees' contributions and any matching contributions provided by the business owner.
</h2>
<p class="fs-h6">
  OneDigital will be the plan&apos;s 3(38) investment fiduciary, and provides objective, independent third-party oversight for the screening, selection and monitoring of the investment options available for Simply Retirement by Principal<small><sup>®</sup></small>. OneDigital also makes changes to the investment lineup when they deem appropriate.
</p>
`;

const sectionAbout = `
<h4 class="fs-display5 mb-3">
  About Fiduciary Services and OneDigital
</h4>
<p class="fs-h6">
  Managing obligations as a fiduciary can be challenging, from navigating the landscape of available investment managers to monitoring plan investments and more.
</p>
<p class="fs-h6">
  <strong>That means the investment professionals at OneDigital are responsible for the creation and oversight of the investment lineup for Simply Retirement by Principal<small><sup>®</sup></small>.</strong> As a result, participants can benefit from the same investment manager research resources that help select investment options for some of the largest retirement and pension plans in the world.
</p>
<p class="fs-h6 mb-0"><strong>What does OneDigital do?</strong></p>
<p class="fs-h6">
  OneDigital helps screen and select plan investments for Simply Retirement by Principal<small><sup>®</sup></small>. They routinely monitor the investments in the plan and apply a rigorous review process, then make appropriate adjustments. If OneDigital makes a change to any investments in the Simply Retirement by Principal<small><sup>®</sup></small> lineup, plan sponsors will receive an email, and the required participant notices will be sent to the participants’ email accounts and be accessible via the dashboard.
</p>
<p class="fs-h6 mb-0">
  <strong>Why OneDigital?</strong>
</p>
<p class="fs-h6">
OneDigital&apos;s team of fierce advocates helps businesses and individuals achieve their aspirations of health, success and financial security. Our insurance, financial services and HR platform provides personalized, tech-enabled solutions for a contemporary work-life experience. Nationally recognized for our culture of caring, OneDigital&apos;s teams enable employers and individuals to do their best work and live their best lives. More than 75,000 employers and millions of individuals rely on our teams for counsel and access to fully integrated worksite products and services and the retirement and wealth management advice provided through OneDigital Investment Advisors. Founded in 2000 and headquartered in Atlanta, OneDigital maintains offices in most major markets across the nation.
</p>
`;

const investmentDisclaimer1 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*below:</span> As of 6/1/2019.</p>`;
const investmentDisclaimer2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Representing combined assets of more than $9 trillion:</span> As of 6/30/2019.</p>`;
const investmentDisclaimer3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*More than 39,000 plans:</span> As of 1/31/2019.</p>`;
const investmentDisclaimer4 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*Over 70 investment professionals contributing to investment manager research:</span> As of ${asOfDate}.</p>`;
const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${investmentDisclaimer1}
     ${investmentDisclaimer2}
     ${investmentDisclaimer3}
     ${investmentDisclaimer4}
    </div>
  </div>
`;

const Investments = ({ applicationPanelInfo, putApplicationPanelInfo }) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'investments-disclaimer-1',
      'investments-disclaimer-2',
      'investments-disclaimer-3',
      'investments-disclaimer-4',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });
  const handleClick = (e) => {
    switch (e.target.id) {
      case 'investments-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: investmentDisclaimer1,
        });
        break;

      case 'investments-disclaimer-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: investmentDisclaimer2,
        });
        break;

      case 'investments-disclaimer-3':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: investmentDisclaimer3,
        });
        break;

      case 'investments-disclaimer-4':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: investmentDisclaimer4,
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="about-page mb-5 pb-5">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        alt={sectionHeader.alt}
      />
      <SectionSingleCol
        copy={sectionInvestmentOptions}
        columns="col-md-10 mx-auto"
      />

      <section className="section section_py-5 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2 className="fs-h2 fw-normal mb-4">
                Participating employees have the option to choose their mix of
                investments from the plan’s investment lineup based on the asset
                classes{' '}
                <span
                  id="investments-disclaimer-4"
                  className="text-underline p-0 no-border-button"
                >
                  below
                </span>
                :
              </h2>
              <InvestmentLineUp />
            </div>
          </div>
        </div>
      </section>

      <SectionSingleCol
        copy={sectionAbout}
        columns="col-md-10 mx-auto"
        className="pt-0"
      />
      <SectionSingleCol
        className="pt-0"
        copy={`<p class="fs-disclaimer pb-1 text-center">
                Intended for financial professional, and plan sponsor use.
              </p>`}
        columns="col-md-10 mx-auto"
      />

      <SectionSingleCol className="d-print-block d-none" copy={footNoteList} />
    </div>
  );
};

Investments.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Investments);
