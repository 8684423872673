import states from '../../../Services/states.json';

import { TPA_INITIAL_PLAN } from './TPAInitialState';
import { DEFAULT_INITIAL_PLAN } from './DefaultPlanInitialState';

export const USER_SELECT_INITIAL_STATE = {
  ...TPA_INITIAL_PLAN,
  ...DEFAULT_INITIAL_PLAN,
  roleSelection: {
    type: 'radio_button',
    name: 'roleSelection',
    user: {},
    label:
      'Tell us which option describes your role best so we can ask you the right questions:',
    options: [
      {
        id: 1,
        user: {},
        label: 'Business owner or HR manager',
        value: 'employer',
        valueFormatted: 'Business owner or HR manager',
        checked: false,
        icon: 'icon-business-owner-o',
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Financial professional',
        value: 'advisor',
        valueFormatted: 'Financial professional',
        checked: false,
        icon: 'icon-advisor-o',
        customStyle: '',
      },
      {
        id: 3,
        user: {},
        label: 'Third party administrator',
        value: 'tpa',
        valueFormatted: 'TPA',
        checked: false,
        icon: 'icon-multi-user-o',
        customStyle: '',
      },
      {
        id: 4,
        user: {},
        label: 'No thanks, I’m just looking ',
        value: 'anonymous',
        valueFormatted: 'Just Looking',
        checked: false,
        customStyle: 'radio_basic',
      },
    ],
    required: true,
    hasError: false,
    dirty: false,
    value: '',
    valueFormatted: '',
  },
  hasFp: {
    stepName: 'employerQuestions',
    type: 'radio_button',
    name: 'hasFp',
    user: {},
    label: 'Are you currently working with a financial professional?',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: true,
        valueFormatted: 'Requires financial professional',
        checked: false,
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: false,
        valueFormatted: 'Financial professional not required',
        checked: false,
        customStyle: '',
      },
    ],
    required: true,
    hasError: false,
    dirty: false,
    value: '',
    valueFormatted: '',
    reviewScreenTitle: 'Currently working with a financial professional',
    optionDescription: `<p class="mb-0"><strong>Why are we asking?</strong></p> If you decide to purchase your plan, we’ll need to confirm that your financial professional is set up with Simply Retirement by Principal<sup><small>®</small></sup>. You’ll be asked to provide their contact information so we can follow up to complete this process.`,
    description: '',
  },
  hasTpa: {
    type: 'radio_button',
    name: 'hasTpa',
    user: {},
    label: 'Are you working with a third party administrator?',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: true,
        valueFormatted: 'Requires third party professional',
        checked: false,
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: false,
        valueFormatted: 'Third party professional not required',
        checked: false,
        customStyle: '',
      },
    ],
    required: true,
    hasError: false,
    dirty: false,
    value: '',
    valueFormatted: '',
    reviewScreenTitle: 'Currently working with a third party professional',
    optionDescription: '',
    description: '',
  },
  returnRoleSelection: {
    type: 'radio_button',
    name: 'returnRoleSelection',
    user: {},
    label:
      'Tell us which option describes your role best so we can ask you the right questions:',
    options: [
      {
        id: 1,
        user: {},
        label: 'Business owner or HR manager',
        value: 'employer',
        valueFormatted: 'Business owner or HR manager',
        checked: false,
        icon: 'icon-business-owner-o',
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Financial professional',
        value: 'advisor',
        valueFormatted: 'Financial professional',
        checked: false,
        icon: 'icon-advisor-o',
        customStyle: '',
      },
      {
        id: 3,
        user: {},
        label: 'Third party administrator',
        value: 'tpa',
        valueFormatted: 'TPA',
        checked: false,
        icon: 'icon-multi-user-o',
        customStyle: '',
      },
    ],
    required: true,
    hasError: false,
    dirty: false,
    value: '',
    valueFormatted: '',
  },
  sponsorFirstName: {
    type: 'text_field',
    name: 'sponsorFirstName',
    user: {
      employer: { label: 'First name' },
      tpa: { label: 'Client’s first name' },
      advisor: { label: 'Client’s first name' },
    },
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  sponsorLastName: {
    type: 'text_field',
    name: 'sponsorLastName',
    user: {
      employer: { label: 'Last name' },
      tpa: { label: 'Client’s last name' },
      advisor: { label: 'Client’s last name' },
    },
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  sponsorEmployerIdentificationNumber: {
    type: 'number_field',
    name: 'sponsorEmployerIdentificationNumber',
    user: {},
    label: 'Your company’s Employer Identification Number (EIN)',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  employerStateCode: {
    type: 'select',
    name: 'employerStateCode',
    user: {
      employer: { label: `Your company's state of business` },
      advisor: { label: 'Company state of business' },
      tpa: { label: 'Company state of business' },
    },
    options: states,
    required: true,
    dirty: false,
    disabled: false,
    value: 'select',
    valueFormatted: '',
    hasError: false,
  },
  insuranceGroupType: {
    type: 'dropdown',
    name: 'insuranceGroupType',
    label: 'Insurance group type',
    options: [
      {
        id: 1,
        label: '1 - Employer (to cover the employer’s employees)',
        value: '1',
        selected: false,
      },
      {
        id: 2,
        label: '2 - Labor Union (to cover the union’s members)',
        value: '2',
        selected: false,
      },
      {
        id: 3,
        label:
          '3 - Governmental Entity or Association of Public Employees(to cover the governmental entity’s employees or the association’s members)',
        value: '3',
        selected: false,
      },
      {
        id: 4,
        label:
          '4 - Nonprofit Organization or Association (to cover the organization’s or association’s members and employees)',
        value: '4',
        selected: false,
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasError: false,
  },
  companyName: {
    type: 'text_field',
    name: 'companyName',
    user: {
      employer: { label: 'Your company name' },
      advisor: { label: 'Company legal name' },
      tpa: { label: 'Company legal name' },
    },
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasError: false,
  },
  zipCode: {
    type: 'text_field',
    name: 'zipCode',
    user: {},
    label: 'Your zip code',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  sponsorEmail: {
    type: 'text_field',
    name: 'sponsorEmail',
    user: {
      employer: { label: 'Email address' },
      advisor: { label: 'Client’s email address' },
      tpa: { label: 'Client’s email address' },
    },
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasError: false,
  },
  sponsorTitle: {
    type: 'text_field',
    name: 'sponsorTitle',
    user: {},
    label: 'Your title',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  companyPhone: {
    type: 'text_field',
    name: 'companyPhone',
    user: {},
    label: 'Your phone number',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasError: false,
  },
  hasRetirementPlan: {
    type: 'radio_condensed',
    name: 'hasRetirementPlan',
    user: {},
    label: 'Does your business have a retirement plan today?',
    infoLabel:
      'If your business has an existing retirement plan, such as a 401(k), 403(b), SEP IRA, Simple IRA or other qualified (pension) plan, please select “yes.”',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: 'yes',
        checked: false,
        icon: 'icon-check-o',
        customStyle: 'radio_confirm',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: 'no',
        checked: false,
        icon: 'icon-close-o',
        customStyle: 'radio_cancel',
      },
    ],
    required: true,
    hasError: false,
    dirty: false,
    value: '',
  },
  currentPlan: {
    type: 'dropdown',
    name: 'currentPlan',
    user: {},
    label: 'Current Plan',
    options: [
      {
        id: 1,
        label: '401(k)',
        value: '401(k)',
        selected: false,
      },
      {
        id: 2,
        label: '403(b)',
        value: '403(b)',
        selected: false,
      },
      {
        id: 3,
        label: 'SEP IRA',
        value: 'SEP IRA',
        selected: false,
      },
      {
        id: 4,
        label: 'Simple IRA',
        value: 'SIMPLE',
        selected: false,
      },
      {
        id: 5,
        label: 'Defined benefit (pension)',
        value: 'Defined Benefit',
        selected: false,
      },
      {
        id: 6,
        label: 'Unsure',
        value: 'Not Comfortable Answering',
        selected: false,
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: 'Not Comfortable Answering',
    hasError: false,
  },
  employees_can_contribute: {
    id: 4,
    type: 'radio_condensed',
    name: 'employees_can_contribute',
    user: {},
    heading: 'Why wouldn’t employees contribute?',
    content:
      'Some plans act as a benefit to the employees and do not require the employee to contribute themselves. Instead, you, as the business owner, make all of the contributions to the plan for your employees.',
    label:
      'Do you want employees to be able to contribute to their retirement accounts?',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: 'radio_btn_1',
        checked: false,
        icon: 'icon-check-o',
        customStyle: 'radio_confirm',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: 'radio_btn_2',
        checked: false,
        icon: 'icon-close-o',
        customStyle: 'radio_cancel',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasConditional: true,
    validateRule: 'RADIO_BTN_2_RULE',
    hasError: false,
  },
  type_of_organization: {
    id: 5,
    type: 'radio_condensed',
    name: 'type_of_organization',
    user: {},
    heading: 'Why does this matter?',
    content:
      'There are certain types of retirement plans that are restricted to tax-exempt, education and religious organizations.',
    label:
      'Is your business a school or a religious or tax-exempt organization?',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: 'radio_btn_1',
        checked: false,
        icon: 'icon-check-o',
        customStyle: 'radio_confirm',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: 'radio_btn_2',
        checked: false,
        icon: 'icon-close-o',
        customStyle: 'radio_cancel',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasConditional: true,
    validateRule: 'RADIO_BTN_1_RULE',
    hasError: false,
  },
  advisorFirmBroker: {
    type: 'dropdown',
    name: 'advisorFirmBroker',
    user: {
      advisor: { label: 'Your broker-dealer' },
    },
    options: [],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasError: false,
    placeholder: 'Start typing or select..',
  },
  firmNameFreeForm: {
    stepName: 'advisorFirm',
    type: 'text_field',
    name: 'firmNameFreeForm',
    user: {},
    label: 'Your practice/company name',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
    valueFormatted: '',
  },
  advisorCompensation: {
    id: 10,
    type: 'radio_condensed',
    name: 'advisorCompensation',
    user: {},
    label:
      'How would you like your compensation to be collected from your client?',
    options: [
      {
        id: 1,
        user: {},
        label: 'Billed separately by you',
        value: 'option_1',
        checked: false,
        icon: 'fas fa-user',
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Deducted from participant accounts',
        value: 'option_2',
        checked: false,
        icon: 'fas fa-users',
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  advisorType: {
    type: 'radio_condensed',
    name: 'advisorType',
    user: {},
    label: 'Which compensation type?',
    infoLabel: '(paid quarterly)',
    options: [
      {
        id: 1,
        user: {},
        label: 'Flat Fee',
        value: 'option_1',
        checked: false,
        icon: 'fas fa-user',
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Basis Points',
        value: 'option_2',
        checked: false,
        icon: 'fas fa-users',
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  advisorFee: {
    type: 'number_field',
    name: 'advisorFee',
    labelClass: 'fs-form-q fw-bold mb-3 text-center',
    user: {},
    label: 'What is your annual flat fee?',
    infoLabel: '(up to $5,000 per year, paid quarterly)',
    valuePrefix: '$',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  advisorPoints: {
    id: 10,
    type: 'radio_condensed',
    name: 'advisorPoints',
    user: {},
    label: 'How many basis points?',
    infoLabel: '(per year, paid quarterly)',
    options: [
      {
        id: 1,
        user: {},
        label: '25',
        value: 'option_1',
        checked: false,
      },
      {
        id: 2,
        user: {},
        label: '50',
        value: 'option_2',
        checked: false,
      },
      {
        id: 3,
        user: {},
        label: '75',
        value: 'option_3',
        checked: false,
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  confirmAdvisorPoints: {
    id: 17,
    type: 'checkbox',
    user: { employer: { label: '' } },
    label: '',
    name: 'confirmAdvisorPoints',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: false,
    hasError: false,
    options: [
      {
        id: 1,
        value: false,
        label: 'Continue with {points} basis points?',
        info: 'In order to receive {points} bps in your state, you must be licensed and appointed in Life and Variable Annuities products.',
        checked: false,
        icon: '',
        hasInfo: false,
        customStyle: '',
      },
    ],
    description: '',
  },
  confirmAdvisorFee: {
    id: 18,
    type: 'checkbox',
    user: { employer: { label: '' } },
    label: '',
    name: 'confirmAdvisorFee',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: false,
    hasError: false,
    options: [
      {
        id: 1,
        value: false,
        label: 'Continue with annual flat fee?',
        info: 'In order to receive a flat fee in your state, you must be licensed and appointed in Life and Variable Annuities products.',
        checked: false,
        icon: '',
        hasInfo: false,
        customStyle: '',
      },
    ],
    description: '',
  },
};

export default USER_SELECT_INITIAL_STATE;
