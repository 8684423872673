import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Question,
  NextStepBtn,
  PrevStepBtn,
} from '../../../common/FormWithSteps';
import FormDataRedux from '../../../Redux/FormDataRedux';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';

export const ReturnRoleSelection = ({
  questionId,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  putRole,
  active,
  googleData,
}) => {
  useEffect(() => {
    updateQuestionById('returnRoleSelection', '', false, false, '');
  }, []);
  const { value, dirty, hasError } = questions[questionId];
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
    putRole(updateValue);
  };
  const destination = (updatedValue) => {
    switch (updatedValue) {
      case 'onedigital':
        updateActiveStep('employerCheckList', 'forward');
        break;
      case 'advisor':
        updateActiveStep('advisorClientInfo', 'forward');
        break;
      default:
        updateActiveStep('step_1', 'forward');
    }
  };
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-md-8 d-flex flex-column justify-content-lg-between mx-auto">
          <Question
            type={questions[questionId].type}
            question={{
              ...questions[questionId],
              options: [
                {
                  id: 1,
                  user: {},
                  label: 'Business owner or HR manager',
                  value: 'onedigital',
                  valueFormatted: 'Business owner or HR manager',
                  checked: false,
                  icon: 'icon-business-owner-o',
                  customStyle: '',
                },
                {
                  id: 2,
                  user: {},
                  label: 'Financial professional',
                  value: 'advisor',
                  valueFormatted: 'Financial professional',
                  checked: false,
                  icon: 'icon-advisor-o',
                  customStyle: '',
                },
              ],
            }}
            handleQuestionChange={handleQuestionChange}
          />
          {validate && (
            <p className="text-danger text-center">Question is required</p>
          )}

          <p className="fws__control">
            <NextStepBtn
              disabled={!(dirty && !hasError)}
              onClick={() => {
                putRole(value);
                destination(value);
              }}
            />
          </p>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

ReturnRoleSelection.propTypes = {
  questionId: PropTypes.string,
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  putRole: PropTypes.func.isRequired,
  googleData: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  putRole: (role) => {
    dispatch(FormDataRedux.putRole(role));
  },
});

export default connect(null, mapDispatchToProps)(ReturnRoleSelection);
