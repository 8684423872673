export const hasDiscretionaryEmployerMatch = {
  id: 'q6aI1d',
  type: 'radio_condensed',
  name: 'hasDiscretionaryEmployerMatch',
  paradigmAlias: 'hasDiscretionaryEmployerMatch',
  user: {},
  label: 'Will the plan allow for discretionary matching contributions?',
  infoLabel:
    'If the plan offers safe harbor match, this would be an additional match.',
  stepName: 'a_step_6_a_i_1_d',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      icon: 'icon-check-o',
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      icon: 'icon-close-o',
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Discretionary employer match',
  reviewScreenTitle: 'Discretionary employer match',
  optionDescription: '',
  description:
    'Business owners have the option to make contributions other than matching contributions. Discretionary contributions allow the business owner to decide which percentage of employee deferrals to match. If the plan offers safe harbor match, this would be an additional match.',
};
