import API from '../../api';
import { getCompensation } from './getCompensation';
const api = API.create();

export const principalCvFail = async (
  planDesign,
  advisor,
  failureMessage,
  date,
  authenticityToken,
  cvResponseArray,
  distributionPartners,
) => {
  const {
    sponsorFirstName,
    sponsorLastName,
    employerStateCode,
    advisorFee,
    advisorPoints,
    companyName,
    investmentLineup,
    planEffectiveDate,
  } = planDesign.questions;
  const {
    advisorPhone,
    advisorNpn,
    advisorFirstName,
    advisorLastName,
    advisorEmail,
    firmName,
  } = advisor.questions;

  try {
    await api.cvFail({
      fail_data: {
        accountState: employerStateCode.value,
        advisorEid: advisor.advisorCredential.eid.value,
        advisorEmail: advisorEmail.value,
        advisorName: `${advisorFirstName.value} ${advisorLastName.value}`,
        advisorNpn: advisorNpn.value,
        advisorPhone: advisorPhone.valueFormatted,
        brokerEid: advisor.advisorCredentialVerification.brokerEid,
        brokerType: advisor.advisorCredentialVerification.brokerType,
        clientName: `${sponsorFirstName.value} ${sponsorLastName.value}`,
        companyName: companyName.value,
        compensation: getCompensation(planDesign),
        crd: advisor.advisorCredentialVerification.crd.value,
        distributionPartners,
        cvResponseArray,
        date,
        failureMessage,
        planEffectiveDate: planEffectiveDate.valueFormatted,
        firmName: firmName.value.label,
        httpCode: failureMessage.code,
        insuranceAgent: advisor.advisorCredentialVerification.insuranceAgent,
        insuranceAgentNpn:
          advisor.advisorCredentialVerification.insuranceAgentNpn,
        mutualFund: investmentLineup.value !== 'fundLineup1',
        scale: advisorFee.valueFormatted || advisorPoints.valueFormatted,
      },
      authenticity_token: authenticityToken,
    });
  } catch (error) {
    // fail silently
  }
};
