import React from 'react';
import { Link } from 'react-router-dom';
import { SectionStyled, CornerStyled, CircleStyled } from './index.styles';
import SimplyIconSprite from '../../../../common/SimplyIconSprite';

const AboutSection = () => (
  <section className="section pt-0 pb-md-5 about-section">
    <div className="container-lg px-md-3 px-0">
      <SectionStyled className="px-sm-4 px-lg-5">
        <div className="row ml-lg-1 mx-0">
          <div className="col-md-7">
            <div className="row">
              <div className="col-xl-10 col-lg-12">
                <h3 className="fs-h3 mt-5 mb-3 text-md-left text-center">
                  What Simply Retirement by Principal
                  <sup>
                    <small>®</small>
                  </sup>{' '}
                  can do for you.
                </h3>
              </div>
            </div>
            <p className="fs-p">
              Many small business owners don&rsquo;t feel comfortable navigating
              retirement plans on their own. They have questions, they need
              guidance, and many don&rsquo;t know where to start. That&rsquo;s
              where your expertise is essential. This simple 401(k) plan is
              designed to take less of your time to set up, so you can focus on
              having the conversations that make a difference for your clients.
              Just plug in your compensation and a few selections to create a
              proposal, and have your client complete the purchase. Then leave
              helping with tasks like plan compliance testing and reporting to
              us. Or, if you’re working with a third party administrator (TPA),
              Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              supports that, too.
            </p>
          </div>
          <CornerStyled className="offset-md-1 col-md-4 d-md-block d-none">
            <CircleStyled className="text-center">
              Create quick client proposals your way, when it works for you.
            </CircleStyled>
            <p className="fs-p text-center">
              Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              allows you to engage your clients the way that works for you, at
              any time and on any device. Create, customize, and send a proposal
              to your client, or walk through the process with them step by
              step.
            </p>
            <p className="fs-p text-center">
              <Link
                to="/plans"
                style={{
                  color: '#464646',
                  top: '10px',
                  position: 'relative',
                }}
              >
                Create a proposal using the interactive planner.
              </Link>
            </p>
          </CornerStyled>
        </div>

        <div className="row mx-0">
          <div className="col-12">
            <div className="row">
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-lifesaver-o"
                  fill="#002855"
                  height="70"
                  alt="Lifesaver Icon"
                />
                <h5 className="fs-h5 text-purple">Expand your opportunities</h5>
                <p className="fs-p">
                  This streamlined product offering allows you to connect with
                  more small business clients and build relationships you may
                  not have had before.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-stop-watch-o"
                  fill="#002855"
                  height="70"
                  alt="Stopwatch Icon"
                />
                <h5 className="fs-h5 text-purple">Create proposals faster</h5>
                <p className="fs-p">
                  Here&rsquo;s a simplified 401(k) plan design and onboarding
                  process with an online experience that saves you time and
                  gives you a competitive advantage.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-coins-o"
                  fill="#002855"
                  height="70"
                  alt="Coins Icon"
                />
                <h5 className="fs-h5 text-purple">
                  Help your clients save more
                </h5>{' '}
                <p className="fs-p">
                  A Simply Retirement by Principal
                  <sup>
                    <small>®</small>
                  </sup>{' '}
                  401(k) plan has a cost-efficient flat-fee recordkeeping
                  pricing that doesn’t change as your clients’ plan assets
                  change.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <SimplyIconSprite
                  name="icon-rocket-o"
                  fill="#002855"
                  height="70"
                  alt="Rocket Icon"
                />
                <h5 className="fs-h5 text-purple">
                  Get a jump-start on sales materials
                </h5>{' '}
                <p className="fs-p">
                  From educating customers to presenting plan features, our
                  resources can help you make the most of your time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <CornerStyled className="d-md-none">
          <CircleStyled className="text-center">
            Create quick client proposals your way, when it works for you
          </CircleStyled>
          <p className="fs-p text-center">
            Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            allows you to engage your clients the way that works for you, at any
            time and on any device. Create, customize, and send a proposal to
            your client, or walk through the process with them step by step.
          </p>
          <p className="fs-p text-center">
            <Link
              to="/"
              style={{
                color: '#464646',
                top: '10px',
                position: 'relative',
              }}
            >
              Create a proposal using the interactive planner.
            </Link>
          </p>
        </CornerStyled>
      </SectionStyled>
    </div>
  </section>
);

AboutSection.propTypes = {};

export default AboutSection;
