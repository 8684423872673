import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  IconBusinessOwner,
  IconMultipleUsers,
  IconCalendar,
  IconChart,
} from '../../../ImagePaths';
import InvestmentLineUp from '../../../common/InvestmentLineUp';

const AdditionalPlanInfo = ({ hasTpa }) => {
  const [additionalInfoVisibility, setAdditionalInfoVisibility] =
    useState(false);
  const toggleAdditionalInfo = () => {
    setAdditionalInfoVisibility((prevState) => !prevState);
  };

  return (
    <>
      <p className="my-5 text-center border-bottom d-print-none">
        <button
          type="button"
          className="srp-button srp-button__outline px-md-5 mb-n4"
          onClick={() => {
            toggleAdditionalInfo();
          }}
        >
          {additionalInfoVisibility ? 'Hide ' : 'View '}
          additional plan &amp; investment information
        </button>
      </p>
      <div
        className={classNames('d-print-block', {
          'd-none': !additionalInfoVisibility,
        })}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <section className="section section_py-5  d-print-none">
                <h3 className="fs-h3 fw-bold text-purple mb-5">
                  Additional information about this plan
                </h3>
                <div className="row">
                  <div className="col-lg-3 col-6 mb-3">
                    <img
                      src={IconBusinessOwner}
                      alt=""
                      width="50"
                      height="50"
                      className="mb-2 d-block"
                    />
                    <a
                      href="#features-owners"
                      className="text-purple anchor-link"
                    >
                      <strong>Features for business owners</strong>
                    </a>
                  </div>
                  <div className="col-lg-3 col-6 mb-3">
                    <img
                      src={IconMultipleUsers}
                      alt=""
                      width="50"
                      height="50"
                      className="mb-2 d-block"
                    />
                    <a
                      href="#features-employees"
                      className="text-purple anchor-link"
                    >
                      <strong>Features for participating employees</strong>
                    </a>
                  </div>
                  <div className="col-lg-3 col-6 mb-3">
                    <img
                      src={IconCalendar}
                      alt=""
                      width="40"
                      height="40"
                      className="mb-2 d-block"
                    />
                    <a href="#billing" className="text-purple anchor-link">
                      <strong>Billing schedule</strong>
                    </a>
                  </div>
                  <div className="col-lg-3 col-6 mb-3">
                    <img
                      src={IconChart}
                      alt=""
                      width="40"
                      height="40"
                      className="mb-2 d-block"
                    />
                    <a href="#investments" className="anchor-link text-purple ">
                      <strong>Investments offered</strong>
                    </a>
                  </div>
                </div>
              </section>

              <section className="section section_py-5" id="features-owners">
                <img
                  src={IconBusinessOwner}
                  alt=""
                  width="80"
                  height="80"
                  className="mb-2 d-block"
                />
                <h5 className="fs-h2 fw-bold text-purple mb-4">
                  Features for business owners
                </h5>
                <p className="fs-p">
                  <strong>Automatic employee enrollment.</strong> Unless
                  otherwise indicated above, employees must be 21 years of age
                  or older to be eligible for the plan. Once the employee meets
                  the plan&apos;s age and service requirements, they will be
                  automatically enrolled at a default pre-tax contribution
                  percentage set by the business owner and begin making
                  contributions to the plan immediately.* Participants can
                  change their deferral or opt out at any time. They&apos;ll
                  also have contributions directed to the plan&apos;s QDIA
                  (Qualified Default Investment Alternative) unless they elect
                  otherwise.{' '}
                  <strong>
                    If the business owner is working with a TPA, other
                    eligibility, automatic enrollment, and vesting options may
                    apply.
                  </strong>
                </p>

                <p className="fs-disclaimer">
                  *Regardless of whether they meet the plan&apos;s age and
                  service requirements, union employees, nonresident aliens, and
                  independent contractors aren&apos;t allowed in the plan.
                </p>

                <div className="row row-cols-md-2 row-cols-1">
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>
                        Optional automatic contribution increases.
                      </strong>{' '}
                      Business owners can choose to have their employees&apos;
                      contributions remain fixed unless they change them, or
                      auto-escalate 1% each year up to 10%.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Profit-sharing flexibility.</strong> Business
                      owners have the option to contribute company profits back
                      to employees’ 401(k) plan accounts.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Payroll provider integrations.</strong> Business
                      owners can save time and reduce errors by automating
                      contribution reporting from their payroll provider system
                      directly into Simply Retirement by Principal
                      <sup>
                        <small>®</small>
                      </sup>
                      . Ubiquity’s Payroll Concierge service can help them get
                      started.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>
                        Protection through an ERISA fidelity bond.
                      </strong>{' '}
                      Business owners will get an ERISA fidelity bond to protect
                      the plan&apos;s assets (up to $250,000 in assets, which
                      equals a $25,000 bond).
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>
                        Easy-to-use online platform for plan administration.
                      </strong>{' '}
                      You&apos;ll manage your plan using the intuitive Ubiquity
                      Retirement + Savings
                      <sup>
                        <small>®</small>
                      </sup>{' '}
                      platform.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Automated signup and onboarding.</strong>{' '}
                      Employees will receive an email from Ubiquity as soon as
                      they’re eligible with instructions to set up a login to
                      their plan account.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Recordkeeping.</strong> Services include tracking
                      which employees are participating, the amount they’ve
                      invested, and the amount invested in each of the plan
                      funds.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Compliance testing.</strong> Ubiquity&apos;s team
                      of compliance experts performs annual nondiscrimination
                      testing (unless the business owner is working with a TPA).
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Filing and reporting.</strong> We help business
                      owners stay on top of required documentation—like IRS Form
                      5500, plan document preparation and filing, participant
                      disclosures, QDIA, and annual plan notifications.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Dedicated phone number.</strong> There&apos;s a
                      team of people just a phone call away if you need help.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Owner participation.</strong> This isn&apos;t just
                      a benefit for employees; qualifying business owners also
                      participate and maximize their retirement savings with any
                      available matching contributions.
                    </p>
                  </div>
                </div>
              </section>
              <section
                className="section section_py-5 pt-0"
                id="features-employees"
              >
                <img
                  src={IconMultipleUsers}
                  alt=""
                  width="100"
                  height="100"
                  className="mb-2 d-block"
                />
                <h5 className="fs-h2 fw-bold text-purple mb-4">
                  Features for participating employees
                </h5>
                <div className="row row-cols-md-2 row-cols-1">
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Preset investment options.</strong> Participants
                      can pick from a carefully selected lineup of options.
                      Wilshire Advisors LLC is the 3(38) plan fiduciary. See
                      “Investment lineup” in this summary.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Immediate vesting.</strong> Unless different
                      vesting schedules are listed above, employees are 100%
                      vested in the 401(k) plan immediately.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Roth contributions.</strong> Unless indicated
                      otherwise above, participants can choose to make both
                      pre-tax and Roth (after-tax) contributions to their 401(k)
                      plan—giving them more ways to get the most out of their
                      retirement savings. Auto-enrollment contributions are only
                      pre-tax contributions.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Loans.</strong> Unless indicated otherwise above,
                      participants can request to take a loan from their 401(k)
                      plan balance and select a loan repayment schedule that
                      best suits them. Only one loan may be outstanding at a
                      time. Loan repayments are made via after-tax payroll
                      deductions. The interest portion of the loan payment is
                      applied to the participant&apos;s account.
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Rollovers.</strong> Participants can roll over
                      eligible accounts into their 401(k) plan. (Rollover
                      contributions can be distributed at any time.)
                    </p>
                  </div>
                  <div className="col mb-3">
                    <p className="fs-p">
                      <strong>Financial wellness.</strong> Participants will
                      have access to a comprehensive financial wellness platform
                      that provides tools and resources to employees to better
                      manage their current and future financial well-being.
                    </p>
                  </div>
                </div>
              </section>
              <section className="section section_py-5 pt-0" id="billing">
                <img
                  src={IconCalendar}
                  alt=""
                  width="80"
                  height="80"
                  className="mb-2 d-block"
                />
                <h5 className="fs-h2 fw-bold text-purple mb-4">
                  Billing schedule
                </h5>

                {!hasTpa && (
                  <p className="fs-h2">
                    The{' '}
                    <strong>
                      one-time $500 setup fee will be charged immediately
                    </strong>{' '}
                    to the payment method entered when the business owner
                    purchases the plan.
                  </p>
                )}

                <p className="fs-p">
                  The business owner will be charged for three months of plan
                  fees on the first day of the next month, and every three
                  months thereafter. Each quarterly bill will include three
                  months of the $145 or $185/month recordkeeping fee ($435 or
                  $555 billed quarterly), and if elected, three months of the
                  $6/month per participant fee. If applicable, the financial
                  professional&apos;s fee will be deducted quarterly from
                  participant accounts. If working with a TPA, the TPA’s fees
                  will be billed separately.
                </p>

                <p className="fs-p">
                  If the business owner elects to have employees cover the
                  monthly $6 per participant fee, it will be deducted from
                  participants&apos; plan assets monthly. Specific deadlines
                  apply to safe harbor plans, and this may impact billing and
                  subscription effective dates.
                </p>
              </section>
              <section className="section section_py-5 pt-0" id="investments">
                <img
                  src={IconChart}
                  alt=""
                  width="80"
                  height="80"
                  className="mb-2 d-block"
                />
                <h5 className="fs-h2 fw-bold text-purple mb-4">
                  Investments offered
                </h5>
                <p className="fs-h2">
                  <strong>Wilshire Advisors LLC</strong>, Inc., will be the
                  plan’s investment advisor.
                </p>
                <p className="fs-p">
                  Legally called a 3(38), Wilshire Advisors LLC has an
                  exceptional track record in selecting and managing
                  high-performing investment options, which can benefit those
                  served.
                </p>
                <p className="fs-p">
                  Choosing investments for a retirement plan comes with a lot of
                  responsibility; it&apos;s called being a
                  &ldquo;fiduciary&rdquo; and fiduciaries are personally liable
                  for those choices. As part of the plan, Wilshire Advisors LLC,
                  will act as a fiduciary to the plan. Wilshire Associates,
                  Inc., will have discretion for picking the investment options
                  for a plan, monitoring, and making changes when they deem
                  appropriate.
                </p>
                <p className="fs-p">
                  <strong>
                    Wilshire Advisors LLC, is an independent third-party
                    investment management firm that offers consulting services
                    and analytical products, as well as managing &ldquo;fund of
                    funds&rdquo; investment vehicles for a global client base.
                  </strong>{' '}
                  The firm manages capital for more than 600 institutional
                  investors globally, representing more than $8 trillion of
                  capital.
                </p>
                <p className="fs-h2 mb-5">
                  Employees have the option to choose their investments from the
                  options below:
                </p>
                <InvestmentLineUp />
              </section>
            </div>
          </div>
        </div>
        <p className="my-5 text-center border-bottom d-print-none">
          <a
            href="#additionalPlanInfo"
            className="srp-button srp-button__outline px-md-5 mb-n4"
            onClick={() => {
              toggleAdditionalInfo();
            }}
          >
            {additionalInfoVisibility ? 'Hide ' : 'View '}
            additional plan &amp; investment information
          </a>
        </p>
      </div>
    </>
  );
};

AdditionalPlanInfo.propTypes = {
  hasTpa: PropTypes.bool.isRequired,
};

export default AdditionalPlanInfo;
