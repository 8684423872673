import React from 'react';
import PropTypes from 'prop-types';
import { Switch, useRouteMatch } from 'react-router-dom';
import AppRoute from '../../../AppRoute';

// Layouts
import ApplicationLayout from '../../ApplicationLayout';

// Pages
import Resources from './Resources';
import NewDigital401KSolution from './NewDigital401KSolution';
import TipsHelpGrowPractice from './TipsHelpGrowPractice';
import SmallBusinessTaxAdvantages from './SmallBusinessTaxAdvantages';

const AdvisorResources = ({ paradigmHost }) => {
  const { url } = useRouteMatch();
  const advisorResources = [
    {
      key: 'financial-professionals/resources',
      component: Resources,
      title: 'Setting up 401k for small business',
      description:
        'Simply Retirement by Principal<small><sup>®</sup></small> helps make setting up a 401(k) for small business clients easy. Utilize these resources and make the most of market opportunities.',
    },
    {
      key: 'new-digital-401k-solution',
      component: NewDigital401KSolution,
      title: 'Setting up 401k for small business',
      description:
        'Simply Retirement by Principal<small><sup>®</sup></small> helps make setting up a 401(k) for small business clients easy. Utilize these resources and make the most of market opportunities.',
    },
    {
      key: '5-tips-to-help-grow-your-practice',
      component: TipsHelpGrowPractice,
      title: 'Setting up 401k for small business',
      description:
        'Simply Retirement by Principal<small><sup>®</sup></small> helps make setting up a 401(k) for small business clients easy. Utilize these resources and make the most of market opportunities.',
    },
    {
      key: 'small-business-startup-plan-tax-advantages',
      component: SmallBusinessTaxAdvantages,
      title: 'Setting up 401k for small business',
      description:
        'Simply Retirement by Principal<small><sup>®</sup></small> helps make setting up a 401(k) for small business clients easy. Utilize these resources and make the most of market opportunities.',
    },
  ];

  return (
    <Switch>
      {advisorResources.map((route) => {
        const keyPath = url === `/${route.key}` ? url : `${url}/${route.key}`;
        return (
          <AppRoute
            key={route.key}
            exact
            path={`${keyPath}`}
            layout={ApplicationLayout}
            paradigmHost={paradigmHost}
            {...route}
          />
        );
      })}
    </Switch>
  );
};

AdvisorResources.propTypes = {
  paradigmHost: PropTypes.string,
};

export default AdvisorResources;
