import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import FormDataRedux from '../../Redux/FormDataRedux';

export const Step = ({
  component: Component,
  active,
  stepAnimDirection,
  hasSidebar,
  name,
  resetData,
  ...rest
}) => {
  const sendDataToGTM = useGTMDispatch();
  const [inAnimation, setInAnimation] = useState(true);
  const animateDirection = () => {
    if (stepAnimDirection === 'forward') {
      return 'fadeInRight';
    }
    return 'fadeInLeft';
  };

  const location = useLocation();

  React.useEffect(() => {
    const stripPlanId = () => {
      const urlArr = location.pathname.split('/');
      if (urlArr.length >= 3) urlArr.pop();
      return urlArr.join('/');
    };
    sendDataToGTM({
      event: 'routeChange',
      pagePath: `${stripPlanId()}#${name}`,
      pageTitle: 'Set Up Plan',
    });
  }, [name]);

  return (
    <fieldset
      className={`fws__step ${active ? 'active' : ''} ${animateDirection()}`}
      style={{
        animationDuration: '.2s',
        animationDelay: '0',
      }}
      onAnimationEnd={() => setInAnimation(false)}
    >
      <Component
        name={name}
        hasSidebar={hasSidebar}
        active={inAnimation}
        {...rest}
      />
    </fieldset>
  );
};

Step.propTypes = {
  component: PropTypes.any.isRequired,
  name: PropTypes.string,
  hasSidebar: PropTypes.bool,
  stepAnimDirection: PropTypes.string,
  active: PropTypes.bool,
  resetData: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  resetData: () => {
    dispatch(FormDataRedux.resetData());
  },
});

export default connect(null, mapDispatchToProps)(Step);
