import React from 'react';
import PropTypes from 'prop-types';
import {
  NextStepBtn,
  PrevStepBtn,
  Question,
  checkValidityOnQuestionArray,
} from '../../../../common/FormWithSteps';
import AdvisorAuthHeader from './Header';
import Dropdown from '../../../../common/FormWithSteps/Question/Dropdown';

const WarningModal = ({ copy }) => (
  <div className="text-center my-5">
    <h1 className="fs-h1 text-center mb-3">
      Working with a third party administrator
    </h1>
    <div
      className="fs-p text-center mb-4 lh-125"
      dangerouslySetInnerHTML={{ __html: copy }}
    />
  </div>
);
WarningModal.propTypes = {
  copy: PropTypes.string.isRequired,
};

const Template = ({
  active = false,
  previousStep = '',
  questions = {},
  updateActiveStep = () => {},
  role = '',
  handleQuestionChange,
  handleQuestionChangeState,
  nextStepAction,
  advisorQuestions,
  userRole = '',
  putApplicationPanelInfo,
}) => {
  const {
    employerStateCode,
    insuranceGroupType,
    companyName,
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
  } = questions;

  return (
    <div className="container-fluid--sitewidth h-100">
      <AdvisorAuthHeader step={1} />
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <fieldset id="stepClientInfo">
            <div className="row">
              <div className="col-md">
                <p className="text-center">
                  The client’s information will be used in legal documents.
                  Please ensure the information entered is correct.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  userRole={userRole}
                  type={sponsorFirstName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorFirstName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorFirstName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
              <div className="col-md">
                <Question
                  role={role}
                  userRole={userRole}
                  type={sponsorLastName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorLastName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorLastName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  type={sponsorEmail.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorEmail}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorEmail.hasError && <small>Question is required</small>}
                </Question>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  type={companyName.type}
                  labelClass="fs-form-label fw-bold text-center"
                  inputClass="srp-form-input form-control"
                  question={companyName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {companyName.hasError && <small>Question is required</small>}
                  <button
                    type="button"
                    className="srp-button__link"
                    onClick={() =>
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: 'Need clarification?',
                        additionalInformation: `Please provide the legal business name for use on plan documents. The legal name is the formal, official name that identifies the company with the government (for example, on the company’s tax ID paperwork and tax returns). If the company is a corporation, the name may contain the designation “Inc.” If it’s a Limited Liability Company, the name may conclude with "LLC."`,
                      })
                    }
                  >
                    Need clarification?
                  </button>
                </Question>
              </div>
              <div className="col-md">
                <Question
                  role={role}
                  userRole={userRole}
                  type={employerStateCode.type}
                  labelClass="fs-form-label fw-bold text-center"
                  inputClass="srp-form-input form-control"
                  question={employerStateCode}
                  handleQuestionChange={handleQuestionChangeState}
                >
                  {companyName.hasError && <small>Question is required</small>}
                </Question>
              </div>
              {employerStateCode.value === 'TX' && (
                <div className="col-md">
                  <Dropdown
                    name={insuranceGroupType.name}
                    label={insuranceGroupType.label}
                    labelClass="fs-form-label fw-bold"
                    inputClass="srp-form-dropdown form-control"
                    options={insuranceGroupType.options}
                    value={insuranceGroupType.value}
                    required
                    hasError={insuranceGroupType.hasError}
                    handleQuestionChange={handleQuestionChange}
                  />
                </div>
              )}
            </div>
          </fieldset>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(advisorQuestions)}
              onClick={() => nextStepAction()}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

Template.propTypes = {
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  role: PropTypes.string,
  userRole: PropTypes.string,
  handleQuestionChange: PropTypes.func.isRequired,
  advisorQuestions: PropTypes.array.isRequired,
  handleQuestionChangeState: PropTypes.func.isRequired,
  nextStepAction: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

export default Template;
