import React from 'react';
import PropTypes from 'prop-types';
import { Question } from '../../../../common/FormWithSteps';

const AqacaMaxAutoEscalateQuestion = ({
  role,
  handleQuestionChange,
  primaryQuestion,
  secondaryQuestion,
  tertiaryQuestion,
}) => {
  const autoEscalate = primaryQuestion.value === 'yes';

  return (
    <div className="row">
      <div className="col-md-9 mx-auto">
        <Question
          userRole={role}
          type={primaryQuestion.type}
          question={primaryQuestion}
          handleQuestionChange={handleQuestionChange}
          labelClass="fs-form-q fw-bold text-center py-3"
          inputClass="srp-form-input form-control"
          radioWrapper="srp-form-toggle"
        />
        {autoEscalate && (
          <Question
            role={role}
            type={secondaryQuestion.type}
            question={{ ...secondaryQuestion, min: 3 }}
            handleQuestionChange={handleQuestionChange}
          />
        )}
        {autoEscalate && (
          <Question
            role={role}
            type={tertiaryQuestion.type}
            question={tertiaryQuestion}
            handleQuestionChange={handleQuestionChange}
          />
        )}
        {autoEscalate && (
          <p>
            The SECURE 2.0 Act relaxed the ADP/ACP safe harbor rules by
            increasing the automatic enrollment escalation cap from 10% to 15%
            for QACA plans. The 10% cap is maintained for the initial period in
            which the employee is automatically enrolled and then increased to
            15% after the initial period.
          </p>
        )}
        {!autoEscalate && (
          <Question
            role={role}
            type={secondaryQuestion.type}
            question={secondaryQuestion}
            handleQuestionChange={handleQuestionChange}
          />
        )}
      </div>
    </div>
  );
};

AqacaMaxAutoEscalateQuestion.propTypes = {
  primaryQuestion: PropTypes.object.isRequired,
  secondaryQuestion: PropTypes.object.isRequired,
  tertiaryQuestion: PropTypes.object.isRequired,
  role: PropTypes.string,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default AqacaMaxAutoEscalateQuestion;
