export const autoEnrollmentPlan = {
  id: 'q6aI',
  type: 'radio_condensed',
  name: 'autoEnrollmentPlan',
  paradigmAlias: 'autoEnrollmentPlan',
  stepName: 'a_step_6',
  user: {},
  label: 'Which automatic enrollment option will the plan use?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: 'ACA',
      value: 'aca',
      valueFormatted: 'ACA',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: 'EACA',
      value: 'eaca',
      valueFormatted: 'EACA',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: 'QACA',
      value: 'qaca',
      valueFormatted: 'QACA',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Auto enrollment option',
  reviewScreenTitle: 'Auto enrollment option',
  optionDescription: '',
  description:
    'Automatic enrollment applies only to pre-tax deferrals, not Roth contributions.',
};
