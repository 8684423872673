import React from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import { HeroWrapper } from './index.styles';

const Sitemap = ({ paradigmHost }) => (
  <div className="about-page pb-5" style={{ marginBottom: '16vw' }}>
    <HeroWrapper className="hero mb-5 pt-5">
      <div className="container-fluid container-fluid--sitewidth">
        <div className="row justify-content-center py-5">
          <h1 className="fs-h1 text-center fw-bold py-5 col-xl-6 col-lg-8 col-md-10 mt-5">
            Sitemap
          </h1>
        </div>
      </div>
    </HeroWrapper>

    <div className="container">
      <div className="row">
        <div className="col-md-10 col-lg-8 col-xl-6 mx-auto pb-5">
          <div className="row pt-3">
            <div className="col-12 col-sm-6">
              <p className="fs-h2 fw-bold lh-125">Business owners</p>
              <ul>
                <li className="fs-h2">
                  <Link to="/business-owners">Home</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/business-owners/about">About</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/business-owners/retirement-plans">401(k)</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/business-owners/investments">Investments</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/business-owners/resources">Resources</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/business-owners/why-simply-retirement">
                    Why choose us?
                  </Link>
                </li>
                <li className="fs-h2">
                  <Link to="/plans">Start a plan</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/business-owners/learn-the-basics">
                    Learn the Basics
                  </Link>
                </li>
                <li className="fs-h2">
                  <Link to="/business-owners/faq">
                    Frequently Asked Questions
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-6">
              <p className="fs-h2 fw-bold lh-125">Financial professionals</p>
              <ul>
                <li className="fs-h2">
                  <Link to="/financial-professionals">Home</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/financial-professionals/about">About</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/financial-professionals/retirement-plans">
                    401(k)
                  </Link>
                </li>
                <li className="fs-h2">
                  <Link to="/financial-professionals/investments">
                    Investments
                  </Link>
                </li>
                <li className="fs-h2">
                  <Link to="/financial-professionals/resources">Resources</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/financial-professionals/why-simply-retirement">
                    Why choose us?
                  </Link>
                </li>
                <li className="fs-h2">
                  <Link to="/plans">Create a proposal</Link>
                </li>
                <li className="fs-h2">
                  <Link to="/financial-professionals/faq">
                    Frequently Asked Questions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-auto">
          <ul>
            <li className="fs-h2">
              <Link to="/disclosures">Disclosures</Link>
            </li>
            <li className="fs-h2">
              <Link to="/roi-calculator">ROI Calculator</Link>
            </li>
            <li className="fs-h2">
              <Link to="/safeharbor-explained">Safe Harbor Explained</Link>
            </li>
            <li className="fs-h2">
              <a href={paradigmHost}>Your Account Resources</a>
            </li>
            <li className="fs-h2">
              <a href="https://www.principal.com/terms-of-use">Terms of Use</a>
            </li>
            <li className="fs-h2">
              <a href="https://www.principal.com/privacy-policies">Privacy</a>
            </li>
            <li className="fs-h2">
              <a href="https://www.principal.com/security-policies">Security</a>
            </li>
            <li className="fs-h2">
              <Link to="/manage-cookie-settings">Cookies settings</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

Sitemap.propTypes = {
  paradigmHost: PropTypes.string,
};

export default Sitemap;
