import React from 'react';
import PropTypes from 'prop-types';

const IconBusinessOwnerO = ({ fill }) => (
  <g>
    <path
      d="M24.4531 19.9531L34.5781 9.92188C35.1406 9.35938 35.1406 8.32812 34.5781 7.76562L32.2344 5.42188C31.6719 4.85938 30.6406 4.85938 30.0781 5.42188L20.0469 15.5469L9.92188 5.42188C9.35938 4.85938 8.32812 4.85938 7.76562 5.42188L5.42188 7.76562C4.85938 8.32812 4.85938 9.35938 5.42188 9.92188L15.5469 19.9531L5.42188 30.0781C4.85938 30.6406 4.85938 31.6719 5.42188 32.2344L7.76562 34.5781C8.32812 35.1406 9.35938 35.1406 9.92188 34.5781L20.0469 24.4531L30.0781 34.5781C30.6406 35.1406 31.6719 35.1406 32.2344 34.5781L34.5781 32.2344C35.1406 31.6719 35.1406 30.6406 34.5781 30.0781L24.4531 19.9531Z"
      className="primary-fill"
      fill={fill}
    />
  </g>
);

IconBusinessOwnerO.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconBusinessOwnerO;
