import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ModalRedux from '../../Redux/ModalRedux';

const Modal = ({ modalVisibility, child, destroyModal }) => {
  let modalRef;
  const customStyles = {
    overlay: {
      background: 'rgba(5, 5, 5, 0.5)',
    },
  };

  useEffect(() => {
    ReactModal.setAppElement(modalRef);
  }, []);

  return (
    <ReactModal
      className="Modal__Bootstrap modal-dialog modal-dialog-centered modal-lg"
      closeTimeoutMS={150}
      isOpen={modalVisibility}
      onRequestClose={() => destroyModal()}
      ariaHideApp={false}
      shouldCloseOnEsc
      style={customStyles}
    >
      <div
        ref={(arg) => {
          modalRef = arg;
        }}
        className="modal-content"
      >
        <div className="modal-header">
          <button
            type="button"
            aria-label="close"
            className="fa-solid fa-xmark fa-2x text-dusty no-border-button close-btn"
            onClick={() => {
              destroyModal();
            }}
          />
        </div>
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 mx-auto">{child}</div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  child: PropTypes.any.isRequired,
  destroyModal: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  modalVisibility: store.modalData.modalVisibility,
  child: store.modalData.child,
});

const mapDispatchToProps = (dispatch) => ({
  destroyModal: () => {
    dispatch(ModalRedux.destroyModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
