export const getEntryDateRequirement = (
  entryDatePlan,
  entryDateDiscretionary,
) => {
  let entryDate;
  if (entryDatePlan.value === 'dailyImmediate') {
    entryDate = entryDateDiscretionary;
  } else if (entryDatePlan.value === 'monthly') {
    entryDate =
      entryDateDiscretionary.value !== 'dailyImmediate'
        ? entryDateDiscretionary
        : entryDatePlan;
  } else if (entryDatePlan.value === 'quarterly') {
    entryDate =
      entryDateDiscretionary.value !== 'dailyImmediate' &&
      entryDateDiscretionary.value !== 'monthly'
        ? entryDateDiscretionary
        : entryDatePlan;
  } else {
    entryDate = entryDatePlan;
  }
  return entryDate;
};
