import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({ text, classes, clickEvent, children, disabled }) => (
  <button
    disabled={disabled}
    type="button"
    className={classNames('srp-button', classes)}
    onClick={() => {
      clickEvent();
    }}
  >
    {text || children}
  </button>
);

Button.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.string,
  clickEvent: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
};

export default Button;
