import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectAdvisorQuestions,
} from '../../../../../Redux/FormDataRedux';
import { Question } from '../../../../../common/FormWithSteps';

const ClientQuestions = ({
  handleQuestionChange,
  primaryAdvisor,
  putApplicationPanelInfo,
  role,
  uniqueId,
}) => {
  const { advisorEmail, advisorFirstName, advisorLastName, advisorStateCode } =
    primaryAdvisor.questions;

  return (
    <div className="row">
      <div className="col-md-7 mx-auto">
        <fieldset id="AddAdvisor" className="mb-4">
          <Question
            type={advisorFirstName.type}
            role={role}
            lable={advisorFirstName.label}
            labelClass="fs-form-label fw-bold text-center"
            inputClass="srp-form-input form-control"
            question={advisorFirstName}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChange({
                name,
                updateValue,
                valueFormatted,
                index: 0,
              });
            }}
          >
            {advisorFirstName.hasError && <small>Question is required</small>}
          </Question>
          <Question
            type={advisorLastName.type}
            labelClass="fs-form-label fw-bold text-center"
            inputClass="srp-form-input form-control"
            question={advisorLastName}
            role={role}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChange({
                name,
                updateValue,
                valueFormatted,
                index: 0,
              });
            }}
          >
            {advisorLastName.hasError && <small>Question is required</small>}
          </Question>
          <Question
            type={advisorEmail.type}
            role={role}
            labelClass="fs-form-label fw-bold text-center"
            inputClass="srp-form-input form-control"
            question={advisorEmail}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChange({
                name,
                updateValue,
                valueFormatted,
                index: 0,
              });
            }}
          >
            {advisorEmail.hasError && <small>Question is required</small>}
          </Question>
          <div className="form-group">
            <label
              className="fs-form-label fw-bold"
              htmlFor={uniqueId(advisorStateCode.name, 0)}
            >
              {advisorStateCode.label}
            </label>
            <select
              id={uniqueId(advisorStateCode.name, 0)}
              name="state"
              autoComplete="address-level1"
              value={advisorStateCode.value}
              className="custom-select srp-form-input form-control form-select"
              onChange={(selected) => {
                handleQuestionChange({
                  name: advisorStateCode.name,
                  updateValue: selected.target.value,
                  valueFormatted: selected.target.value,
                  index: 0,
                });
              }}
            >
              <option>Select</option>
              {advisorStateCode.options.map((option) => (
                <option key={option.id} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>{' '}
            {advisorStateCode.hasError && <small>Question is required</small>}
          </div>
          <p className="fs-p text-center">
            <button
              type="button"
              className="srp-button srp-button__link"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'Why are we asking?',
                  additionalInformation: `If your client is working with a financial professional, the financial professional can select one of three Wilshire 3(38) investment option lineups for the plan. Once you have made all other selections, you will be prompted to share the plan design with your client’s financial professional to make the investment lineup selection. The financial professional will share the plan back to you once the investment lineup selection is complete so you can share the plan directly with your client for approval and purchase.`,
                })
              }
            >
              Why are we asking?
            </button>
          </p>
        </fieldset>
      </div>
    </div>
  );
};

ClientQuestions.propTypes = {
  questions: PropTypes.object,
  primaryAdvisor: PropTypes.object,
  handleQuestionChange: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  uniqueId: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  advisorInfo: selectAdvisorQuestions(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientQuestions);
