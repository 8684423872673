export function billingPrefState(compensationType) {
  switch (compensationType) {
    case 'insidePlan':
      return [
        {
          name: 'advisorCompensation',
          value: 'option_2',
          hasError: false,
          dirty: true,
          valueFormatted: 'Deducted from participant accounts',
          disabled: true,
        },
        {
          name: 'advisorType',
          value: '',
          hasError: false,
          dirty: true,
          valueFormatted: '',
          disabled: false,
        },
        {
          name: 'advisorFee',
          value: '',
          hasError: false,
          dirty: false,
          valueFormatted: '',
          disabled: false,
        },
        {
          name: 'advisorPoints',
          value: '',
          hasError: false,
          dirty: false,
          valueFormatted: '',
          disabled: false,
        },
      ];
    case 'insidePlanBasisPoints':
      return [
        {
          name: 'advisorCompensation',
          value: 'option_2',
          hasError: false,
          dirty: true,
          valueFormatted: 'Deducted from participant accounts',
          disabled: true,
        },
        {
          name: 'advisorType',
          value: 'option_2',
          hasError: false,
          dirty: true,
          valueFormatted: 'Basis Points',
          disabled: true,
        },
        {
          name: 'advisorFee',
          value: '',
          hasError: false,
          dirty: true,
          valueFormatted: '',
          disabled: false,
        },
        {
          name: 'advisorPoints',
          value: '',
          hasError: false,
          dirty: false,
          valueFormatted: '',
          disabled: false,
        },
      ];
    case 'insidePlanFlatFee':
      return [
        {
          name: 'advisorCompensation',
          value: 'option_2',
          hasError: false,
          dirty: true,
          valueFormatted: 'Deducted from participant accounts',
          disabled: true,
        },
        {
          name: 'advisorType',
          value: 'option_1',
          hasError: false,
          dirty: true,
          valueFormatted: 'Flat Fee',
          disabled: true,
        },
        {
          name: 'advisorFee',
          value: '',
          hasError: false,
          dirty: false,
          valueFormatted: '',
          disabled: false,
        },
        {
          name: 'advisorPoints',
          value: '',
          hasError: false,
          dirty: true,
          valueFormatted: '',
          disabled: false,
        },
      ];
    case 'outsidePlan':
      return [
        {
          name: 'advisorCompensation',
          value: 'option_1',
          hasError: false,
          dirty: true,
          valueFormatted: 'Billed separately by you',
          disabled: true,
        },
        {
          name: 'advisorType',
          value: '',
          hasError: false,
          dirty: true,
          valueFormatted: '',
          disabled: false,
        },
        {
          name: 'advisorFee',
          value: '',
          hasError: false,
          dirty: true,
          valueFormatted: '',
          disabled: false,
        },
      ];
    case 'variousWithBasisPoints':
      return [
        {
          name: 'advisorCompensation',
          value: '',
          hasError: false,
          dirty: false,
          valueFormatted: '',
          disabled: false,
        },
        {
          name: 'advisorType',
          value: 'option_2',
          hasError: false,
          dirty: true,
          valueFormatted: 'Basis Points',
          disabled: true,
        },
        {
          name: 'advisorFee',
          value: '',
          hasError: false,
          dirty: true,
          valueFormatted: '',
          disabled: true,
        },
        {
          name: 'advisorPoints',
          value: '',
          hasError: false,
          dirty: true,
          valueFormatted: '',
          disabled: false,
        },
      ];
    case 'reset':
    default:
      return [
        {
          name: 'advisorCompensation',
          value: '',
          hasError: false,
          dirty: false,
          valueFormatted: '',
          disabled: false,
        },
        {
          name: 'advisorType',
          value: '',
          hasError: false,
          dirty: false,
          valueFormatted: '',
          disabled: false,
        },
        {
          name: 'advisorFee',
          value: '',
          hasError: false,
          dirty: false,
          valueFormatted: '',
          disabled: false,
        },
        {
          name: 'advisorPoints',
          value: '',
          hasError: false,
          dirty: true,
          valueFormatted: '',
          disabled: false,
        },
      ];
  }
}
