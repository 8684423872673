import React from 'react';
import { Link } from 'react-router-dom';
import {
  IconCheckCircleO,
  IconMoneyO,
  iconDownloadPurpleO,
  iconArrow,
} from '../../../../ImagePaths';
import { ScrollInToView } from '../../../../common/VisibilitySensor';

const CallToActionSection = () => (
  <section className="pb-5 position-relative bg-concrete">
    <div
      className="bg-purple"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '200px',
      }}
    />

    <div className="container">
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <div
            className="bg-pattern index-0"
            style={{
              borderRadius: '50px',
              backgroundColor: '#ebeced',
              overflow: 'hidden',
            }}
          >
            <div className="row mx-0  my-5">
              <div className="col-xl-8 col-lg-10 mx-auto">
                <h3 className="fs-h4 text-center">
                  Ready to explore a 401(k) plan for your practice?
                </h3>
                <h3 className="fs-h4 text-center">
                  Here are the next steps we would take together:
                </h3>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-lg-11 mx-auto">
                <div className="row">
                  <div className="col-6 col-md-3">
                    <img
                      className="d-block mx-auto mb-3"
                      src={IconCheckCircleO}
                      alt="Check Mark Icon"
                      width={65}
                      height={85}
                    />
                    <p className="fs-p mb-5 text-center">
                      <strong>
                        Answer a few questions to see if this solution fits your
                        needs.
                      </strong>
                    </p>
                  </div>
                  <div className="col-6 col-md-3">
                    <img
                      className="d-block mx-auto mb-3"
                      src={iconDownloadPurpleO}
                      alt="Download Icon"
                      width={80}
                      height={85}
                    />
                    <p className="fs-p mb-5 text-center">
                      <strong>
                        You can share your plan proposal with your team.
                      </strong>
                    </p>
                  </div>
                  <div className="col-6 col-md-3">
                    <img
                      className="d-block mx-auto mb-3"
                      src={iconArrow}
                      alt="Arrow Icon"
                      width={70}
                      height={85}
                    />
                    <p className="fs-p mb-5 text-center">
                      <strong>
                        Complete your plan purchase and get your login.
                      </strong>
                    </p>
                  </div>
                  <div className="col-6 col-md-3">
                    <img
                      className="d-block mx-auto mb-3"
                      src={IconMoneyO}
                      alt="Money Icon"
                      width={85}
                      height={85}
                    />
                    <p className="fs-p mb-5 text-center">
                      <strong>Enroll employees and start saving.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <ScrollInToView
                renderItem={(inView) => (
                  <Link
                    style={{
                      animationDelay: '0.2s',
                      animationDuration: '0.4s',
                      marginBottom: '60px',
                    }}
                    className={
                      inView
                        ? 'srp-button srp-button__primary px-5 mb-5 fadeInUp animated'
                        : 'srp-button srp-button__primary px-5 mb-5 hidden'
                    }
                    to="/psivet/plans"
                  >
                    Try our planner
                  </Link>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default CallToActionSection;
