import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import FormDataRedux from '../../../Redux/FormDataRedux';
import { Question, NextStepBtn } from '../../../common/FormWithSteps';
import {
  getDate,
  requireConfirmation,
} from '../../../Services/helpers/Paradigm/planHelper';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import { effectiveMinMaxDate } from '../../../Services/planEffectiveDate';

export const PlanEffectiveDate = ({
  role,
  active,
  questionId,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestion,
  formInReview,
  goToReviewPlan,
  googleData,
}) => {
  const { dirty, hasError, info, infoDate } = questions[questionId];
  const validate = !!(dirty && hasError);
  let pastDeadline = requireConfirmation(
    questions.safeHarbor,
    questions[questionId].value.split('-'),
  );
  const isSafeHarbor = questions.safeHarbor.value === 'safeHarbor';
  const startFirstOfYear = {
    value: getDate(new Date().getFullYear() + 1, '1', '1'),
    valueFormatted: infoDate,
  };

  const isError = (date) => {
    if (!moment(date).isValid()) {
      return { hasError: true, errorMsg: 'Date is invalid.' };
    }
    if (moment(date).isBefore(effectiveMinMaxDate(isSafeHarbor).min)) {
      return {
        hasError: true,
        errorMsg: effectiveMinMaxDate(isSafeHarbor).valueFormatted,
      };
    }
    if (moment(date).isAfter(effectiveMinMaxDate().max)) {
      return { hasError: true, errorMsg: 'Date is too far into the future.' };
    }
    if (requireConfirmation(questions.safeHarbor, new Date(date))) {
      return { hasError: true, errorMsg: '' };
    }
    return { hasError: false, errorMsg: '' };
  };

  const handleQuestionChange = (id, updateValue) => {
    pastDeadline = requireConfirmation(
      questions.safeHarbor,
      questions[questionId].value.split('-'),
    );
    updateQuestion({
      name: id,
      value: updateValue,
      hasError: isError(updateValue).hasError,
      errorMsg: isError(updateValue).errorMsg,
      dirty: true,
      valueFormatted: updateValue,
    });
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
    if (questions.currentPlan.value === 'SIMPLE') {
      handleQuestionChange(questions[questionId].name, startFirstOfYear.value);
    }
  }, []);

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-md-10 col-xl-8 d-flex flex-column mx-auto">
          <Question
            role={role}
            type={questions[questionId].type}
            labelClass="fs-form-q fw-bold text-center pt-3 px-3 d-block"
            inputClass="date srp-form-input form-control mx-auto w-50"
            question={questions[questionId]}
            handleQuestionChange={handleQuestionChange}
            hasInfo={!!questions[questionId].heading}
            min={effectiveMinMaxDate(isSafeHarbor).min}
            max={effectiveMinMaxDate().max}
            inputLabel={questions[questionId].inputLabel}
          />
          {validate && !pastDeadline && (
            <p className="text-danger text-center">
              {questions[questionId].errorMsg}
            </p>
          )}
          {pastDeadline && (
            <div
              className="mt-5 fs-p text-center"
              dangerouslySetInnerHTML={{ __html: info }}
            />
          )}
          {questions.currentPlan.value === 'SIMPLE' && (
            <p className="mx-5 mt-5 text-center">
              Your plan effective date can be as soon as Jan. 1, following the
              termination of your {questions.currentPlan.value} plan.
            </p>
          )}
          <p className="text-center">
            <NextStepBtn
              text="Review your plan"
              disabled={!(dirty && !hasError)}
              onClick={() => {
                goToReviewPlan();
              }}
            />
          </p>{' '}
        </div>
      </div>
      <p
        className={classNames({
          fws__control: !formInReview,
          'fws__control-group': formInReview,
        })}
      >
        <button
          type="button"
          className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
          onClick={() => {
            updateActiveStep(previousStep, 'back');
          }}
        >
          <span className="sr-only">Go Back</span>
        </button>
      </p>
    </div>
  );
};

PlanEffectiveDate.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  googleData: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
});

export default connect(null, mapDispatchToProps)(PlanEffectiveDate);
