import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { NextStepBtn } from '../../../common/FormWithSteps';
import { iconPlaybookO } from '../../../ImagePaths';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';

export const LetsBegin = ({
  updateActiveStep,
  destination,
  hashId,
  planOwner,
  createdDate,
  googleData,
  updatedDate,
}) => {
  const sendDataToGTM = useGTMDispatch();
  const expiryDate = moment(updatedDate).add(60, 'days').format('MMMM Do YYYY');
  const backupExpireDate = moment(createdDate)
    .add(60, 'days')
    .format('MMMM Do YYYY');
  const textToggle = () => {
    switch (hashId) {
      case '':
        return (
          <h3 className="fs-form-q fw-bold text-center mb-4vw">
            Welcome to your Simply Retirement by Principal
            <small>
              <sup>&reg;</sup>
            </small>{' '}
            with OneDigital planner.
          </h3>
        );

      default:
        return (
          <>
            <h3 className="fs-form-q fw-bold text-center mb-4">
              You have a Simply Retirement by Principal
              <small>
                <sup>&reg;</sup>
              </small>{' '}
              with OneDigital plan to view.
            </h3>
            <p className="fs-h3">
              We just need a little information to continue.
            </p>
            {(!!updatedDate || !!createdDate) && (
              <p className="fs-p text-center px-5 mx-5">
                This plan was originally created by <strong>{planOwner}</strong>{' '}
                and is valid until{' '}
                <strong>{expiryDate || backupExpireDate}</strong>
              </p>
            )}
          </>
        );
    }
  };
  useEffect(() => {
    sendDataToGTM({
      event: 'e_toolInteraction',
      eventType: 'progress',
      tool: {
        name: 'Simply Retirement',
        stepNumber: '1',
        stepName: 'begin',
      },
    });

    pushGoogleDataLayer(googleData);
  }, []);
  return (
    <div className="container-fluid h-100">
      <div className="row h-100 flex-column justify-content-between">
        <div className="col-md-7 m-auto">
          <div className="text-center">
            <div className="mb-4vw">
              <img src={iconPlaybookO} alt="Playbook Icon" height="150" />
            </div>
            {textToggle()}
            <p className="fws__control">
              <NextStepBtn
                role="button"
                id="next"
                text="Let’s begin"
                onClick={() => {
                  updateActiveStep(destination(), 'forward');
                }}
              />
            </p>
            <p className="fs-p">
              Need to manage an existing plan or proposal?{' '}
              <a
                href="https://secure.simplyretirement.com/login"
                target="_blank"
              >
                Log in
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

LetsBegin.propTypes = {
  updateActiveStep: PropTypes.func,
  destination: PropTypes.func,
  hashId: PropTypes.string,
  planOwner: PropTypes.string,
  createdDate: PropTypes.string,
  googleData: PropTypes.object,
  updatedDate: PropTypes.string,
};

export default LetsBegin;
