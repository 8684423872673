import React from 'react';
import { Link } from 'react-router-dom';
import Question from './Question';
import {
  IconDollar,
  IconPiggyBank,
  IconUserGraphO,
  IconMultipleUsers,
  IconRocketBlue,
} from '../../../../ImagePaths';

const QuestionsSet1 = [
  {
    id: 1,
    src: IconUserGraphO,
    anchor: 'features-owner',
    title: `Features for business owners`,
    desc: `
    <div class="row">
    <div class="mb-4 col-md-12">
      <p>
        <p><span class="fs-p mb-0">The Simply Retirement by Principal<sup><small>®</small></sup> 401(k) plan requires employees to be automatically enrolled at a default pre-tax contribution percentage set by the business owner. The default contribution rate must be at least 3%, but not more than 10%, plus an automatic contribution increase of 1% per year up to a maximum of at least 10%, but not more than 15%. Employees can change this amount or opt out at any time.</span></p>
        <p><span class="fs-p">To be eligible, employees must be age 21 or older and meet the employment requirement you set. Whether it's their first day of work, after three months or work, or on their first employment anniversary. With the automatic enrollment feature, once eligible, employees will be automatically enrolled at the default pre-tax contribution percentage the business owner sets. They'll also have contributions directed to the plan's qualified default investment alternative (QDIA) unless they select otherwise. A QDIA is a default investment used when money is contributed to an employee's 401(k) account, and the employee hasn't made their investment selection.</span></p>
        <p><span class="fs-p">Automatic enrollment can help increase participation, simplify administration, reduce follow-up, and help employees save for retirement. An eligible employer that adds an auto-enrollment feature to their plan can claim a tax credit of $500 per year for a 3-year taxable period beginning with the first taxable year the employer includes the auto-enrollment feature. This tax credit is available for new or existing plans that adopt an eligible auto-enrollment plan.</span></p>
      </p>
    </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4">
        <p><span class="fs-p fw-bold mb-0">Profit-sharing flexibility. </span><span class="fs-p">Business owners have the option to contribute company profits back to employees’ 401(k) plan accounts.</span></p>
      </div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Payroll provider integrations. </span><span class="fs-p">Business owners can save time and reduce errors by automating contribution reporting from their payroll provider system directly into Simply Retirement by Principal<small><sup>®</sup></small>. Ubiquity’s Payroll (K)oncierge service can help them get started.</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Protection through an ERISA fidelity bond. </span><span class="fs-p">For the first three years, business owners get an ERISA fidelity bond to protect the plan's assets (up to $250,000 in assets, which equals a $25,000 bond). After three years, a renewal letter will be sent and the business owner can choose to accept the renewal at their own expense, or consult with their financial professional and/or TPA in choosing another bond provider. </span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Easy-to-use online platform for plan administration. </span><span class="fs-p">Business owners will manage their plan using the intuitive Ubiquity Retirement + Savings<sup><small>®</small> </sup> platform.</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Automated signup and onboarding. </span><span class="fs-p">Employees will receive an email from Ubiquity as soon as they’re eligible with instructions to set up a login to their plan account.</span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Recordkeeping.</span> <span class="fs-p">Services include tracking which employees are participating, the amount they’ve invested, and the amount invested in each of the plan funds.</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Owner participation. </span><span class="fs-p">This isn’t just a benefit for employees; qualifying business owners can also participate and maximize their retirement savings with any available matching contributions.</p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Dedicated phone number. </span><span class="fs-p">There's a team of people just a phone call away if you or your clients have a question.</p></div>
    </div>
    `,
  },
  {
    id: 2,
    src: IconMultipleUsers,
    anchor: 'features-employees',
    title: `Features for participating employees`,
    desc: `
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Preset investment options. </span><span class="fs-p">Participants can pick from a carefully selected lineup of investment options. Wilshire Advisors LLC is the 3(38) plan fiduciary. See <a href="/third-party-administrator/investments">investments we offer.</a> If you or your client is working with a financial professional, you’ll be prompted to share the plan you create with the financial professional so they can assist the client in selecting one of the available Wilshire 3(38) investment lineups for the plan. </span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Financial wellness. </span><span class="fs-p">Participants will have access to a comprehensive financial wellness platform that provides tools and resources to employees to better manage their current and future financial well-being.</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Roth contributions. </span><span class="fs-p">When you create the plan online, you’ll have the option to select whether to allow participants to make Roth contributions in addition to pre-tax deferrals in the online plan creation. Auto-enrollment contributions can only be pre-tax contributions.</span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Loans. </span><span class="fs-p">Participants can request to take a loan from their 401(k) plan balance and select a loan repayment schedule that best suits them. Only one loan may be outstanding at a time. Loan repayments are made via after-tax payroll deductions. The interest portion of the loan payment is applied to the participant's account.</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Vesting. </span><span class="fs-p">You’ll have the option to select vesting schedules when you create the plan online.</span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Rollovers. </span><span class="fs-p">Participants can roll over eligible accounts into their 401(k) plan. (Rollover contributions can be distributed at any time.)</span></p></div>
    </div>
    `,
  },
];

const QuestionsSet2 = [
  {
    id: 1,
    src: IconPiggyBank,
    anchor: 'investments',
    title: `Investments we offer`,
    desc: `
    <h2 class="fs-p fw-bold"><strong>Wilshire Advisors LLC</strong> is the 3(38) investment fiduciary.</h2>
    <p class="fs-p">Wilshire Advisors LLC will provide objective, independent third-party oversight for the screening, selection, and monitoring of the investment options for Simply Retirement by Principal<sup><small>®</small></sup> and will have discretion for making changes when they deem appropriate. Wilshire Advisors LLC is a diversified global financial services firm with more than 40 years of experience providing investment guidance to some of the largest plan sponsors in the U.S. The firm’s core strength is in the use of market-tested manager research techniques that have been refined over four decades serving the institutional and pension consulting marketplace.</p>
    <p class="fs-p">Financial professionals can select one of the available Wilshire 3(38) investment lineups for each plan. Participating employees have the option to choose their mix of investments from the preselected investment lineup. If the business owner is working with a financial professional, the financial professional will have the option to assist the business owner in selecting one of the available Wilshire 3(38) investment lineups for the plan.</p>
    `,
  },
  {
    id: 2,
    src: IconDollar,
    anchor: 'compensation',
    title: `TPA compensation`,
    desc: `
    <p class="fs-p">Select client and participant level fees can be included in the Simply Retirement by Principal<sup><small>®</small></sup> fee proposal (if you choose) which will provide more transparency to the business owner and a “view” of the total cost:</p>
    <ul>
      <li class="fs-p">One-time set up 
        <span class="text-underline p-0 no-border-button" id="tpa-compensation-fee-disclaimer">fee*</span>
      </li>
      <li class="fs-p">Annual base 
        <span class="text-underline p-0 no-border-button" id="tpa-compensation-fee-disclaimer-1">fee*</span>
      </li>
      <li class="fs-p">Annual per head fee*</li>
      <li class="fs-p">Asset based fee (up to $5,000 per year or 250 bps), paid quarterly</li>
      <li class="fs-p">Participant transaction fees.</li>
    <ul>
    `,
  },
];

const QuestionSection = () => (
  <>
    <section className="section section_py-5 pt-5">
      <div className="container">
        <div className="row pb-3">
          <img
            className="mx-auto my-3"
            style={{ width: '84px' }}
            src={IconRocketBlue}
            alt="rocket"
          />
        </div>
        <div className="row justify-content-center pb-5">
          <p className="fs-h3 fw-bold text-center col-lg-6">
            Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            401(k) plan features
          </p>
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="row">
              <Question questions={QuestionsSet1} width={50} height={60} />
              <Question questions={QuestionsSet2} width={50} height={60} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section pt-5">
      <div className="container">
        <div className="row">
          <Question questions={QuestionsSet1} width={60} height={80} expand />
        </div>
      </div>
    </section>

    <section className="section pb-5">
      <div className="container">
        <div className="row">
          <Question
            questions={[QuestionsSet2[0]]}
            width={60}
            height={80}
            expand
          />
        </div>
        <div className="row py-5">
          <div className="col-lg-8 mx-auto">
            <div className="text-center">
              <Link
                to="/third-party-administrator/investments"
                className="srp-button srp-button__primary"
              >
                View our investment offerings
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <Question
            questions={[QuestionsSet2[1]]}
            width={60}
            height={80}
            expand
          />
        </div>
      </div>
    </section>
  </>
);

export default QuestionSection;
