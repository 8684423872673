import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import OverlayBtnToggle from './OverlayBtnToggle';
import UserList from './UserList';
import NavList from './NavList';
import MobileContact from './MobileContact';

import PrincipalLogo from '../../../Images/principal-simply-retirement-logo.svg';

const Navigation = ({
  rootRoute,
  isFinancialHp,
  isTpa,
  isReviewPlan,
  location,
  paradigmHost,
}) => {
  const [visibleOverlay, setOverlayVisibility] = useState(false);
  useEffect(() => {
    setOverlayVisibility(false);
  }, [location.pathname]);

  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      if (typeof window !== 'undefined') {
        setSticky(ref.current.getBoundingClientRect().height < window.scrollY);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', () => handleScroll);
      }
    };
  }, []);

  return (
    <nav
      className={classNames('navmenu', { 'affixed-top': isSticky })}
      ref={ref}
      role="navigation"
    >
      <div className="container-xlg">
        <div className="navmenu__navbar">
          <div className="navmenu__header">
            <Link to={rootRoute} className="navmenu__brand">
              <img
                src={PrincipalLogo}
                alt="Simply Retirement by Principal"
                className="img-fluid"
              />
            </Link>
          </div>
          <OverlayBtnToggle
            setOverlayVisibility={setOverlayVisibility}
            visibleOverlay={visibleOverlay}
          >
            Toggle Menu
          </OverlayBtnToggle>
          <div
            className={classNames(
              'navmenu__menu-container order-2 order-lg-1',
              {
                'd-print-none': isReviewPlan,
                'show-overlay': visibleOverlay,
                'hide-overlay': !visibleOverlay,
              },
            )}
          >
            <UserList
              rootRoute={rootRoute}
              location={location}
              paradigmHost={paradigmHost}
              setOverlayVisibility={setOverlayVisibility}
              visibleOverlay={visibleOverlay}
            />
            <NavList
              rootRoute={rootRoute}
              isFinancialHp={isFinancialHp}
              isTpa={isTpa}
              setOverlayVisibility={setOverlayVisibility}
              visibleOverlay={visibleOverlay}
            />
            <MobileContact location={location} />
          </div>
        </div>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  rootRoute: PropTypes.string.isRequired,
  isReviewPlan: PropTypes.bool.isRequired,
  isFinancialHp: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  paradigmHost: PropTypes.string.isRequired,
  isTpa: PropTypes.bool.isRequired,
};

export default withRouter(Navigation);
