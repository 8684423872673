export const minimumAutoDeferralPercentage = {
  id: 11,
  stepName: 'step_5',
  type: 'slider',
  name: 'minimumAutoDeferralPercentage',
  labelClass: 'fs-form-q fw-bold text-center py-3',
  user: {
    employer: {
      label:
        'What percentage of an employee’s pay (pre-tax) would you like to set as the default contribution?',
      infoLabel:
        'You’ve chosen automatic enrollment. That means you need to select a default percentage that all eligible employees will start contributing when they are automatically enrolled in the plan. Don’t worry; employees can change this amount or opt out at any time.',
    },
    advisor: {
      label:
        'What percentage of an employee’s pay (pre-tax) should be the default contribution?',
      infoLabel:
        'You’ve chosen automatic enrollment. That means you need to select a default percentage that all eligible employees will start contributing when they are automatically enrolled in the plan. Don’t worry; employees can change this amount or opt out at any time.',
    },
  },
  heading: 'What’s automatic enrollment?',
  content:
    'Automatic enrollment is a plan provision which automatically enrolls participants into the retirement plan at a specified salary deferral percentage unless the participant elects otherwise by completing an enrollment form. This  can help increase participation, simplify administration, and help employees save for retirement. As an added benefit, the SECURE 2.0 Act offers a new tax credit for small businesses implementing automatic enrollment using an eligible automatic contribution arrangement (EACA). It’s $500 annually for 3 years. This is in addition to the plan start-up credit. Consult with a tax advisor or other appropriate counsel for details.',
  format: 'percentage',
  required: true,
  dirty: false,
  min: 3,
  max: 8,
  defaultValue: 5,
  disabled: false,
  thousandSeperator: false,
  valueFormatted: '5%',
  step: 1,
  value: 5,
  hasConditional: false,
  hasError: false,
  reviewScreenTitle: 'Automatic enrollment employee contribution',
  sideCardTitle: 'Default employee contribution',
  optionDescription: `
      <p class="font-italic mb-0"><strong>You selected from these options:</strong></p>
      <ul class="font-italic"><li>3%, 4%, 5%, 6%, 7%, 8%</li></ul>
    `,
  description:
    'This plan has automatic enrollment, which means you must select a default pre-tax contribution percentage that eligible employees will start contributing when they are automatically enrolled in the plan. Their contributions will be directed to the plan’s QDIA (Qualified Default Investment Alternative) unless they elect otherwise. A QDIA is a default investment used when money is contributed to an employee’s 401(k) account, but the employee hasn’t made their investment election. Employees can change this amount or opt out at any time.',
};
