import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import FormDataRedux from '../../../Redux/FormDataRedux';
import {
  Question,
  PrevStepBtn,
  NextStepBtn,
  checkValidityOnQuestionArray,
} from '../../../common/FormWithSteps';
import {
  getDate,
  requireConfirmation,
} from '../../../Services/helpers/Paradigm/planHelper';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../common/FormWithSteps/Utilities';

export const PlanEffectiveDate = ({
  role,
  active,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestion,
  goToReviewPlan,
  googleData,
  questionId,
  subQuestionIds,
  safeHarbor,
}) => {
  const sendDataToGTM = useGTMDispatch();
  const primaryQuestion = questions[questionId];
  const secondaryQuestion = questions[subQuestionIds[0]];
  const tertiaryQuestion = questions[subQuestionIds[1]];
  const hasSafeHarbor =
    questions[safeHarbor].value === 'yes'
      ? { value: 'safeHarbor' }
      : { value: 'nonSafeHarbor' };

  const [currentMonth, currentDay, currentYear] = new Date()
    .toLocaleDateString('en-us')
    .split('/');

  const [confirmPlanName, setConfirmPlanName] = useState({
    type: 'checkbox',
    user: {},
    label: '',
    name: 'confirmPlanName',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: false,
    hasError: false,
    options: [
      {
        id: 1,
        value: false,
        label:
          'Information entered above must match the Summary Plan Description (SPD) document.',
        checked: false,
        icon: '',
        hasInfo: false,
        customStyle: '',
      },
    ],
    description: '',
  });

  const validateQuestions = [
    primaryQuestion,
    secondaryQuestion,
    confirmPlanName,
  ];

  let pastDeadline = requireConfirmation(
    hasSafeHarbor,
    questions[questionId].value.split('-'),
  );

  const isError = (date) => {
    if (!moment(date).isValid()) {
      return { hasError: true, errorMsg: 'Date is invalid.' };
    }
    if (moment(date).isBefore(min().value)) {
      return { hasError: true, errorMsg: 'Date cannot be in the past.' };
    }
    if (moment(date).isAfter(max)) {
      return { hasError: true, errorMsg: 'Date is too far into the future.' };
    }
    if (requireConfirmation(hasSafeHarbor, new Date(date))) {
      return { hasError: true, errorMsg: '' };
    }
    return { hasError: false, errorMsg: '' };
  };

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    let val = updateValue;
    let valFormat = valueFormatted;
    if (id === 'planNumber') {
      let match = val.toString().match(/^0+/);
      match = match ? match[0].length : 0;
      if (val.length < 3 && val.length !== 0) {
        val = val.padStart(3, '0');
      }
      if ((val.length > 3 && match === 0) || match === 3) {
        val = ('0', val).slice(-3);
      }
      valFormat = val;
    }
    updateQuestion({
      name: id,
      value: val,
      valueFormatted: valFormat,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChangeDate = (id, updateValue) => {
    pastDeadline = requireConfirmation(
      hasSafeHarbor,
      questions[questionId].value.split('-'),
    );
    updateQuestion({
      name: id,
      value: updateValue,
      hasError: isError(updateValue).hasError,
      errorMsg: isError(updateValue).errorMsg,
      dirty: true,
      valueFormatted: updateValue,
    });

    if (
      questions.hasGrandfatherDate.value === 'yes' &&
      questions.grandfatherDateHasCustomDate.value === 'no'
    ) {
      updateQuestion({
        name: questions.grandfatherDate.id,
        value: updateValue,
        hasError: isError(updateValue).hasError,
        errorMsg: isError(updateValue).errorMsg,
        dirty: true,
        valueFormatted: updateValue,
      });
    }
  };

  const handleQuestionChangeConfirm = (_id, updateValue) => {
    setConfirmPlanName({
      ...confirmPlanName,
      value: updateValue,
      dirty: updateValue === true,
    });
  };

  const min = () => {
    const required = {
      value: getDate(new Date().getFullYear() + 1, '1', '1'),
      valueFormatted: primaryQuestion.infoDate,
    };
    const notRequired = {
      value: `${new Date().getFullYear() - 1}-01-01`,
      valueFormatted: 'now',
    };
    return requireConfirmation(hasSafeHarbor) ? required : notRequired;
  };

  const max = getDate(
    (parseInt(currentYear, 10) + 5).toString(),
    currentMonth,
    currentDay,
  );

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  useEffect(() => {
    if (pastDeadline) {
      updateQuestion({
        name: primaryQuestion.name,
        value: primaryQuestion.value,
        hasError: isError(primaryQuestion.value).hasError,
        errorMsg: isError(primaryQuestion.value).errorMsg,
        valueFormatted: primaryQuestion.valueFormatted,
      });
    }
  }, []);
  return (
    <div className="container-fluid h-100 mt-5">
      <div className="row">
        <div className="col-lg-4 mx-auto">
          <Question
            role={role}
            type={primaryQuestion.type}
            labelClass="fs-form-q fw-bold text-center pt-3 px-3 d-block"
            inputClass="date srp-form-input form-control"
            question={primaryQuestion}
            handleQuestionChange={handleQuestionChangeDate}
            hasInfo={!!primaryQuestion.heading}
            min={min().value}
            max={max}
            inputLabel={primaryQuestion.inputLabel}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7 mx-auto mb-3">
          {primaryQuestion.hasError && !pastDeadline && (
            <p className="text-danger text-center">
              {primaryQuestion.errorMsg}
            </p>
          )}
          {pastDeadline && (
            <div
              className="fs-p text-center"
              dangerouslySetInnerHTML={{ __html: primaryQuestion.info }}
            />
          )}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-lg-4 mx-auto">
          <Question
            role={role}
            type={secondaryQuestion.type}
            labelClass="fs-form-label fw-bold"
            inputClass="planName srp-form-input form-control mx-auto"
            question={secondaryQuestion}
            handleQuestionChange={handleQuestionChange}
          >
            {secondaryQuestion.hasError && <small>Question is required</small>}
          </Question>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-4 mx-auto">
          <Question
            role={role}
            type={tertiaryQuestion.type}
            labelClass="fs-form-label fw-bold"
            inputClass="planNumber srp-form-input form-control mx-auto"
            question={tertiaryQuestion}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
            maxLength={3}
          >
            {tertiaryQuestion.hasError && <small>Question is required</small>}
          </Question>
          <div>
            <input
              id="checkbox_id"
              type="checkbox"
              checked={confirmPlanName.value}
              value={confirmPlanName.options[0].value}
              disabled={false}
              className="form-check-input ml-0"
              onChange={(event) => {
                handleQuestionChangeConfirm(
                  confirmPlanName.name,
                  event.target.checked,
                );
              }}
            />
            <div className="ml-4">
              <label className="fw-bold" htmlFor="checkbox_id">
                Information entered above must match the Summary Plan
                Description (SPD) document.
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <NextStepBtn
              text="Review your plan"
              disabled={!checkValidityOnQuestionArray(validateQuestions)}
              onClick={() => {
                sendDataToGTM({
                  event: 'e_toolInteraction',
                  eventType: 'progress',
                  tool: {
                    name: 'Simply Retirement',
                    stepNumber: '1',
                    stepName: 'review',
                  },
                });
                goToReviewPlan();
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

PlanEffectiveDate.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  goToReviewPlan: PropTypes.func,
  googleData: PropTypes.object,
  questionId: PropTypes.string,
  subQuestionIds: PropTypes.array,
  safeHarbor: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
});

export default connect(null, mapDispatchToProps)(PlanEffectiveDate);
