import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectPrimaryAdvisor,
} from '../../../../../Redux/FormDataRedux';
import {
  NextStepBtn,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../../common/FormWithSteps';
import Header from '../Header';
import AddAdvisorStep from './AddAdvisorStep';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../../../common/FormWithSteps/Utilities';

const AddAdvisor = ({
  active,
  questions,
  destination,
  previousStep,
  updateActiveStep,
  primaryAdvisor,
  googleData,
  updateAdvisor,
  role,
}) => {
  const { advisorEmail, advisorFirstName, advisorLastName, advisorStateCode } =
    primaryAdvisor.questions;
  const validQuestions = [
    advisorEmail,
    advisorFirstName,
    advisorLastName,
    advisorStateCode,
  ];

  const handleQuestionChangeAdvisor = ({ name, updateValue, ...rest }) => {
    updateAdvisor(
      {
        ...rest,
        name,
        dirty: true,
        value: updateValue,
        hasError: checkForErrors(name, updateValue, questions),
      },
      rest.index,
    );
  };

  const uniqueId = (name, index) => (index <= 0 ? name : `${name}_${index}`);

  const nextStepAction = () => {
    updateActiveStep(destination(), 'forward');
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <Header step={3} />
      <AddAdvisorStep
        primaryAdvisor={primaryAdvisor}
        handleQuestionChange={handleQuestionChangeAdvisor}
        role={role}
        uniqueId={uniqueId}
      />
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(validQuestions)}
              onClick={() => nextStepAction()}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

AddAdvisor.propTypes = {
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateAdvisor: PropTypes.func.isRequired,
  googleData: PropTypes.object,
  primaryAdvisor: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  primaryAdvisor: selectPrimaryAdvisor(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  updateAdvisor: (obj, index) => {
    dispatch(FormDataRedux.updateAdvisor(obj, index));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAdvisor);
