import { checkAdvisorCredentialVerification } from './checkAdvisorCredentialVerification';
import { checkValidityOnQuestionArray } from './checkValidityOnQuestionArray';
import { whoToShareWith } from './whoToShareWith';

export const tpaNextAction = (
  userRole,
  advisorInfo,
  tpaInfo,
  formData,
  isValid,
) => {
  const isFpValid =
    checkValidityOnQuestionArray(advisorInfo) &&
    checkAdvisorCredentialVerification(
      formData.planDesign.advisors[0].advisorCredentialVerification,
    );
  const shareWith = whoToShareWith(
    userRole,
    formData.planDesign.questions.hasFp.value,
    formData.planDesign.questions.hasTpa.value,
    isFpValid,
    isValid,
    formData.planOriginator.role,
  );
  if (!checkValidityOnQuestionArray(tpaInfo)) return 'tpa-error';
  if (shareWith === 'tpaToAdvisor') {
    if (formData.planOriginator.role === 'advisor') {
      return 'tpa-save-only';
    }
  }

  if (shareWith === 'tpaToEitherParty') return 'tpa-to-either';
  if (shareWith === 'tpaToEmployer') {
    if (formData.planOriginator.role === 'employer') {
      return 'tpa';
    }
    if (formData.planOriginator.role === 'advisor') {
      return 'tpa-save-only';
    }
  }

  return 'tpa';
};
