import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../Redux/FormDataRedux';

const ApplicationInfoPanel = ({
  applicationPanelInfo,
  putApplicationPanelInfo,
}) => {
  const { hasVisibility, heading, description, additionalInformation } =
    applicationPanelInfo;
  let modalRef;

  const customStyles = {
    content: {
      background: '#111C2F',
      position: 'relative',
      overflowY: 'auto',
      flex: '1 1 auto',
    },
  };

  useEffect(() => {
    Modal.setAppElement(modalRef);
  });
  return (
    <Modal
      className="app-panel-from-right app-panel position-relative"
      isOpen={hasVisibility}
      onRequestClose={() => putApplicationPanelInfo({ hasVisibility: false })}
      closeTimeoutMS={250}
      ariaHideApp={false}
      style={customStyles}
    >
      <div
        ref={(arg) => {
          modalRef = arg;
        }}
      >
        <div className="p-5 p-md-3 ps-lg-5">
          <button
            type="button"
            onClick={() => putApplicationPanelInfo({ hasVisibility: false })}
            className="fws__info-arrow"
          >
            <i
              className="fa-sharp fa-solid fa-chevron-right fa-2xl"
              style={{ color: '#979797' }}
            ></i>
            <span className="sr-only">Close</span>
          </button>
          <div className="pb-5 pt-5 py-2 ps-md-5 pt-md-4 pt-lg-5">
            {heading && (
              <h2 className="pb-3 pb-lg-4 fs-h2 lh-125 text-white fw-bold">
                {heading}
              </h2>
            )}
            {description && (
              <p className="fs-p text-white pt-0 mt-0">{description}</p>
            )}
            <div
              className="text-white"
              dangerouslySetInnerHTML={{ __html: additionalInformation }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

ApplicationInfoPanel.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationInfoPanel);
