import styled from 'styled-components';

const Button = styled.button`
  display: block;
  max-width: 200px;
  margin: auto;
  &:hover {
    text-decoration: underline;
    background-color: #003865;
  }
`;

const Aside = styled.div`
  border: 1px solid #003865;
  border-radius: 10px;
  padding: 20px 5px;
  max-width: 322px;
  background-color: #003865;
  @media (min-width: 992px) {
    border: none;
    padding: 25px;
    border-radius: 30px;
    background-color: #003865;
  }
  .aside_row {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;

    @media (min-width: 992px) {
      flex-flow: column;
      align-items: flex-start;
    }
  }
`;

// Parent Block
const ContactStyles = styled.aside`
  color: #fff;
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
`;

// Compose Elements
ContactStyles.Aside = Aside;
ContactStyles.Button = Button;

export default ContactStyles;
