import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import EditAdvisorButton from './EditAdvisorButton';
import Label from './Label';
import SwitchAdvisor from './SwitchAdvisor';
import FormDataRedux, {
  selectPrimaryAdvisor,
  selectAdvisors,
  selectRole,
  selectIsFirmBrokerSplitComp,
} from '../../Redux/FormDataRedux';
import { OneDigitalLogoBlack } from '../../ImagePaths';
import { rootRoute } from '../../Services/utilities';

const AdvisorInfoCard = ({
  userRole,
  primaryAdvisor,
  advisors,
  questions,
  goToRoleSelection,
  isFirmBrokerSplitComp,
}) => {
  const location = useLocation();
  const [current, setCurrent] = useState(0);
  const {
    firmNameFreeForm,
    advisorCompensation,
    advisorType,
    advisorFee,
    advisorPoints,
    employerStateCode,
    companyName,
    hasTpa,
    advisorFirmBroker,
  } = questions;
  const {
    advisorCredentialVerification: { brokerType, insuranceAgent },
  } = primaryAdvisor;

  const isInOneDigitalPath = rootRoute(location.pathname) === '/onedigital';
  const isUserRole = (role) => role === userRole;
  const showAdvisorPhone = (advisor) => {
    if (hasTpa.value === false) {
      return true;
    }
    if (hasTpa.value === true && advisor.questions.advisorPhone.value !== '') {
      return true;
    }
    return false;
  };
  const typeBoolean = advisorType.value === 'option_1';
  const labelType = typeBoolean
    ? `Flat fee <span class="fs-p">(up to $5,000 per year, paid quarterly)</span>`
    : `Basis points <span class="fs-p">(per year, paid quarterly)</span>`;
  const isEmpty = (key, index) => advisors[index].questions[key].value === '';
  const isRequired = (key, index) =>
    advisors[index].questions[key].required
      ? 'Missing information'
      : 'Not required';
  const labelText = (key, index) =>
    isEmpty(key, index)
      ? isRequired(key, index)
      : advisors[index].questions[key].value;

  const hasManyAdvisors = advisors.length > 1;

  const showBrokerType = () => {
    const isBrokerDealer = brokerType === 'brokerDealer';
    if (isBrokerDealer || brokerType === 'iarRia') {
      return (
        <Label label={isBrokerDealer ? 'Broker dealer' : 'RIA'}>
          {advisorFirmBroker.valueFormatted}
        </Label>
      );
    }
    if (brokerType === 'insuranceAgent') {
      return <Label label="Insurance agency">{insuranceAgent}</Label>;
    }
    return false;
  };

  return (
    <section>
      <div className="d-flex justify-content-between align-content-center mb-4 d-print-none">
        <div className="fs-h3 text-purple fw-bold">
          <span className="mr-3">
            Financial professional{!!hasManyAdvisors && '(s)'}
          </span>
          {!isInOneDigitalPath && !!isUserRole('advisor') && (
            <EditAdvisorButton
              handleClick={() => goToRoleSelection('advisorInformation')}
            />
          )}
        </div>
        {!isInOneDigitalPath && !!hasManyAdvisors && (
          <SwitchAdvisor
            current={current}
            setCurrent={setCurrent}
            total={advisors.length}
          />
        )}
        <br />
        {isInOneDigitalPath && (
          <img
            src={OneDigitalLogoBlack}
            alt="One Digital Logo"
            style={{ width: '30%' }}
          />
        )}
      </div>
      <div className="advisors-carousel">
        {advisors.map((advisor, index) => {
          const keyIndex = index;
          return (
            <div
              key={keyIndex}
              className={classNames('row mb-5', {
                'd-print-none': current !== index && isInOneDigitalPath,
                'advisors-carousel--hide': current !== index,
              })}
            >
              <div className="col">
                <strong className="fs-h5 text-purple">
                  {index === 0 && 'Primary'}
                </strong>
                <Label
                  label="Name"
                  labelStyle={
                    isEmpty('advisorFirstName', index) ? 'text-danger' : ''
                  }
                >
                  {`${labelText('advisorFirstName', index)} ${
                    advisor.questions.advisorLastName.value
                  }`}
                </Label>
                <Label
                  label="Email"
                  labelStyle={
                    isEmpty('advisorEmail', index) ? 'text-danger' : ''
                  }
                >
                  {labelText('advisorEmail', index)}
                </Label>
                {showAdvisorPhone(advisor) && (
                  <Label
                    label="Phone number"
                    labelStyle={
                      isEmpty('advisorPhone', index) ? 'text-danger' : ''
                    }
                  >
                    {labelText('advisorPhone', index)}
                  </Label>
                )}
                {!!isUserRole('advisor') && (
                  <Label label="Firm Name">{firmNameFreeForm.value}</Label>
                )}

                {!!isUserRole('advisor') && showBrokerType()}

                {!isInOneDigitalPath && !!isUserRole('advisor') && (
                  <Label
                    label="National producer number (NPN)"
                    labelStyle={
                      isEmpty('advisorNpn', index) ? 'text-danger' : ''
                    }
                  >
                    {labelText('advisorNpn', index)}
                  </Label>
                )}
              </div>
              {!isInOneDigitalPath && !!isUserRole('advisor') && (
                <div className="col">
                  {advisorCompensation.value !== '' && (
                    <div>
                      {advisorCompensation.value === 'option_1' && (
                        <div>
                          <p className="mb-2">
                            <strong className="heading fs-display5">
                              Compensation details
                            </strong>
                          </p>
                          <p>{advisorCompensation.valueFormatted}</p>
                        </div>
                      )}
                      {advisorCompensation.value === 'option_2' && (
                        <div>
                          <p className="mb-2">
                            <strong className="heading fs-display5">
                              Compensation details
                            </strong>
                          </p>
                          <p>
                            <span className="fs-tablelabel lh-125 mb-1">
                              {advisorCompensation.valueFormatted}
                            </span>
                            <br />
                            <span
                              className="fs-tablelabel lh-125 mb-1"
                              dangerouslySetInnerHTML={{
                                __html: labelType,
                              }}
                            />{' '}
                            <strong className="fs-h4 text-purple d-block">
                              {typeBoolean
                                ? advisorFee.valueFormatted
                                : advisorPoints.valueFormatted}
                            </strong>
                          </p>
                          {isFirmBrokerSplitComp && (
                            <Label label="Compensation Percentage">
                              {advisor.questions.compensation.valueFormatted}
                            </Label>
                          )}
                          <hr />
                        </div>
                      )}
                    </div>
                  )}

                  <Label label="Company's legal name">
                    {companyName.value}
                  </Label>
                  <Label label="Company state of business">
                    {employerStateCode.value}
                  </Label>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <hr />
      <hr className="mb-5" />
    </section>
  );
};

AdvisorInfoCard.propTypes = {
  userRole: PropTypes.string.isRequired,
  primaryAdvisor: PropTypes.object.isRequired,
  advisors: PropTypes.array.isRequired,
  questions: PropTypes.object.isRequired,
  goToRoleSelection: PropTypes.func.isRequired,
  isFirmBrokerSplitComp: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  primaryAdvisor: selectPrimaryAdvisor(store),
  advisors: selectAdvisors(store),
  questions: store.formData.planDesign.questions,
  userRole: selectRole(store),
  isFirmBrokerSplitComp: selectIsFirmBrokerSplitComp(store),
});

const mapDispatchToProps = (dispatch) => ({
  addAdvisor: () => {
    dispatch(FormDataRedux.addAdvisor());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorInfoCard);
