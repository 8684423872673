import styled from 'styled-components';

export const QuickQuoteCircle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  height: 275px;
  width: 275px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(244, 245, 246);
  border-radius: 100%;
  padding: 40px;

  @media (min-width: 768px) {
    display: flex;
  }
`;
