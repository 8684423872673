import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ className = '', name = '', text = '', children = null }) => (
  <label className={className} htmlFor={name}>
    {text}
    {children}
  </label>
);

Label.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Label;
