import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Toasts from '../common/Toasts';
import ApplicationInfoPanel from './ApplicationInfoPanel';
import Modal from '../common/Modal';

const ModalLayout = ({ children, title }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Toasts />
    {children}
    <ApplicationInfoPanel />
    <Modal />
  </>
);

ModalLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default ModalLayout;
