import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectTpaQuestions,
  selectPrimaryTPA,
} from '../../../../../Redux/FormDataRedux';
import { NextStepBtn, PrevStepBtn } from '../../../../../common/FormWithSteps';
import {
  checkForErrors,
  checkValidityOnQuestionArray,
  pushGoogleDataLayer,
} from '../../../../../common/FormWithSteps/Utilities';
import Header from '../Header';
import Form from './Form';

const ParticipantFees = ({
  role,
  active,
  questions,
  previousStep,
  updateActiveStep,
  updateQuestion,
  destination,
}) => {
  const {
    tpaDistributionFee,
    tpaHardshipFee,
    tpaLoanMaintenanceFee,
    tpaLoanOriginationFee,
    tpaLoanReamortizationFee,
    tpaRmdFee,
    tpaQdroFee,
    hasFp,
  } = questions;

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const questionsToValidate = () => {
    const validateThese = [
      tpaDistributionFee,
      tpaHardshipFee,
      tpaLoanMaintenanceFee,
      tpaLoanOriginationFee,
      tpaLoanReamortizationFee,
      tpaRmdFee,
      tpaQdroFee,
    ];
    return validateThese;
  };

  useEffect(() => {
    pushGoogleDataLayer({
      event: 'PlanDesignView',
      PlanDesignView: 'fees_tpa',
    });
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <Header step={5} />
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Form
            role={role}
            questions={questions}
            handleQuestionChange={handleQuestionChange}
          />
          <div className="fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(questionsToValidate())}
              onClick={() => {
                updateActiveStep(destination(hasFp.value), 'forward');
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

ParticipantFees.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  destination: PropTypes.func,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  tpaInfo: selectTpaQuestions(store),
  primaryTPA: selectPrimaryTPA(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  updateTPA: (obj, index) => {
    dispatch(FormDataRedux.updateTPA(obj, index));
  },
  setPlanOriginator: (planOriginator) => {
    dispatch(FormDataRedux.setPlanOriginator(planOriginator));
  },
  putPlanURL: (planURL, hashId) => {
    dispatch(FormDataRedux.putPlanURL(planURL, hashId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantFees);
