import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Question = ({ questions, width, height, expand }) => (
  <>
    {questions.map((question) => (
      <div
        id={expand ? question.anchor : ''}
        className={classNames({
          'col-lg-8 mx-auto': expand,
          'col-lg-3 col-md-4 col-6 mb-md-3': !expand,
        })}
        key={question.id}
      >
        <img
          src={question.src}
          alt={question.title}
          width={width}
          height={height}
        />
        <p
          className={classNames('text-purple fw-bold', {
            'fs-h3 mb-4': expand,
          })}
        >
          {!expand && (
            <a
              className="anchor-link"
              href={`#${question.anchor}`}
              dangerouslySetInnerHTML={{ __html: question.title }}
              aria-label={`${question.title} Section`}
            />
          )}
          {expand && question.title}
        </p>
        {expand && <div dangerouslySetInnerHTML={{ __html: question.desc }} />}
      </div>
    ))}
  </>
);

Question.propTypes = {
  questions: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  expand: PropTypes.bool,
};

export default Question;
