import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NextStepBtn, PrevStepBtn } from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';

export const EmployerCheckList = ({
  active,
  previousStep,
  destination,
  updateActiveStep,
  googleData,
}) => {
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-md-10 col-lg-8 mx-auto my-md-auto">
          <h3 className="fs-form-q text-center pb-4 px-md-3">
            Before you get started, you’ll need to have some basic information
            handy:
          </h3>
          <div className="row">
            <div className="col-md-10 col-lg-8 mx-auto">
              <ul className="fa-ul">
                <li className="fs-p mb-1 mb-md-2">
                  <span className="fa-li">
                    <i
                      className="fa-solid fa-check"
                      style={{ color: '#1b7da5' }}
                      aria-hidden
                    />
                  </span>
                  You’ll need the number of employees in your business.{' '}
                </li>
                <li className="fs-p mb-1 mb-md-2">
                  <span className="fa-li">
                    <i className="fa-solid fa-check text-primary" aria-hidden />
                  </span>
                  Each eligible employee must have an email address and access
                  to the internet.
                </li>
              </ul>
              <p className="fws__control">
                <NextStepBtn
                  id="next"
                  text="Next step"
                  onClick={() => {
                    pushGoogleDataLayer({
                      event: 'EmployerPlanDesignStart',
                    });
                    updateActiveStep(destination(), 'forward');
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

EmployerCheckList.propTypes = {
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  destination: PropTypes.func,
  updateActiveStep: PropTypes.func,
  googleData: PropTypes.object,
};

export default EmployerCheckList;
