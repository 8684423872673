export const initFirmSelect = {
  type: 'select',
  name: 'firmSelect',
  user: {
    advisor: {
      label: 'Please confirm how you are selling through your firm.',
      infoLabel: '',
      placeholder: 'I am selling as...',
    },
  },
  required: true,
  dirty: false,
  disabled: false,
  hasError: false,
  value: 'select',
  valueFormatted: '',
  options: [
    {
      id: 0,
      label:
        'I am selling as a registered representative through a broker-dealer',
      value: 'brokerDealer',
      valueFormatted: 'Broker dealer',
      advisorType: 'RR',
      brokerType: 'BROKER',
      selected: '',
    },
    {
      id: 1,
      label: 'I am selling as an IAR through an RIA',
      value: 'iarRia',
      valueFormatted: 'Broker IAR',
      advisorType: 'IAR',
      brokerType: 'RIA_FIRM',
      selected: '',
    },
    {
      id: 2,
      label: 'I am selling as an insurance agent',
      value: 'insuranceAgent',
      valueFormatted: 'Insurance agent',
      advisorType: 'IA',
      brokerType: 'INSURANCE_AGENCY',
      selected: '',
    },
  ],
};

export const initInsuranceAgent = {
  type: 'text_field',
  name: 'insuranceAgent',
  user: {
    advisor: { label: 'Legal name of your insurance agency' },
  },
  required: true,
  dirty: false,
  disabled: false,
  hasError: false,
  value: '',
  valueFormatted: '',
};

export const initInsuranceAgentNpn = {
  type: 'number_field',
  name: 'insuranceAgentNpn',
  user: {
    advisor: { label: 'Insurance agency NPN ' },
  },
  required: true,
  dirty: false,
  disabled: false,
  hasError: false,
  value: '',
  valueFormatted: '',
};

export const initInsAgentCompType = {
  type: 'radio_condensed',
  name: 'compensationType',
  user: {},
  label: 'How would you like your compensation to be paid?',
  options: [
    {
      id: 1,
      user: {},
      label: 'Directly to you',
      value: 'direct',
      checked: false,
    },
    {
      id: 2,
      user: {},
      label: 'Through your agency',
      value: 'throughAgency',
      checked: false,
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasError: false,
};
