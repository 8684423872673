import { googleData } from '../../../Services/utilities';
import EmployerQuestions from './EmployerQuestions';
import EmployerCheckList from './EmployerCheckList';
import HasRetirementPlan from './HasRetirementPlan';
import EmployeesCanContribute from './EmployeesCanContribute';
import TypeOfOrganization from './TypeOfOrganization';
import IsCurrentPrincipalPlan from '../DefaultPlan/IsCurrentPrincipalPlan';

export const employerComponents = {
  EmployerQuestions,
  EmployerCheckList,
  HasRetirementPlan,
  EmployeesCanContribute,
  TypeOfOrganization,
  IsCurrentPrincipalPlan,
};

export const employerFormSteps = (history) => [
  {
    key: 'employerCheckList',
    hasSidebar: false,
    component: 'EmployerCheckList',
    destination: () => 'employerQuestions',
    previousStep: 'roleSelection',
    googleData: googleData('checklist_employer'),
  },
  {
    key: 'employerQuestions',
    hasSidebar: false,
    component: 'EmployerQuestions',
    previousStep: 'roleSelection',
  },
  {
    key: 'hasRetirementPlan',
    showTracker: false,
    questionId: 'hasRetirementPlan',
    component: 'HasRetirementPlan',
    hasSidebar: false,
    previousStep: 'employerQuestions',
    googleData: googleData('has_plan'),
  },
  {
    key: 'isCurrentPrincipalPlan',
    showTracker: false,
    questionId: 'isCurrentPrincipalPlan',
    component: 'IsCurrentPrincipalPlan',
    hasSidebar: false,
    previousStep: 'hasRetirementPlan',
    googleData: googleData('is_principal_plan'),
  },
  {
    key: 'employeesCanContribute',
    showTracker: false,
    questionId: 'employees_can_contribute',
    component: 'EmployeesCanContribute',
    hasSidebar: false,
    previousStep: 'hasRetirementPlan',
    googleData: googleData('can_contribute'),
  },
  {
    key: 'typeOfOrganization',
    showTracker: false,
    questionId: 'type_of_organization',
    component: 'TypeOfOrganization',
    hasSidebar: false,
    goToSeveralOptions: () => {
      history.replace({
        pathname: '/several-options',
      });
    },
    previousStep: 'employeesCanContribute',
    googleData: googleData('org_type'),
  },
];
