import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  pushGoogleDataLayer,
  onSaveToast,
} from '../../../common/FormWithSteps/Utilities';
import FormDataRedux, {
  selectRole,
  selectPlanURL,
  selectPurchasePlanURL,
  selectPlanId,
  selectEmployerQuestions,
  selectAdvisorQuestions,
  selectTpaQuestions,
  selectPlanOptions,
  selectContributionOptions,
  validateWithTpa,
  selectActiveUserEmail,
} from '../../../Redux/FormDataRedux/index';
import { saveData, uuidContext } from '../../../common/FormWithSteps';
import PlanTotalsSection from '../../ReviewPlan/PlanTotalsSection';
import SharePlanModal from '../../ReviewPlan/SharePlanModal';
import HeroSection from '../../ReviewPlan/HeroSection';
import SectionPlanReview from '../../ReviewPlan/SectionPlanReview';
import { PrincipalTabs, Item } from '../../../common/PrincipalTabs';
import PurchasePlanSection from '../../ReviewPlan/PurchasePlanSection';
import ShareSavedPlan from '../../ReviewPlan/ReviewPlanControls/ShareSavedPlan';
import SectionSingleCol from '../../../common/SectionSingleCol';

import API from '../../../Services/api';
import { collectSavedPlanData } from '../../../Services/helpers/Paradigm/planHelper';

import FinancialProfessionalsTab from './FinancialProfessionalsTab';
import BusinessOwnersTab from '../../ReviewPlan/BusinessOwnersTab';
import AdditionalPlanInfo from '../../ReviewPlan/AdditionalPlanInfo';
import ToastRedux from '../../../Redux/ToastRedux';
import { createSalesforceProposal } from '../../../Services/helpers/Salesforce/salesforceHelper';
import { tpaParadigmPackager } from '../../../Services/helpers/tpaParadigm';
import { paradigmPurchase } from '../../../Services/helpers/PrincipalHelper';
import { asOfDate, rootRoute } from '../../../Services/utilities';
import { verifyPlanEffectiveDate } from '../../ReviewPlan/utilities';

const api = API.create();

const ReviewPlan = ({
  formData,
  questions,
  putActiveStep,
  putFormInReview,
  putPlanURL,
  putPurchasePlanURL,
  purchasePlanURL,
  userRole,
  authenticityToken,
  employerInfo,
  advisorInfo,
  tpaInfo,
  history,
  addToast,
  resetData,
  planOptionQuestions,
  contributionPlanOptionQuestions,
  getTpaParadigmQuestions,
  setCreateEmailSent,
  activeUserCc,
  updateQuestion,
}) => {
  const uuid = useContext(uuidContext);
  const location = useLocation();
  const [savePlanStatus, setSavePlanStatus] = useState('');
  const [showSavedPlanBar, setShowSavedPlanBar] = useState(false);
  const [shareModalVisibility, setShareModalVisibility] = useState(false);
  const [salesforceReviewProposalSent, setSalesforceReviewProposalSent] =
    useState(false);
  const isInOneDigitalPath = rootRoute(location.pathname) === '/onedigital';
  const {
    tpaSetupFee,
    annualTpaFee,
    annualTpaPerHeadFee,
    feeCollectionSource,
    tpaCompensationType,
    fixedAmount,
    bpsAmount,
  } = formData.planDesign.questions;
  const advisorsCvStatuses = formData.planDesign.advisors.map(
    (advisor) => advisor.advisorCredentialVerification.verificationStatus,
  );

  let responseHashId;

  const toggleShareModalVisibility = () => {
    setShareModalVisibility((prevState) => !prevState);
  };
  const [purchasePlanStatus, setPurchasePlanStatus] = useState('');
  const goToRoleSelection = (step = 'roleSelection') => {
    putActiveStep('planDesign', step);
    history.push('/onedigital/plans');
  };
  const reviewPlanDisclaimer1 = `<p class="fs-disclaimer-print">If basis points is selected as the method of compensation, the percentage will translate to a dollar amount. For example, .50% = $5 for every $1,000 in the plan annually.</p>`;
  const reviewPlanDisclaimer2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*0.08%–0.86%:</span> As of ${asOfDate}. Based on the investment lineup chosen by OneDigital</p>`;
  const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${reviewPlanDisclaimer1}
     ${reviewPlanDisclaimer2}
    </div>
  </div>
`;

  const findOjectInArray = (key, arry) =>
    arry.find((item) => item.name === key);
  const handleUpdatePlanEmail = async () => {
    const obj = {
      authenticity_token: authenticityToken,
      hashId: formData.hashId,
      to: '',
      name: '',
    };
    if (activeUserCc !== formData.planOriginator.email) {
      obj.cc = activeUserCc;
    }
    switch (formData.planOriginator.role) {
      case 'tpa':
        obj.to = findOjectInArray('tpaEmail', tpaInfo).value;
        obj.name = `${findOjectInArray('tpaFirstName', tpaInfo).value} ${
          findOjectInArray('tpaLastName', tpaInfo).value
        }`;
        await api.shareUpdatePlanTpa(obj);
        break;
      case 'advisor':
        obj.to = findOjectInArray('advisorEmail', advisorInfo).value;
        obj.name = `${
          findOjectInArray('advisorFirstName', advisorInfo).value
        } ${findOjectInArray('advisorLastName', advisorInfo).value}`;
        await api.shareUpdatePlanFp(obj);
        break;
      case 'employer':
        obj.to = findOjectInArray('sponsorEmail', employerInfo).value;
        obj.name = `${
          findOjectInArray('sponsorFirstName', employerInfo).value
        } ${findOjectInArray('sponsorLastName', employerInfo).value}`;
        await api.shareUpdatePlanSmb(obj);
        break;
      default:
        break;
    }
  };

  const createSavedForm = (sendEmail = true) => {
    setSavePlanStatus('loading');
    createSavedPlan(collectSavedPlanData(formData), sendEmail, 'Save');
  };

  const createSavedPlan = async (
    savePlanData,
    sendEmail = true,
    stageFlag = 'Save',
  ) => {
    try {
      const response = await api.savePlan({
        ...savePlanData,
        authenticity_token: authenticityToken,
      });
      if (response.status === 'success') {
        const sessionData = { ...formData };
        sessionData.hashId = response.hashId;
        sessionData.planURL = response.url;
        sessionData.createdDate = response.createdAt;
        sessionData.updatedDate = response.updatedAt;
        sessionData.planOwner =
          sessionData.planOwner === '' ? response.name : sessionData.planOwner;
        sessionData.planOriginator =
          sessionData.planOriginator === ''
            ? savePlanData.state.planOriginator
            : sessionData.planOriginator;

        if (sendEmail) {
          addToast(
            onSaveToast(
              formData.hashId,
              userRole,
              formData.planOriginator,
              'saved',
            ),
            'success',
          );
        }
        if (stageFlag === 'Save') {
          setShowSavedPlanBar(true);
        }
        saveData(uuid, sessionData);
        putPlanURL(response.url, response.hashId);
        if (stageFlag === 'Review') {
          await api.savePlan({
            ...collectSavedPlanData(sessionData),
            authenticity_token: authenticityToken,
          });
        }
        if (stageFlag === 'Review') {
          responseHashId = response.hashId;
        }
        if (
          (salesforceReviewProposalSent === true && stageFlag === 'Save') ||
          (salesforceReviewProposalSent === false && stageFlag === 'Review')
        ) {
          createSalesforceProposal({
            formData,
            authenticityToken,
            stageFlag,
            link: response.url,
            advisorCvStatuses: advisorsCvStatuses,
            tpaParadigmPackager: tpaParadigmPackager(getTpaParadigmQuestions),
            hashId: responseHashId,
            isOneDigital: true,
          });
          if (stageFlag === 'Review') setSalesforceReviewProposalSent(true);
        }
        const obj = {
          authenticity_token: authenticityToken,
          hashId: response.hashId,
          to: formData.planOriginator.email,
          name: formData.planOriginator.name,
        };
        if (formData.createEmailSent && sendEmail) {
          await handleUpdatePlanEmail();
        } else if (!formData.createEmailSent && sendEmail) {
          await api.sendCreateEmail(obj);
          setCreateEmailSent();
        } else if (stageFlag === 'Save') {
          setCreateEmailSent();
        }
      } else {
        addToast('Your plan failed to save. Refresh and try again.', 'error');
        setShowSavedPlanBar(false);
      }
      setSavePlanStatus(response.status);
    } catch (error) {
      setSavePlanStatus('error');
      addToast('Your plan failed to save. Refresh and try again.', 'error');
    }
  };

  const createPurchasePlan = () => {
    paradigmPurchase({
      formData,
      authenticityToken,
      setPurchasePlanStatus,
      advisorCvStatuses: advisorsCvStatuses,
      putPurchasePlanURL,
      resetData,
      tpaParadigmPackager: [],
      isOneDigital: true,
    });
  };

  useEffect(() => {
    verifyPlanEffectiveDate({
      isSafeHarbor: questions.safeHarbor.value === 'safeHarbor',
      planDate: questions.planEffectiveDate.value,
      hasTpa: questions.hasTpa.value,
      callback: updateQuestion,
    });
  }, [verifyPlanEffectiveDate]);

  useEffect(() => {
    putFormInReview('planDesign', true);
    saveData(uuid, formData);
    pushGoogleDataLayer({
      event: 'OneDigitalPlanDesignReview',
      PlanDesignView: `review_${userRole}`,
    });
  }, []);

  useEffect(async () => {
    if (userRole !== 'anonymous') {
      await createSavedPlan(collectSavedPlanData(formData), false, 'Review');
    }
  }, []);

  useEffect(() => {
    if (purchasePlanStatus === 200 || purchasePlanStatus === 201) {
      addToast(
        'Thanks for submitting your plan. You’ll be redirected to finish the signup process.',
        'success',
      );
    }
  }, [purchasePlanStatus]);

  return (
    <div className="review-plan mb-5 bg-pattern">
      <HeroSection />
      <SectionPlanReview
        goToRoleSelection={goToRoleSelection}
        savePlanStatus={savePlanStatus}
        createSavedForm={createSavedForm}
        planOptionQuestions={planOptionQuestions}
        contributionPlanOptionQuestions={contributionPlanOptionQuestions}
      />
      <section id="planTotals" className="section mb-n5 print-break-before">
        <div className="container-xlg">
          <div className="row">
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="row principal-card mb-0 py-5 default">
                <div className="col-lg-10 mx-auto">
                  <PlanTotalsSection
                    employerPaidParticipantFees={
                      questions.employerPaidParticipantFees
                    }
                    advisorCompensation={questions.advisorCompensation}
                    numberOfEmployees={questions.numberOfEmployees}
                    tpaInfo={tpaInfo}
                    advisorInfo={advisorInfo}
                    advisorPoints={questions.advisorPoints}
                    advisorType={questions.advisorType}
                    advisorFee={questions.advisorFee}
                    userRole={userRole}
                    savePlanStatus={savePlanStatus}
                    employerInfo={employerInfo}
                    createSavedForm={createSavedForm}
                    reviewPlanDisclaimer1={reviewPlanDisclaimer1}
                    reviewPlanDisclaimer2={reviewPlanDisclaimer2}
                    tpaSetupFee={tpaSetupFee.valueFormatted}
                    annualTpaFee={annualTpaFee.valueFormatted}
                    annualTpaPerHeadFee={annualTpaPerHeadFee.valueFormatted}
                    feeCollectionSource={feeCollectionSource}
                    tpaCompensationType={tpaCompensationType}
                    fixedAmount={fixedAmount}
                    bpsAmount={bpsAmount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="print-break-before" style={{ overflow: 'hidden' }}>
        <div className="next-container">
          {!isInOneDigitalPath && (
            <section
              id="additionalPlanInfo"
              className="section section_py-5 pt-0"
            >
              <AdditionalPlanInfo
                hasTpa={
                  questions.hasTpa.value === '' ? false : questions.hasTpa.value
                }
              />
            </section>
          )}
          <section className="section section_py-5">
            <div className="container-xlg">
              <div className="row">
                <div className="col-lg-10 col-xl-9 mx-auto">
                  <h2 className="fs-h3 fw-bold text-purple mb-5">
                    What happens next?
                  </h2>
                  <PrincipalTabs
                    panelStyle="shadowed"
                    defaultTab={userRole === 'advisor' ? 1 : 0}
                  >
                    <Item
                      title="For business owners"
                      component={
                        <BusinessOwnersTab
                          hasTpa={
                            questions.hasTpa.value === ''
                              ? false
                              : questions.hasTpa.value
                          }
                        />
                      }
                    />
                    <Item
                      title="For financial professionals"
                      component={<FinancialProfessionalsTab />}
                    />
                  </PrincipalTabs>
                </div>
              </div>
            </div>
          </section>
          <section className="section section_py-5 pt-0">
            <div className="container">
              <PurchasePlanSection
                purchasePlanStatus={purchasePlanStatus}
                purchasePlanURL={purchasePlanURL}
                employerInfo={employerInfo}
                employerStateCode={questions.employerStateCode}
                hasFp={questions.hasFp.value}
                hasTpa={questions.hasTpa.value}
                advisorInfo={advisorInfo}
                tpaInfo={tpaInfo}
                userRole={userRole}
                createSavedForm={createSavedForm}
                createPurchasePlan={createPurchasePlan}
                setPurchasePlanStatus={setPurchasePlanStatus}
                toggleShareModalVisibility={toggleShareModalVisibility}
                goToRoleSelection={goToRoleSelection}
                planDesign={formData.planDesign}
                authenticityToken={authenticityToken}
                showSavedPlanBar={showSavedPlanBar}
              />
            </div>
          </section>
        </div>
      </div>
      {showSavedPlanBar && (
        <ShareSavedPlan
          toggleShareModalVisibility={toggleShareModalVisibility}
          setShowSavedPlanBar={setShowSavedPlanBar}
        />
      )}
      <SectionSingleCol className="d-print-block d-none" copy={footNoteList} />
      {savePlanStatus === 'success' && (
        <SharePlanModal
          shareModalVisibility={shareModalVisibility}
          toggleShareModalVisibility={toggleShareModalVisibility}
        />
      )}
    </div>
  );
};

ReviewPlan.propTypes = {
  formData: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired,
  putActiveStep: PropTypes.func.isRequired,
  putFormInReview: PropTypes.func.isRequired,
  putPlanURL: PropTypes.func.isRequired,
  putPurchasePlanURL: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string,
  purchasePlanURL: PropTypes.string,
  employerInfo: PropTypes.array.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  addToast: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
  planOptionQuestions: PropTypes.object.isRequired,
  contributionPlanOptionQuestions: PropTypes.object.isRequired,
  getTpaParadigmQuestions: PropTypes.array.isRequired,
  setCreateEmailSent: PropTypes.func.isRequired,
  activeUserCc: PropTypes.string,
  updateQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  questions: store.formData.planDesign.questions,
  planURL: selectPlanURL(store),
  purchasePlanURL: selectPurchasePlanURL(store),
  planId: selectPlanId(store),
  userRole: selectRole(store),
  employerInfo: selectEmployerQuestions(store),
  advisorInfo: selectAdvisorQuestions(store),
  tpaInfo: selectTpaQuestions(store),
  planOptionQuestions: selectPlanOptions(store),
  contributionPlanOptionQuestions: selectContributionOptions(store),
  getTpaParadigmQuestions: validateWithTpa(store),
  activeUserCc: selectActiveUserEmail(store),
});

const mapDispatchToProps = (dispatch) => ({
  putActiveStep: (formId, updateStep) => {
    dispatch(FormDataRedux.putActiveStep(formId, updateStep));
  },
  putFormInReview: (formId, formInReview) => {
    dispatch(FormDataRedux.putFormInReview(formId, formInReview));
  },
  putPlanURL: (planURL, hashId) => {
    dispatch(FormDataRedux.putPlanURL(planURL, hashId));
  },
  putPurchasePlanURL: (purchasePlanURL, planId) => {
    dispatch(FormDataRedux.putPurchasePlanURL(purchasePlanURL, planId));
  },
  resetData: () => {
    dispatch(FormDataRedux.resetData());
  },
  addToast: (text, toastType) => {
    dispatch(ToastRedux.addToast(text, toastType));
  },
  setCreateEmailSent: () => {
    dispatch(FormDataRedux.setCreateEmailSent());
  },
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPlan);
