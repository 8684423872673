import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PrevStepBtn } from '../../../common/FormWithSteps';
import ShareForm from '../../../common/ShareForm';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';

export const StepQuestion = ({
  active,
  previousStep,
  updateActiveStep,
  googleData,
}) => {
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <ShareForm toggleShareModalVisibility={() => false} />
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

StepQuestion.propTypes = {
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  updateActiveStep: PropTypes.func,
  googleData: PropTypes.object,
};

export default StepQuestion;
