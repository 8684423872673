import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectQuestion } from '../../../Redux/FormDataRedux';

const AdvisorAuthHeader = ({ step }) => (
  <div className="row mb-4">
    <div className="col-lg-8 mx-auto">
      <h3 className="fs-form-q fw-bold mb-md-5 text-center">
        Client and firm information
      </h3>
      <ul className="stepwizard py-3">
        <li
          className={`stepwizard__item w-25 text-center ${
            step === 1 ? 'stepwizard_active' : ''
          }`}
        >
          <span className="stepwizard__link">
            <span className="d-md-block d-none">Client info</span>
            <span className="d-md-none d-block">Client</span>
          </span>
        </li>
        <li
          className={`stepwizard__item w-25 text-center ${
            step === 3 ? 'stepwizard_active' : ''
          }`}
        >
          <span className="stepwizard__link">
            <span className="d-md-block d-none">Firm info</span>
            <span className="d-md-none d-block">Firm</span>
          </span>
        </li>
        <li
          className={`stepwizard__item w-25 text-center ${
            step === 4 ? 'stepwizard_active' : ''
          }`}
        >
          <span className="stepwizard__link">
            <span className="d-md-block d-none">Your info</span>
            <span className="d-md-none d-block">Info.</span>
          </span>
        </li>
        <li
          className={`stepwizard__item w-25 text-center ${
            step === 5 ? 'stepwizard_active' : ''
          }`}
        >
          <span className="stepwizard__link">
            <span className="d-md-block d-none">Compensation</span>
            <span className="d-md-none d-block">Comp.</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
);

AdvisorAuthHeader.propTypes = {
  step: PropTypes.number.isRequired,
};

const mapStateToProps = (store) => ({
  advisorCompensation: selectQuestion(store, 'advisorCompensation'),
  employerStateCode: selectQuestion(store, 'employerStateCode'),
});

export default connect(mapStateToProps)(AdvisorAuthHeader);
