import React from 'react';
import { HeroWrapper } from './index.styles';

const SingleArticle = () => (
  <div className="disclosures-page mb-5">
    <HeroWrapper className="hero mb-5 pt-5">
      <div className="container-fluid container-fluid--sitewidth">
        <div className="row justify-content-center py-5">
          <h1 className="fs-h1 text-center fw-bold py-5 col-xl-6 col-lg-8 col-md-10 mt-5">
            Five Tips to Help Grow Your Practice with Small Business Clients
          </h1>
        </div>
      </div>
    </HeroWrapper>
    <div className="container pb-5">
      <div className="row pb-5">
        <div className="col-md-9 mx-auto">
          <p>
            Looking to increase and diversify your revenue? Small business
            retirement plans can be a good opportunity, but efficiency is key
            when working with smaller employers. Here are some quick tips for
            reaching employers who are looking to start a retirement plan (for
            additional tips, see the full list{' '}
            <a
              href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12337S&ty=VOP&EXT=.VOP&amp;itemtype=4"
              target="_blank"
            >
              here
            </a>
            ).
          </p>
          <h2>1. Get outside of your comfort zone for referrals.</h2>
          <p>
            Research entrepreneurial and networking groups like 1 Million Cups,
            local small business groups and non-profit boards. Consider joining
            and asking to get on the agenda for a future meeting. Or propose an
            article or series of columns for their member publications.
          </p>
          <h2>2. Do your homework.</h2>
          <p>
            Search websites like{' '}
            <a href="https://www.efast.dol.gov/" target="_blank">
              efast.dol.gov
            </a>{' '}
            and{' '}
            <a href="https://freeerisa.com/" target="_blank">
              freeerisa.com
            </a>
            . Cross-reference different sources to look for pain points and
            uncover valuable information. For extra insight, ask a TPA to review
            along with you.
          </p>
          <h2>3. Use multiple touch points.</h2>
          <p>
            To stay in front of prospects, you’ll generally want to use more
            than one approach. Consider hosting a webinar and invite local small
            business owners. You may also want to start a blog or give your
            social media strategy a boost—always driving prospects to your
            website.
            <small>
              <sup>1</sup>
            </small>
          </p>
          <h2>4. Update your approach.</h2>
          <p>
            Create a standard approach by clearly defining roles within your
            team and determining your value proposition. Develop efficient ways
            to manage your pipeline and prospects. Then segment your clients and
            prioritize based on how you want to spend your time—not just company
            size.
          </p>
          <h2>5. Understand small businesses.</h2>
          <p>
            You may need to change up your sales process for small businesses.
            Business owners wear a lot of hats and may not have the time to
            fully understand the ins and outs of benefits. Keep it simple by
            helping employers understand why benefits may be a better investment
            than a pay raise.
          </p>
          <h2>Bonus tip: Save time with a new digital 401(k) offering.</h2>
          <p>
            With Simply Retirement by Principal
            <small>
              <sup>®</sup>
            </small>
            , you can offer a small business retirement solution without
            investing a lot of time and effort. This new all-digital offering
            allows you to create 401(k) proposals online in minutes and share
            them with your clients by email, no in-person meetings required.
            Designed for businesses with fewer than 100 employees, Simply
            Retirement by Principal
            <small>
              <sup>®</sup>
            </small>{' '}
            features simple online plan design, flat recordkeeping fees, and
            streamlined investment options.
          </p>
          <p>
            <strong>
              To learn more about Simply Retirement by Principal
              <small>
                <sup>®</sup>
              </small>
              , visit{' '}
              <a
                href="https://www.simplyretirement.com/financial-professionals"
                target="_blank"
              >
                SimplyRetirement.com
              </a>
              .
            </strong>
          </p>
          <p>
            <small>
              <sup>1</sup> Be sure to abide by your firm’s social media policies
              and procedures.
            </small>
          </p>
          <p>
            <small>
              Simply Retirement by Principal<sup>®</sup> 401(k) plan
              recordkeeping, and administrative services are provided through
              Decimal, Inc. dba Ubiquity Retirement + Savings
              (&ldquo;Ubiquity&rdquo;). Ubiquity is not affiliated with any plan
              and investment administrative services provided through Principal
              Life Insurance Co. or affiliated with any company of the Principal
              Financial Group<sup>®</sup>. Principal makes available the
              investment options for customers to select through Simply
              Retirement by Principal. All other services are provided by
              service providers not affiliated with any company of the Principal
              Financial Group. Refer to related documents and agreements for
              more details on plan services available.
            </small>
          </p>
          <p>
            <small>
              The subject matter in this communication is educational only and
              provided with the understanding that neither Principal
              <sup>®</sup> or Ubiquity Retirement + Savings are rendering
              legal, accounting, investment advice or tax advice. You should
              consult with appropriate counsel or other financial professionals
              on all matters pertaining to legal, tax, investment or accounting
              obligations and requirements.&#8232;
            </small>
          </p>
          <p>
            <small>
              Insurance products and plan administrative services provided
              through Principal Life Insurance Co., a member of the Principal
              Financial Group<sup>®</sup>, Des Moines, Iowa 50392.
            </small>
          </p>
          <p>
            <small>&#8232;©2020 Principal Financial Services, Inc.</small>
          </p>
          <p>
            <small>1344533-09/2020 | 09/2020</small>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default SingleArticle;
