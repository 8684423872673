import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../../common/Button';

const AdvisorHeading = ({ isFirst, removeAdvisor, advisorIndex }) => {
  if (isFirst) {
    return <h3 className="text-primary">Your details</h3>;
  }
  return (
    <>
      <h3 className="m-0">Additional Financial Professional</h3>
      <Button
        classes="srp-button btn btn-link py-0 px-3"
        clickEvent={() => {
          removeAdvisor(advisorIndex);
        }}
      >
        <i className="fa-solid fa-xmark mx-1 align-middle" aria-hidden="true" />
        Remove
      </Button>
    </>
  );
};

AdvisorHeading.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  removeAdvisor: PropTypes.func.isRequired,
  advisorIndex: PropTypes.number.isRequired,
};

export default AdvisorHeading;
