import React from 'react';
import PropTypes from 'prop-types';

const IconDownload = ({ fill }) => (
  <g>
    <path
      d="M20.4939 0C19.5417 0 18.7698 0.743302 18.7698 1.66021V26.5134L14.0473 21.9592C13.3745 21.3104 12.2828 21.3096 11.609 21.9574C10.9352 22.6053 10.9344 23.6565 11.6072 24.3053L19.2739 31.6988C19.5971 32.0105 20.0358 32.1858 20.4933 32.1859C20.9508 32.1861 21.3896 32.0112 21.7131 31.6997L29.3912 24.3062C30.0645 23.6579 30.0645 22.6067 29.3912 21.9583C28.7179 21.31 27.6262 21.31 26.9529 21.9583L22.2181 26.5176V1.66021C22.2181 0.743302 21.4461 0 20.4939 0ZM36.2759 25.1688C35.3236 25.1688 34.5517 25.9121 34.5517 26.829V36.6796H6.44828V26.829C6.44828 25.9121 5.67635 25.1688 4.72414 25.1688C3.77192 25.1688 3 25.9121 3 26.829V38.3398C3 39.2567 3.77192 40 4.72414 40H36.2759C37.2281 40 38 39.2567 38 38.3398V26.829C38 25.9121 37.2281 25.1688 36.2759 25.1688Z"
      className="primary-fill"
      fill={fill}
    />
  </g>
);

IconDownload.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconDownload;
