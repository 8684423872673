import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Toast = ({ text, toastType, onDismissClick, removeToast, index }) => {
  const [toast, setToast] = useState({
    options: { heading: '', toastType: '' },
  });

  useEffect(() => {
    const options = {};
    switch (toastType) {
      case 'info':
        options.color = '#4caac6';
        options.heading = 'Information';
        setToast({ options });
        break;

      case 'error':
        options.color = '#DB4148';
        options.heading = 'Error!';
        setToast({ options });
        break;

      case 'success':
        options.color = '#0091DA';
        options.heading = 'Success!';
        setToast({ options });
        break;

      case 'warning':
        options.color = '#FF6B3E';
        options.heading = 'Warning!';
        setToast({ options });
        break;

      default:
        break;
    }
  }, [toastType]);

  useEffect(() => {
    if (index >= 0) {
      setTimeout(() => {
        removeToast(index);
      }, 10000);
    }
  }, [index]);

  return (
    <li
      role="status"
      className="toast-note px-3"
      style={{
        borderLeft: `15px solid ${toast.options.color}`,
      }}
    >
      <p
        className="fs-h2 mb-0 fw-bold"
        style={{ color: `${toast.options.color}` }}
      >
        {toast.options.heading}
      </p>
      <p className="fs-p mb-0 lh-125">{text}</p>
      <button
        aria-label="Dismiss"
        type="button"
        className="toast-note__dismiss"
        onClick={onDismissClick}
      >
        <i className="toast-note__dismiss fa-solid fa-xmark" aria-hidden />
      </button>
    </li>
  );
};

Toast.propTypes = {
  toastType: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onDismissClick: PropTypes.func.isRequired,
  removeToast: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default Toast;
