import React from 'react';
import PropTypes from 'prop-types';
import RowTpa from '../../../../common/QuickQuote/RowTpa';
import RowTpaComp from '../../../../common/QuickQuote/RowTpaComp';

const RowTpaDetails = ({ data, handleQuestionChange }) => (
  <div>
    <RowTpa data={data} handleQuestionChange={handleQuestionChange} />
    <RowTpaComp data={data} handleQuestionChange={handleQuestionChange} />
  </div>
);

RowTpaDetails.propTypes = {
  data: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default RowTpaDetails;
