import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SectionSingleCol = ({ copy, className, columns, id }) => (
  <section id={id} className={classNames('section section_py-5', className)}>
    <div className="container">
      <div className="row">
        <div
          className={classNames(columns, { 'col-lg-8 mx-auto': !columns })}
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </div>
    </div>
  </section>
);

SectionSingleCol.propTypes = {
  copy: PropTypes.any.isRequired,
  className: PropTypes.string,
  columns: PropTypes.string,
  id: PropTypes.string,
};

export default SectionSingleCol;
