import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectTpaQuestions,
  selectPrimaryTPA,
  selectTpas,
  selectApplicationPanelInfo,
} from '../../../../../Redux/FormDataRedux';
import {
  NextStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../../common/FormWithSteps';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../../../common/FormWithSteps/Utilities';
import Form from './Form';
import Header from '../Header';

const Tpas = ({
  role,
  active,
  questions,
  previousStep,
  updateActiveStep,
  updateQuestion,
  putApplicationPanelInfo,
  tpaInfo,
  primaryTPA,
  tpas,
  hashId,
  updateTPA,
  addTpa,
  destination,
}) => {
  const { tpaFirmName, employerIdentificationNumber, tpaFirmNameUnlisted } =
    questions;

  const validateTpas = () =>
    tpas.map((tpa) => {
      const validate = [
        tpa.questions.tpaEmail,
        tpa.questions.tpaFirstName,
        tpa.questions.tpaLastName,
        tpa.questions.tpaPhone,
        tpa.questions.tpaZipCode,
      ];
      validate.push(employerIdentificationNumber);
      validate.push(tpaFirmName);
      validate.push(tpaFirmNameUnlisted);
      return checkValidityOnQuestionArray(validate.filter((v) => v.required));
    });

  const [tpaFirmUnlisted, setTpaFirmUnlistedDisabled] = useState(false);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChangeTPA = ({ name, updateValue, ...rest }) => {
    updateTPA(
      {
        ...rest,
        name,
        dirty: true,
        value: updateValue,
        hasError: checkForErrors(name, updateValue, questions),
      },
      rest.tpaIndex,
    );
  };

  const handleQuestionChangeTpaPhone = ({ name, updateValue, ...rest }) => {
    [0, 1].forEach((element) => {
      updateTPA(
        {
          ...rest,
          name,
          dirty: true,
          value: updateValue,
          hasError: checkForErrors(name, updateValue, questions),
        },
        element,
      );
    });
  };

  const handleTpaFirmSelection = (id, updateValue) => {
    const handleEmptySelectionId = updateValue?.tpaID || '';
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted: updateValue[0]?.label || '',
      hasError: checkForErrors(id, updateValue, questions),
      dirty: true,
    });
    setTpaFirmUnlistedDisabled(updateValue.value === 'myFirmIsntListed');
    updateQuestion({
      name: employerIdentificationNumber.name,
      value:
        updateValue.value !== 'myFirmIsntListed' ? handleEmptySelectionId : '',
      valueFormatted: updateValue.tpaID,
      disabled: updateValue.value !== 'myFirmIsntListed',
      hasError: checkForErrors(
        employerIdentificationNumber.name,
        handleEmptySelectionId,
        questions,
      ),
      dirty: true,
    });
    updateQuestion({
      name: tpaFirmNameUnlisted.name,
      required: updateValue.value === 'myFirmIsntListed',
    });
  };

  const handleTpaFirmUnlisted = (e) => {
    setTpaFirmUnlistedDisabled(e.target.checked);
    if (e.target.checked) {
      updateQuestion({
        name: tpaFirmName.name,
        dirty: true,
        hasError: false,
        required: true,
        value: {
          id: 999,
          label: "My firm isn't listed",
          value: 'myFirmIsntListed',
          selected: false,
        },
        valueFormatted: `My firm isn't listed`,
      });
      updateQuestion({
        name: tpaFirmNameUnlisted.name,
        dirty: true,
        value: '',
        valueFormatted: '',
        hasError: true,
        required: true,
      });
      updateQuestion({
        name: employerIdentificationNumber.name,
        value: '',
        valueFormatted: '',
        disabled: false,
        dirty: true,
        hasError: true,
        required: true,
      });
    } else {
      updateQuestion({
        name: tpaFirmName.name,
        dirty: true,
        hasError: true,
        required: true,
        value: '',
        valueFormatted: '',
      });
      updateQuestion({
        name: tpaFirmNameUnlisted.name,
        dirty: true,
        value: '',
        valueFormatted: '',
        hasError: true,
        required: false,
      });
      updateQuestion({
        name: employerIdentificationNumber.name,
        value: '',
        valueFormatted: '',
        disabled: true,
        dirty: true,
        hasError: true,
        required: true,
      });
    }
  };

  const nextStepAction = () => {
    updateActiveStep(destination(), 'forward');
  };

  useEffect(() => {
    pushGoogleDataLayer({
      event: 'PlanDesignView',
      PlanDesignView: 'info_tpa',
    });
    addTpa();
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <Header step={1} />
      <div className="row">
        <div className="col-md-10 mx-auto">
          <Form
            role={role}
            userRole="advisor"
            tpaInfo={tpaInfo}
            questions={questions}
            handleQuestionChange={handleQuestionChange}
            tpaFirmUnlisted={tpaFirmUnlisted}
            handleQuestionChangeTPA={handleQuestionChangeTPA}
            handleQuestionChangeTpaPhone={handleQuestionChangeTpaPhone}
            handleTpaFirmUnlisted={handleTpaFirmUnlisted}
            handleTpaFirmSelection={handleTpaFirmSelection}
            primaryTPA={primaryTPA}
            tpas={tpas}
            tpaIndex={0}
            putApplicationPanelInfo={putApplicationPanelInfo}
          />
          <div className="fws__control">
            <NextStepBtn
              disabled={!validateTpas().every(Boolean)}
              onClick={() => {
                nextStepAction();
              }}
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          if (hashId !== '') {
            updateActiveStep('returnRoleSelection', 'back');
          } else updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

Tpas.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  hashId: PropTypes.string,
  primaryTPA: PropTypes.object.isRequired,
  tpas: PropTypes.array.isRequired,
  updateTPA: PropTypes.func.isRequired,
  addTpa: PropTypes.func.isRequired,
  destination: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  tpaInfo: selectTpaQuestions(store),
  tpas: selectTpas(store),
  primaryTPA: selectPrimaryTPA(store),
  applicationPanelInfo: selectApplicationPanelInfo(store),
});
const mapDispatchToProps = (dispatch) => ({
  addTpa: () => {
    dispatch(FormDataRedux.addTpa());
  },
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  updateTPA: (obj, index) => {
    dispatch(FormDataRedux.updateTPA(obj, index));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Tpas);
