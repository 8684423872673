import React from 'react';
import PropTypes from 'prop-types';

const IconAnniversaryO = ({ fill, fillAlt }) => (
  <g>
    <path
      d="M19.6348 29.9268C25.5082 29.9268 30.2696 25.2597 30.2696 19.5025C30.2696 13.7453 25.5082 9.07812 19.6348 9.07812C13.7614 9.07812 9 13.7453 9 19.5025C9 25.2597 13.7614 29.9268 19.6348 29.9268Z"
      stroke={fillAlt || fill}
      className="stroke"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.2739 19.5026C37.2739 21.1901 33.7044 22.2996 33.2042 23.822C32.683 25.4011 34.8837 28.3529 33.9097 29.6637C32.9252 30.99 29.3767 29.8443 28.0237 30.8094C26.6864 31.7641 26.7022 35.4332 25.0965 35.9441C23.5329 36.4395 21.3585 33.4774 19.6369 33.4774C17.9154 33.4774 15.7357 36.4395 14.1879 35.9441C12.5769 35.4332 12.598 31.7641 11.2607 30.8094C9.90766 29.8443 6.35922 30.99 5.37471 29.6637C4.40073 28.3529 6.6014 25.4011 6.08019 23.822C5.5695 22.2996 2 21.1849 2 19.5026C2 17.8151 5.5695 16.7056 6.06966 15.1832C6.59087 13.6041 4.3902 10.6522 5.36418 9.34144C6.34869 8.01517 9.89713 9.16082 11.2502 8.19579C12.5874 7.24109 12.5716 3.57193 14.1774 3.06103C15.7357 2.56046 17.9154 5.52262 19.6369 5.52262C21.3585 5.52262 23.5381 2.56046 25.086 3.05587C26.697 3.56677 26.6759 7.23593 28.0132 8.19063C29.3662 9.15566 32.9146 8.01001 33.8992 9.33628C34.8731 10.6471 32.6725 13.5989 33.1937 15.178C33.7044 16.7004 37.2739 17.8151 37.2739 19.5026Z"
      stroke={fillAlt || fill}
      className="stroke"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1502 16.5865L17.039 17.7683L16.4336 16.6123L19.4345 14.9197H20.8981V23.9403H19.1449V16.5865H19.1502Z"
      fill={fill}
      className="fill"
    />
  </g>
);

IconAnniversaryO.propTypes = {
  fill: PropTypes.string.isRequired,
  fillAlt: PropTypes.string,
};

export default IconAnniversaryO;
