import { collectSavedPlanData } from '../../../../Services/helpers/Paradigm/planHelper';
import API from '../../../../Services/api';

export const sendEmail = async (
  authenticityToken,
  hashId,
  to,
  recipient,
  message,
  cc,
  bcc,
) => {
  const api = API.create();
  try {
    const response = await api.sharePlanOnedigitalFp({
      authenticity_token: authenticityToken,
      hashId,
      to,
      name: recipient,
      message,
      cc,
      bcc,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const savePlan = async (formData, authenticityToken) => {
  try {
    const api = API.create();
    const savePlanData = collectSavedPlanData(formData);
    const { hashId, status, url } = await api.savePlan({
      ...savePlanData,
      authenticity_token: authenticityToken,
    });
    return { hashId, status, url };
  } catch (error) {
    return error;
  }
};
