export const stepTrackerObj = (steps, active = '') => {
  const visibleSteps = steps.filter((step) => step.props.showTracker === true);
  const activeStepIndex =
    visibleSteps.findIndex((step) => step.props.name === active) + 1;
  return {
    visibleSteps,
    activeStepIndex,
    totalSteps: visibleSteps.length,
  };
};
