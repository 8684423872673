import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import TableSection from './TableSection';
import { styles } from './Styles';
import Advisor from './advisor';
import Tpa from './tpa';

const Page1 = ({ data }) => (
  <Page size="A4" style={styles.page} wrap orientation="portrait">
    <View fixed style={styles.sectionHeader}>
      <Image style={styles.logo} src="/images/SimplyRetirement_Logo.png" />
    </View>
    <View style={styles.section}>
      <View style={styles.utilities.block}>
        <Text style={[styles.copyTitle, { fontSize: '14pt' }]}>
          Simply Retirement by Principal
          <Text style={[styles.copyTitleSuper, styles.registrationSup]}>
            ®
          </Text>
        </Text>
      </View>
      <View style={styles.utilities.block}>
        <Text style={styles.copyTitle}>
          401(k) Plan Proposal for{' '}
          {data.companyName.value && data.companyName.value}
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      <Text style={styles.copyDefault}>
        Number of employees: {data.numberOfEmployees.value}
      </Text>
    </View>
    <View style={styles.sectionRow}>
      {!!data.hasFp.value && (
        <View style={[styles.column50, styles.utilities.spacingPr10]}>
          <Advisor data={data} />
        </View>
      )}
      {!!data.hasTpa.value && (
        <View style={[styles.column50, styles.utilities.spacingPl10]}>
          <Tpa data={data} />
        </View>
      )}
    </View>
    <View style={styles.section}>
      <Text style={styles.copyDefault}>
        This proposal outlines the costs for offering a Simply Retirement by
        Principal
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®
        </Text>{' '}
        plan to your employees.¹
      </Text>
    </View>
    <TableSection tableData={data} />
    <View style={styles.section}>
      {!!data.hasTpa.value && (
        <Text style={styles.copyDefault}>
          While the TPA fees will be collected on the TPA’s behalf per TPA’s
          direction, it is the TPA’s responsibility to disclose these fees.
        </Text>
      )}
      <Text style={styles.copyDefault}>
        More than 57 million employees lack access to a retirement savings plan
        in their workplace.² Simply Retirement by Principal
        <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
          ®{' '}
        </Text>
        aims to change that by making 401(k) plans more accessible to businesses
        with fewer than 100 employees. We know plan costs are an important
        factor to consider, and predictable costs can help with budget planning.
      </Text>
    </View>
    <View style={styles.sectionRow}>
      <View style={[styles.column50, styles.utilities.spacingPr10]}>
        <Text style={styles.copyDefault}>
          <Text
            style={[
              styles.utilities.colorBrandPrimary,
              styles.utilities.fontWeight400,
            ]}
          >
            It’s easy.
          </Text>{' '}
          The streamlined process makes it simple to work with your third party
          administrator (TPA) and/or financial professional to create a plan
          that fits your needs.
        </Text>
      </View>
      <View style={[styles.column50, styles.utilities.spacingPl10]}>
        <Text style={styles.copyDefault}>
          <Text
            style={[
              styles.utilities.colorBrandPrimary,
              styles.utilities.fontWeight400,
            ]}
          >
            It’s user-friendly.
          </Text>{' '}
          Clean, modern online dashboards allow business owners to administer
          the plan, employees to pick from a carefully selected lineup of
          investment options, and financial professionals and TPAs to view
          client details.
        </Text>
      </View>
    </View>
    <View style={styles.sectionRow}>
      <View style={[styles.column50, styles.utilities.spacingPr10]}>
        <Text style={styles.copyDefault}>
          <Text
            style={[
              styles.utilities.colorBrandPrimary,
              styles.utilities.fontWeight400,
            ]}
          >
            It’s 100% digital.
          </Text>{' '}
          From purchasing the plan to onboarding employees, it’s all online—no
          paper or in-person meetings required. And if a question comes up,
          answers are just a phone call away.
        </Text>
      </View>
      <View style={[styles.column50, styles.utilities.spacingPl10]}>
        <Text style={styles.copyDefault}>
          <Text
            style={[
              styles.utilities.colorBrandPrimary,
              styles.utilities.fontWeight400,
            ]}
          >
            It’s flexible.
          </Text>{' '}
          Simply Retirement by Principal
          <Text style={[styles.copyDefaultSuper, styles.registrationSup]}>
            ®
          </Text>{' '}
          supports TPAs with full plan administration capabilities. Plus, our
          offering includes three Wilshire 3(38) investment option lineups for
          plan sponsor fiduciary protection when working with a financial
          professional.
        </Text>
      </View>
    </View>
    <View wrap={false}>
      <View style={styles.sectionCallOut}>
        <View style={{ width: '30%' }}>
          <Text style={styles.copyDisplay1}>
            Up to $5,000/year in tax credits available
            <Text style={styles.copyDisplay1Super}>4</Text>
          </Text>
        </View>
        <View style={{ width: '65%' }}>
          <Text
            style={[styles.copyDefault, { fontSize: '12pt', lineHeight: 1 }]}
          >
            SECURE 2.0 Act legislation offers small business owners tax credits
            for a portion of the cost of starting a new retirement plan for up
            to three years. This can help offset some of the plan costs.
            <Text style={[styles.copyDefault, { fontSize: '6pt' }]}>4</Text>
          </Text>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.copyDisclaimer}>
          Intended for financial professional, TPA, and plan sponsor use.
        </Text>
      </View>
    </View>
  </Page>
);

Page1.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page1;
