import styled from 'styled-components';

export const ImageColStyles = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 450px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    width: auto;
    height: auto;
    background-position: center top;
  }
`;

export const PurpleSectionStyles = styled.section`
  overflow: hidden;
  background-color: #002855;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    padding: 8rem 0;
  }

  @media (min-width: 992px) {
    min-height: 860px;
  }
  &:before,
  &:after {
    content: '';
    display: block;
    background-color: #ffffff;
    z-index: 2;
    left: 0;
    right: 0;
    height: 50px;
    transform: scale(1.2);
    position: absolute;
    border-radius: 100%;
    @media (min-width: 768px) {
      height: 200px;
    }
  }
  &:before {
    top: 0;
    transform: scale(1.2) translateY(-50%);
  }
  &:after {
    top: auto;
    bottom: 0;
    transform: scale(1.2) translateY(50%);
  }
`;
