import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Question,
  NextStepBtn,
  WithInfoPanel,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import { TRANSFER_PLAN_VERIFICATION_CODE } from '../../../Redux/FormDataRedux/InitialState/PlanDesignInitialState';
import { selectAdvisorQuestions } from '../../../Redux/FormDataRedux';

export const StepQuestion = ({
  role,
  active,
  questionId,
  questions,
  updateActiveStep,
  updateQuestionById,
  googleData,
  advisorInfo,
}) => {
  const { hasTpa } = questions;
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };
  const history = useHistory();
  const nextStepDestination = () => {
    if (role === 'tpa') {
      updateActiveStep('_tpa_step_1', 'forward');
    } else if (role === 'advisor') {
      if (questions.hasRetirementPlan.value === 'yes') {
        if (questions[questionId].value === 'no') {
          history.replace({
            pathname: '/explore-options',
          });
        }
      }
      if (hasTpa.value) {
        updateActiveStep('employerPaidParticipantFees', 'forward');
      } else {
        updateActiveStep('step_1', 'forward');
      }
    } else if (questions[questionId].value === 'yes') {
      updateActiveStep('employeesCanContribute', 'forward');
    } else {
      history.replace({
        pathname: '/explore-options',
      });
    }
  };

  const { value, dirty, hasError } = questions[questionId];
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };

  const previousStepDestination = () => {
    const brokerType = advisorInfo.filter((q) => q.name === 'brokerType')[0];

    if (role === 'tpa') {
      updateActiveStep('participantFees', 'back');
    } else if (role === 'advisor') {
      if (brokerType.value === 'insuranceAgent') {
        updateActiveStep('compensation', 'back');
      } else {
        updateActiveStep('investmentLineup', 'back');
      }
    } else {
      updateActiveStep('hasRetirementPlan', 'back');
    }
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <WithInfoPanel
        visibleInfoPanel={visibleInfoPanel}
        setVisibility={setVisibility}
        heading={questions[questionId].heading}
        content={questions[questionId].content}
        toggleInfoPanel={toggleInfoPanel}
      >
        <Question
          role={role}
          type={questions[questionId].type}
          labelClass="fs-form-q fw-bold text-center mb-4"
          inputClass="srp-form-input form-control"
          radioWrapper="srp-form-toggle"
          thousandSeparator
          question={questions[questionId]}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!questions[questionId].heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        {questions[questionId].value === 'yes' && (
          <div className="mx-auto justify-content-center">
            <div>
              <p className="fs-form-label--large fw-normal mb-3 text-center">
                Please enter the conversion plan code provided by your Principal
                Financial Group representative. If you do not have a code,
                please submit an email request to{' '}
                <a href="mailto:dlrissrreviewgroup@exchange.principal.com">
                  dlrissrreviewgroup@exchange.principal.com
                </a>
              </p>
              <Question
                role={role}
                type={questions.transferPlanInputVerificationCode.type}
                labelClass=""
                inputClass="mx-auto srp-form-input form-control text-center w-25"
                question={questions.transferPlanInputVerificationCode}
                handleQuestionChange={handleQuestionChange}
                hasInfo={!!questions.transferPlanInputVerificationCode.heading}
              />
            </div>
          </div>
        )}
        <p className="fws__control">
          <NextStepBtn
            disabled={
              !!(
                questions[questionId].value === 'yes' &&
                questions.transferPlanInputVerificationCode.value !==
                  TRANSFER_PLAN_VERIFICATION_CODE
              )
            }
            onClick={() => {
              setVisibility(false);
              pushGoogleDataLayer({
                event: 'PlanDesignQuestion',
                PlanDesignExistingPlanStatus: value,
              });
              nextStepDestination();
            }}
          />
        </p>
      </WithInfoPanel>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          setVisibility(false);
          previousStepDestination();
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

StepQuestion.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  googleData: PropTypes.object,
  advisorInfo: PropTypes.array.isRequired,
};
const mapStateToProps = (store) => ({
  advisorInfo: selectAdvisorQuestions(store),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StepQuestion);
