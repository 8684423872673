import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoPanel from './InfoPanel';
import WithInfoPanelMobile from './WithInfoPanelMobile';

const WithInfoPanel = ({
  visibleInfoPanel,
  toggleInfoPanel,
  setVisibility,
  children,
  heading,
  content,
}) => (
  <>
    <div className="row h-100">
      <div
        className={classNames('col-md-10 col-xl-8 d-flex flex-column m-auto', {
          'mx-auto': !visibleInfoPanel,
        })}
      >
        {children}
      </div>
      {visibleInfoPanel && (
        <div
          className={classNames('fws__info-panel h-100', {
            'col ': visibleInfoPanel === true,
          })}
          style={{
            animationDuration: '.2s',
            animationDelay: '0',
          }}
        >
          <InfoPanel
            heading={heading}
            content={content}
            toggleInfoPanel={setVisibility}
          />
        </div>
      )}
    </div>
    <WithInfoPanelMobile
      heading={heading}
      content={content}
      visibleInfoPanel={visibleInfoPanel}
      toggleInfoPanel={toggleInfoPanel}
      setVisibility={setVisibility}
    />
  </>
);

WithInfoPanel.propTypes = {
  visibleInfoPanel: PropTypes.bool,
  setVisibility: PropTypes.func.isRequired,
  toggleInfoPanel: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  heading: PropTypes.string,
  content: PropTypes.string,
};

export default WithInfoPanel;
