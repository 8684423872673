import React from 'react';
import PropTypes from 'prop-types';

import { BgImage, Circle } from './index.styles';
import { ScrollInToView } from '../../../../common/VisibilitySensor';

const TwoColCircleImg = ({ heading, copy, image, circleBg }) => (
  <section className="section section_py-5 mb-md-8 pb-8">
    <div className="container position-relative">
      <div className="row">
        <div
          className="ms-auto col-md-7 col-6"
          style={{ zIndex: 1 }}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      </div>
      <div className="row">
        <div className="ms-auto col-md-7 col-sm-9" style={{ zIndex: 1 }}>
          <div dangerouslySetInnerHTML={{ __html: copy }} />
        </div>
      </div>
      <ScrollInToView
        renderItem={(inView) => (
          <Circle className={circleBg}>
            <BgImage
              style={{
                backgroundImage: `url(${image})`,
                animationDelay: '0.2s',
              }}
              className={inView ? 'fadeIn animated' : 'hidden'}
            />
          </Circle>
        )}
      />
    </div>
  </section>
);

TwoColCircleImg.propTypes = {
  heading: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  circleBg: PropTypes.string.isRequired,
};

export default TwoColCircleImg;
