import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Question,
  StepControls,
  WithInfoPanel,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import FormDataRedux, { isFormValid } from '../../../Redux/FormDataRedux';

const EmployerContributionMatch = ({
  role,
  active,
  questionId,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  googleData,
  resetUnusedContributionMatch,
  isValid,
}) => {
  const primaryQuestion = questions[questionId];
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };

  const { dirty, hasError } = questions[questionId];
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
    updateQuestionById('safeHarbor', '', false, updateValue === 'no', '');
    updateQuestionById(
      'stretchSafeHarbor',
      '',
      false,
      updateValue === 'no',
      '',
    );
  };

  const showMessage = primaryQuestion.value === 'no';

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  useEffect(() => {
    resetUnusedContributionMatch(primaryQuestion.value);
  }, [primaryQuestion.value]);

  return (
    <div className="container-fluid h-100">
      <WithInfoPanel
        visibleInfoPanel={visibleInfoPanel}
        setVisibility={setVisibility}
        heading={questions[questionId].heading}
        content={questions[questionId].content}
        toggleInfoPanel={toggleInfoPanel}
      >
        <Question
          role={role}
          type={questions[questionId].type}
          labelClass="fs-form-q fw-bold text-center mb-4"
          inputClass="srp-form-input form-control"
          radioWrapper="srp-form-toggle"
          question={questions[questionId]}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!questions[questionId].heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        {showMessage && (
          <p className="text-center">
            If the business owner chooses to provide a profit-sharing
            contribution, it will be 100% vested. (Vesting is the participant’s
            earned interest in an employer contribution.)
          </p>
        )}
        <StepControls
          formInReview={formInReview}
          disableReviewBtn={!isValid}
          disabledNextBtn={
            questions[questionId].required ? !(dirty && !hasError) : false
          }
          goToReviewPlan={goToReviewPlan}
          clickNextBtn={() => {
            setVisibility(false);
            pushGoogleDataLayer({
              event: 'PlanDesignQuestion',
              PlanDesignContributionMatch: questions[questionId].value,
            });

            if (questions[questionId].value === 'no') {
              updateActiveStep('step_14', 'forward');
              return false;
            }

            updateActiveStep('step_11', 'forward');
            return false;
          }}
        />
      </WithInfoPanel>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          setVisibility(false);
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

EmployerContributionMatch.propTypes = {
  active: PropTypes.bool,
  role: PropTypes.string,
  questionId: PropTypes.string,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  googleData: PropTypes.object,
  resetUnusedContributionMatch: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const mapStateToProps = (store) => ({
  isValid: isFormValid(store),
});

const mapDispatchToProps = (dispatch) => ({
  resetUnusedContributionMatch: (value) => {
    dispatch(FormDataRedux.resetUnusedContributionMatch(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployerContributionMatch);
