import React from 'react';
import PropTypes from 'prop-types';

const IconCoinO = ({ fill, fillAlt }) => (
  <g>
    <path
      d="M20.034 0C8.957 0 0 8.957 0 20.034c0 11.077 8.957 20.034 20.034 20.034 11.009 0 20.034-8.957 20.034-20.034C40.068 8.957 31.043 0 20.034 0zm0 38.222c-10.051 0-18.188-8.137-18.188-18.188 0-10.051 8.137-18.256 18.188-18.256 10.052 0 18.188 8.137 18.188 18.188 0 10.051-8.205 18.256-18.188 18.256z"
      fill={fill}
      className="fill"
    />
    <path
      d="M20.513 19.077c-2.735-.957-4.445-1.64-4.24-3.692.547-2.462 2.804-2.53 3.488-2.462 1.572.137 3.213 1.094 3.282 2.462 0 .478.478.889.957.82.479 0 .889-.478.82-.957-.136-2.393-2.53-3.897-4.786-4.102v-2.94a.905.905 0 0 0-.889-.89.905.905 0 0 0-.889.89v3.008c-1.914.41-3.282 1.778-3.76 3.829v.068c-.342 3.624 2.871 4.718 5.47 5.607 2.598.889 4.24 1.573 4.24 3.282 0 1.163-.616 2.188-1.642 2.804-1.504.82-4.17 1.094-7.248-.684-.41-.274-.957-.137-1.23.342-.274.41-.137.957.341 1.23 1.3.753 2.599 1.231 3.966 1.436v2.804c0 .478.41.889.889.889.479 0 .889-.41.889-.89v-2.734c1.23 0 2.393-.342 3.35-.89 1.641-.956 2.599-2.53 2.599-4.375-.206-3.009-3.077-3.966-5.607-4.855z"
      fill={fillAlt || fill}
      className="fill"
    />
  </g>
);

IconCoinO.propTypes = {
  fill: PropTypes.string.isRequired,
  fillAlt: PropTypes.string,
};

export default IconCoinO;
