export const canCV = (advisor, checkCv) => {
  const principalError = {};
  if (!checkCv) return principalError;
  if (advisor.advisorCredential.eid.error) {
    principalError.message = advisor.advisorCredential.eid.error;
    principalError.subject = 'NPN Not Found - Simply Retirement ';
    principalError.code = advisor.advisorCredential.eid.code;
  }
  return principalError;
};
