import React from 'react';
import PropTypes from 'prop-types';
import {
  validUser,
  errorStep,
  pushGoogleDataLayer,
} from '../../../common/FormWithSteps/Utilities';

export const button = (
  userRole,
  createSavedForm,
  toggleShareModalVisibility,
  goToRoleSelection,
  employerInfo,
  employerStateCode,
  hasFp,
  hasTpa,
  advisorInfo,
  tpaInfo,
  formData,
  isValid,
) => {
  const scrollToSection = () => {
    document.getElementById('purchasePLanSection').scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };
  switch (
    validUser(
      userRole,
      employerStateCode.value,
      hasFp,
      hasTpa,
      advisorInfo,
      tpaInfo,
      employerInfo,
      formData,
      isValid,
    )
  ) {
    case 'employer':
      return {
        heading: 'Ready to purchase your plan?',
        copy: `Click the button below to log in and finish setting up the details.
          You can also save this plan and email yourself a link to come back
          later.`,
        buttonClick: (handleCreateModal) => {
          scrollToSection();
          handleCreateModal(pushGoogleDataLayer);
        },
        buttonText: 'Complete your purchase',
      };
    case 'employer-error':
      return {
        heading: 'Looks like we are missing information.',
        copy: 'Click the button below to finish filling out the form.',
        buttonClick: () => {
          scrollToSection();
          goToRoleSelection('employerQuestions');
        },
        buttonText: 'Enter your information',
      };
    case 'employer-share':
      return {
        heading: 'Ready to share this proposal?',
        copy: 'Click the button below to email a link. (You must still complete the plan purchase.)',
        buttonClick: () => {
          scrollToSection();
          createSavedForm(false);
          toggleShareModalVisibility();
        },
        buttonText: 'Share the proposal',
      };
    case 'advisor':
      return {
        heading: 'Ready to share this proposal?',
        copy: 'Click the button below and we’ll let the plan creator know the plan was updated and you’ll receive a copy of the email. (The business owner must complete the plan purchase).',
        buttonClick: () => {
          scrollToSection();
          createSavedForm(false);
          toggleShareModalVisibility();
        },
        buttonText: 'Share the proposal',
      };
    case 'advisor-to-either':
      return {
        heading: 'Ready to share this proposal?',
        copy: 'Click the button below and we’ll let the plan creator know the plan was updated and you’ll receive a copy of the email. (The business owner must complete the plan purchase).',
        buttonClick: () => {
          scrollToSection();
          createSavedForm(false);
          toggleShareModalVisibility();
        },
        buttonText: 'Share the proposal',
      };
    case 'advisor-save-only':
      return {
        heading: 'Ready to save and share this proposal?',
        copy: '',
        buttonClick: () => {
          scrollToSection();
          createSavedForm();
        },
        buttonText: 'Save and share the proposal',
      };
    case 'advisor-error':
      return {
        heading: 'Looks like we are missing information.',
        copy: 'Click the button below to finish filling out the form.',
        buttonClick: () => {
          scrollToSection();
          goToRoleSelection(errorStep(advisorInfo));
        },
        buttonText: 'Enter your information',
      };
    case 'tpa':
      return {
        heading: 'Ready to share this proposal?',
        copy: 'Click the button below and we’ll let the plan creator know the plan was updated and you’ll receive a copy of the email. (The business owner must complete the plan purchase).',
        buttonClick: () => {
          scrollToSection();
          createSavedForm(false);
          toggleShareModalVisibility();
        },
        buttonText: 'Share the proposal',
      };
    case 'tpa-to-either':
      return {
        heading: 'Ready to share this proposal?',
        copy: 'Click the button below and we’ll let the plan creator know the plan was updated and you’ll receive a copy of the email. (The business owner must complete the plan purchase).',
        buttonClick: () => {
          scrollToSection();
          createSavedForm(false);
          toggleShareModalVisibility();
        },
        buttonText: 'Share the proposal',
      };
    case 'tpa-save-only':
      return {
        heading: 'Ready to save and share this proposal?',
        copy: '',
        buttonClick: () => {
          scrollToSection();
          createSavedForm();
        },
        buttonText: 'Save and share the proposal',
      };
    case 'tpa-error':
      return {
        heading: 'Looks like we are missing information.',
        copy: 'Click the button below to finish filling out the form.',
        buttonClick: () => {
          scrollToSection();
          goToRoleSelection(errorStep(tpaInfo));
        },
        buttonText: 'Enter your information',
      };

    default:
      return {
        heading: 'Ready to finish up?',
        copy: 'In order to submit your plan, we need to collect a few important pieces of information from you.',
        buttonClick: () => {
          scrollToSection();
          goToRoleSelection();
        },
        buttonText: 'Enter your information',
      };
  }
};

export const Section = {
  loading: {
    heading: 'Submitting your plan',
    copy: 'You will be redirected to Ubiquity’s website momentarily.',
  },
  success: {
    heading: 'Success',
    copy: 'If you are not automatically redirected please use this link to continue to your new plan:',
  },
  tryAgain: {
    heading: 'Your plan has not been submitted yet.',
    copy: 'Your information is still being processed. We’re checking a few details and you’ll receive an email as soon as the plan has been submitted successfully.',
  },
  error: {
    heading: 'Your plan has not been submitted.',
    copy: 'Something didn’t work quite right on our end. Please refresh the page and try again.',
  },
  advisorError: {
    heading:
      'Your state requires you to have a financial professional with the required credentials to continue.',
    copy: 'We are following up with your financial professional and they’ll receive an email when this process is complete.',
  },
};

const SectionWrapper = ({ loading, heading, copy, children }) => (
  <div id="purchasePLanSection" className="row py-5 text-center">
    <div className="col-md-6 m-auto pb-5">
      <div className="fs-h4 mb-3 text-center">
        {loading && (
          <div className="spinner-border fs-p text-primary mr-3" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <strong>{heading}</strong>
      </div>
      <p
        className="fs-p text-center"
        dangerouslySetInnerHTML={{ __html: copy }}
      ></p>
      {children}
    </div>
  </div>
);

SectionWrapper.propTypes = {
  loading: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default SectionWrapper;
