export const planName = {
  stepName: '_tpa_step_4',
  paradigmAlias: 'planName',
  type: 'text_field',
  name: 'planName',
  user: {},
  label: 'Plan name',
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasError: false,
  reviewScreenTitle: 'Plan name',
  sideCardTitle: 'Plan name',
  optionDescription: '',
  description:
    'The plan name listed here must match the Summary Plan Description (SPD) document.',
};
