export const restrictedStateInsuranceAgent = (code) => {
  switch (code) {
    case 'select':
      return false;
    case 'AK':
      return false;
    case 'AZ':
      return false;
    case 'AR':
      return false;
    case 'CA':
      return false;
    case 'FL':
      return false;
    case 'HI':
      return false;
    case 'ID':
      return false;
    case 'IL':
      return false;
    case 'IN':
      return false;
    case 'IA':
      return false;
    case 'KS':
      return false;
    case 'LA':
      return false;
    case 'MI':
      return false;
    case 'MS':
      return false;
    case 'MO':
      return false;
    case 'NJ':
      return false;
    case 'OH':
      return false;
    case 'OR':
      return false;
    case 'TN':
      return false;
    case 'TX':
      return false;
    case 'VA':
      return false;
    case 'WA':
      return false;
    case 'WV':
      return false;
    case 'WY':
      return false;
    default:
      return true;
  }
};
