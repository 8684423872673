import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectPrimaryTPA,
  selectTpaQuestions,
} from '../../../../../Redux/FormDataRedux';
import {
  NextStepBtn,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../../common/FormWithSteps';
import Form from './Form';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../../../common/FormWithSteps/Utilities';
import Header from '../Header';

const DeductionFees = ({
  active,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestion,
  googleData,
  role,
  destination,
}) => {
  const { feeCollectionSource, tpaCompensationType, fixedAmount, bpsAmount } =
    questions;

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChangeCompensation = (
    id,
    updateValue,
    valueFormatted,
  ) => {
    handleQuestionChange(id, updateValue, valueFormatted);
    [tpaCompensationType.name, fixedAmount.name, bpsAmount.name].forEach(
      (q) => {
        updateQuestion({
          name: q,
          value: '',
          valueFormatted: '',
          hasError: false,
          dirty: updateValue === 'DIRECT',
        });
      },
    );
  };

  const handleQuestionChangeType = (id, updateValue, valueFormatted) => {
    handleQuestionChange(id, updateValue, valueFormatted);
    updateQuestion({
      name: fixedAmount.name,
      value: '',
      valueFormatted: '',
      hasError: false,
      dirty: updateValue === 'bpsAmount',
    });
    updateQuestion({
      name: bpsAmount.name,
      value: '',
      valueFormatted: '',
      hasError: false,
      dirty: updateValue === 'fixedAmount',
    });
  };

  const handleQuestionChangeConfirm = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: updateValue,
    });
  };

  const showTpaCompensationType =
    feeCollectionSource.value === feeCollectionSource.options[0].value;

  const showFixedAmount =
    showTpaCompensationType &&
    tpaCompensationType.value === tpaCompensationType.options[0].value;

  const showBpsAmount =
    showTpaCompensationType &&
    tpaCompensationType.value === tpaCompensationType.options[1].value;

  const questionsToValidate = () => {
    const validateThese = [
      feeCollectionSource,
      tpaCompensationType,
      fixedAmount,
      bpsAmount,
    ];
    return validateThese;
  };

  const nextStepAction = () => {
    pushGoogleDataLayer({
      event: 'PlanDesignView',
      PlanDesignView: 'participant_fees',
    });
    updateActiveStep(destination, 'forward');
  };

  React.useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <Header step={4} />
      <div className="row">
        <div className="col-md-10 d-flex flex-column justify-content-between mx-auto my-md-auto">
          <Form
            feeCollectionSource={feeCollectionSource}
            handleQuestionChangeCompensation={handleQuestionChangeCompensation}
            showTpaCompensationType={showTpaCompensationType}
            tpaCompensationType={tpaCompensationType}
            handleQuestionChangeType={handleQuestionChangeType}
            showFixedAmount={showFixedAmount}
            fixedAmount={fixedAmount}
            questions={questions}
            handleQuestionChange={handleQuestionChange}
            showBpsAmount={showBpsAmount}
            bpsAmount={bpsAmount}
            handleQuestionChangeConfirm={handleQuestionChangeConfirm}
            role={role}
          />

          <div className="fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(questionsToValidate())}
              onClick={() => {
                nextStepAction();
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

DeductionFees.propTypes = {
  active: PropTypes.bool,
  previousStep: PropTypes.string.isRequired,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  googleData: PropTypes.object,
  role: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  tpaInfo: selectTpaQuestions(store),
  primaryTPA: selectPrimaryTPA(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  updateTPA: (obj, index) => {
    dispatch(FormDataRedux.updateTPA(obj, index));
  },
  setPlanOriginator: (planOriginator) => {
    dispatch(FormDataRedux.setPlanOriginator(planOriginator));
  },
  putPlanURL: (planURL, hashId) => {
    dispatch(FormDataRedux.putPlanURL(planURL, hashId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeductionFees);
