import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux from '../../../Redux/FormDataRedux';
import {
  Question,
  StepControls,
  isEligible,
  PrevStepBtn,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';

export const EligibleEmployees = ({
  role,
  active,
  googleData,
  questionId,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  putApplicationPanelInfo,
  isValid,
}) => {
  const renderValidationText = () => {
    if (
      role === 'advisor' ||
      role === 'psivet' ||
      role === 'onedigital' ||
      role === 'tpa'
    ) {
      return (
        <p className="fs-p text-center">
          Simply Retirement by Principal
          <sup>
            <small>®</small>
          </sup>{' '}
          is built for companies with fewer than 100 employees.
        </p>
      );
    }
    return (
      <p className="fs-p text-center">
        Simply Retirement by Principal
        <sup>
          <small>®</small>
        </sup>{' '}
        is built for companies with fewer than 100 employees. Clicking “Next
        step” will take you to a form to contact Principal for other retirement
        plan options.
      </p>
    );
  };

  const validEligibleEmployeeValue = () => !isEligible(value) && value !== '';
  const { value, dirty, hasError } = questions[questionId];

  const disabledBasedOnRole = () => {
    if (role === 'advisor' || role === 'psivet' || role === 'tpa') {
      if (dirty && !hasError) return !isEligible(value);
    }
    return questions[questionId].required ? !(dirty && !hasError) : false;
  };
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Question
            role={role}
            type={questions[questionId].type}
            labelClass="fs-form-q fw-bold text-center pt-3 pb-5 px-3 d-block"
            inputClass={
              questions[questionId].type === 'number_field'
                ? 'text-question'
                : ''
            }
            thousandSeparator
            question={questions[questionId]}
            handleQuestionChange={handleQuestionChange}
          >
            {validate && (
              <p className="text-center">
                <small className="fs-p lh-125 text-danger text-center">
                  Question is required
                </small>
              </p>
            )}
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            {validEligibleEmployeeValue() && renderValidationText()}
            <button
              type="button"
              className="srp-button__link"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'Need clarification?',
                  additionalInformation: `Include all full-time and part-time employees you anticipate being eligible for the plan. Union employees, independent contractors, and nonresident aliens aren’t eligible for Simply Retirement by Principal<small><sup>®</sup></small>.`,
                })
              }
            >
              Need clarification?
            </button>

            <StepControls
              formInReview={formInReview}
              goToReviewPlan={goToReviewPlan}
              disableReviewBtn={!isValid}
              disabledNextBtn={disabledBasedOnRole()}
              clickNextBtn={() => {
                destination(isEligible(value), updateActiveStep);
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

EligibleEmployees.propTypes = {
  active: PropTypes.bool,
  role: PropTypes.string,
  googleData: PropTypes.object,
  questionId: PropTypes.string,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  isValid: PropTypes.bool,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(null, mapDispatchToProps)(EligibleEmployees);
