import React from 'react';
import PropTypes from 'prop-types';

const SectionEnrollmentFees = ({ putApplicationPanelInfo }) => (
  <section id="section-enrollment-fees" className="pt-0 pb-2">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-10 mx-auto">
          <h4 className="fs-h3 mb-3">Plan features</h4>
          <p className="fs-h2 text-purple">
            <strong>
              What makes Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              a different kind of solution?
            </strong>
          </p>
          <p className="fs-p">
            Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            is a 100% digital 401(k) plan solution from Principal
            <sup>
              <small>®</small>
            </sup>
            . Here are a few key features that set it apart:
          </p>
          <ul>
            <li className="fs-p">
              It&apos;s designed for small businesses with fewer than 100
              employees.
            </li>
            <li className="fs-p">
              It&apos;s all online. From designing and purchasing your plan to
              e-signing documents to onboarding employees and ongoing plan
              administration, everything can be done on a website—where and when
              it&apos;s convenient for you and your advisor.
            </li>
            <li className="fs-p">
              It has a flat recordkeeping fee. This solution is the same price
              for all customers, period.
            </li>
            <li className="fs-p">
              It&apos;s created for simplicity. Streamlined selections and
              investment choices give you options without overcomplicating the
              process. And with less time-consuming paperwork, advisors can
              focus on providing education and service.
            </li>
          </ul>
          <p className="fs-h2 text-purple">
            <strong>What’s the value of a 401(k) plan to my employees?</strong>
          </p>
          <p className="fs-p mb-4">
            First and foremost, it gives them an easy way to save for
            retirement. Contributions are automatically deducted from employee
            paychecks each pay period, so they don&apos;t have to budget
            separately for setting money aside. They also have an opportunity to
            take advantage of any matching contributions you may offer, making
            it possible for them to save more. Plus, pre-tax contributions are
            deducted from paychecks before income taxes, reducing individual
            taxable income, and taxes are deferred on any investment gains and
            earnings until the money is withdrawn in retirement.
          </p>
          <p className="fs-h2 text-purple">
            <strong>
              Do participants have the option to defer Roth contributions with
              this plan?
            </strong>
          </p>
          <p className="fs-p mb-4">
            Yes. By default, auto-enrollment contributions are only pre-tax
            contributions, but participants can choose to make both pre-tax and
            Roth contributions to their 401(k) plan from their dashboard once
            they set up their online account.
          </p>
          <p className="fs-h2 text-purple">
            <strong>What’s the vesting schedule for this plan?</strong>
          </p>
          <p className="fs-p mb-4">
            Business owners can choose to have their employees 100% vested in
            the 401(k) plan immediately or on a 6-year graded vesting schedule.
            <button
              type="button"
              className="btn btn-link d-block d-print-none mx-auto fs-disclaimer pt-0"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'Vesting Schedule',
                  additionalInformation:
                    "A vesting schedule is the time frame it takes for employees to earn interest in contributions that a business owner makes to the employee's retirement plan. This is determined by the business owner and may be used as a retainment incentive. When an employee is 100% vested in their account balance, they earn 100% interest of it and the employer cannot forfeit, or take it back, for any reason. An employee must work 1,000 hours in a calendar year to receive a year of service towards vesting.",
                });
              }}
            >
              More info
            </button>
          </p>
          <p className="fs-h2 text-purple">
            <strong>Are loans available with this plan?</strong>
          </p>
          <p className="fs-p mb-4">
            Yes. Participants can request to take one loan from their 401(k)
            plan balance and select a loan repayment schedule that best suits
            them but is no longer than five years. Participants may repay a loan
            to up to 30 years if the proceeds are used to purchase their primary
            residence. Only one loan may be outstanding at a time. Loan
            repayments are made via after-tax payroll deductions. The interest
            portion of the loan payment is applied to the participant’s account.
          </p>
          <p className="fs-h2 text-purple">
            <strong>Are hardship withdrawals available with this plan?</strong>
          </p>
          <p className="fs-p mb-4">
            Yes. Business owners can choose to have the plan allow for hardship
            withdrawals. This provision allows participants to withdraw eligible
            amounts from their 401(k) account to meet the needs of a financial
            hardship. Participants must pay taxes on the amount they withdraw,
            plus a 10 percent penalty if they are under age 59 1&frasl;2.
            Additional requirements may apply based on the provisions of the
            plan.
            <button
              type="button"
              className="btn btn-link d-block d-print-none mx-auto fs-disclaimer pt-1"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'Hardship Withdrawals',
                  additionalInformation:
                    'This provision allows participants to withdraw eligible amounts from their 401(k) account to meet the needs of a financial hardship. The IRS sets certain guidelines for circumstances that may qualify as a hardship withdrawal, such as out-of-pocket medical expenses, the purchase of or repairs to a primary home, college tuition and related educational expenses, prevention of a mortgage foreclosure or eviction, or funeral and burial expenses, and certain disaster related expenses. However, the plan terms ultimately set the specific criteria of a hardship withdrawals. Participants must pay taxes on the amount they withdraw, plus a 10 percent penalty if they are under age 59½. Additional requirements may apply based on the provisions of the plan.',
                });
              }}
            >
              More info
            </button>
          </p>
          <p className="fs-h2 text-purple">
            <strong>What is payroll integration and how does it work?</strong>
          </p>
          <p className="fs-p mb-4">
            Payroll integration connects your payroll provider directly to your
            401(k) plan. Depending on your payroll provider, each pay period,
            they will automatically share your employee&apos;s contribution
            information to the Simply Retirement by Principal
            <small>
              <sup>®</sup>
            </small>
            /Ubiquity recordkeeping system. Ubiquity’s Payroll (k)oncierge
            service can help you get started.
          </p>
          <p className="fs-h2 text-purple">
            <strong>How can I get more information on the investments?</strong>
          </p>
          <p className="fs-p mb-4">
            See <a href="/onedigital/investments">this page</a>. There’s a
            listing of investment options under each asset class, and you can
            click any investment option for fact sheets and other details. Talk
            to your advisor for more information.
          </p>
        </div>
      </div>
    </div>
  </section>
);

SectionEnrollmentFees.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

export default SectionEnrollmentFees;
