import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ShareForm from '../../../common/ShareForm';

const SharePlanModal = ({
  shareModalVisibility,
  toggleShareModalVisibility,
}) => {
  let modalRef;
  const bgOverlay = {
    overlay: {
      background: 'rgba(5, 5, 5, 0.5)',
    },
  };

  useEffect(() => {
    Modal.setAppElement(modalRef);
  }, []);

  return (
    <Modal
      className="Modal__Bootstrap modal-dialog modal-dialog-centered modal-xl"
      closeTimeoutMS={150}
      isOpen={shareModalVisibility}
      onRequestClose={() => toggleShareModalVisibility()}
      ariaHideApp={false}
      shouldCloseOnEsc
      style={bgOverlay}
    >
      <div
        ref={(arg) => {
          modalRef = arg;
        }}
        className="modal-content"
      >
        <div className="modal-header">
          <button
            type="button"
            aria-label="close"
            className="fa-solid fa-xmark fa-2x text-dusty no-border-button ms-auto p-3"
            onClick={() => {
              toggleShareModalVisibility();
            }}
          />
        </div>
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <ShareForm
                  toggleShareModalVisibility={toggleShareModalVisibility}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SharePlanModal.propTypes = {
  toggleShareModalVisibility: PropTypes.func.isRequired,
  shareModalVisibility: PropTypes.bool.isRequired,
};

export default SharePlanModal;
