import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';

const SecureActNotice = ({ toggleSecureActNotice }) => (
  <div className="position-relative rounded p-4 pr-5 mt-3 bg-simply-dark-blue">
    <button
      type="button"
      className="btn overlay-close-btn overlay-close-btn--sm"
      style={{
        position: 'absolute',
        top: '0',
        right: '0',
      }}
      onClick={() => {
        toggleSecureActNotice(false);
      }}
    >
      x
    </button>
    <p
      className="text-white pb-1 mb-0"
      style={{
        lineHeight: '125%',
      }}
    >
      <strong>
        Get up to $5,000/year in tax credits when you start a plan
      </strong>
    </p>
    <p
      className="text-white mb-0"
      style={{
        fontSize: '14px',
        lineHeight: '125%',
      }}
    >
      <Link
        to="business-owners/retirement-plans#secure-act"
        className="text-white"
      >
        Find out more
      </Link>{' '}
      about how the SECURE 2.0 Act can help offset plan startup costs.
    </p>
  </div>
);

SecureActNotice.propTypes = {
  toggleSecureActNotice: PropTypes.func.isRequired,
};

export default SecureActNotice;
