export const entryDatesPlanParticipation = {
  id: 'q5',
  type: 'radio_button',
  name: 'entryDatesPlanParticipation',
  paradigmAlias: 'entryDatesPlanParticipation',
  user: {},
  stepName: 'a_step_5',
  label: 'Select entry dates for plan participation from the following:',
  options: [
    {
      id: 1,
      user: {},
      label: 'Daily / Immediate',
      value: 'dailyImmediate',
      valueFormatted: 'Daily / Immediate',
      checked: false,
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: 'Monthly',
      info: '(First day of the month following the date the eligibility requirements are satisfied)',
      value: 'monthly',
      valueFormatted: 'Monthly',
      checked: false,
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: 'Quarterly',
      info: '(First of the calendar quarter following the date eligibility requirements are satisfied)',
      value: 'quarterly',
      valueFormatted: 'Quarterly',
      checked: false,
      customStyle: '',
    },
    {
      id: 4,
      user: {},
      label: 'Semi-annually',
      info: '(1/1 and 7/1)',
      value: 'semiannually',
      valueFormatted: 'Semi-annually',
      checked: false,
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Entry date for plan participation',
  reviewScreenTitle: 'Entry date for plan participation',
  optionDescription: '',
  description:
    'This is the entry date for deferrals. Monthly is the first day of the month following the date the eligibility requirements are satisfied. Quarterly is the first day of the calendar quarter following the date the eligibility requirements are satisfied.',
};
