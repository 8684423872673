import React from 'react';
import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import {
  FaqHeroImage,
  // tpaFaqImage1,
  // tpaFaqImage2,
  // tpaFaqImage3,
  // tpaFaqImage4,
  // tpaFaqAddDocument1,
  // tpaFaqAddDocument2,
  // tpaFaqAddDocument3,
} from '../../../ImagePaths';
// import NoticesTableSection from './NoticesTableSection';
// import SupportTableSection from './SupportTableSection';
// import { asOfDate } from '../../../Services/utilities';

const sectionHeader = {
  image: FaqHeroImage,
  title: 'Frequently  asked questions',
  caption: `
    <p class="fs-display5">
      Looking for more details? Here are answers to some of the questions you may have about Simply Retirement by Principal<sup><small>®</small></sup>.
    </p>
  `,
  alt: 'Man working on a computer.',
  copy: ``,
};

const sectionGeneralInformation = `
<h4 class="fs-h3 my-3 pt-5">
  General Information
</h4>
<p class="fs-h2 text-purple"><strong>What is Simply Retirement by Principal<sup><small>®</small></sup>?</strong></p>
<p class="fs-p mb-4">
    Simply Retirement by Principal<sup><small>®</small></sup> is a product for startup plans with fewer than 100 employees. It’s designed to offer an easy-to-use, fully online experience that removes some of the friction that can occur with traditional onboarding—including chasing down paperwork, getting answers to service and expense questions, finalizing the investment offering, and selecting a fidelity bond. Our goal is to simplify and streamline this process, so you can focus on delivering value to your clients.
</p>
<p class="fs-p mb-4">
    Backed by experience, this product combines more than 80 years of retirement expertise from Principal<sup><small>®</small></sup> with the digital technology and services of Ubiquity Retirement + Savings<sup><small>®</small></sup>. We’ve come together to help make retirement plans a reality for more small businesses.
</p>
<p class="fs-p mb-4">
    Third Party Administrators (TPAs) can create plans on their own and/or be included when a financial professional creates a plan.
</p>
<p class="fs-h2 text-purple"><strong>Why did Principal work with Ubiquity to create a digital product, and what does this term mean?</strong></p>
<p class="fs-p mb-4">
    When we refer to Simply Retirement by Principal<sup><small>®</small></sup> as a digital product, it means that everything is online, from designing the plan, to onboarding and administration. Our focus is on overcoming three obstacles that small business owners typically face as to why they don’t set up a retirement plan today—cost, complexity, and time.
</p>
<p class="fs-p mb-4">
    We <strong>worked with Ubiquity to</strong> create a digital product for several reasons. One of our goals was to provide a simple strategy for small businesses to establish a 401(k) plan and help their employees save for retirement—helping to address a need for the thousands of small businesses who don’t currently offer their employees a retirement savings plan. We also wanted to provide a simplified retirement plan platform for TPAs and financial professionals to quote and establish small business retirement plans efficiently and easily—so they can spend less time managing paperwork and more time helping clients navigate the process.
</p>
<p class="fs-h2 text-purple"><strong>Service providers</strong></p>
<p class="fs-h2 mb-4">
    <strong>I’ve noticed Principal<sup><small>®</small></sup>, Ubiquity Retirement + Savings<sup><small>®</small></sup>, Elevate by Principal<sup><small>®</small></sup>, and Wilshire Advisors LLC mentioned on this site. What are their roles and how do they fit in?</strong>
</p>
<p class="fs-p mb-4">
    Principal is the distributor and can be the investment manager of some of the investment options plan sponsors can use through Simply Retirement by Principal. Ubiquity provides the plan recordkeeping and administrative services once the plan is purchased. Your client and their participating employees will also use Ubiquity’s digital platform to manage the plan. You can read more about our collaboration <a href="https://www.simplyretirement.com/third-party-administrator/about">here</a>.
</p>
<p class="fs-p mb-4">
    With a Simply Retirement by Principal<sup><small>®</small></sup> plan, your client has access to Elevate by Principal<sup><small>®</small></sup>, a powerful network, resource, and team of people in their corner. From data-driven insights to deep discounts on various products and services, that a business owner can use every day, Elevate by Principal helps provide what your client needs to take their business to the next level. Upon purchase of a Simply Retirement by Principal<sup><small>®</small></sup> plan, your client will receive an invitation to register with Elevate by Principal<sup><small>®</small></sup> which will allow them to immediately take advantage of these additional services.
</p>
<p class="fs-p mb-4">
    Wilshire Advisors LLC will be the plan’s 3(38) investment fiduciary responsible for selecting and monitoring the plan’s investment options. They're an independent investment management firm that builds enduring relationships with clients through trust and a shared purpose that seek to improve investment outcomes for a better future. More than 47,000 plan sponsors rely on their independent expertise to help meet their fiduciary obligations.
</p>`;

const sectionCompensationAndFees = `
<h4 class="fs-h3 my-2 pt-2">
  TPA Compensation and Fees
</h4>
<p class="fs-h2 text-purple"><strong>Is Simply Retirement by Principal<sup><small>®</small></sup> part of the Principal TPA Edge<sup><small>SM</small></sup> program?</strong></p>
<p class="fs-p mb-4">
    Simply Retirement by Principal<sup><small>®</small></sup> is not included as part of the Principal TPA Edge program, and there’s no compensation from Principal for establishing the plan. Simply Retirement by Principal<sup><small>®</small></sup> plans will, however, count towards qualifying for TPA Platinum status. Here are some of the differences you may notice with Simply Retirement by Principal<sup><small>®</small></sup> compared to the Principal TPA Edge program:
</p>
<p class="fs-p mb-4">
    <ul>
        <li>You'll use a different website and process to get quotes and establish the plan.</li>
        <li>The onboarding transition plan timing is much shorter and streamlined.</li>
        <li>Creating a proposal and ongoing plan administration is different. Simply Retirement by Principal<sup><small>®</small></sup> proposals and plans are managed using the TPA portal on Ubiquity’s website.</li>
        <li>Making changes to the plan is efficient. It doesn’t require completing a Plan Provision Checklist (PPC) and pulling in a Client Services Associate at Principal<sup><small>®</small></sup>. The process is automated on the Ubiquity TPA portal, meaning less manual processing and paperwork.</li>
    </ul>
</p>
<p class="fs-h2 text-purple"><strong>How will the TPA be compensated? Will the TPA compensation options be the same as they are for a retirement plan on the Principal platform?</strong></p>
<p class="fs-p mb-4">
    Select client and participant level fees can be included in the Simply Retirement by Principal<sup><small>®</small></sup> fee proposal (if you choose) which will provide more transparency to the business owner and a “view” of the total cost:
    <ul>
        <li>One-time setup fee*</li>
        <li>Annual base fee*</li>
        <li>Annual per head fee*</li>
        <li>Asset based fee (up to $5,000 per year or 250 bps), paid quarterly.</li>
        <li>Participant transaction fees.</li>
    </ul>
    
</p>
<p class="fs-p mb-4">
    *  Billed directly to the employer and collected by the TPA outside of plan assets.
</p>
<h4 class="fs-h3 my-3 pt-5">
  Plan Fees
</h4>
<p class="fs-h2 text-purple"><strong>What are the plan fees, and who pays them?</strong></p>
<p class="fs-p mb-4">
    The business owner pays:
    <ul style="list-style: none;">
        <li><strong>$145 per month</strong> recordkeeping fee ($435 billed quarterly).</li>
        <li><strong>Financial professional and/or TPA fees</strong> if the business owner chooses to work with one. The TPA could have either a billed and/or deducted fee. If the financial professional chooses to be compensated through a flat fee, this fee will be billed to the business owner.</li>
        <li><strong>ERISA Fidelity Bond</strong> For the first three years, Ubiquity Retirement + Savings will cover the cost of an ERISA fidelity bond up to $250,000 in assets (which equals a $25,000 bond). After three years, a renewal letter will be sent and the business owner can choose to accept the renewal at their own expense or consult with their financial professional and/or TPA in choosing another bond provider.</li>
    </ul>
</p>
<p class="fs-p mb-4">
    The participant pays:
    <ul style="list-style: none;">
        <li><strong>$6 per participant,</strong> per month recordkeeping fee for each participant with an account balance over $100. This is deducted from participant accounts monthly. The business owner may elect to pay it quarterly on behalf of the participants.</li>
        <li>If the financial professional and/or the TPA chooses to be paid out of plan assets, this fee will be deducted quarterly from participant balances in the plan on a pro-rated basis.</li>
        <li><strong>Custodial and investment fees,</strong> which are charged against participating employees’ accounts can be found <a href="https://www.simplyretirement.com/third-party-administrator/investments" target="_blank">here</a>.</li>
    </ul>
</p>
<p class="fs-h2 text-purple"><strong>What happens if the company grows beyond 100 employees?</strong></p>
<p class="fs-p mb-4">
    Plans with 100 or more employees may be subject to an independent audit requirement. Ubiquity, as the recordkeeper, provides the TPA with access to annual reporting necessary to assist with the TPA and auditor’s efforts.
</p>
`;

const sectionPlanDesign = `
<h4 class="fs-h3 my-2 pt-2">
  Creating a plan design/proposal online
</h4>
<p class="fs-h2 text-purple"><strong>Can you provide me a summary of the Simply Retirement by Principal<sup><small>®</small></sup> quoting process?</strong></p>
<p class="fs-p mb-4">
On the TPA landing page, you’ll have the option to create a <a href="https://www.simplyretirement.com/third-party-administrator/plans#begin"><u>quick pricing proposal</u></a>. When you print or email the price quote, you also have the ability to download a <a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736S&ty=VOP">checklist</a> to walk through the plan design features with the business owner.
</p>
<p class="fs-p mb-4">
    To complete the full proposal with plan design details if you’re working with a financial professional:
    <ul>
        <li>The TPA owns all aspects of the plan design, and the financial professional selects the investment lineup and inputs their compensation. If the TPA initiates the process of creating the plan, they’ll be asked to provide the financial professional’s name and email. The financial professional will then be prompted to complete the investment selection. The financial professional can then share the plan with the client or send it back to the TPA to present it to the client for approval.</li>
        <li>If the financial professional initiates the process, they will insert their information and selections, then send it to the TPA to insert their information. The TPA can then share the plan with the client or send it back to the financial professional to present it to the client for approval.</li>
        <li>Note: Whether the TPA or the financial professional starts the full proposal, the second party in the process has the option to share it with the client for approval. This capability is not restricted to the party who started the plan (the plan originator).</li>
        <li>If you don’t already have access to the TPA portal at Ubiquity, when you navigate to the proposal review page, you’ll receive an invitation from Ubiquity Retirement + Savings to register and establish credentials for a TPA portal where you’ll have the ability to access and manage your Simply Retirement by Principal® proposals.</li>
        <li>If you have already established a Simply Retirement by Principal® TPA portal at Ubiquity, you can navigate to your portal to view and manage your Simply Retirement by Principal® proposals.</li>
    </ul>
</p>
<p class="fs-p mb-4">
    For questions on TPA portal registration and access, please contact 855-700-5569 or <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com</a>.
</p>

<p class="fs-h2 text-purple"><strong>What information do I need to create a plan online?</strong></p>
<p class="fs-p mb-4">
    When creating a plan, the TPA owns all aspects of the plan design and the financial professional (if applicable) selects the investment lineup and inputs their compensation. The TPA will have a variety of plan design options available for eligibility, vesting, and matching contributions that'll allow the recordkeeper, Ubiquity, to provide the day-to-day administrative service aspects of the clients' plan design. When completing a quick quote, the TPA will have the ability to download a <a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736S&ty=VOP">checklist</a> that’ll outline the plan design flexibility available. TPAs will need to provide the following data to create a plan through Simply Retirement by Principal<sup><small>®</small></sup>:
    <ul>
        <li>TPA sales contact’s full name and email</li>
        <li>TPA operational contact’s full name, email, phone number and ZIP code</li>
        <li>TPA firm name</li>
        <li>TPA Employer Identification Number (EIN)</li>
        <li>TPA logo for proposal (not required)</li>
        <li>Client contact’s full name and email</li>
        <li>Client company name</li>
        <li>Company state of business</li>
        <li>Client EIN</li>
        <li>Financial professional contact’s full name and email (for investment selection and fees, if applicable)</li>
        <li>TPA setup fee, annual base fee, and annual per head fee to be included on the proposal, if desired (not required)</li>
        <li>Number of employees at client firm that would be eligible for the plan</li>
        <li>Plan design selections around:
            <ul>
                <li>Plan effective date</li>
                <li>Roth contributions</li>
                <li>Eligibility and entry requirements</li>
                <li>Auto enrollment option</li>
                <li>Safe harbor</li>
                <li>ER contributions (if applicable)</li>
                <li>Vesting</li>
                <li>Loans and hardship withdrawals</li>
            </ul>
        </li>
    </ul>
</p>

<p class="fs-h2 text-purple"><strong>How long does it take to answer the questions and create a plan online?</strong></p>
<p class="fs-p mb-4">
    On average, 15 to 20 minutes is a reasonable estimate for creating a plan online, but your experience may vary. There are about 15 questions to answer. Plan designs are saved for 60 days.
</p>

<p class="fs-h2 text-purple"><strong>How do I get help if I have a question or need to make changes to the plan?</strong></p>
<p class="fs-p mb-4">
    When you create a Simply Retirement by Principal<sup><small>®</small></sup> plan online and share a link to that plan with either the financial professional or the business owner, the link will be available for 60 days. You can make changes online anytime during those 60 days until the business owner submits the plan for purchase. As a reminder, you can manage all your Simply Retirement by Principal<sup><small>®</small></sup> proposals through your TPA portal at Ubiquity.
</p>
<p class="fs-p mb-4">
    After the plan is purchased, your Simply Retirement by Principal<sup><small>®</small></sup> plans can be modified by making a request to the Ubiquity administrative team. Change requests may include plan provision changes, corrective refund requests, etc.
</p>`;

const sectionPlanFeatures = `
  <h4 class="fs-h3 my-2 pt-2">
    Plan features
  </h4>
  <p class="fs-p mb-4">
    With Simply Retirement by Principal<sup><small>®</small></sup>, we aim to make the upfront process streamlined and efficient for TPAs with a simple packaged plan. This allows the TPA to focus on their value proposition instead of completing lots of paperwork.
  </p>
  <p class="fs-h2 text-purple">
    <strong>What vesting options are available for plan design?</strong>
  </p>
  <p class="fs-p mb-4">
    The TPA will select the option that applies while creating the plan online. Refer to the <a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736S&ty=VOP">checklist</a> for a summary of the options.
  </p>
  <p class="fs-h2 text-purple">
    <strong>Are loans available with this plan?</strong>
  </p>
  <p class="fs-p mb-4">
    Yes. If the business owner chooses to offer loans, participants can request to take one loan from their 401(k) plan account balance and select a loan repayment schedule that best suits them—but is no longer than five years. Participants may repay a loan to up to 30 years if the proceeds are used to purchase their primary residence. Only one loan may be outstanding at a time. Loan repayments are made via after-tax payroll deductions. The interest portion of the loan payment is applied to the participant’s account.
  </p>
  <p class="fs-h2 text-purple">
    <strong>Are hardship withdrawals available with this plan?</strong>
  </p>
  <p class="fs-p mb-4">
    Yes. Business owners can choose to have the plan allow for hardship withdrawals. This provision allows participants to withdraw eligible amounts from their 401(k) account to meet the needs of a financial hardship. Participants must pay taxes on the amount they withdraw, plus a 10 percent penalty if they are under age 59 1⁄2. Additional requirements may apply based on the provisions of the plan.
  </p>
  <p class="fs-h2 text-purple">
    <strong>Do participants have the option to defer Roth contributions with this plan?</strong>
  </p>
  <p class="fs-p mb-4">
    Yes. If selected, business owners may choose to offer participants the ability to make Roth contributions in addition to pre-tax deferrals with this plan.
  </p>
  <p class="fs-h2 text-purple">
    <strong>Are the investment lineup options the same as the Principal platform, and will financial professionals be restricted in what they can use?</strong>
  </p>
  <p class="fs-p mb-4">
    Simply Retirement by Principal<sup><small>®</small></sup> offers various Wilshire 3(38) investment lineups to choose from depending on whether the business owner is working with a financial professional and which investments the financial professional is licensed to offer. For more information, see <a href="https://www.simplyretirement.com/third-party-administrator/investments">this page</a>. There’s a listing of investment options under each asset class and you can click any investment option for fact sheets and other details.
  </p>
  <p class="fs-p mb-4">
    Most clients work with a financial professional. If the TPA initiates the process of creating a Simply Retirement by Principal<sup><small>®</small></sup> plan you'll be asked to provide the financial professional’s first name, last name, and email. When you’re finished making the plan selections online, you'll be prompted to share the plan with the financial professional via email. The financial professional will open the link, select the investment lineup, save the plan, and share it back to you via email so you can review and send it to your client for approval and purchase.
  </p>
  <p class="fs-p mb-4">
    In situations where the TPA creates a plan and there isn’t a financial professional, the business owner will receive a pre-selected Wilshire 3(38) investment lineup, which is already factored into pricing. This allows you to continue focusing time and effort on your value proposition while utilizing the expertise of Wilshire Advisors LLC to assist your client in selecting an investment lineup for the plan.
  </p>
`;

const sectionOnBoarding = `
<h4 class="fs-h3 mb-3 pt-2">
  Onboarding
</h4>
<p class="fs-h2 text-purple">
  <strong>What payroll companies will you have integration with and how will they get set up?</strong>
</p>
<p class="fs-p mb-4">
    Ubiquity offers 360-integration for most clients with a cloud-based payroll system through Payroll (K)oncierge<sup><small>TM</small></sup> and Enhanced Payroll services. Please visit <a href="https://www.myubiquity.com/payroll-integration">Payroll Integration - Ubiquity</a> for more information regarding Ubiquity’s payroll services.
</p>
<p class="fs-h2 text-purple">
  <strong>Who'll help the plan sponsor with the first payroll submission?</strong>
</p>
<p class="fs-p mb-4">
    As part of the implementation process, a Ubiquity team member serves as the Payroll (K)oncierge<sup><small>TM</small></sup> and meets virtually with the business owner to review integration options, provides simple instructions, and assists them with their first payroll submission. There is no additional fee for this service as this is part of the Simply Retirement by Principal<sup><small>®</small></sup> plan sponsor experience.
</p>
<p class="fs-h2 text-purple">
  <strong>What happens after the business owner commits to purchasing the plan? How will plan implementation work?</strong>
</p>
<p class="fs-p mb-4">
    When the business owner clicks the “Complete your purchase” button, they’ll be redirected to the Ubiquity Retirement + Savings<sup><small>®</small></sup> (Ubiquity) website where they’ll:
    <ul>
        <li>Set up login credentials.</li>
        <li>Provide additional details about their business.</li>
        <li>Pay the one-time non-refundable $500 setup fee.</li>
        <li>Sign required documents electronically.</li>
    </ul>
</p>
<p class="fs-p mb-4">
    After completing these steps, you’ll enter the “Implementations” stage where you’ll work with your dedicated specialist at Ubiquity to set up the plan over 3 phone calls. Here’s what you can expect:
    <ul>
        <li>The business owner will get an email from “401(k) Implementations” &lt;newplan@myubiquity.com&gt; to set up a 50-minute introductory call with their dedicated specialist. The call should be scheduled as soon as possible, preferably within the next five business days.</li>
        <li>The specialist will help them add their employees on the 2nd call.</li>
        <li>The specialist will assist them in funding the first contribution on the 3rd and final call. After they process the first payroll and start funding the plan, they’ll be all set and the plan is active.</li>
    </ul>
</p>
`;

const sectionPlanAdministration = `
<h4 class="fs-h3 mb-3 pt-2">
    Plan administration on the Ubiquity TPA portal
</h4>
<p class="fs-h2 text-purple">
  <strong>Where will I go to view reports and complete administrative tasks for Simply Retirement by Principal<sup><small>®</small></sup> plans?</strong>
</p>
<p class="fs-p mb-4">
    TPAs have access to a TPA portal on the Ubiquity Retirement + Savings<sup><small>®</small></sup> website. The Ubiquity TPA portal includes a dashboard with client information. This portal also provides easy access to the information you need to manage day-to-day tasks. For example, on the Ubiquity TPA portal, you'll be able to:
    <ul>
        <li>View plan details such as balance, participation, outstanding withdrawals, loans, rollovers, and outstanding tasks.</li>
        <li>Review plan loans and distributions, including the ability to view uploaded supporting documents as necessary (e.g., hardships, residential loans, etc.).</li>
        <li>Upload reference documents (e.g. SPD, plan compliance test results, Summary Annual Reports).</li>
        <li>Access plan-specific reports.</li>
    </ul>
</p>

<p class="fs-h2 text-purple">
  <strong>How do I register for and/or access the Ubiquity TPA portal?</strong>
</p>
<p class="fs-p mb-2">
    Once the Simply Retirement by Principal<sup><small>®</small></sup> plan is purchased, the TPA sales contact will receive an email with a link to register for access to the Ubiquity TPA portal. The email will be sent from Ubiquity Retirement + Savings (<a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com</a>) and the subject line will be <em>Access your Simply Retirement by Principal<sup><small>®</small></sup> portal and proposals</em>. If you don’t receive the email, check your spam folder first. If it’s not there, call Ubiquity TPA support at 855-700-5569, Monday–Friday, 6 a.m.–5 p.m. PT.
</p>
`;

// const sectionTpaPortal = `
// `;

const sectionAddEmployees = `
<p>If contributions were posted to the wrong source, send an email to <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com</a> and include the following information:
    <ul>
        <li>Plan name</li>
        <li>Payroll period</li>
        <li>Participant name</li>
        <li>A description of the error and how the contribution should have been posted</li>
    </ul>
</p>
<p>Ubiquity's Operations Team will make the correction as necessary within five business days.</p>
<p>If the error was made in the last two business days, please call Ubiquity TPA support at 855-472-0472, and our team can confirm if we can recall the submission before a correction is needed.</p>
<p class="fs-h2 text-purple">
  <strong>How do I handle updates to plan, plan sponsor, and participant data?</strong>
</p>
<p>Simply Retirement by Principal<sup>®</sup> <strong>plan amendments and plan design changes</strong>
<p>Please ensure that plan amendments and plan design changes are eligible under the Simply Retirement by Principal® plan. See this worksheet for eligible options.</p>
    Send an email with the updated signed plan document to <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com.</a> and include:
    <ul>
        <li>Plan name</li>
        <li>A description of the plan amendment or design change</li>
        <li>The effective date of the plan amendment or design page</li>
    </ul>
    Ubiquity Retirement + Savings will notify you when the changes have been made to the recordkeeping system. Once notified, upload the updated Summary Plan Description (SPD) to the plan’s Documents page from your Ubiquity TPA portal. The uploaded SPD will be visible to trustees, plan administrators, and employees. The SPD will also be added automatically to enrollment emails.
</p>
<strong>Company name, plan name, and effective date changes</strong></br>
    Send an email with the updated signed plan document to <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com.</a> and include:
    <ul>
        <li>Plan name</li>
        <li>A description of what is changed (from and to)</li>
        <li>Effective date of the change</li>
    </ul>
<p>Ubiquity Retirement + Savings will notify you when the changes have been made to the recordkeeping system. </p>
<p>These types of changes generally require amendments to the Group Annuity Contract with Principal. Ubiquity will work with Principal to prepare the amendment and send it to the trustee for signature.</p>
<p>If an updated Summary Plan Description is required, upload the document to the plan’s Documents page from your Ubiquity TPA portal. The uploaded SPD will be visible to trustees, plan administrators, and employees. The SPD will also be added automatically to enrollment emails.</p>
<strong>Participant data reports and updates</strong></br>
    The <u>TPA</u> can <u>view</u> participant data by running the Employee Demographic Report for a specific plan.
    <ul>
        <li>From the left navigation, click on <strong>Reports</strong></li>
        <li>Report Type: Employee Demographic Report</li>
        <li>Plan Name: select the plan</li>
        <li>Date Range: choose the appropriate year or YTD</li>
    </ul>
<p>The <u>employer</u> can <u>add or update</u> an employee’s YTD hours, YTD compensation, ownership percentage and family relationships, and date of termination (add only), or rehire a terminated employee (add only) from the Manage Employees screen of the sponsor website. </p>
<p>Corrections to date of birth, date of hire or date of rehire, or a participant’s name should be sent to <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com.</a> Please include:</p>
<ul>
        <li>Plan name</li>
        <li>Participant’s name</li>
        <li>What the date is and what it should be changed to</li>
        <li>Reason for the change or any additional information or documentation that would help our operations team take the appropriate action.</li>
    </ul>`;

const sectionSupport = `
<h4 class="fs-h3 mb-3 pt-5">
  Support
</h4>
<strong>How can I get support to set up the plan?</strong>
<p class="fs-p">Every new plan is assigned an Implementation Specialist by Ubiquity to ensure the plan is set up correctly, and to help process the first employee census and payroll contribution.</p>
<p class="fs-p mb-0"><strong>Implementation support</strong></p>
<p class="fs-p mb-0"><a href="mailto:newplan@myubiquity.com">newplan@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:855-910-4015">855-910-4015</a></p>
<p class="fs-p mb-4">Monday–Friday, 6 a.m.–5 p.m. PT</p>

<p class="fs-h2 text-purple">
  <strong>How do I (and my clients) get support after my plan is set up?</strong>
</p>
<p class="fs-p">Ongoing support of Simply Retirement by Principal<sup><small>®</small></sup> plans is provided by Ubiquity. The following Customer Success Teams are available:</p>

<p class="fs-p mb-0"><strong>Plan sponsor support</strong></p>
<p class="fs-p mb-0"><a href="mailto:SimplySupport@myubiquity.com">SimplySupport@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18557005569"><u>855-700-5569</u></a></p>
<p class="fs-p">Monday–Friday, 6 a.m.–5 p.m. PT</p>

<p class="fs-p mb-0"><strong>Participant support</strong></p>
<p class="fs-p mb-0"><a href="mailto:info@myubiquity.com">info@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18554014357"><u>855-401-4357, Option 1</u></a></p>
<p class="fs-p">Monday–Friday, 6 a.m.–5 p.m. PT</p>

<p class="fs-p mb-0"><strong>TPA support</strong></p>
<p class="fs-p mb-0"><a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18557005569"><u>855-700-5569</u></a></p>
<p class="fs-p mb-3">Monday–Friday, 6 a.m.–5 p.m. PT</p>

<p class="fs-p mb-0"><strong>Financial professional support</strong></p>
<p class="fs-p mb-0"><a href="mailto:SimplySupport@myubiquity.com">SimplySupport@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18557005569"><u>855-700-5569</u></a></p>
<p class="fs-p mb-4">Monday–Friday, 6 a.m.–5 p.m. PT</p>

<p class="fs-p text-muted">
  <small>Intended for Financial Professional use only.</small>
</p>
`;

const TpaFaq = () => (
  <div className="about-page mb-5">
    <HeaderSection
      image={sectionHeader.image}
      title={sectionHeader.title}
      caption={sectionHeader.caption}
      copy={sectionHeader.copy}
      alt={sectionHeader.alt}
    />

    <SectionSingleCol
      copy={sectionGeneralInformation}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-6 pb-2"
      id="general_information"
    />

    <SectionSingleCol
      copy={sectionCompensationAndFees}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-1 pb-2"
      id="compensation_and_fees"
    />

    <SectionSingleCol
      copy={sectionPlanDesign}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-1 pb-2"
      id="plan_design"
    />

    <SectionSingleCol
      copy={sectionPlanFeatures}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-1 pb-2"
      id="plan_features"
    />

    <SectionSingleCol
      copy={sectionOnBoarding}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-1 pb-2"
      id="onboarding"
    />

    <SectionSingleCol
      copy={sectionPlanAdministration}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-1 pb-2"
      id="tpa_portal"
    />

    {/* <SectionSingleCol
      copy={sectionTpaPortal}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-5 mt-6"
      id="tpa_portal_and_registration"
    /> */}

    {/* <NoticesTableSection /> */}

    <SectionSingleCol
      copy={sectionAddEmployees}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-5 mt-1"
      id="add_employees"
    />

    {/* <SupportTableSection /> */}

    <SectionSingleCol
      copy={sectionSupport}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-5 mt-6"
      id="support"
    />
  </div>
);

export default TpaFaq;
