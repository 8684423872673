export const checkAdvisorCredentialVerification = (
  advisorCredentialVerification,
) => {
  switch (advisorCredentialVerification.brokerType) {
    case 'iarRia':
    case 'brokerDealer':
      return (
        advisorCredentialVerification.brokerEid !== '' &&
        advisorCredentialVerification.crd.value !== ''
      );
    case 'insuranceAgent':
      return (
        advisorCredentialVerification.insuranceAgentCompType === 'direct' ||
        (advisorCredentialVerification.insuranceAgent !== '' &&
          advisorCredentialVerification.insuranceAgentNpn !== '')
      );
    default:
      return false;
  }
};
