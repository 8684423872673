import React from 'react';

const FiduciaryResponsibility = () => (
  <div className="py-4 px-5">
    <h5 className="fs-h5 fw-bold my-2 pt-2">Fiduciary responsibility</h5>
    <p className="fs-p">
      <strong>
        Choosing investments for a retirement plan comes with a lot of
        responsibility. It’s called being a “fiduciary” and fiduciaries are
        personally liable for those choices.
      </strong>{' '}
      Wilshire Advisors LLC will be the plan’s 3(38) investment fiduciary.
      They’ll provide objective, independent third-party oversight for the
      screening, selection, and monitoring of the plan’s investment options, and
      will make changes as appropriate. This service will help manage your
      related fiduciary liability.
      <small>
        <sup>1</sup>
      </small>
    </p>
    <p className="fs-p">
      Wilshire Advisors LLC is a diversified global financial services firm with
      40-plus years of experience providing investment guidance to some of the
      largest plan sponsors in the U.S. The firm serves more than 500 clients
      across 20 countries, representing combined assets of more than $9
      trillion.
      <small>
        <sup>2</sup>
      </small>{' '}
      This includes providing fiduciary services for more than 39,000 plans.
      <small>
        <sup>3</sup>
      </small>
    </p>

    <p className="fs-disclaimer">
      <small>
        <sup>1</sup>
      </small>{' '}
      The decision to delegate and ongoing monitoring of the service provider is
      ultimately a responsibility that belongs to the appropriate retirement
      plan fiduciaries.{' '}
      <small>
        <sup>2</sup>
      </small>{' '}
      As of June 30, 2019.{' '}
      <small>
        <sup>3</sup>
      </small>{' '}
      As of Jan. 31, 2019.
    </p>
  </div>
);

export default FiduciaryResponsibility;
