import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '../Label';

export const Select = ({
  uniqueId,
  name,
  label,
  labelClass,
  inputClass,
  value,
  required,
  options,
  hasError,
  handleQuestionChange,
  infoLabel,
  placeholder,
  children,
}) => (
  <div
    className={classNames('mb-3 form-group', {
      'has-error': hasError,
    })}
  >
    <Label
      name={uniqueId || name}
      className={labelClass}
      text={label}
      required={required}
    />
    {infoLabel && (
      <div
        className="fs-p text-center mb-5"
        dangerouslySetInnerHTML={{ __html: infoLabel }}
      />
    )}
    <select
      id={uniqueId || name}
      name={uniqueId || name}
      autoComplete="off"
      value={value}
      className={`custom-select ${inputClass}`}
      onChange={(selected) => {
        handleQuestionChange(
          name,
          selected.target.value,
          selected.target.value,
        );
      }}
    >
      <option value="select" disabled hidden>
        {placeholder || 'Select'}
      </option>
      {options.map((option) => (
        <option key={option.id} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>

    {children}
  </div>
);

Select.propTypes = {
  uniqueId: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  options: PropTypes.array.isRequired,
  hasError: PropTypes.bool.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  infoLabel: PropTypes.any,
  placeholder: PropTypes.string,
  children: PropTypes.any,
};

export default Select;
