import React from 'react';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { Question } from '../FormWithSteps';

const ColFinancialPro = ({ data, handleQuestionChange }) => (
  <div className="col-md-5 mb-5">
    <h4 className="min-h-50">Financial professional details</h4>
    <Question
      type={data.advisorFirstName.type}
      inputClass="srp-form-input form-control"
      question={{
        ...data.advisorFirstName,
        label: `${data.advisorFirstName.label} ${
          data.advisorFirstName.required ? '(Required)' : '(Optional)'
        }`,
      }}
      handleQuestionChange={handleQuestionChange}
    >
      {data.advisorFirstName.hasError && data.advisorFirstName.required && (
        <small>Question is required</small>
      )}
    </Question>
    <Question
      type={data.advisorLastName.type}
      inputClass="srp-form-input form-control"
      question={{
        ...data.advisorLastName,
        label: `${data.advisorLastName.label} ${
          data.advisorLastName.required ? '(Required)' : '(Optional)'
        }`,
      }}
      handleQuestionChange={handleQuestionChange}
    >
      {data.advisorLastName.hasError && data.advisorLastName.required && (
        <small>Question is required</small>
      )}
    </Question>
    <Question
      type={data.advisorEmail.type}
      inputClass="srp-form-input form-control"
      question={{
        ...data.advisorEmail,
        label: `${data.advisorEmail.label} ${
          data.advisorEmail.required ? '(Required)' : '(Optional)'
        }`,
      }}
      handleQuestionChange={handleQuestionChange}
    >
      {data.advisorEmail.hasError && data.advisorEmail.required && (
        <small>Question is required</small>
      )}
    </Question>
    <div
      className={classNames('mb-3 form-group', {
        'has-error': data.advisorPhone.hasError,
      })}
    >
      <label className="" htmlFor="advisorPhone">
        {`${data.advisorPhone.label} ${
          data.advisorPhone.required ? '(Required)' : '(Optional)'
        }`}
      </label>
      <PatternFormat
        value={data.advisorPhone.value}
        format="###-###-####"
        mask="_"
        id="advisorPhone"
        name="advisorPhone"
        required={data.advisorPhone.required}
        onValueChange={(values) => {
          handleQuestionChange(
            data.advisorPhone.name,
            values.value,
            values.formattedValue,
          );
        }}
        type="tel"
        className="srp-form-input form-control"
      />
    </div>
    <Question
      type={data.firmName.type}
      inputClass="srp-form-input form-control"
      question={{
        ...data.firmName,
        label: `${data.firmName.label} ${
          data.firmName.required ? '(Required)' : '(Optional)'
        }`,
      }}
      handleQuestionChange={handleQuestionChange}
    >
      {data.firmName.hasError && data.firmName.required && (
        <small>Question is required</small>
      )}
    </Question>
    <Question
      type={data.advisorStateCode.type}
      inputClass="srp-form-input form-control"
      question={{
        ...data.advisorStateCode,
        label: `${data.advisorStateCode.label} ${
          data.advisorStateCode.required ? '(Required)' : '(Optional)'
        }`,
      }}
      handleQuestionChange={handleQuestionChange}
    >
      {data.advisorStateCode.hasError && data.advisorStateCode.required && (
        <small>Question is required</small>
      )}
    </Question>
  </div>
);

ColFinancialPro.propTypes = {
  data: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default ColFinancialPro;
