import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import HeaderSection from '../../../common/HeaderSection';
import { PrincipalTabs, Item } from '../../../common/PrincipalTabs';
import QuickQuote from '../../../common/QuickQuote';
import TabContent from './TabContent';
import { ResourcesHeroBusinessOwner, CheckCircle } from '../../../ImagePaths';

const sectionHeader = {
  image: ResourcesHeroBusinessOwner,
  title: 'Resources for business owners',
  caption: `
    <p class="fs-display5">
      Setting up a retirement plan and not sure where to start? You’re in the right place. We want to help you learn so you can make informed decisions for your company and your employees.
    </p>
  `,
  alt: 'Employee reading article in newspaper.',
  copy: ``,
};

const tabsSmallBiz = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Small business retirement trends and insights
    </h3>
    <p class="fs-h2 mb-5">
      Learn about the latest industry trends and what to expect when it comes to retirement plans and your business.
    </p>
  `,
  resourceCards: [
    {
      id: 1,
      headline: 'Principal<sup><small>®</small></sup> Business Owner Insights',
      date: 'Feb 21, 2024',
      link: 'https://www.principal.com/businesses/trends-insights/principal-business-owner-insights',
    },
    {
      id: 2,
      headline: 'Retirement disruptors to watch by 2030',
      date: 'March, 2023',
      link: 'https://www.principal.com/about-us/global-insights/retirement-disruptors-watch-2030',
    },
    {
      id: 0,
      headline:
        '3 things every small business owner needs to know about employee retirement',
      date: 'Jan 1, 2020',
      link: 'https://www.myubiquity.com/business/small-business-owner-employee-retirement-knowledge/',
    },
  ],
};

const tabsChoosingPlan = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Choosing and designing a retirement plan
    </h3>
    <p class="fs-h2 mb-5">
      Here are some things to consider as you evaluate your options and
      design a retirement plan for your business.
    </p>
  `,
  resourceCards: [
    {
      id: 2,
      headline: 'What does the SECURE 2.0 Act of 2022 mean for employers?',
      date: 'April, 2024',
      link: 'https://www.principal.com/businesses/trends-insights/secure-20-act-2022-what-employers-need-know',
    },
    {
      id: 0,
      headline: 'How to make saving in a 401(k) plan hard to avoid',
      date: 'March, 2024',
      link: 'https://www.principal.com/businesses/trends-insights/how-make-saving-401k-plan-hard-avoid',
    },
    {
      id: 1,
      headline: 'Benefits help attract and retain top talent',
      date: 'March, 2024',
      link: 'https://www.principal.com/businesses/trends-insights/client-perspective-benefits-help-your-business-attract-and-retain-top',
      linkText: 'See Video',
    },
    {
      id: 3,
      headline:
        '3 affordable ways small business owners can start a retirement plan',
      date: 'October, 2023',
      link: 'https://www.principal.com/businesses/trends-insights/start-small-business-retirement-plan',
    },
    {
      id: 4,
      headline: 'Guide to finding the right retirement plan',
      date: 'April, 2020',
      link: 'http://www.myubiquity.com/wp-content/uploads/2019/08/Ubiquity_401kGuide.ok2_2019-1.pdf',
    },
    {
      id: 5,
      headline: 'Guide to safe harbor 401(k)',
      date: 'April, 2020',
      link: 'https://www.myubiquity.com/401k/safe-harbor-401k/',
    },
    {
      id: 6,
      headline: 'Your small business is the right size for a 401(k)',
      date: 'March, 2020',
      link: 'https://www.myubiquity.com/401k-plan-information/small-business-big-benefits/',
    },
    {
      id: 7,
      headline: 'Choosing a retirement solution for your small business',
      date: 'March, 2020',
      link: 'https://www.dol.gov/sites/dolgov/files/ebsa/about-ebsa/our-activities/resource-center/publications/choosing-a-retirement-solution-for-your-small-business.pdf',
    },
    {
      id: 8,
      headline:
        'Why offer a competitive retirement plan? A resource for plan sponsors',
      date: 'March, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12337T&ty=VOP&EXT=.VOP',
    },
    {
      id: 9,
      headline: 'What’s a safe harbor 401(k) plan and how is it different?',
      date: 'March, 2020',
      link: '/safeharbor-explained',
      linkText: 'Visit Link',
    },
  ],
};

// const tabsMangingEffect = {
//   intro: `
//     <h3 class="fs-h3 text-purple">
//       Managing an effective retirement plan
//     </h3>
//     <p class="fs-h2 mb-5">
//       Find out what you need to know as the plan sponsor to manage this
//       important benefit for your employees.
//     </p>
//   `,
//   resourceCards: [
//     {
//       id: 0,
//       headline: 'What best-in-class retirement plan sponsors do differently',
//       date: 'April, 2020',
//       link: 'https://www.principal.com/employers/trends-insights/what-best-class-retirement-plan-sponsors-do-differently',
//     },
//   ],
// };

// const tabsPlanResources = {
//   intro: `
//     <h3 class="fs-h3 text-purple">
//       401(k) plan resources
//     </h3>
//     <p class="fs-h2 mb-5">
//       Find out what you need to know as the plan sponsor to manage this
//       important benefit for your employees.
//     </p>
//   `,
//   resourceCards: [
//     {
//       id: 0,
//       headline: 'What best-in-class retirement plan sponsors do differently',
//       date: 'April, 2020',
//       link: 'https://www.principal.com/employers/trends-insights/what-best-class-retirement-plan-sponsors-do-differently',
//     },
//   ],
// };

const tabsResources = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Resources for your employees
    </h3>
    <p class="fs-h2 mb-5">
      Share these resources with your employees to help them prepare for the
      future and maximize their savings.
    </p>
  `,
  resourceCards: [
    {
      id: 1,
      headline:
        'Finance 101: What is credit, a credit score, and a credit report and why they matter',
      date: 'April, 2024',
      link: 'https://www.principal.com/individuals/build-your-knowledge/what-credit-score-and-how-do-you-improve-yours',
    },
    {
      id: 0,
      headline: 'How and when to increase your 401(k) contributions',
      date: 'October, 2023',
      link: 'https://www.principal.com/individuals/build-your-knowledge/how-max-out-your-401k-and-ira-retirement-contributions ',
    },
    {
      id: 2,
      headline: 'Should you save for retirement or pay off student loans?',
      date: 'October, 2023',
      link: 'https://www.principal.com/individuals/build-your-knowledge/should-you-save-retirement-or-pay-student-loans',
    },
    {
      id: 3,
      headline:
        'How to build a better budget to help reach your financial goals',
      date: 'March, 2023',
      link: 'https://www.principal.com/individuals/build-your-knowledge/planning-budget-step-step',
    },
  ],
};

const Resources = () => {
  const location = useLocation();

  const isTpa =
    matchPath(location.pathname, {
      path: '/third-party-administrator',
    }) !== null;

  return (
    <div className="resources-page mb-5">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        alt={sectionHeader.alt}
      />
      <section
        className="section position-relative"
        style={{ marginTop: '-70px', zIndex: '1' }}
      >
        <div className="container-xl px-0">
          <PrincipalTabs panelStyle="default">
            <Item
              title="Small business retirement trends and insights"
              component={
                <TabContent
                  intro={tabsSmallBiz.intro}
                  resourceCards={tabsSmallBiz.resourceCards}
                />
              }
            />
            <Item
              title="Choosing and designing a retirement plan"
              component={
                <TabContent
                  intro={tabsChoosingPlan.intro}
                  resourceCards={tabsChoosingPlan.resourceCards}
                />
              }
            />
            {/* <Item
              title="Managing an effective retirement plan"
              component={
                <TabContent
                  intro={tabsMangingEffect.intro}
                  resourceCards={tabsMangingEffect.resourceCards}
                />
              }
            /> */}
            {/* <Item
              title="401(k) plan resources"
              component={
                <TabContent
                  intro={tabsPlanResources.intro}
                  resourceCards={tabsPlanResources.resourceCards}
                />
              }
            /> */}
            <Item
              title="Resources for your employees"
              component={
                <TabContent
                  intro={tabsResources.intro}
                  resourceCards={tabsResources.resourceCards}
                />
              }
            />
          </PrincipalTabs>
        </div>
      </section>
      <section id="quickQuote" className="section section_py-5">
        {!isTpa && (
          <QuickQuote
            questionLabels={{
              numberOfEmployees: 'How many people work at your organization?',
              averageSalary: 'What’s the average salary per employee?',
            }}
            sectionLabel={{
              offerEmployees: 'Offer a 401(k) plan to your employees for:',
              matchContributions: 'Your matching contributions could cost:',
            }}
          />
        )}
      </section>
      <section className="section py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <h3 className="fs-h3 text-center mb-5">
                See what Simply Retirement by Principal
                <sup>
                  <small>®</small>
                </sup>{' '}
                offers you.
              </h3>
            </div>
          </div>
          <div className="row d-print-none">
            <div className="col-lg-7 mx-auto">
              <p className="fs-h6 text-center mb-5">
                Watch this video demo and see how we make it easy to design and
                manage retirement plans for small businesses.
              </p>
              <ReactPlayerLoader
                accountId="1510329453001"
                videoId="6274775432001"
                attrs={{
                  className: 'brightcove-player',
                }}
                embedOptions={{ responsive: { aspectRatio: '16:9' } }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section section_py-5 pt-5">
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <img
                src={CheckCircle}
                alt="Checkmark In Circle Icon"
                className="d-block mx-auto mb-3"
              />
              <h3 className="fs-h3 text-center mb-4">Ready to get started?</h3>
              <p className="text-center pb-5 mb-5">
                <Link to="/plans" className="srp-button srp-button__primary">
                  Try our 401(k) planner
                </Link>
              </p>
              <p className="fs-disclaimer text-center pb-5 mb-5">
                Intended for plan sponsor use.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Resources;
