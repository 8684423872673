import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RenderCustomMessage } from './RenderCustomMessage';
import { IconBlueShareO } from '../../ImagePaths';
import { ShareRadioButtons } from './ShareFormRadioButtons';

export const ShareForm = ({
  shareModalCopy,
  createAnotherPlan,
  email,
  handleEmail,
  // preview,
  submitForm,
  userRole,
  hasFp,
  updateQuestion,
  resetData,
  advisorFirmBroker,
  messageVisible,
  setMessageVisible,
  setMessage,
  disabled,
  shareWith,
  sponsorEmail,
  advisorEmail,
  tpaEmail,
}) => {
  const { heading, recipient } = shareModalCopy;
  const sendToEitherParty =
    shareWith === 'advisorToEitherParty' || shareWith === 'tpaToEitherParty';
  return (
    <div>
      <img
        src={IconBlueShareO}
        alt=""
        width={26}
        height={24.72}
        className="d-block mx-auto"
      />
      <h4 className="fs-h4 text-center fw-bold text-purple">
        Share the proposal
      </h4>
      <p
        className="fs-sm text-center mt-4"
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      {!createAnotherPlan && (
        <>
          <div className="mt-4 mb-5">
            {sendToEitherParty && (
              <ShareRadioButtons
                sponsorEmail={sponsorEmail}
                userRole={userRole}
                handleEmail={handleEmail}
                tpaEmail={tpaEmail}
                email={email}
                advisorEmail={advisorEmail}
              />
            )}
            <div
              className={classNames('mb-3 form-group', {
                'has-error': !email.value,
              })}
            >
              {sendToEitherParty === false && (
                <div>
                  <label
                    className="fs-form-label text-center d-block"
                    htmlFor="email"
                  >
                    {recipient}
                  </label>{' '}
                </div>
              )}
              <input
                value={email.value}
                type="email"
                name="email"
                className={classNames('srp-form-input form-control', {
                  'is-invalid': !email.isValid,
                })}
                disabled={disabled}
                id="email"
                placeholder="Email"
                onChange={(el) => {
                  handleEmail(el.target.value);
                }}
              />
            </div>
            <RenderCustomMessage
              advisorFirmBroker={advisorFirmBroker}
              messageVisible={messageVisible}
              setMessageVisible={setMessageVisible}
              recipient={recipient}
              setMessage={setMessage}
            />
            <div className="row align-items-center">
              <p className="col-md-auto mx-auto text-center py-3">
                <button
                  type="button"
                  className="srp-button srp-button__primary"
                  disabled={!email.isValid || email.value === ''}
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Send proposal
                </button>
              </p>
            </div>
          </div>
          {userRole === 'employer' && hasFp.value && (
            <p className="text-center">
              <button
                type="button"
                className="srp-button__link"
                onClick={() => {
                  updateQuestion({
                    name: hasFp.name,
                    value: false,
                    valueFormatted: 'No',
                    hasError: false,
                    dirty: true,
                    disabled: false,
                  });
                }}
              >
                I’m not working with a financial professional.
              </button>
            </p>
          )}
          <p className="font-italic text-center">
            This proposal will be valid for 60 days.
          </p>
        </>
      )}
      {createAnotherPlan && (
        <div className="mx-5 my-5">
          <div className="card bg-lite">
            <div className="m-auto p-2">
              <p className="text-center fw-bold mb-0 text-green">
                The plan has been delivered to:
              </p>
              <p className="text-center text-green mb-0">{email.value}</p>
            </div>
          </div>
          <p className="text-center pt-5">
            <button
              type="button"
              className="srp-button srp-button__primary"
              onClick={() => {
                resetData();
                sessionStorage.clear();
              }}
            >
              Create another plan
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

ShareForm.propTypes = {
  userRole: PropTypes.string.isRequired,
  resetData: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  shareModalCopy: PropTypes.object.isRequired,
  createAnotherPlan: PropTypes.bool.isRequired,
  email: PropTypes.object.isRequired,
  handleEmail: PropTypes.func.isRequired,
  // preview: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  hasFp: PropTypes.object.isRequired,
  advisorFirmBroker: PropTypes.object.isRequired,
  messageVisible: PropTypes.bool.isRequired,
  setMessageVisible: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  shareWith: PropTypes.string,
  advisorEmail: PropTypes.string,
  tpaEmail: PropTypes.string,
  sponsorEmail: PropTypes.string,
};
