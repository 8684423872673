import React from 'react';
import PropTypes from 'prop-types';

const IconSingleUserO = ({ fill }) => (
  <g>
    <path
      d="M20.1234 24.2578C25.8794 24.2578 30.5456 19.499 30.5456 13.6289C30.5456 7.75871 25.8794 3 20.1234 3C14.3674 3 9.70117 7.75871 9.70117 13.6289C9.70117 19.499 14.3674 24.2578 20.1234 24.2578Z"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M38.259 36.2047C37.5815 30.1887 35.6742 29.2109 33.6106 27.8291C29.4209 25.0337 24.6475 24.2578 20.1347 24.2578C15.6219 24.2578 10.8381 25.0443 6.64832 27.8291C4.58471 29.2109 2.66702 30.1887 2 36.2047"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
  </g>
);

IconSingleUserO.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconSingleUserO;
