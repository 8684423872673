import React from 'react';
import InvestmentLineupAccordion from './InvestmentLineupAccordion';

const InvestmentLineUp = () => (
  <section>
    <h5 className="fs-h2 fw-bold text-purple mt-4">US Equity</h5>
    <h6 className="fs-p fw-bold">US Equity Large-Cap</h6>
    <p className="fs-p">
      This asset class is generally composed of investment options that invest
      in stocks or shares of ownership in large, well-established, U.S.
      companies. These investment options typically carry more risk than fixed
      income investment options but have the potential for higher returns over
      longer time periods. They may be an appropriate choice for long-term
      investors who are seeking the potential for growth. All investment options
      in this category have the potential to lose value.
    </p>

    <InvestmentLineupAccordion
      title="US Equity Large-Cap investment options"
      lineup={[
        {
          title: 'LargeCap Growth I Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=4395&rtclss=97',
        },
        {
          title: 'LargeCap S&P 500 Index Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=80&rtclss=97',
        },
        {
          title: 'LargeCap Value III Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=4397&rtclss=97',
        },
      ]}
    />

    <h6 className="fs-p fw-bold">US Equity Small/Mid-Cap</h6>
    <p className="fs-p">
      This asset class is generally composed of investment options that invest
      in stocks or shares of ownership in small to medium-sized U.S. companies.
      These investment options typically carry more risk than larger U.S. equity
      investment options but have the potential for higher returns. They may be
      an appropriate choice for long-term investors who are seeking the
      potential for growth. All investment options in this category have the
      potential to lose value.
    </p>

    <InvestmentLineupAccordion
      title="US Equity Small/Mid-Cap investment options"
      lineup={[
        {
          title: 'SmallCap Growth I Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=4393&rtclss=97',
        },
        {
          title: 'SmallCap S&P 600 Index Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=191&rtclss=97',
        },
        {
          title: 'SmallCap Value II Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=6211&rtclss=97',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">International Equity</h5>
    <p className="fs-p">
      This asset class is composed of investment options that invest in stocks
      or shares of ownership in companies with their principal place of business
      or office outside the United States. These investment options often carry
      more risk than U.S. equity investment options but may have the potential
      for higher returns. They may be an appropriate choice for long-term
      investors who are seeking the potential for growth. All investment options
      in this category have the potential to lose value.
    </p>
    <InvestmentLineupAccordion
      title="International Equity investment options"
      lineup={[
        {
          title: 'International Equity Index Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=10020&rtclss=97',
        },
        {
          title: 'International I Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=156&rtclss=97',
        },
        {
          title: 'Overseas Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9320&rtclss=97',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">Fixed Income</h5>
    <p className="fs-p">
      This asset class is generally composed of investment options that invest
      in bonds or debt of a company or government entity (including U.S. and
      Non-U.S.). It may also include real estate investment options that
      directly own property. These investment options typically carry more risk
      than short-term fixed income investment options (including, for real
      estate investment options, liquidity risk), but less overall risk than
      equities. All investment options in this category have the potential to
      lose value.
    </p>
    <InvestmentLineupAccordion
      title="Fixed Income investment options"
      lineup={[
        {
          title: 'Bond Market Index Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=10021&rtclss=97',
        },
        {
          title: 'Core Plus Bond Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=60&rtclss=97',
        },
        {
          title: 'High Yield Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=13647&rtclss=97',
        },
        {
          title: 'Inflation Protection Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=6447&rtclss=97',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">Target Date</h5>
    <p className="fs-p">
      Target date portfolios provide a diversified exposure to stocks, bonds,
      and cash for those investors who have a specific date in mind for
      retirement or another goal. These portfolios aim to provide investors with
      an optimal level of return and risk, based solely on the target date. Over
      time, management adjusts the allocation among asset classes to more
      conservative mixes as the target date approaches. All investment options
      in this category have the potential to lose value.
    </p>
    <InvestmentLineupAccordion
      title="Target Date investment options"
      lineup={[
        {
          title: 'Principal LifeTime Hybrid 2015 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9260&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2020 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9261&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2025 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9262&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2030 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9263&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2035 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9264&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2040 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9265&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2045 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9266&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2050 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9267&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2055 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9268&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2060 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=13755&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2065 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=16896&rtclss=97',
        },
        {
          title: 'Principal LifeTime Hybrid 2070 CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&symbol=T7097',
        },
        {
          title: 'Principal LifeTime Hybrid Income CIT Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9269&rtclss=97',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">Capital Preservation</h5>
    <p className="fs-p">
      Stable-value portfolios seek to provide income while preventing price
      fluctuations. The most common stable-value portfolios invest in a
      diversified portfolio of bonds and enter into wrapper agreements with
      financial companies to guarantee against fluctuations in their share
      prices. These wrapper agreements typically provide price stability on a
      day-to-day basis, thereby insulating each portfolio&apos;s net asset value
      from interest-rate volatility.
    </p>
    <InvestmentLineupAccordion
      title="Capital Preservation investment options"
      lineup={[
        {
          title: 'Morley Stable Value Fund',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=9082&rtclss=20',
        },
      ]}
    />
    <h5 className="fs-h2 fw-bold text-purple mt-4">Real Estate</h5>
    <p className="fs-p">
      Real estate portfolios invest primarily in real estate investment trusts
      of various types. REITs are companies that develop and manage real estate
      properties. There are several different types of REITs, including
      apartment, factory-outlet, health care, hotel, industrial, mortgage,
      office, and shopping center REITs. Some portfolios in this category also
      invest in real estate operating companies. All investment options in this
      category have the potential to lose value.
    </p>
    <InvestmentLineupAccordion
      title="Real Estate investment options"
      lineup={[
        {
          title: 'Global Real Estate Securities Separate Account-Z',
          link: 'https://www.principal.com/InvestmentProfiles/index.faces?&inv=13646&rtclss=97',
        },
      ]}
    />
  </section>
);

export default InvestmentLineUp;
