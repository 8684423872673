import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import SimplyIconSprite from '../../../../common/SimplyIconSprite';

const QuestionSection = ({ putApplicationPanelInfo }) => (
  <section className="section section_py-5 bg-concrete">
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-5 mx-auto">
          <SimplyIconSprite
            name="icon-comment-question-o"
            className="d-block mx-auto"
            size="75"
            fill="#00ADCA"
            height="75%"
          />
          <p className="fs-h5 fw-bold text-center">
            Still have questions?
            <br /> We have answers.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-5">
          <p className="fs-lead fw-bold lh-125">
            What are the tax benefits for starting a new 401(k) plan?
          </p>
          <p className="fs-sm lh-165">
            A new enhancement was made to the tax credits intended to help cover
            the costs for small employers that choose to offer new defined
            contribution plans. Small employers with 50 or fewer employees can
            now count 100% (maximum $5,000 a year) of their qualified plan
            expenses toward the tax credit calculation, allowing more employers
            the ability to maximize the&nbsp;
            <button
              type="button"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation:
                    '*Employers with 51-100 employees can utilize the tax credit under the SECURE Act of 2019,  which is 50% of qualifying start-up costs for new employee retirement plan (maximum $5,000 a year).',
                })
              }
              className="text-underline p-0 no-border-button"
            >
              tax credit.
            </button>
          </p>
          <a
            href="https://www.principal.com/landing-page/secureact-plansponsor"
            target="_blank"
            className="srp-button srp-button__primary_outline fs-disclaimer"
          >
            Benefits of the SECURE 2.0 Act
          </a>
        </div>
        <div className="col-md-4 mb-5">
          <p className="fs-lead fw-bold lh-125">
            What is a 401(k) plan, and how does it compare?
          </p>
          <p className="fs-sm lh-165">
            401(k) plans allow employees to set aside a portion of their pay,
            typically before taxes. Employers can make contributions to the
            employees&rsquo; retirement plan if they choose.
          </p>
          <Link
            to="psivet/retirement-plans#401k-table"
            className="srp-button srp-button__primary_outline fs-disclaimer"
          >
            401(k) plan details
          </Link>
        </div>
        <div className="col-md-4 mb-5">
          <p className="fs-lead fw-bold lh-125">
            What’s involved in managing a 401(k) plan?
          </p>
          <p className="fs-sm lh-165">
            The program automates many of the tasks required, but you&rsquo;ll
            still have a few basic responsibilities as the plan administrator.
            Here’s what you need to know.
          </p>
          <Link
            to="psivet/learn-the-basics"
            className="srp-button srp-button__primary_outline fs-disclaimer"
          >
            Retirement plan basics
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default QuestionSection;

QuestionSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func,
};
