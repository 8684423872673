import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux from '../../../../Redux/FormDataRedux';
import {
  pushGoogleDataLayer,
  checkForErrors,
  multiQuestionNextDisabled,
} from '../../../../common/FormWithSteps/Utilities';
import {
  Question,
  StepControls,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../common/FormWithSteps';

const DiscretionaryMatchQuestion = ({
  active,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestion,
  role,
  googleData,
  resetUnusedBqacaAltTwoOptions,
  isValid,
  ...stepProps
}) => {
  // eslint-disable-next-line camelcase
  const { b_minAgeReqEligibility } = questions;

  const getSecondaryQuestionOptions = () => {
    let options;
    if (b_minAgeReqEligibility.value === 'noMin') {
      options = questions[stepProps.subQuestionIds[0]].options;
    } else if (b_minAgeReqEligibility.value === '18') {
      options = [
        {
          id: 1,
          user: {},
          label: 'Age 18',
          value: '18',
          valueFormatted: '18',
          checked: false,
          customStyle: '',
        },
        {
          id: 2,
          user: {},
          label: 'Age 21',
          value: '21',
          valueFormatted: '21',
          checked: false,
          customStyle: '',
        },
      ];
    } else {
      options = [
        {
          id: 2,
          user: {},
          label: 'Age 21',
          value: '21',
          valueFormatted: '21',
          checked: false,
          customStyle: '',
        },
      ];
    }
    return options;
  };
  const primaryQuestion = questions[stepProps.questionId];
  const secondaryQuestion = questions[stepProps.subQuestionIds[0]];
  const [validateQuestions, setValidateQuestions] = useState([]);
  const showQuestion =
    primaryQuestion.value === primaryQuestion.options[0].value;

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
    if (id === primaryQuestion.name)
      updateQuestion({
        name: secondaryQuestion.name,
        value: updateValue === 'no' ? '' : secondaryQuestion.value,
        valueFormatted:
          updateValue === 'no' ? '' : secondaryQuestion.valueFormatted,
        dirty: updateValue === 'no',
        required: updateValue === 'yes',
      });
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  useEffect(() => {
    resetUnusedBqacaAltTwoOptions(primaryQuestion.value);
    if (primaryQuestion.value === 'yes') {
      setValidateQuestions([primaryQuestion, secondaryQuestion]);
    } else {
      setValidateQuestions([primaryQuestion]);
    }
  }, [primaryQuestion, secondaryQuestion]);

  return (
    <div className="container-fluid h-100">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Question
            role={role}
            labelClass="fs-form-q fw-bold text-center mb-4"
            radioWrapper="srp-form-toggle"
            type={primaryQuestion.type}
            question={primaryQuestion}
            handleQuestionChange={handleQuestionChange}
          />
          {showQuestion && (
            <Question
              role={role}
              labelClass="fs-h4 fw-bold text-center mb-4 text-center"
              inputClass="srp-form-input form-control"
              type={secondaryQuestion.type}
              question={{
                ...secondaryQuestion,
                options: getSecondaryQuestionOptions(),
              }}
              handleQuestionChange={handleQuestionChange}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <StepControls
              formInReview={stepProps.formInReview}
              disableReviewBtn={!isValid}
              disabledNextBtn={
                !checkValidityOnQuestionArray(validateQuestions) ||
                multiQuestionNextDisabled(primaryQuestion, secondaryQuestion)
              }
              goToReviewPlan={stepProps.goToReviewPlan}
              clickNextBtn={() => {
                updateActiveStep(destination(primaryQuestion.value), 'forward');
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

DiscretionaryMatchQuestion.propTypes = {
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  role: PropTypes.string,
  updateQuestion: PropTypes.func.isRequired,
  googleData: PropTypes.object,
  resetUnusedBqacaAltTwoOptions: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  resetUnusedBqacaAltTwoOptions: (value) => {
    dispatch(FormDataRedux.resetUnusedBqacaAltTwoOptions(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscretionaryMatchQuestion);
