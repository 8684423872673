export const restrictedState = (code) => {
  switch (code) {
    case 'FL':
      return true;
    case 'TX':
      return true;
    case 'GA':
      return true;
    default:
      return false;
  }
};
