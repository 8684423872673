import React, { useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import SimplyIconSprite from '../../../../common/SimplyIconSprite';

const startupCostsDisclaimer = `<p class="fs-startup-costs-disclaimer">Start-up tax credit modification: Small employers with 50 or fewer employees may apply 100% of qualified start-up costs towards the tax credit formula (up to $5,000 per year). </p><p>New tax credit for start-up plans offering employer contributions: A tax credit equal to the applicable percentage of employer contributions, capped at a maximum of $1,000 per employee.<ul><li>Applicable to small employers with 50 or fewer employees.</li><li>For employees with 51-100 employees: The credit is phased out by reducing the amount of credit each year 2% for each employee in excess of 50.</li></ul><b>Applicable Percentage:</b><p>1st and 2nd year = 100%, 3rd year = 75%, 4th year = 50%, 5th year = 25%, 6th year = 0% </p><p>No contributions may be counted for employees with wages in excess of $100,000 (inflation adjusted). If taking advantage of this tax credit, employer contributions may not also be counted towards “start-up costs” in the start-up tax credit calculation.</p></p>`;
const QuestionSection = ({
  putApplicationPanelInfo,
  financialProfessionalDisclaimer4,
  applicationPanelInfo,
}) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = ['startupCostsDisclaimer'];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'startupCostsDisclaimer':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: startupCostsDisclaimer,
        });
        break;

      default:
        break;
    }
  };

  return (
    <section className="section section_py-5">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-5 mx-auto">
            <SimplyIconSprite
              name="icon-comment-question-o"
              className="d-block mx-auto"
              size="75"
              fill="#00ADCA"
              height="75%"
              alt="Questions Icon"
            />
            <p className="fs-h5 fw-bold text-center">
              Still have questions?
              <br /> We have answers.
            </p>
          </div>
        </div>{' '}
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-5">
            <p className="fs-lead fw-bold" style={{ lineHeight: '125%' }}>
              How will my financial professional fee be paid?
            </p>
            <p className="fs-sm text-left">
              You have two options: bill your fee to your client separately or
              have it deducted each quarter from participant accounts. In terms
              of fees, you can charge a fixed fee, 25 basis points, 50 basis
              points, or 75{' '}
              <button
                type="button"
                onClick={() =>
                  putApplicationPanelInfo({
                    hasVisibility: true,
                    additionalInformation: financialProfessionalDisclaimer4,
                  })
                }
                className="text-underline p-0 no-border-button"
              >
                basis points.
              </button>
              <sup>
                <small>*</small>
              </sup>
            </p>
            <Link to="/financial-professionals/retirement-plans">
              Additional fee payment details
            </Link>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <p className="fs-lead fw-bold" style={{ lineHeight: '125%' }}>
              How can my clients benefit from SECURE 2.0 Act tax credits?
            </p>
            <p className="fs-sm text-left">
              The SECURE 2.0 Act offers a tax credit that can help offset up to
              100%{' '}
              <span
                id="startupCostsDisclaimer"
                className="text-underline p-0 no-border-button"
              >
                (up to $5,000 per tax year for the first three years for some
                employers){' '}
              </span>{' '}
              for a new 401(k) plan. There’s also a tax credit for automatic
              enrollment, which is a feature of the Simply Retirement by
              Principal
              <small>
                <sup>®</sup>
              </small>{' '}
              plan.
            </p>
            <Link to="/financial-professionals/retirement-plans#secure-act">
              SECURE 2.0 Act tax credit specifics
            </Link>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <p className="fs-lead fw-bold" style={{ lineHeight: '125%' }}>
              Where do I fit and add value in the sales process?
            </p>
            <p className="fs-sm text-left">
              Although business owners can design a plan, many
              won&rsquo;t—because they need expert guidance before they’re
              comfortable making a purchase. From safe harbor to compliance
              testing, your value is in helping them understand the tough stuff.
            </p>
            <Link to="/financial-professionals/resources">
              Expand your tool kit with sales materials
            </Link>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <p className="fs-lead fw-bold" style={{ lineHeight: '125%' }}>
              How does this 401(k) plan compare to traditional plans?
            </p>
            <p className="fs-sm text-left">
              Simply Retirement by Principal
              <small>
                <sup>&reg;</sup>
              </small>{' '}
              is 100% online, from plan design and purchase to onboarding and
              administration. It’s also designed specifically for businesses
              with fewer than 100 employees.
            </p>
            <Link to="/financial-professionals/retirement-plans#401k-table">
              401(k) plan comparison
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionSection;

QuestionSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  financialProfessionalDisclaimer4: PropTypes.any,
  applicationPanelInfo: PropTypes.any,
};
