export const setVestingSchedule = (planDesign, tpaParadigmPackager) => {
  // eslint-disable-next-line camelcase
  const { bQaca_vestingScheduleQaca } = planDesign.questions;
  const {
    autoEnrollmentPlan = { value: '', valueFormatted: '' },
    vestingScheduleMatch = { value: '', valueFormatted: '' },
    vestingScheduleProfit = { value: '', valueFormatted: '' },
  } = tpaParadigmPackager;
  let vestingSchedule;
  if (autoEnrollmentPlan !== undefined && autoEnrollmentPlan.value === 'qaca') {
    // eslint-disable-next-line camelcase
    vestingSchedule = bQaca_vestingScheduleQaca;
  }
  if (
    (autoEnrollmentPlan !== undefined && autoEnrollmentPlan.value === 'aca') ||
    autoEnrollmentPlan.value === 'eaca' ||
    autoEnrollmentPlan.value === ''
  ) {
    if (vestingScheduleMatch !== undefined) {
      vestingSchedule = vestingScheduleMatch;
    }
    if (
      vestingScheduleMatch === undefined &&
      vestingScheduleProfit !== undefined
    ) {
      vestingSchedule = vestingScheduleProfit;
    }
    if (
      vestingScheduleProfit !== undefined &&
      vestingScheduleMatch !== undefined
    ) {
      vestingSchedule = vestingScheduleMatch;
    }
  }
  return vestingSchedule;
};
