export const autoEscalateAutoEnrolledDeferrals = {
  id: 'q6aI1b',
  type: 'radio_condensed',
  name: 'autoEscalateAutoEnrolledDeferrals',
  paradigmAlias: 'hasAutoEscalation',
  user: {},
  label: 'Do you want to auto-escalate auto-enrolled participant deferrals?',
  infoLabel: '',
  stepName: 'a_step_6_a_i_1_a',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      icon: 'icon-check-o',
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      icon: 'icon-close-o',
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Auto escalate deferrals',
  reviewScreenTitle: 'Auto escalate deferrals',
  optionDescription: '',
  description: '',
};
