export const minServiceReqEligibility = {
  id: 'q4',
  type: 'radio_button',
  name: 'minServiceReqEligibility',
  paradigmAlias: 'minServiceReqEligibility',
  user: {},
  stepName: 'minServiceReqEligibility',
  label: 'Select the minimum service requirement for eligibility purposes.',
  options: [
    {
      id: 1,
      user: {},
      label: '1 month (elapsed time)',
      value: 'oneMonth',
      valueFormatted: '1 month (elapsed time)',
      checked: false,
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: '3 months (elapsed time)',
      value: 'threeMonths',
      valueFormatted: '3 months (elapsed time)',
      checked: false,
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: '6 months (elapsed time)',
      value: 'sixMonths',
      valueFormatted: '6 months (elapsed time)',
      checked: false,
      customStyle: '',
    },
    {
      id: 4,
      user: {},
      label: '12 months (elapsed time)',
      value: 'twelveMonths',
      valueFormatted: '12 months (elapsed time)',
      checked: false,
      customStyle: '',
    },
    {
      id: 5,
      user: {},
      label: '1 Year of Service (1,000 hours)',
      value: 'oneYear',
      valueFormatted: '1 Year of Service (1,000 hours)',
      checked: false,
      customStyle: '',
    },
    {
      id: 6,
      user: {},
      label: 'No minimum service requirement',
      value: 'noServiceRequirement',
      valueFormatted: 'No service requirement',
      checked: false,
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Minimum service requirement for eligibility',
  reviewScreenTitle: 'Minimum service requirement for eligibility',
  optionDescription: '',
  description: 'This is the minimum service requirement for deferrals.',
};
