import React from 'react';
import PropTypes from 'prop-types';
import { Question } from '../../../../common/FormWithSteps';

const QacaMaxAutoEscalateQuestions = ({
  questions,
  role,
  handleQuestionChange,
}) => {
  const {
    autoDeferralPercentageStartEmployees,
    hasAutoEscalation,
    maxAutoEscalatePercentage,
  } = questions;
  const autoEscalate =
    hasAutoEscalation.value === hasAutoEscalation.options[0].value;

  return (
    <div className="row">
      <div className="col-md-9 mx-auto">
        <Question
          userRole={role}
          labelClass="fs-form-q fw-bold text-center mb-4"
          type={hasAutoEscalation.type}
          question={hasAutoEscalation}
          handleQuestionChange={handleQuestionChange}
          radioWrapper="srp-form-toggle"
        />
        {autoEscalate && (
          <Question
            role={role}
            type={autoDeferralPercentageStartEmployees.type}
            question={autoDeferralPercentageStartEmployees}
            handleQuestionChange={handleQuestionChange}
          />
        )}
        {autoEscalate && (
          <Question
            role={role}
            type={maxAutoEscalatePercentage.type}
            question={maxAutoEscalatePercentage}
            handleQuestionChange={handleQuestionChange}
          />
        )}
        {!autoEscalate && (
          <Question
            role={role}
            type={autoDeferralPercentageStartEmployees.type}
            question={autoDeferralPercentageStartEmployees}
            handleQuestionChange={handleQuestionChange}
          />
        )}
      </div>
    </div>
  );
};

QacaMaxAutoEscalateQuestions.propTypes = {
  questions: PropTypes.object,
  role: PropTypes.string,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default QacaMaxAutoEscalateQuestions;
