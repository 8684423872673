import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PlanOptionSelections from './PlanOptionSelections';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../../Redux/FormDataRedux';
import { checkValidityOnQuestionArray } from '../../../../common/FormWithSteps';
import { rootRoute } from '../../../../Services/utilities';

const PlanSelections = ({
  questions,
  planOptionQuestions,
  contributionPlanOptionQuestions,
  putActiveStep,
  advisorInfo,
  userRole,
  goToRoleSelection,
  putApplicationPanelInfo,
}) => {
  const location = useLocation();
  const showInvestmentDisclosure =
    questions.investmentLineup.value === 'fundLineup2' ||
    questions.investmentLineup.value === 'fundLineup3';

  const showInvestmentQuestion =
    questions.hasFp.value && questions.investmentLineup.dirty;
  const showInvestmentDefaultEmployer =
    !questions.hasFp.value && userRole === 'employer';
  const defaultInvestmentLineup = {
    id: 9,
    stepName: 'investmentLineup',
    label: 'Wilshire 3(38) Investment Lineup 1',
    value: 'fundLineup1',
    valueFormatted: 'Wilshire 3(38) Investment Lineup 1',
    checked: false,
    customStyle: '',
    heading: 'Wilshire 3(38) Investment Lineup 1',
    sideCardTitle: 'Investment lineup',
    reviewScreenTitle: 'Investment lineup',
    description: '',
  };
  const isEmployerNoAdvisor =
    !checkValidityOnQuestionArray(advisorInfo) && userRole === 'employer';
  const isEditable = questions.hasTpa.value ? userRole === 'tpa' : true;

  const renderContributionMatch = () => {
    if (questions.employerContributionMatch.value === 'yes') {
      return (
        <PlanOptionSelections
          goToRoleSelection={goToRoleSelection}
          question={questions.employerContributionMatch}
          putActiveStep={putActiveStep}
          optionDescription={
            questions.employerContributionMatch.optionDescription
          }
          contributionPlanOptionQuestions={contributionPlanOptionQuestions}
          description={questions.employerContributionMatch.description}
          className="border-0"
          isEditable={isEditable}
          questions={questions}
          userRole={userRole}
        />
      );
    }
    return (
      <PlanOptionSelections
        goToRoleSelection={goToRoleSelection}
        question={questions.employerContributionMatch}
        putActiveStep={putActiveStep}
        optionDescription={
          questions.employerContributionMatch.optionDescription
        }
        description={questions.employerContributionMatch.description}
        className="border-0"
        isEditable={isEditable}
        questions={questions}
        userRole={userRole}
      />
    );
  };

  const investmentDisclosure = () => (
    <div className="col-12">
      <div className="my-3 mb-5">
        <div className="fs-tablelabel lh-125 mb-1 text-muted fs-form-label">
          {questions.investmentLineup.value === 'fundLineup2'
            ? 'Wilshire 3(38) Investment Lineup 2'
            : 'Wilshire 3(38) Investment Lineup 3'}
        </div>
        <p className="text-muted fs-sm">
          Mutual funds are sold by prospectus. Please consider the investment
          objectives, risks, charges and expenses carefully before investing.
          The prospectus and, if available, the summary prospectus, contains
          this and other information. Read the prospectus and, if available, the
          summary prospectus carefully before investing.
        </p>
      </div>
    </div>
  );

  const showQuestion = (key) =>
    planOptionQuestions[key].name !== 'investmentLineup';

  const isInOneDigitalPath = rootRoute(location.pathname) === '/onedigital';

  return (
    <div>
      <div className="mb-3">
        <h3 className="fs-h3 text-purple">Plan selections</h3>
      </div>
      {showInvestmentDefaultEmployer && (
        <PlanOptionSelections
          goToRoleSelection={goToRoleSelection}
          question={defaultInvestmentLineup}
          putActiveStep={putActiveStep}
          optionDescription={defaultInvestmentLineup.optionDescription}
          description={defaultInvestmentLineup.description}
          isEditable={userRole === 'advisor'}
          questions={questions}
          userRole={userRole}
        >
          <button
            type="button"
            className="d-block srp-button srp-button__link fs-sm p-0"
            onClick={() => {
              const info = questions.investmentLineup.options[0];
              putApplicationPanelInfo({
                hasVisibility: true,
                heading: questions.investmentLineup.options[0].valueFormatted,
                additionalInformation: info.content,
              });
            }}
          >
            Investment lineup details
          </button>
        </PlanOptionSelections>
      )}

      {showInvestmentQuestion && (
        <div className="border-bottom mt-5">
          <PlanOptionSelections
            goToRoleSelection={goToRoleSelection}
            question={questions.investmentLineup}
            putActiveStep={putActiveStep}
            optionDescription={questions.investmentLineup.optionDescription}
            description={questions.investmentLineup.description}
            isEditable={userRole === 'advisor' && !isInOneDigitalPath}
            questions={questions}
            userRole={userRole}
          >
            <button
              type="button"
              className="d-block srp-button srp-button__link fs-sm p-0"
              onClick={() => {
                const info = questions.investmentLineup.options.find(
                  (op) => op.value === questions.investmentLineup.value,
                );
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: questions.investmentLineup.valueFormatted,
                  additionalInformation: info.content,
                });
              }}
            >
              Investment lineup details
            </button>
          </PlanOptionSelections>
          {showInvestmentDisclosure && investmentDisclosure()}
          {userRole === 'tpa' && (
            <div className="col-12">
              <div className="my-3 mb-5">
                <div className="fs-tablelabel lh-125 mb-1 text-muted fs-form-label">
                  DISCLAIMER:
                </div>
                <p className="text-muted fs-sm">
                  The financial professional associated with this plan will
                  select from the investment option lineups available based on
                  the investments they are licensed to sell.
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      {isEmployerNoAdvisor && (
        <PlanOptionSelections
          goToRoleSelection={goToRoleSelection}
          question={questions.hasFp}
          putActiveStep={putActiveStep}
          optionDescription={questions.hasFp.optionDescription}
          description={questions.hasFp.description}
          isEditable={isEditable}
          questions={questions}
          userRole={userRole}
        />
      )}

      {Object.keys(planOptionQuestions)
        .filter((question) => planOptionQuestions[question].dirty)
        .map(
          (question) =>
            showQuestion(question) && (
              <PlanOptionSelections
                goToRoleSelection={goToRoleSelection}
                key={planOptionQuestions[question].name}
                question={planOptionQuestions[question]}
                putActiveStep={putActiveStep}
                optionDescription={
                  planOptionQuestions[question].optionDescription
                }
                description={planOptionQuestions[question].description}
                isEditable={isEditable}
                userRole={userRole}
                questions={questions}
              />
            ),
        )}
      {userRole !== 'tpa' &&
        questions.employerContributionMatch.dirty &&
        renderContributionMatch()}
    </div>
  );
};

PlanSelections.propTypes = {
  questions: PropTypes.object.isRequired,
  planOptionQuestions: PropTypes.object.isRequired,
  contributionPlanOptionQuestions: PropTypes.object.isRequired,
  putActiveStep: PropTypes.func.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  userRole: PropTypes.string.isRequired,
  goToRoleSelection: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelections);
