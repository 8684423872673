import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectRole,
  selectPlanURL,
} from '../../../Redux/FormDataRedux';
import ToastRedux from '../../../Redux/ToastRedux';
import createAnotherIcon from '../../../../Images/icon-create-another.svg';
import { SharePlanControls, PlanurlStyles } from './index.styles';

const ShareSavedPlan = ({
  hasHashId,
  planURL,
  setShowSavedPlanBar,
  addToast,
  resetData,
  userRole,
}) => {
  const [copy, setCopy] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setCopy(true);
    }, 3000);
  }, [copy]);

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(planURL);
    setCopy(false);
    addToast('Copied to clipboard.', 'success');
  };

  const canStartNewPlan =
    hasHashId && (userRole === 'advisor' || userRole === 'tpa');
  return (
    <SharePlanControls className="controls d-print-none">
      <div className="container-xlg">
        <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center my-3 position-relative text-sm-left">
          <div
            className={classNames('text-white', {
              'text-center': !canStartNewPlan,
              'm-auto': !canStartNewPlan,
            })}
          >
            {canStartNewPlan ? (
              <strong className="d-block">
                <a
                  href="https://secure.simplyretirement.com/login"
                  className="text-white"
                >
                  Sign into your dashboard
                </a>{' '}
                or copy your saved plan URL:
              </strong>
            ) : (
              <strong className="d-block">Here is your saved plan URL:</strong>
            )}
            <PlanurlStyles className="fs-h5 text-white overflow-planurl d-inline">
              {planURL}
            </PlanurlStyles>{' '}
            <button
              type="button"
              className="btn btn-link text-white align-top p-0 fs-disclaimer ml-3"
              onClick={copyToClipBoard}
            >
              {copy ? 'Copy' : 'Copied'} URL to your clipboard
            </button>{' '}
          </div>
          <div className="text-sm-right me-sm-5">
            {canStartNewPlan && (
              <button
                type="button"
                className="srp-button srp-button__outline__white mx-2 mt-3 mt-sm-0"
                onClick={() => {
                  resetData();
                  sessionStorage.clear();
                }}
              >
                <img src={createAnotherIcon} alt="" className="icon" />
                Create another plan
              </button>
            )}
          </div>
          <button
            aria-label="Close"
            type="button"
            className="btn btn-link text-white"
            style={{
              position: 'absolute',
              top: '-15px',
              right: '0',
              fontSize: '1.5rem',
            }}
            onClick={() => {
              setShowSavedPlanBar(false);
            }}
          >
            <i className="fa-solid fa-xmark" aria-hidden />
          </button>
        </div>
      </div>
    </SharePlanControls>
  );
};

ShareSavedPlan.propTypes = {
  planURL: PropTypes.string,
  hasHashId: PropTypes.bool.isRequired,
  setShowSavedPlanBar: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  hasHashId: !!store.formData.hashId,
  userRole: selectRole(store),
  planURL: selectPlanURL(store),
});

const mapDispatchToProps = (dispatch) => ({
  addToast: (text, toastType) => {
    dispatch(ToastRedux.addToast(text, toastType));
  },
  resetData: () => {
    dispatch(FormDataRedux.resetData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareSavedPlan);
