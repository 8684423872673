import states from '../../../Services/states.json';

export const ADVISOR_CREDENTIAL_INITIAL_STATE = {
  hierarchies: {
    endpoint: 'sct/fp/relationship/hierarchies',
  },
  status: '',
  eid: {
    endpoint: 'sca/distribution-partner/identity/npn',
  },
};

export const ADVISOR_CREDENTIAL_VERIFICATION_INITIAL_STATE = {
  insuranceAgent: '',
  insuranceAgentNpn: '',
  insuranceAgentCompType: '',
  brokerType: '',
  status: '',
  brokerEid: '',
  verificationStatus: '',
  crd: {
    value: '',
    error: '',
  },
  endpoint: 'sca/distribution-partner/identity/eId',
};

export const ADVISOR_CV_DATA = {
  state: '',
  date: '',
  mutualFund: '',
  levelized: '',
  distributionPartners: [],
  brokerType: '',
  principalError: '',
  cvResponse: {
    status: '',
    message: '',
  },
};

export const ADVISOR_INITIAL_STATE = {
  advisorCredential: { ...ADVISOR_CREDENTIAL_INITIAL_STATE },
  advisorCredentialVerification: {
    ...ADVISOR_CREDENTIAL_VERIFICATION_INITIAL_STATE,
  },
  advisorCvData: {
    ...ADVISOR_CV_DATA,
  },
  advisorIndex: 0,
  questions: {
    advisorNpn: {
      type: 'number_field',
      name: 'advisorNpn',
      stepName: 'advisorInformation',
      user: {},
      label: 'Your national producer number (NPN)',
      required: true,
      dirty: false,
      value: '',
      hasError: true,
    },
    advisorEmail: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorEmail',
      user: {
        employer: { label: 'Your email' },
        advisor: { label: 'Your email' },
        tpa: { label: 'Financial Professional email' },
      },
      required: true,
      dirty: false,
      value: '',
      hasError: false,
    },
    advisorFirstName: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorFirstName',
      user: {
        employer: { label: 'Your first name' },
        advisor: { label: 'Your first name' },
        tpa: { label: 'Financial Professional first name' },
      },
      required: true,
      dirty: false,
      value: '',
      hasError: false,
    },
    advisorLastName: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorLastName',
      user: {
        employer: { label: 'Your last name' },
        advisor: { label: 'Your last name' },
        tpa: { label: 'Financial Professional last name' },
      },
      required: true,
      dirty: false,
      value: '',
      hasError: false,
    },
    advisorStateCode: {
      stepName: 'advisorInformation',
      type: 'dropdown',
      name: 'advisorStateCode',
      label: 'Your state of residence',
      options: states,
      required: true,
      dirty: false,
      value: '',
      hasError: false,
    },
    advisorZipCode: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorZipCode',
      user: {},
      label: 'Your zip code',
      required: true,
      dirty: false,
      value: '',
      hasError: false,
    },
    advisorPhone: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorPhone',
      user: {},
      label: 'Your phone number',
      required: true,
      dirty: false,
      value: '',
      hasError: false,
    },
    firmName: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'firmName',
      user: {},
      label: 'Your broker-dealer',
      required: true,
      dirty: false,
      value: {
        id: 'noFirm',
        value: 'no_firm',
        selected: false,
        enterpriseId: '',
      },
      hasError: false,
      valueFormatted: 'no_firm',
    },
    floridaLicenseNumber: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'floridaLicenseNumber',
      user: {},
      label: 'Florida license number',
      required: true,
      dirty: false,
      value: '',
      valueFormatted: '',
      hasError: false,
    },
    compensation: {
      stepName: 'compensation',
      type: 'slider',
      name: 'compensation',
      user: {},
      label: 'Compensation amount',
      thousandSeperator: false,
      min: 0,
      max: 100,
      defaultValue: 100,
      valueFormatted: '100%',
      step: 1,
      value: 100,
      required: true,
      dirty: false,
      hasError: false,
    },
    advisorRepNumber: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorRepNumber',
      user: {},
      label: 'Representative number',
      required: true,
      dirty: true,
      value: '',
      hasError: false,
    },
  },
};

export default ADVISOR_INITIAL_STATE;
