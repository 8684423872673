import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NextStepBtn } from '../../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../../common/FormWithSteps/Utilities';
import FormDataRedux, {
  isFormValid,
  selectPrimaryAdvisor,
  selectAdvisors,
} from '../../../../Redux/FormDataRedux';
import Header from './Header';

const whoIsPrimaryAdvisor = {
  id: 'whoIsPrimaryAdvisor',
  type: 'dropdown',
  name: 'whoIsPrimaryAdvisor',
  stepName: 'employerFirmInfo',
  user: {},
  label: 'Advisor name',
  placeholder: 'Select a primary advisor',
  content:
    'Please select your OneDigital advisor’s name from the dropdown list. If you do not see their name on the list, please reach out to salesdeskleads@onedigital.com or select “Unsure”.',
  options: [],
  required: true,
  dirty: false,
  value: '',
  hasError: false,
};

export const AdvisorFirmInfo = ({
  formData,
  active = false,
  destination = () => {},
  previousStep = '',
  updateActiveStep = () => {},
  googleData = {},
  putApplicationPanelInfo,
  setAsPrimary,
  primaryAdvisor,
  advisors,
  putOneDigitalAdvisor,
  updateAdvisor,
  setPlanOriginator,
  questions,
}) => {
  const [question, setQuestion] = React.useState(whoIsPrimaryAdvisor);
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    const advisorOptions = advisors.map((advisor, index) => ({
      value: index,
      label: `${advisor.questions.advisorFirstName.value} ${advisor.questions.advisorLastName.value}`,
    }));
    setOptions(advisorOptions);
  }, [advisors, primaryAdvisor, setOptions]);

  useEffect(() => {
    pushGoogleDataLayer(googleData);
    putOneDigitalAdvisor();
  }, []);

  useEffect(() => {
    if (
      formData.planOriginator === '' ||
      formData.planOriginator.email !== questions.sponsorEmail.value
    ) {
      const planOriginator = {
        name: `${primaryAdvisor.questions.advisorFirstName.value} ${primaryAdvisor.questions.advisorLastName.value}`,
        email: primaryAdvisor.questions.advisorEmail.value,
        role: 'advisor',
      };
      setPlanOriginator(planOriginator);
    }
  }, [primaryAdvisor]);

  return (
    <div className="container-fluid--sitewidth h-100">
      <Header step={2} />
      <div className="row">
        <div className="col-md-6 mx-auto">
          <p className="text-center">
            Please select your name from the dropdown list. If you do not see
            your name on the list, please reach out to
            salesdeskleads@onedigital.com
          </p>
          <div className="form-group">
            <label className="fs-form-label fw-bold" htmlFor={question.name}>
              {question.label}
            </label>
            <select
              id={question.name}
              name={question.name}
              defaultValue="select"
              className="custom-select srp-form-input form-control form-select"
              onChange={(event) => {
                setQuestion((prevState) => ({
                  ...prevState,
                  dirty: true,
                }));
                setAsPrimary(event.target.value);
                advisors.forEach((_advisor, index) => {
                  updateAdvisor(
                    {
                      name: 'compensation',
                      value: index === 0 ? 100 : 0,
                      valueFormatted: index === 0 ? '100' : '0',
                    },
                    index,
                  );
                });
              }}
            >
              <option value="select" disabled>
                Select an advisor...
              </option>

              {options.map((option) => {
                if (options.length < 0)
                  return (
                    <option value="" disabled selected>
                      Loading options...
                    </option>
                  );
                return (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </select>
            {!!(question.dirty && question.hasError) && (
              <p className="text-danger text-center">Question is required</p>
            )}
          </div>
          <p className="text-center">
            <button
              type="button"
              className="srp-button__link"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'Select your name from the dropdown',
                  additionalInformation: `
                    <p>Once you’ve selected your name from the dropdown, your information will be automatically populated within the remaining proposal process.</p>
                    <p>If you do not see your name, please reach out to <a href="mailto:salesdeskleads@onedigital.com">salesdeskleads@onedigital.com</a>.</p>`,
                })
              }
            >
              More info
            </button>
          </p>

          <div className="fws__control">
            <NextStepBtn
              disabled={
                question.required
                  ? !(question.dirty && !question.hasError)
                  : false
              }
              onClick={() => updateActiveStep(destination(), 'forward')}
            />
          </div>
        </div>
      </div>

      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

AdvisorFirmInfo.propTypes = {
  formData: PropTypes.object.isRequired,
  active: PropTypes.bool,
  destination: PropTypes.any,
  previousStep: PropTypes.string,
  updateActiveStep: PropTypes.func,
  googleData: PropTypes.object,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  setAsPrimary: PropTypes.func.isRequired,
  primaryAdvisor: PropTypes.object.isRequired,
  advisors: PropTypes.array.isRequired,
  putOneDigitalAdvisor: PropTypes.func.isRequired,
  updateAdvisor: PropTypes.func.isRequired,
  setPlanOriginator: PropTypes.func.isRequired,
  questions: PropTypes.object,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  isValid: isFormValid(store),
  primaryAdvisor: selectPrimaryAdvisor(store),
  advisors: selectAdvisors(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  setAsPrimary: (advisorIndex) => {
    dispatch(FormDataRedux.setAsPrimary(advisorIndex));
  },
  putOneDigitalAdvisor: () => {
    dispatch(FormDataRedux.putOneDigitalAdvisor());
  },
  updateAdvisor: (obj, index) => {
    dispatch(FormDataRedux.updateAdvisor(obj, index));
  },
  setPlanOriginator: (planOriginator) => {
    dispatch(FormDataRedux.setPlanOriginator(planOriginator));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorFirmInfo);
