import React from 'react';
import { IconBlueGraphO } from '../../../../ImagePaths';
import TableFluidLarge from '../../../../common/TableFluidLarge';

const TableSection = () => (
  <section
    id="401k-table"
    className="table-section bg-pattern section section_py-5 pt-0"
    style={{
      background:
        'linear-gradient(180deg, rgba(255,255,255,1) 5%, rgba(230,231,232,1) 100%)',
    }}
  >
    <div className="container-lg bg-white">
      <div className="row">
        <div className="col-12 col-lg-10 mx-auto">
          <div className="row justify-content-center py-5">
            <img src={IconBlueGraphO} alt="" width={90} height={90} />
          </div>
          <div className="row justify-content-center">
            <p className="fs-h3 fw-bold text-center col-xl-6">
              How does this 401(k) plan compare to other types of retirement
              plans?
            </p>
          </div>
          <div className="row justify-content-center pb-5">
            <p className="fs-p text-center col-lg-10 col-xl-8">
              When it comes to workplace retirement plans, we know a 401(k) plan
              isn’t your clients’ only option. Feel free to use this high-level
              comparison to walk them through their choices as you discuss what
              type of plan might best fit their needs.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      className="container-lg bg-white"
      style={{
        borderBottomLeftRadius: '50px',
        borderBottomRightRadius: '50px',
      }}
    >
      <div className="row">
        <div className="col-lg-10 mx-auto px-md-3 px-0">
          <TableFluidLarge />
          <div className="row justify-content-between align-items-center">
            <div className="col-md-8 text-md-left text-center">
              <p className="fs-disclaimer">
                <sup>
                  <small>1</small>
                </sup>
                If client is working with a TPA, eligibility, automatic
                enrollment and vesting options may vary.
              </p>
              <p>
                Please contact our financial professional support team at{' '}
                <a href="tel:+18009523343" className="fake-link">
                  800-952-3343
                </a>{' '}
                or{' '}
                <a href="mailto:retirementsalessupportteam@principal.com">
                  retirementsalessupportteam@principal.com
                </a>{' '}
                to discuss other retirement plan options.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

TableSection.propTypes = {};

export default TableSection;
