import { combineReducers } from 'redux';

// Reducers
import { formDataReducer } from './Redux/FormDataRedux';
import { toastReducer } from './Redux/ToastRedux';
import { modalReducer } from './Redux/ModalRedux';
import { signUpModalReducer } from './Redux/SignUpModalRedux';

export { INITIAL_STATE } from './Redux/FormDataRedux/InitialState';
export { USER_SELECT_INITIAL_STATE } from './Redux/FormDataRedux/InitialState/UserSelectInitialState';
export { PLAN_DESIGN_INITIAL_STATE } from './Redux/FormDataRedux/InitialState/PlanDesignInitialState';
export { APPLICATION_INFO_PANEL_INITIAL_STATE } from './Redux/FormDataRedux/InitialState/applicationInfoPanelInitialState';
export { ADVISOR_INITIAL_STATE } from './Redux/FormDataRedux/InitialState/AdvisorInitialState';
export { TPA_INITIAL_STATE } from './Redux/FormDataRedux/InitialState/TPAInitialState';

// Combine Reducers
const reducers = combineReducers({
  formData: formDataReducer,
  toastData: toastReducer,
  modalData: modalReducer,
  signUpModal: signUpModalReducer,
});

export default reducers;
