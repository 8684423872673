import React from 'react';
import { Link } from 'react-router-dom';
import { PurpleMask } from './index.styles';
import { ScrollInToView } from '../../../../common/VisibilitySensor';
import {
  IconCheckCircleO,
  iconUserO,
  iconListToGoO,
  iconUserPageO,
} from '../../../../ImagePaths';

const CallToActionSection = () => (
  <section className="pb-5 position-relative">
    <PurpleMask />
    <div className="container">
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <div
            className="bg-pattern index-0"
            style={{
              borderRadius: '50px',
              backgroundColor: '#ebeced',
              overflow: 'hidden',
            }}
          >
            <div className="row mx-0  my-5">
              <div className="col-xl-8 col-lg-10 mx-auto">
                <h3 className="fs-h4 text-center">
                  Ready to create a plan proposal for your client?
                </h3>
                <h3 className="fs-h4 text-center">
                  Here&apos;s what will happen next:
                </h3>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-lg-11 mx-auto">
                <div className="row">
                  <div className="col-6 col-md-3">
                    <img
                      className="d-block mx-auto mb-3"
                      src={IconCheckCircleO}
                      alt="Check Mark In Circle Icon"
                      width={70}
                      height={70}
                    />
                    <p className="fs-p mb-5 text-center lh-125">
                      <strong>
                        Confirm your credentials are in our system.
                      </strong>
                    </p>
                  </div>
                  <div className="col-6 col-md-3">
                    <img
                      className="d-block mx-auto mb-3"
                      src={iconUserO}
                      alt="User Icon"
                      width={75}
                      height={70}
                    />
                    <p className="fs-p mb-5 text-center lh-125">
                      <strong>
                        Provide the required information about your client.
                      </strong>
                    </p>
                  </div>
                  <div className="col-6 col-md-3">
                    <img
                      className="d-block mx-auto mb-3"
                      src={iconListToGoO}
                      alt="List Icon"
                      width={75}
                      height={70}
                    />
                    <p className="fs-p mb-5 text-center lh-125">
                      <strong>
                        Review the proposal and send it to your client to
                        complete the purchase.
                      </strong>
                    </p>
                  </div>
                  <div className="col-6 col-md-3">
                    <img
                      className="d-block mx-auto mb-3"
                      src={iconUserPageO}
                      alt="User on Webpage Icon"
                      width={75}
                      height={70}
                    />
                    <p className="fs-p mb-5 text-center lh-125">
                      <strong>
                        After purchase, your client can create a login and
                        finish setup.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <ScrollInToView
                renderItem={(inView) => (
                  <Link
                    style={{
                      animationDelay: '0.2s',
                      animationDuration: '0.4s',
                      marginBottom: '60px',
                    }}
                    className={
                      inView
                        ? 'srp-button srp-button__primary px-5 mb-5 fadeInUp animated'
                        : 'srp-button srp-button__primary px-5 mb-5 hidden'
                    }
                    to="/plans"
                  >
                    Create a proposal.
                  </Link>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default CallToActionSection;
