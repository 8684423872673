export const checkRegisterTpaResponseStatus = (status) => {
  switch (status) {
    case 200:
    case 201:
    case 204:
    case 400:
      return true;
    default:
      return false;
  }
};
