import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PrevStepBtn } from '../../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../../common/FormWithSteps/Utilities';

export const FirmBrokerNotAllowed = ({
  active,
  previousStep,
  updateActiveStep,
  googleData,
}) => {
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);
  return (
    <div className="container-fluid--sitewidth h-100">
      <div className="row h-100 flex-column justify-content-between">
        <div className="col-md-8 m-auto">
          <div className="text-center">
            <h2 className="fs-h2 fw-bold text-center mt-5 pt-5">
              Based on your broker-dealer&rsquo;s current guidelines, you aren’t
              able to sell Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>
              .
            </h2>
            <p className="fs-h6">
              Please contact our financial professionals support team at
              800-952-3343 or{' '}
              <a
                className="fs-h6"
                href="mailto:retirementsalessupportteam@principal.com"
              >
                retirementsalessupportteam@principal.com
              </a>{' '}
              to discuss other retirement plan options.
            </p>
          </div>
        </div>
        <p className="fws__control">
          <Link
            className="srp-button srp-button__sm-large srp-button__primary my-5"
            to="/business-owners"
          >
            Close
          </Link>
        </p>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

FirmBrokerNotAllowed.propTypes = {
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  updateActiveStep: PropTypes.func,
  googleData: PropTypes.object,
};

export default FirmBrokerNotAllowed;
