import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Question } from '../../../../../common/FormWithSteps';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../../../Redux/FormDataRedux';

const Form = ({
  role,
  questions,
  handleQuestionChange,
  putApplicationPanelInfo,
}) => {
  const {
    tpaDistributionFee,
    tpaHardshipFee,
    tpaLoanMaintenanceFee,
    tpaLoanOriginationFee,
    tpaLoanReamortizationFee,
    tpaRmdFee,
    tpaQdroFee,
  } = questions;

  return (
    <fieldset>
      <div className="mb-5">
        <p className="text-center">
          Participant transaction fees will be applied to a participant’s
          account when processing their request. If your firm does not require a
          fee associated for one or more of any of the fields below, please
          enter $0.
        </p>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <Question
            role={role}
            type={tpaDistributionFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaDistributionFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {tpaDistributionFee.hasError && <small>Question is required</small>}
          </Question>
        </div>
        <div className="col-lg-6">
          <Question
            role={role}
            type={tpaHardshipFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaHardshipFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {tpaHardshipFee.hasError && <small>Question is required</small>}
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <Question
            role={role}
            type={tpaLoanMaintenanceFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaLoanMaintenanceFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {tpaLoanMaintenanceFee.hasError && (
              <small>Question is required</small>
            )}
          </Question>
        </div>
        <div className="col-lg-4">
          <Question
            role={role}
            type={tpaLoanOriginationFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaLoanOriginationFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {tpaLoanOriginationFee.hasError && (
              <small>Question is required</small>
            )}
          </Question>
        </div>
        <div className="col-lg-4">
          <Question
            role={role}
            type={tpaLoanReamortizationFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaLoanReamortizationFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {tpaLoanReamortizationFee.hasError && (
              <small>Question is required</small>
            )}
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Question
            role={role}
            type={tpaRmdFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaRmdFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {tpaRmdFee.hasError && <small>Question is required</small>}
          </Question>
        </div>
        <div className="col-lg-6">
          <Question
            role={role}
            type={tpaQdroFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaQdroFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {tpaQdroFee.hasError && <small>Question is required</small>}
          </Question>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mx-auto">
          <p className="text-center">
            <button
              type="button"
              className="srp-button__link"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'Participant transaction fees',
                  additionalInformation: `Participant transaction fees will be applied across all participants in the plan if they have this type of transaction applied to their account. For new business, TPA fees deducted from plan assets will be included in Ubiquity’s fee disclosure. This includes flat fee or basis point TPA compensation taken from plan assets, as well any TPA transaction fees.`,
                })
              }
            >
              Compensation details
            </button>
          </p>
        </div>
      </div>
    </fieldset>
  );
};

Form.propTypes = {
  role: PropTypes.string,
  questions: PropTypes.object,
  handleQuestionChange: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
