import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectRole,
  selectQuestion,
  selectIsFirmBroker,
  selectIsFirmBrokerSplitComp,
  selectPrimaryAdvisor,
  selectAdvisors,
} from '../../../Redux/FormDataRedux';
import Question from '../../../common/FormWithSteps/Question';
import { checkForErrors } from '../../../common/FormWithSteps/Utilities';
import { billingPrefState } from './billingPrefState';
import { USER_SELECT_INITIAL_STATE } from '../../../Redux/FormDataRedux/InitialState';
import { useBrokerDealers } from '../../../Services/useBrokerDealers';

const AdvisorBrokerDealerSelect = ({
  role,
  advisorFirmBroker,
  isFirmBrokerSplitComp = false,
  isFirmBrokerSecurian,
  updateQuestion,
  updateAdvisor,
  primaryAdvisor,
  putBrokerEid,
  putCrd,
  removeExtraAdvisors,
}) => {
  const {
    advisorCredentialVerification: { brokerType },
    questions: { firmName },
  } = primaryAdvisor;
  const setBillingPreferences = (compensationType) => {
    billingPrefState(compensationType).forEach((state) => {
      updateQuestion(state);
    });
  };

  const [{ brokerOptions, isLoading, isError }] = useBrokerDealers(brokerType);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    const { investmentLineup } = USER_SELECT_INITIAL_STATE;
    updateQuestion({ name: 'investmentLineup', ...investmentLineup });
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
    updateAdvisor(
      {
        name: firmName.name,
        value: updateValue,
        valueFormatted: updateValue.label,
        dirty: true,
      },
      0,
    );
    putCrd(
      {
        status: '',
        verificationStatus: '',
        crd: updateValue.crd,
      },
      0,
    );
    putBrokerEid(
      {
        eid: updateValue.brokerEid,
        verificationStatus: '',
      },
      0,
    );
    setBillingPreferences(updateValue.compensation);
  };

  useEffect(() => {
    if (!isFirmBrokerSplitComp) {
      removeExtraAdvisors();
    }
  }, [advisorFirmBroker]);
  return (
    <div>
      <Question
        role={role}
        type={advisorFirmBroker.type}
        labelClass="fs-form-label fw-bold text-center"
        inputClass="srp-form-input form-control"
        question={{
          ...advisorFirmBroker,
          disabled: isLoading || isError,
          user: {
            advisor: {
              label:
                brokerType === 'brokerDealer'
                  ? 'Your broker-dealer'
                  : 'Your RIA ',
            },
          },
          options: brokerOptions,
        }}
        handleQuestionChange={handleQuestionChange}
      >
        {advisorFirmBroker.hasError && <small>Question is required</small>}
      </Question>
      {isFirmBrokerSplitComp && (
        <p className="text-muted">
          You can add more financial professionals on the next screen.
        </p>
      )}

      {isFirmBrokerSecurian && (
        <p className="text-muted">
          Reminder: Please complete required SFN broker-dealer paperwork and
          submit using communicated SFN procedures
        </p>
      )}
    </div>
  );
};

AdvisorBrokerDealerSelect.propTypes = {
  role: PropTypes.string.isRequired,
  advisorFirmBroker: PropTypes.object.isRequired,
  isFirmBrokerSplitComp: PropTypes.bool,
  isFirmBrokerSecurian: PropTypes.bool.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  updateAdvisor: PropTypes.func.isRequired,
  putBrokerEid: PropTypes.func.isRequired,
  putCrd: PropTypes.func.isRequired,
  primaryAdvisor: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired,
  removeExtraAdvisors: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  role: selectRole(store),
  advisorFirmBroker: selectQuestion(store, 'advisorFirmBroker'),
  isFirmBrokerSplitComp: selectIsFirmBrokerSplitComp(store),
  isFirmBrokerSecurian: selectIsFirmBroker(
    store,
    'SECURIAN FINANCIAL SERVICES INC',
  ),
  primaryAdvisor: selectPrimaryAdvisor(store),
  advisors: selectAdvisors(store),
  investmentLineup: selectQuestion(store, 'investmentLineup'),
  questions: store.formData.planDesign.questions,
});

const mapDispatchToProps = (dispatch) => ({
  removeExtraAdvisors: () => {
    dispatch(FormDataRedux.removeExtraAdvisors());
  },
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  updateAdvisor: (obj, index) => {
    dispatch(FormDataRedux.updateAdvisor(obj, index));
  },
  putBrokerEid: (obj, index) => {
    dispatch(FormDataRedux.putBrokerEid(obj, index));
  },
  putCrd: (obj, index) => {
    dispatch(FormDataRedux.putCrd(obj, index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvisorBrokerDealerSelect);
