import React from 'react';
import PropTypes from 'prop-types';
import { Question } from '../FormWithSteps';

const ColFinancialProComp = ({ data, handleQuestionChange }) => {
  const showAdvisorFee =
    data.advisorType.value === data.advisorType.options[0].value;

  const showAdvisorPoints =
    data.advisorType.value === data.advisorType.options[1].value;

  return (
    <div className="offset-md-1 col-md-5 mb-5">
      <h4 className="min-h-50">What are your fees? (optional)</h4>
      <p>
        Financial professionals can be paid outside the plan. If you choose to
        receive compensation through the retirement plan, you must choose either
        basis points or a flat fee.
      </p>

      <Question
        type={data.advisorType.type}
        question={data.advisorType}
        handleQuestionChange={handleQuestionChange}
        labelClass="fs-disclaimer-print text-left"
        radioWrapper="srp-form-toggle text-left"
      />

      {showAdvisorPoints && (
        <Question
          type={data.advisorPoints.type}
          labelClass="fs-disclaimer-print text-left"
          radioWrapper="srp-form-toggle text-left"
          question={{
            ...data.advisorPoints,
            label: `${data.advisorPoints.label} ${
              data.advisorPoints.required ? '(Required)' : '(Optional)'
            }`,
          }}
          handleQuestionChange={handleQuestionChange}
        >
          {data.advisorPoints.hasError && data.advisorPoints.required && (
            <small>Question is required</small>
          )}
        </Question>
      )}
      {showAdvisorFee && (
        <Question
          type={data.advisorFee.type}
          thousandSeparator
          inputClass="srp-form-input form-control"
          question={{
            ...data.advisorFee,
            label: `${data.advisorFee.label} ${
              data.advisorFee.required ? '(Required)' : '(Optional)'
            }`,
          }}
          handleQuestionChange={handleQuestionChange}
        >
          {data.advisorFee.hasError && data.advisorFee.required && (
            <small>Question is required</small>
          )}
        </Question>
      )}
    </div>
  );
};

ColFinancialProComp.propTypes = {
  data: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default ColFinancialProComp;
