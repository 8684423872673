import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, { isFormValid } from '../../../Redux/FormDataRedux';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../common/FormWithSteps/Utilities';
import {
  Question,
  StepControls,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../common/FormWithSteps';

const SafeHarborQuestions = ({
  active = false,
  destination = () => {},
  previousStep = '',
  questions = {},
  updateActiveStep = () => {},
  updateQuestion,
  role = '',
  googleData = {},
  isValid = false,
  resetUnusedSafeHarbor,
  putApplicationPanelInfo,
  ...stepProps
}) => {
  const primaryQuestion = questions[stepProps.questionId];
  const [validateQuestions, setValidateQuestions] = useState([]);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  useEffect(() => {
    resetUnusedSafeHarbor(primaryQuestion.value);
  }, [primaryQuestion.value]);

  useEffect(() => {
    setValidateQuestions([primaryQuestion]);
  }, [primaryQuestion]);

  return (
    <div className="container-fluid justify-content-center">
      <div className="row h-100">
        <div className="col-md-10 col-xl-8 d-flex flex-column mx-auto my-auto">
          <Question
            role={role}
            labelClass="fs-form-q fw-bold text-center mb-4"
            radioWrapper="srp-form-toggle"
            type={primaryQuestion.type}
            question={primaryQuestion}
            handleQuestionChange={handleQuestionChange}
          />
          {(questions[stepProps.questionId].user[role]?.heading ||
            questions[stepProps.questionId].user.employer.heading) && (
            <p className="text-center mb-lg-auto">
              <button
                type="button"
                className="srp-button__link"
                onClick={() => {
                  const heading =
                    questions[stepProps.questionId].user[role]?.heading ||
                    questions[stepProps.questionId].user.employer.heading;
                  const content =
                    questions[stepProps.questionId].user[role]?.content ||
                    questions[stepProps.questionId].user.employer.content;

                  putApplicationPanelInfo({
                    hasVisibility: true,
                    heading,
                    additionalInformation: content,
                  });
                }}
              >
                Need clarification?
              </button>
            </p>
          )}
          <div className="form-group fws__control">
            <StepControls
              formInReview={stepProps.formInReview}
              disableReviewBtn={!isValid}
              disabledNextBtn={!checkValidityOnQuestionArray(validateQuestions)}
              goToReviewPlan={stepProps.goToReviewPlan}
              clickNextBtn={() => {
                updateActiveStep(destination(primaryQuestion.value), 'forward');
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

SafeHarborQuestions.propTypes = {
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  role: PropTypes.string,
  updateQuestion: PropTypes.func.isRequired,
  googleData: PropTypes.object,
  resetUnusedSafeHarbor: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  isValid: isFormValid(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  resetUnusedSafeHarbor: (value) => {
    dispatch(FormDataRedux.resetUnusedSafeHarbor(value));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SafeHarborQuestions);
