export const USER_LIST_LINKS = {
  default: [
    {
      label: 'Business owners',
      to: '/business-owners',
      excludeRoutes: ['/psivet'],
    },
    {
      label: 'Financial professionals',
      to: '/financial-professionals',
      excludeRoutes: ['/psivet'],
    },
    {
      label: 'Third party administrators',
      to: '/third-party-administrator',
      excludeRoutes: ['/psivet'],
    },
    {
      label: '844-804-0604',
      type: 'tel',
      href: 'tel:+18448040604',
      classes: 'd-none d-lg-inline-block',
    },
    {
      label: 'Login',
      type: 'external',
      hrefKey: 'paradigmHost', // Using the key to construct the href dynamically in the component.
      hrefSuffix: '/login', // This suffix will be appended to the value from props.
    },
  ],
  onedigital: [
    {
      label: '844-804-0604',
      type: 'tel',
      href: 'tel:+18448040604',
      classes: 'd-none d-lg-inline-block',
    },
    {
      label: 'Login',
      type: 'external',
      hrefKey: 'paradigmHost', // Using the key to construct the href dynamically in the component.
      hrefSuffix: '/login', // This suffix will be appended to the value from props.
    },
  ],
};

export default USER_LIST_LINKS;
