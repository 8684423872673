import apisauce from 'apisauce';
import * as Sentry from '@sentry/react';

const setBaseUrl = () => {
  switch (process.env.RAILS_ENV) {
    case 'production':
      return 'https://www.simplyretirement.com';
    case 'staging':
      return 'https://simplyretirement.devface.com';
    case 'development':
      return 'http://localhost:5000';
    default:
      return 'http://localhost:5000';
  }
};

const create = (baseURL = setBaseUrl()) => {
  const api = apisauce.create({
    baseURL,
    timeout: 15000,
  });
  api.addResponseTransform((response) => {
    switch (response.status) {
      case 200:
        response.data = {
          ...response.data,
          status: 'success',
        };
        break;
      default:
        response.data = {
          ...response.data,
          status: 'error',
        };
    }
  });

  const paradigmApi = apisauce.create({
    baseURL,
    timeout: 15000,
  });
  paradigmApi.addResponseTransform((response) => {
    let sentryErrorMessage = '';
    if (response.data?.paradigmStatus !== undefined) {
      sentryErrorMessage = `ERROR with field: ${
        response.data.paradigmStatus
      } and type ${response.data.type}. Error Message: ${JSON.parse(
        JSON.stringify(response.data.data),
      )}`;
    }

    switch (response.status) {
      case 200:
      case 201:
        response.data = {
          ...response.data,
          status: 'success',
        };
        break;

      // 452 Status Code Corresponds to Paradigm Advisor Errors
      case 452:
        response.data = {
          ...response.data,
          status: 'paradigm advisor error',
          paradigmStatus: response.data.paradigmStatus,
        };
        Sentry.captureException(new Error(sentryErrorMessage), {
          tags: {
            paradigmApi: 'Paradigm advisor registration failure',
          },
        });
        break;

      // 453 Status Code Corresponds to Paradigm Broker Dealer Errors
      case 453:
        response.data = {
          ...response.data,
          status: 'broker dealer error',
        };
        Sentry.captureException(new Error(sentryErrorMessage), {
          tags: {
            paradigmApi: 'Paradigm broker dealer registration failure',
          },
        });
        break;

      // 454 Status Code Corresponds to Paradigm Plan Purchase Errors
      case 454:
        response.data = {
          ...response.data,
          status: 'plan purchase error',
        };
        Sentry.captureException(new Error(sentryErrorMessage), {
          tags: {
            paradigmApi: 'Paradigm plan purchase  failure',
          },
        });
        break;
      default:
        response.data = {
          ...response.data,
          status: 'error',
        };
        Sentry.captureException(new Error(sentryErrorMessage), {
          tags: {
            paradigmApi: 'paradigm purchase',
          },
        });
    }
  });

  const advisorApi = apisauce.create({
    baseURL,
    timeout: 40000,
  });
  advisorApi.addResponseTransform(
    (response) => {
      switch (response.status) {
        case 200:
          response.data = {
            ...response.data,
            status: 'success',
            code: response.status,
          };
          break;
        case 404:
          response.data = {
            ...response.data,
            status: 'not-found',
            code: response.status,
          };
          break;
        case 401:
          response.data = {
            ...response.data,
            status: 'auth-error',
            code: response.status,
          };
          break;
        default:
          response.data = {
            ...response.data,
            status: 'error',
            code: response.status,
          };
      }
    },
    (error) => {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-console
        console.log('need a new token', 'call oauthApiPrincipal again');
      }
    },
  );

  const sharePlan = (obj) =>
    api.post('/plans/share', obj).then((response) => response);
  const sendCreateEmail = (obj) =>
    api.post('/plans/send_create_email', obj).then((response) => response);
  const sharePlanSmb = (obj) =>
    api.post('/plans/smb_share', obj).then((response) => response);
  const sharePlanFp = (obj) =>
    api.post('/plans/fp_share', obj).then((response) => response);
  const sharePlanOnedigitalFp = (obj) =>
    api.post('/plans/onedigital_fp_share', obj).then((response) => response);
  const sharePlanTpa = (obj) =>
    api.post('/plans/tpa_share', obj).then((response) => response);
  const shareUpdatePlanSmb = (obj) =>
    api.post('/plans/smb_update', obj).then((response) => response);
  const shareUpdatePlanFp = (obj) =>
    api.post('/plans/fp_update', obj).then((response) => response);
  const shareUpdatePlanTpa = (obj) =>
    api.post('/plans/tpa_update', obj).then((response) => response);
  const savePlan = (obj) =>
    api.post('/plans/save', obj).then((response) => response.data);
  const paradigmPurchase = (obj) => paradigmApi.post('/plans/submit', obj);
  const createSalesforceProposal = (obj) => {
    const proposal = obj;
    return api.post('/salesforce-create', proposal);
  };
  const createSalesforceQuickQuoteProposal = (obj) => {
    const proposal = obj;
    return api.post('/salesforce-create-quick-quote', proposal);
  };
  const authenticateNpn = (obj) => advisorApi.post('/authenticate-npn', obj);
  const relationships = (obj) => advisorApi.post('/relationships', obj);
  const advisorCV = (obj) => advisorApi.post('/advisor-cv', obj);
  const registerTpa = (obj) =>
    process.env.NODE_ENV === 'test'
      ? { status: 200 }
      : paradigmApi.post('/registerParadigmTpa', obj);
  const preview = (obj) => api.get('/preview', obj);
  const cvFail = (obj) => api.post('/cv-fail', obj);
  const getCrd = (obj) => advisorApi.post('/get-crd', obj);
  const restrictedStateCvFail = (obj) =>
    api.post('/restricted-state-cv-fail', obj);
  const getAllTpaFirms = () =>
    api.get('/all-tpa-firms').then((response) => response);
  const getAllBrokerDealers = () =>
    api.get('/all-broker-dealers').then((response) => response);
  const getRegisteredRepBrokerDealers = () =>
    api.get('/registered-rep-broker-dealers').then((response) => response);
  const getIarRiaBrokerDealers = () =>
    api.get('/iar-ria-broker-dealers').then((response) => response);
  const getInsuranceBrokerDealers = () =>
    api.get('/insurance-broker-dealers').then((response) => response);

  return {
    savePlan,
    // shareUpdatePlan,
    shareUpdatePlanSmb,
    shareUpdatePlanFp,
    shareUpdatePlanTpa,
    sendCreateEmail,
    sharePlan,
    sharePlanSmb,
    sharePlanFp,
    sharePlanOnedigitalFp,
    sharePlanTpa,
    paradigmPurchase,
    authenticateNpn,
    relationships,
    advisorCV,
    preview,
    cvFail,
    getCrd,
    restrictedStateCvFail,
    createSalesforceProposal,
    createSalesforceQuickQuoteProposal,
    registerTpa,
    getAllTpaFirms,
    getAllBrokerDealers,
    getRegisteredRepBrokerDealers,
    getIarRiaBrokerDealers,
    getInsuranceBrokerDealers,
  };
};

export default {
  create,
};
