import moment from 'moment';
import { isEmpty, omit } from 'lodash';
import API from '../../api';
import { restrictedState } from '../../../common/FormWithSteps/Utilities';
import { collectSavedPlanData } from '../Paradigm/planHelper';
import { saveData } from '../../../common/FormWithSteps';
import { canCV } from './canCV';
import { principalCvFail } from './principalCvFail';
import { setAdvisorCvData } from './setAdvisorCvData';
import { canPurchase } from './canPurchase';
import { paradigmPurchase } from './paradigmPurchase';

const api = API.create();
const CvFailSubject = 'Advisor Failed CV - Simply Retirement';

export const handleCV = async (
  formData,
  authenticityToken,
  setPurchasePlanStatus,
  putPurchasePlanURL,
  resetData,
  tpaParadigmPackager = [], // eslint-disable-line default-param-last
  setAdvisorCvDataAndResponse,
  uuid,
) => {
  const date = moment().format('YYYY-MM-DD');
  const {
    investmentLineup,
    employerStateCode,
    advisorPoints,
    advisorCompensation,
  } = formData.planDesign.questions;

  const advisorCvStatus = [];
  const advisors = [];

  await Promise.all(
    formData.planDesign.advisors.map(async (advisor, index) => {
      const { advisorNpn, firmName } = advisor.questions;
      const { advisorCredential, advisorCredentialVerification } = advisor;
      const { brokerType, brokerEid } = advisorCredentialVerification;
      const { eid } = advisorCredential;

      const checkCv =
        brokerType === 'insuranceAgent' ||
        brokerType === 'brokerDealer' ||
        brokerType === 'iarRia';
      const advisorCVdata = {
        businessEvent: 'COMMIT-CHANGED',
        writtenState: employerStateCode.value,
        ownersResidentState: employerStateCode.value,
        productCategory: 'GROUP-ANNUITY',
        applicationSignedDate: date,
        transactionEffectiveDate: date,
        mutualFund: investmentLineup.value !== 'fundLineup1',
        levelized:
          advisorCompensation.value === 'option_1'
            ? true
            : advisorPoints.valueFormatted !== '25',
      };

      if (formData.role === 'psivet') {
        advisorCVdata.distributionPartners = [
          {
            role: 'Career-Agent',
            enterpriseId: eid.value,
          },
        ];
      } else if (
        firmName.valueFormatted === 'PRINCIPAL SECURITIES INC' ||
        brokerType === 'insuranceAgent'
      ) {
        advisorCVdata.distributionPartners = [
          {
            role: 'Advisor',
            enterpriseId: eid.value,
          },
        ];
      } else if (brokerType === 'brokerDealer') {
        advisorCVdata.distributionPartners = [
          {
            role: 'Advisor',
            enterpriseId: eid.value,
          },
          {
            role: 'Broker-Dealer',
            enterpriseId: brokerEid,
          },
        ];
      } else {
        advisorCVdata.distributionPartners = [
          {
            role: 'IAR',
            enterpriseId: eid.value,
          },
        ];
      }

      let cvResponseArray = null;
      let response;

      const principalError = canCV(advisor, checkCv);
      if (isEmpty(principalError) && advisorNpn.value !== '') {
        if (advisorCredentialVerification.brokerEid !== '') {
          advisorCVdata.brokerDealer = advisorCredentialVerification.brokerEid;
        }
        response = await api.advisorCV({
          ...advisorCVdata,
          authenticity_token: authenticityToken,
        });
        advisorCvStatus[index] = response.data.credentialStatusText;
        cvResponseArray = response.data.credentialFailureMessages;

        if (response.data.qualificationError) {
          principalError.subject = CvFailSubject;
          principalError.message = response.data.qualificationError;
        }
        principalError.code = response.data.code;
      }

      if (
        advisorCvStatus[index] !== 'PASS' &&
        isEmpty(omit(principalError, 'code'))
      ) {
        principalError.subject = CvFailSubject;
        principalError.message = {
          credentialStatusText: advisorCvStatus[index],
        };
      }

      if (!isEmpty(omit(principalError, 'code'))) {
        if (restrictedState(employerStateCode.value)) {
          api.savePlan({
            ...collectSavedPlanData(formData),
            authenticity_token: authenticityToken,
          });
        }
        principalCvFail(
          formData.planDesign,
          advisor,
          principalError,
          date,
          authenticityToken,
          cvResponseArray,
          advisorCVdata.distributionPartners,
        );

        advisorCvStatus[index] = 'FAIL';
      }
      const cvReferenceData = {
        ...advisorCVdata,
        principalError,
        cvResponse: {
          status: advisorCvStatus[index],
          message: response?.data.credentialVerificationMessages,
        },
      };
      setAdvisorCvDataAndResponse({ ...cvReferenceData }, index);
      advisors[index] = setAdvisorCvData(formData, cvReferenceData, advisor);
    }),
  ).then(() => {
    const sessionData = { ...formData };
    sessionData.planDesign.advisors = advisors;
    const savePlanData = collectSavedPlanData(sessionData);

    saveData(uuid, sessionData);
    api.savePlan({
      ...savePlanData,
      authenticity_token: authenticityToken,
    });
  });

  if (canPurchase(employerStateCode.value, advisorCvStatus)) {
    paradigmPurchase({
      formData,
      authenticityToken,
      setPurchasePlanStatus,
      advisorCvStatuses: advisorCvStatus,
      putPurchasePlanURL,
      resetData,
      tpaParadigmPackager,
    });
  } else {
    setPurchasePlanStatus('advisor-error');
  }
};
