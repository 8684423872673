import styled from 'styled-components';
import { backgroundGrayPattern } from '../../../ImagePaths';

export const DivStyled = styled.div`
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0) 10%
  );
  &::after {
    content: '';
    opacity: 0.2;
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    transform: scale(1.5);
    transition: all 0.5s cubic-bezier(0.44, 0.01, 0.4, 1.34) 0.125s;
    background-image: url(${backgroundGrayPattern});
    background-size: 100%;
    background-image: repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.25) 400px,
        rgba(57, 68, 78, 0.25) 650px,
        transparent 650px 760px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      );
  }
`;
