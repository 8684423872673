import * as React from 'react';
import API from '../../../../../Services/api';
const api = API.create();

export const useTpaFirmApi = (type) => {
  const [data, setFetchData] = React.useState({
    isLoading: false,
    isError: false,
    tpaFirmOptions: [],
  });

  React.useEffect(() => {
    let isCancelled = false;
    let res;
    const fetchData = async () => {
      setFetchData({ ...data, isLoading: true, isError: false });
      try {
        res = await api.getAllTpaFirms();
        const tpaFirmOptions = Object.values(res.data)
          .filter((firm) => typeof firm !== 'string')
          .map((option) => ({
            ...option,
            tpaID: option.tpa_ein,
          }));
        setFetchData({
          ...data,
          isLoading: false,
          isError: false,
          tpaFirmOptions,
        });
      } catch (e) {
        if (!isCancelled) {
          setFetchData({ ...data, isLoading: false, isError: true, msg: e });
        }
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [type]);
  return [data, setFetchData];
};
