import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Question,
  StepControls,
  WithInfoPanel,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';

export const BacaShareEntryDiscretionary = ({
  role,
  active,
  questionId,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  googleData,
  isValid,
}) => {
  const { entryDateDeferrals } = questions;
  const question = questions[questionId];
  const getEntryRequirement = () => {
    let options = [];
    if (entryDateDeferrals.value === 'dailyImmediate') {
      options = entryDateDeferrals.options;
    } else if (entryDateDeferrals.value === 'monthly') {
      options = [
        {
          id: 2,
          user: {},
          label: 'Monthly',
          info: '(First day of the month following the date the eligibility requirements are satisfied)',
          value: 'monthly',
          valueFormatted: 'Monthly',
          checked: false,
          customStyle: '',
        },
        {
          id: 3,
          user: {},
          label: 'Quarterly',
          info: '(First day of the calendar quarter following the date the eligibility requirements are satisfied)',
          value: 'quarterly',
          valueFormatted: 'Quarterly',
          checked: false,
          customStyle: '',
        },
        {
          id: 4,
          user: {},
          label: 'Semi-annually (1/1 and 7/1)',
          value: 'semiAnnually',
          valueFormatted: 'Semi-annually (1/1 and 7/1)',
          checked: false,
          customStyle: '',
        },
      ];
    } else if (entryDateDeferrals.value === 'quarterly') {
      options = [
        {
          id: 3,
          user: {},
          label: 'Quarterly',
          info: '(First day of the calendar quarter following the date the eligibility requirements are satisfied)',
          value: 'quarterly',
          valueFormatted: 'Quarterly',
          checked: false,
          customStyle: '',
        },
        {
          id: 4,
          user: {},
          label: 'Semi-annually (1/1 and 7/1)',
          value: 'semiAnnually',
          valueFormatted: 'Semi-annually (1/1 and 7/1)',
          checked: false,
          customStyle: '',
        },
      ];
    } else {
      options = [
        {
          id: 4,
          user: {},
          label: 'Semi-annually (1/1 and 7/1)',
          value: 'semiAnnually',
          valueFormatted: 'Semi-annually (1/1 and 7/1)',
          checked: false,
          customStyle: '',
        },
      ];
    }
    return options;
  };

  const questionOptions = getEntryRequirement();
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };
  const { value, dirty, hasError } = question;
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <WithInfoPanel
        visibleInfoPanel={visibleInfoPanel}
        setVisibility={setVisibility}
        heading={question.heading}
        content={question.content}
        toggleInfoPanel={toggleInfoPanel}
      >
        <Question
          role={role}
          type={question.type}
          labelClass="fs-form-q fw-bold mb-md-5 text-center"
          inputClass={
            question.type === 'number_field'
              ? 'text-question srp-form-input form-control'
              : 'srp-form-input form-control'
          }
          radioWrapper={
            question.type === 'radio_condensed' ? 'srp-form-toggle' : ''
          }
          thousandSeparator
          question={{
            ...question,
            options: questionOptions,
          }}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!question.heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        {question.heading && (
          <p className="text-center mb-lg-auto">
            <button
              type="button"
              className="srp-button srp-button__link mx-auto"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Need clarification?
            </button>
          </p>
        )}
        <StepControls
          formInReview={formInReview}
          disableReviewBtn={!isValid}
          disabledNextBtn={question.required ? !(dirty && !hasError) : false}
          goToReviewPlan={goToReviewPlan}
          clickNextBtn={() => {
            setVisibility(false);
            updateActiveStep(destination(value), 'forward');
          }}
        />
      </WithInfoPanel>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          setVisibility(false);
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

BacaShareEntryDiscretionary.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  destination: PropTypes.any,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  googleData: PropTypes.object,
  isValid: PropTypes.bool,
};

export default BacaShareEntryDiscretionary;
