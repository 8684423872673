import React from 'react';

const FlatFee = () => (
  <div className="py-4 px-5">
    <p className="fs-p fw-bold mb-0">
      Flat recordkeeping fees vs. asset-based recordkeeping fees
    </p>
    <p className="fs-h2">
      When it comes to plan expenses, one important consideration is whether
      fees are flat or asset-based.
    </p>
    <p className="fs-p">
      <strong>Flat fees have two advantages.</strong> First, they’re consistent,
      so you can plan for them in your budget. And second, they’re typically an
      advertised amount that’s the same for everyone, so there’s no question if
      others are getting a better price.
    </p>
    <p className="fs-p">
      <strong>
        Asset-based fees are determined by the assets in the plan,
      </strong>{' '}
      so as employees contribute and plan assets change, the fees change
      accordingly. When a plan is initially started, asset-based fees may appear
      lower than flat fees, but they can quickly add up over time.
    </p>
  </div>
);

export default FlatFee;
