import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../../Redux/FormDataRedux';

export const DefaultCheckList = ({ putApplicationPanelInfo }) => (
  <>
    <h3 className="fs-form-q text-center pb-4 px-md-3">
      Before you begin, make sure Simply Retirement by Principal
      <small>
        <sup>®</sup>
      </small>{' '}
      fits your client’s needs.
    </h3>
    <div className="row">
      <div className="col-md-10 col-lg-8 mx-auto">
        <ul className="fa-ul">
          <li className="fs-p mb-1">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            New 401(k) plans only.
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Businesses with fewer than 100 employees.
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Only business owners can purchase the plan.
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Each eligible employee must have an email address and access to the
            internet.
          </li>
        </ul>
        <div className="mx-auto">
          <button
            type="button"
            onClick={() => {
              putApplicationPanelInfo({
                hasVisibility: true,
                heading:
                  'Additional information for third party administrators (TPAs)',
                additionalInformation: `
                      Ubiquity will only monitor participants' eligibility and vesting for plans effective on a calendar year.<br />
                      <br></br>
                      <strong>TPAs agree to provide the following services:</strong><br />
                      Plan documents and amendments<br />
                      Plan design<br />
                      Signature-ready annual 5500 and 8955-SSA form filings<br />
                      Address plan failures<br />
                      Annual non-discrimination testing<br />
                      Annual employer contribution calculations<br />
                      Review vesting<br />
                      Monitor/approve participant distributions and loans<br />
                      Communicate plan amendments to the recordkeeper<br />
                      `,
              });
            }}
            className="btn p-0 fs-p"
          >
            <u>
              <b>
                See additional information for third party administrators (TPAs)
              </b>
            </u>
            *
          </button>
        </div>

        <h4 className="fs-p mb-3 md-2">
          We’ll also ask a few things about you and your client’s business:
        </h4>
        <ul className="fa-ul mb-0">
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Legal company name
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            State of company headquarters
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Number of employees
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            <button
              type="button"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: `The Employer Identification Number (EIN) will be used as a unique identifier for each company (your firm and your client).`,
                });
              }}
              className="btn p-0 fs-p text-left"
            >
              <b>
                <u>
                  The Employer Identification Number (EIN) for your firm and
                  your client
                </u>
              </b>
              *
            </button>
          </li>
        </ul>

        <p className="fs-p mt-3 lh-125">
          <small>
            Don’t have all of the information needed above? Selecting Fee
            Proposal allows you to build a print-friendly worksheet to walk
            through with your client, but does not allow submission or sharing
            without providing all required fields.
          </small>
        </p>
        <br></br>
      </div>
    </div>
  </>
);

DefaultCheckList.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultCheckList);
