import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import {
  CloseForm,
  checkValidityOnQuestionArray,
} from '../../common/FormWithSteps';
import EmployerFormSection from '../../common/EmployerFormSection';
import { forms, pages } from '../data';

export const SeveralOptions = ({
  history,
  location,
  formData,
  sponsorFirstName,
  sponsorLastName,
  sponsorEmail,
  companyName,
  companyPhone,
  employerStateCode,
}) => {
  const lastLocation = useLastLocation();
  const handleClose = () => {
    if (lastLocation) {
      history.goBack();
    } else {
      history.push('/');
    }
  };
  const pageKey = 'severalOptions';
  const additionalInputs = Object.values(formData.planDesign.questions).filter(
    (question) => forms[pageKey].inputs.includes(question.name),
  );
  const inputsToValidate = [
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    companyName,
    companyPhone,
    employerStateCode,
    formData.planDesign.questions.companyPhone,
    formData.planDesign.questions.zipCode,
    ...additionalInputs,
  ];
  const searchParams = new URLSearchParams(location.search);
  const isDisabled =
    searchParams.get('response') === 'success'
      ? true
      : !checkValidityOnQuestionArray(inputsToValidate);
  const renderResponse = () => {
    switch (searchParams.get('response')) {
      case 'success':
        return (
          <div className="text-success fs-h3 text-center">
            {forms[pageKey].responseSuccessMsg}
          </div>
        );
      case 'error':
        return (
          <div className="text-warning fs-h3 text-center">
            {forms[pageKey].responseErrorMsg}
          </div>
        );
      default:
        return false;
    }
  };
  const [status, setStatus] = useState('');
  const refForm = useRef('');
  const submitForm = async (e) => {
    // Previously POST to Pardot -- has been removed
    // Form is broken and needs to be fixed
    e.preventDefault();
    setStatus('error');
  };
  return (
    <div className="fws fws_dark">
      <div className="fws__wrapper h-md-100">
        <CloseForm handleClose={handleClose} light />
        <div className="container h-100">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div
                dangerouslySetInnerHTML={{
                  __html: pages[pageKey].content,
                }}
              />
              {status === 'error' ? (
                <div className="text-warning fs-h3 text-center">
                  {forms[pageKey].responseErrorMsg}
                </div>
              ) : (
                ''
              )}

              {searchParams.has('response') && renderResponse()}
              <form
                ref={refForm}
                id={forms[pageKey].id}
                action={forms[pageKey].action}
                method={forms[pageKey].method}
              >
                <EmployerFormSection className="mb-4" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: forms[pageKey].disclaimer,
                  }}
                />
                <p className="text-center">
                  <button
                    disabled={isDisabled || status === 'loading'}
                    className="srp-button srp-button__large srp-button__primary"
                    type="submit"
                    onClick={(e) => {
                      setStatus('loading');
                      submitForm(e);
                    }}
                  >
                    {status === '' ? 'Contact us' : 'Submitting...'}
                  </button>
                </p>
              </form>
            </div>
          </div>
          {lastLocation && (
            <button
              type="button"
              className="goback fa-solid fa-arrow-left text-white"
              onClick={() => {
                history.replace({
                  pathname: lastLocation.pathname,
                });
              }}
            >
              <span className="sr-only">Go Back</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

SeveralOptions.propTypes = {
  formData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  sponsorFirstName: PropTypes.object.isRequired,
  sponsorLastName: PropTypes.object.isRequired,
  sponsorEmail: PropTypes.object.isRequired,
  companyName: PropTypes.object.isRequired,
  companyPhone: PropTypes.object.isRequired,
  employerStateCode: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  sponsorFirstName: store.formData.planDesign.questions.sponsorFirstName,
  sponsorLastName: store.formData.planDesign.questions.sponsorLastName,
  sponsorEmail: store.formData.planDesign.questions.sponsorEmail,
  companyName: store.formData.planDesign.questions.companyName,
  companyPhone: store.formData.planDesign.questions.companyPhone,
  employerStateCode: store.formData.planDesign.questions.employerStateCode,
});

export default connect(mapStateToProps)(SeveralOptions);
