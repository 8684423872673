import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { ScrollInToView } from '../../../../common/VisibilitySensor';

const CostSavingsSection = () => (
  <section
    className="section section_py-5"
    style={{
      background:
        'linear-gradient(0deg, rgba(242,242,242,1) 0%, rgba(242,242,242,0.5) 100%)',
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-6 col-md-8 mx-auto">
          <h3 className="fs-h3 text-center mb-3">
            Cost savings with flat-fee 401(k) plan pricing
          </h3>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-7 col-md-8 mx-auto">
          <h5
            className="fs-h5 fw-normal text-center mb-3"
            style={{ lineHeight: '165%' }}
          >
            Other 401(k) plans may increase recordkeeping fees to business
            owners as the total account balances increase. Not with the Simply
            Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            401(k) plan. No guessing and no changes as employees contribute.
            It’s a predictable model you can plan for.
          </h5>
        </div>
      </div>
      <div className="row mb-5 d-print-none">
        <div className="col-lg-7 col-md-8 mx-auto">
          <ScrollInToView
            renderItem={(inView) => (
              <div
                style={{
                  animationDelay: '0.2s',
                }}
                className={inView ? 'fadeIn animated' : 'hidden'}
              >
                <ReactPlayerLoader
                  accountId="1510329453001"
                  videoId="6274775350001"
                  attrs={{
                    className: 'brightcove-player',
                  }}
                  embedOptions={{ responsive: { aspectRatio: '16:9' } }}
                />
              </div>
            )}
          />
        </div>
      </div>
      <p className="text-center my-5 pt-3">
        <Link
          className="srp-button srp-button__primary px-5"
          to="/third-party-administrator/plans"
        >
          Quote a plan for your client
        </Link>
      </p>
    </div>
  </section>
);

export default CostSavingsSection;
