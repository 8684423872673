import React from 'react';
import PropTypes from 'prop-types';
import { Question } from '../FormWithSteps';

const RowPlanSponsor = ({ data, handleQuestionChange }) => (
  <div className="row justify-content-center">
    <div className="col-md-5 mb-5 d-flex justify-content-between flex-column">
      <h4 id="planSponsor" className="min-h-50">
        Who is the small business owner contact?
      </h4>
      <Question
        type={data.companyName.type}
        inputClass="srp-form-input form-control"
        question={{
          ...data.companyName,
          label: `${data.companyName.label} ${
            data.companyName.required ? '(Required)' : '(Optional)'
          }`,
        }}
        handleQuestionChange={handleQuestionChange}
      >
        {data.companyName.hasError && data.companyName.required && (
          <small>Question is required</small>
        )}
      </Question>
    </div>
    <div className="offset-md-1 col-md-5 mb-5 d-flex justify-content-between flex-column">
      <h4 id="workers" className="min-h-50">
        How many employees (including the business owner) would be eligible for
        the plan?
      </h4>
      <Question
        type={data.numberOfEmployees.type}
        labelClass="text-left"
        inputClass="srp-form-input form-control"
        question={{
          ...data.numberOfEmployees,
          label: `${data.numberOfEmployees.label} ${
            data.numberOfEmployees.required ? '(Required)' : '(Optional)'
          }`,
        }}
        handleQuestionChange={handleQuestionChange}
      >
        {data.numberOfEmployees.hasError && data.numberOfEmployees.required && (
          <small>Question is required and must be 99 or less</small>
        )}
      </Question>
    </div>
  </div>
);

RowPlanSponsor.propTypes = {
  data: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default RowPlanSponsor;
