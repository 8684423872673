export const sourcesGrandfatherDate = (
  questions,
  tpaParadigmPackager = null,
) => {
  const {
    hasGrandfatherDate,
    grandfatherDateHasCustomDate,
    grandfatherDate,
    planEffectiveDate,
    hasTpa,
  } = questions;
  let planDate;

  if (hasTpa.value) {
    planDate =
      tpaParadigmPackager.planEffectiveDate === null ||
      tpaParadigmPackager.planEffectiveDate === undefined
        ? planEffectiveDate.value
        : tpaParadigmPackager.planEffectiveDate.value;
  } else {
    planDate = planEffectiveDate.value;
  }

  if (hasGrandfatherDate.value === 'no') return null;
  if (grandfatherDateHasCustomDate.value === 'no') return planDate;
  return grandfatherDate.value;
};
