import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';

import HeroSection from './HeroSection';
import ActionCardSection from '../../../common/ActionCardSection';
import TwoColCircleImg from '../../../common/TwoColCircleImg';
import AboutSection from './AboutSection';
import SecureActSection from './SectionCosts';
import CostSavingsSection from './CostSavingsSection';
import CallToActionSection from './CallToActionSection';
import QuestionSection from './QuestionSection';
import PurpleSection from './PurpleSection';

import { DivStyled } from './index.styles';
import { TPAHomeHero02, TPAHomeCircle } from '../../../ImagePaths';
import { GrowthPlatform } from '../../../common/GrowthPlatform';

const sectionHero = {
  title: `<h1 class="fs-display1 mb-md-4 mb-0 text-white">
            Start-up retirement plans <span class="fw-bold" style="color: rgba(0, 40, 85, 1)">simplified</span>
          </h1>`,
  image: TPAHomeHero02,
  caption: `<h2 class="fs-h2 fw-normal d-md-block d-none text-white">
  Establish start-up 401(k) plans for small businesses in a matter of days, not weeks, with this third party administrator (TPA) solution.
            </h2>`,
  copy: `<h2 class="fs-h2 fw-normal text-center my-5 lh-125">
  Establish start-up 401(k) plans for small businesses in a matter of days, not weeks, with this third party administrator (TPA) solution.
          </h2>`,
};

const calltoactionData = [
  {
    id: 1,
    icon: `<svg fill="none" height="100" viewBox="0 0 100 100" width="100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" role="img" aria-labelledby="planTitle planDesc"><title id="planTitle">Planning icon</title><desc id="planDesc">Planning icon: Create a 401(k) plan proposal for your client.</desc><linearGradient id="a1"><stop offset="0" stop-color="#0395c6"/><stop offset="1" stop-color="#029c98"/></linearGradient><linearGradient id="b1" gradientUnits="userSpaceOnUse" x1="43.8822" x2="43.8822" xlink:href="#a1" y1="26.6362" y2="90.6672"/><linearGradient id="c1" gradientUnits="userSpaceOnUse" x1="28.7274" x2="28.7274" xlink:href="#a1" y1="46.644" y2="61.1644"/><linearGradient id="d1" gradientUnits="userSpaceOnUse" x1="74.2322" x2="74.2322" xlink:href="#a1" y1="76.9209" y2="91.4413"/><linearGradient id="e1" gradientUnits="userSpaceOnUse" x1="53.7983" x2="53.7983" xlink:href="#a1" y1="26.6362" y2="39.4315"/><linearGradient id="f1" gradientUnits="userSpaceOnUse" x1="28.7205" x2="28.7205" xlink:href="#a1" y1="8" y2="29.5429"/><g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"><path d="m47.8905 26.6362c6.8406 6.8731 11.0721 16.3541 11.0721 26.8403 0 18.3508-12.9512 33.6455-30.1608 37.1907" stroke="url(#b1)"/><path d="m21.5015 46.644 14.4517 14.5204" stroke="url(#c1)"/><path d="m35.9532 46.644-14.4517 14.5204" stroke="url(#c1)"/><path d="m67.0063 76.9209 14.4518 14.5204" stroke="url(#d1)"/><path d="m81.4581 76.9209-14.4518 14.5204" stroke="url(#d1)"/><path d="m47.0388 39.4315.7841-12.7953 12.7349.7879" stroke="url(#e1)"/><path d="m28.7205 29.5429c5.9208 0 10.7206-4.8226 10.7206-10.7715s-4.7998-10.7714-10.7206-10.7714-10.7205 4.8225-10.7205 10.7714 4.7997 10.7715 10.7205 10.7715z" stroke="url(#f1)"/></g></svg>`,
    excerpt: 'Create a 401(k) plan proposal for your client.',
    caption:
      'Enter a few details and share the plan proposal with your client for review.',
    actionText: 'Start a proposal',
    actionPath: 'third-party-administrator/plan-proposal',
  },
  {
    id: 2,
    icon: `<svg fill="none" height="100" viewBox="0 0 100 100" width="100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="a"><stop offset="0" stop-color="#0394c8"/><stop offset="1" stop-color="#029c97"/></linearGradient><linearGradient id="b" gradientUnits="userSpaceOnUse" x1="50.5076" x2="50.5076" xlink:href="#a" y1="24.6094" y2="62.0836"/><linearGradient id="c" gradientUnits="userSpaceOnUse" x1="50" x2="50" xlink:href="#a" y1="53.6431" y2="63.6402"/><linearGradient id="d" gradientUnits="userSpaceOnUse" x1="50.0001" x2="50.0001" xlink:href="#a" y1="10.9604" y2="90.0245"/><linearGradient id="e" gradientUnits="userSpaceOnUse" x1="73.5148" x2="73.5148" xlink:href="#a" y1="10.9604" y2="26.3602"/><linearGradient id="f" gradientUnits="userSpaceOnUse" x1="50" x2="50" xlink:href="#a" y1="58.6411" y2="74.2055"/><g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"><path d="m50.0076 24.6094v37.4742" stroke="url(#b)"/><path d="m60.0487 53.6431-10.0412 9.9971-10.0562-9.9971" stroke="url(#c)"/><path d="m82.028 26.3602v63.6643h-64.0558v-79.0641h47.0294z" stroke="url(#d)"/><path d="m82.0279 26.3602h-17.0263v-15.3998" stroke="url(#e)"/><path d="m29.338 58.6411v15.5644h41.3239v-15.5644" stroke="url(#f)"/></g></svg>`,
    excerpt: 'Create a 401(k) plan for your client.',
    caption:
      'Ready to complete the plan specifics? Make selections in our online planner and share the plan for approval.',
    actionText: 'Create a plan',
    actionPath: 'third-party-administrator/plans', // 401k-table
  },
  {
    id: 3,
    icon: `<svg height="75" enable-background="new 0 0 100 100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" role="img" aria-labelledby="magTitle magDesc"><title id="magTitle">Magnifying glass icon</title><desc id="magDesc">Magnifying glass icon: Compare retirement savings options.</desc><linearGradient id="a2"><stop offset="0" stop-color="#0091da"/><stop offset=".474" stop-color="#0096b7"/><stop offset="1" stop-color="#009c91"/></linearGradient><linearGradient id="b2" gradientUnits="userSpaceOnUse" x1="31.2678" x2="31.2678" xlink:href="#a2" y1="14.2463" y2="28.1706"/><linearGradient id="c2" gradientUnits="userSpaceOnUse" x1="68.036" x2="68.036" xlink:href="#a2" y1="47.8317" y2="90.5018"/><linearGradient id="d2" gradientUnits="userSpaceOnUse" x1="36.9385" x2="36.9385" xlink:href="#a2" y1="8.0686" y2="59.8589"/><g fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"><path d="m39.9 15.7c-.3 0-.4 0-.7 0-4.4 0-8.9 1.7-12.3 5-1.8 1.7-3.3 3.8-4.3 6" stroke="url(#b2)"/><path d="m49.9 56.4 26.7 29.8.9 1c1 1.1 2.3 1.7 3.7 1.7 1.2 0 2.5-.5 3.5-1.4 1.1-1 1.7-2.4 1.7-3.8-.1-1.4-.6-2.6-1.4-3.7l-.7-.8-27.1-29.9" stroke="url(#c2)"/><ellipse cx="36.9" cy="34" rx="24.3" ry="24.4" stroke="url(#d2)"/></g></svg>`,
    excerpt: 'Compare our solution with other types of plans.',
    caption:
      'See how Simply Retirement by Principal<sup><small>®</small></sup> stacks up against other types of plans.',
    actionText: 'Compare plans',
    actionPath: '/third-party-administrator/retirement-plans#401k-table',
  },
];

const sectionTwoColCircleImg = {
  heading: `
  <h3 class="fs-h3 mb-md-3 mb-5 mr-4 mr-md-0">
    Finally, a straightforward solution for small business retirement plans.
  </h3>
`,
  copy: `
  <p class="fs-h6">
    Establishing a new retirement plan for a small business can take awhile. There’s information to verify. Documents to sign. Details to coordinate. <strong>We can help you speed up the process.</strong>
  </p>
  <p class="fs-h6 mb-3">
    How do we do it? By pairing a competitive packaged product offering with innovative online technology. We’ve streamlined every step in the process—from the proposal to the creation and implementation of new small business retirement plans.
  </p>
  `,
};

const TPADisclaimer1 = `<p class="fs-disclaimer-print">As of Dec. 31, 2023.</p>`;
const TPADisclaimer2 = `<p class="fs-disclaimer-print">As of Jan. 1, 2023.</p>`;

const HomeContainer = ({ applicationPanelInfo, putApplicationPanelInfo }) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });
  return (
    <DivStyled className="smb-home">
      <HeroSection
        image={sectionHero.image}
        title={sectionHero.title}
        caption={sectionHero.caption}
        copy={sectionHero.copy}
        offset
      />
      <section style={{ position: 'relative', zIndex: 1 }}>
        <ActionCardSection cards={calltoactionData} />
      </section>

      <TwoColCircleImg
        heading={sectionTwoColCircleImg.heading}
        copy={sectionTwoColCircleImg.copy}
        image={TPAHomeCircle}
        circleBg="bg-white"
      />

      <AboutSection />

      <SecureActSection
        title="Simply Retirement by Principal<sup><small>®</small></sup> costs (TPA services not included)"
        putApplicationPanelInfo={putApplicationPanelInfo}
        cardType="default"
      />

      <CostSavingsSection />
      <PurpleSection
        putApplicationPanelInfo={putApplicationPanelInfo}
        TPADisclaimer1={TPADisclaimer1}
        TPADisclaimer2={TPADisclaimer2}
      />
      <CallToActionSection />
      <QuestionSection
        putApplicationPanelInfo={putApplicationPanelInfo}
        applicationPanelInfo={applicationPanelInfo}
      />
      <GrowthPlatform
        wrapperStyles="bg-concrete"
        title="Small business growth platform"
        copy="With a Simply Retirement by Principal<span><sup>®</sup></span> plan, your client has access to Elevate by Principal, a powerful network, resource, and team of people in their corner. From data-driven insights to deep discounts on products and services, that a business owner uses every day, Elevate by Principal can provide what your client needs to take their business to the next level."
      />
      <section className="section_py-5 bg-concrete">
        <p className="fs-disclaimer text-center pb-5 mb-5">
          Intended for TPA and financial professional use.
        </p>
        <span className="d-none">1847653-092021</span>
      </section>
    </DivStyled>
  );
};

HomeContainer.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
