import React from 'react';
import PropTypes from 'prop-types';

const IconFirstDayO = ({ fill, fillAlt }) => (
  <g>
    <path
      d="M20.553 17.0546L16.5307 19.1651L17.298 14.6929L14.043 11.5242L18.5388 10.8725L20.553 6.80688L22.5672 10.8725L27.069 11.5242L23.814 14.6929L24.5813 19.1651L20.553 17.0546Z"
      stroke={fillAlt || fill}
      className="stroke"
      strokeWidth="2.6242"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1634 8.79785H3V11.6079C3 12.8037 3.46158 13.9456 4.30081 14.8006C5.9433 16.4747 8.82066 18.9977 10.9847 18.6868"
      stroke={fill}
      className="stroke"
      strokeWidth="2.6242"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.7827 31.9001V25.8495C31.3058 21.0545 30.7124 14.2446 30.7124 14.2446V2H10.2771V14.2446C10.2771 14.2446 9.68966 21.0545 17.2068 25.8495V31.906H14.7011C11.8956 31.906 9.59375 34.1959 9.59375 37H31.4377C31.4377 34.2019 29.1418 31.906 26.3304 31.906L23.7827 31.9001Z"
      stroke={fill}
      className="stroke"
      strokeWidth="2.6242"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.8271 8.79785H37.9905V11.6079C37.9905 12.8037 37.529 13.9456 36.6897 14.8006C35.0472 16.4747 32.1699 18.9977 30.0059 18.6868"
      stroke={fill}
      className="stroke"
      strokeWidth="2.6242"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

IconFirstDayO.propTypes = {
  fill: PropTypes.string.isRequired,
  fillAlt: PropTypes.string,
};

export default IconFirstDayO;
