import { googleData } from '../../../Services/utilities';
import CheckList from './Steps/CheckList';
import ClientInfo from './Steps/ClientInfo';
import FirmInfo from './Steps/FirmInfo';
import FirmBrokerNotAllowed from './Steps/FirmBrokerNotAllowed';
import YourInfo from './Steps/YourInfo';
import NoNpnBilling from './Steps/NoNpnBilling';
import Compensation from './Steps/Compensation';
import SplitComp from './Steps/SplitComp';
import InvestmentLineup from './Steps/InvestmentLineup';
import EmployerPaidParticipantFees from '../DefaultPlan/EmployerPaidParticipantFees';
import IsCurrentPrincipalPlan from '../DefaultPlan/IsCurrentPrincipalPlan';

export const advisorComponents = {
  CheckList,
  ClientInfo,
  FirmInfo,
  FirmBrokerNotAllowed,
  YourInfo,
  NoNpnBilling,
  Compensation,
  SplitComp,
  InvestmentLineup,
  EmployerPaidParticipantFees,
  IsCurrentPrincipalPlan,
};

const investmentLineupPreviousStep = (formData, advisorIa, splitComp) => {
  if (splitComp && formData.planDesign.advisors.length > 1) return 'splitComp';
  return 'compensation';
};

export const advisorFormSteps = (
  hashId,
  putCrd,
  putOptionDisabled,
  advisors,
  putBrokerEid,
  isFirmBrokerSplitComp,
  goToReviewPlan,
  role,
  history,
  formData,
  isAdvisorIa,
) => [
  {
    key: 'advisorCheckList',
    hasSidebar: false,
    component: 'CheckList',
    destination: () => 'clientInfo',
    previousStep: hashId !== '' ? 'returnRoleSelection' : 'roleSelection',
    googleData: googleData('checklist_advisor'),
  },
  {
    hashId,
    role,
    key: 'clientInfo',
    hasSidebar: false,
    putCrd,
    putOptionDisabled,
    component: 'ClientInfo',
    previousStep: 'advisorCheckList',
    destination: () => 'firmInfo',
    googleData: googleData('advisor_credential_info'),
  },
  {
    key: 'firmInfo',
    hasSidebar: false,
    history,
    component: 'FirmInfo',
    previousStep: 'clientInfo',
    destination: () => 'advisorInformation',
    googleData: googleData('advisor_firm_details'),
  },
  {
    role,
    key: 'firmBrokerNotAllowed',
    hasSidebar: false,
    component: 'FirmBrokerNotAllowed',
    previousStep: 'firmInfo',
    googleData: googleData('firm_broker_not_allowed'),
  },
  {
    role,
    key: 'advisorInformation',
    hasSidebar: false,
    component: 'YourInfo',
    previousStep: 'firmInfo',
    destination: 'compensation',
    googleData: googleData('advisor_credential_details'),
  },
  {
    role,
    key: 'noNpnBilling',
    hasSidebar: false,
    component: 'NoNpnBilling',
    previousStep: 'advisorInformation',
    destination: () => 'investmentLineup',
    googleData: googleData('no_npn_billing'),
  },
  {
    role,
    advisors,
    putCrd,
    key: 'compensation',
    hasSidebar: false,
    putBrokerEid,
    isFirmBrokerSplitComp,
    component: 'Compensation',
    previousStep: 'advisorInformation',
    destination: (multipleAdvisors) =>
      isFirmBrokerSplitComp && multipleAdvisors
        ? 'splitComp'
        : 'investmentLineup',
    googleData: googleData('advisor_credential_billing'),
  },
  {
    role,
    key: 'splitComp',
    hasSidebar: false,
    component: 'SplitComp',
    previousStep: 'compensation',
    destination: () => 'investmentLineup',
    googleData: googleData('splitComp'),
  },
  {
    role,
    goToReviewPlan,
    history,
    isFirmBrokerSplitComp,
    key: 'investmentLineup',
    hasSidebar: false,
    questionId: 'investmentLineup',
    component: 'InvestmentLineup',
    previousStep: investmentLineupPreviousStep(
      formData,
      isFirmBrokerSplitComp,
      isAdvisorIa,
    ),
    destination: () => 'isCurrentPrincipalPlan',
    googleData: googleData('investmentLineup'),
  },
  {
    role,
    goToReviewPlan,
    history,
    key: 'isCurrentPrincipalPlan',
    hasSidebar: false,
    questionId: 'isCurrentPrincipalPlan',
    component: 'IsCurrentPrincipalPlan',
    googleData: googleData('isCurrentPrincipalPlan'),
  },
  {
    role,
    goToReviewPlan,
    history,
    isFirmBrokerSplitComp,
    key: 'employerPaidParticipantFees',
    hasSidebar: false,
    questionId: 'employerPaidParticipantFees',
    component: 'EmployerPaidParticipantFees',
    destination: () => 'step_1',
    googleData: googleData('employerPaidParticipantFees'),
  },
];
