import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StepTracker = ({ totalSteps, activeStepIndex }) => (
  <div
    className="progress-tracker progress-tracker-wrapper"
    data-steps={totalSteps}
  >
    <span className="fs-p progress-tracker__caption d-inline-block mb-1">
      STEP {`${activeStepIndex}/${totalSteps}`}
    </span>
    <div className="progress-tracker__bar">
      {[...Array(totalSteps).keys()].map((el) => (
        <div
          key={el}
          className={classNames(
            `progress-tracker__button progress-tracker__button__${totalSteps}`,
            {
              'progress-tracker__button_active': activeStepIndex >= el + 1,
              'progress-tracker__button_disabled': activeStepIndex <= el + 1,
            },
          )}
        >
          <span>{el + 1}</span>
        </div>
      ))}
    </div>
  </div>
);

StepTracker.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
};

export default StepTracker;
