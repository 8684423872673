import React from 'react';
import PropTypes from 'prop-types';

const IconBusinessOwnerO = ({ fill, fillAlt }) => (
  <g>
    <path
      d="M11.1684 27.1743C7.47704 27.1743 4.4707 24.1551 4.4707 20.4478C4.4707 16.7405 7.47704 13.7212 11.1684 13.7212C14.8598 13.7212 17.8662 16.7405 17.8662 20.4478C17.8662 24.1551 14.8598 27.1743 11.1684 27.1743ZM11.1684 15.0229C8.18674 15.0229 5.76688 17.4582 5.76688 20.4478C5.76688 23.4374 8.19166 25.8726 11.1684 25.8726C14.1452 25.8726 16.57 23.4374 16.57 20.4478C16.57 17.4532 14.1501 15.0229 11.1684 15.0229Z"
      fill={fill}
      className="fill"
    />
    <path
      d="M21.69 34.0001C21.3647 34.0001 21.0887 33.7576 21.0493 33.426C20.7092 30.4562 19.8714 29.9117 18.8068 29.2188L18.6393 29.1099C16.6531 27.8081 14.2086 27.1746 11.1727 27.1746C8.13187 27.1746 5.6923 27.8081 3.70615 29.1099L3.53858 29.2188C2.47404 29.9068 1.63621 30.4562 1.29615 33.426C1.25672 33.7823 0.936374 34.0348 0.581527 33.9952C0.22668 33.9556 -0.0295977 33.6339 0.0147582 33.2775C0.423817 29.6989 1.651 28.902 2.83875 28.1298L3.00138 28.021C5.20439 26.5757 7.8756 25.8728 11.1776 25.8728C14.4748 25.8728 17.1509 26.5757 19.3539 28.021L19.5165 28.1298C20.7043 28.902 21.9315 29.6989 22.3405 33.2775C22.38 33.6339 22.1286 33.9556 21.7738 33.9952C21.7393 34.0001 21.7146 34.0001 21.69 34.0001Z"
      fill={fill}
      className="fill"
    />
    <path
      d="M13.5086 6.48484C12.6438 6.48484 11.9256 7.20052 11.9256 8.07858V10.9791C11.9256 11.4411 11.5509 11.8156 11.0888 11.8156C10.6266 11.8156 10.252 11.4411 10.252 10.9791V8.07858C10.252 6.28384 11.7121 4.81177 13.5086 4.81177H36.549C38.3455 4.81177 39.8057 6.28384 39.8057 8.07858V24.6451C39.8057 26.4398 38.3455 27.9119 36.549 27.9119H22.503C22.0408 27.9119 21.6662 27.5373 21.6662 27.0753C21.6662 26.6133 22.0408 26.2388 22.503 26.2388H36.549C37.4139 26.2388 38.1321 25.5231 38.1321 24.6451V8.07858C38.1321 7.20052 37.4139 6.48484 36.549 6.48484H13.5086Z"
      fill={fillAlt || fill}
      className="fill"
    />
    <path
      d="M34.8977 9.94126C35.0564 10.0967 35.1467 10.3088 35.1488 10.5308L35.1882 14.7232C35.1925 15.1852 34.8214 15.5632 34.3593 15.5675C33.8971 15.5719 33.519 15.2009 33.5146 14.7389L33.4831 11.3841L30.1465 11.4197C29.6844 11.4247 29.3058 11.0541 29.3008 10.5922C29.2959 10.1302 29.6665 9.75167 30.1287 9.74674L34.3031 9.7022C34.5252 9.69983 34.7391 9.78583 34.8977 9.94126Z"
      fill={fillAlt || fill}
      className="fill"
    />
    <path
      d="M34.1373 10.7922C34.4648 11.1182 34.4661 11.6478 34.14 11.9753L26.5552 19.5928C26.3942 19.7544 26.1743 19.8434 25.9462 19.839C25.7181 19.8347 25.5017 19.7375 25.347 19.5698L20.9343 14.7881L18.849 16.3722C18.4811 16.6517 17.9561 16.5802 17.6765 16.2123C17.3968 15.8445 17.4684 15.3197 17.8364 15.0401L20.5273 12.9959C20.8708 12.735 21.3563 12.7778 21.6487 13.0947L25.985 17.7938L32.9538 10.795C33.2799 10.4675 33.8097 10.4663 34.1373 10.7922Z"
      fill={fillAlt || fill}
      className="fill"
    />
  </g>
);

IconBusinessOwnerO.propTypes = {
  fill: PropTypes.string.isRequired,
  fillAlt: PropTypes.string,
};

export default IconBusinessOwnerO;
