import states from '../../../../Services/states.json';
import { oneDigitalAdvisors } from '../../../../data/OneDigitalAdvisors';

export const ADVISOR_CREDENTIAL_INITIAL_STATE = {
  hierarchies: {
    endpoint: 'sct/fp/relationship/hierarchies',
  },
  status: '',
  eid: {
    value: 'onedigital',
    endpoint: 'sca/distribution-partner/identity/npn',
  },
};

export const ADVISOR_CREDENTIAL_VERIFICATION_INITIAL_STATE = {
  insuranceAgent: '',
  insuranceAgentNpn: '',
  insuranceAgentCompType: '',
  brokerType: 'iarRia',
  status: '',
  brokerEid: '000000000',
  verificationStatus: 'PASS',
  crd: {
    value: '6823125',
    error: '',
  },
  endpoint: 'sca/distribution-partner/identity/eId',
};

export const ADVISOR_CV_DATA = {
  state: '',
  date: '',
  mutualFund: '',
  levelized: '',
  distributionPartners: [],
  brokerType: '',
  principalError: '',
  cvResponse: {
    status: '',
    message: '',
  },
};

export const ONEDIGITAL_INITIAL_STATE = {
  advisorCredential: { ...ADVISOR_CREDENTIAL_INITIAL_STATE },
  advisorCredentialVerification: {
    ...ADVISOR_CREDENTIAL_VERIFICATION_INITIAL_STATE,
  },
  advisorCvData: {
    ...ADVISOR_CV_DATA,
  },
  advisorIndex: 0,
  questions: {
    advisorNpn: {
      type: 'number_field',
      name: 'advisorNpn',
      stepName: 'advisorInformation',
      user: {},
      label: 'Your national producer number (NPN)',
      required: true,
      dirty: true,
      value: '',
      hasError: false,
    },
    advisorEmail: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorEmail',
      user: {
        employer: { label: 'Your email' },
        advisor: { label: 'Your email' },
        tpa: { label: 'Financial Professional email' },
      },
      required: true,
      dirty: true,
      value: 'Weston.staples@onedigital.com',
      valueFormatted: 'Weston.staples@onedigital.com',
      hasError: false,
    },
    advisorFirstName: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorFirstName',
      user: {
        employer: { label: 'Your first name' },
        advisor: { label: 'Your first name' },
        tpa: { label: 'Financial Professional first name' },
      },
      required: true,
      dirty: true,
      value: 'Weston',
      valueFormatted: 'Weston',
      hasError: false,
    },
    advisorLastName: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorLastName',
      user: {
        employer: { label: 'Your last name' },
        advisor: { label: 'Your last name' },
        tpa: { label: 'Financial Professional last name' },
      },
      required: true,
      dirty: true,
      value: 'Staples',
      valueFormatted: 'Staples',
      hasError: false,
    },
    advisorStateCode: {
      stepName: 'advisorInformation',
      type: 'dropdown',
      name: 'advisorStateCode',
      label: 'Your state of residence',
      options: states,
      required: true,
      dirty: true,
      value: 'KS',
      valueFormatted: 'Kansas',
      hasError: false,
    },
    advisorZipCode: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorZipCode',
      user: {},
      label: 'Your zip code',
      required: true,
      dirty: true,
      value: '66210',
      hasError: false,
    },
    advisorPhone: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorPhone',
      user: {},
      label: 'Your phone number',
      required: true,
      dirty: true,
      value: '877-742-2021',
      valueFormatted: '877-742-2021',
      hasError: false,
    },
    firmName: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'firmName',
      user: {},
      label: 'Your broker-dealer',
      required: true,
      dirty: true,
      value: {
        id: '0',
        label: 'ONEDIGITAL INVESTMENT ADVISORS',
        value: 'ONEDIGITAL INVESTMENT ADVISORS',
        selected: false,
        enterpriseId: '000000000',
      },
      hasError: false,
      valueFormatted: 'ONEDIGITAL INVESTMENT ADVISORS',
    },
    floridaLicenseNumber: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'floridaLicenseNumber',
      user: {},
      label: 'Florida license number',
      required: true,
      dirty: true,
      value: '',
      valueFormatted: '',
      hasError: false,
    },
    compensation: {
      stepName: 'compensation',
      type: 'slider',
      name: 'compensation',
      user: {},
      label: 'Compensation amount',
      thousandSeperator: false,
      min: 0,
      max: 100,
      defaultValue: 100,
      valueFormatted: '100%',
      step: 1,
      value: 100,
      required: true,
      dirty: true,
      hasError: false,
    },
    advisorRepNumber: {
      stepName: 'advisorInformation',
      type: 'text_field',
      name: 'advisorRepNumber',
      user: {},
      label: 'Representative number',
      required: true,
      dirty: true,
      value: '',
      hasError: false,
    },
  },
};

export function initOneDigitalAdvisors() {
  return oneDigitalAdvisors.map(({ data, advisorIndex }) => ({
    ...ONEDIGITAL_INITIAL_STATE,
    advisorIndex,
    questions: {
      ...ONEDIGITAL_INITIAL_STATE.questions,
      advisorEmail: {
        ...ONEDIGITAL_INITIAL_STATE.questions.advisorEmail,
        value: data.advisorEmail,
        valueFormatted: data.advisorEmail,
      },
      advisorFirstName: {
        ...ONEDIGITAL_INITIAL_STATE.questions.advisorFirstName,
        value: data.advisorFirstName,
        valueFormatted: data.advisorFirstName,
      },
      advisorLastName: {
        ...ONEDIGITAL_INITIAL_STATE.questions.advisorLastName,
        value: data.advisorLastName,
        valueFormatted: data.advisorLastName,
      },
      advisorStateCode: {
        ...ONEDIGITAL_INITIAL_STATE.questions.advisorStateCode,
        value: data.advisorStateCode,
        valueFormatted: data.advisorStateCode,
      },
      advisorZipCode: {
        ...ONEDIGITAL_INITIAL_STATE.questions.advisorZipCode,
        value: data.advisorZipCode,
        valueFormatted: data.advisorZipCode,
      },
      advisorPhone: {
        ...ONEDIGITAL_INITIAL_STATE.questions.advisorPhone,
        value: data.advisorPhone,
        valueFormatted: data.advisorPhone,
      },
      advisorRepNumber: {
        ...ONEDIGITAL_INITIAL_STATE.questions.advisorRepNumber,
        value: data.repNumber,
        valueFormatted: data.repNumber,
      },
      compensation: {
        ...ONEDIGITAL_INITIAL_STATE.questions.compensation,
        value: data.compensation,
        valueFormatted: data.compensation,
      },
    },
    advisorCredentialVerification: {
      ...ONEDIGITAL_INITIAL_STATE.advisorCredentialVerification,
      crd: {
        ...ONEDIGITAL_INITIAL_STATE.advisorCredentialVerification.crd,
        value: data.crd,
      },
    },
  }));
}
