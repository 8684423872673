import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { psiVetLogo } from '../../../ImagePaths';

const HeroSection = ({ image, title, caption, copy, offset }) => (
  <section style={{ position: 'relative', zIndex: 1 }}>
    <div className="hero-section">
      <div className="container" style={{ zIndex: 3 }}>
        <div className="row">
          <div className="offset-xl-1 col-9 col-md-7 col-lg-6 col-xl-5">
            <img src={psiVetLogo} alt="" width="175px" height="62px" />
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
        <div className="row">
          <div
            className="offset-xl-1 col-10 col-sm-9 col-md-7 col-lg-6 col-xl-5"
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        </div>
      </div>
      <div
        className={classNames('hero-section__image', {
          'hero-section__image--offset': offset,
        })}
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
    <div className="container d-md-none">
      <div className="row">
        <div
          className="col-12 col-md-8 mx-auto"
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </div>
    </div>
  </section>
);

HeroSection.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  copy: PropTypes.string,
  offset: PropTypes.bool,
};

export default HeroSection;
