import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { selectAdvisorQuestions } from '../../../../Redux/FormDataRedux';

const InvestmentLineupAccordion = ({ title, lineup }) => {
  const [investmentLineupVisibility, setInvestmentLineupVisibility] =
    useState(false);

  const toggleInvestmentLineup = () => {
    setInvestmentLineupVisibility((prevState) => !prevState);
  };

  return (
    <div className="mb-5">
      <button
        type="button"
        className="button-text my-2 fs-p fw-bold"
        onClick={() => {
          toggleInvestmentLineup();
        }}
      >
        {title}
        <i
          className={classNames('fas ml-2 text-dusty', {
            'fa-plus-circle': !investmentLineupVisibility,
            'fa-minus-circle': investmentLineupVisibility,
          })}
          aria-hidden
        />
      </button>

      <ul
        className={classNames('investment-list mb-4 d-print-block', {
          'd-block': investmentLineupVisibility,
          'd-none': !investmentLineupVisibility,
        })}
      >
        {lineup.map((item) => (
          <li
            key={item.title.replace(/[^\w]/gi, '')}
            className="investment-list__item fs-p"
          >
            <a
              href={item.link}
              target="_blank"
              className="investment-list__link"
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

InvestmentLineupAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  lineup: PropTypes.any.isRequired,
};

const mapStateToProps = (store) => ({
  advisorInfo: selectAdvisorQuestions(store),
});

export default connect(mapStateToProps)(InvestmentLineupAccordion);
