import React from 'react';
import PropTypes from 'prop-types';
import { Question } from '../FormWithSteps';

const RowTpaComp = ({ data, handleQuestionChange }) => {
  const showFixedAmount =
    data.tpaCompensationType.value ===
    data.tpaCompensationType.options[0].value;

  const showBpsAmount =
    data.tpaCompensationType.value ===
    data.tpaCompensationType.options[1].value;

  return (
    <div className="row justify-content-center mb-5">
      <div className="col-md-5 mb-md-5">
        <h4 className="min-h-50">What are the TPA fees? (optional)</h4>
        <Question
          type={data.tpaSetupFee.type}
          thousandSeparator
          inputClass="srp-form-input form-control"
          question={{
            ...data.tpaSetupFee,
            label: `${data.tpaSetupFee.label} ${
              data.tpaSetupFee.required ? '(Required)' : '(Optional)'
            }`,
          }}
          handleQuestionChange={handleQuestionChange}
        />
        <Question
          type={data.annualTpaFee.type}
          thousandSeparator
          inputClass="srp-form-input form-control"
          question={{
            ...data.annualTpaFee,
            label: `${data.annualTpaFee.label} ${
              data.annualTpaFee.required ? '(Required)' : '(Optional)'
            }`,
          }}
          handleQuestionChange={handleQuestionChange}
        />
        <Question
          type={data.annualTpaPerHeadFee.type}
          thousandSeparator
          inputClass="srp-form-input form-control"
          question={{
            ...data.annualTpaPerHeadFee,
            label: `${data.annualTpaPerHeadFee.label} ${
              data.annualTpaPerHeadFee.required ? '(Required)' : '(Optional)'
            }`,
          }}
          handleQuestionChange={handleQuestionChange}
        />
      </div>
      <div className="offset-md-1 col-md-5 mb-5">
        <h4>Will a TPA fee be paid from plan assets? (optional)</h4>
        <p className="fs-p mb-2">(paid quarterly)</p>
        <Question
          type={data.tpaCompensationType.type}
          question={data.tpaCompensationType}
          handleQuestionChange={(name, updateValue, valueFormatted) => {
            handleQuestionChange(name, updateValue, valueFormatted);
          }}
          labelClass="fs-disclaimer-print text-left"
          radioWrapper="srp-form-toggle text-left"
        />

        {showFixedAmount && (
          <Question
            type={data.fixedAmount.type}
            inputClass="srp-form-input form-control"
            labelClass="foo fs-disclaimer-print text-left"
            radioWrapper="srp-form-toggle text-left"
            question={{
              ...data.fixedAmount,
              label: `${data.fixedAmount.label} ${
                data.fixedAmount.required ? '(Required)' : '(Optional)'
              }`,
            }}
            handleQuestionChange={handleQuestionChange}
          />
        )}
        {showBpsAmount && (
          <Question
            type={data.bpsAmount.type}
            thousandSeparator
            inputClass="srp-form-input form-control"
            labelClass="fs-disclaimer-print text-left"
            question={{
              ...data.bpsAmount,
              label: `${data.bpsAmount.label} ${
                data.bpsAmount.required ? '(Required)' : '(Optional)'
              }`,
            }}
            handleQuestionChange={handleQuestionChange}
          />
        )}
      </div>
    </div>
  );
};

RowTpaComp.propTypes = {
  data: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default RowTpaComp;
