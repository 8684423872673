import React from 'react';

const ParticipantCommunication = () => (
  <div className="py-4 px-5">
    <h5 className="fs-h5 my-2 pt-2">Participant communication</h5>
    <p className="fs-p">
      We create required participant notifications for you and make them easy to
      distribute.
    </p>
  </div>
);

export default ParticipantCommunication;
