import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectAdvisorQuestions,
  selectAdvisors,
} from '../../../Redux/FormDataRedux';
import {
  Question,
  NextStepBtn,
  checkValidityOnQuestionArray,
  restrictedState,
} from '../../../common/FormWithSteps';
import Dropdown from '../../../common/FormWithSteps/Question/Dropdown';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../common/FormWithSteps/Utilities';
import { selectEmployerQuestions } from '../../../Redux/FormDataRedux/Selectors';

const EmployerQuestions = ({
  role,
  active,
  destination,
  questions,
  previousStep,
  updateAdvisor,
  updateActiveStep,
  updateQuestion,
  history,
  employerInfo,
  advisorInfo,
  hashId,
  putRole,
  putEid,
  putCrd,
  putBrokerEid,
  formData,
  setPlanOriginator,
  putApplicationPanelInfo,
  putBrokerType,
  putExperience,
}) => {
  const {
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    companyName,
    sponsorTitle,
    companyPhone,
    employerStateCode,
    insuranceGroupType,
    hasFp,
    advisorFirmBroker,
    hasTpa,
    sponsorEmployerIdentificationNumber,
  } = questions;

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChangeState = (id, updateValue, valueFormatted) => {
    handleQuestionChange(id, updateValue, valueFormatted);
    updateQuestion({
      name: insuranceGroupType.name,
      value: insuranceGroupType.value,
      valueFormatted: insuranceGroupType.valueFormatted,
      hasError: updateValue === 'TX' ? !insuranceGroupType.value : false,
      dirty: true,
    });
    putBrokerType(
      {
        brokerType: 'iarRia',
      },
      0,
    );
  };

  const showStateErrorMessage =
    restrictedState(employerStateCode.value) &&
    !checkValidityOnQuestionArray(advisorInfo);

  const stateErrorMessage = () => {
    switch (true) {
      case employerStateCode.value === 'GA':
        return 'Your state requires a financial professional to set up a 401(k) plan. Please provide their contact information in the next step.';

      case employerStateCode.value === 'TX' &&
        insuranceGroupType.valueFormatted === '':
        return 'Your state requires a financial professional to set up a 401(k) plan. Please provide their contact information in the next step. An insurance group type must be selected.';

      case employerStateCode.value === 'FL':
        return 'A financial professional with a valid Florida license is required to set up a 401(k) plan. Please provide their contact information in the next step.';

      default:
        return '';
    }
  };

  const submitContactInfo = async () => {
    if (formData.planOriginator === '') {
      const planOriginator = {
        name: `${sponsorFirstName.value} ${sponsorLastName.value}`,
        email: sponsorEmail.value,
        role: 'employer',
      };
      setPlanOriginator(planOriginator);
    }
  };

  const advisors = [
    {
      name: 'advisorNpn',
      updateValue: '16007614',
      valueFormatted: '16007614',
      advisorIndex: 0,
    },
    {
      name: 'advisorEmail',
      updateValue:
        process.env.RAILS_ENV !== 'production'
          ? 'britt.wells+psivet@shiftdsm.com'
          : 'Darby.Affeldt@northstarfinancial.com',
      valueFormatted:
        process.env.RAILS_ENV !== 'production'
          ? 'britt.wells+psivet@shiftdsm.com'
          : 'Darby.Affeldt@northstarfinancial.com',
      advisorIndex: 0,
    },
    {
      name: 'advisorFirstName',
      updateValue: 'Darby',
      valueFormatted: 'Darby',
      advisorIndex: 0,
    },
    {
      name: 'advisorLastName',
      updateValue: 'Affeldt',
      valueFormatted: 'Affeldt',
      advisorIndex: 0,
    },
    {
      name: 'advisorStateCode',
      updateValue: 'WA',
      valueFormatted: 'Washington',
      advisorIndex: 0,
    },
    {
      name: 'floridaLicenseNumber',
      updateValue: 'W412824',
      valueFormatted: 'W412824',
      advisorIndex: 0,
    },
    {
      name: 'advisorZipCode',
      updateValue: '98103',
      valueFormatted: '98103',
      advisorIndex: 0,
    },
    {
      name: 'advisorPhone',
      updateValue: '206-321-6566',
      valueFormatted: '206-321-6566',
      advisorIndex: 0,
    },
    {
      name: 'firmName',
      updateValue: {
        id: '3B00105B56079307D0A0C6',
        label: 'CETERA INVESTMENT ADVISERS LLC',
        value: 'CETERA INVESTMENT ADVISERS LLC',
        broker_dealer_name: 'CETERA INVESTMENT ADVISERS LLC',
        brokerType: 'iarRia',
        compensation: 'variousWithBasisPoints',
        crd: 13572,
        brokerEid: 'L74F8466L',
        selected: false,
        enterpriseId: 'L74F8466L',
      },
      valueFormatted: 'CETERA INVESTMENT ADVISERS LLC',
      advisorIndex: 0,
    },
    {
      name: 'compensation',
      updateValue: 100,
      valueFormatted: '100',
      advisorIndex: 0,
    },
  ];

  const setAdvisor = ({ name, updateValue, valueFormatted, advisorIndex }) => {
    updateAdvisor(
      {
        name,
        dirty: true,
        value: updateValue,
        valueFormatted,
        hasError: checkForErrors(name, updateValue, questions),
      },
      advisorIndex,
    );
    putEid(
      {
        enterpriseId: 'G28G6684U',
      },
      advisorIndex,
    );
    putCrd(
      {
        status: '',
        verificationStatus: '',
        crd: '13572',
      },
      advisorIndex,
    );
    putBrokerEid(
      {
        eid: 'L74F8466L',
        verificationStatus: 'PASS',
      },
      advisorIndex,
    );
  };

  useEffect(() => {
    putRole('psivet');
    putExperience('psivet');
    pushGoogleDataLayer({
      event: 'PsivetPlanDesignView',
      PlanDesignView: 'info_psivet',
    });
    handleQuestionChange(
      questions.advisorCompensation.name,
      'option_2',
      questions.advisorCompensation.options[1].label,
    );
    updateQuestion({
      name: questions.advisorFee.name,
      value: '',
      hasError: false,
      dirty: true,
      valueFormatted: '',
    });
    updateQuestion({
      name: questions.investmentLineup.name,
      value: questions.investmentLineup.options[0].value,
      hasError: false,
      dirty: true,
      valueFormatted: questions.investmentLineup.options[0].label,
    });
    updateQuestion({
      name: hasTpa.name,
      value: false,
      hasError: false,
      dirty: true,
      valueFormatted: hasTpa.options[1].valueFormatted,
    });
    handleQuestionChange(
      questions.advisorType.name,
      questions.advisorType.options[1].label,
      questions.advisorType.options[1].label,
    );
    handleQuestionChange('advisorPoints', 'option_2', '50');
    handleQuestionChange(hasFp.name, true, hasFp.options[0].label);
    handleQuestionChange(
      questions.firmNameFreeForm.name,
      'North Star Resource Group',
      'North Star Resource Group',
    );
    updateQuestion({
      name: advisorFirmBroker.name,
      value: {
        id: '3B00105B56079307D0A0C6',
        label: 'CETERA INVESTMENT ADVISERS LLC',
        value: 'CETERA INVESTMENT ADVISERS LLC',
        broker_dealer_name: 'CETERA INVESTMENT ADVISERS LLC LLC',
        brokerType: 'iarRia',
        compensation: 'variousWithBasisPoints',
        crd: 13572,
        brokerEid: 'L74F8466L',
        selected: false,
        enterpriseId: 'L74F8466L',
      },
      hasError: false,
      dirty: true,
      valueFormatted: 'CETERA INVESTMENT ADVISERS LLC',
    });
    advisors.map((advisor) => setAdvisor(advisor));
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <div className="row h-100">
        <div className="col-md-10 d-flex flex-column justify-content-between mx-auto my-md-auto">
          <h3 className="fs-form-q text-center pb-5 px-3">
            Tell us about yourself.
          </h3>
          <p className="text-center">
            This information is used to create legal documents. Please ensure it
            is correct.
          </p>
          <fieldset>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  type={sponsorFirstName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorFirstName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorFirstName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
              <div className="col-md">
                <Question
                  role={role}
                  type={sponsorLastName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorLastName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorLastName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  type={sponsorEmail.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorEmail}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorEmail.hasError && <small>Question is required</small>}
                </Question>
              </div>
              <div className="col-md">
                <Question
                  role={role}
                  uniqueId="employerCompany"
                  type={companyName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={companyName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {companyName.hasError && <small>Question is required</small>}
                </Question>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  type={sponsorTitle.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorTitle}
                  handleQuestionChange={handleQuestionChange}
                />
              </div>
              <div className="col-md">
                <div
                  className={classNames('mb-3 form-group', {
                    'has-error': companyPhone.hasError,
                  })}
                >
                  <label
                    className="fs-form-label fw-bold"
                    htmlFor={companyPhone.name}
                  >
                    {companyPhone.label}
                  </label>
                  <PatternFormat
                    value={companyPhone.value}
                    format="###-###-####"
                    mask="_"
                    className={classNames('srp-form-input form-control', {
                      'is-invalid': companyPhone.hasError,
                      'is-dirty': companyPhone.dirty,
                    })}
                    id={companyPhone.name}
                    name={companyPhone.name}
                    required
                    onValueChange={(values) => {
                      handleQuestionChange(
                        companyPhone.name,
                        values.value,
                        values.formattedValue,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <label
                    className="fs-form-label fw-bold"
                    htmlFor={employerStateCode.name}
                  >
                    Your company’s state of business
                  </label>
                  <select
                    id={employerStateCode.name}
                    name="state"
                    autoComplete="address-level1"
                    value={employerStateCode.value}
                    className="custom-select srp-form-input form-control form-select"
                    onChange={(selected) => {
                      handleQuestionChangeState(
                        employerStateCode.name,
                        selected.target.value,
                        selected.target.value,
                      );
                    }}
                  >
                    <option value="select" disabled hidden>
                      Select
                    </option>

                    {employerStateCode.options.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {showStateErrorMessage && (
                    <p className="fs-disclaimer lh-125 mt-2 text-danger">
                      {stateErrorMessage()}
                    </p>
                  )}
                </div>
              </div>
              {employerStateCode.value === 'TX' && (
                <div className="col-md">
                  <Dropdown
                    name={insuranceGroupType.name}
                    label={insuranceGroupType.label}
                    labelClass="fs-form-label fw-bold"
                    inputClass="srp-form-dropdown form-control"
                    options={insuranceGroupType.options}
                    value={insuranceGroupType.value}
                    required
                    hasError={insuranceGroupType.hasError}
                    handleQuestionChange={(id, updateValue) =>
                      handleQuestionChange(id, updateValue, updateValue.value)
                    }
                  />
                </div>
              )}
              <div className="col-md-6">
                <Question
                  role={role}
                  type={sponsorEmployerIdentificationNumber.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorEmployerIdentificationNumber}
                  handleQuestionChange={handleQuestionChange}
                  allowLeadingZeros
                >
                  {sponsorEmployerIdentificationNumber.hasError && (
                    <small>Question is required</small>
                  )}
                  <button
                    type="button"
                    className="srp-button__link"
                    onClick={() =>
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: 'More info',
                        additionalInformation: `The Employer Identification Number (EIN) will be used as your business’ unique identifier.`,
                      })
                    }
                  >
                    More info
                  </button>
                </Question>
              </div>
            </div>
          </fieldset>
          <div className="fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(employerInfo)}
              onClick={() => {
                submitContactInfo();
                pushGoogleDataLayer({
                  event: 'PlanDesignQuestion',
                  PlanDesignEmployerState: employerStateCode.value,
                });
                if (restrictedState(employerStateCode.value)) {
                  if (checkValidityOnQuestionArray(advisorInfo)) {
                    updateActiveStep(destination(), 'forward');
                    return false;
                  }
                  if (hasFp.value) {
                    updateActiveStep(destination(), 'forward');
                    return false;
                  }
                  history.push({
                    pathname: '/requires-license',
                  });
                  return false;
                }
                if (hashId !== '') {
                  updateActiveStep('step_1', 'forward');
                } else updateActiveStep(destination(), 'forward');
                return false;
              }}
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

EmployerQuestions.propTypes = {
  formData: PropTypes.object.isRequired,
  role: PropTypes.string,
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  updateAdvisor: PropTypes.func.isRequired,
  history: PropTypes.object,
  employerInfo: PropTypes.array.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  hashId: PropTypes.string,
  putRole: PropTypes.func.isRequired,
  putEid: PropTypes.func.isRequired,
  putBrokerEid: PropTypes.func.isRequired,
  putCrd: PropTypes.func.isRequired,
  setPlanOriginator: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  putBrokerType: PropTypes.func.isRequired,
  putExperience: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  employerInfo: selectEmployerQuestions(store),
  advisorInfo: selectAdvisorQuestions(store),
  advisors: selectAdvisors(store),
});
const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  updateAdvisor: (obj, index) => {
    dispatch(FormDataRedux.updateAdvisor(obj, index));
  },
  putRole: (role) => {
    dispatch(FormDataRedux.putRole(role));
  },
  putEid: (obj, index) => {
    dispatch(FormDataRedux.putEid(obj, index));
  },
  putBrokerEid: (obj, index) => {
    dispatch(FormDataRedux.putBrokerEid(obj, index));
  },
  putCrd: (obj, index) => {
    dispatch(FormDataRedux.putCrd(obj, index));
  },
  setPlanOriginator: (planOriginator) => {
    dispatch(FormDataRedux.setPlanOriginator(planOriginator));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  putBrokerType: (obj, index) => {
    dispatch(FormDataRedux.putBrokerType(obj, index));
  },
  putExperience: (experience) => {
    dispatch(FormDataRedux.putExperience(experience));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployerQuestions),
);
