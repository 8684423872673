export const whichSafeHarbor = {
  id: 'q6aI1c1a',
  type: 'dropdown',
  name: 'whichSafeHarbor',
  paradigmAlias: 'whichSafeHarbor',
  user: {},
  stepName: 'a_step_6_a_i_1_c_1_a',
  label: 'Which safe harbor option will be used?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: '3% non-elective Safe Harbor',
      value: '3PercNonelectiveSafeharbor',
      valueFormatted: '3% Non-elective',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: 'Basic match:  100% up to 3%, 50% up to the next 2%',
      value: 'basicMatch100to3-50toNext2',
      valueFormatted: 'Basic match:  100% up to 3%, 50% up to the next 2%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: 'Enhanced match:  100% up to 4%',
      value: 'enhancedMatch100to4',
      valueFormatted: 'Enhanced match:  100% up to 4%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 4,
      user: {},
      label: 'Enhanced match:  100% up to 5%',
      value: 'enhancedMatch100to5',
      valueFormatted: 'Enhanced match:  100% up to 5%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 5,
      user: {},
      label: 'Enhanced match:  100% up to 6%',
      value: 'enhancedMatch100to6',
      valueFormatted: 'Enhanced match:  100% up to 6%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Safe harbor plan',
  reviewScreenTitle: 'Safe harbor plan type',
  optionDescription: '',
  description: 'This is the safe harbor contribution the plan will utilize.',
};
