import React from 'react';
import PropTypes from 'prop-types';

const PrintPlanTable = ({
  planOptionQuestions,
  contributionPlanOptionQuestions,
}) => {
  const planEffectiveDate = Object.values(planOptionQuestions).find(
    (question) =>
      (question.name === 'planEffectiveDate' ||
        question?.paradigmAlias === 'planEffectiveDate') &&
      question.dirty,
  );

  const showQuestion = (key) =>
    (planOptionQuestions[key].name !== 'planEffectiveDate' ||
      planOptionQuestions[key]?.paradigmAlias === 'planEffectiveDate') &&
    planOptionQuestions[key].dirty;

  return (
    <div className="container d-none d-print-block">
      <table className="table table-bordered">
        <tbody>
          {Object.keys(planOptionQuestions).map(
            (key) =>
              showQuestion(key) && (
                <tr key={planOptionQuestions[key].name}>
                  <td className="w-25">
                    <p>{planOptionQuestions[key].sideCardTitle}</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: planOptionQuestions[key].optionDescription,
                      }}
                    />
                  </td>
                  <td className="w-25 text-center">
                    <strong className="d-block">
                      {planOptionQuestions[key].valueFormatted ||
                        planOptionQuestions[key].value}
                    </strong>
                  </td>
                  <td className="w-25" aria-label="Description">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: planOptionQuestions[key].description,
                      }}
                    />
                  </td>
                </tr>
              ),
          )}
          {contributionPlanOptionQuestions.safeHarbor.dirty && (
            <tr key={contributionPlanOptionQuestions.safeHarbor.name}>
              <td className="w-25">
                <p>
                  {contributionPlanOptionQuestions.safeHarbor.sideCardTitle}
                </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      contributionPlanOptionQuestions.safeHarbor
                        .optionDescription,
                  }}
                />
                {contributionPlanOptionQuestions.stretchSafeHarbor.dirty && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        contributionPlanOptionQuestions.stretchSafeHarbor
                          .optionDescription,
                    }}
                  />
                )}
              </td>
              <td className="w-25 text-center">
                <strong className="d-block">
                  {contributionPlanOptionQuestions.safeHarbor.valueFormatted}
                </strong>
                {contributionPlanOptionQuestions.stretchSafeHarbor.dirty && (
                  <strong className="d-block">
                    {
                      contributionPlanOptionQuestions.stretchSafeHarbor
                        .valueFormatted
                    }
                  </strong>
                )}
              </td>
              <td className="w-25">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      contributionPlanOptionQuestions.safeHarbor.description,
                  }}
                />
                {contributionPlanOptionQuestions.stretchSafeHarbor.dirty && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        contributionPlanOptionQuestions.stretchSafeHarbor
                          .description,
                    }}
                  />
                )}
              </td>
            </tr>
          )}
          <tr key={planEffectiveDate.name}>
            <td className="w-25">
              <p>{planEffectiveDate.sideCardTitle}</p>
            </td>
            <td className="w-25 text-center">
              <strong className="d-block">
                {planEffectiveDate.valueFormatted}
              </strong>
            </td>
            <td className="w-25" aria-label="Description">
              <div
                dangerouslySetInnerHTML={{
                  __html: planEffectiveDate.description,
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

PrintPlanTable.propTypes = {
  planOptionQuestions: PropTypes.object.isRequired,
  contributionPlanOptionQuestions: PropTypes.object.isRequired,
};

export default PrintPlanTable;
