import { checkNpnValue } from './checkNpnValue';
import { isEligible } from './isEligible';
import { isEmptyOrSpaces } from './isEmptyOrSpaces';

export const checkForErrors = (questionId, value, questions) => {
  switch (questionId) {
    case 'numberOfEmployees':
      return !isEligible(value);
    case 'advisorFee':
    case 'fixedAmount':
      return value === '' || value > 5000;
    case 'tpaDistributionFee':
    case 'tpaHardshipFee':
    case 'tpaLoanMaintenanceFee':
    case 'tpaLoanOriginationFee':
    case 'tpaLoanReamortizationFee':
    case 'tpaRmdFee':
    case 'tpaQdroFee':
      return value === '' || value > 500;
    case 'bpsAmount':
      return value === '' || value > 250;
    case 'advisorNpn':
    case 'insuranceAgentNpn':
      return checkNpnValue(value, questions);
    case 'advisorFirmBroker':
      return Object.entries(value).length === 0;
    case 'employerStateCode':
      return value === 'select';
    case 'advisorPhone':
    case 'companyPhone':
    case 'tpaPhone':
      return value.length < 10;
    case 'advisorEmail':
    case 'sponsorEmail':
    case 'tpaEmail':
      return !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value.toLowerCase(),
      );
    case 'advisorZipCode':
    case 'tpaZipCode':
      return !/^\d{5}$/.test(value);
    case 'floridaLicenseNumber':
      return !/^[a-zA-Z]\d{4,6}$/.test(value);
    case 'sponsorTitle':
      return false;
    case 'employerIdentificationNumber':
      return !/^[a-zA-Z0-9-]{9,10}$/.test(value);
    case 'sponsorEmployerIdentificationNumber':
      return !/^\d{9}$/.test(value);
    case 'advisorStateCode':
      return value === 'select' || value === 'Select';
    default:
      return isEmptyOrSpaces(value);
  }
};
