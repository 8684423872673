import React from 'react';
import { Link } from 'react-router-dom';

const CostSavingsSection = () => (
  <section
    className="section section_py-5"
    style={{
      background:
        'linear-gradient(0deg, rgba(242,242,242,1) 0%, rgba(242,242,242,0.5) 100%)',
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-6 col-md-8 mx-auto">
          <h3 className="fs-h3 text-center mb-3">
            Cost savings with flat-fee 401(k) plan recordkeeping pricing
          </h3>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-7 col-md-8 mx-auto">
          <h5
            className="fs-h5 fw-normal text-center mb-3"
            style={{ lineHeight: '165%' }}
          >
            Other 401(k) plans may increase recordkeeping fees to business
            owners as the total account balances increase. Not with the Simply
            Retirement by Principal
            <small>
              <sup>&reg;</sup>
            </small>{' '}
            401(k) plan with OneDigital advisory services.
          </h5>
        </div>
      </div>

      <p className="text-center my-5 pt-3">
        <Link
          className="srp-button srp-button__primary px-5"
          to="/onedigital/plans#begin"
        >
          What would a plan for my company look like?
        </Link>
      </p>
    </div>
  </section>
);

export default CostSavingsSection;
