import React from 'react';
import PropTypes from 'prop-types';
import {
  NextStepBtn,
  PrevStepBtn,
  Question,
  checkValidityOnQuestionArray,
} from '../../../../../common/FormWithSteps';
import AdvisorAuthHeader from '../../AdvisorAuthHeader';
import Dropdown from '../../../../../common/FormWithSteps/Question/Dropdown';

const WarningModal = ({ copy }) => (
  <div className="text-center my-5">
    <h1 className="fs-h1 text-center mb-3">
      Working with a third party administrator
    </h1>
    <div
      className="fs-p text-center mb-4 lh-125"
      dangerouslySetInnerHTML={{ __html: copy }}
    />
  </div>
);
WarningModal.propTypes = {
  copy: PropTypes.string.isRequired,
};

const Template = ({
  active,
  previousStep,
  questions,
  updateActiveStep,
  role,
  tpaInfo,
  handleQuestionChange,
  handleQuestionChangeState,
  nextStepAction,
  advisorQuestions,
  userRole,
  putApplicationPanelInfo,
  createModal,
}) => {
  const {
    employerStateCode,
    insuranceGroupType,
    companyName,
    hasTpa,
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
  } = questions;

  return (
    <div className="container-fluid--sitewidth h-100">
      <AdvisorAuthHeader step={1} />
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <fieldset id="stepClientInfo">
            <div className="row">
              <div className="col-md">
                <p className="text-center">
                  The client’s information will be used in legal documents.
                  Please ensure the information entered is correct.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  userRole={userRole}
                  type={sponsorFirstName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorFirstName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorFirstName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
              <div className="col-md">
                <Question
                  role={role}
                  userRole={userRole}
                  type={sponsorLastName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorLastName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorLastName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  type={sponsorEmail.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorEmail}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorEmail.hasError && <small>Question is required</small>}
                </Question>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  type={companyName.type}
                  labelClass="fs-form-label fw-bold text-center"
                  inputClass="srp-form-input form-control"
                  question={companyName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {companyName.hasError && <small>Question is required</small>}
                  <button
                    type="button"
                    className="srp-button__link"
                    onClick={() =>
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: 'Need clarification?',
                        additionalInformation: `Please provide the legal business name for use on plan documents. The legal name is the formal, official name that identifies the company with the government (for example, on the company’s tax ID paperwork and tax returns). If the company is a corporation, the name may contain the designation “Inc.” If it’s a Limited Liability Company, the name may conclude with "LLC."`,
                      })
                    }
                  >
                    Need clarification?
                  </button>
                </Question>
              </div>
              <div className="col-md">
                <Question
                  role={role}
                  userRole={userRole}
                  type={employerStateCode.type}
                  labelClass="fs-form-label fw-bold text-center"
                  inputClass="srp-form-input form-control form-select"
                  question={employerStateCode}
                  handleQuestionChange={handleQuestionChangeState}
                >
                  {companyName.hasError && <small>Question is required</small>}
                  <button
                    type="button"
                    className="srp-button__link"
                    onClick={() =>
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: 'Some states require a license.',
                        additionalInformation: `
                          <p>The financial professional must be licensed to sell this product in the state
                          where the purchasing business is located.</p>
                          <p>Group annuity contracts are treated as an insurance product and require appropriate
                          insurance licensing and/or security registration to receive compensation.
                          Our credential validation process will determine if you meet the appropriate
                          criteria. If additional information is required, we will work with you to ensure
                          the appropriate requirements (differentiated by state) are met.</p>`,
                      })
                    }
                  >
                    Some states require a license.
                  </button>
                </Question>
              </div>
              {employerStateCode.value === 'TX' && (
                <div className="col-md">
                  <Dropdown
                    name={insuranceGroupType.name}
                    label={insuranceGroupType.label}
                    labelClass="fs-form-label fw-bold"
                    inputClass="srp-form-dropdown form-control"
                    options={insuranceGroupType.options}
                    value={insuranceGroupType.value}
                    required
                    hasError={insuranceGroupType.hasError}
                    handleQuestionChange={handleQuestionChange}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-auto mx-auto">
                {!checkValidityOnQuestionArray(tpaInfo) && (
                  <div className="form-group">
                    <span className="fs-form-label fw-bold">
                      {hasTpa.label}
                    </span>
                    <div className="my-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="hasTpaOption1"
                          value={hasTpa.options[0].value}
                          name={hasTpa.name}
                          checked={hasTpa.value === hasTpa.options[0].value}
                          onChange={(input) => {
                            createModal(
                              <WarningModal copy="<p>When working with a TPA, the Financial Professional will select the investment lineup and desired compensation.  Upon completion, you’ll be asked to send this proposal to the TPA (using their email address) so they can finalize plan design for this opportunity.</p> <p>Upon completion of those tasks, you or the third party administrator can share the final proposal with the business owner.</p>" />,
                            );
                            handleQuestionChange(
                              hasTpa.name,
                              input.target.checked,
                              hasTpa.options[0].label,
                            );
                          }}
                        />
                        <label
                          className="form-check-label mr-2"
                          htmlFor="hasTpaOption1"
                        >
                          <strong>Yes</strong>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="hasTpaOption2"
                          value={hasTpa.options[1].value}
                          name={hasTpa.name}
                          checked={hasTpa.value === hasTpa.options[1].value}
                          onChange={(input) => {
                            handleQuestionChange(
                              hasTpa.name,
                              !input.target.checked,
                              hasTpa.options[1].label,
                            );
                          }}
                        />
                        <label
                          className="form-check-label mr-2"
                          htmlFor="hasTpaOption2"
                        >
                          <strong>No</strong>
                        </label>
                      </div>
                      <small>
                        <button
                          type="button"
                          className="srp-button__link"
                          onClick={() =>
                            putApplicationPanelInfo({
                              hasVisibility: true,
                              heading: 'Why are we asking?',
                              additionalInformation: `Third party administrators (TPAs) help complete key plan details. If your client is working with a TPA, you'll be asked to share this proposal with them after your portion of the plan is complete.`,
                            })
                          }
                        >
                          Why are we asking?
                        </button>
                      </small>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(advisorQuestions)}
              onClick={() => nextStepAction()}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

Template.propTypes = {
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  role: PropTypes.string,
  userRole: PropTypes.string,
  handleQuestionChange: PropTypes.func.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  advisorQuestions: PropTypes.array.isRequired,
  handleQuestionChangeState: PropTypes.func.isRequired,
  nextStepAction: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
};

export default Template;
