import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Question,
  NextStepBtn,
  WithInfoPanel,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';

export const StepQuestion = ({
  role,
  active,
  questionId,
  questions,
  updateActiveStep,
  updateQuestionById,
  googleData,
}) => {
  const { hasRetirementPlan } = questions;
  const [visibleInfoPanel, setVisibility] = useState(false);
  const toggleInfoPanel = () => {
    setVisibility((prevState) => !prevState);
  };
  const history = useHistory();
  const goToLetsTalk = () => {
    history.replace({
      pathname: '/lets-talk',
    });
  };

  const { value, dirty, hasError } = questions[questionId];
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };

  const previousStepDestination = () => {
    if (hasRetirementPlan.value === 'no' || questions.hasFp.value) {
      updateActiveStep('hasRetirementPlan', 'back');
      return false;
    }
    updateActiveStep('isCurrentPrincipalPlan', 'back');
    return false;
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <WithInfoPanel
        visibleInfoPanel={visibleInfoPanel}
        setVisibility={setVisibility}
        heading={questions[questionId].heading}
        content={questions[questionId].content}
        toggleInfoPanel={toggleInfoPanel}
      >
        <Question
          role={role}
          type={questions[questionId].type}
          labelClass="fs-form-q fw-bold text-center mb-4"
          inputClass="srp-form-input form-control"
          radioWrapper="srp-form-toggle"
          thousandSeparator
          question={questions[questionId]}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!questions[questionId].heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        {questions[questionId].heading && (
          <p className="text-center mb-lg-auto">
            <button
              type="button"
              className="srp-button srp-button__link mx-auto"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Need clarification?
            </button>
          </p>
        )}
        <p className="fws__control">
          <NextStepBtn
            disabled={
              questions[questionId].required ? !(dirty && !hasError) : false
            }
            onClick={() => {
              setVisibility(false);
              if (value === 'radio_btn_2') {
                goToLetsTalk();
                return false;
              }
              updateActiveStep('typeOfOrganization', 'forward');
              return false;
            }}
          />
        </p>
      </WithInfoPanel>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          setVisibility(false);
          previousStepDestination();
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

StepQuestion.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  googleData: PropTypes.object,
};

export default StepQuestion;
