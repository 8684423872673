export const getVestingScheduleID = async (vestingScheduleMatch) => {
  if (vestingScheduleMatch.valueFormatted === '4-year graded') {
    return 22;
  }
  if (vestingScheduleMatch.valueFormatted === '5-year graded') {
    return 3;
  }
  if (vestingScheduleMatch.valueFormatted === '6-year graded') {
    return 2;
  }
  if (vestingScheduleMatch.valueFormatted === '3-year cliff') {
    return 6;
  }
  if (vestingScheduleMatch.valueFormatted === '2-year cliff = 0%, 100%') {
    return 5;
  }
  return 1;
};
