import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
  selectCanBeAnonymous,
} from '../../../../../Redux/FormDataRedux';

export const DefaultCheckList = ({ putApplicationPanelInfo }) => (
  <>
    <h3 className="fs-form-q text-center pb-4 px-md-3">
      Before you begin, make sure Simply Retirement by Principal
      <small>
        <sup>®</sup>
      </small>{' '}
      fits your client’s needs.
    </h3>
    <div className="row">
      <div className="col-md-10 col-lg-8 mx-auto">
        <ul className="fa-ul">
          <li className="fs-p mb-1">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            New 401(k) plans only.
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Businesses with fewer than 100 employees.
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Only business owners can purchase the plan.
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Each eligible employee must have an email address and access to the
            internet.
          </li>
        </ul>
        <h4 className="fs-p mb-1 mb-md-2">
          We’ll also ask a few things about you and your client’s business:
        </h4>
        <ul className="fa-ul mb-0">
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Legal company name
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            State of company headquarters
          </li>
          <li className="fs-p mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            Estimated number of employees
          </li>
          <li className="fs-h5 mb-1 mb-md-2">
            <span className="fa-li">
              <i className="fa-solid fa-check text-primary" aria-hidden />
            </span>
            <button
              type="button"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: `Simply Retirement by Principal<sup><small>®</small></sup> is a group variable annuity product offering which requires both FINRA registration and variable annuity line insurance licensing in order for a financial professional to receive compensation through the retirement plan.  Principal will research required financial professional licensing and registration beginning with the National Producer Number (NPN) which all variable annuity insurance licensed professionals are assigned.`,
                });
              }}
              className="btn p-0 fs-p"
            >
              <u>National producer number (NPN)</u>*
            </button>
          </li>
        </ul>
      </div>
    </div>
  </>
);

DefaultCheckList.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
  canBeAnonymous: selectCanBeAnonymous(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultCheckList);
