export const autoEnrollment = {
  id: 'q4',
  type: 'radio_condensed',
  name: 'autoEnrollment',
  user: {},
  stepName: 'step_4',
  label: 'Do you want to use auto enrollment?',
  labelClass: 'fs-form-q fw-bold mb-3 text-center',
  infoLabel:
    'Automatic enrollment deductions are pre-tax. If an employee wants to make after-tax Roth contributions, they can opt out of auto-enrollment and choose a Roth contribution deferral amount.',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      icon: 'icon-check-o',
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      icon: 'icon-close-o',
      customStyle: 'radio_cancel',
    },
  ],
  heading: 'What’s automatic enrollment?',
  content:
    'Automatic enrollment is a plan provision which automatically enrolls eligible employees (participants) into the retirement plan at a specified salary deferral percentage unless the participant elects otherwise by completing an enrollment form in the enrollment meeting or on the participant website. This can help increase participation, simplify administration, and help employees save for retirement. As an added benefit, the SECURE 2.0 Act offers a new tax credit for small businesses when first implementing automatic enrollment using an eligible automatic contribution arrangement (EACA). It’s $500 annually for 3 years. This is in addition to the plan start-up credit. Consult with a tax advisor or other appropriate counsel for details.',
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasError: false,
  sideCardTitle: 'Use auto enrollment',
  reviewScreenTitle: 'Use auto enrollment',
  optionDescription: '',
  description:
    "If auto enrollment is selected, once the employee meets the plan's age and service requirements, they will be automatically enrolled at a default pre-tax contribution percentage set by the business owner and begin making contributions to the plan immediately.* Participants can change their deferral or opt out at any time. They'll also have contributions directed to the plan's QDIA (Qualified Default Investment Alternative) unless they elect otherwise.  *Regardless of whether they meet the plan's age and service requirements, union employees, nonresident aliens, and independent contractors aren't allowed in the plan.",
};
