import React from 'react';
import { HeroWrapper } from './index.styles';

const ManageCookiesPreferences = () => (
  <div className="mb-5">
    <HeroWrapper className="hero mb-5 pt-5">
      <div className="container-fluid container-fluid--sitewidth">
        <div className="row justify-content-center py-5">
          <h1 className="fs-h1 text-center fw-bold py-5 col-xl-6 col-lg-8 col-md-10 mt-5">
            Manage cookie preferences
          </h1>
        </div>
      </div>
    </HeroWrapper>
    <div className="container pb-5">
      <div className="row pb-5">
        <div className="col-md-9 mx-auto">
          <p className="fs-copy">
            Cookies are used to store information on your computer and are a way
            to have your web browser “remember” specific bits of information
            about your previous visits to our site. They allow you to access
            secured information, conduct secured transactions, and take
            advantage of promotional opportunities. They are designed to help
            you have a better user experience within our website, and we use the
            information to improve our site content and site functionality.
          </p>

          <p className="fs-copy">
            Different websites store the information in cookies differently. Our
            site uses both “session” and “persistent” cookies. Session cookies
            are temporary and expire when you leave our website or are inactive
            for a specified length of time. Persistent cookies store your
            preferences for a site and are read by your browser each time you
            visit the website.
          </p>

          <p className="fs-copy">
            You can manage your selected cookie preferences in the cookie
            preference center.
          </p>

          <p className="fs-copy">
            <button
              type="button"
              id="ot-sdk-btn"
              className="ot-sdk-show-settings srp-button srp-button__primary"
            >
              Cookie Settings
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ManageCookiesPreferences;
