import styled from 'styled-components';

export const SectionStyled = styled.div`
  background-color: #fff;
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.05);
  @media (min-width: 768px) {
    border-radius: 50px;
  }
`;

export const CornerStyled = styled.div`
  position: relative;
  padding-top: 150px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 100px;
  background: linear-gradient(
    90deg,
    #eff0f1 0%,
    rgba(247, 247, 247, 0) 123.56%
  );
  @media (min-width: 768px) {
    padding-top: 130px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 0;
    border-radius: 0px 50px;
  }
`;

export const CircleStyled = styled.div`
  position: absolute;
  top: -80px;
  left: 50%;
  width: 200px;
  height: 200px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 125%;
  background-color: #002855;
  display: flex;
  align-items: center;
  border-radius: 50%;
  padding: 25px;
  transform: translateX(-50%);
`;
