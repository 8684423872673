import React from 'react';
import PropTypes from 'prop-types';

const IconTimeRewindO = ({ fill, fillAlt }) => (
  <g>
    <path
      fill={fill}
      className="fill"
      d="M30.427 2.616l.89-1.514c.136-.207.136-.482 0-.758C31.18.138 30.905 0 30.632 0h-5.334a.717.717 0 0 0-.615.344c-.137.207-.137.482 0 .758l1.299 2.34 1.367 2.341a.717.717 0 0 0 .616.344.717.717 0 0 0 .615-.344l.89-1.583c5.196 3.235 8.683 9.019 8.683 15.628 0 5.095-2.051 9.638-5.334 12.943-3.282 3.305-7.863 5.37-12.854 5.37-5.06 0-9.573-2.065-12.855-5.37a18.388 18.388 0 0 1-5.333-12.943 18.371 18.371 0 0 1 9.23-15.973c.41-.275.616-.826.342-1.239-.273-.413-.82-.62-1.23-.344C4.103 5.714 0 12.324 0 19.828 0 30.98 8.957 40 20.034 40c11.077 0 20.034-9.019 20.034-20.172a20.432 20.432 0 0 0-9.64-17.212z"
    />
    <path
      fill={fillAlt || fill}
      className="fill"
      d="M10.803 12.324c-.342.344-.342.895 0 1.308l8.89 9.088a.863.863 0 0 0 1.23 0l5.949-5.508c.342-.345.41-.895.068-1.308-.342-.413-.889-.413-1.299-.07l-5.333 4.958-8.205-8.468c-.342-.345-.958-.345-1.3 0z"
    />
  </g>
);

IconTimeRewindO.propTypes = {
  fill: PropTypes.string.isRequired,
  fillAlt: PropTypes.string,
};

export default IconTimeRewindO;
