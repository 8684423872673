import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { ScrollInToView } from '../../../common/VisibilitySensor';

const VideoSection = ({ putApplicationPanelInfo, safeHarborDisclaimer2 }) => (
  <section className="section section_py-5">
    <div className="container">
      <div className="row">
        <div className="offset-lg-1 col-lg-6 d-print-none">
          <ScrollInToView
            renderItem={(inView) => (
              <div
                className={classNames('mb-5', {
                  'fadeIn animated': inView,
                  hidden: !inView,
                })}
              >
                <ReactPlayerLoader
                  accountId="1510329453001"
                  videoId="6274773063001"
                  attrs={{
                    className: 'brightcove-player',
                  }}
                  embedOptions={{ responsive: { aspectRatio: '16:9' } }}
                />
              </div>
            )}
          />
        </div>
        <div className="col-lg-4">
          <ScrollInToView
            renderItem={(inView) => (
              <div
                style={{
                  animationDelay: '0.2s',
                }}
                className={classNames('mb-4', {
                  'fadeIn animated': inView,
                  hidden: !inView,
                })}
              >
                <p className="fs-p text-purple fw-bold">
                  Advantages of a safe harbor 401(k) plan with Simply Retirement
                  by Principal
                  <sup>
                    <small>®</small>
                  </sup>
                  :
                </p>
                <ul>
                  <li className="mb-2">
                    Highly compensated employees or owners can maximize elective
                    deferrals.
                  </li>
                  <li className="mb-2">
                    No complicated non-discrimination testing.
                  </li>
                  <li className="mb-2">
                    Additional profit sharing contributions are allowed with a
                    vesting schedule.
                  </li>
                  <li className="mb-2">
                    Employees may make pre-tax salary deferrals of up to{' '}
                    <button
                      type="button"
                      onClick={() =>
                        putApplicationPanelInfo({
                          hasVisibility: true,
                          additionalInformation: safeHarborDisclaimer2,
                        })
                      }
                      className="text-underline p-0 no-border-button"
                    >
                      $23,000.
                    </button>
                    <sup>
                      <small>*</small>
                    </sup>
                  </li>
                  <li className="mb-2">
                    Highly compensated employees or owners can maximize elective
                    defferals.
                  </li>
                </ul>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  </section>
);

VideoSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  safeHarborDisclaimer2: PropTypes.any,
};

export default VideoSection;
