import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import FormDataRedux, {
  selectRole,
  selectAdvisorQuestions,
  selectHashId,
  selectIsFirmBrokerSplitComp,
} from '../../../Redux/FormDataRedux';
import FormWithSteps, { Step } from '../../../common/FormWithSteps';
import CostEstimator from '../../../common/FormWithSteps/PlanOutLine/CostEstimator';

// Steps
import LetsBegin from '../../SetupPlan/LetsBegin';
import EmployerQuestions from './EmployerQuestions';
import EmployerCheckList from '../../SetupPlan/EmployerQuestions/EmployerCheckList';

import EligibleEmployees from '../../SetupPlan/DefaultPlan/EligibleEmployees';
import AverageSalary from '../../SetupPlan/DefaultPlan/AverageSalary';
import EmployerPaidParticipantFees from '../../SetupPlan/DefaultPlan/EmployerPaidParticipantFees';
import StepQuestion from '../../SetupPlan/DefaultPlan/StepQuestion';
import EnableEmployerContribution from '../../SetupPlan/DefaultPlan/EnableEmployerContribution';
import EmployerContributionMatch from './EmployerContributionMatch';
import AutoDeferralType from '../../SetupPlan/DefaultPlan/AutoDeferralType';
import SafeHarbor from '../../SetupPlan/DefaultPlan/SafeHarbor';
import StretchSafeHarbor from '../../SetupPlan/DefaultPlan/StretchSafeHarbor';
import PlanEffectiveDate from './PlanEffectiveDate';
import { HardshipWithdrawals } from '../../SetupPlan/DefaultPlan/HardshipWithdrawals';
import AutoEnrollment from '../../SetupPlan/DefaultPlan/AutoEnrollment';
import { VestingSchedule } from '../../SetupPlan/DefaultPlan/VestingSchedule';
import { GrandfatherDate } from '../../SetupPlan/DefaultPlan/GrandfatherDate';

export const SetupPlan = ({
  formData,
  role,
  putActiveStep,
  putQuestionById,
  history,
  authenticityToken,
  putBrokerEid,
  hashId,
  resetData,
}) => {
  const { safeHarbor, employerContributionMatch, autoEnrollment } =
    formData.planDesign.questions;
  const [notificationIndicator, setNotificationIndicator] = useState(false);
  const lastLocation = useLastLocation();
  const handleClose = () => {
    if (lastLocation) {
      history.goBack();
    } else {
      history.push('/psivet');
    }
  };
  const goToReviewPlan = () => {
    history.push('/psivet/review-plan');
  };

  const googleData = (key) => ({
    event: 'PsivetPlanDesignView',
    PlanDesignView: key,
  });

  const formSteps = [
    {
      hashId,
      hasSidebar: false,
      planOwner: formData.planOwner,
      createdDate: formData.createdDate,
      updatedDate: formData.updatedDate,
      key: 'begin',
      component: LetsBegin,
      destination: () => 'employerCheckList',
      googleData: googleData('plans'),
    },
    {
      role,
      key: 'employerCheckList',
      hasSidebar: false,
      component: EmployerCheckList,
      destination: () => 'employerQuestions',
      previousStep: 'begin',
      googleData: googleData('checklist_employer'),
    },
    {
      authenticityToken,
      formData,
      role,
      hashId,
      key: 'employerQuestions',
      hasSidebar: false,
      component: EmployerQuestions,
      previousStep: 'employerCheckList',
      destination: () => 'step_1',
    },
    {
      role,
      hashId,
      key: 'step_1',
      showTracker: true,
      questionId: 'numberOfEmployees',
      component: EligibleEmployees,
      hasSidebar: false,
      singleSelection: true,
      destination: (_isEligible, cb) => {
        cb('step_2', 'forward');
      },
      goToReviewPlan,
      previousStep: 'employerQuestions',
      googleData: googleData(`eligible_employees_${role}`),
    },
    {
      role,
      key: 'step_2',
      showTracker: true,
      singleSelection: true,
      questionId: 'averageSalary',
      component: AverageSalary,
      hasSidebar: true,
      destination: () => 'step_3',
      goToReviewPlan,
      previousStep: 'step_1',
      googleData: googleData(`annual_salary_${role}`),
    },
    {
      role,
      key: 'step_3',
      showTracker: true,
      singleSelection: true,
      questionId: 'serviceEligibility',
      component: StepQuestion,
      hasSidebar: true,
      destination: () => 'step_4',
      goToReviewPlan,
      previousStep: 'step_2',
      googleData: googleData(`when_enrolled_${role}`),
    },
    {
      role,
      key: 'step_4',
      showTracker: true,
      singleSelection: true,
      questionId: 'hasGrandfatherDate',
      component: StepQuestion,
      hasSidebar: true,
      destination: () =>
        formData.planDesign.questions.hasGrandfatherDate.value === 'yes'
          ? 'step_5'
          : 'step_6',
      goToReviewPlan,
      previousStep: 'step_3',
      googleData: googleData(`has_grandfather_date_${role}`),
    },
    {
      role,
      key: 'step_5',
      showTracker: true,
      singleSelection: true,
      questionId: 'grandfatherDateHasCustomDate',
      component: GrandfatherDate,
      hasSidebar: true,
      destination: () => 'step_6',
      goToReviewPlan,
      previousStep: 'step_4',
      googleData: googleData(`grandfather_date_${role}`),
    },
    {
      role,
      key: 'step_6',
      showTracker: true,
      singleSelection: true,
      questionId: 'autoEnrollment',
      component: AutoEnrollment,
      hasSidebar: true,
      destination: () => 'step_7',
      goToReviewPlan,
      previousStep:
        formData.planDesign.questions.hasGrandfatherDate.value === 'yes'
          ? 'step_5'
          : 'step_4',
      googleData: googleData(`auto_enrollment_${role}`),
    },
    {
      role,
      key: 'step_7',
      showTracker: true,
      singleSelection: true,
      questionId: 'employerPaidParticipantFees',
      component: EmployerPaidParticipantFees,
      hasSidebar: true,
      destination: () =>
        autoEnrollment.value === 'yes' ? 'step_8' : 'step_10',
      goToReviewPlan,
      previousStep: 'step_6',
      googleData: googleData(`pay_participant_fee_${role}`),
    },
    {
      role,
      key: 'step_8',
      showTracker: true,
      singleSelection: true,
      questionId: 'minimumAutoDeferralPercentage',
      component: EnableEmployerContribution,
      hasSidebar: true,
      destination: () => 'step_9',
      goToReviewPlan,
      previousStep: 'step_7',
      googleData: googleData(`default_contribution_${role}`),
    },
    {
      role,
      key: 'step_9',
      showTracker: true,
      singleSelection: true,
      questionId: 'autoDeferralType',
      component: AutoDeferralType,
      hasSidebar: true,
      destination: () => 'step_10',
      goToReviewPlan,
      previousStep: 'step_8',
      googleData: googleData(`deferral_type_${role}`),
    },
    {
      role,
      key: 'step_10',
      showTracker: true,
      goToReviewPlan,
      questionId: 'employerContributionMatch',
      component: EmployerContributionMatch,
      hasSidebar: true,
      previousStep: autoEnrollment.value === 'yes' ? 'step_9' : 'step_7',
      googleData: googleData(`contribution_match_${role}`),
    },
    {
      role,
      key: 'step_11',
      showTracker: true,
      mergedSelections: true,
      questionId: 'safeHarbor',
      component: SafeHarbor,
      hasSidebar: true,
      destination: () => 'step_12',
      goToReviewPlan,
      previousStep: 'step_10',
      googleData: googleData(`safe_harbor_${role}`),
    },
    {
      role,
      key: 'step_12',
      showTracker: true,
      mergedSelections: true,
      questionId: 'stretchSafeHarbor',
      component: StretchSafeHarbor,
      hasSidebar: true,
      destination: () => nextStepDest(employerContributionMatch, safeHarbor),
      goToReviewPlan,
      previousStep: 'step_11',
    },
    {
      role,
      key: 'step_13',
      showTracker: true,
      mergedSelections: true,
      questionId: 'vestingSchedule',
      component: VestingSchedule,
      hasSidebar: true,
      destination: () => 'step_14',
      goToReviewPlan,
      previousStep: 'step_11',
    },
    {
      role,
      key: 'step_14',
      showTracker: true,
      mergedSelections: true,
      questionId: 'hardshipWithdrawals',
      component: HardshipWithdrawals,
      hasSidebar: true,
      destination: () => 'step_15',
      goToReviewPlan,
      previousStep: prevStepHardship(employerContributionMatch, safeHarbor),
    },
    {
      role,
      key: 'step_15',
      showTracker: true,
      questionId: 'planEffectiveDate',
      component: PlanEffectiveDate,
      hasSidebar: true,
      goToReviewPlan,
      previousStep: 'step_14',
      googleData: googleData(`plan_effective_date_${role}`),
    },
  ];

  return (
    <FormWithSteps
      formInReview={formData.planDesign.formInReview}
      startAtStep={formData.planDesign.startAtStep}
      formId={formData.planDesign.formId}
      activeStep={formData.planDesign.activeStep}
      questions={formData.planDesign.questions}
      putActiveStep={putActiveStep}
      putQuestionById={putQuestionById}
      handleClose={handleClose}
      formData={formData}
      resetData={resetData}
      putBrokerEid={putBrokerEid}
      notificationIndicator={notificationIndicator}
      setNotificationIndicator={setNotificationIndicator}
      hashId={hashId}
      costEstimator={CostEstimator}
    >
      {formSteps.map((step) => (
        <Step name={step.key} {...step} />
      ))}
    </FormWithSteps>
  );
};

SetupPlan.propTypes = {
  formData: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  putActiveStep: PropTypes.func.isRequired,
  putQuestionById: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string,
  hashId: PropTypes.string,
  putBrokerEid: PropTypes.func,
  resetData: PropTypes.func,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  role: selectRole(store),
  advisors: store.formData.planDesign.advisors,
  advisorInfo: selectAdvisorQuestions(store),
  hashId: selectHashId(store),
  isFirmBrokerSplitComp: selectIsFirmBrokerSplitComp(store),
});
const mapDispatchToProps = (dispatch) => ({
  putActiveStep: (formId, updateStep) => {
    dispatch(FormDataRedux.putActiveStep(formId, updateStep));
  },
  putQuestionById: (
    formId,
    questionId,
    value,
    hasError,
    dirty,
    valueFormatted,
    disabled,
  ) => {
    dispatch(
      FormDataRedux.putQuestionById(
        formId,
        questionId,
        value,
        hasError,
        dirty,
        valueFormatted,
        disabled,
      ),
    );
  },
  resetData: (formId) => {
    dispatch(FormDataRedux.resetData(formId));
  },
  putBrokerEid: (eid, index) => {
    dispatch(FormDataRedux.putBrokerEid(eid, index));
  },
  putCrd: (obj, index) => {
    dispatch(FormDataRedux.putCrd(obj, index));
  },
});

const prevStepHardship = (employerContributionMatch, safeHarbor) => {
  if (employerContributionMatch.value === 'no') {
    return 'step_10';
  }
  if (safeHarbor.value === 'nonSafeHarbor') {
    return 'step_13';
  }
  return 'step_12';
};

const nextStepDest = (employerContributionMatch, safeHarbor) => {
  if (
    employerContributionMatch.value === 'yes' &&
    safeHarbor.value === 'nonSafeHarbor'
  ) {
    return 'step_13';
  }
  return 'step_14';
};
export default connect(mapStateToProps, mapDispatchToProps)(SetupPlan);
