import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux from '../../../../Redux/FormDataRedux';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../../common/FormWithSteps/Utilities';
import MaxAutoEscalateQuestions from './MaxAutoEscalateQuestions';
import {
  StepControls,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../common/FormWithSteps';

const MaxAutoEscalateQuestion = ({ updateQuestion, isValid, ...stepProps }) => {
  const primaryQuestion = stepProps.questions[stepProps.questionId];
  const secondaryQuestion = stepProps.questions[stepProps.subQuestionIds[0]];
  const tertiaryQuestion = stepProps.questions[stepProps.subQuestionIds[1]];
  const [validateQuestions, setValidateQuestions] = useState([]);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
    if (id === secondaryQuestion.name) {
      updateQuestion({
        name: tertiaryQuestion.name,
        dirty: true,
        required: updateValue === 'yes',
      });
    }
  };

  useEffect(() => {
    pushGoogleDataLayer(stepProps.googleData);
    updateQuestion({
      name: primaryQuestion.name,
      dirty: true,
    });
  }, []);

  useEffect(() => {
    if (secondaryQuestion.value === 'yes') {
      setValidateQuestions([
        primaryQuestion,
        secondaryQuestion,
        tertiaryQuestion,
      ]);
    } else {
      setValidateQuestions([primaryQuestion, secondaryQuestion]);
    }
  }, [primaryQuestion, secondaryQuestion, tertiaryQuestion]);

  const customTertiaryQuestion = {
    ...tertiaryQuestion,
    min: primaryQuestion.value,
  };

  return (
    <div className="container-fluid h-100">
      <MaxAutoEscalateQuestions
        primaryQuestion={primaryQuestion}
        secondaryQuestion={secondaryQuestion}
        tertiaryQuestion={customTertiaryQuestion}
        active={stepProps.active}
        previousStep={stepProps.previousStep}
        questions={stepProps.questions}
        updateActiveStep={stepProps.updateActiveStep}
        role={stepProps.role}
        handleQuestionChange={handleQuestionChange}
      />
      <div className="row">
        <div className="col-lg-12 mx-auto">
          <div className="form-group fws__control">
            <StepControls
              formInReview={stepProps.formInReview}
              disableReviewBtn={!isValid}
              disabledNextBtn={!checkValidityOnQuestionArray(validateQuestions)}
              goToReviewPlan={stepProps.goToReviewPlan}
              clickNextBtn={() => {
                stepProps.updateActiveStep(stepProps.destination(), 'forward');
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={stepProps.active}
        goTo={stepProps.previousStep}
        updateActiveStep={stepProps.updateActiveStep}
      />
    </div>
  );
};

MaxAutoEscalateQuestion.propTypes = {
  updateQuestion: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
});

export default connect(null, mapDispatchToProps)(MaxAutoEscalateQuestion);
