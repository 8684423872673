export const paradigmPaymentMethod = (
  compensation,
  brokerType,
  insuranceAgentCompType,
  role,
  investmentLineup,
) => {
  if (role === 'psivet') {
    return 'psivet';
  }
  if (
    role === 'onedigital' ||
    investmentLineup.value === 'fundLineupOneDigital'
  ) {
    return 'onedigital';
  }
  if (brokerType === 'insuranceAgent') {
    // ia-direct
    if (insuranceAgentCompType === 'direct') {
      return 'ia-direct';
    }
    // ia-indirect
    return 'ia-indirect';
  }
  // indirect
  if (compensation === 'option_2') {
    return 'indirect';
  }
  // direct
  return 'direct';
};
