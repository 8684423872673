import React from 'react';

const IconBlueCheckO = () => (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8724 3.40903C17.7094 -0.38647 7.20452 3.9647 3.40902 13.1276C-0.386471 22.2906 3.96471 32.7955 13.1276 36.591C22.2906 40.3865 32.7955 36.0353 36.591 26.8724C40.3865 17.7094 36.0353 7.20452 26.8724 3.40903ZM1.5273 12.3482C5.75328 2.146 17.4496 -2.69867 27.6518 1.5273C37.854 5.75328 42.6987 17.4496 38.4727 27.6518C34.2467 37.854 22.5504 42.6987 12.3482 38.4727C2.14601 34.2467 -2.69867 22.5504 1.5273 12.3482ZM30.6915 9.30792C30.9637 9.30792 31.2245 9.41684 31.4158 9.61038L34.1264 12.3526C34.5186 12.7493 34.5186 13.3877 34.1264 13.7844L18.0591 30.0388C17.8678 30.2324 17.607 30.3413 17.3349 30.3413C17.0627 30.3413 16.8019 30.2324 16.6106 30.0388L6.19739 19.5044C5.80523 19.1076 5.80523 18.4692 6.19739 18.0725L8.90804 15.3303C9.09935 15.1368 9.36016 15.0278 9.6323 15.0278C9.90443 15.0278 10.1653 15.1368 10.3566 15.3303L17.3349 22.3899L29.9673 9.61038C30.1586 9.41684 30.4194 9.30792 30.6915 9.30792ZM30.6915 11.7749L18.0591 24.5544C17.8678 24.748 17.607 24.8569 17.3349 24.8569C17.0627 24.8569 16.8019 24.748 16.6106 24.5544L9.6323 17.4948L8.3536 18.7884L17.3349 27.8743L31.9702 13.0685L30.6915 11.7749Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="20"
        y1="0"
        x2="20"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0393D8" />
        <stop offset="1" stopColor="#039C9A" />
      </linearGradient>
    </defs>
  </g>
);

export default IconBlueCheckO;
