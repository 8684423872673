import React from 'react';

const PreApprovedDocuments = () => (
  <div className="py-4 px-5">
    <h5 className="fs-h5 my-2 pt-2">Pre-approved documents</h5>

    <p className="fs-p">
      <strong>
        Simply Retirement by Principal
        <sup>
          <small>®</small>
        </sup>{' '}
        provides you with a qualified, preapproved document, so you don’t have
        to prepare this document yourself.
      </strong>{' '}
      The Employee Retirement Income Security Act (ERISA) requires plan
      administrators (the people who run plans) to provide certain information
      to their retirement plan participants in writing. The plan document must
      be submitted for approval by the U.S. Department of Labor.
    </p>
  </div>
);

export default PreApprovedDocuments;
