import { planUsingSafeHarbor } from './TpaQuestions/planUsingSafeHarbor';
import { whichSafeHarbor } from './TpaQuestions/whichSafeHarbor';
import { hasDiscretionaryEmployerMatch } from './TpaQuestions/hasDiscretionaryEmployerMatch';
import { vestingScheduleMatch } from './TpaQuestions/vestingScheduleMatch';
import { discretionarySharingContributions } from './TpaQuestions/discretionarySharingContributions';
import { vestingScheduleProfit } from './TpaQuestions/vestingScheduleProfit';
import { vestingScheduleQaca } from './TpaQuestions/vestingScheduleQaca';
import { safeHarborDiscretionaryMatchingContributions } from './TpaQuestions/safeHarborDiscretionaryMatchingContributions';
import { minServiceReqdiscretionaryProfitContribution } from './TpaQuestions/minServiceReqdiscretionaryProfitContribution';
import { minAgeReqDiscretionaryProfitContributions } from './TpaQuestions/minAgeReqDiscretionaryProfitContributions';
import { entryDateDiscretionaryProfitContribution } from './TpaQuestions/entryDateDiscretionaryProfitContribution';
import { autoDeferralPercentageStartEmployees } from './TpaQuestions/autoDeferralPercentageStartEmployees';
import { autoEscalateAutoEnrolledDeferrals1perc } from './TpaQuestions/autoEscalateAutoEnrolledDeferrals1perc';
import { maxAutoEscalatePercentage } from './TpaQuestions/maxAutoEscalatePercentage';
import { autoEscalateAutoEnrolledDeferrals } from './TpaQuestions/autoEscalateAutoEnrolledDeferrals';
import { autoEnrollment } from './TpaQuestions/autoEnrollment';
import { autoEnrollmentPlan } from './TpaQuestions/autoEnrollmentPlan';
import { minAgeReqEligibility } from './TpaQuestions/minAgeReqEligibility';
import { minServiceReqEligibility } from './TpaQuestions/minServiceReqEligibility';
import { entryDatesPlanParticipation } from './TpaQuestions/entryDatesPlanParticipation';
import { entryDateDeferrals } from './TpaQuestions/entryDateDeferrals';
import { hasAutoEscalation } from './TpaQuestions/hasAutoEscalation';
import { whichSafeHarborQaca } from './TpaQuestions/whichSafeHarborQaca';
import { allowLoans } from './TpaQuestions/allowLoans';
import { offerRothContributions } from './TpaQuestions/offerRothContributions';
import { hardshipWithdrawals } from './TpaQuestions/hardshipWithdrawals';
import { minAgeReqDiscretionaryMatchContributions } from './TpaQuestions/minAgeReqDiscretionaryMatchContributions';
import { minServiceReqDiscretionaryMatchingContribution } from './TpaQuestions/minServiceReqDiscretionaryMatchingContribution';
import { entryDatesDiscretionaryMatching } from './TpaQuestions/entryDatesDiscretionaryMatching';
import { minServiceReqDiscretionaryProfit } from './TpaQuestions/minServiceReqDiscretionaryProfit';
import { tpaFirmName } from './TpaQuestions/tpaFirmName';
import { planEffectiveDate } from './TpaQuestions/planEffectiveDate';
import { planNumber } from './TpaQuestions/planNumber';
import { planName } from './TpaQuestions/planName';

export const TPA_INITIAL_SECTION_A_NONE = {
  a_planUsingSafeHarbor: {
    ...planUsingSafeHarbor,
    name: 'a_planUsingSafeHarbor',
    stepName: 'a_step_11_1',
  },
  a_whichSafeHarbor: {
    ...whichSafeHarbor,
    name: 'a_whichSafeHarbor',
    stepName: 'a_step_11_1',
  },
  hasDiscretionaryEmployerMatch: {
    ...hasDiscretionaryEmployerMatch,
    name: 'hasDiscretionaryEmployerMatch',
    stepName: 'a_step_12_2',
  },
  vestingScheduleMatch: {
    ...vestingScheduleMatch,
    name: 'vestingScheduleMatch',
    stepName: 'a_step_12_2',
  },
  discretionarySharingContributions: {
    ...discretionarySharingContributions,
    name: 'discretionarySharingContributions',
    stepName: 'a_step_13_3',
  },
  vestingScheduleProfit: {
    ...vestingScheduleProfit,
    name: 'vestingScheduleProfit',
    stepName: 'a_step_13_3',
  },
  allowLoans: {
    ...allowLoans,
    name: 'allowLoans',
    stepName: 'a_step_14_4',
  },
  hardshipWithdrawals: {
    ...hardshipWithdrawals,
    name: 'hardshipWithdrawals',
    stepName: 'a_step_15_5',
  },
  a_planEffectiveDate: {
    ...planEffectiveDate,
    name: 'a_planEffectiveDate',
    stepName: 'a_step_16_6',
  },
  a_planName: {
    ...planName,
    name: 'a_planName',
    stepName: 'a_step_16_6',
  },
  a_planNumber: {
    ...planNumber,
    name: 'a_planNumber',
    stepName: 'a_step_16_6',
  },
};
export const TPA_INITIAL_SECTION_A_ACA = {
  autoDeferralPercentageStartEmployees: {
    ...autoDeferralPercentageStartEmployees,
    name: 'autoDeferralPercentageStartEmployees',
    stepName: 'a_step_11_aca_1',
  },
  autoEscalateAutoEnrolledDeferrals1perc: {
    ...autoEscalateAutoEnrolledDeferrals1perc,
    name: 'autoEscalateAutoEnrolledDeferrals1perc',
    stepName: 'a_step_11_aca_1',
  },
  maxAutoEscalatePercentage: {
    ...maxAutoEscalatePercentage,
    name: 'maxAutoEscalatePercentage',
    stepName: 'a_step_11_aca_1',
  },
  planUsingSafeHarbor: {
    ...planUsingSafeHarbor,
    name: 'planUsingSafeHarbor',
    stepName: 'a_step_12_aca_2',
  },
  whichSafeHarbor: {
    ...whichSafeHarbor,
    name: 'whichSafeHarbor',
    stepName: 'a_step_12_aca_2',
    options: [
      {
        id: 1,
        user: {},
        label: '3% non-elective',
        value: '3PercNonelectiveSafeharbor',
        valueFormatted: '3% Non-elective',
        checked: false,
        icon: 'icon-calendar-o',
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Basic match:  100% up to 3%, 50% up to the next 2%',
        value: 'basicMatch100to3-50toNext2',
        valueFormatted: 'Basic match:  100% up to 3%, 50% up to the next 2%',
        checked: false,
        icon: 'icon-calendar-o',
        customStyle: '',
      },
      {
        id: 3,
        user: {},
        label: 'Enhanced match:  100% up to 4%',
        value: 'enhancedMatch100to4',
        valueFormatted: 'Enhanced match:  100% up to 4%',
        checked: false,
        icon: 'icon-calendar-o',
        customStyle: '',
      },
      {
        id: 4,
        user: {},
        label: 'Enhanced match:  100% up to 5%',
        value: 'enhancedMatch100to5',
        valueFormatted: 'Enhanced match:  100% up to 5%',
        checked: false,
        icon: 'icon-calendar-o',
        customStyle: '',
      },
      {
        id: 5,
        user: {},
        label: 'Enhanced match:  100% up to 6%',
        value: 'enhancedMatch100to6',
        valueFormatted: 'Enhanced match:  100% up to 6%',
        checked: false,
        icon: 'icon-calendar-o',
        customStyle: '',
      },
    ],
  },
  aAca_hasDiscretionaryEmployerMatch: {
    ...hasDiscretionaryEmployerMatch,
    name: 'aAca_hasDiscretionaryEmployerMatch',
    stepName: 'a_step_13_aca_3',
  },
  aAca_vestingScheduleMatch: {
    ...vestingScheduleMatch,
    name: 'aAca_vestingScheduleMatch',
    stepName: 'a_step_13_aca_3',
  },
  aAca_discretionarySharingContributions: {
    ...discretionarySharingContributions,
    name: 'aAca_discretionarySharingContributions',
    stepName: 'a_step_14_aca_4',
  },
  aAca_vestingScheduleProfit: {
    ...vestingScheduleProfit,
    name: 'aAca_vestingScheduleProfit',
    stepName: 'a_step_14_aca_4',
  },
  aAca_allowLoans: {
    ...allowLoans,
    name: 'aAca_allowLoans',
    stepName: 'a_step_15_aca_5',
  },
  aAca_hardshipWithdrawals: {
    ...hardshipWithdrawals,
    name: 'aAca_hardshipWithdrawals',
    stepName: 'a_step_16_aca_6',
  },
  aAca_planEffectiveDate: {
    ...planEffectiveDate,
    name: 'aAca_planEffectiveDate',
    stepName: 'a_step_17_aca_7',
  },
  aAca_planName: {
    ...planName,
    name: 'aAca_planName',
    stepName: 'a_step_17_aca_7',
  },
  aAca_planNumber: {
    ...planNumber,
    name: 'aAca_planNumber',
    stepName: 'a_step_17_aca_7',
  },
};
export const TPA_INITIAL_SECTION_A_QACA = {
  aQaca_autoDeferralPercentageStartEmployees: {
    ...autoDeferralPercentageStartEmployees,
    name: 'aQaca_autoDeferralPercentageStartEmployees',
    stepName: 'a_step_11_qaca_1',
    min: 6,
  },
  autoEscalateAutoEnrolledDeferrals: {
    ...autoEscalateAutoEnrolledDeferrals,
    name: 'autoEscalateAutoEnrolledDeferrals',
    stepName: 'a_step_11_qaca_1',
  },
  whichSafeHarborQaca: {
    ...whichSafeHarborQaca,
    name: 'whichSafeHarborQaca',
    stepName: 'a_step_12_qaca_2',
  },
  aQaca_maxAutoEscalatePercentage: {
    ...maxAutoEscalatePercentage,
    name: 'aQaca_maxAutoEscalatePercentage',
    stepName: 'a_step_11_qaca_1',
  },
  aQaca_discretionarySharingContributions: {
    ...discretionarySharingContributions,
    name: 'aQaca_discretionarySharingContributions',
    stepName: 'a_step_15_qaca_5',
  },
  aQaca_vestingScheduleQaca: {
    ...vestingScheduleQaca,
    name: 'aQaca_vestingScheduleQaca',
    stepName: 'a_step_13_qaca_3',
  },
  aQaca_vestingScheduleMatch: {
    ...vestingScheduleMatch,
    name: 'aQaca_vestingScheduleMatch',
    stepName: 'a_step_14_qaca_4',
  },
  aQaca_safeHarborDiscretionaryMatchingContributions: {
    ...safeHarborDiscretionaryMatchingContributions,
    name: 'aQaca_safeHarborDiscretionaryMatchingContributions',
    stepName: 'a_step_14_qaca_4',
  },
  aQaca_vestingScheduleProfit: {
    ...vestingScheduleProfit,
    name: 'aQaca_vestingScheduleProfit',
    stepName: 'a_step_15_qaca_5',
  },
  aQaca_allowLoans: {
    ...allowLoans,
    name: 'aQaca_allowLoans',
    stepName: 'a_step_16_qaca_6',
  },
  aQaca_hardshipWithdrawals: {
    ...hardshipWithdrawals,
    name: 'aQaca_hardshipWithdrawals',
    stepName: 'a_step_17_qaca_7',
  },
  aQaca_planEffectiveDate: {
    ...planEffectiveDate,
    name: 'aQaca_planEffectiveDate',
    stepName: 'a_step_18_qaca_8',
  },
  aQaca_planName: {
    ...planName,
    name: 'aQaca_planName',
    stepName: 'a_step_18_qaca_8',
  },
  aQaca_planNumber: {
    ...planNumber,
    name: 'aQaca_planNumber',
    stepName: 'a_step_18_qaca_8',
  },
};

export const TPA_INITIAL_SECTION_A_DEFAULT = {
  minAgeReqEligibility: {
    ...minAgeReqEligibility,
    name: 'minAgeReqEligibility',
    stepName: 'a_step_7_1',
    label: 'What is the minimum age requirement for eligibility?',
  },
  minServiceReqEligibility: {
    ...minServiceReqEligibility,
    name: 'minServiceReqEligibility',
    stepName: 'a_step_8_2',
    label: 'What is the minimum service requirement for eligibility?',
  },
  entryDatesPlanParticipation: {
    ...entryDatesPlanParticipation,
    name: 'entryDatesPlanParticipation',
    stepName: 'a_step_9_3',
    label: 'What is the entry date for plan participation?',
  },
  autoEnrollment: {
    ...autoEnrollment,
    name: 'autoEnrollment',
    stepName: 'a_step_10_4',
  },
  autoEnrollmentPlan: {
    ...autoEnrollmentPlan,
    name: 'autoEnrollmentPlan',
    stepName: 'a_step_10_4',
  },
};

export const TPA_INITIAL_SECTION_A = {
  ...TPA_INITIAL_SECTION_A_DEFAULT,
  ...TPA_INITIAL_SECTION_A_NONE,
  ...TPA_INITIAL_SECTION_A_ACA,
  ...TPA_INITIAL_SECTION_A_QACA,
};

export const TPA_INITIAL_SECTION_B_NONE_ALT = {
  bShare_minServiceReqDiscretionaryMatchingContribution: {
    ...minServiceReqDiscretionaryMatchingContribution,
    name: 'bShare_minServiceReqDiscretionaryMatchingContribution',
    stepName: 'b_step_13_sharing_3',
  },
  bShare_entryDatesDiscretionaryMatching: {
    ...entryDatesDiscretionaryMatching,
    name: 'bShare_entryDatesDiscretionaryMatching',
    stepName: 'b_step_14_sharing_4',
  },
  bShare_vestingScheduleMatch: {
    ...vestingScheduleMatch,
    name: 'bShare_vestingScheduleMatch',
    stepName: 'b_step_15_sharing_5',
  },
};

export const TPA_INITIAL_SECTION_B_NONE_ALT2 = {
  bShare_minServiceReqDiscretionaryProfit: {
    ...minServiceReqDiscretionaryProfit,
    name: 'bShare_minServiceReqDiscretionaryProfit',
    stepName: 'b_step_17_sharing_7',
  },
  bShare_entryDateDiscretionaryProfitContribution: {
    ...entryDateDiscretionaryProfitContribution,
    name: 'bShare_entryDateDiscretionaryProfitContribution',
    stepName: 'b_step_18_sharing_8',
  },
  bShare_vestingScheduleProfit: {
    ...vestingScheduleProfit,
    name: 'bShare_vestingScheduleProfit',
    stepName: 'b_step_19_sharing_9',
  },
};

export const TPA_INITIAL_SECTION_B_NONE = {
  b_planUsingSafeHarbor: {
    ...planUsingSafeHarbor,
    name: 'b_planUsingSafeHarbor',
    stepName: 'b_step_11_1',
  },
  b_whichSafeHarbor: {
    ...whichSafeHarbor,
    name: 'b_whichSafeHarbor',
    stepName: 'b_step_11_1',
  },
  b_hasDiscretionaryEmployerMatch: {
    ...hasDiscretionaryEmployerMatch,
    name: 'b_hasDiscretionaryEmployerMatch',
    stepName: 'b_step_12_2',
  },
  b_minAgeReqDiscretionaryMatchContributions: {
    ...minAgeReqDiscretionaryMatchContributions,
    name: 'b_minAgeReqDiscretionaryMatchContributions',
    stepName: 'b_step_12_2',
  },
  b_discretionarySharingContributions: {
    ...discretionarySharingContributions,
    name: 'b_discretionarySharingContributions',
    stepName: 'b_step_16_6',
  },
  b_minAgeReqDiscretionaryProfitContributions: {
    ...minAgeReqDiscretionaryProfitContributions,
    name: 'b_minAgeReqDiscretionaryProfitContributions',
    stepName: 'b_step_16_6',
  },
  b_allowLoans: {
    ...allowLoans,
    name: 'b_allowLoans',
    stepName: 'b_step_17_7',
  },
  b_hardshipWithdrawals: {
    ...hardshipWithdrawals,
    name: 'b_hardshipWithdrawals',
    stepName: 'b_step_18_8',
  },
  b_planEffectiveDate: {
    ...planEffectiveDate,
    name: 'b_planEffectiveDate',
    stepName: 'b_step_19_9',
  },
  b_planName: {
    ...planName,
    name: 'b_planName',
    stepName: 'b_step_19_9',
  },
  b_planNumber: {
    ...planNumber,
    name: 'b_planNumber',
    stepName: 'b_step_19_9',
  },
};

export const TPA_INITIAL_SECTION_B_ACA_ALT = {
  bAcaShare_minServiceReqDiscretionaryMatchingContribution: {
    ...minServiceReqDiscretionaryMatchingContribution,
    name: 'bAcaShare_minServiceReqDiscretionaryMatchingContribution',
    stepName: 'b_step_14_aca_share_4',
  },
  bAcaShare_entryDatesDiscretionaryMatching: {
    ...entryDatesDiscretionaryMatching,
    name: 'bAcaShare_entryDatesDiscretionaryMatching',
    stepName: 'b_step_15_aca_share_5',
  },
  bAcaShare_vestingScheduleMatch: {
    ...vestingScheduleMatch,
    name: 'bAcaShare_vestingScheduleMatch',
    stepName: 'b_step_16_aca_share_6',
  },
};

export const TPA_INITIAL_SECTION_B_ACA_ALT2 = {
  bAcaShare_minServiceReqdiscretionaryProfitContribution: {
    ...minServiceReqDiscretionaryProfit,
    name: 'bAcaShare_minServiceReqdiscretionaryProfitContribution',
    stepName: 'b_step_17_aca_share_7',
  },
  bAcaShare_entryDateDiscretionaryProfitContribution: {
    ...entryDateDiscretionaryProfitContribution,
    name: 'bAcaShare_entryDateDiscretionaryProfitContribution',
    stepName: 'b_step_18_aca_share_8',
  },
  bAcaShare_vestingScheduleProfit: {
    ...vestingScheduleProfit,
    name: 'bAcaShare_vestingScheduleProfit',
    stepName: 'b_step_19_aca_share_9',
  },
};

export const TPA_INITIAL_SECTION_B_ACA = {
  bAca_autoDeferralPercentageStartEmployees: {
    ...autoDeferralPercentageStartEmployees,
    name: 'bAca_autoDeferralPercentageStartEmployees',
    stepName: 'b_step_11_aca_1',
  },
  bAca_autoEscalateAutoEnrolledDeferrals1perc: {
    ...autoEscalateAutoEnrolledDeferrals1perc,
    name: 'bAca_autoEscalateAutoEnrolledDeferrals1perc',
    stepName: 'b_step_11_aca_1',
  },
  bAca_maxAutoEscalatePercentage: {
    ...maxAutoEscalatePercentage,
    name: 'bAca_maxAutoEscalatePercentage',
    stepName: 'b_step_11_aca_1',
  },
  bAca_planUsingSafeHarbor: {
    ...planUsingSafeHarbor,
    name: 'bAca_planUsingSafeHarbor',
    stepName: 'b_step_12_aca_2',
  },
  bAca_whichSafeHarbor: {
    ...whichSafeHarbor,
    name: 'bAca_whichSafeHarbor',
    stepName: 'b_step_12_aca_2',
  },
  bAca_hasDiscretionaryEmployerMatch: {
    ...hasDiscretionaryEmployerMatch,
    name: 'bAca_hasDiscretionaryEmployerMatch',
    stepName: 'b_step_13_aca_3',
  },
  bAcaShare_minAgeReqDiscretionaryMatchContributions: {
    ...minAgeReqDiscretionaryMatchContributions,
    name: 'bAcaShare_minAgeReqDiscretionaryMatchContributions',
    stepName: 'b_step_13_aca_3',
  },
  bAca_discretionarySharingContributions: {
    ...discretionarySharingContributions,
    name: 'bAca_discretionarySharingContributions',
    stepName: 'b_step_16_aca_6',
  },
  bAca_minAgeReqDiscretionaryProfitContributions: {
    ...minAgeReqDiscretionaryProfitContributions,
    name: 'bAca_minAgeReqDiscretionaryProfitContributions',
    stepName: 'b_step_16_aca_6',
  },
  bAca_allowLoans: {
    ...allowLoans,
    name: 'bAca_allowLoans',
    stepName: 'b_step_18_aca_8',
  },
  bAca_hardshipWithdrawals: {
    ...hardshipWithdrawals,
    name: 'bAca_hardshipWithdrawals',
    stepName: 'b_step_19_aca_9',
  },
  bAca_planEffectiveDate: {
    ...planEffectiveDate,
    name: 'bAca_planEffectiveDate',
    stepName: 'b_step_22_aca_12',
  },
  bAca_planName: {
    ...planName,
    name: 'bAca_planName',
    stepName: 'b_step_22_aca_12',
  },
  bAca_planNumber: {
    ...planNumber,
    name: 'bAca_planNumber',
    stepName: 'b_step_22_aca_12',
  },
};

export const TPA_INITIAL_SECTION_B_QACA_ALT = {
  bQacaShare_minAgeReqDiscretionaryMatchContributions: {
    ...minAgeReqDiscretionaryMatchContributions,
    name: 'bQacaShare_minAgeReqDiscretionaryMatchContributions',
    stepName: 'b_step_15_qaca_share_5',
  },
  bQacaShare_minServiceReqDiscretionaryMatchingContribution: {
    ...minServiceReqDiscretionaryMatchingContribution,
    name: 'bQacaShare_minServiceReqDiscretionaryMatchingContribution',
    stepName: 'b_step_16_qaca_share_6',
  },
  bQacaShare_entryDatesDiscretionaryMatching: {
    ...entryDatesDiscretionaryMatching,
    name: 'bQacaShare_entryDatesDiscretionaryMatching',
    stepName: 'b_step_17_qaca_share_7',
  },
  bQacaShare_vestingScheduleMatch: {
    ...vestingScheduleMatch,
    name: 'bQacaShare_vestingScheduleMatch',
    stepName: 'b_step_18_qaca_share_8',
  },
};

export const TPA_INITIAL_SECTION_B_QACA_ALT2 = {
  bQacaShare_minAgeReqDiscretionaryProfitContributions: {
    ...minAgeReqDiscretionaryProfitContributions,
    name: 'bQacaShare_minAgeReqDiscretionaryProfitContributions',
    stepName: 'b_step_19_qaca_9',
  },
  bQacaShare_minServiceReqDiscretionaryProfit: {
    ...minServiceReqDiscretionaryProfit,
    name: 'bQacaShare_minServiceReqDiscretionaryProfit',
    stepName: 'b_step_20_qaca_share_10',
  },
  bQacaShare_entryDateDiscretionaryProfitContribution: {
    ...entryDateDiscretionaryProfitContribution,
    name: 'bQacaShare_entryDateDiscretionaryProfitContribution',
    stepName: 'b_step_21_qaca_share_11',
  },
  bQacaShare_vestingScheduleProfit: {
    ...vestingScheduleProfit,
    name: 'bQacaShare_vestingScheduleProfit',
    stepName: 'b_step_22_qaca_share_12',
  },
};

export const TPA_INITIAL_SECTION_B_QACA = {
  hasAutoEscalation: {
    ...hasAutoEscalation,
    name: 'hasAutoEscalation',
    stepName: 'b_step_11_qaca_1',
  },
  bQaca_autoDeferralPercentageStartEmployees: {
    ...autoDeferralPercentageStartEmployees,
    name: 'bQaca_autoDeferralPercentageStartEmployees',
    stepName: 'b_step_11_qaca_1',
  },
  bQaca_maxAutoEscalatePercentage: {
    ...maxAutoEscalatePercentage,
    name: 'bQaca_maxAutoEscalatePercentage',
    stepName: 'b_step_11_qaca_1',
  },
  bQaca_whichSafeHarborQaca: {
    ...whichSafeHarborQaca,
    name: 'bQaca_whichSafeHarborQaca',
    stepName: 'b_step_12_qaca_2',
  },
  bQaca_vestingScheduleQaca: {
    ...vestingScheduleQaca,
    name: 'bQaca_vestingScheduleQaca',
    stepName: 'b_step_13_qaca_3',
  },
  bQaca_discretionarySharingContributions: {
    ...discretionarySharingContributions,
    name: 'bQaca_discretionarySharingContributions',
    stepName: 'b_step_19_qaca_9',
  },
  bQaca_safeHarborDiscretionaryMatchingContributions: {
    ...safeHarborDiscretionaryMatchingContributions,
    name: 'bQaca_safeHarborDiscretionaryMatchingContributions',
    stepName: 'b_step_14_qaca_4',
  },
  bQaca_allowLoans: {
    ...allowLoans,
    name: 'bQaca_allowLoans',
    stepName: 'b_step_23_qaca_13',
  },
  bQaca_hardshipWithdrawals: {
    ...hardshipWithdrawals,
    name: 'bQaca_hardshipWithdrawals',
    stepName: 'b_step_24_qaca_14',
  },
  bQaca_planEffectiveDate: {
    ...planEffectiveDate,
    name: 'bQaca_planEffectiveDate',
    stepName: 'b_step_25_qaca_15',
  },
  bQaca_planName: {
    ...planName,
    name: 'bQaca_planName',
    stepName: 'b_step_25_qaca_15',
  },
  bQaca_planNumber: {
    ...planNumber,
    name: 'bQaca_planNumber',
    stepName: 'b_step_25_qaca_15',
  },
};
export const TPA_INITIAL_SECTION_B_DEFAULT = {
  b_minAgeReqEligibility: {
    ...minAgeReqEligibility,
    name: 'b_minAgeReqEligibility',
    stepName: 'b_step_7_1',
  },
  minServiceReqdiscretionaryProfitContribution: {
    ...minServiceReqdiscretionaryProfitContribution,
    name: 'minServiceReqdiscretionaryProfitContribution',
    stepName: 'b_step_8_2',
  },
  entryDateDeferrals: {
    ...entryDateDeferrals,
    name: 'entryDateDeferrals',
    stepName: 'b_step_9_3',
  },
  b_autoEnrollment: {
    ...autoEnrollment,
    name: 'b_autoEnrollment',
    stepName: 'b_step_10_4',
  },
  b_autoEnrollmentPlan: {
    ...autoEnrollmentPlan,
    name: 'b_autoEnrollmentPlan',
    stepName: 'b_step_10_4',
  },
};
export const TPA_INITIAL_SECTION_B = {
  ...TPA_INITIAL_SECTION_B_DEFAULT,
  ...TPA_INITIAL_SECTION_B_NONE_ALT,
  ...TPA_INITIAL_SECTION_B_NONE_ALT2,
  ...TPA_INITIAL_SECTION_B_NONE,
  ...TPA_INITIAL_SECTION_B_ACA_ALT,
  ...TPA_INITIAL_SECTION_B_ACA_ALT2,
  ...TPA_INITIAL_SECTION_B_ACA,
  ...TPA_INITIAL_SECTION_B_QACA_ALT,
  ...TPA_INITIAL_SECTION_B_QACA_ALT2,
  ...TPA_INITIAL_SECTION_B_QACA,
};

export const TPA_INITIAL_PLAN_DEFAULT = {
  offerRothContributions: {
    ...offerRothContributions,
    name: 'offerRothContributions',
    stepName: '_tpa_step_5',
  },
  contributionRequirements: {
    id: 'q2',
    type: 'radio_condensed',
    name: 'contributionRequirements',
    user: {},
    stepName: '_tpa_step_6',
    label:
      'Are all plan contributions subject to the same eligibility and entry requirements?',
    infoLabel: '',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: 'yes',
        valueFormatted: 'Yes',
        checked: false,
        customStyle: 'radio_confirm',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: 'no',
        valueFormatted: 'No',
        checked: false,
        customStyle: 'radio_cancel',
      },
    ],
    required: true,
    hasError: false,
    dirty: false,
    value: '',
    reviewScreenTitle:
      'Are all plan contributions subject to the same eligibility and entry requirements?',
    optionDescription: '',
    description:
      'If "no" is selected here, separate eligibility and entry requirements will be shown for each type of plan contributions.',
    sideCardTitle: 'Plan contributions subject to the same eligibility req.',
  },
};

export const TPA_INITIAL_PLAN = {
  ...TPA_INITIAL_PLAN_DEFAULT,
  ...TPA_INITIAL_SECTION_A,
  ...TPA_INITIAL_SECTION_B,
  tpaFirmName: {
    ...tpaFirmName,
  },
  tpaFirmNameUnlisted: {
    type: 'text_field',
    name: 'tpaFirmNameUnlisted',
    user: {},
    label: 'TPA firm name',
    required: true,
    dirty: false,
    value: '',
    hasError: false,
  },
  tpaSetupFee: {
    type: 'number_field',
    name: 'tpaSetupFee',
    user: {},
    label: 'TPA setup cost',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'TPA setup cost',
    stepName: 'tpaFees',
    description: '',
  },
  annualTpaFee: {
    type: 'number_field',
    name: 'annualTpaFee',
    user: {},
    label: 'TPA annual base cost',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'TPA annual base cost',
    stepName: 'tpaFees',
    description: '',
  },
  annualTpaPerHeadFee: {
    type: 'number_field',
    name: 'annualTpaPerHeadFee',
    user: {},
    label: 'TPA annual per participant cost',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'TPA annual per participant cost',
    stepName: 'tpaFees',
    description: '',
  },
  feeCollectionSource: {
    id: 10,
    type: 'radio_condensed',
    name: 'feeCollectionSource',
    paradigmAlias: 'feeCollectionSource',
    user: {},
    stepName: 'tpaFeesDeductions',
    label: 'Do you want to include a fee to be deducted from plan assets?',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: 'ASSETS',
        checked: false,
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: 'DIRECT',
        checked: false,
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  tpaCompensationType: {
    type: 'radio_condensed',
    name: 'tpaCompensationType',
    paradigmAlias: 'tpaCompensationType',
    user: {},
    stepName: 'tpaFeesDeductions',
    label: 'Which compensation type?',
    infoLabel: '(paid quarterly)',
    options: [
      {
        id: 1,
        user: {},
        label: 'Flat Fee',
        value: 'fixedAmount',
        checked: false,
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Basis Points',
        value: 'bpsAmount',
        checked: false,
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  fixedAmount: {
    type: 'number_field',
    name: 'fixedAmount',
    paradigmAlias: 'fixedAmount',
    labelClass: 'fs-form-q fw-bold mb-3 text-center',
    user: {},
    stepName: 'tpaFeesDeductions',
    label: 'What is your annual flat fee?',
    infoLabel: '(up to $5,000 per year, paid quarterly)',
    valuePrefix: '$',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  bpsAmount: {
    type: 'number_field',
    name: 'bpsAmount',
    paradigmAlias: 'bpsAmount',
    labelClass: 'fs-form-q fw-bold mb-3 text-center',
    user: {},
    stepName: 'tpaFeesDeductions',
    label: 'How many basis points?',
    infoLabel: '(up to 250 bps, per year, paid quarterly)',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  tpaDistributionFee: {
    type: 'number_field',
    name: 'tpaDistributionFee', // TPA_DISTRIBUTION_FEE
    paradigmAlias: 'tpaDistributionFee',
    user: {},
    label: 'Distribution fee',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'Distribution fee',
    stepName: 'participantFees',
    description: '',
  },
  tpaHardshipFee: {
    type: 'number_field',
    name: 'tpaHardshipFee', // TPA_HARDSHIP_FEE
    paradigmAlias: 'tpaHardshipFee',
    user: {},
    label: 'Hardship withdrawal fee',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'Hardship withdrawal fee',
    stepName: 'participantFees',
    description: '',
  },
  tpaLoanMaintenanceFee: {
    type: 'number_field',
    name: 'tpaLoanMaintenanceFee', // TPA_LOAN_MAINTENANCE_FEE
    paradigmAlias: 'tpaLoanMaintenanceFee',
    user: {},
    label: 'Loan annual maintenance fee',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'Loan annual maintenance fee',
    stepName: 'participantFees',
    description: '',
  },
  tpaLoanOriginationFee: {
    type: 'number_field',
    name: 'tpaLoanOriginationFee', // TPA_LOAN_ORIGINATION_FEE
    paradigmAlias: 'tpaLoanOriginationFee',
    user: {},
    label: 'Loan fee (origination)',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'Loan fee (origination)',
    stepName: 'participantFees',
    description: '',
  },
  tpaLoanReamortizationFee: {
    type: 'number_field',
    name: 'tpaLoanReamortizationFee', // TPA_LOAN_REAMORTIZATION_FEE
    paradigmAlias: 'tpaLoanReamortizationFee',
    user: {},
    label: 'Loan re-amortization fee',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'Loan re-amortization fee',
    stepName: 'participantFees',
    description: '',
  },
  tpaRmdFee: {
    type: 'number_field',
    name: 'tpaRmdFee', // TPA_RMD_FEE
    paradigmAlias: 'tpaRmdFee',
    user: {},
    label: 'Required minimum distribution (RMD) fee',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'Required minimum distribution (RMD) fee',
    stepName: 'participantFees',
    description: '',
  },
  tpaQdroFee: {
    type: 'number_field',
    name: 'tpaQdroFee', // TPA_QDRO_FEE
    paradigmAlias: 'tpaQdroFee',
    user: {},
    label: 'Qualified domestic relations order (QDRO) fee',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valuePrefix: '$',
    format: 'currency',
    thousandSeperator: true,
    hasError: false,
    reviewScreenTitle: 'Qualified domestic relations order (QDRO) fee',
    stepName: 'participantFees',
    description: '',
  },
  showFees: {
    stepName: 'tpaFees',
    type: 'radio_button',
    name: 'showFees',
    user: {},
    label: 'Show TPA fees on proposal?',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: true,
        valueFormatted: 'Show fees on proposal',
        checked: false,
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: false,
        valueFormatted: 'Do not show fees on proposal',
        checked: false,
        customStyle: '',
      },
    ],
    required: true,
    hasError: false,
    dirty: true,
    value: false,
    valueFormatted: '',
    reviewScreenTitle: 'TPA Fees',
    description: '',
  },
  noFirm: {
    type: 'text_field',
    name: 'noFirm',
    user: {},
    label: "My TPA firm isn't listed",
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  employerIdentificationNumber: {
    type: 'text_field',
    name: 'employerIdentificationNumber',
    user: {},
    label: 'TPA Employer Identification Number (EIN)',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  tpaLogo: {
    type: 'file_upload',
    name: 'tpaLogo',
    user: {},
    label: 'You logo',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
  },
  salesContactFirstName: {
    stepName: 'tpaInformation',
    type: 'text_field',
    name: 'salesContactFirstName',
    user: {},
    label: 'Sales contact first name',
    required: true,
    dirty: false,
    value: '',
    hasError: false,
  },
  salesContactLastName: {
    stepName: 'tpaInformation',
    type: 'text_field',
    name: 'salesContactLastName',
    user: {},
    label: 'Sales contact last name',
    required: true,
    dirty: false,
    value: '',
    hasError: false,
  },
  salesContactEmail: {
    stepName: 'tpaInformation',
    type: 'text_field',
    name: 'salesContactEmail',
    user: {},
    label: 'Sales contact email',
    required: true,
    dirty: false,
    value: '',
    hasError: false,
  },
};

export const TPA_INITIAL_STATE = {
  tpaIndex: 0,
  questions: {
    tpaFirstName: {
      stepName: 'tpaInformation',
      type: 'text_field',
      name: 'tpaFirstName',
      user: {
        tpa: { label: 'Operational contact first name' },
        salesContact: { label: 'Sales contact first name' },
      },
      required: true,
      dirty: false,
      value: '',
      hasError: false,
    },
    tpaLastName: {
      stepName: 'tpaInformation',
      type: 'text_field',
      name: 'tpaLastName',
      user: {
        tpa: { label: 'Operational contact last name' },
        salesContact: { label: 'Sales contact last name' },
      },
      required: true,
      dirty: false,
      value: '',
      hasError: false,
    },
    tpaEmail: {
      stepName: 'tpaInformation',
      type: 'text_field',
      name: 'tpaEmail',
      user: {
        tpa: { label: 'Operational contact email' },
        salesContact: { label: 'Sales contact email' },
      },
      required: true,
      dirty: false,
      value: '',
      hasError: false,
    },
    tpaPhone: {
      stepName: 'tpaInformation',
      type: 'text_field',
      name: 'tpaPhone',
      user: {},
      label: 'Operational contact phone number',
      required: false,
      dirty: false,
      value: '',
      hasError: false,
    },
    tpaZipCode: {
      stepName: 'tpaInformation',
      type: 'text_field',
      name: 'tpaZipCode',
      user: {},
      label: 'Operational contact ZIP code',
      required: false,
      dirty: false,
      value: '',
      hasError: false,
    },
  },
};

export default TPA_INITIAL_STATE;
