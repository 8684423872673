export const getEntry = async (entryDatesPlanParticipation) => {
  if (entryDatesPlanParticipation.value === 'dailyImmediate') {
    return 'DAILY';
  }
  if (entryDatesPlanParticipation.value === 'monthly') {
    return 'MONTHLY';
  }
  if (entryDatesPlanParticipation.value === 'quarterly') {
    return 'QUARTERLY';
  }
  if (
    entryDatesPlanParticipation.value === 'semiAnnually' ||
    entryDatesPlanParticipation.value === 'semiannually'
  ) {
    return 'SEMI_ANNUALLY';
  }
  return 'ANNUALLY';
};
