import { googleData } from '../../../Services/utilities';

import LetsBegin from './LetsBegin';

import TpaCheckList from './TpaCheckList';

import ClientInfo from './TpaQuestions/ClientInfo';
import TpaInformation from './TpaQuestions/TpaInformation';
import AddAdvisor from './TpaQuestions/AddAdvisor';
import TpaFees from './TpaQuestions/Fees';
import TpaFeesDeductions from './TpaQuestions/TpaFeesDeductions';
import ParticipantFees from './TpaQuestions/ParticipantFees';

import EligibleEmployees from './EligibleEmployees';
import EmployerPaidParticipantFees from './EmployerPaidParticipantFees';
import PlanEffectiveDate from './PlanEffectiveDate';
import StepQuestion from './StepQuestion';
import StepQuestions from './StepQuestions';
import AllowLoans from './AllowLoans';
import AutoEnrollment from './AutoEnrollmentQuestion';
import MaxAutoEscalateQuestion from './MaxAutoEscalateQuestion';
import AqacaMaxAutoEscalateQuestion from './AqacaMaxAutoEscalateQuestion';
import ContributionRequirements from './ContributionRequirements';
import SbQacaAutoEscalateQuestion from './SbQacaAutoEscalateQuestion';
import DiscretionarySharingContribution from './DiscretionarySharingContribution';
import DiscretionaryMatchContribution from './DiscretionaryMatchContribution';
import EntryDatesPlanParticipation from './EntryDatesPlanParticipation';
import BacaDiscretionaryMatchContribution from './BacaDiscretionaryMatchContribution';
import BacaDiscretionarySharingContribution from './BacaDiscretionarySharingContribution';
import BqacaDiscretionaryMatchContribution from './BqacaDiscretionaryMatchContribution';
import BqacaDiscretionarySharingContribution from './BqacaDiscretionarySharingContribution';
import BacaShareMinServiceDiscretionary from './BacaShareMinServiceDiscretionary';
import BacaShareEntryDiscretionary from './BacaShareEntryDiscretionary';
import BqacaMinAgeDiscretionary from './BqacaMinAgeDiscretionary';
import BStep12Aca2 from './BStep12Aca2';
import IsCurrentPrincipalPlan from '../../SetupPlan/DefaultPlan/IsCurrentPrincipalPlan';
import GrandfatherDate from '../../SetupPlan/DefaultPlan/GrandfatherDate';

import { stepsInfo } from './steps-info';
import { stepsOneToSix } from './steps-one-six';

export const components = {
  LetsBegin,
  TpaCheckList,
  ClientInfo,
  AddAdvisor,
  TpaInformation,
  EligibleEmployees,
  EmployerPaidParticipantFees,
  StepQuestion,
  StepQuestions,
  AllowLoans,
  PlanEffectiveDate,
  AutoEnrollment,
  TpaFees,
  TpaFeesDeductions,
  ParticipantFees,
  MaxAutoEscalateQuestion,
  AqacaMaxAutoEscalateQuestion,
  ContributionRequirements,
  SbQacaAutoEscalateQuestion,
  DiscretionarySharingContribution,
  DiscretionaryMatchContribution,
  EntryDatesPlanParticipation,
  BacaDiscretionaryMatchContribution,
  BacaDiscretionarySharingContribution,
  BqacaDiscretionaryMatchContribution,
  BqacaDiscretionarySharingContribution,
  BacaShareMinServiceDiscretionary,
  BacaShareEntryDiscretionary,
  BqacaMinAgeDiscretionary,
  BStep12Aca2,
  IsCurrentPrincipalPlan,
  GrandfatherDate,
};

export const tpaFormSteps = (
  formData,
  hashId,
  role = 'tpa', // eslint-disable-line default-param-last
  history,
  goToReviewPlan,
) => [
  // === Steps info
  ...stepsInfo(hashId, formData, history, formData.role),
  // === Steps 1 - 6
  ...stepsOneToSix(hashId, role, history, goToReviewPlan, formData),
  // === Section A
  {
    key: 'a_step_7_1',
    questionId: 'minAgeReqEligibility',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'a_step_8_2',
    previousStep: 'tpa_step_6',
    googleData: googleData('a_step_7_1'),
    showTracker: true,
    activeStepIndex: 6,
    totalSteps: 11,
  },
  {
    key: 'a_step_8_2',
    questionId: 'minServiceReqEligibility',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'a_step_8_3',
    previousStep: 'a_step_7_1',
    googleData: googleData('a_step_8_2'),
    showTracker: true,
    activeStepIndex: 7,
    totalSteps: 13,
  },
  {
    key: 'a_step_8_3',
    questionId: 'hasGrandfatherDate',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: false,
    destination: () =>
      formData.planDesign.questions.hasGrandfatherDate.value === 'yes'
        ? 'a_step_8_4'
        : 'a_step_9_3',
    previousStep: 'a_step_8_2',
    googleData: googleData('a_step_8_3'),
    showTracker: true,
    activeStepIndex: 8,
    totalSteps: 13,
  },
  {
    role,
    key: 'a_step_8_4',
    showTracker: true,
    singleSelection: false,
    questionId: 'grandfatherDateHasCustomDate',
    component: 'GrandfatherDate',
    hasSidebar: true,
    destination: () => 'a_step_9_3',
    goToReviewPlan,
    previousStep: 'a_step_8_3',
    googleData: googleData(`a_step_8_4`),
    activeStepIndex: 8,
    totalSteps: 13,
  },
  {
    key: 'a_step_9_3',
    questionId: 'entryDatesPlanParticipation',
    component: 'EntryDatesPlanParticipation',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'a_step_10_4',
    previousStep:
      formData.planDesign.questions.hasGrandfatherDate.value === 'yes'
        ? 'a_step_8_4'
        : 'a_step_8_3',
    googleData: googleData('a_step_9_3'),
    showTracker: true,
    activeStepIndex: 9,
    totalSteps: 13,
  },
  {
    key: 'a_step_10_4',
    questionId: 'autoEnrollment', // autoEnrollmentPlan
    subQuestionIds: ['autoEnrollmentPlan'],
    component: 'AutoEnrollment',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: (value) => {
      switch (value) {
        case 'aca':
        case 'eaca':
          return 'a_step_11_aca_1';
        case 'qaca':
          return 'a_step_11_qaca_1';
        default:
          return 'a_step_11_1';
      }
    },
    previousStep: 'a_step_9_3',
    googleData: googleData('a_step_10_4'),
    showTracker: true,
    activeStepIndex: 9,
    totalSteps: 16,
  },
  // === PATH IF USER CHOOSES YES > ACA OR YES > EACA IN STEP 10
  {
    key: 'a_step_11_aca_1',
    questionId: 'autoDeferralPercentageStartEmployees',
    subQuestionIds: [
      'autoEscalateAutoEnrolledDeferrals1perc',
      'maxAutoEscalatePercentage',
    ],
    singleSelection: true,
    component: 'MaxAutoEscalateQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_12_aca_2',
    previousStep: 'a_step_10_4',
    googleData: googleData('a_step_11_aca_1'),
    showTracker: true,
    activeStepIndex: 10,
    totalSteps: 16,
  },
  {
    key: 'a_step_12_aca_2',
    questionId: 'planUsingSafeHarbor',
    subQuestionIds: ['whichSafeHarbor'],
    singleSelection: true,
    component: 'StepQuestions',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_13_aca_3',
    previousStep: 'a_step_11_aca_1',
    googleData: googleData('a_step_12_aca_2'),
    showTracker: true,
    activeStepIndex: 11,
    totalSteps: 16,
  },
  {
    key: 'a_step_13_aca_3',
    questionId: 'aAca_hasDiscretionaryEmployerMatch',
    subQuestionIds: ['aAca_vestingScheduleMatch'],
    singleSelection: true,
    component: 'StepQuestions',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_14_aca_4',
    previousStep: 'a_step_12_aca_2',
    googleData: googleData('a_step_13_aca_3'),
    showTracker: true,
    activeStepIndex: 12,
    totalSteps: 16,
  },
  {
    key: 'a_step_14_aca_4',
    questionId: 'aAca_discretionarySharingContributions',
    subQuestionIds: ['aAca_vestingScheduleProfit'],
    singleSelection: true,
    component: 'StepQuestions',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_15_aca_5',
    previousStep: 'a_step_13_aca_3',
    googleData: googleData('a_step_14_aca_4'),
    showTracker: true,
    activeStepIndex: 13,
    totalSteps: 16,
  },
  {
    key: 'a_step_15_aca_5',
    questionId: 'aAca_allowLoans',
    singleSelection: true,
    component: 'AllowLoans',
    goToReviewPlan,
    destination: () => 'a_step_16_aca_6',
    hasSidebar: true,
    previousStep: 'a_step_14_aca_4',
    googleData: googleData('a_step_15_aca_5'),
    showTracker: true,
    activeStepIndex: 14,
    totalSteps: 16,
  },
  {
    key: 'a_step_16_aca_6',
    questionId: 'aAca_hardshipWithdrawals',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    destination: () => 'a_step_17_aca_7',
    hasSidebar: true,
    previousStep: 'a_step_15_aca_5',
    googleData: googleData('a_step_16_aca_6'),
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 16,
  },
  {
    role,
    key: 'a_step_17_aca_7',
    googleData: googleData('a_step_17_aca_7'),
    safeHarbor: 'planUsingSafeHarbor',
    questionId: 'aAca_planEffectiveDate',
    subQuestionIds: ['aAca_planName', 'aAca_planNumber'],
    component: 'PlanEffectiveDate',
    hasSidebar: true,
    singleSelection: true,
    goToReviewPlan,
    previousStep: 'a_step_16_aca_6',
    showTracker: true,
    activeStepIndex: 16,
    totalSteps: 16,
  },
  // === PATH IF USER CHOOSES YES > QACA IN STEP 10
  {
    key: 'a_step_11_qaca_1',
    questionId: 'autoEscalateAutoEnrolledDeferrals',
    subQuestionIds: [
      'aQaca_autoDeferralPercentageStartEmployees',
      'aQaca_maxAutoEscalatePercentage',
    ],
    singleSelection: true,
    component: 'AqacaMaxAutoEscalateQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_12_qaca_2',
    previousStep: 'a_step_10_4',
    googleData: googleData('a_step_11_qaca_1'),
    showTracker: true,
    activeStepIndex: 11,
    totalSteps: 18,
  },
  {
    key: 'a_step_12_qaca_2',
    questionId: 'whichSafeHarborQaca',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_13_qaca_3',
    previousStep: 'a_step_11_qaca_1',
    googleData: googleData('a_step_12_qaca_2'),
    showTracker: true,
    activeStepIndex: 12,
    totalSteps: 18,
  },
  {
    key: 'a_step_13_qaca_3',
    questionId: 'aQaca_vestingScheduleQaca',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_14_qaca_4',
    previousStep: 'a_step_12_qaca_2',
    googleData: googleData('a_step_13_qaca_3'),
    showTracker: true,
    activeStepIndex: 13,
    totalSteps: 18,
  },
  {
    key: 'a_step_14_qaca_4',
    questionId: 'aQaca_safeHarborDiscretionaryMatchingContributions',
    subQuestionIds: ['aQaca_vestingScheduleMatch'],
    singleSelection: true,
    component: 'StepQuestions',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_15_qaca_5',
    previousStep: 'a_step_13_qaca_3',
    googleData: googleData('a_step_14_qaca_4'),
    showTracker: true,
    activeStepIndex: 14,
    totalSteps: 18,
  },
  {
    key: 'a_step_15_qaca_5',
    questionId: 'aQaca_discretionarySharingContributions',
    subQuestionIds: ['aQaca_vestingScheduleProfit'],
    singleSelection: true,
    component: 'StepQuestions',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_16_qaca_6',
    previousStep: 'a_step_14_qaca_4',
    googleData: googleData('a_step_15_qaca_5'),
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 18,
  },
  {
    key: 'a_step_16_qaca_6',
    questionId: 'aQaca_allowLoans',
    singleSelection: true,
    component: 'AllowLoans',
    goToReviewPlan,
    destination: () => 'a_step_17_qaca_7',
    hasSidebar: true,
    previousStep: 'a_step_15_qaca_5',
    googleData: googleData('a_step_16_qaca_6'),
    showTracker: true,
    activeStepIndex: 16,
    totalSteps: 18,
  },
  {
    key: 'a_step_17_qaca_7',
    questionId: 'aQaca_hardshipWithdrawals',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    destination: () => 'a_step_18_qaca_8',
    hasSidebar: true,
    previousStep: 'a_step_16_qaca_6',
    googleData: googleData('a_step_17_qaca_7'),
    showTracker: true,
    activeStepIndex: 17,
    totalSteps: 18,
  },
  {
    role,
    key: 'a_step_18_qaca_8',
    googleData: googleData('a_step_18_qaca_8'),
    safeHarbor: 'whichSafeHarborQaca',
    questionId: 'aQaca_planEffectiveDate',
    subQuestionIds: ['aQaca_planName', 'aQaca_planNumber'],
    component: 'PlanEffectiveDate',
    hasSidebar: true,
    singleSelection: true,
    goToReviewPlan,
    previousStep: 'a_step_17_qaca_7',
    showTracker: true,
    activeStepIndex: 18,
    totalSteps: 18,
  },
  // === PATH IF USER CHOOSES NO IN STEP 10
  // a_step_11_1
  {
    key: 'a_step_11_1',
    questionId: 'a_planUsingSafeHarbor', // , whichSafeHarbor
    subQuestionIds: ['a_whichSafeHarbor'],
    singleSelection: true,
    component: 'StepQuestions',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_12_2',
    previousStep: 'a_step_10_4',
    googleData: googleData('a_step_11_1'),
    showTracker: true,
    activeStepIndex: 10,
    totalSteps: 15,
  },
  {
    key: 'a_step_12_2',
    questionId: 'hasDiscretionaryEmployerMatch',
    subQuestionIds: ['vestingScheduleMatch'],
    singleSelection: true,
    component: 'StepQuestions',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_13_3',
    previousStep: 'a_step_11_1',
    googleData: googleData('a_step_12_2'),
    showTracker: true,
    activeStepIndex: 11,
    totalSteps: 15,
  },
  {
    key: 'a_step_13_3',
    questionId: 'discretionarySharingContributions',
    subQuestionIds: ['vestingScheduleProfit'],
    singleSelection: true,
    component: 'StepQuestions',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'a_step_14_4',
    previousStep: 'a_step_12_2',
    googleData: googleData('a_step_13_3'),
    showTracker: true,
    activeStepIndex: 12,
    totalSteps: 15,
  },
  {
    key: 'a_step_14_4',
    questionId: 'allowLoans',
    singleSelection: true,
    component: 'AllowLoans',
    goToReviewPlan,
    destination: () => 'a_step_15_5',
    hasSidebar: true,
    previousStep: 'a_step_13_3',
    googleData: googleData('a_step_14_4'),
    showTracker: true,
    activeStepIndex: 13,
    totalSteps: 15,
  },
  {
    key: 'a_step_15_5',
    questionId: 'hardshipWithdrawals',
    component: 'StepQuestion',
    goToReviewPlan,
    destination: () => 'a_step_16_6',
    hasSidebar: true,
    singleSelection: true,
    previousStep: 'a_step_14_4',
    googleData: googleData('a_step_15_5'),
    showTracker: true,
    activeStepIndex: 14,
    totalSteps: 15,
  },
  {
    role,
    key: 'a_step_16_6',
    googleData: googleData('a_step_16_6'),
    safeHarbor: 'a_planUsingSafeHarbor',
    questionId: 'a_planEffectiveDate',
    subQuestionIds: ['a_planName', 'a_planNumber'],
    component: 'PlanEffectiveDate',
    hasSidebar: true,
    singleSelection: true,
    goToReviewPlan,
    previousStep: 'a_step_15_5',
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 15,
  },
  // --- Section B
  {
    key: 'b_step_7_1',
    questionId: 'b_minAgeReqEligibility',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    previousStep: 'tpa_step_6',
    destination: () => 'b_step_8_2',
    googleData: googleData('b_step_7_1'),
    showTracker: true,
    activeStepIndex: 6,
    totalSteps: 10,
  },
  {
    key: 'b_step_8_2',
    questionId: 'minServiceReqdiscretionaryProfitContribution',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    previousStep: 'b_step_7_1',
    destination: () => 'b_step_8_3',
    googleData: googleData('b_step_8_2'),
    showTracker: true,
    activeStepIndex: 7,
    totalSteps: 12,
  },
  {
    key: 'b_step_8_3',
    questionId: 'hasGrandfatherDate',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () =>
      formData.planDesign.questions.hasGrandfatherDate.value === 'yes'
        ? 'b_step_8_4'
        : 'b_step_9_3',
    previousStep: 'b_step_8_2',
    googleData: googleData('b_step_8_3'),
    showTracker: true,
    activeStepIndex: 8,
    totalSteps: 12,
  },
  {
    role,
    key: 'b_step_8_4',
    showTracker: true,
    singleSelection: true,
    questionId: 'grandfatherDateHasCustomDate',
    component: 'GrandfatherDate',
    hasSidebar: true,
    destination: () => 'b_step_9_3',
    goToReviewPlan,
    previousStep: 'b_step_8_3',
    googleData: googleData(`a_step_8_4`),
    activeStepIndex: 9,
    totalSteps: 12,
  },
  {
    key: 'b_step_9_3',
    questionId: 'entryDateDeferrals',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    previousStep:
      formData.planDesign.questions.hasGrandfatherDate.value === 'yes'
        ? 'b_step_8_4'
        : 'b_step_8_3',
    destination: () => 'b_step_10_4',
    googleData: googleData('b_step_9_3'),
    showTracker: true,
    activeStepIndex: 10,
    totalSteps: 12,
  },
  {
    key: 'b_step_10_4',
    questionId: 'b_autoEnrollment',
    subQuestionIds: ['b_autoEnrollmentPlan'],
    singleSelection: true,
    component: 'AutoEnrollment',
    goToReviewPlan,
    hasSidebar: true,
    destination: (value) => {
      switch (value) {
        case 'aca':
        case 'eaca':
          return 'b_step_11_aca_1';
        case 'qaca':
          return 'b_step_11_qaca_1';
        default:
          return 'b_step_11_1';
      }
    },
    previousStep: 'b_step_9_3',
    googleData: googleData('b_step_10_4'),
    showTracker: true,
    activeStepIndex: 9,
    totalSteps: 19,
  },
  // --- Section B
  // PATH IF USER CHOOSES YES > ACA OR YES > EACA
  {
    key: 'b_step_11_aca_1',
    questionId: 'bAca_autoDeferralPercentageStartEmployees',
    subQuestionIds: [
      'bAca_autoEscalateAutoEnrolledDeferrals1perc',
      'bAca_maxAutoEscalatePercentage',
    ],
    singleSelection: true,
    component: 'MaxAutoEscalateQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_12_aca_2',
    previousStep: 'b_step_10_4',
    googleData: googleData('b_step_11_aca_1'),
    showTracker: true,
    activeStepIndex: 10,
    totalSteps: 19,
  },
  {
    key: 'b_step_12_aca_2',
    questionId: 'bAca_planUsingSafeHarbor',
    subQuestionIds: ['bAca_whichSafeHarbor'],
    singleSelection: true,
    component: 'BStep12Aca2',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_13_aca_3',
    previousStep: 'b_step_11_aca_1',
    googleData: googleData('b_step_12_aca_2'),
    showTracker: true,
    activeStepIndex: 11,
    totalSteps: 19,
  },
  {
    key: 'b_step_13_aca_3',
    questionId: 'bAca_hasDiscretionaryEmployerMatch',
    subQuestionIds: ['bAcaShare_minAgeReqDiscretionaryMatchContributions'],
    singleSelection: true,
    component: 'BacaDiscretionaryMatchContribution',
    goToReviewPlan,
    hasSidebar: true,
    destination: (value) =>
      value === 'yes' ? 'b_step_14_aca_share_4' : 'b_step_16_aca_6',
    previousStep: 'b_step_12_aca_2',
    googleData: googleData('b_step_13_aca_3'),
    showTracker: true,
    activeStepIndex: 12,
    totalSteps: 19,
  },
  // IF YES, SHOW THESE ADDITIONAL QUESTIONS, IF NO PROCEED TO STEP
  {
    key: 'b_step_14_aca_share_4',
    questionId: 'bAcaShare_minServiceReqDiscretionaryMatchingContribution',
    singleSelection: true,
    component: 'BacaShareMinServiceDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_15_aca_share_5',
    previousStep: 'b_step_13_aca_3',
    googleData: googleData('b_step_14_aca_share_4'),
    showTracker: true,
    activeStepIndex: 13,
    totalSteps: 19,
  },
  {
    key: 'b_step_15_aca_share_5',
    questionId: 'bAcaShare_entryDatesDiscretionaryMatching',
    singleSelection: true,
    component: 'BacaShareEntryDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_16_aca_share_6',
    previousStep: 'b_step_14_aca_share_4',
    googleData: googleData('b_step_15_aca_share_5'),
    showTracker: true,
    activeStepIndex: 14,
    totalSteps: 19,
  },
  {
    key: 'b_step_16_aca_share_6',
    questionId: 'bAcaShare_vestingScheduleMatch',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_16_aca_6',
    previousStep: 'b_step_13_aca_share_5',
    googleData: googleData('b_step_16_aca_share_6'),
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 19,
  },
  // IF NO PROCEED

  {
    key: 'b_step_16_aca_6',
    questionId: 'bAca_discretionarySharingContributions',
    subQuestionIds: ['bAca_minAgeReqDiscretionaryProfitContributions'],
    singleSelection: true,
    component: 'BacaDiscretionarySharingContribution',
    goToReviewPlan,
    hasSidebar: true,
    destination: (value) =>
      value === 'yes' ? 'b_step_17_aca_share_7' : 'b_step_20_aca_10',
    previousStep: 'b_step_13_aca_3',
    googleData: googleData('b_step_16_aca_6'),
    showTracker: true,
    activeStepIndex: 12,
    totalSteps: 19,
  },
  // IF YES, SHOW THESE ADDITIONAL QUESTIONS
  {
    key: 'b_step_17_aca_share_7',
    questionId: 'bAcaShare_minServiceReqdiscretionaryProfitContribution',
    singleSelection: true,
    component: 'BacaShareMinServiceDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_18_aca_share_8',
    previousStep: 'b_step_16_aca_6',
    googleData: googleData('b_step_17_aca_share_7'),
    showTracker: true,
    activeStepIndex: 13,
    totalSteps: 19,
  },
  {
    key: 'b_step_18_aca_share_8',
    questionId: 'bAcaShare_entryDateDiscretionaryProfitContribution',
    singleSelection: true,
    component: 'BacaShareEntryDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_19_aca_share_9',
    previousStep: 'b_step_17_aca_share_7',
    googleData: googleData('b_step_18_aca_share_8'),
    showTracker: true,
    activeStepIndex: 14,
    totalSteps: 19,
  },
  {
    key: 'b_step_19_aca_share_9',
    questionId: 'bAcaShare_vestingScheduleProfit',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_20_aca_10',
    previousStep: 'b_step_18_aca_share_8',
    googleData: googleData('b_step_19_aca_share_9'),
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 19,
  },
  // IF NO PROCEED TO STEP
  {
    key: 'b_step_20_aca_10',
    questionId: 'bAca_allowLoans',
    singleSelection: true,
    component: 'AllowLoans',
    goToReviewPlan,
    destination: () => 'b_step_21_aca_11',
    hasSidebar: true,
    previousStep: 'b_step_16_aca_6',
    googleData: googleData('b_step_20_aca_10'),
    showTracker: true,
    activeStepIndex: 17,
    totalSteps: 19,
  },
  {
    key: 'b_step_21_aca_11',
    questionId: 'bAca_hardshipWithdrawals',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    destination: () => 'b_step_22_aca_12',
    hasSidebar: true,
    previousStep: 'b_step_20_aca_10',
    googleData: googleData('b_step_21_aca_11'),
    showTracker: true,
    activeStepIndex: 18,
    totalSteps: 19,
  },
  {
    role,
    key: 'b_step_22_aca_12',
    googleData: googleData('b_step_22_aca_12'),
    safeHarbor: 'bAca_planUsingSafeHarbor',
    questionId: 'bAca_planEffectiveDate',
    subQuestionIds: ['bAca_planName', 'bAca_planNumber'],
    component: 'PlanEffectiveDate',
    hasSidebar: true,
    singleSelection: true,
    goToReviewPlan,
    previousStep: 'b_step_21_aca_11',
    showTracker: true,
    activeStepIndex: 19,
    totalSteps: 19,
  },
  // --- Section B
  // PATH IF USER CHOOSES YES > QACA
  {
    key: 'b_step_11_qaca_1',
    questionId: 'hasAutoEscalation',
    subQuestionIds: [
      'bQaca_autoDeferralPercentageStartEmployees',
      'bQaca_maxAutoEscalatePercentage',
    ],
    singleSelection: true,
    component: 'AqacaMaxAutoEscalateQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_12_qaca_2',
    previousStep: 'b_step_10_4',
    googleData: googleData('b_step_11_qaca_1'),
    showTracker: true,
    activeStepIndex: 10,
    totalSteps: 24,
  },
  {
    key: 'b_step_12_qaca_2',
    questionId: 'bQaca_whichSafeHarborQaca',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_13_qaca_3',
    previousStep: 'b_step_11_qaca_1',
    googleData: googleData('b_step_12_qaca_2'),
    showTracker: true,
    activeStepIndex: 11,
    totalSteps: 24,
  },
  {
    key: 'b_step_13_qaca_3',
    questionId: 'bQaca_vestingScheduleQaca',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_14_qaca_4',
    previousStep: 'b_step_12_qaca_2',
    googleData: googleData('b_step_13_qaca_3'),
    showTracker: true,
    activeStepIndex: 12,
    totalSteps: 24,
  },
  {
    key: 'b_step_14_qaca_4',
    questionId: 'bQaca_safeHarborDiscretionaryMatchingContributions',
    component: 'BqacaDiscretionaryMatchContribution',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: (value) =>
      value === 'yes' ? 'b_step_15_qaca_share_5' : 'b_step_19_qaca_9',
    previousStep: 'b_step_13_qaca_3',
    googleData: googleData('b_step_14_qaca_4'),
    showTracker: true,
    activeStepIndex: 13,
    totalSteps: 24,
  },

  // IF YES TO DISCRETIONARY PROFIT-SHARING CONTRIBUTIONS, SHOW THESE ADDITIONAL QUESTIONS:
  {
    key: 'b_step_15_qaca_share_5',
    questionId: 'bQacaShare_minAgeReqDiscretionaryMatchContributions',
    component: 'BqacaMinAgeDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_16_qaca_share_6',
    previousStep: 'b_step_14_qaca_4',
    googleData: googleData('b_step_15_qaca_share_5'),
    showTracker: true,
    activeStepIndex: 14,
    totalSteps: 24,
  },
  {
    key: 'b_step_16_qaca_share_6',
    questionId: 'bQacaShare_minServiceReqDiscretionaryMatchingContribution',
    component: 'BacaShareMinServiceDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_17_qaca_share_7',
    previousStep: 'b_step_15_qaca_share_5',
    googleData: googleData('b_step_16_qaca_share_6'),
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 24,
  },
  {
    key: 'b_step_17_qaca_share_7',
    questionId: 'bQacaShare_entryDatesDiscretionaryMatching',
    component: 'BacaShareEntryDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_18_qaca_share_8',
    previousStep: 'b_step_16_qaca_share_6',
    googleData: googleData('b_step_17_qaca_share_7'),
    showTracker: true,
    activeStepIndex: 16,
    totalSteps: 24,
  },
  {
    key: 'b_step_18_qaca_share_8',
    questionId: 'bQacaShare_vestingScheduleMatch',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_19_qaca_9',
    previousStep: 'b_step_17_qaca_share_7',
    googleData: googleData('b_step_18_qaca_share_8'),
    showTracker: true,
    activeStepIndex: 17,
    totalSteps: 24,
  },

  // IF NO TO DISCRETIONARY PROFIT-SHARING CONTRIBUTIONS, CONTINUE:
  {
    key: 'b_step_19_qaca_9',
    questionId: 'bQaca_discretionarySharingContributions',
    subQuestionIds: ['bQacaShare_minAgeReqDiscretionaryProfitContributions'],
    component: 'BqacaDiscretionarySharingContribution',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: (value) =>
      value === 'yes' ? 'b_step_20_qaca_share_10' : 'b_step_23_qaca_13',
    previousStep: 'b_step_14_qaca_4',
    googleData: googleData('b_step_19_qaca_9'),
    showTracker: true,
    activeStepIndex: 18,
    totalSteps: 24,
  },
  // IF YES TO DISCRETIONARY PROFIT-SHARING CONTRIBUTIONS, SHOW THESE ADDITIONAL QUESTIONS
  {
    key: 'b_step_20_qaca_share_10',
    questionId: 'bQacaShare_minServiceReqDiscretionaryProfit',
    component: 'BacaShareMinServiceDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_21_qaca_share_11',
    previousStep: 'b_step_19_qaca_9',
    googleData: googleData('b_step_20_qaca_share_10'),
    showTracker: true,
    activeStepIndex: 19,
    totalSteps: 24,
  },
  {
    key: 'b_step_21_qaca_share_11',
    questionId: 'bQacaShare_entryDateDiscretionaryProfitContribution',
    component: 'BacaShareEntryDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_22_qaca_share_12',
    previousStep: 'b_step_20_qaca_share_10',
    googleData: googleData('b_step_21_qaca_share_11'),
    showTracker: true,
    activeStepIndex: 20,
    totalSteps: 24,
  },
  {
    key: 'b_step_22_qaca_share_12',
    questionId: 'bQacaShare_vestingScheduleProfit',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_23_qaca_13',
    previousStep: 'b_step_21_qaca_share_11',
    googleData: googleData('b_step_22_qaca_share_12'),
    showTracker: true,
    activeStepIndex: 21,
    totalSteps: 24,
  },

  // IF NO TO DISCRETIONARY PROFIT-SHARING CONTRIBUTIONS
  {
    key: 'b_step_23_qaca_13',
    questionId: 'bQaca_allowLoans',
    component: 'AllowLoans',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_24_qaca_14',
    previousStep: 'b_step_19_qaca_9',
    googleData: googleData('b_step_23_qaca_13'),
    showTracker: true,
    activeStepIndex: 22,
    totalSteps: 24,
  },
  {
    key: 'b_step_24_qaca_14',
    questionId: 'bQaca_hardshipWithdrawals',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    destination: () => 'b_step_25_qaca_15',
    hasSidebar: true,
    previousStep: 'b_step_23_qaca_13',
    googleData: googleData('b_step_24_qaca_14'),
    showTracker: true,
    activeStepIndex: 23,
    totalSteps: 24,
  },
  {
    role,
    key: 'b_step_25_qaca_15',
    googleData: googleData('b_step_25_qaca_15'),
    safeHarbor: 'bQaca_whichSafeHarborQaca',
    questionId: 'bQaca_planEffectiveDate',
    subQuestionIds: ['bQaca_planName', 'bQaca_planNumber'],
    component: 'PlanEffectiveDate',
    hasSidebar: true,
    singleSelection: true,
    goToReviewPlan,
    previousStep: 'b_step_24_qaca_14',
    showTracker: true,
    activeStepIndex: 24,
    totalSteps: 24,
  },
  // === PATH IF USER CHOOSES NO IN STEP 10
  // b_step_11_1
  {
    key: 'b_step_11_1',
    questionId: 'b_planUsingSafeHarbor',
    subQuestionIds: ['b_whichSafeHarbor'],
    component: 'StepQuestions',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_12_2',
    previousStep: 'b_step_10_4',
    googleData: googleData('b_step_11_1'),
    showTracker: true,
    activeStepIndex: 10,
    totalSteps: 18,
  },
  {
    key: 'b_step_12_2',
    questionId: 'b_hasDiscretionaryEmployerMatch',
    subQuestionIds: ['b_minAgeReqDiscretionaryMatchContributions'],
    singleSelection: true,
    component: 'DiscretionaryMatchContribution',
    goToReviewPlan,
    hasSidebar: true,
    destination: (value) =>
      value === 'yes' ? 'b_step_13_sharing_3' : 'b_step_16_6',
    previousStep: 'b_step_11_1',
    googleData: googleData('b_step_12_2'),
    showTracker: true,
    activeStepIndex: 11,
    totalSteps: 18,
  },
  // IF YES TO DISCRETIONARY MATCHING CONTRIBUTIONS, SHOW THESE ADDITIONAL QUESTIONS:
  {
    key: 'b_step_13_sharing_3',
    questionId: 'bShare_minServiceReqDiscretionaryMatchingContribution',
    component: 'BacaShareMinServiceDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_14_sharing_4',
    previousStep: 'b_step_12_2',
    googleData: googleData('b_step_13_sharing_3'),
    showTracker: true,
    activeStepIndex: 12,
    totalSteps: 18,
  },
  {
    key: 'b_step_14_sharing_4',
    questionId: 'bShare_entryDatesDiscretionaryMatching',
    component: 'BacaShareEntryDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_15_sharing_5',
    previousStep: 'b_step_13_sharing_3',
    googleData: googleData('b_step_14_sharing_4'),
    showTracker: true,
    activeStepIndex: 13,
    totalSteps: 18,
  },
  {
    key: 'b_step_15_sharing_5',
    questionId: 'bShare_vestingScheduleMatch',
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    singleSelection: true,
    destination: () => 'b_step_16_6',
    previousStep: 'b_step_14_sharing_4',
    googleData: googleData('b_step_15_sharing_5'),
    showTracker: true,
    activeStepIndex: 14,
    totalSteps: 18,
  },
  // Answer no on step b_step_12_2
  {
    key: 'b_step_16_6',
    questionId: 'b_discretionarySharingContributions',
    subQuestionIds: ['b_minAgeReqDiscretionaryProfitContributions'],
    singleSelection: true,
    component: 'DiscretionarySharingContribution',
    goToReviewPlan,
    hasSidebar: true,
    destination: (value) =>
      value === 'yes' ? 'b_step_17_share_7' : 'b_step_17_7',
    previousStep: 'b_step_12_2',
    googleData: googleData('b_step_16_6'),
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 18,
  },
  // IF YES TO DISCRETIONARY PROFIT-SHARING CONTRIBUTIONS, SHOW THESE ADDITIONAL QUESTIONS:
  {
    key: 'b_step_17_share_7',
    questionId: 'bShare_minServiceReqDiscretionaryProfit',
    singleSelection: true,
    component: 'BacaShareMinServiceDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_18_share_8',
    previousStep: 'b_step_16_6',
    googleData: googleData('b_step_17_share_7'),
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 18,
  },
  {
    key: 'b_step_18_share_8',
    questionId: 'bShare_entryDateDiscretionaryProfitContribution',
    singleSelection: true,
    component: 'BacaShareEntryDiscretionary',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_19_share_9',
    previousStep: 'b_step_17_share_7',
    googleData: googleData('b_step_18_share_8'),
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 18,
  },
  {
    key: 'b_step_19_share_9',
    questionId: 'bShare_vestingScheduleProfit',
    singleSelection: true,
    component: 'StepQuestion',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_17_7',
    previousStep: 'b_step_18_share_8',
    googleData: googleData('b_step_19_share_9'),
    showTracker: true,
    activeStepIndex: 15,
    totalSteps: 18,
  },
  // Answer no on step b_step_16_6
  {
    key: 'b_step_17_7',
    questionId: 'b_allowLoans',
    singleSelection: true,
    component: 'AllowLoans',
    goToReviewPlan,
    hasSidebar: true,
    destination: () => 'b_step_18_8',
    previousStep: 'b_step_16_6',
    googleData: googleData('b_step_17_7'),
    showTracker: true,
    activeStepIndex: 16,
    totalSteps: 18,
  },
  {
    key: 'b_step_18_8',
    questionId: 'b_hardshipWithdrawals',
    component: 'StepQuestion',
    goToReviewPlan,
    destination: () => 'b_step_19_9',
    hasSidebar: true,
    singleSelection: true,
    previousStep: 'b_step_17_7',
    googleData: googleData('b_step_18_8'),
    showTracker: true,
    activeStepIndex: 17,
    totalSteps: 18,
  },
  {
    role,
    key: 'b_step_19_9',
    googleData: googleData('b_step_19_9'),
    safeHarbor: 'b_planUsingSafeHarbor',
    questionId: 'b_planEffectiveDate',
    subQuestionIds: ['b_planName', 'b_planNumber'],
    component: 'PlanEffectiveDate',
    hasSidebar: true,
    singleSelection: true,
    goToReviewPlan,
    previousStep: 'b_step_18_8',
    showTracker: true,
    activeStepIndex: 18,
    totalSteps: 18,
  },
];
