import React from 'react';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { Question } from '../../../../../common/FormWithSteps';
import { useTpaFirmApi } from './useTpaFirmApi';

const Form = ({
  role,
  handleQuestionChange,
  handleQuestionChangeTPA,
  handleQuestionChangeTpaPhone,
  handleTpaFirmUnlisted,
  handleTpaFirmSelection,
  primaryTPA,
  tpaIndex,
  tpas,
  putApplicationPanelInfo,
  questions,
  tpaFirmUnlisted,
}) => {
  const { tpaFirmName, employerIdentificationNumber, tpaFirmNameUnlisted } =
    questions;
  const { tpaFirstName, tpaLastName, tpaEmail } = primaryTPA.questions;
  const [{ tpaFirmOptions, isLoading, isError }] = useTpaFirmApi();
  return (
    <fieldset>
      <div className="row">
        <div className="col-md">
          <Question
            role="salesContact" // eslint-disable-line jsx-a11y/aria-role
            uniqueId="salesContactFirstName"
            type={tpaFirstName.type}
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaFirstName}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChangeTPA({
                name,
                updateValue,
                valueFormatted,
                tpaIndex,
              });
            }}
          >
            {tpaFirstName.hasError && <small>Question is required</small>}
          </Question>
        </div>
        <div className="col-md">
          <Question
            role="salesContact" // eslint-disable-line jsx-a11y/aria-role
            uniqueId="salesContactLastName"
            type={tpaLastName.type}
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaLastName}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChangeTPA({
                name,
                updateValue,
                valueFormatted,
                tpaIndex,
              });
            }}
          >
            {tpaLastName.hasError && <small>Question is required</small>}
          </Question>
        </div>
        <div className="col-md">
          <Question
            role="salesContact" // eslint-disable-line jsx-a11y/aria-role
            uniqueId="salesContactEmail"
            type={tpaEmail.type}
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaEmail}
            handleQuestionChange={(name, updateValue, valueFormatted) => {
              handleQuestionChangeTPA({
                name,
                updateValue,
                valueFormatted,
                tpaIndex,
              });
            }}
          >
            {tpaEmail.hasError && <small>Question is required</small>}
          </Question>
        </div>
      </div>

      <hr />
      {tpas.map((tpa, index) => {
        if (index === 0) return false;
        return (
          <div key={tpa.tpaIndex} id="secondaryTpa">
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  uniqueId={`opContactFirstName_${index}`}
                  type={tpa.questions.tpaFirstName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={tpa.questions.tpaFirstName}
                  handleQuestionChange={(name, updateValue, valueFormatted) => {
                    handleQuestionChangeTPA({
                      name,
                      updateValue,
                      valueFormatted,
                      tpaIndex: index,
                    });
                  }}
                >
                  {tpa.questions.tpaFirstName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
              <div className="col-md">
                <Question
                  role={role}
                  uniqueId={`opContactLastName_${index}`}
                  type={tpa.questions.tpaLastName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={tpa.questions.tpaLastName}
                  handleQuestionChange={(name, updateValue, valueFormatted) => {
                    handleQuestionChangeTPA({
                      name,
                      updateValue,
                      valueFormatted,
                      tpaIndex: index,
                    });
                  }}
                >
                  {tpa.questions.tpaLastName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  uniqueId={`opContactEmail_${index}`}
                  type={tpa.questions.tpaEmail.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={tpa.questions.tpaEmail}
                  handleQuestionChange={(name, updateValue, valueFormatted) => {
                    handleQuestionChangeTPA({
                      name,
                      updateValue,
                      valueFormatted,
                      tpaIndex: index,
                    });
                  }}
                >
                  {tpa.questions.tpaEmail.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
              <div className="col-md-6">
                <div
                  className={classNames('mb-3 form-group', {
                    'has-error': tpa.questions.tpaPhone.hasError,
                  })}
                >
                  <label
                    className="fs-form-label fw-bold"
                    htmlFor={`opContactPhone_${index}`}
                  >
                    Operational contact phone number
                  </label>
                  <PatternFormat
                    id={`opContactPhone_${index}`}
                    value={tpa.questions.tpaPhone.value}
                    format="###-###-####"
                    mask="_"
                    className={classNames('srp-form-input form-control', {
                      'is-invalid': tpa.questions.tpaPhone.hasError,
                      'is-dirty': tpa.questions.tpaPhone.dirty,
                    })}
                    name={tpa.questions.tpaPhone.name}
                    required
                    onValueChange={(values) => {
                      handleQuestionChangeTpaPhone({
                        name: tpa.questions.tpaPhone.name,
                        updateValue: values.value,
                        valueFormatted: values.formattedValue,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="row">
        <div className="col-md">
          <div
            className={
              tpaFirmName.value.value === 'myFirmIsntListed'
                ? 'd-none'
                : 'd-block'
            }
          >
            <Question
              role={role}
              type={tpaFirmName.type}
              labelClass="fs-form-label fw-bold text-center"
              inputClass="srp-form-input form-control"
              question={{
                ...tpaFirmName,
                disabled: isLoading || isError,
                options: tpaFirmOptions,
              }}
              handleQuestionChange={handleTpaFirmSelection}
            >
              {tpaFirmName.hasError && <small>Question is required</small>}
            </Question>
          </div>
          <div
            className={
              tpaFirmName.value.value !== 'myFirmIsntListed'
                ? 'd-none'
                : 'd-block'
            }
          >
            <Question
              role={role}
              type={tpaFirmNameUnlisted.type}
              labelClass="fs-form-label fw-bold text-center"
              inputClass="srp-form-input form-control"
              question={tpaFirmNameUnlisted}
              handleQuestionChange={handleQuestionChange}
            >
              {tpaFirmName.hasError && <small>Question is required</small>}
            </Question>
          </div>
        </div>
        <div className="col-md">
          <Question
            role={role}
            type={employerIdentificationNumber.type}
            labelClass="fs-form-label fw-bold text-center"
            inputClass="srp-form-input form-control"
            question={employerIdentificationNumber}
            handleQuestionChange={handleQuestionChange}
          >
            {employerIdentificationNumber.hasError && (
              <small>Question is required</small>
            )}
            <small>
              <button
                type="button"
                className="srp-button__link"
                onClick={() =>
                  putApplicationPanelInfo({
                    hasVisibility: true,
                    heading: 'TPA Employer Identification Number (EIN)',
                    additionalInformation:
                      'The Employer Identification Number (EIN) will be used as your firm’s unique identifier.',
                  })
                }
              >
                More information
              </button>
            </small>
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <div className="form-group form-check custom-checkbox">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="tpaFirmUnlisted"
              checked={tpaFirmUnlisted}
              onChange={(e) => handleTpaFirmUnlisted(e)}
            />
            <label
              className="form-check-label fw-bold"
              htmlFor="tpaFirmUnlisted"
            >
              My firm isn’t listed or my TPA EIN is incorrect.
            </label>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

Form.propTypes = {
  role: PropTypes.string,
  questions: PropTypes.object,
  handleQuestionChangeTPA: PropTypes.func.isRequired,
  handleQuestionChangeTpaPhone: PropTypes.func.isRequired,
  tpas: PropTypes.array.isRequired,
  primaryTPA: PropTypes.object.isRequired,
  tpaIndex: PropTypes.number.isRequired,
  handleTpaFirmUnlisted: PropTypes.func.isRequired,
  handleTpaFirmSelection: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  tpaFirmUnlisted: PropTypes.bool.isRequired,
};

export default Form;
