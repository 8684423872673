import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Label = ({ label, children, labelStyle, containerStyle }) => (
  <p className={classNames('lh-100', containerStyle)}>
    <span className="fs-tablelabel lh-125 mb-1">{label}</span>{' '}
    <strong
      className={classNames('fs-h4 text-purple d-block', labelStyle)}
      style={{ overflowWrap: 'break-word' }}
    >
      {children}
    </strong>
  </p>
);

Label.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  labelStyle: PropTypes.string,
  containerStyle: PropTypes.string,
};

export default Label;
