export const getInvestmentLineup = (investmentLineup) => {
  switch (investmentLineup.value) {
    case 'fundLineup1':
      return 'principal_std';
    case 'fundLineup2':
      return 'blackrock';
    case 'fundLineupOneDigital':
      return 'onedigital';
    default:
      return 'american';
  }
};
