import React from 'react';

const AdvisorFee = () => (
  <div className="py-4 px-5">
    <p className="fs-p fw-bold mb-0">Financial professional and TPA fees</p>
    <p className="fs-p">
      If you choose to work with a financial professional and/or a TPA, they
      will also have a fee for providing their expertise and assistance with the
      setup and management of your plan. You may pay this fee directly to your
      financial professional and/or a TPA. Or, financial professional fees can
      be deducted from participant accounts. (TPA fees must be billed directly).
    </p>
  </div>
);

export default AdvisorFee;
