import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Question } from '../../../../../common/FormWithSteps';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../../../Redux/FormDataRedux';

const Form = ({
  showTpaCompensationType,
  handleQuestionChangeType,
  showFixedAmount,
  questions,
  handleQuestionChange,
  showBpsAmount,
  role,
  handleQuestionChangeCompensation,
  putApplicationPanelInfo,
}) => {
  const { tpaCompensationType, fixedAmount, bpsAmount, feeCollectionSource } =
    questions;
  const { dirty, hasError } = feeCollectionSource;
  const validate = !!(dirty && hasError);

  return (
    <div>
      <p className="text-center">
        TPA fees deducted from plan assets will be included in Ubiquity’s fee
        disclosure for new business. This includes flat fee or basis point TPA
        compensation taken from plan assets, as well any TPA transaction fees.
      </p>
      <Question
        role={role}
        type={feeCollectionSource.type}
        labelClass="fs-h4 fw-bold text-center"
        inputClass="srp-form-input form-control"
        radioWrapper="srp-form-toggle"
        question={feeCollectionSource}
        handleQuestionChange={handleQuestionChangeCompensation}
      >
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        <button
          type="button"
          className="srp-button__link"
          onClick={() =>
            putApplicationPanelInfo({
              hasVisibility: true,
              heading: 'Need clarification?',
              additionalInformation: `If you choose to be compensated from plan assets, you can choose a flat fee (not to exceed $5,000 annually), or a basis point amount (not to exceed 250 basis points annually based on assets under management). TPA compensation is calculated by Ubiquity, in arrears, each calendar quarter (FMV) at the close of market on the Last day of each quarter. To calculate TPA compensation, the TPA’s annual fee will be divided by (4) an multiplied by the plan’s FMV on the last calendar day of the quarter. If the TPA was not associated with the plan for the entire quarter, the TPA’s quarterly rate will be multiplied by the number of days that the TPA was associated to the plan, divided by the number of days in the quarter. Ubiquity will direct the Plan’s custodian, Matrix, to liquidate the compensation from the pan assets to cover the compensation amount requested by the TPA and approved by the trustee of the plan. This expense will be shared pro-rata by plan participants with a balance in the plan. Ubiquity will facilitate and ensure compensation is paid to the TPA no later than the 45th day after the end of each quarter. For new business, TPA fees deducted from plan assets will be included in Ubiquity’s fee disclosure. This includes flat fee or basis point TPA compensation taken from plan assets, as well any TPA transaction fees. TPA setup cost, annual base cost, and/or per participant cost will be collected outside of the plan by the TPA and the TPA will be responsible for the collection and disclosure of these fees to the plan sponsor.`,
            })
          }
        >
          Need clarification?
        </button>
      </Question>

      {showTpaCompensationType && (
        <Question
          type={tpaCompensationType.type}
          question={tpaCompensationType}
          handleQuestionChange={handleQuestionChangeType}
          radioWrapper="srp-form-toggle"
          labelClass="fs-h4 fw-bold text-center"
        >
          {tpaCompensationType.disabled && (
            <p className="text-center text-muted my-3">
              Your broker-dealer does not allow changing this option.
            </p>
          )}
        </Question>
      )}
      {showFixedAmount && (
        <div
          className={classNames('form-group text-center', {
            'has-error': fixedAmount.hasError,
          })}
        >
          <label className="fs-h4 fw-bold" htmlFor={fixedAmount.name}>
            {fixedAmount.label}
          </label>
          {fixedAmount.infoLabel && (
            <div
              className="fs-p text-center mb-2"
              dangerouslySetInnerHTML={{ __html: fixedAmount.infoLabel }}
            />
          )}
          <NumericFormat
            style={{ width: 'auto', margin: 'auto' }}
            id={fixedAmount.name}
            prefix={fixedAmount.valuePrefix}
            allowNegative={false}
            className={classNames('fs-h3 srp-form-input form-control', {
              'is-invalid': fixedAmount.hasError,
              'is-dirty': fixedAmount.dirty,
            })}
            required
            name={fixedAmount.name}
            thousandSeparator
            value={fixedAmount.value}
            onValueChange={(values) => {
              handleQuestionChange(
                fixedAmount.name,
                values.value,
                values.formattedValue,
              );
            }}
          />
          {fixedAmount.hasError && (
            <p className="text-center text-danger my-3">
              Yearly fee must be less than $5,000
            </p>
          )}
        </div>
      )}

      {showBpsAmount && (
        <div
          className={classNames('form-group text-center', {
            'has-error': bpsAmount.hasError,
          })}
        >
          <label className="fs-h4 fw-bold" htmlFor={bpsAmount.name}>
            {bpsAmount.label}
          </label>
          {bpsAmount.infoLabel && (
            <div
              className="fs-p text-center mb-2"
              dangerouslySetInnerHTML={{ __html: bpsAmount.infoLabel }}
            />
          )}
          <NumericFormat
            style={{ width: 'auto', margin: 'auto' }}
            id="bpsAmount"
            allowNegative={false}
            className={classNames('fs-h3 srp-form-input form-control', {
              'is-invalid': bpsAmount.hasError,
              'is-dirty': bpsAmount.dirty,
            })}
            required
            name={bpsAmount.name}
            value={bpsAmount.value}
            onValueChange={(values) => {
              handleQuestionChange(
                bpsAmount.name,
                values.value,
                values.formattedValue,
              );
            }}
          />
          {bpsAmount.hasError && (
            <p className="text-center text-danger my-3">
              Quarterly fee must be less than 250
            </p>
          )}
        </div>
      )}
    </div>
  );
};

Form.propTypes = {
  showTpaCompensationType: PropTypes.bool.isRequired,
  handleQuestionChangeType: PropTypes.func.isRequired,
  showFixedAmount: PropTypes.bool.isRequired,
  questions: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  showBpsAmount: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  handleQuestionChangeCompensation: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
