import React from 'react';
import { HeroWrapper } from './index.styles';

const HeroSection = () => (
  <div className="disclosures-page mb-5">
    <HeroWrapper className="hero mb-5 pt-5">
      <div className="container-fluid container-fluid--sitewidth">
        <div className="row justify-content-center py-5">
          <h1 className="fs-h1 text-center fw-bold py-5 col-xl-6 col-lg-8 col-md-10 mt-5">
            Disclosures
          </h1>
        </div>
      </div>
    </HeroWrapper>
    <div className="container pb-5">
      <div className="row pb-5">
        <div className="col-md-9 mx-auto">
          <p className="fs-p">
            Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            401(k) plan recordkeeping and administrative services are provided
            through Decimal, Inc. dba Ubiquity Retirement + Savings
            <sup>
              <small>®</small>
            </sup>{' '}
            (“Ubiquity”). Ubiquity is not affiliated with any plan and
            investment administrative services provided through Principal Life
            Insurance Company, or affiliated with any company of the Principal
            Financial Group
            <sup>
              <small>®</small>
            </sup>
            . Principal makes available the Separate Accounts and collective
            investment trusts for customers to select them through Simply
            Retirement by Principal
            <sup>
              <small>®</small>
            </sup>
            . All other services and mutual funds are provided by service
            providers not affiliated with any company of the Principal Financial
            Group. Refer to related documents and agreements for more details on
            plan services available.
          </p>
          <p className="fs-p">
            Mutual fund, separate account and collective investment trust trade
            instructions are processed by Matrix Settlement & Clearance
            Services, LLC through the NSCC Membership of its affiliate, Matrix
            Trust Company.
          </p>
          <p className="fs-p">
            Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            requires a client service agreement between Ubiquity and the plan
            sponsor (Customer). In addition to and as part of the services, each
            Customer will sign separate agreements with the following parties:
            (i) custodian (ii) 3(38) investment manager, and (iii) a Principal
            Life Insurance Company, a Principal Comprehensive Retirement Program
            (PCRP) offered through the Modular Group Annuity Contract for
            separate accounts, and a declaration of trust for Principal
            collective investment funds. Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            services and costs are subject to change.
          </p>
          <p className="fs-p">
            The Morley Stable Value Fund (the Fund), is a collective investment
            trust maintained by Principal Global Investors Trust Company, (the
            Trust Company). Principal Morley is an investment team within
            Principal Global Investors (the Adviser), serves as investment
            manager with respect to the Fund, subject to the Trust
            Company&apos;s supervision and review. Principal Asset Management
            <sup>
              <small>SM</small>
            </sup>{' '}
            is a trade name of Principal Global Investors, LLC. The Adviser is
            an indirect wholly owned subsidiary of Principal Financial Group
            <sup>
              <small>®</small>
            </sup>{' '}
            and is under common control with the Trust Company.
          </p>
          <p className="fs-p">
            Principal Global Investors, LLC is registered with the U.S.
            Commodity Futures Trading Commission (CFTC) as a commodity trading
            advisor (CTA), a commodity pool operator (CPO) and is a member of
            the National Futures Association (NFA). Principal Global Investors
            advises qualified eligible persons (QEPs) under CFTC Regulation 4.7.
          </p>
          <p className="fs-p">
            The Fund is not a mutual fund and is not registered with the
            Securities and Exchange Commission. The Trust Company is regulated
            by the State of Oregon. Units of the Fund are not deposits or
            obligations of, guaranteed by, or insured by the Trust Company or
            any affiliate, and are not insured by the FDIC or any other federal
            or state government agency. The value of the Fund may fluctuate so
            that when redeemed, units may be worth more or less than the
            original cost.
          </p>
          <p className="fs-p">
            The declaration of trust, participation agreement, and disclosure
            documents contain important information about investment objectives,
            risks, fees and expenses associated with investment in the Fund and
            should be read carefully before investing.
          </p>
          <p className="fs-p">
            Direct investment in the Fund is limited to participating trusts
            (also known as investing plan/trust) that meet certain requirements
            described in the declaration of trust, that enter into a
            participation agreement with the Trust Company. The Fund cannot
            accept investment directly from individuals and is subject to
            restrictions regarding transfer and withdrawal of assets including
            potential deferral of withdrawal requests by up to 12 months, as
            defined in the applicable declaration of trust.
          </p>
          <p className="fs-p">
            The Principal LifeTime Hybrid Collective Investment Funds (CITs) are
            collective investment trusts maintained by Principal Global
            Investors Trust Company, (the Trust Company). The Trust Company has
            retained Principal Global Investors, LLC (the Adviser), to serve as
            investment adviser with respect to the CITs, subject to the Trust
            Company&apos;s supervision and review. The Adviser is an indirect
            wholly owned subsidiary of Principal Financial Group, Inc., and is
            under common control with the Trust Company. The Adviser also
            manages portfolios which may be included as underlying investments
            in the CITs.
          </p>
          <p className="fs-p">
            Principal Global Investors, LLC (PGI) is registered with the U.S.
            Commodity Futures Trading Commission (CFTC) as a commodity trading
            advisor (CTA), a commodity pool operator (CPO) and is a member of
            the National Futures Association (NFA). PGI advises qualified
            eligible persons (QEPs) under CFTC Regulation 4.7.
          </p>
          <p className="fs-p">
            The Trust Company is regulated by the State of Oregon. Units of the
            CITs are not deposits or obligations of, guaranteed by, or insured
            by the Trust Company or any affiliate, and are not insured by the
            FDIC or any other federal or state government agency. The value of
            the CITs will fluctuate so that when redeemed, units may be worth
            more or less than the original cost.
          </p>
          <p className="fs-p">
            A copy of the participation agreement can be obtained from your plan
            administrator or investment contact.
          </p>
          <p className="fs-p">
            The declaration of trust, participation agreement, and disclosure
            documents contain important information about investment objectives,
            risks, fees and expenses associated with investment in the CITs and
            should be read carefully before investing. The declaration of trust
            is available at principal.com. A copy of the participation agreement
            can be obtained from your plan administrator.
          </p>
          <p className="fs-p">
            Separate Accounts are available through a group annuity contract
            with Principal Life Insurance Company
            <sup>
              <small>®</small>
            </sup>
            . Insurance products and plan administrative services provided
            through Principal Life Insurance Company, a member of the Principal
            Financial Group
            <sup>
              <small>®</small>
            </sup>
            , Des Moines, Iowa 50392. See the group annuity contract for the
            full name of the Separate Account. Certain investment options and
            contract riders may not be available in all states or U.S.
            commonwealths. Principal Life Insurance Company reserves the right
            to defer payments or transfers from Principal Life Separate Accounts
            as permitted by the group annuity contracts providing access to the
            Separate Accounts or as required by applicable law. Such deferment
            will be based on factors that may include situations such as:
            unstable or disorderly financial markets; investment conditions
            which do not allow for orderly investment transactions; or
            investment, liquidity, and other risks inherent in real estate (such
            as those associated with general and local economic conditions). If
            you elect to allocate funds to a Separate Account, you may not be
            able to immediately withdraw them.
          </p>
          <p className="fs-p">
            Qualified startup costs (1) In general “qualified startup costs” is
            ordinary and necessary expenses of an eligible employer which are
            paid or incurred in connection with -- (i) the establishment or
            administration of an eligible employer plan, or (ii) the retirement
            related education of employees with respect to such plan. (2) Plan
            must have at least 1 participant: would not apply if plan does not
            have at least 1 employee eligible to participate who is not a highly
            compensated employee.
          </p>
          <p className="fs-p">
            <strong>
              Carefully consider the Fund&apos;s objectives, risks, charges, and
              expenses. Contact your financial professional or visit
              principal.com for a prospectus, or summary prospectus if
              available, containing this and other information. Please read it
              carefully before investing. Before directing retirement funds to a
              separate account, investors should carefully consider the
              investment objectives, risks, charges and expenses of the separate
              account as well as their individual risk tolerance, time horizon
              and goals. For additional information contact us by visiting
              principal.com. For more information on this or other plan
              investment options, login to your account on principal.com, or
              call 800-547-7754.
            </strong>
          </p>
          <p className="fs-p">
            <strong>Investment and Insurance products are:</strong>
            <ul>
              <li>
                <strong>
                  Not Insured by the FDIC or Any Federal Government Agency
                </strong>
              </li>
              <li>
                <strong>
                  Not a Deposit or Other Obligation of, or Guaranteed by Credit
                  Union or Bank
                </strong>
              </li>
              <li>
                <strong>
                  Subject to Investment Risks, Including Possible Loss of the
                  Principal Amount Invested
                </strong>
              </li>
            </ul>
          </p>
          <p className="fs-p">
            Investing involves risk, including possible loss of principal.
          </p>
          <p className="fs-p">
            Asset allocation and diversification does not ensure a profit or
            protect against a loss. Additionally there is no guarantee this
            investment option will provide adequate income at or through
            retirement. Equity investment options involve greater risk,
            including heightened volatility, than fixed-income investment
            options. Fixed-income investments are subject to interest rate risk;
            as interest rates rise their value will decline. International and
            global investing involves greater risks such as currency
            fluctuations, political/social instability and differing accounting
            standards. These risks are magnified in emerging markets.
            Fixed-income and asset allocation investment options that invest in
            mortgage securities are subject to increased risk due to real estate
            exposure. The performance and risks of a fund of funds directly
            correspond to the performance and risks of the underlying funds in
            which the fund invests.
          </p>
          <p className="fs-p">
            There is no guarantee that a target date investment will provide
            adequate income at or through retirement. A target date fund&apos;s
            (TDF) glide path is typically set to align with a retirement age of
            65, which may be your plan&apos;s normal retirement date (NRD). If
            your plan&apos;s NRD/age is different, the plan may default you to a
            TDF based on the plan&apos;s NRD/Age. Participants may choose a TDF
            that does not match the plan&apos;s intended retirement date but
            instead aligns more to their investment risk. Compare the different
            TDF&apos;s to see how the mix of investments shift based on the TDF
            glide path.
          </p>
          <p className="fs-p">
            Insurance products and plan administrative services provided through
            Principal Life Insurance Company Principal Funds, Inc. is
            distributed by Principal Funds Distributor, Inc. Mutual fund,
            separate account and collective investment trust trade instructions
            are processed by Matrix Settlement & Clearance LLC through the NSCC
            membership of its affiliate, Matrix Trust Company. Certain
            investment options and contract riders may not be available in all
            states or U.S. commonwealths. Separate Accounts are available
            through a group annuity contract with Principal Life Insurance
            Company. See the group annuity contract for the full name of the
            Separate Account. Principal Life Insurance Company reserves the
            right to defer payments or transfers from Principal Life Separate
            Accounts as permitted by the group annuity contracts providing
            access to the Separate Accounts or as required by applicable law.
            Such deferment will be based on factors that may include situations
            such as: unstable or disorderly financial markets; investment
            conditions which do not allow for orderly investment transactions;
            or investment, liquidity and other risks inherent in real estate
            (such as those associated with general and local economic
            conditions). If you elect to allocate funds to a Separate Account,
            you may not be able to immediately withdraw them.
          </p>
          <p className="fs-p">
            Information provided on the websites regarding our products and
            services is not intended to take the place of our agreements
            governing our products and services, or our disclosures made in
            accordance with federal and state law.
          </p>
          <p className="fs-p">
            Additionally, this information does not take the place of any
            legally required disclosures for any products or services offered by
            any of the member companies of Principal.
          </p>
          <p className="fs-p">
            Principal Life maintains certificates of authority to transact
            insurance in all 50 states. Principal Life NAIC identification
            number is 61271. Principal National NAIC identification number is
            71161.
          </p>
          <p className="fs-p">
            Principal Trust Company
            <sup>
              <small>®</small>
            </sup>{' '}
            is a trade name of Delaware Charter Guarantee & Trust Company.
          </p>
          <p className="fs-p">
            Principal Life, Principal Securities, Inc., Principal Funds
            Distributor, and Principal Trust Company are members of the
            Principal Financial Group
            <sup>
              <small>®</small>
            </sup>
            , Des Moines, Iowa 50392.
          </p>
          <p className="fs-p">
            Ubiquity Retirement + Savings
            <sup>
              <small>®</small>
            </sup>
            , Wilshire Advisors LLC, and Surety Bonds Direct are not affiliates
            of any company of the Principal Financial Group.
          </p>
          <p className="fs-p">
            Investing involves risk, including possible loss of principal. Asset
            allocation and diversification does not ensure a profit or protect
            against a loss. Equity investment options involve greater risk,
            including heightened volatility, than fixed-income investment
            options. Fixed-income investments are subject to interest rate risk;
            as interest rates rise their value will decline. International and
            global investing involves greater risks such as currency
            fluctuations, political/social instability and differing accounting
            standards. Small and mid-cap stocks may have additional risks
            including greater price volatility.
          </p>
          <p className="fs-p">
            Annuities, insurance, retirement plans, mutual funds and other
            securities are not insured by the FDIC, have no bank guarantee,
            involve risk, and may lose value.
          </p>
          <p className="fs-p">
            Principal
            <sup>
              <small>®</small>
            </sup>
            , Principal Financial Group
            <sup>
              <small>®</small>
            </sup>
            , and Principal and the logomark design are registered trademarks of
            Principal Financial Services, Inc., a Principal Financial Group
            company, in the United States and are trademarks and service marks
            of Principal Financial Services, Inc., in various countries around
            the world.
          </p>
          <p className="fs-p">
            Elevate by Principal is a trade name for Business Owner Ecosystem,
            Inc. Insurance products issued by Principal National Life Insurance
            Company (except in NY) and Principal Life Insurance Company
            <sup>
              <small>®</small>
            </sup>
            . Referenced companies are members of Principal Financial Group
            <sup>
              <small>®</small>
            </sup>
            , Des Moines, IA 50392. Additional products and services may be
            offered through Strategic Partners that are not member companies of
            Principal Financial Group
            <sup>
              <small>®</small>
            </sup>
            . All resources, including Strategic Partner resources, may be
            changed or canceled at any time.
          </p>
          <p className="fs-p">3885644-092024</p>
        </div>
      </div>
    </div>
  </div>
);

export default HeroSection;
