import React from 'react';
import PropTypes from 'prop-types';
import { PurpleSectionStyles } from './index.styles';

const PurpleSection = ({ copy }) => (
  <PurpleSectionStyles className="position-relative" id="secureAct">
    <div className="container">
      <div className="row">
        <div
          className="col-md-8 mx-auto"
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </div>
    </div>
  </PurpleSectionStyles>
);

PurpleSection.propTypes = {
  copy: PropTypes.any.isRequired,
};

export default PurpleSection;
