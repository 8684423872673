import React from 'react';
import { Link } from 'react-router-dom';
import HeaderSection from '../../../common/HeaderSection';
import QuickQuote from './QuickQuote';
import { headerPlanProposal001 } from '../../../ImagePaths';
import SectionMaskTop from '../../../../Images/section-mask-top.svg';

const sectionHeader = {
  image: headerPlanProposal001,
  title: 'Start a proposal for your client',
  caption: `
    <p class="fs-display5">
      Create a fee proposal that outlines plan costs for your client and show them how affordable a workplace retirement plan can be.
    </p>
  `,
  alt: 'Employee reading article.',
  copy: ``,
};

const Resources = () => (
  <div className="resources-page print-pt-80 mb-5">
    <HeaderSection
      image={sectionHeader.image}
      title={sectionHeader.title}
      caption={sectionHeader.caption}
      copy={sectionHeader.copy}
      alt={sectionHeader.alt}
      wrapperClasses="d-print-none"
    />
    <section className="bg-white py-6">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto px-md-3">
            <p className="fs-display5 fw-bold">
              Enter a few details here and create a PDF of your Simply
              Retirement by Principal
              <small>
                <sup>®</sup>
              </small>{' '}
              401(k) plan fee proposal.
            </p>
            <p>
              Simply Retirement by Principal
              <small>
                <sup>®</sup>
              </small>{' '}
              was designed to streamline the process of starting a retirement
              plan with straightforward options and fees. To create a fee
              proposal, complete the information below and click the button to
              create a PDF. When you’re ready to complete the plan design, get
              started <Link to="/plans">here</Link>.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section
      id="quickQuote"
      className=" section_bg-pattern section section_pb-5"
    >
      <img src={SectionMaskTop} alt="" className="section__mask-top" />
      <QuickQuote
        questionLabels={{
          numberOfEmployees: `How many people work at your client's organization?`,
          averageSalary: 'What’s the average salary per employee?',
        }}
        sectionLabel={{
          offerEmployees: 'Offer a 401(k) plan to your employees for:',
          matchContributions: 'Your matching contributions could cost:',
        }}
      />
    </section>
  </div>
);

export default Resources;
