export function brokerTypeForParadigm(brokerType) {
  switch (brokerType) {
    case 'insuranceAgent':
      return 'INSURANCE_AGENCY';
    case 'brokerDealer':
      return 'BROKER';
    case 'iarRia':
      return 'RIA_FIRM';
    default:
      return null;
  }
}
