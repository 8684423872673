import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import {
  recordkeepingFee,
  totalContributions,
  contributionsPerEmployee,
} from './Utilities';

const setupFeeInfo = `
<h2 class="text-white fw-bold fs-h3">What’s included in the one-time $500 setup fee</h2>
<p class='text-white pt-3 fs-sm'>
This one-time fee covers the setup required to administer your plan. Tax credits
may be available to offset a portion of plan startup costs through the SECURE 2.0 Act.
<a class="text-white" target="_blank" href="https://www.principal.com/landing-page/secureact-plansponsor">Find more information here</a> or contact your tax advisor or appropriate counsel for details.
</p>
`;

const recordkeepingInfo = `
<h2 class="text-white fw-bold fs-h3 mb-4">What’s included in the recordkeeping fees that can be offset up to 100% with tax credits</h2>
<span class="equation stacked">
<span class="equation__operator"> </span> <span class="equation__number">22 employees</span>
<span class="equation__operator">x</span> <span class="equation__number">$6 per-participant fee</span>
<span class="equation__equals"></span>
<span class="equation__operator">=</span>   <span class="equation__number">$132 per month</span>
<span class="equation__operator">+</span> <span class="equation__number">$145 per month recordkeeping fee<small><sup>1</sup></small></span>
<span class="equation__equals"></span>
<span class="equation__operator">=</span>   <span class="equation__number">$277 per month</span>
</span>
<p class='text-white pt-3 fs-sm'>
<small><sup>1</sup></small></span>
The $145 per month recordkeeping fee applies if working with a TPA. If not working with a TPA, the bundled recordkeeping fee pricing is $185 per month.
</p>
<p class='text-white pt-3 fs-sm'>
  Monthly recordkeeping fees cover the cost of maintaining the plan, including services such as annual nondiscrimination testing, filing, and reporting
  of required documentation, ongoing recordkeeping, automated participant signup and onboarding, an online platform for plan administration,
  and a dedicated phone number for questions.
</p>
`;

const contributions = `
<h2 class="text-white fw-bold fs-h3 mb-4">What’s included in the matching contributions</h2>
<span class="equation stacked">
<span class="equation__operator"> </span> <span class="equation__number">$30,000 per year avg. salary</span>
<span class="equation__operator">x</span> <span class="equation__number">4% safe harbor matching contribution</span>
<span class="equation__operator">x</span> <span class="equation__number">22 employees</span>
<span class="equation__operator">÷</span> <span class="equation__number">12 months</span>
<span class="equation__equals"></span>
<span class="equation__operator">=</span> <span class="equation__number">$2,200 per month total or</span>
<span class="equation__operator"> </span> <span class="equation__number">$100 per employee, per month</span>
</span>
<p class='text-white pt-3 fs-sm'>
  Matching contributions are estimated based on the average annual salary and
  number of employees you entered.
</p>
`;

const QuickQuoteCard = ({
  statValue,
  statLabel,
  statHelper,
  moreInfo,
  putApplicationPanelInfo,
}) => (
  <>
    <div className="stat">
      <strong className="stat-value">
        <span className="prefix">$</span>
        {statValue}
      </strong>
      {statHelper && (
        <strong className="stat-label d-md-block d-none">
          <small>{statHelper}</small>
        </strong>
      )}
      <strong
        className="stat-label"
        dangerouslySetInnerHTML={{ __html: statLabel }}
      />
      {moreInfo && (
        <button
          className="srp-button__link p-0 d-block"
          type="button"
          onClick={() => {
            putApplicationPanelInfo({
              hasVisibility: true,
              additionalInformation: moreInfo,
            });
          }}
        >
          What’s included?
        </button>
      )}
    </div>
    {statHelper && (
      <strong className="stat-label d-md-none d-block text-center">
        <small>(as low as $145 + $6 per participant*)</small>
      </strong>
    )}
  </>
);

QuickQuoteCard.propTypes = {
  statValue: PropTypes.string.isRequired,
  statLabel: PropTypes.string,
  statHelper: PropTypes.string,
  moreInfo: PropTypes.string,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const QuickQuoteCalculator = ({
  numberOfEmployees,
  averageSalary,
  putApplicationPanelInfo,
  sectionLabel,
}) => {
  const [activeSlide, setActiveSlide] = useState('offerEmployees');
  const handlers = useSwipeable({
    onSwiped: (eventData) =>
      eventData.dir === 'Right'
        ? setActiveSlide('offerEmployees')
        : setActiveSlide('matchContributions'),
  });
  return (
    <div className="quick-quote__slides" {...handlers}>
      <div
        id="offerEmployees"
        className={classNames('quick-quote__slide', {
          active: activeSlide === 'offerEmployees',
        })}
      >
        <p className="fs-h6 text-white text-md-left text-center">
          <strong>{sectionLabel.offerEmployees}</strong>
        </p>

        <div className="quick-quote__card-row">
          <div className="quick-quote__card">
            <div className="label">
              <i className="far fa-clock icon" aria-hidden />{' '}
              <span>Upfront</span>
            </div>
            <QuickQuoteCard
              statValue="500"
              statLabel="One-time setup fee for bundled plans"
              moreInfo={setupFeeInfo}
              putApplicationPanelInfo={putApplicationPanelInfo}
            />
          </div>
          <div className="quick-quote__card">
            <div className="label">
              <i className="far fa-calendar-check icon" aria-hidden />{' '}
              <span>Every month</span>
            </div>
            <QuickQuoteCard
              statValue={recordkeepingFee(numberOfEmployees)}
              statHelper="(as low as $145 + $6 per participant*)"
              statLabel="Recordkeeping fee"
              moreInfo={recordkeepingInfo}
              putApplicationPanelInfo={putApplicationPanelInfo}
            />
          </div>
        </div>
      </div>
      <div
        id="matchContributions"
        className={classNames('quick-quote__slide', {
          active: activeSlide === 'matchContributions',
        })}
      >
        <p className="fs-h6 text-white text-md-left text-center">
          <strong>{sectionLabel.matchContributions}</strong>
        </p>
        <div className="quick-quote__card-row">
          <div className="quick-quote__card-split">
            <div className="label">
              <i className="far fa-calendar-check icon" aria-hidden />{' '}
              <span>Every month</span>
            </div>

            <div className="quick-quote__card-row">
              <div className="card-half">
                <QuickQuoteCard
                  statValue={totalContributions(
                    numberOfEmployees,
                    averageSalary,
                  )}
                  statLabel="Total contributions"
                  putApplicationPanelInfo={putApplicationPanelInfo}
                />
              </div>
              <div className="card-half">
                <QuickQuoteCard
                  statValue={contributionsPerEmployee(
                    numberOfEmployees,
                    averageSalary,
                  )}
                  statLabel="Contributions per employee"
                  putApplicationPanelInfo={putApplicationPanelInfo}
                />
              </div>
            </div>
            <button
              className="srp-button__link p-0 d-block mx-auto"
              type="button"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: contributions,
                });
              }}
            >
              What’s included?
            </button>
          </div>
        </div>
      </div>
      <div className="quick-quote__slides-control">
        <button
          type="button"
          className={classNames('control', {
            active: activeSlide === 'offerEmployees',
          })}
          onClick={() => {
            setActiveSlide('offerEmployees');
          }}
        >
          <span className="sr-only">Show employer offer</span>
        </button>
        <button
          type="button"
          className={classNames('control', {
            active: activeSlide === 'matchContributions',
          })}
          onClick={() => {
            setActiveSlide('matchContributions');
          }}
        >
          <span className="sr-only">Show matching contributions</span>
        </button>
      </div>
    </div>
  );
};

QuickQuoteCalculator.propTypes = {
  numberOfEmployees: PropTypes.number.isRequired,
  averageSalary: PropTypes.number.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  sectionLabel: PropTypes.object.isRequired,
};

export default QuickQuoteCalculator;
