/**
 * Returns the compensation type based on the plan design.
 *
 * @param {Object} planDesign - The plan design object.
 * @returns {string} - The compensation type.
 */
export const getCompensation = (planDesign) => {
  const { advisorFee, advisorPoints } = planDesign.questions;
  const { brokerType } = planDesign.advisors[0].advisorCredentialVerification;
  if (advisorFee.value) {
    return 'Flat Fee';
  }
  if (advisorPoints.value) {
    return brokerType === 'iarRia' ? 'RIA Basis Points' : 'Basis Points';
  }
  return `Billed Separately`;
};
