import React from 'react';
import PropTypes from 'prop-types';
import { asOfDate } from '../../../../Services/utilities';

const SectionEnrollmentFees = ({ putApplicationPanelInfo }) => (
  <section id="section-enrollment-fees" className="pt-0 pb-2">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-10 mx-auto">
          <h4 className="fs-h3 my-3 pt-5">Enrollment and fees</h4>
          <p className="fs-h2 text-purple">
            <strong>
              What if I (or my employees) don’t want to be automatically
              enrolled in the plan?
            </strong>
          </p>
          <p className="fs-p mb-4">
            No problem. The automatic enrollment feature is optional. If you
            choose the option for employees to be automatically enrolled at a
            default pre-tax contribution percentage (set by the business owner),
            employees can change this amount or opt out at any time.
            Participants can opt out by choosing the opt-out option in their
            online account under “Manage Deferrals.” Choosing the automatic
            enrollment feature can help increase participation, simplify
            administration, and help employees save for retirement.
            <button
              type="button"
              className="btn btn-link d-block d-print-none mx-auto fs-disclaimer pt-2"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'Automatic Enrollment',
                  additionalInformation:
                    'Automatic enrollment is a plan provision which automatically enrolls eligible employees (participants) into the retirement plan at a specified salary deferral percentage unless the participant elects otherwise by completing an enrollment form in the enrollment meeting or on the participant website. This can help increase participation, simplify administration, and help employees save for retirement. As an added benefit, the SECURE 2.0 Act offers a new tax credit for small businesses when first implementing automatic enrollment using an eligible automatic contribution arrangement (EACA). It’s $500 annually for 3 years. This is in addition to the plan start-up credit. Consult with a tax advisor or other appropriate counsel for details.',
                });
              }}
            >
              More info
            </button>
          </p>
          <p className="fs-h2 text-purple">
            <strong>What happens if my company grows beyond 100?</strong>
          </p>
          <p className="fs-p mb-4">
            When your eligible employees exceed 100, your plan may become
            subject to an independent annual plan audit. When your plan becomes
            subject to audit requirements, we will inform you and work alongside
            you and the auditors to complete this annual audit.
          </p>
          <p className="fs-h2 text-purple">
            <strong>
              What if an employee leaves their job? Is there a penalty?
            </strong>
          </p>
          <p className="fs-p mb-4">
            If an employee leaves your business, they’ll need to decide what
            they want to do with their vested 401(k) account. They can keep the
            money in the plan, move it to a new employer’s plan, move it to a
            self-directed retirement account (also known as a rollover IRA), or
            cash out.* Former employees may stay invested in your 401(k) plan
            indefinitely if their balance is at least $5,000.
          </p>
          <p className="fs-disclaimer">
            *If an employee takes a distribution (“cashes out”) before reaching
            age age 59 1&frasl;2, the IRS generally requires automatic
            withholding of 20% of a 401(k) early withdrawal, which will be used
            to offset whatever they owe in federal taxes. They may owe
            applicable state and local taxes as well. The IRS will also assess a
            10% early withdrawal penalty if applicable.
          </p>
          <p className="fs-h2 text-purple">
            <strong>As a business owner, do I have to participate?</strong>
          </p>
          <p className="fs-p mb-4">
            No. However, it’s a great way to receive the same benefits as your
            employees. For example, you can defer paying taxes on any pre-tax
            contributions you make, and you can take advantage of any matching
            contributions that are offered. You may contribute up to the maximum
            per IRS limits for the applicable tax year. Note: You will be
            automatically enrolled unless you opt out by choosing the opt-out
            option in your online account.
          </p>
          <p className="fs-h2 text-purple">
            <strong>What are the plan fees, and who pays them?</strong>
          </p>
          <p className="fs-p">
            <strong>The business owner pays:</strong>
          </p>
          <ul>
            <li>
              <p className="fs-p mb-0">
                <strong>$500 one-time</strong> setup fee.
              </p>
            </li>
            <li>
              <p className="fs-p mb-0">
                <strong>$185 per month</strong> recordkeeping fee ($555 billed
                quarterly).
              </p>
            </li>
            <li>
              <p className="fs-p mb-0">
                <strong>
                  OneDigital Investment Advisors LLC (“OneDigital”) of $750 per
                  quarter
                </strong>{' '}
                until assets reach defined threshold. Refer to OneDigital 3(38)
                advisory agreement for more details.
              </p>
            </li>
          </ul>
          <p className="fs-p">
            <strong>The participants pay:</strong>
          </p>
          <ul>
            <li>
              <p className="fs-p">
                <strong>$6 per participant, per month</strong> recordkeeping fee
                for each participant with an account balance over $100. This is
                deducted from participant accounts monthly. You (the business
                owner) may elect to pay it quarterly on behalf of the
                participants.
              </p>
            </li>
            <li>
              <p className="fs-p">
                <strong>
                  OneDigital advisory compensation advisory compensation
                </strong>{' '}
                of 11.25 bps per quarter until assets reach defined threshold.
                Refer to OneDigital 3(38) advisory agreement for more details.
              </p>
            </li>
            <li>
              <p className="fs-p">
                <strong>Custodial and investment fees,</strong> which are
                charged against participating employees’ accounts (those vary by
                investment and range from 0.02% - 0.80% as of ${asOfDate}).
                Custodial fees are up to 0.055%.
              </p>
            </li>
          </ul>
          <p className="fs-h2 text-purple">
            <strong>How do contributions work?</strong>
          </p>
          <p className="fs-p">
            Contributions are deposited by the plan sponsor (you) or the
            employees employees (participants). While employee contributions are
            elective to the employee, some employer contributions may be
            required by the terms of your plan.
          </p>
          <ul>
            <li>
              <p className="fs-p">
                <strong>Participants</strong> contribute their elected deferral
                (a percentage of their pay they have selected) each pay period.
              </p>
            </li>
            <li>
              <p className="fs-p">
                If offered, <strong>business owners</strong> can match up to 10%
                of participant contributions for the plan each pay period.
              </p>
            </li>
          </ul>
          <p className="fs-h2 text-purple">
            <strong>How and when are the fees billed?</strong>
          </p>
          <p className="fs-p ">
            On the first day of the month after you sign the required documents
            and purchase the plan, the one-time $500 setup fee and your first
            three months of plan and advisory fees will be charged to the
            payment method you have entered. If your purchase is completed on
            the first day of the month, you will be charged the same day. The
            same payment method will be charged on the first day of the month
            every three months thereafter for three months of the recordkeeping
            fee ($185/month for a total of $555), and if elected, three months
            of the $6/month per participant fee ($6 x number of participants x 3
            months). OneDigital&apos;s advisory fees will be assessed quarterly
            and charged to the plan sponsor and deducted from participant
            accounts. Advisory fee will be billed to the plan sponsor with 11.25
            bps deducted from participants quarterly (until the assets reach
            $1,000,000)
          </p>

          <p className="fs-p mb-4">
            Specific deadlines apply to safe harbor plans, and this may impact
            billing and subscription effective dates.
          </p>

          <p className="fs-disclaimer">
            <sup>
              <small>*</small>
            </sup>
            Refer to the 3(38) agreement for additional details.
          </p>
        </div>
      </div>
    </div>
  </section>
);

SectionEnrollmentFees.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

export default SectionEnrollmentFees;
