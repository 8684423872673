import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { AppVariables } from '../../AppVariables';
import FormDataRedux, {
  selectRole,
  selectPrimaryAdvisor,
  isFormValid,
  selectPrimaryTPA,
  selectAdvisorQuestions,
  selectActiveUserEmail,
} from '../../Redux/FormDataRedux';
import ToastRedux from '../../Redux/ToastRedux';
import API from '../../Services/api';
import { saveData, uuidContext } from '../FormWithSteps';
import {
  checkValidityOnQuestionArray,
  whoToShareWith,
  onSaveToast,
} from '../FormWithSteps/Utilities';
import { createSalesforceProposal } from '../../Services/helpers/Salesforce/salesforceHelper';
import { ShareForm } from './Form';
import { collectSavedPlanData } from '../../Services/helpers/Paradigm/planHelper';

const api = API.create();

const SharePlanModal = ({
  userRole,
  primaryAdvisor,
  addToast,
  formData,
  resetData,
  updateQuestion,
  isValid,
  primaryTPA,
  advisorInfo,
  setCreateEmailSent,
  putPlanURL,
  activeUserCc,
}) => {
  const uuid = useContext(uuidContext);
  const { advisorFirmBroker, sponsorEmail, hasFp, hasTpa } =
    formData.planDesign.questions;
  const { authenticityToken } = useContext(AppVariables);
  const { advisorEmail } = primaryAdvisor.questions;
  const { tpaEmail } = primaryTPA.questions;

  let modalRef;

  const [email, setEmail] = useState({ value: '', isValid: true });
  const [shareModalCopy, setShareModalCopy] = useState({
    heading:
      'Want to share this proposal with another member of your organization? Enter their email here and they’ll receive a link so they can review the proposed plan or make edits. Don’t worry—you’ll get an email if any changes are made to the plan. Feel free to add a custom message.',
    recipient: '',
  });
  const [apiResponse, setApiResponse] = useState({ msg: '', status: '' });
  const [messageVisible, setMessageVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [createAnotherPlan, setCreateAnotherPlan] = useState(false);

  const shareWith = whoToShareWith(
    userRole,
    hasFp.value,
    hasTpa.value,
    checkValidityOnQuestionArray(advisorInfo),
    isValid,
    formData.planOriginator.role,
  );

  const handleEmail = (value) => {
    setEmail({
      value,
      isValid: /\S+@\S+\.\S+/.test(value),
    });
  };

  const submitForm = () => {
    setCreateEmailSent();
    sendSharedPlanEmail();
  };

  const sendSharedPlanEmail = async () => {
    try {
      const savePlanData = collectSavedPlanData(formData);
      const saveResponse = await api.savePlan({
        ...savePlanData,
        authenticity_token: authenticityToken,
      });
      const creatorRole = formData.planOriginator.role;
      if (saveResponse.status === 'success') {
        addToast(
          onSaveToast(
            formData.hashId,
            userRole,
            formData.planOriginator,
            'sent',
          ),
          'success',
        );
        saveData(uuid, formData);
        putPlanURL(saveResponse.url, saveResponse.hashId);

        const obj = {
          authenticity_token: authenticityToken,
          hashId: saveResponse.hashId,
          to: email.value,
          name: formData.planOriginator.name, // ? 'name' is plan originator not recipient name
          ...(message !== '' && { message }),
        };
        if (activeUserCc !== formData.planOriginator.email) {
          obj.cc = activeUserCc;
        }

        let response;

        if (shareWith === 'tpaToEitherParty') {
          if (obj.to === sponsorEmail.value) {
            if (creatorRole === 'employer') {
              response = await api.shareUpdatePlanSmb(obj);
            } else {
              response = await api.sharePlanSmb(obj);
            }
          } else if (obj.to === advisorEmail.value) {
            if (creatorRole === 'advisor') {
              response = await api.shareUpdatePlanFp(obj);
            } else {
              response = await api.sharePlanFp({
                ...obj,
                cc: tpaEmail.value,
              });
            }
          } else {
            response = await api.sharePlan(obj);
          }
        } else if (shareWith === 'tpaToAdvisor') {
          if (creatorRole === 'advisor') {
            response = await api.shareUpdatePlanFp(obj);
          } else {
            response = await api.sharePlanFp({ ...obj, cc: tpaEmail.value });
          }
        } else if (shareWith === 'advisorToTpa') {
          if (creatorRole === 'tpa') {
            response = await api.shareUpdatePlanTpa(obj);
          } else {
            response = await api.sharePlanTpa({
              ...obj,
              cc: advisorEmail.value,
            });
          }
        } else if (shareWith === 'advisorToEitherParty') {
          if (obj.to === sponsorEmail.value) {
            if (creatorRole === 'employer') {
              response = await api.shareUpdatePlanSmb(obj);
            } else {
              response = await api.sharePlanSmb(obj);
            }
          } else if (obj.to === tpaEmail.value) {
            if (creatorRole === 'tpa') {
              response = await api.shareUpdatePlanTpa(obj);
            } else {
              response = await api.sharePlanTpa({
                ...obj,
                cc: advisorEmail.value,
              });
            }
          } else {
            response = await api.sharePlan(obj);
          }
        } else if (shareWith === 'advisorToEmployer') {
          if (creatorRole === 'employer') {
            response = await api.shareUpdatePlanSmb(obj);
          } else {
            response = await api.sharePlanSmb({
              ...obj,
              cc: advisorEmail.value,
            });
          }
        } else if (shareWith === 'employerToTpa') {
          response = await api.sharePlanTpa({ ...obj, cc: sponsorEmail.value });
        } else if (shareWith === 'employerToAdvisor') {
          response = await api.sharePlanFp({ ...obj, cc: sponsorEmail.value });
        } else if (shareWith === 'tpaToEmployer') {
          if (creatorRole === 'employer') {
            response = await api.shareUpdatePlanSmb(obj);
          } else {
            response = await api.sharePlanSmb(obj);
          }
        } else {
          response = await api.sharePlan({ ...obj, cc: sponsorEmail.value });
        }
        let status = '';
        let msg = '';
        if (response.ok) {
          status = 'success';
          msg = 'Your information has been successfully sent';
          if (advisorEmail.value !== '' || tpaEmail.value !== '') {
            createSalesforceProposal({
              formData,
              authenticityToken,
              stageFlag: 'Share',
              link: saveResponse.url,
              advisorCvStatuses: [],
              tpaParadigmPackager: [],
              hashId: saveResponse.hashId,
            });
          }
        } else {
          status = 'error';
          msg = 'Your plan failed to send. Refresh and try again.';
        }
        setApiResponse({ msg, status });
      } else {
        addToast('Your plan failed to save. Refresh and try again.', 'error');
      }
    } catch (error) {
      addToast('Your plan failed to save. Refresh and try again.', 'error');
    }
  };

  useEffect(() => {
    Modal.setAppElement(modalRef);
  }, []);

  useEffect(() => {
    if (shareWith === 'advisorToEitherParty') {
      setShareModalCopy({
        heading:
          'After you select the investment lineup and review the plan, click “Share the proposal” to share your changes with the small business owner (client) or third party administrator. You’ll be cc’d on the email your contact receives.',
        recipient: 'To third-party administrator or small business owner',
      });
    }
    if (shareWith === 'tpaToEitherParty') {
      setShareModalCopy({
        heading: `<p>Once you’ve entered your information and made plan selections, you can share your updates with the financial professional or share the proposal directly with the small business owner (client). You’ll be cc’d on the email your contact receives.</p><p>You can now manage your proposals in your TPA dashboard at Ubiquity. If this is your first proposal, you should have received an invitation to set up your dashboard. If this isn’t your first Simply Retirement plan, you can now manage your proposals from your TPA dashboard! We’ll still send you an email when the investment lineup is selected and the plan is purchased.</p>`,
        recipient: 'To financial professional or small business owner',
      });
    }
    if (shareWith === 'employerToAdvisor') {
      setShareModalCopy({
        heading:
          'Send a link to this proposal to your financial professional to help you with choosing the investment lineup or complete plan details. You’ll get an email when the plan is updated. Feel free to add a custom message.',
        recipient: 'To financial professional',
      });
      setEmail({ value: advisorEmail?.value, isValid: !!advisorEmail?.value });
    }
    if (shareWith === 'employerToTpa') {
      setShareModalCopy({
        heading: `Send a link to this proposal to your third party administrator (TPA) to complete plan details. You’ll get an email when the plan is updated. Feel free to add a custom message.`,
        recipient: 'To third party administrator',
      });
      setEmail({ value: tpaEmail?.value, isValid: !!tpaEmail?.value });
    }
    if (shareWith === 'advisorToTpa') {
      setShareModalCopy({
        heading: `Send a link to this plan to the third party administrator (TPA) to complete the plan details. You can now manage your proposals in your advisor dashboard at Ubiquity. If this is your first proposal, you should have received an invitation to set up your dashboard. If this isn’t your first Simply Retirement plan, you can now manage your proposals from your advisor dashboard! We’ll still send you an email if any changes are made to the proposal and notify you when the plan is purchased.`,
        recipient: 'To third party administrator',
      });
      setEmail({ value: tpaEmail?.value, isValid: !!tpaEmail?.value });
    }
    if (shareWith === 'advisorToEmployer') {
      setShareModalCopy({
        heading: `Send a link to this plan to your client to review the plan details and submit it for purchase when ready.  You can now manage your proposals in your advisor dashboard at Ubiquity. If this is your first proposal, you should have received an invitation to set up your dashboard. If this isn’t your first Simply Retirement plan, you can now manage your proposals from your advisor dashboard! We’ll still send you an email if any changes are made to the proposal and notify you when the plan is purchased.`,
        recipient: 'To client',
      });
      setEmail({ value: sponsorEmail?.value, isValid: !!sponsorEmail?.value });
    }
    if (shareWith === 'tpaToEmployer') {
      setShareModalCopy({
        heading: `Send a link to this plan to your client to review the plan details and submit it for purchase when ready. Don’t worry—you’ll get an email if any changes are made to the plan. We’ll also send you an email when the plan is purchased. Feel free to add a custom message.`,
        recipient: 'To client',
      });
      setEmail({ value: sponsorEmail?.value, isValid: !!sponsorEmail?.value });
    }
    if (shareWith === 'tpaToAdvisor') {
      setShareModalCopy({
        heading:
          'Send a link to this plan to the financial professional to assist your client with selecting the investment lineup. You’ll receive an email when the investment lineup is selected. You can also sign into your <a href="https://secure.simplyretirement.com/login" target="_blank">dashboard</a> to see this plan’s status. Feel free to add a custom message.',
        recipient: 'To financial professional',
      });
      setEmail({ value: advisorEmail?.value, isValid: !!advisorEmail?.value });
    }
  }, []);

  useEffect(() => {
    switch (apiResponse.status) {
      case 'success':
        setCreateAnotherPlan(true);
        break;

      case 'error':
        addToast(apiResponse.msg, apiResponse.status);
        break;

      default:
        break;
    }
  }, [apiResponse.status]);

  return (
    <ShareForm
      shareModalCopy={shareModalCopy}
      createAnotherPlan={createAnotherPlan}
      email={email}
      handleEmail={handleEmail}
      // preview={preview}
      submitForm={submitForm}
      userRole={userRole}
      hasFp={hasFp}
      updateQuestion={updateQuestion}
      resetData={resetData}
      advisorFirmBroker={advisorFirmBroker}
      messageVisible={messageVisible}
      setMessageVisible={setMessageVisible}
      setMessage={setMessage}
      disabled={false}
      shareWith={shareWith}
      sponsorEmail={sponsorEmail.value}
      advisorEmail={advisorEmail.value}
      tpaEmail={tpaEmail.value}
    />
  );
};

SharePlanModal.propTypes = {
  userRole: PropTypes.string.isRequired,
  primaryAdvisor: PropTypes.object.isRequired,
  addToast: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  resetData: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  primaryTPA: PropTypes.object.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  setCreateEmailSent: PropTypes.func.isRequired,
  putPlanURL: PropTypes.func.isRequired,
  activeUserCc: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  userRole: selectRole(store),
  primaryAdvisor: selectPrimaryAdvisor(store),
  isValid: isFormValid(store),
  primaryTPA: selectPrimaryTPA(store),
  advisorInfo: selectAdvisorQuestions(store),
  activeUserCc: selectActiveUserEmail(store),
});

const mapDispatchToProps = (dispatch) => ({
  putPlanURL: (planURL, hashId) => {
    dispatch(FormDataRedux.putPlanURL(planURL, hashId));
  },
  addToast: (text, toastType) => {
    dispatch(ToastRedux.addToast(text, toastType));
  },
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  resetData: () => {
    dispatch(FormDataRedux.resetData());
  },
  setCreateEmailSent: () => {
    dispatch(FormDataRedux.setCreateEmailSent());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SharePlanModal);
