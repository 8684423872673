import React from 'react';
import PropTypes from 'prop-types';

const IconCalendarO = ({ fill, fillAlt }) => (
  <g>
    <path
      d="M33.6736 36.4878H6.74791C4.68733 36.4878 3 34.8114 3 32.7641V7.72378C3 5.67646 4.68733 4 6.74791 4H33.6736C35.7342 4 37.4215 5.67646 37.4215 7.72378V32.7641C37.4215 34.8114 35.7342 36.4878 33.6736 36.4878Z"
      stroke={fillAlt || fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M3.08789 11.1326H37.3355"
      stroke={fillAlt || fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M15.9062 17.2744H18.0896"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M22.332 17.2744H24.5102"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M28.7559 17.2744H30.9392"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M15.9062 23.635H18.0896"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M9.48438 23.635H11.6626"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M22.332 23.635H24.5102"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M28.7559 23.635H30.9392"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M15.9062 29.9954H18.0896"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M9.48438 29.9954H11.6626"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M22.332 29.9954H24.5102"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
    <path
      d="M28.7559 29.9954H30.9392"
      stroke={fill}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke"
    />
  </g>
);

IconCalendarO.propTypes = {
  fill: PropTypes.string.isRequired,
  fillAlt: PropTypes.string,
};

export default IconCalendarO;
