import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import SectionWrapper, { button, Section } from './Section';
import FormDataRedux, { isFormValid } from '../../../Redux/FormDataRedux';
import ModalRedux from '../../../Redux/ModalRedux';
import { requireConfirmation } from '../../../Services/helpers/Paradigm/planHelper';

const ConfirmModal = ({
  setPurchasePlanStatus,
  createPurchasePlan,
  pushGoogleDataLayer,
  destroyModal,
  copy,
}) => {
  const scrollToSection = () => {
    document.getElementById('purchasePLanSection').scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  return (
    <div className="text-center my-5">
      <h1 className="fs-h1 text-center mb-3">Safe harbor Deadline</h1>
      <p
        className="fs-p text-center mb-4 lh-125"
        dangerouslySetInnerHTML={{ __html: copy }}
      />
      <button
        type="button"
        className="srp-button srp-button__primary px-5"
        onClick={() => {
          setPurchasePlanStatus('loading');
          createPurchasePlan();
          pushGoogleDataLayer({
            event: 'PlanDesignFinish',
          });
          scrollToSection();
          destroyModal();
        }}
      >
        Submit Plan
      </button>
    </div>
  );
};
ConfirmModal.propTypes = {
  setPurchasePlanStatus: PropTypes.func.isRequired,
  createPurchasePlan: PropTypes.func.isRequired,
  pushGoogleDataLayer: PropTypes.func.isRequired,
  destroyModal: PropTypes.func.isRequired,
  copy: PropTypes.string.isRequired,
};

const PurchasePlanSection = ({
  userRole,
  createPurchasePlan,
  setPurchasePlanStatus,
  createSavedForm,
  toggleShareModalVisibility,
  goToRoleSelection,
  employerInfo,
  employerStateCode,
  hasFp,
  hasTpa,
  advisorInfo,
  tpaInfo,
  purchasePlanStatus,
  purchasePlanURL,
  showSavedPlanBar,
  createModal,
  destroyModal,
  questions,
  formData,
  isValid,
}) => {
  const sendDataToGTM = useGTMDispatch();
  const copy = `As a reminder, due to safe harbor deadlines, your plan will have a
    ${questions.planEffectiveDate.value} effective date. <strong>
      Please call
      844-804-0604 with any questions.
    </strong>`;
  const handleCreateModal = (pushGoogleDataLayer) => {
    if (requireConfirmation(questions.safeHarbor)) {
      createModal(
        <ConfirmModal
          setPurchasePlanStatus={setPurchasePlanStatus}
          createPurchasePlan={createPurchasePlan}
          pushGoogleDataLayer={pushGoogleDataLayer}
          destroyModal={destroyModal}
          copy={copy}
        />,
      );
    } else {
      setPurchasePlanStatus('loading');
      createPurchasePlan();
      sendDataToGTM({
        event: 'e_toolInteraction',
        eventType: 'progress',
        tool: {
          name: 'Simply Retirement',
          stepNumber: '3',
          stepName: 'purchase',
        },
      });

      pushGoogleDataLayer({
        event: 'PlanDesignFinish',
      });
    }
  };

  const purchaseButton = button(
    userRole,
    createSavedForm,
    toggleShareModalVisibility,
    goToRoleSelection,
    employerInfo,
    employerStateCode,
    hasFp,
    hasTpa,
    advisorInfo,
    tpaInfo,
    formData,
    isValid,
  );
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      if (typeof window !== 'undefined') {
        setSticky(
          ref.current.getBoundingClientRect().top - window.innerHeight >= 0,
        );
      }
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', () => handleScroll);
      }
    };
  }, []);
  switch (purchasePlanStatus) {
    case 'loading':
      return (
        <SectionWrapper
          loading
          heading={Section.loading.heading}
          copy={Section.loading.copy}
        />
      );
    case 200:
    case 201:
      return (
        <SectionWrapper
          heading={Section.success.heading}
          copy={Section.success.copy}
        >
          <p className="text-center">
            <a className="text-center" href={purchasePlanURL} target="_blank">
              {purchasePlanURL}
            </a>
          </p>
        </SectionWrapper>
      );
    case 'advisor-error':
      return (
        <SectionWrapper
          heading={Section.advisorError.heading}
          copy={Section.advisorError.copy}
        />
      );
    case 422:
      return (
        <SectionWrapper
          heading={Section.tryAgain.heading}
          copy={Section.tryAgain.copy}
        />
      );
    case 400:
    case 401:
    case 404:
    case 500:
      return (
        <SectionWrapper
          heading={Section.error.heading}
          copy={Section.error.copy}
        />
      );
    default:
      return (
        <SectionWrapper
          heading={purchaseButton.heading}
          copy={purchaseButton.copy}
        >
          <p
            className={classNames('text-center sticky-wrapper', {
              sticky: isSticky,
            })}
            ref={ref}
          >
            <button
              type="button"
              className={classNames(
                'srp-button srp-button__primary sticky-inner',
                {
                  'srp-button--btmbar': showSavedPlanBar && isSticky,
                },
              )}
              onClick={() => {
                purchaseButton.buttonClick(handleCreateModal);
              }}
            >
              {purchaseButton.buttonText}
            </button>
          </p>
        </SectionWrapper>
      );
  }
};

PurchasePlanSection.propTypes = {
  userRole: PropTypes.string.isRequired,
  createPurchasePlan: PropTypes.func.isRequired,
  setPurchasePlanStatus: PropTypes.func.isRequired,
  createSavedForm: PropTypes.func.isRequired,
  toggleShareModalVisibility: PropTypes.func.isRequired,
  goToRoleSelection: PropTypes.func.isRequired,
  employerStateCode: PropTypes.object.isRequired,
  hasFp: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  hasTpa: PropTypes.bool.isRequired,
  employerInfo: PropTypes.array.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  purchasePlanStatus: PropTypes.any.isRequired,
  purchasePlanURL: PropTypes.string,
  showSavedPlanBar: PropTypes.bool,
  createModal: PropTypes.func.isRequired,
  destroyModal: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  questions: store.formData.planDesign.questions,
  isValid: isFormValid(store),
});

const mapDispatchToProps = (dispatch) => ({
  createModal: (child, modalType) => {
    dispatch(ModalRedux.createModal(child, modalType));
  },
  destroyModal: () => {
    dispatch(ModalRedux.destroyModal());
  },
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchasePlanSection);
