import React from 'react';
import PropTypes from 'prop-types';
import SectionSMBCosts from '../SectionSMBCosts';
import Icon from '../../../../Images/icon-dollar-violet.svg';

const PredictableNoGuessPricing = ({
  putApplicationPanelInfo,
  businessOwnerWhySimplyDisclaimer3,
  businessOwnerWhySimplyDisclaimer4,
}) => (
  <section className="section section_py-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <img
            src={Icon}
            width={60}
            height={60}
            alt="Chart Icon"
            className="d-block mb-3"
          />
          <h3 className="fs-h3 fw-bold text-purple">
            Predictable, no-guess pricing
          </h3>
          <h2 className="fs-h2 fw-normal mb-3">
            A 401(k) plan with Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            has simple, transparent pricing.
          </h2>
          <p className="fs-h6 mb-5">
            There’s a one-time startup fee of $500, a monthly recordkeeping fee
            as low as $185 ($555 billed quarterly), and a $6 monthly fee{' '}
            <button
              type="button"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: businessOwnerWhySimplyDisclaimer3,
                })
              }
              className="text-underline p-0 no-border-button"
            >
              per participating employee.
            </button>
            <sup>
              <small>*</small>
            </sup>{' '}
            These are consistent costs you can plan for in your budget.
          </p>
        </div>
      </div>

      <SectionSMBCosts
        title="Simply Retirement by Principal<sup><small>®</small></sup> with PSIvet costs"
        putApplicationPanelInfo={putApplicationPanelInfo}
        cardType="default floating"
        businessOwnerWhySimplyDisclaimer4={businessOwnerWhySimplyDisclaimer4}
      />
    </div>
  </section>
);

PredictableNoGuessPricing.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  businessOwnerWhySimplyDisclaimer3: PropTypes.any,
  businessOwnerWhySimplyDisclaimer4: PropTypes.any,
};

export default PredictableNoGuessPricing;
