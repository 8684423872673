import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-dropdown-select';
import classNames from 'classnames';
import Label from '../Label';

export const Dropdown = ({
  uniqueId,
  name,
  label,
  labelClass,
  inputClass,
  value,
  required,
  options,
  hasError,
  handleQuestionChange,
  infoLabel = null,
  placeholder = 'Select..',
  children,
  labelInfo,
  disabled,
}) => (
  <div
    className={classNames('mb-3 form-group', {
      'has-error': hasError,
    })}
  >
    <Label
      name={uniqueId || name}
      className={labelClass}
      text={disabled ? 'Loading...' : label}
      required={required}
    >
      {labelInfo}
    </Label>
    {infoLabel && (
      <div
        className="fs-p text-center mb-5"
        dangerouslySetInnerHTML={{ __html: infoLabel }}
      />
    )}

    <div className="dropdown-wrapper">
      <Select
        disabled={disabled}
        id={uniqueId || name}
        addPlaceholder={value === '' ? placeholder : ''}
        name={uniqueId || name}
        className={`custom-select form-select ${inputClass}`}
        values={[value]}
        onClearAll={() => handleQuestionChange(name, '', '')}
        clearable
        options={[...options].sort((a, b) => a?.label?.localeCompare(b.label))}
        onChange={(selected) => {
          if (selected.length === 1) {
            handleQuestionChange(
              name,
              selected[0],
              selected[0].valueFormatted || selected[0].value,
            );
          }
        }}
      />
      {children}
    </div>
  </div>
);

Dropdown.propTypes = {
  uniqueId: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  options: PropTypes.array.isRequired,
  hasError: PropTypes.bool.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  infoLabel: PropTypes.any,
  placeholder: PropTypes.string,
  children: PropTypes.any,
  labelInfo: PropTypes.any,
  disabled: PropTypes.bool,
};

export default Dropdown;
