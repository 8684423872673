import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import HeaderSection from '../../../common/HeaderSection';
import { ResourcesHeroBusinessOwner, CheckCircle } from '../../../ImagePaths';

const sectionHeader = {
  image: ResourcesHeroBusinessOwner,
  title: 'Retirement resources',
  caption: `
    <p class="fs-display5">
      Administering plans and servicing client needs is a full-time job. Save time with these resources to help your small business clients better understand the ins and outs of workplace retirement plans.
    </p>
  `,
  alt: 'Employee reading article in newspaper.',
  copy: ``,
};

const Resources = () => (
  <div className="resources-page mb-5">
    <HeaderSection
      image={sectionHeader.image}
      title={sectionHeader.title}
      caption={sectionHeader.caption}
      copy={sectionHeader.copy}
      alt={sectionHeader.alt}
    />
    <section className="section py-5">
      <div className="row">
        <div className="col-lg-5 mx-auto">
          <h3 className="fs-h3 text-center mb-5">
            <a
              href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736&ty=VOP"
              target="_blank"
            >
              Simply Retirement by Principal brochure
            </a>
          </h3>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <h3 className="fs-h3 text-center mb-5">
              See what Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              offers you.
            </h3>
          </div>
        </div>
        <div className="row d-print-none">
          <div className="col-lg-7 mx-auto">
            <p className="fs-h6 text-center mb-5">
              Watch this video demo and see how we make it easy to design and
              manage retirement plans for small businesses.
            </p>
            <ReactPlayerLoader
              accountId="1510329453001"
              videoId="6274775432001"
              attrs={{
                className: 'brightcove-player',
              }}
              embedOptions={{ responsive: { aspectRatio: '16:9' } }}
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section section_py-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="resource-card text-center px-md-8 py-md-5">
              <p>
                <strong>
                  Get helpful information to support you through the plan design
                  process.
                </strong>{' '}
                Download this document for plan design considerations and other
                important details for TPAs.
              </p>
              <a
                href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736S&ty=VOP"
                target="_blank"
                className="srp-button srp-button__primary"
              >
                Get plan design supports
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section_py-5 pt-5">
      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <img
              src={CheckCircle}
              alt="Checkmark In Circle Icon"
              className="d-block mx-auto mb-3"
            />
            <h3 className="fs-h3 text-center mb-4">Ready to get started?</h3>
            <p className="text-center pb-5 mb-5">
              <Link to="/plans" className="srp-button srp-button__primary">
                Try our 401(k) planner
              </Link>
            </p>
            <p className="fs-disclaimer text-center pb-5 mb-5">
              Intended for TPA and financial professional use.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Resources;
