import React from 'react';
import PropTypes from 'prop-types';

const IconCoinsO = ({ fill }) => (
  <g>
    <path
      d="M10.1147 6.93676C12.8545 4.18739 16.6452 2.48595 20.8334 2.48595C29.1906 2.48595 35.9654 9.26081 35.9654 17.618C35.9654 18.0335 35.9487 18.4451 35.9158 18.8522C36.1442 19.9843 36.2641 21.1557 36.2641 22.3551C36.2641 24.0661 36.0202 25.7201 35.5652 27.2843C37.3898 24.5091 38.4514 21.1875 38.4514 17.618C38.4514 7.88786 30.5635 0 20.8334 0C12.8142 0 6.04639 5.35769 3.91418 12.6888C5.48121 10.3054 7.61107 8.32504 10.1147 6.93676Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6455 7.24984C10.3034 7.24984 3.54085 14.0124 3.54085 22.3545C3.54085 30.6965 10.3034 37.4591 18.6455 37.4591C26.9875 37.4591 33.7501 30.6965 33.7501 22.3545C33.7501 14.0124 26.9875 7.24984 18.6455 7.24984ZM1 22.3545C1 12.6091 8.90015 4.70898 18.6455 4.70898C28.3908 4.70898 36.291 12.6091 36.291 22.3545C36.291 32.0998 28.3908 39.9999 18.6455 39.9999C8.90015 39.9999 1 32.0998 1 22.3545Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1947 14.2242C23.1548 14.972 23.9042 16.0695 23.9955 17.4434C24.042 18.1435 23.5122 18.7488 22.8121 18.7953C22.112 18.8418 21.5068 18.312 21.4603 17.6119C21.4275 17.1194 21.1605 16.6394 20.6334 16.2288C20.0991 15.8126 19.3553 15.5219 18.5678 15.4472C17.7816 15.3727 17.0419 15.5209 16.4797 15.8672C15.9585 16.1884 15.5123 16.7244 15.3136 17.6177C15.2736 18.1029 15.349 18.4484 15.4682 18.7104C15.595 18.9889 15.806 19.249 16.1308 19.5043C16.8277 20.0519 17.8612 20.4459 19.1623 20.8978C19.2537 20.9295 19.3467 20.9617 19.441 20.9942C20.5822 21.3882 21.9099 21.8466 22.9529 22.5351C24.1669 23.3365 25.1373 24.5428 25.1373 26.3806C25.1373 29.0538 23.3697 30.9734 20.9775 31.7134C18.6164 32.4438 15.6346 32.0654 12.7701 30.3862C12.1648 30.0313 11.9618 29.253 12.3166 28.6477C12.6714 28.0424 13.4498 27.8393 14.0551 28.1942C16.4234 29.5825 18.6688 29.7679 20.2266 29.286C21.7534 28.8137 22.5965 27.7447 22.5965 26.3806C22.5965 25.6244 22.2686 25.1279 21.5531 24.6556C20.7895 24.1516 19.7553 23.7928 18.5103 23.361C18.4503 23.3402 18.3897 23.3192 18.3287 23.298C17.105 22.873 15.6497 22.3578 14.5608 21.5021C13.9928 21.0556 13.4867 20.4906 13.1555 19.7627C12.822 19.0296 12.6999 18.2062 12.7914 17.2988C12.7957 17.2557 12.8023 17.213 12.811 17.1706C13.1384 15.5763 13.9959 14.4132 15.1469 13.704C16.2693 13.0125 17.5864 12.8019 18.8076 12.9177C20.0275 13.0333 21.2418 13.482 22.1947 14.2242Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8674 9.0166C18.569 9.0166 19.1378 9.58539 19.1378 10.287V13.8056C19.1378 14.5073 18.569 15.0761 17.8674 15.0761C17.1657 15.0761 16.5969 14.5073 16.5969 13.8056V10.287C16.5969 9.58539 17.1657 9.0166 17.8674 9.0166Z"
      fill={fill}
      className="fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8674 29.5195C18.569 29.5195 19.1378 30.0883 19.1378 30.79V34.4326C19.1378 35.1343 18.569 35.703 17.8674 35.703C17.1657 35.703 16.5969 35.1343 16.5969 34.4326V30.79C16.5969 30.0883 17.1657 29.5195 17.8674 29.5195Z"
      fill={fill}
      className="fill"
    />
  </g>
);

IconCoinsO.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconCoinsO;
