import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NextStepBtn from './NextStepBtn';
import ReviewPlanBtn from './ReviewPlanBtn';

export const StepControls = ({
  textNextBtn,
  formInReview,
  disabledNextBtn,
  clickNextBtn,
  disableReviewBtn,
  goToReviewPlan,
}) => (
  <p
    className={classNames({
      fws__control: !formInReview,
      'fws__control-group': formInReview,
    })}
  >
    <NextStepBtn
      text={textNextBtn}
      disabled={disabledNextBtn}
      onClick={clickNextBtn}
    />
    {formInReview && (
      <ReviewPlanBtn
        disabled={disableReviewBtn}
        goToReviewPlan={goToReviewPlan}
      />
    )}
  </p>
);

StepControls.propTypes = {
  textNextBtn: PropTypes.string,
  formInReview: PropTypes.bool.isRequired,
  disabledNextBtn: PropTypes.bool.isRequired,
  clickNextBtn: PropTypes.func.isRequired,
  disableReviewBtn: PropTypes.bool.isRequired,
  goToReviewPlan: PropTypes.func.isRequired,
};

export default StepControls;
