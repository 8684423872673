export const autoDeferralPercentageStartEmployees = {
  id: 'q6aI1a',
  type: 'slider',
  name: 'autoDeferralPercentageStartEmployees',
  paradigmAlias: 'minimumAutoDeferralPercentage',
  stepName: 'a_step_6_a_i_1_a',
  step: 1,
  labelClass: 'fs-form-q fw-bold text-center py-3',
  tooltipInfo: '',
  user: {},
  label: 'Select the auto deferral percentage at which employees will start.',
  infoLabel: '',
  format: 'percentage',
  required: true,
  dirty: false,
  disabled: false,
  valueFormatted: '7%',
  value: 7,
  hasConditional: false,
  hasError: false,
  min: 3,
  thousandSeperator: false,
  max: 10,
  defaultValue: 7,
  sideCardTitle: 'Auto deferral percentage',
  reviewScreenTitle: 'Auto deferral percentage',
  optionDescription: '',
  description:
    'This is the auto deferral percentage at which employees will start if auto enrollment is selected.',
};
