import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const OverlayBtnToggle = ({
  setOverlayVisibility,
  visibleOverlay,
  children,
}) => (
  <button
    type="button"
    aria-label={visibleOverlay ? 'Close Menu' : 'Open Menu'}
    aria-expanded={visibleOverlay ? 'true' : 'false'}
    className={classNames('burgerbtn', {
      open: !visibleOverlay,
      close: visibleOverlay,
    })}
    onClick={() => {
      setOverlayVisibility(!visibleOverlay);
    }}
  >
    <span className="item"></span>
    <span className="item"></span>
    <span className="item"></span>
    <em className="sr-only">{children}</em>
  </button>
);

OverlayBtnToggle.propTypes = {
  setOverlayVisibility: PropTypes.func.isRequired,
  visibleOverlay: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};

export default OverlayBtnToggle;
