import { googleData } from '../../../Services/utilities';

export const stepsInfo = (hashId, formData, history, role) => [
  {
    hashId,
    hasSidebar: false,
    planOwner: formData.planOwner,
    createdDate: formData.createdDate,
    updatedDate: formData.updatedDate,
    key: 'begin',
    component: 'LetsBegin',
    destination: () => 'tpaCheckList',
    googleData: googleData('plans'),
  },
  {
    key: 'tpaCheckList',
    hasSidebar: false,
    component: 'TpaCheckList',
    destination: () => 'tpaInformation',
    previousStep: hashId !== '' ? 'returnRoleSelection' : 'roleSelection',
    googleData: googleData('checklist_tpa'),
  },
  {
    key: 'tpaInformation',
    hasSidebar: false,
    component: 'TpaInformation',
    previousStep: 'tpaCheckList',
    destination: () => 'clientInfo',
  },
  {
    key: 'clientInfo',
    hasSidebar: false,
    component: 'ClientInfo',
    history,
    formData,
    previousStep: 'tpaInformation',
    destination: (value) => (value ? 'addAdvisor' : 'tpaFees'),
    googleData: googleData('client_info'),
  },
  {
    key: 'addAdvisor',
    hasSidebar: false,
    component: 'AddAdvisor',
    previousStep: 'clientInfo',
    destination: () => 'tpaFees',
    googleData: googleData('add_advisor'),
  },
  {
    key: 'tpaFees',
    hasSidebar: false,
    component: 'TpaFees',
    destination: 'tpaFeesDeductions',
    previousStep: (value) => (value ? 'addAdvisor' : 'clientInfo'),
  },
  {
    key: 'tpaFeesDeductions',
    hasSidebar: false,
    component: 'TpaFeesDeductions',
    destination: 'participantFees',
    previousStep: 'tpaFees',
  },
  {
    key: 'participantFees',
    hasSidebar: false,
    component: 'ParticipantFees',
    destination: (value) => (value ? '_tpa_step_1' : 'isCurrentPrincipalPlan'),
    previousStep: 'tpaFeesDeductions',
  },
  {
    role,
    hashId,
    key: 'isCurrentPrincipalPlan',
    googleData: googleData('isCurrentPrincipalPlan'),
    questionId: 'isCurrentPrincipalPlan',
    component: 'IsCurrentPrincipalPlan',
    hasSidebar: false,
  },
];
