import INITIAL_STATE, {
  USER_SELECT_INITIAL_STATE,
  ADVISOR_INITIAL_STATE,
  TPA_INITIAL_STATE,
  PLAN_DESIGN_INITIAL_STATE,
} from '../InitialState';
import {
  TPA_INITIAL_SECTION_A_NONE,
  TPA_INITIAL_SECTION_B_ACA_ALT,
  TPA_INITIAL_SECTION_B_ACA_ALT2,
  TPA_INITIAL_SECTION_A_ACA,
  TPA_INITIAL_SECTION_A_QACA,
  TPA_INITIAL_SECTION_A,
  TPA_INITIAL_SECTION_B_NONE_ALT,
  TPA_INITIAL_SECTION_B_NONE_ALT2,
  TPA_INITIAL_SECTION_B_NONE,
  TPA_INITIAL_SECTION_B_ACA,
  TPA_INITIAL_SECTION_B_QACA_ALT,
  TPA_INITIAL_SECTION_B_QACA_ALT2,
  TPA_INITIAL_SECTION_B_QACA,
  TPA_INITIAL_SECTION_B,
} from '../InitialState/TPAInitialState';

import {
  DEFAULT_PLAN_ENROLLMENT,
  DEFAULT_PLAN_NO_ENROLLMENT,
  DEFAULT_PLAN_SAFE_HARBOR,
  DEFAULT_PLAN_NON_SAFE_HARBOR,
  DEFAULT_PLAN_MATCHING_CONT,
} from '../InitialState/DefaultPlanInitialState';
import { asOfDate } from '../../../Services/utilities';
import { initOneDigitalAdvisors } from '../InitialState/OneDigital/OneDigitalState';

/* ------------- Reducers ------------- */
export { putQuestionById } from './putQuestionById';
export { putActiveStep } from './putActiveStep';

export const putExperience = (state, { experience }) => ({
  ...state,
  experience,
});

// export const putActiveStep = (state, { formId, updateStep }) => ({
//   ...state,
//   [formId]: {
//     inProgress: true,
//     ...state[formId],
//     activeStep: updateStep,
//   },
// });

export const updateQuestion = (state, { obj }) => ({
  ...state,
  planDesign: {
    ...state.planDesign,
    inProgress: true,
    questions: {
      ...state.planDesign.questions,
      [obj.name]: {
        ...state.planDesign.questions[obj.name],
        ...obj,
      },
    },
  },
});

export const updateTPA = (state, { obj, index }) => {
  const updateTPAs = state.planDesign.tpas.map((tpa, i) => {
    const update = {
      ...tpa,
      questions: {
        ...tpa.questions,
        [obj.name]: {
          ...tpa.questions[obj.name],
          ...obj,
        },
      },
    };
    return i === index ? update : tpa;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      inProgress: true,
      tpas: updateTPAs,
    },
  };
};

export const addTpa = (state) => {
  const tpaLimit = state.planDesign.tpas.length;
  if (tpaLimit > 1) {
    return {
      ...state,
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      tpas: [
        ...state.planDesign.tpas,
        {
          ...TPA_INITIAL_STATE,
          questions: {
            ...TPA_INITIAL_STATE.questions,
            tpaZipCode: {
              ...TPA_INITIAL_STATE.questions.tpaZipCode,
              required: false,
            },
            tpaPhone: {
              ...TPA_INITIAL_STATE.questions.tpaPhone,
              required: true,
            },
          },
        },
      ],
      questions: {
        ...state.planDesign.questions,
        numberOfEmployees: {
          ...state.planDesign.questions.numberOfEmployees,
          stepName: '_tpa_step_1',
        },
      },
    },
  };
};

export const updateAdvisor = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      questions: {
        ...advisor.questions,
        [obj.name]: {
          ...advisor.questions[obj.name],
          ...obj,
        },
      },
    };
    return i === index ? update : advisor;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      inProgress: true,
      advisors: updateAdvisors,
    },
  };
};

export const updateAllAdvisors = (state, { obj }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor) => {
    const update = {
      ...advisor,
      questions: {
        ...advisor.questions,
        [obj.name]: {
          ...advisor.questions[obj.name],
          ...obj,
        },
      },
    };
    return update;
  });

  return {
    ...state,
    planDesign: {
      inProgress: true,
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const addAdvisor = (state) => {
  const advisorLimit = state.planDesign.advisors.length;
  if (advisorLimit > 4) {
    return {
      ...state,
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: [
        ...state.planDesign.advisors,
        {
          ...ADVISOR_INITIAL_STATE,
          advisorCredential: state.planDesign.advisors[0].advisorCredential,
          advisorCredentialVerification:
            state.planDesign.advisors[0].advisorCredentialVerification,
          questions: {
            ...ADVISOR_INITIAL_STATE.questions,
            firmName: state.planDesign.advisors[0].questions.firmName,
          },
        },
      ],
    },
  };
};

export const removeAdvisor = (state, { index }) => {
  const trimmed = state.planDesign.advisors.filter(
    (_advisor, i) => i !== index,
  );
  if (index === 0) {
    return { ...state };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: [...trimmed],
    },
  };
};

export const removeExtraAdvisors = (state) => ({
  ...state,
  planDesign: {
    ...state.planDesign,
    advisors: [state.planDesign.advisors[0]],
  },
});

export const setAdvisorCvDataAndResponse = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCvData: {
        state: obj.writtenState,
        date: obj.applicationSignedDate,
        mutualFund: obj.mutualFund,
        levelized: obj.levelized,
        brokerType: advisor.advisorCredentialVerification.brokerType,
        distributionPartners: obj.distributionPartners,
        principalError: obj.principalError,
        cvResponse: obj.cvResponse,
      },
    };
    return i === index ? update : advisor;
  });
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      inProgress: true,
      advisors: updateAdvisors,
    },
  };
};

export const putFormInReview = (state, { formId, formInReview }) => ({
  ...state,
  [formId]: {
    inProgress: false,
    ...state[formId],
    formInReview,
  },
});

export const putRole = (state, { userRole }) => ({
  ...state,
  role: userRole,
});

export const setPlanOriginator = (state, { planOriginator }) => ({
  ...state,
  planOriginator,
});

export const setCreateEmailSent = (state) => ({
  ...state,
  createEmailSent: true,
});

export const resetAdvisorCredential = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCredential: {
        ...ADVISOR_INITIAL_STATE.advisorCredential,
        status: obj.status,
      },
      advisorCredentialVerification: {
        ...ADVISOR_INITIAL_STATE.advisorCredentialVerification,
      },
    };
    return i === index ? update : advisor;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const putEid = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCredential: {
        ...advisor.advisorCredential,
        status: obj.status,
        eid: {
          ...advisor.advisorCredential.eid,
          value: obj.enterpriseId || '',
          error: obj.enterpriseIdError || '',
          code: obj.code || '',
        },
      },
    };
    return i === index ? update : advisor;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const putHierarchies = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCredential: {
        ...advisor.advisorCredential,
        status: obj.status,
        hierarchies: {
          ...advisor.advisorCredential.hierarchies,
          value: obj.hierarchies || '',
          error: obj.hierarchiesError || '',
          code: obj.code || '',
        },
      },
    };
    return i === index ? update : advisor;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const putInsuranceAgent = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCredentialVerification: {
        ...advisor.advisorCredentialVerification,
        insuranceAgent: obj.insuranceAgent,
      },
    };
    return i === index ? update : advisor;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const putInsuranceAgentNpn = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCredentialVerification: {
        ...advisor.advisorCredentialVerification,
        insuranceAgentNpn: obj.insuranceAgentNpn,
      },
    };
    return i === index ? update : advisor;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const putBrokerType = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCredentialVerification: {
        ...advisor.advisorCredentialVerification,
        brokerType: obj.brokerType,
      },
    };
    return i === index ? update : advisor;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const putInsuranceAgentCompType = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCredentialVerification: {
        ...advisor.advisorCredentialVerification,
        insuranceAgentCompType: obj.insuranceAgentCompType,
      },
    };
    return i === index ? update : advisor;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const putBrokerEid = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCredentialVerification: {
        ...advisor.advisorCredentialVerification,
        brokerEid: obj.eid,
        verificationStatus: obj.verificationStatus,
        status: obj.status,
      },
    };
    return i === index ? update : advisor;
  });

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const putCrd = (state, { obj, index }) => {
  const updateAdvisors = state.planDesign.advisors.map((advisor, i) => {
    const update = {
      ...advisor,
      advisorCredentialVerification: {
        ...advisor.advisorCredentialVerification,
        crd: {
          value: obj.crd || '',
          error: obj.crdError || '',
        },
        code: obj.code || '',
      },
    };
    return i === index ? update : advisor;
  });
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: updateAdvisors,
    },
  };
};

export const putApplicationPanelInfo = (state, { obj }) => ({
  ...state,
  applicationPanelInfo: {
    hasVisibility: obj.hasVisibility,
    heading: obj.heading,
    description: obj.description,
    additionalInformation: obj.additionalInformation,
  },
});

export const putPlanURL = (state, { planURL, hashId }) => ({
  ...state,
  planURL,
  hashId,
});

export const putPurchasePlanURL = (state, { purchasePlanURL, planId }) => ({
  ...state,
  purchasePlanURL,
  planId,
});

export const putApplicationState = (state, { localData }) => {
  if (localData.planDesign.advisors) {
    return {
      ...INITIAL_STATE,
      ...localData,
      planDesign: {
        ...INITIAL_STATE.planDesign,
        ...localData.planDesign,
        questions: {
          hasTpa: USER_SELECT_INITIAL_STATE.hasTpa,
          investmentLineup: USER_SELECT_INITIAL_STATE.investmentLineup,
          planNumber: USER_SELECT_INITIAL_STATE.planNumber,
          planName: USER_SELECT_INITIAL_STATE.planName,
          ...INITIAL_STATE.planDesign.questions,
          ...localData.planDesign.questions,
        },
      },
    };
  }
  return {
    ...localData,
    planDesign: {
      ...localData.planDesign,
      advisors: [
        {
          ...ADVISOR_INITIAL_STATE,
          questions: {
            ...ADVISOR_INITIAL_STATE.questions,
            advisorNpn: {
              ...localData.planDesign.questions.advisorNpn,
            },
            advisorEmail: {
              ...localData.planDesign.questions.advisor_email,
            },
            advisorFirstName: {
              ...localData.planDesign.questions.advisorFirstName,
            },
            advisorLastName: {
              ...localData.planDesign.questions.advisorLastName,
            },
            advisorStateCode: {
              ...localData.planDesign.questions.advisorStateCode,
            },
            advisorZipCode: {
              ...localData.planDesign.questions.advisorZipCode,
            },
            advisorPhone: {
              ...localData.planDesign.questions.advisorPhone,
            },
            firmName: {
              ...localData.planDesign.questions.firmName,
            },
            floridaLicenseNumber: {
              ...localData.planDesign.questions.floridaLicenseNumber,
            },
            compensation: {
              ...localData.planDesign.questions.compensation,
            },
          },
        },
      ],
    },
  };
};

export const resetData = () => INITIAL_STATE;

export const resetEmployerData = (state) => {
  const {
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    companyName,
    companyPhone,
    employerStateCode,
    insuranceGroupType,
    sponsorTitle,
    hasFp,
    hasTpa,
  } = USER_SELECT_INITIAL_STATE;
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
        sponsorFirstName,
        sponsorLastName,
        sponsorEmail,
        companyName,
        insuranceGroupType,
        sponsorTitle,
        companyPhone,
        employerStateCode,
        hasFp,
        hasTpa,
      },
    },
  };
};

export const resetAdvisorData = (state) => {
  const { investmentLineup } = PLAN_DESIGN_INITIAL_STATE;
  const {
    advisorFirmBroker,
    advisorCompensation,
    advisorType,
    advisorFee,
    advisorPoints,
    confirmAdvisorPoints,
    confirmAdvisorFee,
  } = USER_SELECT_INITIAL_STATE;
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: [
        {
          ...ADVISOR_INITIAL_STATE,
          questions: {
            ...state.planDesign.advisors[0].questions,
            firmName: ADVISOR_INITIAL_STATE.questions.firmName,
            compensation: ADVISOR_INITIAL_STATE.questions.compensation,
          },
        },
      ],
      questions: {
        ...state.planDesign.questions,
        advisorFirmBroker,
        advisorCompensation,
        advisorType,
        advisorFee,
        advisorPoints,
        confirmAdvisorPoints,
        confirmAdvisorFee,
        investmentLineup,
      },
    },
  };
};

export const resetPlanQuestion = (state, { questionId, customAttr }) => ({
  ...state,
  planDesign: {
    ...state.planDesign,
    questions: {
      ...state.planDesign.questions,
      [questionId]: INITIAL_STATE.planDesign.questions[questionId],
      ...customAttr,
    },
  },
});

export const updateRequiredQuestion = (state, { questionId, required }) => ({
  ...state,
  planDesign: {
    ...state.planDesign,
    questions: {
      ...state.planDesign.questions,
      [questionId]: {
        ...state.planDesign.questions[questionId],
        required,
      },
    },
  },
});

export const resetUnusedAutoEnrollment = (state, { value }) => {
  if (value === 'yes') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
          ...DEFAULT_PLAN_ENROLLMENT,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
        ...DEFAULT_PLAN_NO_ENROLLMENT,
      },
    },
  };
};

export const resetUnusedContributionMatch = (state, { value }) => {
  if (value === 'yes') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
        ...DEFAULT_PLAN_MATCHING_CONT,
      },
    },
  };
};

export const resetUnusedSafeHarbor = (state, { value }) => {
  if (value === 'safeHarbor') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
          ...DEFAULT_PLAN_SAFE_HARBOR,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
        ...DEFAULT_PLAN_NON_SAFE_HARBOR,
      },
    },
  };
};

export const resetUnusedContributionOptions = (state) => {
  const { contributionRequirements } = state.planDesign.questions;
  if (contributionRequirements.value === 'yes') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
          ...TPA_INITIAL_SECTION_B,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
        ...TPA_INITIAL_SECTION_A,
      },
    },
  };
};

export const resetUnusedEnrollmentOptions = (
  state,
  { enrollment, enrollmentPlan },
) => {
  if (enrollment.value === 'yes') {
    switch (enrollmentPlan.value) {
      case 'aca':
      case 'eaca':
        return {
          ...state,
          planDesign: {
            ...state.planDesign,
            questions: {
              ...state.planDesign.questions,
              ...TPA_INITIAL_SECTION_A_NONE,
              ...TPA_INITIAL_SECTION_A_QACA,
              ...TPA_INITIAL_SECTION_B_NONE_ALT,
              ...TPA_INITIAL_SECTION_B_NONE_ALT2,
              ...TPA_INITIAL_SECTION_B_NONE,
              ...TPA_INITIAL_SECTION_B_QACA_ALT,
              ...TPA_INITIAL_SECTION_B_QACA_ALT2,
              ...TPA_INITIAL_SECTION_B_QACA,
            },
          },
        };
      default:
        return {
          ...state,
          planDesign: {
            ...state.planDesign,
            questions: {
              ...state.planDesign.questions,
              ...TPA_INITIAL_SECTION_A_NONE,
              ...TPA_INITIAL_SECTION_A_ACA,
              ...TPA_INITIAL_SECTION_B_NONE_ALT,
              ...TPA_INITIAL_SECTION_B_NONE_ALT2,
              ...TPA_INITIAL_SECTION_B_NONE,
              ...TPA_INITIAL_SECTION_B_ACA_ALT,
              ...TPA_INITIAL_SECTION_B_ACA_ALT2,
              ...TPA_INITIAL_SECTION_B_ACA,
            },
          },
        };
    }
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
        ...TPA_INITIAL_SECTION_A_ACA,
        ...TPA_INITIAL_SECTION_A_QACA,
        ...TPA_INITIAL_SECTION_B_ACA_ALT,
        ...TPA_INITIAL_SECTION_B_ACA_ALT2,
        ...TPA_INITIAL_SECTION_B_ACA,
        ...TPA_INITIAL_SECTION_B_QACA_ALT,
        ...TPA_INITIAL_SECTION_B_QACA_ALT2,
        ...TPA_INITIAL_SECTION_B_QACA,
      },
    },
  };
};

export const resetUnusedNoneAltOptions = (state, { value }) => {
  if (value === 'no') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
          ...TPA_INITIAL_SECTION_B_NONE_ALT,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
      },
    },
  };
};

export const resetUnusedNoneAltTwoOptions = (state, { value }) => {
  if (value === 'no') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
          ...TPA_INITIAL_SECTION_B_NONE_ALT2,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
      },
    },
  };
};

export const resetUnusedBacaAltOptions = (state, { value }) => {
  if (value === 'no') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
          ...TPA_INITIAL_SECTION_B_ACA_ALT,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
      },
    },
  };
};

export const resetUnusedBacaAltTwoOptions = (state, { value }) => {
  if (value === 'no') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
          ...TPA_INITIAL_SECTION_B_ACA_ALT2,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
      },
    },
  };
};

export const resetUnusedBqacaAltOptions = (state, { value }) => {
  if (value === 'no') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
          ...TPA_INITIAL_SECTION_B_QACA_ALT,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
      },
    },
  };
};

export const resetUnusedBqacaAltTwoOptions = (state, { value }) => {
  if (value === 'no') {
    return {
      ...state,
      planDesign: {
        ...state.planDesign,
        questions: {
          ...state.planDesign.questions,
          ...TPA_INITIAL_SECTION_B_QACA_ALT2,
        },
      },
    };
  }
  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      questions: {
        ...state.planDesign.questions,
      },
    },
  };
};

// This should be in it's own file
// Need a parameter to choose primary advisor
export const putOneDigitalAdvisor = (state) => ({
  ...state,
  planDesign: {
    ...state.planDesign,
    advisors: initOneDigitalAdvisors(),
    questions: {
      ...state.planDesign.questions,
      advisorCompensation: {
        ...state.planDesign.questions.advisorCompensation,
        dirty: true,
        value: 'option_2',
        valueFormatted:
          state.planDesign.questions.advisorCompensation.options[1].label,
      },
      advisorFee: {
        ...state.planDesign.questions.advisorFee,
        dirty: true,
        value: '',
      },
      investmentLineup: {
        ...state.planDesign.questions.investmentLineup,
        dirty: true,
        value: 'fundLineupOneDigital',
        valueFormatted: '3(38) investment lineup OneDigital 1',
        heading: '3(38) investment lineup OneDigital 1',
        content: `
          <strong>Investment fees vary and range from 0.02% - 0.86% as (of ${asOfDate}) depending on the investment chosen.</strong>
          <br/>See investment details <a href="https://www.simplyretirement.com/onedigital/investments">here</a>.`,
      },
      hasTpa: {
        ...state.planDesign.questions.hasTpa,
        dirty: true,
        value: false,
        valueFormatted:
          state.planDesign.questions.hasTpa.options[1].valueFormatted,
      },
      advisorType: {
        ...state.planDesign.questions.advisorType,
        dirty: true,
        value: state.planDesign.questions.advisorType.options[1].label,
        valueFormatted: state.planDesign.questions.advisorType.options[1].label,
      },
      advisorPoints: {
        ...state.planDesign.questions.advisorPoints,
        dirty: true,
        value: 'option_2',
        valueFormatted: '45',
      },
      hasFp: {
        ...state.planDesign.questions.hasFp,
        dirty: true,
        value: true,
        valueFormatted: state.planDesign.questions.hasFp.options[0].label,
      },
      firmNameFreeForm: {
        ...state.planDesign.questions.firmNameFreeForm,
        dirty: true,
        value: 'OneDigital Investment Advisors',
        valueFormatted: 'OneDigital Investment Advisors',
      },
      advisorFirmBroker: {
        ...state.planDesign.questions.advisorFirmBroker,
        dirty: true,
        value: {
          id: 9999,
          broker_dealer_name: 'ONEDIGITAL INVESTMENT ADVISORS',
          eid: '',
          brokerEid: 'G86R0152W',
          crd: 106766,
          broker_type: 'iarRia',
          brokerType: 'iarRia',
          label: 'ONEDIGITAL INVESTMENT ADVISORS',
          value: 'ONEDIGITAL INVESTMENT ADVISORS',
          compensation: 'various',
          investments: ['fundLineup1'],
          max_basis_points: ['65'],
          split_comp: true,
          maxBasisPoints: ['65'],
        },
        valueFormatted: 'ONEDIGITAL INVESTMENT ADVISORS',
      },
    },
  },
});

export const setAsPrimary = (state, { advisorIndex }) => {
  const deepClone = JSON.parse(JSON.stringify(state.planDesign.advisors));
  const primary = deepClone[advisorIndex];
  deepClone.splice(advisorIndex, 1);
  deepClone.unshift(primary);

  return {
    ...state,
    planDesign: {
      ...state.planDesign,
      advisors: deepClone,
    },
  };
};
