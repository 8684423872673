import React from 'react';
import PropTypes from 'prop-types';

const IconBusinessOwnerO = ({ fill }) => (
  <g>
    <path
      d="M35.7112 4.32979L12.6915 27.4589L4.28884 18.9284C3.8512 18.5766 3.15098 18.5766 2.80088 18.9284L0.262582 21.4787C-0.0875274 21.8305 -0.0875274 22.534 0.262582 22.9738L11.9912 34.6702C12.4289 35.1099 13.0416 35.1099 13.4792 34.6702L39.7374 8.28723C40.0875 7.93546 40.0875 7.23191 39.7374 6.7922L37.1991 4.32979C36.849 3.89007 36.1488 3.89007 35.7112 4.32979Z"
      className="primary-fill"
      fill={fill}
    />
  </g>
);

IconBusinessOwnerO.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default IconBusinessOwnerO;
