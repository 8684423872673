export const offerRothContributions = {
  id: 'q1',
  type: 'radio_condensed',
  name: 'offerRothContributions',
  paradigmAlias: 'offerRothContributions',
  user: {},
  stepName: 'offerRothContributions_1',
  label:
    'Do you want to make Roth contributions available to plan participants in addition to pre-tax deferrals?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  hasError: false,
  dirty: false,
  value: '',
  reviewScreenTitle:
    'Offer Roth contributions in addition to pre-tax deferrals',
  sideCardTitle: 'Offer Roth contributions in addition to pre-tax deferrals',
  description:
    'If this option is selected, participants can choose to make both pre-tax and Roth (after-tax) contributions to their 401(k) plan—giving them more ways to get the most out of their retirement savings. Auto-enrollment contributions are only pre-tax contributions.',
};
