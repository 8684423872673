// const CONTACT_NUMBER = { display: '800.952.3343', tel: '+18009523343' };
const DISCLAIMER = `<p class="text-white text-center fs-sm mb-4">
        By clicking “Contact us,” you agree to the Principal privacy
        policy. Your email will only be used to send you updates about
        Simply Retirement by Principal<small><sup>®</sup></small>, and you may update your preferences or unsubscribe anytime.
      </p>`;
export const pages = {
  exploreOptions: {
    content: `<h1 class="fs-h1 fw-bold text-white text-center my-5">
                Sounds like you might be exploring your options. We’d love to
                help!
              </h1>
              <p class="fs-p text-white text-center mb-5">
                Because you have an existing retirement plan, we need more
                information to make suggestions. Just send us your contact
                information and a Principal team member will contact you within
                two business days.
              </p>
  `,
    id: 'exploreOptions',
  },
  restrictedInsuranceAgent: {
    content: `<h1 class="fs-h1 fw-bold text-white text-center my-5">
                Sounds like you might be exploring your options. We’d love to
                help!
              </h1>
  `,
    id: 'RestrictedInsuranceAgent',
  },
  letsTalk: {
    content: `<h3 class="fs-form-q text-white text-center my-5">
                Okay. Let’s talk.
              </h3>
              <p class="fs-p text-white text-center mb-5">
                Since you don’t want employees to be able to contribute to their
                retirement accounts, Simply Retirement by Principal<sup><small>®</small></sup> may not fit your needs. We suggest a Simplified Employee Pension
                (SEP) IRA or possibly a defined benefit (pension) plan. If you’d
                like to explore your options, send us your contact information
                and we’ll get in touch within two business days to discuss
                specifics.
              </p>
    `,
    id: 'letsTalk',
  },
  planLargerGroup: {
    content: `<h3 class="fs-form-q text-white text-center my-5">
                Today, Simply Retirement by Principal<small><sup>®</sup></small>is set up for businesses with fewer than 100 employees.
              </h3>
              <p class="fs-p text-white text-center mb-5">
                You can still work with our service team to set up a retirement
                plan that fits your needs, such as our
                <a href="business-owners/retirement-plans" class="text-white"> Principal<small><sup>®</sup></small> 401(k) plan </a> or <a href="business-owners/retirement-plans" class="text-white">403(b) plan</a>. Just send us your contact information and we’ll get in touch
                within two business days to discuss the options.
              </p>
    `,
    id: 'planLargerGroup',
  },
  severalOptions: {
    content: `<h3 class="fs-form-q text-white text-center my-5">
                Sounds like several options might fit your needs.
              </h3>
              <p class="fs-p text-white text-center mb-5">
                Plans you could consider include a 403(b) plan and a SIMPLE IRA.
                Just send us your contact information and we’ll get in touch
                within two business days to discuss specifics.
              </p>
    `,
    id: 'severalOptions',
  },
};

export const forms = {
  exploreOptions: {
    id: 'ExistingRetirementPlanForm',
    action: 'https://info.simplyretirement.com/l/836343/2020-02-28/2zmw',
    method: 'post',
    responseSuccessMsg: 'Thanks. We’ll be in touch.',
    responseErrorMsg: 'There was an error submitting the form.',
    inputs: [],
    disclaimer: DISCLAIMER,
  },
  letsTalk: {
    id: 'NonContributingEmployeesRequest',
    action: 'https://info.simplyretirement.com/l/836343/2020-02-28/2zp1',
    method: 'post',
    responseSuccessMsg: 'Thanks. We’ll be in touch.',
    responseErrorMsg: 'There was an error submitting the form.',
    inputs: [],
    disclaimer: DISCLAIMER,
  },
  planLargerGroup: {
    id: 'LargeGroupRequestForm',
    action: 'https://info.simplyretirement.com/l/836343/2020-01-15/lxn',
    method: 'post',
    responseSuccessMsg: 'Thanks. We’ll be in touch.',
    responseErrorMsg: 'There was an error submitting the form.',
    inputs: [],
    disclaimer: DISCLAIMER,
  },
  severalOptions: {
    id: 'MinistryNonProfitRequestForm',
    action: 'https://info.simplyretirement.com/l/836343/2020-02-28/2zny',
    method: 'post',
    responseSuccessMsg: 'Thanks. We’ll be in touch.',
    responseErrorMsg: 'There was an error submitting the form.',
    inputs: [],
    disclaimer: DISCLAIMER,
  },
  restrictedInsuranceAgent: {
    id: 'RestrictedInsuranceAgentForm',
    action: 'http://info.simplyretirement.com/l/836343/2022-03-09/w9111',
    method: 'post',
    responseSuccessMsg: 'Thanks. We’ll be in touch.',
    responseErrorMsg: 'There was an error submitting the form.',
    inputs: [],
    disclaimer: DISCLAIMER,
  },
};
