import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, className, children }) =>
  /^https?:\/\//.test(to) ? (
    <a href={to} className={className} target="_blank">
      {children}
    </a>
  ) : (
    <RouterLink to={to} className={className}>
      {children}
    </RouterLink>
  );

Link.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default Link;
