import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SignUpModalRedux from '../../Redux/SignUpModalRedux';
import ContactStyles from './Contact.styles';

const Contact = ({ toggleModalVisibility }) => (
  <ContactStyles className="text-center text-lg-start">
    <ContactStyles.Aside className="mb-4 mx-auto mx-lg-0">
      <div className="aside_row">
        <h6 className="fs-h2 fw-bold text-white mb-0">Questions?</h6>
        <a className="fs-hp text-white" href="mailto:info@simplyretirement.com">
          <span className="sr-only">Contact Via</span>
          <strong className="d-lg-block d-none">
            Email &nbsp;
            <i className="fa-regular fa-envelope" aria-hidden />
          </strong>
          <i className="fa-regular fa-envelope d-lg-none fa-2x" aria-hidden />
        </a>
        <a className="fs-hp text-white" href="tel:+18448040604">
          <span className="sr-only">Contact Via Phone: </span>
          <strong className="d-lg-block d-none text-white">
            844-804-0604 &nbsp;
            <i className="fa-solid fa-phone" aria-hidden />
          </strong>
          <i
            className="fa-solid fa-phone d-lg-none fa-2x text-white"
            aria-hidden
          />
        </a>
      </div>
      <p className="text-md-left pt-2">
        Contact a Simply Retirement by Principal
        <small>
          <sup>®</sup>
        </small>{' '}
        team member at Ubiquity.
      </p>
    </ContactStyles.Aside>
    <ContactStyles.Button
      type="button"
      className="d-block srp-button srp-button__primary w-100"
      onClick={() => {
        toggleModalVisibility(true);
      }}
    >
      Sign up for updates
    </ContactStyles.Button>
  </ContactStyles>
);

Contact.propTypes = {
  toggleModalVisibility: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  toggleModalVisibility: (toggle) => {
    dispatch(SignUpModalRedux.toggleModalVisibility(toggle));
  },
});

export default connect(null, mapDispatchToProps)(Contact);
