import styled from 'styled-components';

export const PurpleMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  background-color: #002855;
  &:after {
    content: '';
    display: block;
    background-color: #002855;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
    transform: scale(1.2) translateY(50%);
    position: absolute;
    border-radius: 100%;
  }
`;
