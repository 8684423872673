import React from 'react';
import { PurpleSectionStyles, DivStyled } from './index.styles';

const PurpleSection = () => (
  <PurpleSectionStyles className="position-relative" id="secureAct">
    <div className="container">
      <div className="row">
        <div className="col-8 mx-auto">
          <h3 className="fs-h3 text-white fw-bold text-center mb-5 print-dark">
            Retirement benefits are important to job seekers.
          </h3>
          <div className="row mb-5">
            <DivStyled className="col-md-12 text-center">
              <p className="text-white fs-xxxl fw-bold p-1 mb-0">75%</p>
              <p className="fs-h2 text-white">
                of small and midsize business (SMB) employees are interested in
                their employer offering additional benefits within the{' '}
                <span
                  id="slider-bo-ltb-bp-disclaimer"
                  className="text-white text-underline p-0 no-border-button"
                >
                  next year.
                </span>{' '}
              </p>
            </DivStyled>
            {/* <DivStyled className="col-md-6 text-center">
              <p className="text-white fs-xxxl fw-bold p-1 mb-0">62%</p>
              <p className="fs-h2 text-white">
                said retirement benefits are important in{' '}
                <strong>staying with a job</strong>.
              </p>
            </DivStyled> */}
          </div>
          <p
            className="fs-sm text-white text-center mb-0"
            style={{ lineHeight: '165%' }}
          >
            {/* <a
              href="https://www.napa-net.org/news-info/daily-news/retirement-benefits-workplace-culture-crucial-job-seekers"
              className="text-white"
              target="_blank"
            >
              “Retirement Benefits, Workplace Culture Crucial to Job Seekers,”
            </a>{' '} */}
            {/* 2024 Principal SMB Sentiment survey of small and midsize employer
            non-customers (272) and employees (260) conducted between January 22
            and February 14, 2024. */}
          </p>
        </div>
      </div>
    </div>
  </PurpleSectionStyles>
);

export default PurpleSection;
