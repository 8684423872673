export const STATE_REQUIRED_BASIS_POINTS = [
  'AL',
  'CO',
  'CT',
  'DE',
  'DC',
  'GA',
  'KY',
  'ME',
  'MD',
  'MA',
  'MN',
  'MT',
  'ND',
  'NE',
  'NV',
  'NH',
  'NM',
  'NY',
  'NC',
  'OK',
  'PA',
  'RI',
  'SC',
  'SD',
  'UT',
  'VT',
  'WI',
];

export const DEFAULT_MAX_BASIS_POINTS = '75';
