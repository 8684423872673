export const numberOfEmployees = {
  id: 7,
  stepName: 'step_1',
  type: 'number_field',
  name: 'numberOfEmployees',
  hasInfo: true,
  infoLabel: '',
  user: {
    employer: {
      label:
        'How many full-time and part-time employees do you employ (including yourself)?',
    },
    advisor: {
      label:
        'How many employees (including the business owner) would be eligible for the plan?',
    },
    tpa: {
      label:
        'How many employees (including the business owner) would be eligible for the plan?',
    },
  },
  heading: 'Include all full-time and part-time employees.',
  content:
    'Union employees, independent contractors, and nonresident aliens aren’t eligible for Simply Retirement by Principal<small><sup>®</sup></small>.',
  placeholder: 'Number of employees',
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  valueFormatted: '',
  hasConditional: true,
  validateRule: 'LARGER_EMPLOYEE_RULE',
  hasError: false,
  sideCardTitle: 'Total number of employees',
  reviewScreenTitle: 'Total number of employees',
  description:
    'This is the number of full-time and part-time employees age 21 or older (including the business owner) in the business.',
};
