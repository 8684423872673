import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CostEstimatorCard = ({ cards, title }) => {
  const [infoTool, setInfoTool] = useState(false);
  const toggleInfoTool = () => {
    setInfoTool((prevState) => !prevState);
  };
  return (
    <div className="card my-3" style={{ borderRadius: '10px' }}>
      <p className="text-center fs-p fw-bold pt-3 px-4 mb-0">{title}</p>
      <div className="card-body">
        {cards.map((card) => (
          <div className={card.value !== '' ? 'mt-3' : 'd-none'} key={card.id}>
            {card.id !== 1 && <div className="cost-estimator-border mb-3" />}
            {card.header && (
              <p className="text-left text-purple fs-p fw-bold mb-3">
                {card.header}
              </p>
            )}
            <p className="fs-p mb-0">{card.title}:</p>
            <p className="fs-h4 fw-bold">
              {card.value} {card.period}
            </p>
            {card.hasInfo && (
              <>
                <button
                  type="button"
                  className="w-100 text-underline text-link text-end fs-sm no-border-button"
                  onClick={toggleInfoTool}
                >
                  What&rsquo;s included?
                </button>
                {infoTool && (
                  <div
                    className="card mt-3 p-1 cost-estimator-info"
                    style={{ backgroundColor: '#eeeeee' }}
                  >
                    <div className="cost-estimator-infotip"></div>
                    <div
                      className="card-body"
                      style={{ zIndex: 1, position: 'relative' }}
                      dangerouslySetInnerHTML={{ __html: card.info }}
                    ></div>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

CostEstimatorCard.propTypes = {
  cards: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default CostEstimatorCard;
