import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../Redux/FormDataRedux';
import VideoSection from './VideoSection';
import TableSection from './TableSection';
import SectionSingleCol from '../../common/SectionSingleCol';

import { HeroWrapper } from './index.styles';

const introSection = `
  <h2 class="fs-h2 text-center fw-normal">
    Haven’t heard this term before? Don’t let it intimidate you. Safe
    harbor is just a type of 401(k) plan that offers benefits for
    meeting a few requirements. It’s a popular solution for business
    owners who want to reduce their administrative burden.
  </h2>
`;

const sectionDefinitions = `
  <p class="fs-p">
    <strong>Safe harbor 401(k) plans</strong> have a simpler plan
    design that allows you to bypass some of the annual compliance
    testing required by the <button id="safeharbor-disclaimer-1" class="text-underline p-0 no-border-button">IRS</button><sup><small>*</small></sup>.
    In return, you must make a minimum safe harbor contribution to
    the plan. The most common form of contribution is a match,
    meaning the business owner is only responsible for making a
    contribution when the employee does so. Employees benefit by
    getting “free money” in the form of the matching contribution,
    which incentivizes them to save more. They’re also immediately
    vested in the plan.
  </p>
  <p class="fs-p">
  If you’re not sure about setting up a safe harbor plan on your own, a third party administrator (TPA) can assist with this type of plan design.
  </p>
  <p class="fs-p">
    <strong>Non-safe harbor 401(k)</strong> plans typically don’t
    require you to make a minimum matching contribution. But they’re
    subject to more compliance testing—which means more
    administrative paperwork and potential hassle if a correction is
    needed.
  </p>
  <p class="fs-p">
    <strong>So, what’s plan compliance testing?</strong> The IRS requires
    a series of annual tests to ensure that qualified retirement
    plans are “in compliance”—in other words, that they follow the
    applicable laws and plan provisions, and are fair to all
    participants. Safe harbor plans are automatically deemed to pass
    non-discrimination compliance tests, which are tests to ensure
    the plan doesn’t provide a more significant benefit to highly
    compensated employees (those who earn at least $155,000 per year
    or who own more than 5% of the company).
  </p>
  <p class="fs-p">
    The Simply Retirement by Principal<sup><small>®</small></sup>
    safe harbor 401(k) plan is designed to help you save time with
    this process. The Ubiquity Retirement + Savings<sup><small>®</small></sup> recordkeeping compliance system is fully automated—we just
    need you to answer some questions each year. From there, we
    automatically crunch the numbers and create the necessary forms
    for you to submit to the IRS, all on our site.
  </p>

`;

const safeHarborDisclaimer1 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*IRS:</span>Eligible employees must be notified prior to plan start date.<p class="fs-disclaimer"> Information is intended to be educational in nature and is not intended to be taken as a recommendation.</p>`;
const safeHarborDisclaimer2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*$19,500:</span> As indexed by the IRS for the 2024 calendar year. For purposes of maximum contributions, compensation is limited to $345,000, as indexed, per participant per year.</p><p class="fs-disclaimer">Information is intended to be educational in nature and is not intended to be taken as a recommendation.</p>`;
const safeHarborDisclaimer3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Safe harbor plan:</span> Deferrals and required business owner contribution and optional additional profit sharing contributions cannot exceed the lesser of 100% of pay or $69,000 per participant. Total business owner contribution to plan cannot exceed 25% of total eligible compensation (2024 tax year, as indexed). Enhanced matching options also available.</p><p class="fs-disclaimer">Information is intended to be educational in nature and is not intended to be taken as a recommendation.</p>`;
const safeHarborDisclaimer4 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*up to 5%:</span>Employees are 100% vested in required business owner contribution.<p class="fs-disclaimer"> Information is intended to be educational in nature and is not intended to be taken as a recommendation.</p>`;
const safeHarborDisclaimer5 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*4% of pay:</span>Employees are 100% vested in required business owner contribution.<p class="fs-disclaimer"> Information is intended to be educational in nature and is not intended to be taken as a recommendation.</p>`;
const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${safeHarborDisclaimer1}
     ${safeHarborDisclaimer2}
     ${safeHarborDisclaimer3}
     ${safeHarborDisclaimer4}
     ${safeHarborDisclaimer5}
    </div>
  </div>
`;

const SafeHarbor = ({ applicationPanelInfo, putApplicationPanelInfo }) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'safeharbor-disclaimer-1',
      'safeharbor-disclaimer-4',
      'safeharbor-disclaimer-4-2',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });
  const handleClick = (e) => {
    switch (e.target.id) {
      case 'safeharbor-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: safeHarborDisclaimer1,
        });
        break;

      case 'safeharbor-disclaimer-4':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: safeHarborDisclaimer4,
        });
        break;

      case 'safeharbor-disclaimer-4-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: safeHarborDisclaimer5,
        });
        break;

      default:
        break;
    }
  };
  return (
    <div className="safeharbor-page pb-5 mb-5">
      <HeroWrapper>
        <div className="container-fluid container-fluid--sitewidth">
          <div className="row justify-content-center">
            <h1 className="fs-h1 text-center fw-bold col-xl-6 col-lg-8 col-md-10">
              What’s a safe harbor 401(k) plan and how is it different?
            </h1>
          </div>
        </div>
      </HeroWrapper>
      <SectionSingleCol
        copy={introSection}
        columns="col-md-9 mx-auto"
        className="pb-0"
      />
      <SectionSingleCol copy={sectionDefinitions} />
      <VideoSection
        putApplicationPanelInfo={putApplicationPanelInfo}
        safeHarborDisclaimer2={safeHarborDisclaimer2}
      />

      <TableSection
        putApplicationPanelInfo={putApplicationPanelInfo}
        safeHarborDisclaimer3={safeHarborDisclaimer3}
      />
      <p className="fs-disclaimer text-center pb-5 mb-5">
        Intended for financial professional, TPA, and plan sponsor use.
      </p>
      <SectionSingleCol className="d-print-block d-none" copy={footNoteList} />
      <span className="d-none">1178600-052020</span>
    </div>
  );
};

SafeHarbor.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SafeHarbor);
