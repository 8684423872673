import React from 'react';
import TableFluidLarge from './TableFluidLarge';

import { IconBlueGraphO } from '../../../../ImagePaths';

const TableSection = () => (
  <section
    id="401k-table"
    className="table-section bg-pattern section section_py-5 pt-0"
    style={{
      background:
        'linear-gradient(0deg, rgba(230,231,232,1) 0%, rgba(230,231,232,0) 100%)',
    }}
  >
    <div className="container-lg bg-white">
      <div className="row justify-content-center py-5">
        <img src={IconBlueGraphO} alt="" width={90} height={90} />
      </div>
      <div className="row justify-content-center">
        <p className="fs-h3 fw-bold text-center col-xl-6">
          How does a 401(k) plan compare to other retirement plans?
        </p>
      </div>
      <div className="row justify-content-center pb-5">
        <p className="fs-p text-center col-lg-8">
          If you’re looking for a workplace retirement plan, a 401(k) plan isn’t
          your only option. See how it compares to other plan types below. This
          is just an overview, so talk to your financial professional if you’d
          like more details about each plan option and how they might fit your
          needs.
        </p>
      </div>
    </div>
    <div
      className="container-lg bg-white"
      style={{
        borderBottomLeftRadius: '50px',
        borderBottomRightRadius: '50px',
      }}
    >
      <div className="row">
        <div className="col-lg-10 mx-auto px-md-3 px-0">
          <TableFluidLarge />
        </div>
      </div>
    </div>
  </section>
);

TableSection.propTypes = {};

export default TableSection;
