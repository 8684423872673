import React from 'react';

const Table = () => {
  const tableHeaders = [
    {
      id: 1,
      desc: `<p class="fs-p mb-0">Employee contribution example</p>`,
    },
    {
      id: 2,
      desc: `<p class="fs-p mb-0">Business owner matching contribution example</p>`,
    },
  ];
  const tableRows = [
    {
      id: 1,
      header: `<p class="fs-p fw-bold mb-0">Stretch the match</p><p class="fs-p fw-normal"> Match 100% of the employee’s contribution up to the first 3% of pay, then match 50% of the next 2% of pay, <button id="safeharbor-disclaimer-4" class="text-underline p-0 no-border-button">up to 5%</button><sup><small>*</small></sup>.</p>`,
      rowDesc: [
        {
          id: 1,
          desc: `<p class="fs-p text-center"><strong>5%</strong> of pay<strong><br />$1,750</strong></p>`,
        },
        {
          id: 2,
          desc: `<p class="fs-p text-center"><strong>3% + .5% + .5% (equal to 4%)</strong> of employee pay<br /> <strong>$1,400</strong></p>`,
        },
      ],
    },
    {
      id: 2,
      header: `<p class="fs-p fw-bold mb-0">Straight match</p><p class="fs-p fw-normal">Match 100% of the employee’s contribution up to <button id="safeharbor-disclaimer-4-2" class="text-underline p-0 no-border-button">4% of pay</button><sup><small>*</small></sup>.</p>`,
      rowDesc: [
        {
          id: 1,
          desc: `<p class="fs-p text-center"><strong>4%</strong> of pay<br /><strong>$1,400</strong></p>`,
        },
        {
          id: 2,
          desc: `<p class="fs-p text-center"><strong>4%</strong> of employee pay<br /> <strong>$1,400</strong></p>`,
        },
      ],
    },
  ];
  return (
    <div className="table-fluid">
      <div className="table-fluid__thead">
        <div className="table-fluid__label"></div>
        {tableHeaders.map((tableHeader) => (
          <div className="table-fluid__col" key={tableHeader.id}>
            <strong
              className="heading fs-display5"
              key={tableHeader.id}
              dangerouslySetInnerHTML={{ __html: tableHeader.desc }}
            />
          </div>
        ))}
      </div>

      {tableRows.map((tableRow) => (
        <div className="table-fluid__row active" key={tableRow.id}>
          <div className="table-fluid__label">
            <span
              className="label fs-tablelabel"
              dangerouslySetInnerHTML={{ __html: tableRow.header }}
            ></span>
          </div>
          {tableRow.rowDesc.map((rowDesc, indx) => (
            <div className="table-fluid__col" key={rowDesc.id}>
              <span
                className="table-col scope"
                dangerouslySetInnerHTML={{ __html: tableHeaders[indx].desc }}
              />
              <span className="table-col value">
                <span
                  className="fs-sm"
                  dangerouslySetInnerHTML={{ __html: rowDesc.desc }}
                />
              </span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Table;
