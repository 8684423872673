import {
  defaultPlanVestingId,
  sourcesGrandfatherDate,
} from '../ParadigmUtilities';

export const setBundledPlanAddSources = (
  employerContributionMatch,
  safeHarbor,
  stretchSafeHarbor,
  employerMatch,
  userRole,
  defaultPlanVesting,
  questions,
) => {
  let sourceIndex = 0;
  const addSources = [];

  addSources[sourceIndex] = {
    id: 1,
    name: 'Employee Pretax Contribution',
    sourceType: 'DEFERRAL',
    contributorType: 'EMPLOYEE',
    vestingId: null,
    requiredAge: null,
    entry: null,
    serviceEligibility: null,
    grandfatherDate: sourcesGrandfatherDate(questions),
  };
  sourceIndex += 1;
  addSources[sourceIndex] = {
    id: 2,
    name: 'Employee Roth Contribution',
    sourceType: 'DEFERRAL',
    contributorType: 'EMPLOYEE',
    vestingId: null,
    requiredAge: null,
    entry: null,
    serviceEligibility: null,
    grandfatherDate: sourcesGrandfatherDate(questions),
  };
  sourceIndex += 1;
  addSources[sourceIndex] = {
    id: 10,
    vestingId:
      employerContributionMatch.value === 'no' ||
      safeHarbor.value === 'safeHarbor'
        ? 1
        : defaultPlanVestingId(userRole, defaultPlanVesting),
    planSourceFrequency: null,
    grandfatherDate: sourcesGrandfatherDate(questions),
  };

  if (safeHarbor.value === 'safeHarbor') {
    sourceIndex += 1;
    addSources[sourceIndex] = {
      id: stretchSafeHarbor.value,
      vestingId: 1,
      planSourceFrequency: 'PAYROLL',
      grandfatherDate: sourcesGrandfatherDate(questions),
    };
  } else if (safeHarbor.value === 'nonSafeHarbor') {
    sourceIndex += 1;
    addSources[sourceIndex] = {
      id: 1009 + employerMatch.value,
      vestingId: defaultPlanVestingId(userRole, defaultPlanVesting),
      planSourceFrequency: null,
      grandfatherDate: sourcesGrandfatherDate(questions),
    };
  }
  return addSources;
};
