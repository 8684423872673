export const vestingScheduleProfit = {
  id: 'q6aI1c1d1a',
  type: 'dropdown',
  name: 'vestingScheduleProfit',
  stepName: 'a_step_6_a_i_1_e',
  paradigmAlias: 'vestingScheduleProfit',
  user: {},
  label:
    'Which vesting schedule will apply to discretionary profit-sharing contributions?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: '4-year graded = 25%, 50%, 75%, 100%',
      value: 'fourYearGraded',
      valueFormatted: '4-year graded',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: '5-year graded = 20%, 40%, 60%, 80%, 100%',
      value: 'fiveYearGraded',
      valueFormatted: '5-year graded',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: '6-year graded = 0%, 20%, 40%, 60%, 80%, 100%',
      value: 'sixYearGraded',
      valueFormatted: '6-year graded',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 4,
      user: {},
      label: '3-year cliff = 0%, 0%, 100%',
      value: 'threeYearCliff',
      valueFormatted: '3-year cliff',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 5,
      user: {},
      label: 'Immediate = 100%',
      value: 'immediate',
      valueFormatted: 'Immediate',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Vesting schedule profit-sharing match',
  reviewScreenTitle: 'Vesting schedule for profit-sharing match',
  optionDescription: '',
  description:
    'This is the selected vesting schedule for profit-sharing matching contributions.',
};
