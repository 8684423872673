import { checkValidityOnQuestionArray } from './checkValidityOnQuestionArray';
import { restrictedState } from './restrictedState';
import { checkAdvisorCredentialVerification } from './checkAdvisorCredentialVerification';
import { restrictedStateInsuranceAgent } from './restrictedStateInsuranceAgent';

export const employerCanPurchasePlan = (
  code,
  hasFp,
  hasTpa,
  advisorInfo,
  tpaInfo,
  employerInfo,
  formData = {},
) => {
  const { advisorCredentialVerification } =
    hasFp || hasFp.value ? formData.planDesign.advisors[0] : {};
  if (hasTpa) {
    if (!checkValidityOnQuestionArray(tpaInfo)) {
      return 'employer-share';
    }
  }
  if (restrictedState(code)) {
    if (
      checkAdvisorCredentialVerification(advisorCredentialVerification) &&
      checkValidityOnQuestionArray(advisorInfo) &&
      checkValidityOnQuestionArray(employerInfo)
    ) {
      return 'employer';
    }
    return 'employer-share';
  }
  if (hasFp) {
    const { brokerType } =
      formData.planDesign.advisors[0].advisorCredentialVerification;
    if (
      restrictedStateInsuranceAgent(code) &&
      brokerType === 'insuranceAgent'
    ) {
      return 'advisor-error';
    }
    if (
      checkAdvisorCredentialVerification(advisorCredentialVerification) &&
      checkValidityOnQuestionArray(advisorInfo) &&
      checkValidityOnQuestionArray(employerInfo)
    ) {
      return 'employer';
    }
    return 'employer-share';
  }
  if (checkValidityOnQuestionArray(employerInfo)) {
    return 'employer';
  }
  return 'employer-error';
};
