import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SimplyIconSprite from '../../../SimplyIconSprite';

export const Radio = (props) => {
  const {
    questionId,
    name,
    checked,
    value,
    valueFormatted,
    dirty,
    hasError,
    required,
    disabled,
    label,
    info,
    icon,
    customStyle,
    handleInputChange,
  } = props;
  return (
    <label
      htmlFor={questionId}
      className={classNames(`radio ${customStyle}`, {
        'has-error': hasError,
        active: checked,
        dirty: dirty && !checked,
      })}
    >
      <input
        tabIndex="0"
        id={questionId}
        type="radio"
        className={classNames('radio__input', { 'is-invalid': hasError })}
        name={name}
        onChange={(event) => {
          handleInputChange(event.target.value, valueFormatted);
        }}
        checked={checked}
        value={value}
        required={required}
        disabled={disabled}
      />
      {icon && (
        <SimplyIconSprite
          className={classNames('radio__icon', {
            dirty: dirty && !checked,
          })}
          name={icon}
          style={{}}
          fill="#002855"
          fillAlt={null}
          size="65"
          alt=""
        />
      )}
      <div className="radio__label fs-form-label fs-form-label--large">
        {label}
        {info && (
          <div
            className="radio__info fs-sm fw-normal"
            dangerouslySetInnerHTML={{ __html: info }}
          />
        )}
      </div>
      <div className="radio__background"></div>
    </label>
  );
};

Radio.propTypes = {
  questionId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  valueFormatted: PropTypes.string,
  hasError: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  customStyle: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  info: PropTypes.string,
};

export default Radio;
