import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './Styles';

const Advisor = ({ data }) => (
  <View>
    <Text style={[styles.copyDefault, { fontWeight: 'bold' }]}>
      Financial professional:
    </Text>
    <Text style={styles.copyDefault}>
      {data.advisorFirstName.value &&
        `Full name: ${data.advisorFirstName.value} ${data.advisorLastName.value}`}
    </Text>
    <Text style={styles.copyDefault}>
      {`Email: ${data.advisorEmail.value}`}
    </Text>
    <Text style={styles.copyDefault}>
      {`Phone: ${data.advisorPhone.value}`}
    </Text>
    <Text
      style={styles.copyDefault}
    >{`Firm name: ${data.firmName.value}`}</Text>
  </View>
);

Advisor.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Advisor;
