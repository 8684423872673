import React from 'react';
import Link from '../Link';

const SiteLinkList = ({ siteMap }) =>
  siteMap.map((link) => {
    switch (!!link.children) {
      case true:
        return (
          <div key={link.id} className="d-inline-block w-100 mb-4  pr-md-5">
            <h5
              id={`footer${link.title.replace(/[^A-Z0-9]/gi, '')}`}
              className="fs-h5 fw-bold text-white"
            >
              <span dangerouslySetInnerHTML={{ __html: link.title }} />
            </h5>
            <ul className="list-unstyled text-white mb-3">
              {link.children.map((child) => (
                <li key={child.id} className="mb-3 mb-lg-2">
                  <Link to={child.path} className="fs-h6 lh-125 text-white">
                    <span dangerouslySetInnerHTML={{ __html: child.title }} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        );
      case false:
        return (
          <div key={link.id} className="d-inline-block w-100 mb-4 pr-md-5">
            <h5
              id={`footer${link.title.replace(/[^A-Z0-9]/gi, '')}`}
              className="fs-h5 fw-bold mb-3"
            >
              <Link to={link.path} className="text-white">
                <span dangerouslySetInnerHTML={{ __html: link.title }} />
              </Link>
            </h5>
          </div>
        );
      default:
        return false;
    }
  });

export default SiteLinkList;
