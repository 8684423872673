import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import {
  selectAdvisorQuestions,
  selectRole,
  selectTpaQuestions,
} from '../Redux/FormDataRedux';
import ToastRedux from '../Redux/ToastRedux';
import Navigation from '../common/Navigation';
import PlanDesignStickyAlert from '../common/PlanDesignStickyAlert';
import Footer from '../common/Footer';
import ScrollToTop from './ScrollToTop';
import Toasts from '../common/Toasts';
import Modal from '../common/Modal';
import ApplicationInfoPanel from './ApplicationInfoPanel';
import UpdateSignUpModal from '../common/UpdateSignUpModal';
import {
  pushGoogleDataLayer,
  checkValidityOnQuestionArray,
} from '../common/FormWithSteps/Utilities';
import { rootRoute } from '../Services/utilities';
import { selectHasTpaHasFp } from '../Redux/FormDataRedux/Selectors';

const usePageTracking = (title) => {
  const location = useLocation();
  const sendDataToGTM = useGTMDispatch();

  React.useEffect(() => {
    const stripPlanId = () => {
      const urlArr = location.pathname.split('/');
      if (urlArr[1] !== 'plans') return location.pathname;
      if (urlArr.length >= 3) urlArr.pop();
      return urlArr.join('/');
    };
    sendDataToGTM({
      event: 'routeChange',
      pagePath: `${stripPlanId()}${location.hash}`,
      pageTitle: `${title} | Simply Retirement By Principal®`,
    });
  }, [location]);
};

const ApplicationLayout = ({
  location,
  children,
  title,
  description,
  paradigmHost,
  role,
  tpaInfo,
  advisorInfo,
  addToast,
  hasFpHasTpa,
}) => {
  usePageTracking(title);
  const onHelmetChange = () => {
    pushGoogleDataLayer({
      event:
        rootRoute(location.pathname) === '/psivet'
          ? 'psivetCustomPageChange'
          : 'CustomPageChange',
    });
  };

  const matchRoute = (route) => route === location.pathname;

  const isReviewPlan =
    matchRoute(`${rootRoute(location.pathname)}/review-plan`) ||
    matchRoute('/review-plan');

  React.useEffect(() => {
    if (
      hasFpHasTpa.hasFp.value &&
      !checkValidityOnQuestionArray(advisorInfo) &&
      isReviewPlan
    ) {
      const message =
        role === 'advisor'
          ? 'You have missing information. Please complete missing information before continuing'
          : 'The financial professional needs to add more information. Share the proposal to continue.';
      addToast(message, 'info');
    } else if (
      hasFpHasTpa.hasTpa.value &&
      !checkValidityOnQuestionArray(tpaInfo) &&
      isReviewPlan
    ) {
      const message =
        role === 'tpa'
          ? 'You have missing information. Please complete missing information before continuing'
          : 'Your third party administrator needs to add more information. Share the proposal to continue.';
      addToast(message, 'info');
    }
  }, []);

  return (
    <ScrollToTop>
      <Helmet onChangeClientState={onHelmetChange}>
        <title>{title} | Simply Retirement By Principal®</title>
        <meta name="description" content={description} />
      </Helmet>
      <Navigation
        paradigmHost={paradigmHost}
        rootRoute={rootRoute(location.pathname)}
        isFinancialHp={
          matchPath(location.pathname, {
            path: '/financial-professionals',
          }) !== null
        }
        isTpa={
          matchPath(location.pathname, {
            path: '/third-party-administrator',
          }) !== null
        }
        isReviewPlan={isReviewPlan}
        matchRoute={matchRoute}
      />
      {children}
      <ApplicationInfoPanel />
      <PlanDesignStickyAlert
        rootRoute={rootRoute(location.pathname)}
        isReviewPlan={isReviewPlan}
      />
      <UpdateSignUpModal />
      <Modal />
      <Toasts />
      <Footer
        rootRoute={rootRoute(location.pathname)}
        paradigmHost={paradigmHost}
        isFinancialHp={
          matchPath(location.pathname, {
            path: '/financial-professionals',
          }) !== null
        }
      />
    </ScrollToTop>
  );
};

ApplicationLayout.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  description: PropTypes.string,
  paradigmHost: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  addToast: PropTypes.func.isRequired,
  hasFpHasTpa: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  role: selectRole(store),
  tpaInfo: selectTpaQuestions(store),
  advisorInfo: selectAdvisorQuestions(store),
  hasFpHasTpa: selectHasTpaHasFp(store),
});

const mapDispatchToProps = (dispatch) => ({
  addToast: (text, toastType) => {
    dispatch(ToastRedux.addToast(text, toastType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLayout);
