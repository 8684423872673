import USER_SELECT_INITIAL_STATE from './UserSelectInitialState';
import PLAN_DESIGN_INITIAL_STATE from './PlanDesignInitialState';
import APPLICATION_INFO_PANEL_INITIAL_STATE from './applicationInfoPanelInitialState';
import { ADVISOR_INITIAL_STATE } from './AdvisorInitialState';
import { TPA_INITIAL_STATE } from './TPAInitialState';

export { USER_SELECT_INITIAL_STATE } from './UserSelectInitialState';
export { PLAN_DESIGN_INITIAL_STATE } from './PlanDesignInitialState';
export { APPLICATION_INFO_PANEL_INITIAL_STATE } from './applicationInfoPanelInitialState';
export { ADVISOR_INITIAL_STATE } from './AdvisorInitialState';
export { TPA_INITIAL_STATE } from './TPAInitialState';
export { TPA_INITIAL_PLAN } from './TPAInitialState';

export const INITIAL_STATE = {
  experience: '',
  paradigmHost: '',
  defaultRole: 'employer',
  role: '',
  planURL: '',
  hashId: '',
  purchasePlanURL: '',
  createdDate: '',
  planOwner: '',
  planOriginator: '',
  updatedDate: '',
  planId: '',
  createEmailSent: false,
  applicationPanelInfo: { ...APPLICATION_INFO_PANEL_INITIAL_STATE },
  planDesign: {
    formId: 'planDesign',
    formInReview: false,
    startAtStep: 'begin',
    activeStep: 'begin',
    inProgress: false,
    advisors: [ADVISOR_INITIAL_STATE],
    tpas: [TPA_INITIAL_STATE],
    questions: {
      ...PLAN_DESIGN_INITIAL_STATE,
      ...USER_SELECT_INITIAL_STATE,
    },
  },
};

export default INITIAL_STATE;
