import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './Styles';
import { investmentCost } from '../../Services/utilities';

export const CreateTable = ({
  tableData: { header, rows, registration, noBoarderLeft = false },
}) => (
  <View style={styles.tableStyle}>
    <View
      style={[
        styles.tableHeaderStyle,
        {
          borderLeft: 'solid',
          borderLeftColor: 'white',
          borderLeftWidth: noBoarderLeft ? 0 : 4,
        },
      ]}
    >
      <View style={styles.sup}>
        <Text style={styles.tableHeaderCellStyle}>
          {header}
          {registration && (
            <Text style={styles.tableHeaderCellStyleSup}>®</Text>
          )}
        </Text>
      </View>
    </View>
    {rows.map((row, index) => (
      <View key={row.id} style={styles.tableRowStyle}>
        <View
          style={[
            styles.tableColStyle,
            { width: '55%', borderWidth: index === rows.length - 1 ? 0 : 1 },
          ]}
        >
          <Text style={[styles.tableCellStyle, { textAlign: 'left' }]}>
            {row.label}
          </Text>
          {row.description && (
            <Text
              style={[
                styles.tableCellStyle,
                styles.utilities.font7,
                { textAlign: 'left' },
              ]}
            >
              {row.description}
            </Text>
          )}
        </View>
        <View
          style={[
            styles.tableColStyle,
            { width: '45%', borderWidth: index === rows.length - 1 ? 0 : 1 },
          ]}
        >
          <View>
            <Text
              style={[
                styles.tableCellStyle,
                styles.utilities.colorBrandPrimary,
                styles.utilities.fontWeight400,
                styles.utilities.lineHeight15,
              ]}
            >
              {row.value}
            </Text>
          </View>
          <View style={styles.utilities.block}>
            <Text style={[styles.tableCellStyle, styles.utilities.font7]}>
              {row.caption}
            </Text>
          </View>
        </View>
      </View>
    ))}
  </View>
);

CreateTable.propTypes = {
  tableData: PropTypes.object.isRequired,
};

const TableSection = ({ tableData }) => {
  const rate = () => (tableData.hasTpa.value ? 145 : 185);
  const numberFormat = (number) =>
    new Intl.NumberFormat('en-IN', {
      maximumSignificantDigits: 3,
    }).format(number);

  const simply = {
    id: 0,
    noBoarderLeft: true,
    registration: true,
    header: 'Simply Retirement by Principal',
    rows: [
      {
        id: 0,
        label: 'Simply Retirement setup cost',
        value: '$500',
        caption: 'One-time setup cost',
      },
      {
        id: 1,
        label: 'Simply Retirement annual cost³',
        value: `$${numberFormat(rate() * 12)}`,
        caption: `($${numberFormat(rate() * 3)} billed quarterly)`,
      },
      {
        id: 2,
        label: 'Simply Retirement annual per participant cost*',
        value: '$72',
      },
      {
        id: 3,
        label: 'Investment costs',
        value: investmentCost,
      },
      { id: 4 },
    ],
  };

  const simplyWithTpa = {
    id: 0,
    noBoarderLeft: true,
    registration: true,
    header: 'Simply Retirement by Principal',
    rows: [
      {
        id: 0,
        label: 'Simply Retirement annual cost³',
        value: `$${numberFormat(rate() * 12)}`,
        caption: `($${numberFormat(rate() * 3)} billed quarterly)`,
      },
      {
        id: 1,
        label: 'Simply Retirement annual per participant cost*',
        value: '$72',
      },
      {
        id: 2,
        label: 'Investment costs',
        description: '(custodial fees are up to 0.055%)',
        value: investmentCost,
      },
      { id: 3 },
    ],
  };

  const tpaCompensationAmount = tableData.fixedAmount.valueFormatted
    ? tableData.fixedAmount.valueFormatted
    : tableData.bpsAmount.valueFormatted;
  const tpaCompensation = {
    id: 0,
    label: 'TPA annual compensation',
    description: '(paid from plan assets)',
    value: tableData.tpaCompensationType.value ? tpaCompensationAmount : '--',
    caption: tableData.tpaCompensationType.value
      ? tableData.tpaCompensationType.valueFormatted
      : '',
  };
  const tpaSetupFee = {
    id: 1,
    label: 'TPA setup cost',
    description: '(billed to plan sponsor)',
    value: tableData.tpaSetupFee.valueFormatted || '--',
    caption: 'One-time setup cost',
  };
  const annualTpaFee = {
    id: 2,
    label: 'TPA annual cost',
    description: '(billed to plan sponsor)',
    value: tableData.annualTpaFee.valueFormatted || '--',
  };
  const annualTpaPerHeadFee = {
    id: 3,
    label: 'TPA annual per participant cost',
    description: '(billed to plan sponsor)',
    value: tableData.annualTpaPerHeadFee.valueFormatted || '--',
  };
  const tpa = {
    id: 4,
    header: 'Third Party Administrator',
    rows: [tpaCompensation, tpaSetupFee, annualTpaFee, annualTpaPerHeadFee],
  };

  const advisorCompValue = tableData.advisorFee.valueFormatted
    ? tableData.advisorFee.valueFormatted
    : `${tableData.advisorPoints.valueFormatted} bps`;
  const advisor = {
    id: 2,
    header: 'Financial Professional',
    rows: [
      {
        id: 0,
        label: 'Financial Professional annual compensation',
        value: tableData.advisorType.value ? advisorCompValue : '--',
        caption:
          tableData.advisorType.value === 'option_1'
            ? 'Flat Fee'
            : 'Basis Points',
      },
      { id: 1 },
      { id: 2 },
      { id: 3 },
    ],
  };
  return (
    <View style={styles.sectionTable}>
      <CreateTable
        tableData={tableData.hasTpa.value ? simplyWithTpa : simply}
      />
      {tableData.hasTpa.value && <CreateTable tableData={tpa} />}
      {tableData.hasFp.value && <CreateTable tableData={advisor} />}
    </View>
  );
};

TableSection.propTypes = {
  tableData: PropTypes.object.isRequired,
};

export default TableSection;
