import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectAdvisorQuestions,
  selectAdvisors,
  selectPrimaryAdvisor,
} from '../../../Redux/FormDataRedux';
import {
  Question,
  NextStepBtn,
  checkValidityOnQuestionArray,
  restrictedState,
} from '../../../common/FormWithSteps';
import Dropdown from '../../../common/FormWithSteps/Question/Dropdown';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../common/FormWithSteps/Utilities';
import { selectEmployerQuestions } from '../../../Redux/FormDataRedux/Selectors';

const EmployerQuestions = ({
  role,
  active,
  destination,
  questions,
  previousStep,
  updateActiveStep,
  updateQuestion,
  employerInfo,
  advisorInfo,
  putRole,
  formData,
  setPlanOriginator,
  putApplicationPanelInfo,
  putBrokerType,
  putExperience,
}) => {
  const {
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    companyName,
    sponsorTitle,
    companyPhone,
    employerStateCode,
    insuranceGroupType,
    sponsorEmployerIdentificationNumber,
    hasFp,
    hasTpa,
  } = questions;

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChangeState = (id, updateValue, valueFormatted) => {
    handleQuestionChange(id, updateValue, valueFormatted);
    updateQuestion({
      name: insuranceGroupType.name,
      value: insuranceGroupType.value,
      valueFormatted: insuranceGroupType.valueFormatted,
      hasError: updateValue === 'TX' ? !insuranceGroupType.value : false,
      dirty: true,
    });
    putBrokerType(
      {
        brokerType:
          updateValue === 'TX' || updateValue === 'WA'
            ? 'iarRia'
            : 'brokerDealer',
      },
      0,
    );
  };

  const showStateErrorMessage =
    restrictedState(employerStateCode.value) &&
    !checkValidityOnQuestionArray(advisorInfo);

  const stateErrorMessage = () => {
    switch (true) {
      case employerStateCode.value === 'GA':
        return 'Your state requires a financial professional to set up a 401(k) plan. Please provide their contact information in the next step.';

      case employerStateCode.value === 'TX' &&
        insuranceGroupType.valueFormatted === '':
        return 'Your state requires a financial professional to set up a 401(k) plan. Please provide their contact information in the next step. An insurance group type must be selected.';

      case employerStateCode.value === 'FL':
        return 'A financial professional with a valid Florida license is required to set up a 401(k) plan. Please provide their contact information in the next step.';

      default:
        return '';
    }
  };

  const submitContactInfo = async () => {
    if (formData.planOriginator === '') {
      const planOriginator = {
        name: `${sponsorFirstName.value} ${sponsorLastName.value}`,
        email: sponsorEmail.value,
        role: 'employer',
      };
      setPlanOriginator(planOriginator);
    }
  };

  useEffect(() => {
    putRole('onedigital');
    putExperience('onedigital');
    pushGoogleDataLayer({
      event: 'OneDigitalPlanDesignView',
      PlanDesignView: 'info_onedigital',
    });
    handleQuestionChange(hasFp.name, true, hasFp.options[0].label);
    handleQuestionChange(hasTpa.name, false, hasTpa.options[1].label);
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <div className="row mb-4">
        <div className="col-lg-8 mx-auto">
          <h3 className="fs-form-q fw-bold mb-md-5 text-center">
            Tell us about yourself.
          </h3>
          <p className="text-center">
            We’ll share this information with OneDigital. An advisor will reach
            out to assist you.{' '}
          </p>
        </div>
      </div>
      <div className="row h-100">
        <div className="col-md-10 d-flex flex-column mx-auto">
          <fieldset>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  type={sponsorFirstName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorFirstName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorFirstName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
              <div className="col-md">
                <Question
                  role={role}
                  type={sponsorLastName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorLastName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorLastName.hasError && (
                    <small>Question is required</small>
                  )}
                </Question>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  role={role}
                  type={sponsorEmail.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorEmail}
                  handleQuestionChange={handleQuestionChange}
                >
                  {sponsorEmail.hasError && <small>Question is required</small>}
                </Question>
              </div>
              <div className="col-md">
                <Question
                  role={role}
                  uniqueId="employerCompany"
                  type={companyName.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={companyName}
                  handleQuestionChange={handleQuestionChange}
                >
                  {companyName.hasError && <small>Question is required</small>}
                </Question>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Question
                  type={sponsorTitle.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorTitle}
                  handleQuestionChange={handleQuestionChange}
                />
              </div>
              <div className="col-md">
                <div
                  className={classNames('mb-3 form-group', {
                    'has-error': companyPhone.hasError,
                  })}
                >
                  <label
                    className="fs-form-label fw-bold"
                    htmlFor={companyPhone.name}
                  >
                    {companyPhone.label}
                  </label>
                  <PatternFormat
                    value={companyPhone.value}
                    format="###-###-####"
                    mask="_"
                    className={classNames('srp-form-input form-control', {
                      'is-invalid': companyPhone.hasError,
                      'is-dirty': companyPhone.dirty,
                    })}
                    id={companyPhone.name}
                    name={companyPhone.name}
                    required
                    onValueChange={(values) => {
                      handleQuestionChange(
                        companyPhone.name,
                        values.value,
                        values.formattedValue,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <label
                    className="fs-form-label fw-bold"
                    htmlFor={employerStateCode.name}
                  >
                    Your company’s state of business
                  </label>
                  <select
                    id={employerStateCode.name}
                    name="state"
                    autoComplete="address-level1"
                    value={employerStateCode.value}
                    className="custom-select srp-form-input form-control form-select"
                    onChange={(selected) => {
                      const index = selected.target.selectedIndex;
                      const label = selected.target[index].text;
                      handleQuestionChangeState(
                        employerStateCode.name,
                        selected.target.value,
                        label,
                      );
                    }}
                  >
                    <option value="select" disabled hidden>
                      Select
                    </option>

                    {employerStateCode.options.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {showStateErrorMessage && (
                    <p className="fs-disclaimer lh-125 mt-2 text-danger">
                      {stateErrorMessage()}
                    </p>
                  )}
                </div>
              </div>
              {employerStateCode.value === 'TX' && (
                <div className="col-md">
                  <Dropdown
                    name={insuranceGroupType.name}
                    label={insuranceGroupType.label}
                    labelClass="fs-form-label fw-bold"
                    inputClass="srp-form-dropdown form-control"
                    options={insuranceGroupType.options}
                    value={insuranceGroupType.value}
                    required
                    hasError={insuranceGroupType.hasError}
                    handleQuestionChange={(id, updateValue) =>
                      handleQuestionChange(id, updateValue, updateValue.value)
                    }
                  />
                </div>
              )}
              <div className="col-md-6">
                <Question
                  role={role}
                  type={sponsorEmployerIdentificationNumber.type}
                  labelClass="fs-form-label fw-bold"
                  inputClass="srp-form-input form-control"
                  question={sponsorEmployerIdentificationNumber}
                  handleQuestionChange={handleQuestionChange}
                  allowLeadingZeros
                >
                  {sponsorEmployerIdentificationNumber.hasError && (
                    <small>Question is required</small>
                  )}
                  <button
                    type="button"
                    className="srp-button__link"
                    onClick={() =>
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: 'More info',
                        additionalInformation: `The Employer Identification Number (EIN) will be used as your business’ unique identifier.`,
                      })
                    }
                  >
                    More info
                  </button>
                </Question>
              </div>
            </div>
          </fieldset>
          <div className="fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(employerInfo)}
              text={
                checkValidityOnQuestionArray(advisorInfo)
                  ? 'Next step'
                  : 'Send to OneDigital'
              }
              onClick={() => {
                submitContactInfo();
                pushGoogleDataLayer({
                  event: 'PlanDesignQuestion',
                  PlanDesignEmployerState: employerStateCode.value,
                });
                updateActiveStep(
                  destination(checkValidityOnQuestionArray(advisorInfo)),
                  'forward',
                );
                return false;
              }}
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

EmployerQuestions.propTypes = {
  formData: PropTypes.object.isRequired,
  role: PropTypes.string,
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  employerInfo: PropTypes.array.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  putRole: PropTypes.func.isRequired,
  setPlanOriginator: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  putBrokerType: PropTypes.func.isRequired,
  putExperience: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  employerInfo: selectEmployerQuestions(store),
  advisorInfo: selectAdvisorQuestions(store),
  advisors: selectAdvisors(store),
  primaryAdvisor: selectPrimaryAdvisor(store),
});
const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  putRole: (role) => {
    dispatch(FormDataRedux.putRole(role));
  },
  setPlanOriginator: (planOriginator) => {
    dispatch(FormDataRedux.setPlanOriginator(planOriginator));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  putBrokerType: (obj, index) => {
    dispatch(FormDataRedux.putBrokerType(obj, index));
  },
  putExperience: (experience) => {
    dispatch(FormDataRedux.putExperience(experience));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployerQuestions),
);
