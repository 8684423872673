import React from 'react';

const CardSection = () => (
  <section id="card-section" className="position-relative section pt-4">
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-6 col-md-7 mx-auto">
          <h3 className="fs-h3 mb-4 text-center">
            That’s why we created Simply Retirement by Principal
            <small>
              <sup>®</sup>
            </small>
            .
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-7 col-md-10 mx-auto">
          <p className="fs-p">
            <strong>
              We’ve paired decades of experience in retirement solutions and
              investments from Principal with the simplicity and
              cost-effectiveness of the{' '}
              <a
                href="https://www.myubiquity.com/about"
                target="_blank"
                className="text-dark"
              >
                Ubiquity Retirement + Savings
                <small>
                  <sup>&reg;</sup>
                </small>{' '}
                online recordkeeping platform
              </a>
            </strong>
            , breaking down barriers like cost and complexity so more small
            businesses can offer their employees a way to save for retirement.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default CardSection;
