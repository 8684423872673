import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux from '../../../../Redux/FormDataRedux';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../../common/FormWithSteps/Utilities';
import QacaMaxAutoEscalateQuestions from './QacaMaxAutoEscalateQuestions';
import {
  StepControls,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../common/FormWithSteps';

const MaxAutoEscalateQuestion = ({
  updateQuestion,
  resetPlanQuestion,
  isValid,
  ...stepProps
}) => {
  const {
    autoDeferralPercentageStartEmployees,
    hasAutoEscalation,
    maxAutoEscalatePercentage,
  } = stepProps.questions;
  const [validateQuestions, setValidateQuestions] = useState([]);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
    if (updateValue === 'yes') {
      updateQuestion({
        name: maxAutoEscalatePercentage.name,
        dirty: true,
      });
    }
  };

  useEffect(() => {
    pushGoogleDataLayer(stepProps.googleData);
    updateQuestion({
      name: autoDeferralPercentageStartEmployees.name,
      dirty: true,
    });
  }, []);

  useEffect(() => {
    if (hasAutoEscalation.value === 'yes') {
      setValidateQuestions([
        autoDeferralPercentageStartEmployees,
        hasAutoEscalation,
        maxAutoEscalatePercentage,
      ]);
    } else {
      resetPlanQuestion(maxAutoEscalatePercentage.name);
      setValidateQuestions([
        autoDeferralPercentageStartEmployees,
        hasAutoEscalation,
      ]);
    }
  }, [
    autoDeferralPercentageStartEmployees,
    hasAutoEscalation,
    maxAutoEscalatePercentage,
  ]);

  return (
    <div className="container-fluid h-100">
      <QacaMaxAutoEscalateQuestions
        active={stepProps.active}
        previousStep={stepProps.previousStep}
        questions={stepProps.questions}
        updateActiveStep={stepProps.updateActiveStep}
        role={stepProps.role}
        handleQuestionChange={handleQuestionChange}
      />
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <StepControls
              formInReview={stepProps.formInReview}
              disableReviewBtn={!isValid}
              disabledNextBtn={!checkValidityOnQuestionArray(validateQuestions)}
              goToReviewPlan={stepProps.goToReviewPlan}
              clickNextBtn={() =>
                stepProps.updateActiveStep(stepProps.destination(), 'forward')
              }
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={stepProps.active}
        goTo={stepProps.previousStep}
        updateActiveStep={stepProps.updateActiveStep}
      />
    </div>
  );
};

MaxAutoEscalateQuestion.propTypes = {
  updateQuestion: PropTypes.func.isRequired,
  resetPlanQuestion: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  resetPlanQuestion: (questionId) => {
    dispatch(FormDataRedux.resetPlanQuestion(questionId));
  },
});

export default connect(null, mapDispatchToProps)(MaxAutoEscalateQuestion);
