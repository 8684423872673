import styled from 'styled-components';
import PrintIcon from '../../../../Images/icon-print.svg';
import SaveIcon from '../../../../Images/icon-save.svg';
import ShareIcon from '../../../../Images/icon-share.svg';

export const IconPrint = styled.i`
  background: url(${PrintIcon}) no-repeat;
  background-position: 50%;
`;

export const IconShare = styled.i`
  background: url(${ShareIcon}) no-repeat;
  background-position: 50%;
`;

export const IconSave = styled.i`
  background: url(${SaveIcon}) no-repeat;
  background-position: 50%;
`;

export const SharePlanControls = styled.div`
  background-color: rgba(0, 40, 85, 0.9);
  position: fixed;
  bottom: 0;
  z-index: 1;
  left: 0;
  right: 0;
`;

export const PlanurlStyles = styled.span`
  display: block;
  width: 100%;
  margin: auto;
  word-break: break-all;
  overflow-wrap: break-word;
`;
