export const safeHarbor = {
  id: 13,
  stepName: 'step_9',
  type: 'radio_button',
  name: 'safeHarbor',
  user: {
    employer: {
      label: 'Which type of matching contribution do you want to make?',
      infoLabel:
        'Contributing to your employees’ retirement is a great benefit to them. There are two ways you can contribute—safe harbor and non-safe harbor (safe harbor contributions will be allocated to participants each pay period). Both options provide tax deduction benefits.',
      heading: 'What’s safe harbor?',
      content:
        'Safe harbor is a type of 401(k) plan that allows you to bypass some of the compliance testing required by the IRS to make sure the plan is fair. In return, you’re required to help your employees save for retirement by making matching contributions to their 401(k) accounts. With safe harbor, you’ll contribute more money, but there’s less paperwork and administrative hassle. With a non-safe harbor plan, you have the option to contribute less, but you can’t bypass compliance testing required by the IRS. Check out the <a href="/safeharbor-explained">safe harbor resource page</a> for more details.',
    },
    advisor: {
      label:
        'Which type of matching contribution does the business owner want to make?',
      infoLabel:
        'Contributing to employees’ retirement is a great benefit to them. There are two ways the business owner can contribute—safe harbor and non-safe harbor (safe harbor contributions will be allocated to participants each pay period). Both options provide tax deduction benefits.',
      heading: "What's safe harbor?",
      content:
        'Safe harbor is a type of 401(k) plan that allows the business owner to bypass some of the compliance testing required by the IRS to make sure the plan is fair. In return, the business owner is required to help their employees save for retirement by making matching contributions to their 401(k) accounts. With safe harbor, the business owner may contribute more money, but there’s less paperwork and administrative hassle. With a non-safe harbor plan, the business owner has the option to contribute less, but they can’t bypass compliance testing required by the IRS. Check out the <a href="/safeharbor-explained">safe harbor resource page</a> for more details.',
    },
    anonymous: {
      label: 'Which type of matching contribution do you want to make?',
      infoLabel:
        'Contributing to your employees’ retirement is a great benefit to them. There are two ways you can contribute—safe harbor and non-safe harbor (safe harbor contributions will be allocated to participants each pay period). Both options provide tax deduction benefits.',
      heading: 'What’s safe harbor?',
      content:
        'Safe harbor is a type of 401(k) plan that allows you to bypass some of the compliance testing required by the IRS to make sure the plan is fair. In return, you’re required to help your employees save for retirement by making matching contributions to their 401(k) accounts. With safe harbor, you’ll contribute more money, but there’s less paperwork and administrative hassle. With a non-safe harbor plan, you have the option to contribute less, but you can’t bypass compliance testing required by the IRS. Check out the <a href="/safeharbor-explained">safe harbor resource page</a> for more details.',
    },
  },
  options: [
    {
      id: 1,
      user: {
        employer: {
          label: 'Safe harbor',
        },
        advisor: {
          label: 'Safe harbor',
        },
      },
      value: 'safeHarbor',
      valueFormatted: 'Safe harbor',
      checked: false,
      icon: '',
      customStyle: '',
    },
    {
      id: 2,
      user: {
        employer: {
          label: 'Non-safe harbor',
        },
        advisor: {
          label: 'Non-safe harbor',
        },
      },
      value: 'nonSafeHarbor',
      valueFormatted: 'Discretionary Non-safe harbor',
      checked: false,
      icon: '',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  valueFormatted: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Employer contribution type',
  description:
    'Employers have the option to match employee contributions. This not only provides a benefit to employees, but also helps encourage them to participate in the retirement plan. Matching contributions are tax deductible (see IRS.gov for more information).There are two ways an employer can contribute — Safe harbor and Non Safe harbor. With Safe harbor, you’ll contribute more money, but you can bypass some of the compliance testing required by the IRS. With non Safe harbor, you have the option to contribute less, but cannot bypass compliance testing required by the IRS. Compliance testing ensures that highly compensated employees do not benefit more than others from the plan.',
  optionDescription: `
      <p class="font-italic mb-0"><strong>You selected from these EMPLOYER options:</strong></p>
      <ul class="font-italic">
        <li>
          <strong>Safe harbor</strong>
          <ul>
            <li><strong>Stretch your match</strong> — Match the employee’s contribution 100% up to the first 3% of pay + match 50% of the next 2% of pay, up to 5% of pay.</li>
            <li><strong>Straight match</strong> — Match the employee’s contribution 100% up to 4% of pay.</li>
          </ul>
        </li>
        <li>
          <strong>Non-safe harbor</strong>
          <ul>
            <li>Match employee contributions dollar-for-dollar, 1%, 2%, 3% or 4% of pay</li>
            <li>No employer matching contributions</li>
          </ul>
        </li>
      <ul>
    `,
};
