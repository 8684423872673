export const tpaFirmName = {
  type: 'dropdown',
  name: 'tpaFirmName',
  user: {},
  label: 'TPA firm name',
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasError: false,
  placeholder: 'Start typing or select..',
  options: [],
};
