export const requireReview = (advisor, advisorCvStatus) => {
  if (advisor.questions.firmName === undefined) {
    return true;
  }
  if (advisor.questions.firmName.value === 'I don’t see my broker-dealer') {
    return true;
  }
  if (advisorCvStatus === 'PASS') {
    return false;
  }
  return true;
};
