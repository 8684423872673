import React from 'react';
import PropTypes from 'prop-types';

const BusinessOwnersTab = ({ hasTpa }) => (
  <div className="py-4 px-5">
    <h5 className="fs-h3 my-4 pt-2 text-purple">For business owners</h5>
    <p className="fs-p fw-bold">
      When you click the “Complete your purchase” button, you will be redirected
      to the Ubiquity Retirement + Savings
      <sup>
        <small>®</small>
      </sup>{' '}
      (Ubiquity) website where you will:
    </p>

    <div className="row my-5 justify-content-center">
      <div className="col-md-2 align-self-center">
        <h5 className="fs-h5 fw-bold text-purple">Set up login credentials.</h5>
      </div>
      <div className="col-md-1 align-self-center">
        <i className="fa-solid fa-arrow-right text-link" aria-hidden />
      </div>
      <div className="col-md-2 align-self-center">
        <h5 className="fs-h5 fw-bold text-purple">
          Provide additional details about your business.
        </h5>
      </div>
      <div className="col-md-1 align-self-center">
        <i className="fa-solid fa-arrow-right text-link" aria-hidden />
      </div>
      {!hasTpa && (
        <div className="col-md-2 align-self-center">
          <h5 className="fs-h5 fw-bold text-purple">
            Pay the one-time non-refundable $500 setup fee.
          </h5>
        </div>
      )}
      {!hasTpa && (
        <div className="col-md-1 align-self-center">
          <i className="fa-solid fa-arrow-right text-link" aria-hidden />
        </div>
      )}
      <div className="col-md-2 align-self-center">
        <h5 className="fs-h5 fw-bold text-purple">
          Sign required documents electronically.
        </h5>
      </div>
    </div>

    <p className="fs-p">
      After completing these steps, you will enter the “Implementations” stage
      where you will work with your dedicated specialist at Ubiquity to set up
      your plan over 3 phone calls. Here’s what you can expect:
    </p>

    <ul className="fs-p">
      <li>
        You’ll get an email from “401(k) Implementations”
        &lt;newplan@myubiquity.com&gt; to set up a 50-minute introductory call
        with your dedicated specialist. Please schedule your call as soon as
        possible, preferably within the next five business days!
      </li>
      <li>
        Your specialist will help you add your employees on your 2nd call.
      </li>
      <li>
        Your specialist will assist you in funding your first contribution on
        your 3rd and final implementation call. After you process the first
        payroll and start funding the plan, you’ll be all set and your plan is
        active!
      </li>
    </ul>

    <p className="fs-p mb-4">
      After you process the first payroll and start funding the plan, you’ll be
      all set! Your plan will be considered active.
    </p>
  </div>
);

BusinessOwnersTab.propTypes = {
  hasTpa: PropTypes.bool.isRequired,
};

export default BusinessOwnersTab;
