import React from 'react';

const AdvisorFee = () => (
  <div className="py-4 px-5">
    <p className="fs-p fw-bold mb-0">Financial professional</p>
    <p className="fs-p">
      Financial professional fees If you choose to work with a financial
      professional, they will also have a fee for providing their expertise and
      assistance with the setup and management of your plan. You may pay this
      fee directly to your financial professional, or it can be deducted from
      participant accounts.
    </p>
  </div>
);

export default AdvisorFee;
