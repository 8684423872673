import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../../../Images/icon-laptop-purple.svg';

const SectionInvestPerform = ({
  putApplicationPanelInfo,
  financialProfessionalWhySimplyDisclaimer6,
}) => (
  <section className="section section_py-5 pt-0">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <img
            src={Icon}
            width={60}
            height={60}
            alt="computer icon with checkmark"
            className="mb-4"
          />
          <h3 className="fs-h3 fw-bold text-purple mb-4">
            Investments with attractive performance
          </h3>
          <p className="fs-h4 fw-normal mb-md-0 mb-4">
            Our investment options offer attractive performance with a
            competitive return, helping make the most of employees’
            contributions and any matching contributions your client provides as
            a business owner.
          </p>
          <p className="fs-h6">
            <strong>
              Wilshire Advisors LLC will be the planʼs 3(38) investment
              fiduciary.
            </strong>{' '}
            They&rsquo;ll provide objective, independent third-party oversight
            for the screening, selection, and monitoring of the plan&rsquo;s
            investment options. They’ll also make changes to the{' '}
            <button
              type="button"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation:
                    financialProfessionalWhySimplyDisclaimer6,
                })
              }
              className="text-underline p-0 no-border-button"
            >
              investment lineup as appropriate.
            </button>
            This service will help manage your clients’ related fiduciary
            liability.
            <sup>
              <small>1</small>
            </sup>{' '}
            See <Link to="/financial-professionals/resources">Resources</Link>{' '}
            for a listing of investments.
          </p>
          <p className="fs-h6">
            Wilshire Advisors LLC is a diversified global financial services
            firm with more than 40 years of experience providing investment
            guidance to some of the largest plan sponsors in the U.S.
          </p>
          <p className="fs-disclaimer">
            <sup>
              <small>1</small>
            </sup>
            The decision to delegate and ongoing monitoring of the service
            provider is ultimately a responsibility that belongs to the
            appropriate retirement plan fiduciaries.{' '}
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default SectionInvestPerform;

SectionInvestPerform.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  financialProfessionalWhySimplyDisclaimer6: PropTypes.any,
};
