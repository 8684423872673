import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectAdvisors,
  selectTpaQuestions,
  selectPrimaryAdvisor,
} from '../../../../../Redux/FormDataRedux';
import ModalRedux from '../../../../../Redux/ModalRedux';
import Template from './Template';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../../../common/FormWithSteps/Utilities';
import ConfirmModal from '../../../../../common/Modal/ConfirmModal';

const StepClientInfo = ({
  active,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  tpaInfo,
  updateQuestion,
  role,
  googleData,
  putApplicationPanelInfo,
  createModal,
  destroyModal,
}) => {
  const {
    companyName,
    employerStateCode,
    insuranceGroupType,
    hasFp,
    hasTpa,
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
  } = questions;
  const validateAdvisorQuestions = [
    employerStateCode,
    insuranceGroupType,
    companyName,
    hasTpa,
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
  ];

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChangeState = (id, updateValue, valueFormatted) => {
    handleQuestionChange(id, updateValue, valueFormatted);
    updateQuestion({
      name: insuranceGroupType.name,
      value: insuranceGroupType.value,
      hasError: updateValue === 'TX' ? !insuranceGroupType.value : false,
      dirty: true,
      valueFormatted: insuranceGroupType.valueFormatted,
    });
  };

  const nextStepAction = () => {
    const nextAction = () => {
      destroyModal();
      updateQuestion({
        name: hasFp.name,
        value: true,
        valueFormatted: 'Yes',
        hasError: false,
        dirty: true,
      });
      updateActiveStep(destination(), 'forward');
    };
    createModal(
      <ConfirmModal destroyModal={destroyModal} nextAction={nextAction} />,
    );
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <Template
      active={active}
      previousStep={previousStep}
      questions={questions}
      updateActiveStep={updateActiveStep}
      role={role}
      userRole="advisor"
      tpaInfo={tpaInfo}
      handleQuestionChange={handleQuestionChange}
      handleQuestionChangeState={handleQuestionChangeState}
      advisorQuestions={validateAdvisorQuestions}
      nextStepAction={nextStepAction}
      putApplicationPanelInfo={putApplicationPanelInfo}
      createModal={createModal}
    />
  );
};

StepClientInfo.propTypes = {
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  tpaInfo: PropTypes.array.isRequired,
  role: PropTypes.string,
  updateQuestion: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  googleData: PropTypes.object,
  createModal: PropTypes.func.isRequired,
  destroyModal: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  tpaInfo: selectTpaQuestions(store),
  primaryAdvisor: selectPrimaryAdvisor(store),
  advisors: selectAdvisors(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  createModal: (child, modalType) => {
    dispatch(ModalRedux.createModal(child, modalType));
  },
  destroyModal: () => {
    dispatch(ModalRedux.destroyModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StepClientInfo);
