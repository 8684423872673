import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';

const beautifyText = (text) =>
  text.replace(/'/g, '’').replace(/\s([^\s<]+)\s*$/, '\u00A0$1');

const ResourceCard = ({
  headline = 'Test Headline',
  date = 'Date 22, 2020',
  link = '/',
  linkText = 'Read Article',
}) => (
  <div className="resource-card">
    <p className="fs-discalimer mb-2">{date}</p>
    <Link to={link} className="text-decoration-none">
      <h3
        className="fs-h2 mb-3"
        dangerouslySetInnerHTML={{ __html: beautifyText(headline) }}
      ></h3>
    </Link>
    <Link to={link} className="fs-p">
      {linkText} &gt;
    </Link>
  </div>
);

ResourceCard.propTypes = {
  headline: PropTypes.string,
  date: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
};

export default ResourceCard;
