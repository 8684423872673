import React from 'react';
import PropTypes from 'prop-types';

export const RenderCustomMessage = ({
  advisorFirmBroker,
  messageVisible,
  setMessageVisible,
  setMessage,
}) => {
  if (advisorFirmBroker.valueFormatted === 'Ameriprise') return false;
  if (!messageVisible) {
    return (
      <p className="text-center mb-lg-auto">
        <button
          type="button"
          className="srp-button srp-button__link mx-auto"
          onClick={() => setMessageVisible(true)}
        >
          Add a custom message
        </button>
      </p>
    );
  }
  return (
    <div className="mt-4">
      <label className="fs-form-label text-center d-block" htmlFor="message">
        Custom message
      </label>
      <input
        type="text"
        className="srp-form-input form-control"
        id="message"
        name="message"
        onChange={(e) => setMessage(e.target.value)}
      />
    </div>
  );
};

RenderCustomMessage.propTypes = {
  advisorFirmBroker: PropTypes.object.isRequired,
  messageVisible: PropTypes.bool.isRequired,
  setMessageVisible: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
};
