import React from 'react';
import { CalloutSection } from './index.styles';
import { AboutFigure002 } from '../../../../ImagePaths';
import SectionSingleCol from '../../../../common/SectionSingleCol';

const workTogetherSection = `
  <img
    src=${AboutFigure002}
    alt="teamwork illustration"
    class="d-block mx-auto mt-3 mb-5"
    fill="#ffffff"
  />
  <h3 class="fs-h3 text-white text-center mb-5">How we’re working together</h3>
  <p class="fs-p mb-5 text-white text-center">
    You may be wondering about each company’s role in this collaboration. Here’s the simple answer. Principal is the plan provider. We back the Simply Retirement by Principal<sup><small>®</small></sup> product and provide the investment options. Ubiquity provides the plan record keeping and administrative services once your client purchases their plan. Your clients and their participating employees will also use Ubiquity’s digital platform to manage their plan.
  </p>
`;

const PurpleBox = () => (
  <CalloutSection
    style={{ background: '#002855' }}
    className="position-relative"
  >
    <SectionSingleCol copy={workTogetherSection} />
  </CalloutSection>
);

export default PurpleBox;
