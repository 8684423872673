export const whichSafeHarborQaca = {
  id: 'q6ai3b',
  type: 'dropdown',
  name: 'whichSafeHarborQaca',
  paradigmAlias: 'whichSafeHarborQaca',
  user: {},
  sideCardTitle: 'Which Safe Harbor QACA',
  label: 'Which QACA Safe Harbor contribution will the plan utilize?',
  infoLabel: '',
  options: [
    {
      id: 1,
      user: {},
      label: '3% non-elective',
      value: '3PercNonelectiveSafeharbor',
      valueFormatted: '3% non-elective',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 2,
      user: {},
      label: 'Basic match:  100% up to 1%, 50% up to the next 5%',
      value: 'basicMatch100to1-50toNext6',
      valueFormatted: 'Basic match:  100% up to 1%, 50% up to the next 5%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 3,
      user: {},
      label: 'Enhanced match:  100% up to 3.5%',
      value: 'enhancedMatch100to3.5',
      valueFormatted: 'Enhanced match:  100% up to 3.5%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 4,
      user: {},
      label: 'Enhanced match:  100% up to 4%',
      value: 'enhancedMatch100to4',
      valueFormatted: 'Enhanced match:  100% up to 4%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 5,
      user: {},
      label: 'Enhanced match:  100% up to 5%',
      value: 'enhancedMatch100to5',
      valueFormatted: 'Enhanced match:  100% up to 5%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
    {
      id: 6,
      user: {},
      label: 'Enhanced match:  100% up to 6%',
      value: 'enhancedMatch100to6',
      valueFormatted: 'Enhanced match:  100% up to 6%',
      checked: false,
      icon: 'icon-calendar-o',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  reviewScreenTitle: 'Which Safe Harbor QACA',
  optionDescription: '',
  description: 'This is the safe harbor contribution the plan will utilize.',
};
