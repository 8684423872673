import React from 'react';
import PropTypes from 'prop-types';
import { recordkeepingFee } from './Utilities';

const recordkeepingInfo = `
<h2 class="text-white fw-bold fs-h3 mb-4">What’s included in the recordkeeping fees</h2>
<p class='text-white pt-3 fs-sm'>
  Monthly recordkeeping fees cover the cost of maintaining the plan, including
  services such as annual nondiscrimination testing, filing and reporting of
  required documentation, ongoing recordkeeping, automated participant signup
  and onboarding, an online platform for plan administration, and a dedicated phone
  number for questions.
</p>
`;

const QuickQuoteCard = ({
  statValue,
  statLabel,
  statHelper,
  moreInfo,
  putApplicationPanelInfo,
}) => (
  <>
    <div className="stat">
      <strong className="stat-value">
        <span className="prefix">$</span>
        {statValue}
      </strong>
      {statHelper && (
        <strong className="stat-label d-md-block d-none">
          <small>{statHelper}</small>
        </strong>
      )}
      <strong
        className="stat-label"
        dangerouslySetInnerHTML={{ __html: statLabel }}
      />
      {moreInfo && (
        <button
          className="srp-button__link p-0 d-block"
          type="button"
          onClick={() => {
            putApplicationPanelInfo({
              hasVisibility: true,
              additionalInformation: moreInfo,
            });
          }}
        >
          What’s included?
        </button>
      )}
    </div>
    {statHelper && (
      <strong className="stat-label d-md-none d-block text-center">
        <small>($150 + $6 per participant*)</small>
      </strong>
    )}
  </>
);

QuickQuoteCard.propTypes = {
  statValue: PropTypes.string.isRequired,
  statLabel: PropTypes.string,
  statHelper: PropTypes.string,
  moreInfo: PropTypes.string,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const QuickQuoteCalculator = ({
  numberOfEmployees,
  putApplicationPanelInfo,
}) => (
  <div className="row plan-proposal__card-row justify-content-center mb-5">
    <div className="col-md-9 mb-5">
      <h4>Your client can offer a 401(k) plan to their employees for:</h4>
    </div>
    <div className="col-xl-5 col-lg-6 col-md-8 mb-3 mx-auto">
      <div className=" plan-proposal__card principal-card default floating h-100 d-flex justify-content-around flex-column">
        <div className="label">
          <i className="far fa-calendar-check icon" aria-hidden />{' '}
          <span>Every month</span>
        </div>
        <QuickQuoteCard
          statValue={recordkeepingFee(numberOfEmployees)}
          statHelper="($145 + $6 per participant*)"
          statLabel="Recordkeeping fee"
          moreInfo={recordkeepingInfo}
          putApplicationPanelInfo={putApplicationPanelInfo}
        />
      </div>
    </div>
  </div>
);

QuickQuoteCalculator.propTypes = {
  numberOfEmployees: PropTypes.number.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

export default QuickQuoteCalculator;
